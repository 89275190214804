<mat-card class="categoryListCard">
    <mat-card-content>
        <div>
            <div *ngFor="let category of categoryList" class="categoryList" [ngClass]="category.active?'active':''">
                <div class="singleCategory" (click)="selectTraining(category)">
                    {{category.training_category_name}}
                </div>
                <span class="categoryArrow">
                    <mat-icon >keyboard_arrow_right</mat-icon>
                </span>
            </div>
        </div>
    </mat-card-content>
</mat-card>