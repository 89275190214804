import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../../usermanagement/user-list/user-list.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-evaluationlist',
  templateUrl: './evaluationlist.component.html',
  styleUrls: ['./evaluationlist.component.css']
})
 


 

export class EvaluationlistComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/evaluationstatus";
  deleteendpoint = "intake/deleteevaluation";
  datacollection: any = "intake/evaluationlist";
  public listprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/evaluationlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    code: "E & M Code",
    non_facility_charge: "Non Facility Charge",
    non_facility_limited_charge: "Non Facility Limited Charge",
    time_required: "Time",
    description: "Description",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status: "Status",
 


    
  };
  search_settings: any = {


    search: [{
      label: "Search By E & M Code", field: 'code',
      serversearchdata: { endpoint: 'intake/evaluationautocomplete' },
    },
    ],

    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: [  "createdon_datetime", "updatedon_datetime", "status", "usertype"],
  };
  public cookieData: any = {};
 
 

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      
    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "code", val: "E & M Code" },
      { key: "non_facility_charge", val: "Non Facility Charge" },
      { key: "non_facility_limited_charge", val: "Non Facility Limited Charge" },
      { key: "time_required", val: "Time" },
      { key: "description", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "status", val: 'Status' }


   
    ],
    updateendpoint: "intake/evaluationstatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/deleteevaluation",

    deleteendpointmany: "intake/deleteevaluation",
    tableheaders: ["code", "non_facility_charge", "non_facility_limited_charge","time_required",  "status", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewevaluation',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ["code", "non_facility_charge", "non_facility_limited_charge","time_required",'description', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },

      //     {
      //       label: "Preview",
      //     type: 'action',
      //     datatype: 'local',
      //       datafields: ['assay_name', 'assay_des', 'status', 'createdon_datetime',  'updatedon_datetime'],
      //     headermessage: 'Local Info',
      //     // cond:'status',
      //     // condval:0
      //       classname: 'previewButton'
      // },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
 
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {



    this.progressLoader = true;
    this.userListFetching();
 

  }
 
addBtn() { this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-evaluation`); }
  
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}


    }

  



    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })

    this.httpService.httpViaPost('intake/evaluationlist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }



  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
  



  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
    }
  
    
  
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/edit-evaluation/${item._id}`);


    }
  }
}