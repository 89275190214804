import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  durationInSeconds = 5;

  hide = true;
  email: any = '';
  password: any = '';

  public loginForm: FormGroup;
  public ipInformation: any = {};
  public progressLoader: boolean = false;

  public messageShow: any = '';

  public messageShow_flag: boolean = false;
  // public currTime: any = new Date().getTime();
  // public currentDate: any = null;
  // public dayAfterFourteen: any = null;

  constructor(private http: HttpServiceService, public router: Router, private cookieService: CookieService, private _snackBar: MatSnackBar, private metaService: Meta, private titleService: Title) { }

  ngOnInit() {

    // this.currentDate = this.currTime + 24 * 60 * 60 * 1000;
    // this.dayAfterFourteen = this.currTime + 336 * 3600 * 1000;
    this.titleService.setTitle("Login");
    this.metaService.addTags([
      {
        name: 'keywords',
        content: 'Login',
      },
      {
        name: "og:title", property: "og:title", content: "Login"
      },
      {
        name: "og:description", property: "og:description", content: "Login"
      },
      {
        name: "og:type", property: "og:type", content: "Login"
      },
      { name: 'robots', content: 'Login' },
      { name: 'author', content: 'Login' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: new Date().toString(), scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);

    console.log("this.cookieService.get('loggedin_user')", this.cookieService.get('loggedin_user'))
    if (this.cookieService.get('loggedin_user')) {
      let userData: any = JSON.parse(this.cookieService.get('login_user_details'))
      console.log("login route", userData)
      this.router.navigateByUrl(`${userData.user_type}/dashboard`)
    }
    if (this.cookieService.get('loggedout_user')) {
      // this._snackBar.open('You have successfully logged out! ', '', {
      //   duration: 2000
      // });
      setTimeout(() => {
        this.cookieService.delete('loggedout_user', '/');
        this.cookieService.deleteAll();
      }, 3000)
    }
    this.loginForm = new FormGroup({
      // email: new FormControl('', Validators.pattern(`^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$`)), // remove patter for user can use Capital letter
      email: new FormControl(''),
      password: new FormControl(''),
    });

    fetch("https://ipinfo.io/?format=json&token=3dca28c86c59f8")
      .then((results) => results.json())
      .then((data) => {
        console.log('response', data)
        if (data) {
          this.ipInformation = data
        }
      })


  }




  login() {


    console.log(this.loginForm.value)
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.progressLoader = true;
      this.messageShow = null;
      let dataobj = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
        login_data: this.ipInformation

      }

      console.log("dataobj------------>", dataobj);

      this.http.httpViaPost('auth/login', dataobj).subscribe((response) => {


        if (response) {
          if (response.status == "success") {

            this.cookieService.set('login_user_details', JSON.stringify(response.res[0]));
            this.cookieService.set('loggedin_user', 'true');
            setTimeout(() => {
              console.log('login response', response.res[0])

              if (response.res[0].first_login === 1) {
                this.router.navigateByUrl(`${response.res[0].user_type}/set-password`);
              } else {
                if (response.res[0].user_type) {
                  if (response.res[0].first_login === 2 && response.res[0].user_type == 'laboratory') {
                    this.router.navigateByUrl(`${response.res[0].user_type}/miscellaneous/choose-diagnostic`);
                  } else {
                    if (response.res[0].info_update !== null && response.res[0].info_update !== undefined && response.res[0].info_update == false && response.res[0].landing_page) {
                      this.router.navigateByUrl(`${response.res[0].user_type}/account-settings`);
                    } else {
                      // if (response.res[0].user_type == 'practice') {
                      //   console.log('login response', response.res[0])
                      // }
                      // this.router.navigateByUrl(`${response.res[0].user_type}/training/training-center`);
                      this.router.navigateByUrl(`${response.res[0].user_type}/dashboard`);
                    }
                  }
                } else {
                  this.router.navigateByUrl("dashboard");
                }
              }
            }, 1000)
          }
          this._snackBar.open(response.message, '', {
            duration: 3000
          })
          this.progressLoader = false;
        }



      }, (err) => {
        console.log(err)

        this.progressLoader = false;
        this.messageShow_flag = true
        this.messageShow = err.error.message;

      })


    }
  }


  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // if (event.key === "Enter") {}
      //alert('you just pressed the enter key');
      // rest of your code
      this.login();
      this.hide = false;


    }
  }




}


