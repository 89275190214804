import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../usermanagement/user-list/user-list.component';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export interface DialogData {
  data: any;
  flag: any;
}
@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.css']
})
export class LeadListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'status', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "users";
  editroute = "";
  updateendpoint = "";
  deleteendpoint = "";
  datacollection: any = "intake/leadlist";
  public statusCode: any;
  public listTitleName: any;
  public UsersSummaryLoader = false


  searchendpoint = "";
  date_search_endpoint: any = "intake/leadlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    doctor_email: "Doctor Email",
    doctor_name: "Doctor Name",
    patient_address: "Patient Address",
    patient_name: "Patient Name",
    patient_email: "Patient Email",
    practice_email: "Practice Email",
    createdon_datetime: "Created On",
updatedon_datetime: "Updated On",
    practice_name: "Practice Name",

  };
  search_settings: any = {


    search: [{
      label: "Search By Patient Name",
      field: '_id',
      serversearchdata: { endpoint: 'intake/patientnameautocomplete' },
    },
    {
      label: "Search By Patient Email",
      field: 'patient_email',
      serversearchdata: { endpoint: 'intake/patientemailautocomplete' },
    },
    {
      label: "Search By Practice Name",
      field: 'practice_id',
      serversearchdata: { endpoint: 'users/userNameAutocomplete' },
    },
    {
      label: "Search By Doctor Name",
      field: 'doctor_id',
      serversearchdata: { endpoint: 'users/userNameAutocomplete' },
    },
    ],


    textsearch: [
      // { label: "Search By Priority", field: 'priority' },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      // { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["doctor_name", "createdon_datetime", "patient_name", "patient_email", "practice_name"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Taxonomies',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "doctor_name", val: "Doctor Name" },
      { key: "doctor_email", val: "Doctor Email" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "practice_name", val: "Practice Name" },
      { key: "patient_address", val: "patient_address" },
      { key: "patient_name", val: "patient_name" },
      { key: "practice_email", val: "practice_email" },
      { key: "patient_email", val: "patient_email" }
    ],
    updateendpoint: "users/statusChangeTaxo",

    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "users/statusChangeTaxo",

    deleteendpointmany: "users/deleteTaxo",
    // tableheaders: ['patient_name', "patient_email", "practice_name", "practice_email", "doctor_name", "doctor_email", "createdon_datetime"],
    tableheaders: ['patient_name', "patient_email", "practice_name", "practice_email", "doctor_name", "doctor_email", "updatedon_datetime"],


    custombuttons: [
      // // {
      // //   label: "Edit",
      // //   type: 'listner',
      // //   id: 'edit_btn',
      // //   tooltip: 'Edit',
      // //   name: 'edit',
      // //   classname: 'edit_btn'
      // // },
      // {
      //   label: "Preview",
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'users/previewtaxo',
      //   otherparam: [],
      //   // cond:'status',
      //   // condval:0,
      //   param: '_id',
      //   datafields: ['taxonomy', 'description', 'priority', 'createdon_datetime', 'updatedon_datetime', 'status'],
      //   //  refreshdata: true,
      //   headermessage: 'Preview',
      //   tooltip: 'Preview',
      //   classname: 'previewButton'
      // },
      {
        label: "Preview",
        type: "listner",
        id: "preview_btn",
        classname: 'previewButton',
        tooltip: 'Preview',

      },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(public dialog: MatDialog, public matSnackBar: MatSnackBar, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    if (!window.location.pathname.includes('admin/lead-list') && !window.location.pathname.includes('doctor/lead-list') && !window.location.pathname.includes('practice/lead-list') && !window.location.pathname.includes('laboratory/lead-list') && !window.location.pathname.includes('sales_person/lead-list')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {
    this.progressLoader = true;
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;
      }
    }
    console.log("cookieService", this.cookieData)










    // if (this.statusCode) {
    //   this.libdata.basecondition.status = this.statusCode
    // }

    console.log("this.libdata.basecondition", this.libdata.basecondition);


    this.libdata.notes = {
      label: "Notes",
      tooltip: 'Add Notes',
      listendpoint: "intake/listleadnotedata",
      deleteendpoint: "intake/deleteleadnotedata",
      addendpoint: "intake/addleadsnotes",
      user: this.cookieData._id,
      currentuserfullname: this.cookieData.firstname + " " + this.cookieData.lastname,
      header: "patient_name",
    }

    console.log("this.libdata", this.sortdata);

    if (this.activatedRoute && this.activatedRoute.snapshot.params["param"]) {
      this.statusCode = JSON.parse(this.activatedRoute.snapshot.params["param"])
      console.log("this.activatedRoute>>>>>", this.statusCode);
    }
    if (this.statusCode == 3) {
      this.libdata.basecondition.status = { $in: [3, 7] }
    } else {
      this.libdata.basecondition.status = this.statusCode
    }
    switch (this.cookieData.user_type) {

      case 'doctor':
        this.libdata.basecondition.$or= [{ doctor_id: this.cookieData._id }] //{ practice_id: this.cookieData.practice_id }
        break;
      case 'practice':
        this.libdata.basecondition.practice_id= this.cookieData._id 
        break;
      case 'laboratory':
        console.log("this.statusCode>>>>>>>>>", this.statusCode);
        this.libdata.basecondition.parent_laboratory = this.cookieData._id ;
        break;

    }


    this.userListFetching();

    if (this.statusCode) {
      if (this.statusCode == 1) {
        this.listTitleName = "New patient"
      }
      else if (this.statusCode == 2) {
        this.listTitleName = "Order Sheet Generated"
      }


      else if (this.statusCode == 4) {
        this.listTitleName = "Order Accepted"
        console.log("this.listTitleName", this.listTitleName);

      }
      else if (this.statusCode == 5) {
        this.listTitleName = "Order Rejected"
      }
      else if (this.statusCode == 6) {
        this.listTitleName = "Test Results Received"
      } else if (this.statusCode == 3) {
        if (this.cookieData.user_type !== 'laboratory') {
          this.listTitleName = "Order Sign and Send"
        } else {
          this.listTitleName = "Order Pending"
        }
      }

    }


    if (this.cookieData.user_type == 'laboratory') {
      if (this.statusCode && this.statusCode == 3) {
        // console.log("aaaaaaaaaa", this.libdata);
        this.libdata.custombuttons = [
          {
            label: "Preview",
            type: "listner",
            id: "preview_btn",
            classname: 'previewButton',
            tooltip: 'Preview',

          },
          {
            label: "Approve",
            type: "listner",
            id: "approve_btn",
            classname: 'approveButton',
            tooltip: 'Approve',
          },
        ]

      }
      if (this.statusCode && this.statusCode == 4) {
        this.libdata.custombuttons = [
          {
            label: "Preview",
            type: "listner",
            id: "preview_btn",
            classname: 'previewButton',
            tooltip: 'Preview',

          },
          {
            label: "Mark As Done",
            type: "listner",
            id: "markasdone_btn",
            classname: 'markasdoneButton',
            tooltip: 'Mark As Done',
          },
          {
            label: "Reverse Status",
            type: "listner",
            id: "markasdone_reversestatus",
            classname: 'reversestatusButton',
            tooltip: 'Reverse Status',
          },
        ]
      }
      if (this.statusCode && this.statusCode == 5) {
        this.libdata.custombuttons = [
          {
            label: "Preview",
            type: "listner",
            id: "preview_btn",
            classname: 'previewButton',
            tooltip: 'Preview',

          },
          {
            label: "Reverse Status",
            type: "listner",
            id: "markasdone_reversestatus",
            classname: 'reversestatusButton',
            tooltip: 'Reverse Status',
          },
        ]
      }
    }





  }

  userListFetching() {
    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        status: this.statusCode
      }
    }
    if (this.statusCode == 3) {
      data.searchcondition.status = { $in: [3, 7] }
    }
    console.log("this.cookieData>>>>>>", this.cookieData);
    switch (this.cookieData.user_type) {
      case 'doctor':
        data.searchcondition.$or = [{ doctor_id: this.cookieData._id }, ] //{ practice_id: this.cookieData.practice_id }
        break;
      case 'practice':
        data.searchcondition.practice_id = this.cookieData._id
        break;
      case 'laboratory':
        data.searchcondition.parent_laboratory = this.cookieData._id

// data.searchcondition.is_demo = false
        break;


    }


    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })




    this.httpService.httpViaPost('intake/leadlist-count', data).subscribe((response: any) => {
      console.log("response list", response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }

  // addBtn() {
  //   this.router.navigateByUrl(`admin/taxonomies/add`);
  // }

  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
    this.router.navigateByUrl(`admin/taxonomies/add`);

  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data, this.libdata);






    // if (data.action == "custombuttonclick") {
    //   if (data.custombuttonclick.btninfo.id == 'edit_btn') {
    //     this.editListItem(data.custombuttonclick.data);
    //   }
    // }

    if (data.action == 'custombuttonclick') {
      if (data.custombuttonclick.btninfo.id == "preview_btn") {

        // this.router.navigateByUrl(`${this.cookieData.user_type}/preview-intake/${data.custombuttonclick.data._id}`);

        window.open(`${this.cookieData.user_type}/preview-intake/${data.custombuttonclick.data._id}`, '_blank')

      }
    }
    if (data.custombuttonclick.btninfo.id == "approve_btn") {
      const bottomSheetRef = this._bottomSheet.open(ApproveBottomSheet, {
        data: data.custombuttonclick.data,
      });
      bottomSheetRef.afterDismissed().subscribe(x => {
        console.log("bottomSheetRef afterDismissed", x)
        if (x) {
          if (x == "accept") {
            this.libdata.basecondition.status = 3
            this.taxonomy_updatetable = !this.taxonomy_updatetable;
          }
          else {
            const dialogRef = this.dialog.open(RejectNotesLeadList, {
              data: data.custombuttonclick.data,
              panelClass: 'reject-notes-modal'
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log("result test +++++", result);
              this.libdata.basecondition.status = 3
              this.taxonomy_updatetable = !this.taxonomy_updatetable;
            });
          }


        }
      });
    }
    if (data && data.custombuttonclick.btninfo.id == "markasdone_btn") {
      this.UsersSummaryLoader = true
      let dataset: any = {
        data: {
          _id: data.custombuttonclick.data._id,
          status: 6
        }
      }
      this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
        console.log("finduniquecodebyuser", response)
        if (response.status == "success") {
          this.UsersSummaryLoader = false
          this.matSnackBar.open("Diagnosis Completed", "ok", {
            duration: 3000,
          });
          this.libdata.basecondition.status = 4
          this.taxonomy_updatetable = !this.taxonomy_updatetable;
        }

      })
    }

    if (data && data.custombuttonclick.btninfo.id == "markasdone_reversestatus") {
      this.UsersSummaryLoader = true
      let dataset: any = {
        data: {
          _id: data.custombuttonclick.data._id,
          status: 3
        }
      }
      this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
        console.log("finduniquecodebyuser", response)
        if (response.status == "success") {
          this.UsersSummaryLoader = false
          this.matSnackBar.open("Order Pending", "ok", {
            duration: 3000,
          });
          if (this.statusCode == 4) {
            this.libdata.basecondition.status = 4
          }
          else {
            this.libdata.basecondition.status = 5
          }

          this.taxonomy_updatetable = !this.taxonomy_updatetable;
        }

      })
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults.status == 'success') {
        console.log('statusupdate>>>>', data);
        this.taxonomy_updatetable = !this.taxonomy_updatetable;

      }

    }
    if (data.action == "multiplestatusupdate") {
      console.log('statusupdate>>>>', data);
      this.taxonomy_updatetable = !this.taxonomy_updatetable;
    }
  }
  // editListItem(item: any) {
  //   if (item) {
  //     console.log("Op=====>1", item);
  //     this.router.navigateByUrl(`admin/taxonomies/edit/${item._id}`);
  //   }
  // }
}


@Component({
  selector: 'addedituniquename',
  templateUrl: 'approvemodal.html',
})
export class ApproveBottomSheet {
  // public laboratoryData: any = null;
  public approveProgressBar: any = false;
  public cookieData: any = {};
  public Uniquemessage: any = {};
  public approveListData: any;
  constructor(
    // public dialogRef: MatDialogRef<ApproveDialog>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    private _bottomSheetRef: MatBottomSheetRef<ApproveBottomSheet>,
    public activateRoute: ActivatedRoute,
    private httpService: HttpServiceService,
    public cookieService: CookieService,
  ) {
    console.log('DDD>>', data);
    if (data) {
      this.approveListData = data
    }


  }


  ngOnInit() {



  }

  modalClose(): void {
    this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }


  accept() {
    this.approveProgressBar = true
    let data: any = {
      data: {
        _id: this.approveListData._id,
        status: 4
      }
    }
    this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
      console.log("finduniquecodebyuser", response)
      if (response.status == "success") {
        this.approveProgressBar = false
        this._bottomSheetRef.dismiss('accept');
      }

    })

  }

  reject() {


    this._bottomSheetRef.dismiss('reject');


    // this.approveProgressBar = true
    // let data: any = {
    //   data: {
    //     _id: this.approveListData._id,
    //     status: 5
    //   }

    // }
    // this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
    //   console.log("finduniquecodebyuser", response)

    //   if (response.status == "success") {
    //     this.approveProgressBar = false
    //     this._bottomSheetRef.dismiss('reject');
    //   }

    // })
  }


  // modalClose(): void {
  //   this.dialogRef.close();
  // }


}



@Component({
  selector: 'rejectnotes',
  templateUrl: 'rejectnotes.html',
})

export class RejectNotesLeadList {
  public addRejectForm: FormGroup;
  public cookieData: any = {};
  public approveProgressBar = false;
  // public error = false;

  constructor(
    public dialogRef: MatDialogRef<RejectNotesLeadList>,
    public apiservice: HttpServiceService,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService
  ) {

    console.log("modal Data test", data);

  }



  ngOnInit() {
    this.addRejectForm = new FormGroup({
      addnotes: new FormControl(''),
      // user_id: new FormControl(this.cookieData._id),
    });

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("this.cookieData>>>>", this.cookieData)


      // this.firstBlockData = this.cookieData.last_login_time;

    };
    console.log("cookieData+++++++++", this.cookieData);

  }

  addRejectNotes() {
    this.approveProgressBar = true
    console.log(">>>>>>>>", this.addRejectForm)
    if (this.addRejectForm.valid) {
      console.log(">>>>>>>>qq", this.addRejectForm)
      // return
      // this.addRejectForm.value.uniquecode = this.addRejectForm.value.uniquecode.replace(/\s/g, "")
      // this.progressBar = true;

      // let dataobj = {
      //   addnotes: this.addRejectForm.value.addnotes,

      // }

      let data: any = {
        data: {
          _id: this.data._id,
          status: 5,
          reject_notes: this.addRejectForm.value.addnotes,
          rejected_by: this.cookieData._id
        }

      }
      this.apiservice.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
        console.log("finduniquecodebyuser", response)

        if (response.status == "success") {
          this.approveProgressBar = false
          this.dialogRef.close();
        }

      })
    }
    else {
      this.approveProgressBar = false
      // this.error = true
    }
  }


  modalClose() {
    this.dialogRef.close();
  }

}









