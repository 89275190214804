<div class="ModalTabSubConPage">

    <h1>POTENTIAL DIAGNOSIS BY ICD </h1>

    <div class="Additionalmodalwrapper_content_wrapper">
      

        <div *ngFor="let item of potentialdiagnosisData" class="wrapper">
            <mat-card-content class="content1st">
                <h2>{{item.icd_code}}: </h2>
                <h2>{{item.icd_description}} </h2>
            </mat-card-content>
            <mat-card-content class="content2nd">
                <mat-card-content>
                    <h3> Additional Recommemded Diagnostic Tests :</h3>
                </mat-card-content>
                <ng-container *ngIf="item.selected_additional_testing_name.length==0">
                    <mat-card-content>
                        <h5> No additional tests recommended </h5>
                    </mat-card-content>
                </ng-container>
                <ng-container *ngIf="item.selected_additional_testing_name.length!=0">
                    <mat-card-content>
                        <table class="content2nd_table">
                            <tr
                                *ngFor="let itm of item.selected_additional_testing_name;let i=index">
                                <td>
                                    <span>{{i+1}}</span>
                                </td>
                                <td>
                                    <span> {{itm}}</span>
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </ng-container>
            </mat-card-content>

          
            <mat-card-content class="content2nd">
                <mat-card-content>
                    <h3> Related Medical Links</h3>
                </mat-card-content>
                <mat-card-content>
                    <table class="content2nd_table">
                        <tr>
                            <td><img src="https://dev.decisiondoc.com/assets/images/icdimg2.svg"
                                    class="fisticsimg" /></td>
                            <td>
                                <a href="{{item.webmd_general_link}}" target="_blank"
                                    matTooltip="Webmd General Link">
                                    <img src="https://dev.decisiondoc.com/assets/images/icd3.png"
                                        class="othericsimg" />
                                </a>
                            </td>
                            <td>
                                <a href="{{item.webmd_specified_link}}" target="_blank"
                                    matTooltip="Webmd Specified Link">
                                    <img src="https://dev.decisiondoc.com/assets/images/icd4.png"
                                        class="othericsimg" />
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img src="https://dev.decisiondoc.com/assets/images/icdimg1.png"
                                    class="fisticsimg" />
                            </td>

                            <td>
                                <a href="{{item.webmd_medlineplus_general_link}}"
                                    target="_blank" matTooltip="Medlineplus General Link">
                                    <img src="https://dev.decisiondoc.com/assets/images/icd3.png"
                                        class="othericsimg" />
                                </a>
                            </td>
                            <td>
                                <a href="{{item.webmd_medlineplus_specific_link}}"
                                    target="_blank" matTooltip="Medlineplus Specified Link">
                                    <img src="https://dev.decisiondoc.com/assets/images/icd4.png"
                                        class="othericsimg" />
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card-content>

        </div>
 

    </div>

</div>