<div class="signPageDialogBody symptomReasonModal">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <ng-container *ngIf="cookieData && cookieData.user_type=='doctor' || cookieData && cookieData.user_type=='sales_person'">
        <h2>Order Notes!</h2>
        <p>Please add all the medical necessities or symptoms for ordering this diagnosis.</p>
        <form (ngSubmit)="addSymptomReasonMessage()" autocomplete="off" name="symptomReasonForm" [formGroup]="symptomReasonForm">
            <ng-container *ngIf="multiformFlag">
                <div class="allReasonsDiv">
                    <mat-form-field *ngFor="let val of data; let i=index">
                        <!-- <p>{{val.diagnostic_name}}</p> -->
                        <mat-label>Symptom reasons for {{val.diagnostic_name}}</mat-label>
                        <textarea matInput row="6" name="reason" formControlName="reason" required [(ngModel)]="resonValArray[i].notes_by_doctor" [ngModelOptions]="{standalone: true}"></textarea>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="!multiformFlag">
                <mat-form-field>
                    <mat-label>Symptom reasons</mat-label>
                    <textarea matInput row="6" name="reason" formControlName="reason" required></textarea>
                </mat-form-field>

                <mat-error *ngIf="!symptomReasonForm.controls['reason'].valid && symptomReasonForm.controls['reason'].touched">
                    <span class="errortext">This Field is Required</span>
                </mat-error>
            </ng-container>
            <div class="signmodalgroup">
                <button type="submit" mat-raised-button color="primary">{{data.notes_by_doctor?'Update':'Submit'}}</button>
                <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="cookieData && cookieData.user_type !=='doctor' && cookieData.user_type !=='sales_person'">
        <h2>Notes</h2>
        <div>
            <h4 *ngIf="data.doctor_name"><b>Doctor Name :</b> {{data.doctor_name}}</h4>
            <h4><b>*Notes :</b> {{data.notes_by_doctor}}</h4>
        </div>
    </ng-container>
    <ng-container *ngIf="addsignLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

</div>