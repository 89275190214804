import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../../usermanagement/user-list/user-list.component';
@Component({
  selector: 'app-cpt-list',
  templateUrl: './cpt-list.component.html',
  styleUrls: ['./cpt-list.component.css']
})
 
export class CPTListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/cptcodestatus";
  deleteendpoint = "intake/deletecptcode";
  datacollection: any = "intake/cptcodelist";
  public listprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/cptcodelist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    cpt_code: "CPT Code",
    price: "price",
    description: "Description",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status: "Status"
  };
  search_settings: any = {


    search: [{
      label: "Search By CPT Code", field: 'cpt_code',
      serversearchdata: { endpoint: 'intake/cptcodeautocomplete' },
    },
    ],

    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: [  "createdon_datetime", "updatedon_datetime", "status", "usertype"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Assay',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "cpt_code", val: "CPT Code" },
      { key: "price", val: "Price" },
      { key: "description", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "status", val: 'Status' }



    ],
    updateendpoint: "intake/cptcodestatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/cptcodestatus",

    deleteendpointmany: "intake/deletecptcode",
    tableheaders: ["cpt_code", "price", "description", "status", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewcptcode',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['cpt_code', 'price','description', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },

      //     {
      //       label: "Preview",
      //     type: 'action',
      //     datatype: 'local',
      //       datafields: ['assay_name', 'assay_des', 'status', 'createdon_datetime',  'updatedon_datetime'],
      //     headermessage: 'Local Info',
      //     // cond:'status',
      //     // condval:0
      //       classname: 'previewButton'
      // },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {



    this.progressLoader = true;
    this.userListFetching();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;

        case 'laboratory':
          this.libdata.basecondition = { addedby: this.cookieData._id }
          break;

      }
    }
    console.log("cookieService", this.cookieData)


  }
 
addBtn() { this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-cpt`); }
  
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}


    }

    if (this.cookieData.user_type == "laboratory") {
      data.searchcondition = {

        addedby: this.cookieData._id,
      }

    }



    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })

    this.httpService.httpViaPost('intake/cptcodelist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }



  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-cpt`);



  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults.status == 'success') {
        console.log('statusupdate>>>>', data);
        this.taxonomy_updatetable = !this.taxonomy_updatetable;

      }

    }
    if (data.action == "multiplestatusupdate") {
      console.log('statusupdate>>>>', data);
      this.taxonomy_updatetable = !this.taxonomy_updatetable;
    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/edit-cpt/${item._id}`);


    }
  }
}