<mat-card-content class="loginPage">
    <mat-card class="bg_block1"></mat-card>
    <mat-card class="bg_block2"></mat-card>
    <mat-card class="bg_block3"></mat-card>
    <mat-card class="loginPageBody">
        <!-- <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png" alt="practice2lab_logo"> -->
        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/repid_respose_virtual_payment.webp" alt="practice2lab_logo">

        <h2>{{updatePasswordPage?"Change":"Forgot"}} Password</h2>
        <ng-container *ngIf="updatePasswordPage">
            <form (ngSubmit)="changePassword()" (keydown)="keyDownChangePassword($event)" autocomplete="off" name="updatePasswordForm" [formGroup]="updatePasswordForm">
                <mat-card-content class="input_field_wrapper">
                    <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="passwordHide ? 'password' : 'text'" name="password" formControlName="password" required>
                        <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                            <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <ng-container *ngIf="!updatePasswordForm.controls['password'].valid && updatePasswordForm.controls['password'].touched && (updatePasswordForm.controls['password'].errors && !updatePasswordForm.controls['password'].errors['pattern'])">
                        <span class="error"> Password is Required</span>
                    </ng-container>
                    <ng-container *ngIf="updatePasswordForm.controls['password'].errors && updatePasswordForm.controls['password'].errors['pattern'] && updatePasswordForm.controls['password'].touched">
                        <span class="error"> Password must be Minimum 8 characters</span>
                    </ng-container>
                </mat-card-content>
                <mat-card-content class="input_field_wrapper">
                    <mat-form-field appearance="fill">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput [type]="confirmPasswordHide ? 'password' : 'text'" name="confirm_password" formControlName="confirm_password" required>
                        <button mat-icon-button matSuffix (click)="confirmPasswordHide = !confirmPasswordHide;$event.preventDefault()" [attr.aria-label]="'Hide Confirm password'" [attr.aria-pressed]="confirmPasswordHide">
                            <mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <ng-container *ngIf="!updatePasswordForm.controls['confirm_password'].valid && updatePasswordForm.controls['confirm_password'].touched && updatePasswordForm.controls['confirm_password'].value == ''">
                        <span class="error"> Confirm Password is Required</span>
                    </ng-container>
                    <ng-container *ngIf="updatePasswordForm.controls['confirm_password'].touched && updatePasswordForm.controls['confirm_password'].value !== '' && (updatePasswordForm.controls['confirm_password'].value !== updatePasswordForm.controls['password'].value)">
                        <span class="error"> Confirm Password must be matched with Password</span>
                    </ng-container>
                </mat-card-content>
                <button mat-button type="submit">Submit</button>
            </form>
        </ng-container>
        <ng-container *ngIf="!updatePasswordPage">
            <!-- <div *ngIf="forgotEmailErrorMessage">{{forgotEmailErrorMessage}}</div> -->
            <form (ngSubmit)="forgot()" (keydown)="keyDownForgot($event)" autocomplete="off" name="forgotForm" [formGroup]="forgotForm">
                <span class="messageShow_flag"> <mat-error
                        *ngIf="forgotEmailErrorMessage">{{forgotEmailErrorMessage}}</mat-error></span>
                <mat-card-content class="input_field_wrapper">
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" name="email" formControlName="email" required>
                    </mat-form-field>
                    <ng-container *ngIf="!forgotForm.controls['email'].valid && forgotForm.controls['email'].touched && forgotForm.controls['email'].value==''">
                        <span class="error">Email Address is Required.</span>
                    </ng-container>
                    <ng-container *ngIf="forgotForm.controls['email'].value!=='' && forgotForm.controls['email'].errors">
                        <span class="error"> Email not valid.</span>
                    </ng-container>
                </mat-card-content>
                <a href="/login" class="link">Login</a>
                <button mat-button type="submit">Submit</button>
            </form>
        </ng-container>
        <ng-container *ngIf="progressLoader">
            <br />
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
    </mat-card>

</mat-card-content>