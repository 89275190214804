import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Location } from "@angular/common";



@Component({
  selector: 'app-addedit-admin',
  templateUrl: './addedit-admin.component.html',
  styleUrls: ['./addedit-admin.component.css']
})
export class AddeditAdminComponent implements OnInit {
  hide = true;
  hidec = true;
  public addEditAdminForm: FormGroup;
  public stateList: any = [];
  public isedit: boolean = false;
  public edited = true;
  public editdata: any = {};

  public addFormLoader: boolean = false;

  public cookieData: any = {};
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  //  phonepattern = /[0-9\+\-\ ]/;

  constructor(private location: Location, private http: HttpServiceService, private activateRoute: ActivatedRoute, private httpService: HttpServiceService, private _snackBar: MatSnackBar, private router: Router, public matSnackBar: MatSnackBar, public cookieService: CookieService ) { 
this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
  if (!window.location.pathname.includes(`${this.cookieData.user_type}/user-management`)) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
    }

  }



  ngOnInit() {

    console.log("event", this.activateRoute);

    // form calection

 

    this.getStates()

    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.isedit = true;
      this.addEditAdminForm = new FormGroup({
        firstname: new FormControl(''),
        lastname: new FormControl(''),
        email: new FormControl('', Validators.pattern(`^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$`)),
        type: new FormControl(''),
        address: new FormControl(''),
        state: new FormControl(''),
        city: new FormControl(''),
        zip: new FormControl(''),
        phone: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),
        fax: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),
        status: new FormControl(''),
       
      });
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.editdata = response.data.results.res[0]
            this.userListFetching(this.editdata)
          } else {
            this.editdata = response.results.res[0]
            this.userListFetching(this.editdata)
          }
        }
      })


    } else {
  
      this.addEditAdminForm = new FormGroup({
        
        firstname: new FormControl(''),
        lastname: new FormControl(''),
        email: new FormControl('', Validators.pattern(`^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$`)),
        type: new FormControl(''),
        address: new FormControl(''),
        state: new FormControl(''),
        city: new FormControl(''),
        zip: new FormControl(''),
        phone: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),
        fax: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),
        password: new FormControl('',Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)),
        confirmpassword: new FormControl(''),
        status: new FormControl(''),
        // _id: new FormControl('',  this.cookieData._id),
      });
    }
// 

  }


//  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  // state json file
  getStates() {
    this.http.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      this.stateList = response;
    })
  }


  phoneFormatting(event: any) {
    // console.log("event", this.addEditAdminForm.controls);
    var formatted = this.formatPhoneText(event.target.value);
    // console.log("event formatted", formatted);
    this.addEditAdminForm.patchValue({
      phone: formatted
    })
  }
  formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  }


  faxFormatting(event: any) {
    console.log("event", this.addEditAdminForm);
    var formatted = this.formatPhoneText(event.target.value);
    console.log("event formatted", formatted);
    this.addEditAdminForm.patchValue({
      fax: formatted
    })
  }
  // formatfaxText(value) {
  //   value = value.trim().replaceAll("-", "");
  //   value = value.trim().replaceAll("(", "");
  //   value = value.trim().replaceAll(")", "");
  //   value = value.trim().replaceAll(" ", "");

  //   if (value.length > 3 && value.length <= 6)
  //     value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
  //   else if (value.length > 6)
  //     value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

  //   return value;
  // }




  // form add endpoint call

  addEditAdmin() {

    console.log(">>>>>>>>", this.addEditAdminForm)
    this.addEditAdminForm.markAllAsTouched();
    if (this.addEditAdminForm.valid) {
      let dataobj = {
        data: this.addEditAdminForm.value
      }
      if (this.isedit) {
        dataobj.data._id = this.editdata._id
      }
      dataobj.data.user_type = 'admin';
      dataobj.data.added_by = this.cookieData._id;

      console.log('dataobj', dataobj)

      this.addFormLoader=true;

      this.http.httpViaPost('users/addUpdateData', dataobj).subscribe((response) => {

        console.log('response', response)

        if (response && response.status == 'success') {

          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          // console.log("this.location", this.location);
          this.location.back();
          // this.router.navigateByUrl('admin/user-management/users');

          this.addFormLoader=false;
        }

        if (response && response.status == "error") {

          this.matSnackBar.open(response.message, "", {
            duration: 1000
            
          });
          this.addFormLoader=false;
          
        }


      })
    }

  }

  resetForm() {
    this.addEditAdminForm.reset();

  }
  backPage() {
    // this.router.navigateByUrl("admin/user-management/users")
    this.location.back()
  }


  // form calection [edit data fatch]

  userListFetching(itemDetails?: any) {
    // console.log(itemDetails)
    this.addEditAdminForm.patchValue({
      firstname: itemDetails.firstname,
      lastname: itemDetails.lastname,
      email: itemDetails.email,
      address: itemDetails.address,
      state: itemDetails.state,
      city: itemDetails.city,
      zip: itemDetails.zip,
      phone: itemDetails.phone,
      fax: itemDetails.fax,
      status: itemDetails.status,
    });

  }


}
