<div class="unique_form">
    <h4 *ngIf="Uniquemessage.status=='error'" class="errortitle">Uniquecode already used, Please Try Others .</h4>
<form (ngSubmit)="addEditAdmin()" autocomplete="off" name="addEditUniqueCodeForm" [formGroup]="addEditUniqueCodeForm"
    class="addEditUniqueCodeForm">
    <mat-form-field appearance="fill" class="inputBlock2line">
        <mat-label>Uniquecode</mat-label>
        <input matInput placeholder="Uniquecode" name="uniquecode" formControlName="uniquecode" required (keydown)="onKeydownMain($event)">
        <ng-container
            *ngIf="!addEditUniqueCodeForm.controls['uniquecode'].valid && addEditUniqueCodeForm.controls['uniquecode'].touched">
            <span class="error"> Uniquecode is Required.</span>
        </ng-container>
    </mat-form-field>

    <!-- <button mat-button type="submit">{{isedit?'Update':'Submit'}} </button> -->
    <button mat-button type="submit">Submit</button>
</form>
<button class="close_button">
    <mat-icon style="cursor: pointer;" (click)="onNoClick()"> Cancel</mat-icon>
</button>
<mat-progress-bar [ngClass]="progressBar==true? 'show':'hide' " mode="indeterminate"></mat-progress-bar>