import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-bodymetrics',
  templateUrl: './bodymetrics.component.html',
  styleUrls: ['./bodymetrics.component.css']
})
 


export class BodymericsComponent {

  public BodyMetricsDataset:any;

 

  @Input() set bodyMetrics(value: any) {

    if (value) {
      console.log("BodyMetricsDataset", value)
      this.BodyMetricsDataset=value;
 
    }


  }

  constructor(private httpService: HttpServiceService,){

   

  }

 

  

}
