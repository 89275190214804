<mat-card class="adminBody diagnostic_listwrapper VirtualPlatformAccess">
    <button mat-button (click)="modalClose('close')" class="closealert"> <mat-icon>close</mat-icon></button>
    <mat-card-content class="Emailtemplatelist list_style1">
        <div class="admin-list_v1">
            <h2>Requests for Rapid Response Virtual Platform Access</h2>
            <div [ngClass]="listprogressBar==true? 'hide':'show' ">
                <mat-progress-bar mode="indeterminate" *ngIf="isPendingListLoader"></mat-progress-bar>
                <lib-listing class="adminlisttable_v1 DiagnosticCategory " *ngIf="tabledatatalist != null && tabledatatalist.length > 0" [datasource]="tabledatatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_rapid_response"
                    [deleteendpoint]="deleteendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                    [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                    [updatetable]="daignosis_updatetable" [limitcond]="limitcond" [customlistenbutton]="customlistenbutton" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-listing>
                <ng-container *ngIf="tabledatatalist.length == 0">
                    <div class="allAcceptedMsg">You have accept all pending requests for rapid response virtual platform access</div>
                </ng-container>
            </div>
        </div>
    </mat-card-content>
    <mat-card>