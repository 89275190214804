import { Component, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Observable, Subscription, debounceTime, throttleTime, fromEvent, distinctUntilChanged, Subject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-ddiagnostic-list',
  templateUrl: './ddiagnostic-list.component.html',
  styleUrls: ['./ddiagnostic-list.component.css']
})


export class DdiagnosticListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "diagnostic_priority"];
  public tabledatatalist: []
  public listprogressBar: any = false;
  public modalrogressBar: any = false;


  tablename = "diagnostic";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deletediagnostic";
  datacollection: any = "intake/diagnosticlist";

  searchendpoint = "intake/diagnosticlist";
  date_search_endpoint: any = "intake/diagnosticlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    diagnostic_name: "Name",
    diagnostic_des: "Description",
    assay_name: "Assay Name",
    diagnostic_category_name: "Diagnosis Category",
    equipment_name: "Equipment Name",
    diagnostic_priority: "Diagnostic Priority",
    is_private: "Access",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [

      {
        label: "Search By Name ", field: 'diagnostic_name',

        serversearchdata: { endpoint: 'intake/diagnostic-autocomplete' },

      },

      {
        label: "Search By Category Name ", field: 'diagnostic_category_id',

        serversearchdata: { endpoint: 'intake/diagnosticcatautocomplete' },

      },
      {
        label: "Search By Equipment Name ", field: 'equipment_id',

        serversearchdata: { endpoint: 'intake/equipmentautocomplete' },

      },

      {
        label: "Search By Assay Name ", field: 'assay_id',

        serversearchdata: { endpoint: 'intake/assayautocomplete' },

      },

    ],



    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [

      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["diagnostic_name", "updatedon_datetime", "diagnostic_category_name", "status"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Mark as unavailable',
      //   type: 'button',
      //   name: 'remove_test',
      //   tooltip: 'Mark as unavailable',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // }
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "diagnostic_name", val: "Name" },
      { key: "diagnostic_des", val: "Description" },
      { key: "assay_name", val: "Assay Name" },
      { key: "diagnostic_category_name", val: "Diagnostic Category" },
      // { key: "equipment_name", val: "Equipment Name" },
      { key: 'is_private', val: "Is Private" },
      { key: "status", val: "Status" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "diagnostic_priority", val: "Diagnostic Priority" },







    ],
    updateendpoint: "intake/diagnosticstatus",
    hidedeletebutton: false,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/diagnosticstatus",

    deleteendpointmany: "intake/deletediagnostic",
    tableheaders: ["diagnostic_name", "assay_name", "diagnostic_category_name", "equipment_name", "is_private", "updatedon_datetime", "status"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewdiagnostic',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['diagnostic_category_name', 'diagnostic_des', 'diagnostic_name', "diagnostic_priority", 'reference', "access", 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },


      {
        label: "Map Symptoms",
        type: 'listner',
        id: 'map_symptoms',
        tooltip: 'Map Symptoms',
        name: 'map_symptoms',
        classname: 'map_symptoms'
      },

      {
        label: "Related Diagnosis",
        type: 'listner',
        id: 'related_diagnosis',
        tooltip: 'Related Diagnosis',
        name: 'related_diagnosis',
        classname: 'related_diagnosis'
      },


    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;
  public daignosis_updatetable: boolean = false;
  public chooseDiagnosisLoader: boolean = false;
  public isPendingListFlag: boolean = false;

  constructor(public dialog: MatDialog, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public matSnackBar: MatSnackBar, private metaService: Meta, private titleService: Title,) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous') && !window.location.pathname.includes('laboratory/diagnostic-list')) {
      this.router.navigateByUrl('404');
    }
    if (window.location.pathname.includes('laboratory/diagnostic-list')) {
      // this.libdata.hideaction = true
    }
    if (window.location.pathname.includes('admin/miscellaneous/pending-diagnostic-list')) {
      this.libdata.basecondition = { isNew: true }
      this.libdata.hidedeletebutton = true;
      this.libdata.hidestatustogglebutton = true;
      this.isPendingListFlag = true;
      this.libdata.custombuttons.push({

        label: "Approve Test",
        type: 'listner',
        id: 'approve_diagnosis',
        tooltip: 'Approve Test',
        name: 'approve_diagnosis',
        classname: 'approve_diagnosis'

      })
    }

  }

  ngOnInit() {
    this.titleService.setTitle(`${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test`);
    this.metaService.addTags([
      { name: 'keywords', content: `${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test` },
      { name: 'description', content: `${this.cookieData.user_type}  Available List` },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.progressLoader = true;
    this.userListFetching();
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute responseff", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        console.log("this.tabledatatalist", this.tabledatatalist)
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
        if (this.tabledatatalist.length == 0) {

          // console.log("no record")

          setTimeout(() => {
            this.adduserBTN = true

            let myRow = document.querySelector('.adminBody');
            myRow.classList.add('libListNoDataCSS');
          }, 200);


        }
      }
    })


    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {

        case 'laboratory':
          this.libdata.basecondition = { selected_lab: this.cookieData._id }
          break;

      }
    }

    if (this.cookieData.user_type == 'laboratory') {
      // this.libdata.hideaction = true;
      this.libdata.custombuttons = [
        // {
        //   label: "Edit",
        //   type: 'listner',
        //   id: 'edit_btn',
        //   tooltip: 'Edit',
        //   name: 'edit',
        //   classname: 'edit_btn',
        //   cond: 'added_by',
        //   condval: this.cookieData.laboratory_name,
        // },
        // {
        //   label: "Preview",
        //   type: 'action',
        //   datatype: 'api',
        //   endpoint: 'intake/previewdiagnostic',
        //   otherparam: [],
        //   cond: 'added_by',
        //   condval: this.cookieData.laboratory_name,
        //   param: '_id',
        //   datafields: ['diagnostic_category_name', 'diagnostic_des', 'diagnostic_name', "diagnostic_priority", 'reference', "access", 'createdon_datetime', 'updatedon_datetime', 'status'],
        //   //  refreshdata: true,
        //   headermessage: 'Preview',
        //   tooltip: 'Preview',
        //   classname: 'previewButton'
        // },
        // {
        //   label: "Delete",
        //   type: 'listner',
        //   id: 'delete_diagnosis_btn',
        //   tooltip: 'Delete',
        //   name: 'delete',
        //   classname: 'delete_btn',
        //   cond: 'added_by',
        //   condval: this.cookieData.laboratory_name,
        // },
        {
          label: "Mark as unavailable",
          type: 'listner',
          id: 'remove_test',
          tooltip: 'Mark as unavailable',
          name: 'delete',
          classname: 'delete_btn'
        },
      ];
      this.libdata.hideviewbutton = true
      this.libdata.hidedeletebutton = true
      this.libdata.hidestatustogglebutton = true;
      this.libdata.hidemultipleselectbutton = true;
    }

  }

  addBtn() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-diagnostic`);


  }
  manageCategoryAdd() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-category-list`);

  }

  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    if (this.cookieData.user_type == "laboratory") {
      data.searchcondition = {
        selected_lab: this.cookieData._id,
      }
    }
    if (this.isPendingListFlag) {
      data.searchcondition = { 'isNew': true }
    }
    this.httpService.httpViaPost('intake/diagnosticlist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }
    })
  }

  chooseDaignosis() {
    this.chooseDiagnosisLoader = true;
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        selected_lab: { $ne: this.cookieData._id }
      }
    }
    // if (this.cookieData.user_type == "laboratory") {
    //   data.searchcondition = {
    //     addedby: this.cookieData._id,
    //   }
    // }
    this.httpService.httpViaPost('intake/diagnosticlist', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        // this.tabledatatalist = response.results.res;
        this.chooseDiagnosisLoader = false;
        const dialogRef = this.dialog.open(ExistingDiagnosisList, {
          panelClass: ["symptoms-modal-class", "labDiagnosisModal"],
          data: { existingList: response.results.res }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          // if (result) {
          this.daignosis_updatetable = !this.daignosis_updatetable;
          // }
        })
      }
    })
    // this.httpService.httpViaPost('intake/diagnosticlist-count', data).subscribe((response: any) => {
    //   // console.log(response)
    //   if (response) {
    //     this.date_search_source_count = response.count;
    //   }
    // })


  }
  addLabDiagnosis() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/add-diagnostic`);
  }


  listenLiblistingChange(data: any = null) {
    // console.log("listenLiblistingChange", data);
    // console.log("listenLiblistingChange", data.custombuttonclick.data._id);
    // practice/intake-form-preview/view
    console.log("Op=====>1", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == "map_symptoms") {
        const dialogRef = this.dialog.open(SelectSymptomsDialog, {
          panelClass: "symptoms-modal-class",
          data: { modalData: data.custombuttonclick.data._id }
        });
      }

      if (data.custombuttonclick.btninfo.id == "remove_test") {
        let dataset = {
          "_id": data.custombuttonclick.data._id,
          "lab_id": this.cookieData._id
        }

        console.warn('DataSet>>>', dataset);

        this.httpService.httpViaPost('intake/removeselectedtest', dataset).subscribe((response: any) => {
          // console.log(response)
          if (response) {
            this.daignosis_updatetable = !this.daignosis_updatetable;
          }
        })

      }

      if (data.custombuttonclick.btninfo.id == "related_diagnosis") {
        const dialogRef = this.dialog.open(relatedDiagnosisModal, {
          panelClass: "symptoms-modal-class",
          data: { modalDatasymptoms: data.custombuttonclick.data._id }

        });


      }

      if (data.custombuttonclick.btninfo.id == "approve_diagnosis") {
        this.approveTest([data.custombuttonclick.data._id]);
      }
    }
    if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          if (this.cookieData.user_type == 'laboratory') {
            this.router.navigateByUrl(`${this.cookieData.user_type}/edit-diagnostic/${data.custombuttonclick.data._id}`);
          } else {
            this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/edit-diagnostic/${data.custombuttonclick.data._id}`);
          }
          break;
        case "Delete":
          this.deleteListItem(data.custombuttonclick.data)
          break;
        default: null
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
  }
  approveTest(value) {
    let data = {
      ids: value
    }
    this.httpService.httpViaPost('intake/approvediagnosis', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.daignosis_updatetable = !this.daignosis_updatetable;
      }
    })
  }

  deleteListItem(item: any) {
    console.log("delete item", item)
    // let data: any = {
    //   "source": "diagnostic", "id": item._id, "secret": "na", "token": ""
    // };

    const dialogRef = this.dialog.open(LabDiagnosisDeleteModal, {
      panelClass: [],
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.daignosis_updatetable = !this.daignosis_updatetable;
        // this.httpService.httpViaPost('intake/deletediagnostic', data).subscribe((response: any) => {
        //   if (response) {
        //     this.matSnackBar.open("Symptoms Maping Successfully", "Ok", {
        //       duration: 1000
        //     });
        //   }
        // })
      }
    })

  }
  // addNewCategory() {

  //   // this.router.navigateByUrl('admin/miscellaneous/addEdit-diagnostic-category')
  // }

  onLiblistingButtonChange(val: any) {
    console.log("onLiblistingButtonChange val", val)
    if (val.action == "customlistenbutton") {
      if (val.buttondata.name == 'add_category') {
        this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-category-list`)
      }

      if (val.buttondata.name == 'add_diagnostic') {
        this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-diagnostic`)


      }
    }

  }
  listenFormFieldChange(val: any) {


  }






}



export interface DialogData {
  data: any;
  flag: any;
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'select-symptoms-modal.html',
})
// import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

export class SelectSymptomsDialog {
  public modalSymptomsData: any;
  userNameModalChange = new Subject<string>();
  public nameprogressFlag: boolean = false;
  nameselectedData: any = [];
  nameArr: any = [];
  public cookieData: any = {};
  public userId: any;
  public modalrogressBar: any = false;

  public Url: any;
  // public signupLinkpac: any;
  public env = environment;
  public diagnosticId: any = null;
  public symptomsName: string = null;
  constructor(
    public dialogRef: MatDialogRef<SelectSymptomsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
    public apiservice: HttpServiceService,
  ) {
    console.log('DDD>>', data);
    this.diagnosticId = data.modalData

    this.modalSymptomsData = data.searchItem;
    console.log("env++++", this.modalSymptomsData);

  }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.modalSymptomsData) {
      if (this.modalSymptomsData.nameArr && this.modalSymptomsData.nameArr.length > 0) {
        this.modalSymptomsData.nameArr.forEach((e: any, i: number) => {
          this.namechooseChipItem(e, i, 'patch');
        })
      }
    }


    this.userNameModalChange.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.nameprogressFlag = true;
        if (value && value !== '') {
          let data: any = {
            "search_str": value,
            "sort": { "field": "_id", "type": "desc" },
            "allSearchCond": [],
            "basecondition": {
              // user_type: { $in: [this.userType] }
            },
            "datasearch": {},
            "textsearch": {},
            "buttonSearch": {},
            "selectsearch": {},
            "secret": "na",
            "token": ""
          }
          this.apiservice.httpViaPost('intake/symptomautocomplete', data).subscribe((response) => {
            // console.log("The dialog userNameAutocomplete response", response)
            this.nameprogressFlag = false;
            if (response) {
              this.nameArr = response.res
            }
          })
        }
      });


    let data: any = {

      "searchcondition": {
        _id: this.diagnosticId
      },

    }
    this.modalrogressBar = true
    this.apiservice.httpViaPost('intake/getmapsymptom', data).subscribe((response) => {
      console.log("The dialog userNameAutocomplete response", response.results.res[0].symptoms_val)
      // this.nameprogressFlag = false;          
      if (response) {
        if (response.status == "success") {
          this.modalrogressBar = false
          console.log("modalrogressBar", this.modalrogressBar);

          if (response.results.res[0].symptoms_val) {
            this.nameselectedData = response.results.res[0].symptoms_val
          }

          console.log(this.nameselectedData, "this.nameselectedData");
        }

      }


    })





    console.log("DialogData", this.cookieData);
    console.log("emailintakeData>>>", this.diagnosticId);


  }

  namechooseChipItem(item: any, i: number, type?: string) {
    console.log("nameselectedData", item);

    if (this.nameselectedData && this.nameselectedData.length > 0) {
      this.nameselectedData = this.nameselectedData.filter((e) => {
        return e._id !== item._id
      });
      console.log("nameselectedData", this.nameselectedData);
    }
    this.nameselectedData.push(item);


    // if (!type) {
    //   this.modalSymptomsData.fullname = "";
    // }
    this.symptomsName = ''
    // console.log("symptomsName", this.symptomsName);

  }

  remove(value: any, index: any, flag: any) {
    this.nameselectedData.splice(index, 1);
  }

  closeModal(): void {
    this.dialogRef.close();
    // this.router.navigateByUrl(this.cookieData.user_type + `/email-intake-list`);
  }
  onNoClick() {
    let symptomData = []

    console.log("listenFormFieldChangeval,", this.nameselectedData, this.diagnosticId)
    for (const key in this.nameselectedData) {
      symptomData.push({ key: this.nameselectedData[key].key, name: this.nameselectedData[key].name })
      console.log("nameselectedData", symptomData);
    }
    console.log("coo", this.cookieData);



    let data = {
      symptoms_value: symptomData,
      _id: this.diagnosticId,
      status: 1
    }




    this.apiservice.httpViaPost('intake/adddiagnostic', data).subscribe((response) => {
      // console.log("The dialog userNameAutocomplete response", response)
      this.nameprogressFlag = false;
      if (response) {
        this.nameArr = response.res
      }
      if (response.status == "success") {
        this.matSnackBar.open("Symptoms Maping Successfully", "Ok", {
          duration: 1000
        });
        this.dialogRef.close();
      }
    })


    // this.dialogRef.close();
  }
}





@Component({
  selector: 'related-diagnosis-modal',
  templateUrl: 'related-diagnosis-modal.html',
})
export class relatedDiagnosisModal {

  public relateddiagnosisId: any = null;
  public modalrogressBar: any = false;

  userNameModalChange = new Subject<string>();

  public nameprogressFlag: boolean = false;
  diagnosticArr: any = [];

  public DiagnostName: string = null;

  public cookieData: any = {};

  public modalDiagnostData: any;

  DiagnostselectedData: any = [];

  constructor(
    public dialogRef: MatDialogRef<relatedDiagnosisModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
    public apiservice: HttpServiceService,
  ) {

    this.relateddiagnosisId = this.data.modalDatasymptoms
  }

  ngOnInit() {

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.modalDiagnostData) {
      if (this.modalDiagnostData.diagnosticArr && this.modalDiagnostData.diagnosticArr.length > 0) {
        this.modalDiagnostData.diagnosticArr.forEach((e: any, i: number) => {
          this.namechooseChipItem(e, i, 'patch');
        })
      }
    }



    this.userNameModalChange.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.nameprogressFlag = true;
        if (value && value !== '') {
          let data: any = {
            "search_str": value,
            "sort": { "field": "_id", "type": "desc" },
            "allSearchCond": [],
            "basecondition": {
              // user_type: { $in: [this.userType] }
            },
            "datasearch": {},
            "textsearch": {},
            "buttonSearch": {},
            "selectsearch": {},
            "secret": "na",
            "token": ""
          }
          this.apiservice.httpViaPost('intake/diagnostic-autocomplete', data).subscribe((response) => {
            // console.log("The dialog userNameAutocomplete response", response)
            this.nameprogressFlag = false;
            if (response) {
              this.diagnosticArr = response.res
            }
          })
        }
      });



    let data: any = {

      "searchcondition": {
        _id: this.relateddiagnosisId
      },

    }



    this.apiservice.httpViaPost('intake/getrelateddiagnosis', data).subscribe((response) => {


      if (response) {
        if (response.status == "success") {

          console.log(response.results.res, "response+++++");


          if (response.results.res[0].related_diagnosis) {
            this.DiagnostselectedData = response.results.res[0].related_diagnosis
          }

          console.log(this.DiagnostselectedData, "this.DiagnostselectedData");



        }

      }


    })


  }

  namechooseChipItem(item: any, i: number, type?: string) {
    console.log("DiagnostselectedData", item);

    if (this.DiagnostselectedData && this.DiagnostselectedData.length > 0) {
      this.DiagnostselectedData = this.DiagnostselectedData.filter((e) => {
        return e._id !== item._id
      });
      console.log("DiagnostselectedData", this.DiagnostselectedData);
    }
    this.DiagnostselectedData.push(item);


    // if (!type) {
    //   this.modalSymptomsData.fullname = "";
    // }
    this.DiagnostName = ''
    // console.log("DiagnostName", this.DiagnostName);

  }

  remove(value: any, index: any, flag: any) {
    this.DiagnostselectedData.splice(index, 1);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();


    let diagData = []

    for (const key in this.DiagnostselectedData) {
      diagData.push(this.DiagnostselectedData[key].key)
      console.log("nameselectedData", diagData);
    }




    let data = {
      related_diagnosis: diagData,
      _id: this.relateddiagnosisId,

    }


    this.apiservice.httpViaPost('intake/adddiagnostic', data).subscribe((response) => {
      // console.log("The dialog userNameAutocomplete response", response)
      this.nameprogressFlag = false;
      if (response) {
        this.diagnosticArr = response.res
      }
      if (response.status == "success") {
        this.matSnackBar.open("Diagnostic Related Successfully", "Ok", {
          duration: 1000
        });
        this.dialogRef.close();
      }
    })


  }
}



@Component({
  selector: 'app-existing-diagonis-list',
  templateUrl: 'existing-diagonis-list.html',
})
// import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

export class ExistingDiagnosisList {

  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "diagnostic_priority"];
  public tabledatatalist: []
  public listprogressBar: any = false;
  public modalrogressBar: any = false;


  tablename = "diagnostic";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deletediagnostic";
  datacollection: any = "intake/diagnosticlist";

  searchendpoint = "intake/diagnosticlist";
  date_search_endpoint: any = "intake/diagnosticlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    diagnostic_name: "Name",
    diagnostic_des: "Description",
    assay_name: "Assay Name",
    diagnostic_category_name: "Diagnosis Category",
    diagnostic_priority: "Diagnostic Priority",
    equipment_name: "Equipment Name",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [
      {
        label: "Search By Name ", field: 'diagnostic_name',
        serversearchdata: { endpoint: 'intake/diagnostic-autocomplete' },
      },
      {
        label: "Search By Category Name ", field: 'diagnostic_category_id',
        serversearchdata: { endpoint: 'intake/diagnosticcatautocomplete' },
      },
      {
        label: "Search By Equipment Name ", field: 'equipment_id',

        serversearchdata: { endpoint: 'intake/equipmentautocomplete' },

      },
      {
        label: "Search By Assay Name ", field: 'assay_id',
        serversearchdata: { endpoint: 'intake/assayautocomplete' },
      },
    ],
    // datesearch: [
    //   {
    //     startdatelabel: 'Update On Start Date',
    //     enddatelabel: 'Update On End Date',
    //     submit: 'Search',
    //     field: 'updatedon_datetime',
    //     // value: {$gte: createdon_datetime, $lte: 1622962799000}
    //   },
    // ],
    // selectsearch: [

    //   { label: 'Search By Status', field: 'status', values: this.statusarray },

    // ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["diagnostic_name", "updatedon_datetime", "diagnostic_category_name", "is_private", "status"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "diagnostic_name", val: "Name" },
      { key: "diagnostic_des", val: "Description" },
      { key: "assay_name", val: "Assay Name" },
      { key: "diagnostic_category_name", val: "Diagnostic Category" },
      // { key: "equipment_name", val: "Equipment Name" },
      // { key: "is_private", val: "Is Private" },
      { key: "status", val: "Status" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "diagnostic_priority", val: "Diagnostic Priority" },

    ],
    updateendpoint: "intake/diagnosticstatus",
    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: true,
    hideaction: true,
    updateendpointmany: "intake/diagnosticstatus",

    deleteendpointmany: "intake/deletediagnostic",
    tableheaders: ["diagnostic_name", "assay_name", "diagnostic_category_name", "equipment_name", "status"],

    custombuttons: [
      {
        label: "Choose",
        type: 'listner',
        id: 'chosse_btn',
        tooltip: 'Choose',
        name: 'checkbox',
        classname: 'chosse_btn'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;

  public selectedDiagnosis: any = [];
  public daignosis_updatetable: boolean = false;

  public chooseDiagnosisLoader: boolean = false;

  constructor(public dialogRef: MatDialogRef<ExistingDiagnosisList>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
    public httpService: HttpServiceService,) { }
  ngOnInit() {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

    this.libdata.basecondition = { selected_lab: { $ne: this.cookieData._id } };
    console.log("existingList", this.cookieData)
    if (this.data) {
      if (this.data.existingList) {
        this.tabledatatalist = this.data.existingList;
        console.log("this.tabledatatalist", this.tabledatatalist)
        this.userListFetching()
      }
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  

  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: { selected_lab: { $ne: this.cookieData._id } }
    }
    // if (this.cookieData.user_type == "laboratory") {
    //   data.searchcondition = {
    //     addedby: this.cookieData._id,
    //   }
    // }
    // this.httpService.httpViaPost('intake/diagnosticlist', data).subscribe((response: any) => {
    //   // console.log(response)
    //   if (response) {
    //     this.tabledatatalist = response.results.res;
    //   }
    // })
    this.httpService.httpViaPost('intake/diagnosticlist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
        // Overlay button start
        this.setOverlayButton();
        // console.log("matRow ", matRow)
        // Overlay button end
      }
    })

    // console.log("tabledatatalist test======>>>>>", this.tabledatatalist);
  }

  // reload(){
  //  this.daignosis_updatetable = !this.daignosis_updatetable    
  // }
  
  

  setOverlayButton() {
    let matRow = document.querySelectorAll(".labDiagnosis .tablewrapper .mat-mdc-row");
    let matHead = document.querySelector(".labDiagnosis .mat-mdc-header-row");
    let hiddenButton = document.createElement("button");
    hiddenButton.classList.add('hiddenButton');
    matHead.prepend(hiddenButton);
    console.log("matRow", matRow)
    if (matRow.length > 0) {
      matRow.forEach((e: any, i: number) => {
        let newButton = document.createElement("button");
        this.tabledatatalist.forEach((f: any, index: number) => {
          newButton.addEventListener('click', (el) => {
            if (index == i) {
              this.getRowData(f);
            }
          });
        })
        newButton.classList.add('overlayButton');
        e.prepend(newButton)
      })
    }
    console.log("matRow ", matRow)
  }

  getRowData(row?: any) {
    console.log(row)
    this.submitDiagnosis(row._id);
  }

  public selectedIndexes: any = [];
  listenLiblistingChange(data: any) {
    console.log("listenLiblistingChange", data)
    if (data) {
      if (data.action == "search") {
        if (data.totalresults) {
          if (data.totalresults.results && data.totalresults.results.res) {
            this.tabledatatalist = data.totalresults.results.res;
            setTimeout(() => {
              this.setOverlayButton();
            }, 500);
          }
        }
      }
      // if (data.action == "custombuttonclick") {
      //   // this.submitDiagnosis(data.custombuttonclick.data._id)
      //   let tableRow = document.querySelectorAll('tr');
      //   if (this.selectedDiagnosis.length > 0) {
      //     this.selectedDiagnosis.forEach((f: any, k: number) => {
      //       if (f == data.custombuttonclick.data._id) {
      //         this.selectedDiagnosis = this.selectedDiagnosis.filter((x: any) => {
      //           return x !== data.custombuttonclick.data._id
      //         })
      //       } else {
      //         this.selectedDiagnosis = this.selectedDiagnosis.filter((x: any) => {
      //           return x !== data.custombuttonclick.data._id
      //         })
      //         this.submitDiagnosis(data.custombuttonclick.data._id)
      //         this.selectedDiagnosis.push(data.custombuttonclick.data._id);
      //       }
      //     })
      //   } else {
      //     this.submitDiagnosis(data.custombuttonclick.data._id)
      //     this.selectedDiagnosis.push(data.custombuttonclick.data._id);
      //   }

      //   if (this.tabledatatalist.length > 0) {
      //     this.tabledatatalist.forEach((e: any, i: number) => {
      //       if (e._id == data.custombuttonclick.data._id) {
      //         if (tableRow.length > 0) {
      //           tableRow.forEach((f: any, j: number) => {
      //             tableRow[i + 1].classList.add('selectedRow')
      //           })
      //         }
      //       }
      //     })
      //     this.tabledatatalist.forEach((w: any, o: number) => {
      //       if (w._id == data.custombuttonclick.data._id) {
      //         if (this.selectedIndexes.length > 0) {

      //           this.selectedIndexes.forEach((r: any) => {
      //             if (r == o) {
      //               this.selectedIndexes = this.selectedIndexes.filter((x: any) => {
      //                 return x !== o
      //               })
      //               tableRow[o + 1].classList.remove('selectedRow')
      //             } else {
      //               this.selectedIndexes = this.selectedIndexes.filter((x: any) => {
      //                 return x !== o
      //               })
      //               tableRow[o + 1].classList.remove('selectedRow')
      //               tableRow[o + 1].classList.add('selectedRow')
      //               this.selectedIndexes.push(o);
      //             }
      //           })
      //         } else {
      //           this.selectedIndexes.push(o);
      //         }

      //       }
      //     })
      //   }
      //   // this.submitDiagnosis(data.custombuttonclick.data._id)
      // }
      console.log("listenLiblistingChange", this.selectedDiagnosis)
      // console.log("listenLiblistingChange selectedIndexes", this.selectedIndexes)
    }

  }
  onLiblistingButtonChange(val: any) { }
  listenFormFieldChange(val: any) { }

  submitDiagnosis(diagnosis: any) {
    this.chooseDiagnosisLoader = true;
    console.log("listenLiblistingChange", this.selectedDiagnosis)
    let payloadData: any = {
      data: {
        selected_lab: [this.cookieData._id],
        // is_private: 1,
        // status: 0,
        _id: diagnosis
      }
    }
    this.httpService.httpViaPost('intake/adddiagnostic', payloadData).subscribe((response: any) => {
      console.log(response)
      if (response) {
        this.daignosis_updatetable = !this.daignosis_updatetable;
        setTimeout(() => {
          this.chooseDiagnosisLoader = false;
          this.setOverlayButton();
        }, 2000)
        // this.dialogRef.close('success');
      }
    })

  }
}

@Component({
  selector: 'lab-diagnosis-delete-modal',
  templateUrl: 'lab-diagnosis-delete-modal.html',
})
export class LabDiagnosisDeleteModal {



  public cookieData: any = {};

  public progressBar: boolean = false;;


  constructor(
    public dialogRef: MatDialogRef<LabDiagnosisDeleteModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
    public httpService: HttpServiceService,
  ) {


  }

  ngOnInit() {
    console.log("LabDiagnosisDeleteModal", this.data);
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }

  confirm() {
    this.progressBar = true;
    if (this.data) {
      let data: any = {
        "source": "diagnostic", "id": this.data._id, "secret": "na", "token": ""
      };
      this.httpService.httpViaPost('intake/deletediagnostic', data).subscribe((response: any) => {
        if (response) {
          this.progressBar = false;
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.dialogRef.close('yes');
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();


  }
}