import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-ai-upgradation',
  templateUrl: './ai-upgradation.component.html',
  styleUrls: ['./ai-upgradation.component.css']
})
export class AiUpgradationComponent {
  public addFormLoader: boolean = false;
  public cookieData: any = null;

  constructor(public cookieService: CookieService, public router: Router, private httpService: HttpServiceService, public matSnackBar: MatSnackBar) {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );

      if (this.cookieData.user_type !== 'practice') {
        this.router.navigateByUrl('404')
      }
    }
  }
  ngOnInit() { }

  upgradePractice() {
    this.addFormLoader = true;

    let payload: any = {
      data: {
        "_id": this.cookieData._id,
        "accept_ai": 1
      }
    }
    this.httpService.httpViaPost("users/acceptai", payload).subscribe((response: any) => {
      console.log(response)
      if (response) {
        this.addFormLoader = false;
        this.matSnackBar.open(response.message, '', {
          duration: 3000,
        });
        if (response.status === "success") {
          this.cookieData.accept_ai = 1;
          this.cookieService.delete('login_user_details', '/');
          setTimeout(() => {
            this.cookieService.set('login_user_details', JSON.stringify(this.cookieData));
          }, 200);
        }
      }
    })


  }

}
