import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";



@Component({
  selector: 'app-add-edit-email-intake',
  templateUrl: './add-edit-email-intake.component.html',
  styleUrls: ['./add-edit-email-intake.component.css']
})
export class AddEditEmailIntakeComponent {
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public emailintakeData: any = null;
  public cookieData: any = {};
  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public demoDoctorDashboard: boolean = false;
  constructor(private location: Location, public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    if (!window.location.pathname.includes('doctor/add-email-intake') && !window.location.pathname.includes('practice/add-email-intake') && !window.location.pathname.includes('sales_person/doctor')) {
      this.router.navigateByUrl('404')
    }
    if (window.location.pathname.includes('sales_person/doctor')) {
      this.demoDoctorDashboard = true;
    }
  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    } else {
    }
    console.log("editFormData", this.editFormData);

    this.initailForm();
    console.log("this.cookieData", this.cookieData);


  }

  initailForm() {
    if( this.cookieData.user_type == 'practice') {
      this.practiceformdata =
      {
        // from start
        successmessage: 'Added Successfully !!', // success message
        submittext: this.editFormData ? 'Update' : 'Send',
        apiUrl: this.apiservice.baseUrl,
        // endpoint: 'users/addUpdateData',
        resettext: 'Reset',
        canceltext: 'Cancel',
        hidereset: true ,
        jwttoken: '',
        // cancelroute: this.cookieData.user_type + `/add-email-intake`, // use for redirect after cancel the form
        fields: [
  
          {
            label: 'Patient Name',
            name: 'patient_name',
            value: this.editFormData && this.editFormData.patient_name ? this.editFormData.patient_name : '',
            type: 'text',
            classname: 'aaa',
            validations: [
              { rule: 'required', message: 'patient Name is Required' },
  
            ],
          },
          {
            label: 'Patient Email',
            name: 'patient_email',
            value: this.editFormData && this.editFormData.patient_email ? this.editFormData.patient_email : '',
            type: 'email',
            classname: 'aaa',
            validations: [
              { rule: 'required', message: 'Patient Email is Required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
  
            ],
          },
  
          {
            label: "Select Doctor",
            name: "doctor_id",
            type: 'autocomplete',
            endpoint: "users/doctorautocomplete",
            search_field: "name_search",
            val:  [],
            value:  [],
            validations: [
              { rule: 'required', message: 'Doctor Name is Required' }
            ]
  
          },

          {
            label: 'Practice Id',
            name: 'practice_id',
            value: this.cookieData._id,
            type: 'hidden',
            classname: 'aaa',
            // validations: [
            //   { rule: 'required', message: 'patient Name is Required' },
  
            // ],
          },
  
        ]
      }
    }else{
      this.practiceformdata =
      {
        // from start
        successmessage: 'Added Successfully !!', // success message
        submittext: this.editFormData ? 'Update' : 'Send',
        apiUrl: this.apiservice.baseUrl,
        // endpoint: 'users/addUpdateData',
        resettext: 'Reset',
        canceltext: 'Cancel',
        hidereset: true,
        jwttoken: '',
        // cancelroute: this.cookieData.user_type + `/add-email-intake`, // use for redirect after cancel the form
        fields: [
  
          {
            label: 'Patient Name',
            name: 'patient_name',
            value: this.editFormData && this.editFormData.patient_name ? this.editFormData.patient_name : '',
            type: 'text',
            classname: 'aaa',
            validations: [
              { rule: 'required', message: 'patient Name is Required' },
  
            ],
          },
          {
            label: 'Patient Email',
            name: 'patient_email',
            value: this.editFormData && this.editFormData.patient_email ? this.editFormData.patient_email : '',
            type: 'email',
            classname: 'aaa',
            validations: [
              { rule: 'required', message: 'Patient Email is Required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
  
            ],
          },
        ]
      }
    }

  }



  listenFormFieldChange(val: any) {
    console.log("this.cookieData", this.cookieData);


    console.log("listenFormFieldChangeval", val)
    if (val) {
      if (val.field && val.field == "formcancel") {
        this.location.back();
        // console.log("aaaaaaaaa");


      }
      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        // val.fromval.prioriy = Number(val.fromval.prioriy) 
        console.log("val.fromval", val.fromval);

        val.fromval.location = this.externaldatavalue;
        let dataobj = {

          data: val.fromval
        }
        if (this.editFormData) {
          dataobj.data._id = this.editFormData._id
        }
        // form submit and extra data add

        if (this.cookieData.user_type == "doctor") {
          dataobj.data.sender_name = this.cookieData.firstname + ' ' + this.cookieData.lastname
          dataobj.data.practice_id = this.cookieData.practice_id
          dataobj.data.doctor_id = this.cookieData._id
          dataobj.data.parent_laboratory = this.cookieData.parent_laboratory

        }
        else {
          dataobj.data.sender_name = this.cookieData.practice_name;
          dataobj.data.practice_id = this.cookieData._id;
          dataobj.data.parent_laboratory = this.cookieData.parent_laboratory
        }
        dataobj.data.added_by = this.cookieData._id;
        dataobj.data.is_demo = false;
        if (this.demoDoctorDashboard) {
          dataobj.data.sender_name = this.cookieData.firstname + ' ' + this.cookieData.lastname;
          dataobj.data.practice_id = this.cookieData._id;
          dataobj.data.doctor_id = this.cookieData._id;
          dataobj.data.parent_laboratory = this.cookieData.parent_laboratory;
          dataobj.data.is_demo = true;
        }
        // ========
        console.log("dataobj val", dataobj);

        this.apiservice.httpViaPost('email/addemailinatake', dataobj).subscribe((response: any) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            // console.log("link", this.cookieData.user_type + `/email-intake-list`);
            this.emailintakeData = response
            console.log("emailintakeData", this.emailintakeData);

            const dialogRef = this.dialog.open(CopyUrlDialog, {
              data: { modalData: response.results.res, is_demo: this.demoDoctorDashboard }
            });
            // this.router.navigateByUrl(this.cookieData.user_type + `/email-intake-list`);
            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })

      }
      if (val.field && val.field == "formreset") {
        this.formfieldrefreshdata = {
          field: "description",
          value: '',
        };
      }
    }
  }



  // openDialog() {
  // }
  //   closeModal(){
  //   const dialogRef = this.dialog.open(DialogContentExampleDialog);
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }




}



export interface DialogData {
  data: any;
  flag: any;
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'copyurl.html',
})

export class CopyUrlDialog {
  public cookieData: any = {};

  public Url: any;
  // public signupLinkpac: any;
  public env = environment;
  public emailintakeData: any = null;
  public isDemo: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CopyUrlDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
  ) {
    console.log('DDD>>', data);
    this.emailintakeData = data.modalData
    this.isDemo = data.is_demo;
    console.log("env++++", this.env);
    if (this.env.slug == 'development') {
      this.Url = 'https://dev.practice2lab.influxiq.com/email-intake-form/'
    } else {
      this.Url = 'https://practice2lab.influxiq.com/email-intake-form/';
    }
  }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    console.log("DialogData", this.cookieData);
    console.log("emailintakeData>>>", this.emailintakeData);


  }
  copyLink(code) {
    let linkVal: any = ''
    linkVal = this.Url + code;
    const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    selBox.value = linkVal;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let message: any = '" ' + linkVal + ' " ' + " Copied to Clipboard!";
    let action: any = "OK";
    this.matSnackBar.open(message, action, {
      duration: 3000,
    });

  }


  closeModal(): void {
    this.dialogRef.close();
    if (this.isDemo) {
      this.router.navigateByUrl(this.cookieData.user_type + `/doctor/email-intake-list`);
    } else {
      this.router.navigateByUrl(this.cookieData.user_type + `/email-intake-list`);
    }
  }
}
