<!-- <app-header></app-header> -->
<mat-card class="adminBody assay_listwrapper" *ngIf="!addEditFormOpen">
    <mat-card-content class="Emailtemplatelist listingpage_wrapper list_style1">
        <h2 class="listingpageh2">Assay Map with ICD Code</h2>
        <div class="admin-list_v1 searchcol4">
            <div class="listingtableheading">
                <div class="listingtableheading_wrapper">
                    <h2><label>Assay Map with ICD Code List</label></h2>
                    <button class="add_btn" (click)="addBtn()">Map ICD Code</button>
                </div>
                <mat-progress-bar mode="indeterminate" [ngClass]="editprogressBar==true? 'show':'hide' "></mat-progress-bar>
            </div>
            <mat-progress-bar mode="indeterminate" [ngClass]="listprogressBar==true? 'show':'hide' "></mat-progress-bar>
            <div class="assyaMapWithICDCodeLibDiv" [ngClass]="listprogressBar==true? 'hide':'show' ">
                <lib-listing class="adminlisttable_v1 " *ngIf="tabledatatalist != null && tabledatatalist.length > 0" [datasource]="tabledatatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner" [deleteendpoint]="deleteendpoint"
                    [updateendpoint]="updateendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                    [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                    [limitcond]="limitcond" [customlistenbutton]="customlistenbutton" [updatetable]="assay_map_with_icd_code_updatetable" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)">
                </lib-listing>
            </div>

            <ng-container *ngIf="tabledatatalist != null && tabledatatalist.length == 0">
                <div class="noFoundTextinner">
                    <span>Oops!<br/> No Record Found</span>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="adminBody" *ngIf="addEditFormOpen">

    <mat-card-content class="addEditPageWrapper practice_form_style form_title_style">

        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <h2 class="listingpageh2">{{isedit?'Edit':'Add'}} Map ICD Code</h2>
        <div class="AddEditBlog taxonomies_formstyle">
            <div class="addEditForm addEditAssay">
                <lib-showform [formdata]="practiceformdata" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>

                <ng-container *ngIf="addFormLoader">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-container>

            </div>
        </div>

    </mat-card-content>


</mat-card>