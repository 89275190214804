import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  public changepasswordLoader: boolean = false;
  public formdatachngpass: any;
  public formdatachngpassp: any;
  formfieldrefreshpass: any = true;
  formfieldrefreshdatapass: any = null;
  public userdata: any;
  public issetpassword: boolean = false

  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#^(?)&])[A-Za-z\d@$!%*#^(?)&]{8,16}$/ ; // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, private http: HttpServiceService, private activateRoute: ActivatedRoute, private _snackBar: MatSnackBar) {


    this.userdata = JSON.parse(this.cookieService.getAll()['login_user_details']);

    console.log("activateRoute", this.activateRoute);

    if (this.activateRoute && this.activateRoute.snapshot && this.activateRoute.snapshot.routeConfig && this.activateRoute.snapshot.routeConfig.path == ":usertype/set-password") {
      this.setPassword();
      this.issetpassword = true;


      // this.formdatachngpass.valueChanges
      // .subscribe(values => if(path)['set-password'] == true && )
    }
    else {
      this.changePassword();
    }



  }

  ngOnInit() { }


  changePassword() {

    this.formdatachngpass = {
      // successmessage: 'Updated Successfully',
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'auth/changePassword',
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: '/dashboard', // use for redirect after cancel the form
      // redirectpath: "/dashboard",
      resettable: false,

      fields: [
        {
          label: 'Current Password',
          name: 'current_password',
          type: 'password',
          passwordflag: true,
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                "This field is required",
            },
            {
              rule: 'pattern',
              value: '',
              message: "Current Password doesn't match",
            },
          ],
        },
        {
          label: 'New Password',
          name: 'new_password',
          type: 'password',
          passwordflag: true,
          value: '',
//  hint:'Password must contain at least one Uppercase, one Lowercase, one Numeric character , one Special character and Minimum 8 characters',
          validations: [
            {
              rule: 'required',
              message: "This field is required",
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Password must be Minimum 8 characters',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirm_password',
          type: 'password',
          passwordflag: true,
          value: '',
          validations: [
            {
              rule: 'required',
              message: "This field is required",
            },
            // { rule: 'match', message: "Confirm Password field Needs to  match Password" },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: "Confirm Password must be matched with Password",
            },
          ],
        },
        {
          label: 'id',
          name: '_id',
          type: 'hidden',
          value: this.userdata._id,
        },
      ],
    }
  }
  setPassword() {
    this.formdatachngpassp = {
      // successmessage: 'Updated Successfully',
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'auth/changePassword',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: true,
      hidecancel: true,
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: '/dashboard', // use for redirect after cancel the form
      // redirectpath: "/dashboard",
      resettable: false,

      fields: [
        {
          label: 'New Password',
          name: 'new_password',
          type: 'password',
          value: null,
          passwordflag: true,
          validations: [
            {
              rule: 'required',
              message: "This field is required",
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Your password must contain 8 characters',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirm_password',
          type: 'password',
          value: null,
          passwordflag: true,
          validations: [
            {
              rule: 'required',
              message: "This field is required",
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: "Confirm password field value must be matched",
            },
          ],
        },
        {
          label: 'id',
          name: '_id',
          type: 'hidden',
          value: this.userdata._id,
        },
      ],
    }
  }


  listenFormFieldChange(val) {

    console.log('val+++++', val)
    let dataobj: any = {}

    if (val.field == "fromsubmit" && val.fieldval == "success") {

      dataobj.data = val.fromval
      this.changepasswordLoader = true;
      if (this.issetpassword) {
        this.http.httpViaPost('auth/setpassword', dataobj).subscribe((response) => {
          console.log('response>>>5555', response)
          if (response) {
            if (response.status == "success") {
              // this.router.navigateByUrl(`${this.userdata.user_type}/dashboard`);
              this.cookieService.delete('login_user_details', '/');
              this.cookieService.delete('loggedin_user', '/');
              this.cookieService.deleteAll();
              this.router.navigateByUrl('login');
            }
            this._snackBar.open(response.message, '', {
              duration: 3000
            })
            this.changepasswordLoader = false;
          }
        })

      } else {
        console.log("change password", dataobj)
        if (dataobj.data && (dataobj.data.new_password == dataobj.data.confirm_password)) {
          this.http.httpViaPost('auth/changePassword', dataobj).subscribe((response) => {
            console.log('response>>>5555', response)
            if (response) {
              if (response.status == "success") {
                this._snackBar.open(response.message, '', {
                  duration: 3000
                })
                // this.router.navigateByUrl('/dashboard');
                this.router.navigateByUrl(`${this.userdata.user_type}/dashboard`);
                this.changepasswordLoader = false;
              }
              else {
                this._snackBar.open(response.message, '', {
                  duration: 3000
                })
                this.changepasswordLoader = false;
              }
            }

          });
        } else {
          this.changepasswordLoader = false;
          this._snackBar.open("Confirm Password must be matched with New Password", '', {
            duration: 3000
          })
        }
      }




    }

  }




}


