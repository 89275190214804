import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-ai-use-report',
  templateUrl: './ai-use-report.component.html',
  styleUrls: ['./ai-use-report.component.css']
})
export class AiUseReportComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'status', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  public reportViewClicked: boolean = false
  tablename = "users";
  editroute = "";
  updateendpoint = "";
  deleteendpoint = "";
  datacollection: any = "users/fetchUsers";

  searchendpoint = "";
  date_search_endpoint: any = "users/fetchUsers";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    fullname: "Full Name",
    email: "Email",
    phone: "Phone",
    status: "Status",
    createdon_datetime: "Created On",
    acceptai_datetime: "Accept On"
  };
  search_settings: any = {


    search: [{
      label: "Search By Name", field: '_id',
      serversearchdata: { endpoint: 'users/userNameAutocomplete' },
    },
    ],

    textsearch: [
    ],
    datesearch: [
      {
        startdatelabel: 'Accept On Start Date',
        enddatelabel: 'Accept On End Date',
        submit: 'Search',
        field: 'acceptai_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["fullname", "status", "email", "createdon_datetime","acceptai_datetime"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Taxonomies',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: { user_type: 'practice','accept_ai':1 },
    detailview_override: [
    ],
    updateendpoint: "",

    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: true,
    hideaction: true,
    updateendpointmany: "",

    deleteendpointmany: "",
    tableheaders: ["fullname", "email", "phone", "status", "createdon_datetime","acceptai_datetime"],


    custombuttons: [
      // {
      //   label: "Lab Report",
      //   type: 'listner',
      //   id: 'view_report',
      //   tooltip: 'Lab Report',
      //   name: 'labreport',
      //   classname: 'labreport'
      // }
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public dialog: MatDialog) {

  }


  ngOnInit() {
    this.progressLoader = true;
    this.userListFetching();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        'user_type': 'practice',
        'accept_ai':1
      }
    }

    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })

    this.httpService.httpViaPost('users/fetchUsers-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }


  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      // if (data.custombuttonclick.btninfo.id == "view_report") {
      //   this.reportViewClicked = true;
      //   let dataSet = {
      //     "condition": {
      //       "limit": 10,
      //       "skip": 0
      //     },
      //     "sort": {
      //       "field": "_id",
      //       "type": "desc"
      //     },
      //     "searchcondition": {
      //       "selected_lab": data.custombuttonclick.data._id
      //     }
      //   }
      //   this.httpService.httpViaPost('intake/diagnosticlist', dataSet).subscribe((response: any) => {
      //     // console.log(response)
      //     if (response && response.status == "success") {
      //       this.reportViewClicked = false;
      //       const dialogRef = this.dialog.open(ReportViewModal, {
      //         panelClass: ["labReportViewModal"],
      //         data: response.results.res
      //       });
      //       dialogRef.afterClosed().subscribe(result => {
      //         console.log('The dialog was closed', result);
      //       })
      //     }

      //     // this.progressLoader = false;
      //   })


      // }
    }
  }

}
