import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-chosse-diagnosis',
  templateUrl: './chosse-diagnosis.component.html',
  styleUrls: ['./chosse-diagnosis.component.css']
})
export class ChosseDiagnosisComponent {
  public cookieData: any;
  public diagnosisData: any;
  public loaderFlag: boolean = false;

  tabledata_header_skip: any = ["_id", "is_private", "status"];
  tabledata_detail_skip: any = ['_id', "diagnostic_priority"];
  tablename = "diagnostic";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deletediagnostic";
  datacollection: any = "intake/diagnosticlist";

  searchendpoint = "intake/diagnosticlist";
  date_search_endpoint: any = "intake/diagnosticlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    diagnostic_name: "Name",
    diagnostic_des: "Description",
    assay_name: "Assay Name",
    diagnostic_category_name: "Diagnosis Category",
    // status: "Status",
    // createdon_datetime: "Created On",
    // updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [
      {
        label: "Search By Name ", field: 'diagnostic_name',
        serversearchdata: { endpoint: 'intake/diagnostic-autocomplete' },
      },
      {
        label: "Search By Category Name ", field: 'diagnostic_category_id',
        serversearchdata: { endpoint: 'intake/diagnosticcatautocomplete' },
      },
      {
        label: "Search By Assay Name ", field: 'assay_id',
        serversearchdata: { endpoint: 'intake/assayautocomplete' },
      },
    ],
    datesearch: [],
    selectsearch: [],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["diagnostic_name", "updatedon_datetime", "diagnostic_category_name",],
  };
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
    ],
    updateendpoint: "intake/diagnosticstatus",
    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideupdatemany: true,
    hidedeletemany: true,
    hideaction: true,
    updateendpointmany: "intake/diagnosticstatus",

    deleteendpointmany: "intake/deletediagnostic",
    tableheaders: ["diagnostic_name", "assay_name", "diagnostic_category_name"],
    customselectbuttons: [
      {
        label: "Choose & Access Dashboard",
        id: 'choose_all',
        classname: 'choose_all',
        tooltip: "Choose & Access Dashboard",
      }
    ],
    custombuttons: [
      {
        label: "Choose",
        type: 'listner',
        id: 'chosse_btn',
        tooltip: 'Choose',
        name: 'checkbox',
        classname: 'chosse_btn'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;

  public selectedDiagnosis: any = [];
  public daignosis_updatetable: boolean = false;

  public chooseDiagnosisLoader: boolean = false;

  constructor(public dialog: MatDialog, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public matSnackBar: MatSnackBar) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
  }
  ngOnInit() {
    this.diagnosisCount();
    this.activatedRoute.data.subscribe((response: any) => {
      this.loaderFlag = true
      if (response) {
        this.diagnosisData = response.data.results.res;
        this.libdata.basecondition = { selected_lab: { $ne: this.cookieData._id } }
      }
    })
  }

  diagnosisCount() {
    let dataset: any = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "type": "desc",
        "field": "_id"
      },
      "searchcondition": {
        "selected_lab": {
        }
      }
    }
    console.log("VVV>>>>>>>", dataset);

    dataset.searchcondition.selected_lab['$ne'] = this.cookieData._id
    this.httpService.httpViaPost('intake/diagnosticlist-count', dataset).subscribe((response: any) => {
      console.log(response)
      if (response) {
        // this.chooseDiagnosisLoader = false;
        this.date_search_source_count = response.count;
        // this.dialogRef.close('success');
      }
    })

  }


  listenLiblistingChange(val) {
    console.warn("listenLiblistingChange>>>>", val);
    if (val.action == "custombuttonclick") {
      this.submitDiagnosis(val.custombuttonclick.data._id, 'single')
    }

    if (val.action == "multipleselectoptionclick") {
      let vlauesofIds = val.selecteddata.map((dataid) => dataid._id);
      this.submitDiagnosis(vlauesofIds, 'multi')
    }
  }
  listenFormFieldChange(val) {
    console.warn("listenFormFieldChange>>>>", val);
  }
  onLiblistingButtonChange(val) {
    console.warn("onLiblistingButtonChange>>>>", val);
  }

  submitDiagnosis(diagnosis: any, stepFlag: any) {
    this.chooseDiagnosisLoader = true;
    // console.log("listenLiblistingChange", this.selectedDiagnosis)
    let payloadData: any;
    let endpoint_name: any;
    if (stepFlag == 'single') {
      payloadData = {
        data: {
          selected_lab: this.cookieData._id,
          _id: diagnosis
        }
      }

      endpoint_name = 'intake/adddiagnostic'
    }

    if (stepFlag == 'multi') {
      payloadData = {
        data: {
          selected_lab: this.cookieData._id,
          ids: diagnosis
        }
      }

      endpoint_name = 'intake/diagnosischossemany'
    }

    this.httpService.httpViaPost(endpoint_name, payloadData).subscribe((response: any) => {
      console.log(response)
      if (response) {
        this.chooseDiagnosisLoader = false;
        // this.daignosis_updatetable = !this.daignosis_updatetable;
        this.router.navigateByUrl('laboratory/dashboard');
        // this.dialogRef.close('success');
      }
    })

  }
}
