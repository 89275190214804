import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../layouts/header/header.component';
import { FooterComponent } from '../layouts/footer/footer.component';
import { ApproveDialog, ConfirmModal, DashboardComponent, FileUploadModal, PracticeUpgradeAlert, RapidResponseVirtualAccessModal, RejectNotes, Searchmodal, UniqueNameDialog } from '../components/dashboard/dashboard/dashboard.component';
import { DemoMaterialModule } from '../material/material.module';
import { AddUserBottomSheet, UserListComponent } from '../components/usermanagement/user-list/user-list.component';


//////////////////////////////////////////////////////////////////////////////////
// import { ListingAngular15Module } from 'listing-angular15';
// import { ListingAngular15Module } from 'dist/listing-angular15';
//////////////////////////////////////////////////////////////////////////////////
import { AddeditAdminComponent } from '../components/usermanagement/admin/addedit-admin/addedit-admin.component';
import { AddeditDoctorComponent } from '../components/usermanagement/doctor/addedit-doctor/addedit-doctor.component';
import { AuthService } from '../service/auth.service';
import { HttpServiceService } from '../service/http-service.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from '../components/auth/login/login.component';
import { ForgetpasswordComponent } from '../components/auth/forgetpassword/forgetpassword.component';
import { AddeditLaboratoryComponent, ExternalDataModalComponent } from '../components/usermanagement/laboratory/addedit-laboratory/addedit-laboratory.component';
import { BrowserModule } from '@angular/platform-browser';
import { AddeditSalesPersonComponent } from '../components/usermanagement/sales-person/addedit-sales-person/addedit-sales-person.component';
import { EmailTemplateAddComponent } from '../components/email-template/email-template-add/email-template-add.component';
import { EmailTemplateListComponent } from '../components/email-template/email-template-list/email-template-list.component';
import { UsermanagementModule } from '../components/usermanagement/usermanagement.module';
import { EmailTemplateModule } from '../components/email-template/email-template.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddeditpracticeComponent, ExternalDataLocationModalComponent } from '../components/usermanagement/practice/addeditpractice/addeditpractice.component';
import { locationMmodal, ManageLocationComponent } from '../layouts/manage-location/manage-location.component';
import { TaxonomiesModule } from '../components/taxonomies/taxonomies.module';
import { TaxonomiesListComponent } from '../components/taxonomies/taxonomies-list/taxonomies-list.component';
import { TaxonomiesAddComponent } from '../components/taxonomies/taxonomies-add/taxonomies-add.component';
import { PagesModule } from '../components/pages/pages.module';
import { LandingPageComponent } from '../components/pages/landing-page/landing-page.component';
import { deleteNote, FolderViewComponent, locationMmodalFV } from '../components/usermanagement/folder-view/folder-view.component';
import { EmailSlugAddComponent } from '../components/email-template/email-slug-add/email-slug-add.component';
import { EmailSlugListComponent } from '../components/email-template/email-slug-list/email-slug-list.component';
import { NotFoundComponent } from '../components/auth/not-found/not-found.component';

import { AddEditAssayComponent } from '../components/miscellaneous/assay-manage/add-edit-assay/add-edit-assay.component';
import { AssayListComponent, SelectCPTCodeDialog,AssayRangeDialog } from '../components/miscellaneous/assay-manage/assay-list/assay-list.component';

import { AddEditDiagnosticCategoryComponent } from '../components/miscellaneous/diagnostic-category-manage/add-edit-diagnostic-category/add-edit-diagnostic-category.component';
import { DiagnosticCategoryListComponent } from '../components/miscellaneous/diagnostic-category-manage/diagnostic-category-list/diagnostic-category-list.component';
import { DdiagnosticListComponent, ExistingDiagnosisList, LabDiagnosisDeleteModal, relatedDiagnosisModal, SelectSymptomsDialog } from '../components/miscellaneous/diagnostic-manage/ddiagnostic-list/ddiagnostic-list.component';
import { AddEditDdiagnosticComponent } from '../components/miscellaneous/diagnostic-manage/add-edit-ddiagnostic/add-edit-ddiagnostic.component';
import { ListingAngular15Module } from 'listing-angular15';
import { IntakePageComponent } from '../components/pages/intake-page/intake-page.component';
import { ApproveBottomSheet, LeadListComponent, RejectNotesLeadList } from '../components/leads/lead-list/lead-list.component';
// import { PreviewIntakeComponent } from '../components/pages/preview-intake/preview-intake.component';
import { OrderSheetComponent } from '../components/pages/order-sheet/order-sheet.component';
import { AddEditEmailIntakeComponent, CopyUrlDialog } from '../components/leads/email-intake/add-edit-email-intake/add-edit-email-intake.component';
import { EmailIntakeListComponent, ResendConfirmModal } from '../components/leads/email-intake/email-intake-list/email-intake-list.component';
import { SymptomsListComponent } from '../components/miscellaneous/symptoms-manage/symptoms-list/symptoms-list.component';
import { AddEditSymptomsComponent } from '../components/miscellaneous/symptoms-manage/add-edit-symptoms/add-edit-symptoms.component';
import { DoctorChooseModal, IntakeSymptomDescriptionModal, LeadIntakesComponent, PatientIintakesuccess, signPage } from '../components/leads/lead-intakes/lead-intakes.component';
import { alertModal, LeadOrderSheetComponent, SignModal, SymptomReasonModal } from '../components/leads/lead-order-sheet/lead-order-sheet.component';
import { CalculateDecisionDoc, LeadPreviewIntakeComponent } from '../components/leads/lead-preview-intake/lead-preview-intake.component';
import { IntakePdfSheetComponent } from '../components/leads/intake-pdf/intake-pdf-sheet/intake-pdf-sheet.component';
import { EquipmentListComponent } from '../components/miscellaneous/equipment-manage/equipment-list/equipment-list.component';
import { EquipmentAddEditComponent } from '../components/miscellaneous/equipment-manage/equipment-add-edit/equipment-add-edit.component';
import { ChosseDiagnosisComponent } from '../components/miscellaneous/diagnostic-manage/chosse-diagnosis/chosse-diagnosis.component';
import { InsuranceListComponent } from '../components/taxonomies/isurance/insurance-list/insurance-list.component';
import { InsuranceAddEditComponent } from '../components/taxonomies/isurance/insurance-add-edit/insurance-add-edit.component';
import { PhysicianExaminationComponent } from '../components/leads/physician-examination/physician-examination.component';
import { LabReportsListComponent, ReportViewModal } from '../components/usermanagement/lab-reports-list/lab-reports-list.component';
import { LabOrderSheetComponent } from '../components/leads/lab-order-sheet/lab-order-sheet.component';
import { AiUpgradationComponent } from '../components/dashboard/ai-upgradation/ai-upgradation.component';
import { AiUseReportComponent } from '../components/dashboard/ai-use-report/ai-use-report.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConformModal, PartialDoneLeadsListComponent } from '../components/leads/partial-done-leads-list/partial-done-leads-list.component';
import { SubjectiveFinalViewComponent } from '../components/leads/subjective-final-view/subjective-final-view.component';
import { BlockUIModule } from 'ng-block-ui';
import { TainingCategotyComponent } from '../components/trainingmanagement/taining-categoty/training-category-list/taining-categoty.component';
import { TainingCategotyAddEditComponent } from '../components/trainingmanagement/taining-categoty/taining-categoty-add-edit/taining-categoty-add-edit.component';
import { TrainingLessonListComponent } from '../components/trainingmanagement/taining-lesson/training-lesson-list/training-lesson-list.component';
import { TrainingLessonAddEditComponent } from '../components/trainingmanagement/taining-lesson/training-lesson-add-edit/training-lesson-add-edit.component';
import { TrainingCenterMainComponent } from '../components/trainingmanagement/training-center/training-center-main/training-center-main.component';
import { TrainingCenterProgressComponent } from '../components/trainingmanagement/training-center/training-center-progress/training-center-progress.component';
import { TrainingCenterLessonListComponent } from '../components/trainingmanagement/training-center/training-center-lesson-list/training-center-lesson-list.component';
import { TrainingCenterCategoryListComponent } from '../components/trainingmanagement/training-center/training-center-category-list/training-center-category-list.component';
import { DeviceOrderComponent } from '../components/leads/device-order/device-order.component';
import { LabReportComponent } from '../components/leads/lab-report/lab-report.component';
import { EvaluationManagementComponent } from '../components/leads/evaluation-management/evaluation-management.component';
import { CPTListComponent } from '../components/miscellaneous/cpt-manage/cpt-list/cpt-list.component';
import { AddEditCptComponent } from '../components/miscellaneous/cpt-manage/add-edit-cpt/add-edit-cpt.component';
import { AddEditIcdCodeComponent } from '../components/miscellaneous/icd-code-manage/add-edit-icd-code/add-edit-icd-code.component';
import { ListIcdCodeComponent } from '../components/miscellaneous/icd-code-manage/list-icd-code/list-icd-code.component';
import { AddEvaluationComponent } from '../components/miscellaneous/evaluation-manage/add-evaluation/add-evaluation.component';
import { EvaluationlistComponent } from '../components/miscellaneous/evaluation-manage/evaluationlist/evaluationlist.component';
import { PatientDetailsComponent } from '../components/leads/patient-details/patient-details.component';
import { PatientHistoryComponent } from '../components/leads/patient-history/patient-history.component';
import { PotentialDiagnosisComponent } from '../components/leads/potential-diagnosis/potential-diagnosis.component';
import { RecommendedNextStepsComponent } from '../components/leads/recommended-next-steps/recommended-next-steps.component';
import { BodymericsComponent } from '../components/leads/bodymetrics/bodymetrics.component';
import { LabReportsComponent, SignModalLabReports, SymptomReasonModalLabReports, alertModalLabReports } from '../components/leads/lab-reports/lab-reports.component';
import { EmcodeComponent } from '../components/leads/emcode/emcode.component';
import { PossibleDiagnosisComponent } from '../components/leads/possible-diagnosis/possible-diagnosis.component';
import { TreatmentProtocalComponent } from '../components/leads/treatment-protocal/treatment-protocal.component';
import { MedicationComponent } from '../components/leads/medication/medication.component';
import { LifeStyleChangesComponent } from '../components/leads/life-style-changes/life-style-changes.component';
import { SampleConversationComponent, sampleconversationModal } from '../components/leads/sample-conversation/sample-conversation.component';
import { PinionModal, ProfessionalOpinionComponent } from '../components/leads/professional-opinion/professional-opinion.component';
import { AssayMapWithIcdCodeComponent } from '../components/miscellaneous/assay-manage/assay-map-with-icd-code/assay-map-with-icd-code.component';
// import { ListingAngular15Module } from 'dist/listing-angular15';







@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    UserListComponent,
    AddUserBottomSheet,
    AddeditAdminComponent,
    AddeditDoctorComponent,
    AddeditLaboratoryComponent,
    LoginComponent,
    ForgetpasswordComponent,
    ExternalDataModalComponent,
    AddeditSalesPersonComponent,
    EmailTemplateAddComponent,
    EmailTemplateListComponent,
    signPage,
    PatientIintakesuccess,
    DoctorChooseModal,
    TaxonomiesListComponent,
    TaxonomiesAddComponent,
    LeadListComponent,
    PhysicianExaminationComponent,
    LeadIntakesComponent,
    LeadOrderSheetComponent,
    LeadPreviewIntakeComponent,
    DeviceOrderComponent,
    LabReportComponent,
    EvaluationManagementComponent,
    AddeditpracticeComponent,
    ExternalDataLocationModalComponent,
    IntakeSymptomDescriptionModal,

    ManageLocationComponent,
    locationMmodal,
    Searchmodal,
    RejectNotes,
    RejectNotesLeadList,
    ConfirmModal,
    FileUploadModal,
    UniqueNameDialog,
    ApproveDialog,
    ApproveBottomSheet,
    CopyUrlDialog,
    LandingPageComponent,
    SelectSymptomsDialog,
    SelectCPTCodeDialog,
    AssayMapWithIcdCodeComponent,
    relatedDiagnosisModal,
    ExistingDiagnosisList,
    LabDiagnosisDeleteModal,
    FolderViewComponent,
    IntakePageComponent,
    // PreviewIntakeComponent,
    OrderSheetComponent,
    AddEditEmailIntakeComponent,
    EmailIntakeListComponent,

    EmailSlugAddComponent,
    EmailSlugListComponent,
    NotFoundComponent,
    deleteNote,
    locationMmodalFV,

    AddEditAssayComponent,
    AssayListComponent,
    AddEditDiagnosticCategoryComponent,
    DiagnosticCategoryListComponent,
    AddEditDdiagnosticComponent,
    DdiagnosticListComponent,
    EquipmentAddEditComponent,
    EquipmentListComponent,
    SymptomsListComponent,
    AddEditSymptomsComponent,
    SignModal,
    alertModal,
    SymptomReasonModal,
    IntakePdfSheetComponent,
    ChosseDiagnosisComponent,
    InsuranceAddEditComponent,
    InsuranceListComponent,
    LabReportsListComponent,
    ReportViewModal,
    LabOrderSheetComponent,
    ResendConfirmModal,
    PracticeUpgradeAlert,
    RapidResponseVirtualAccessModal,
    AiUpgradationComponent,
    AiUseReportComponent,
    PartialDoneLeadsListComponent,
    ConformModal,
    SubjectiveFinalViewComponent,
    TainingCategotyComponent,
    TainingCategotyAddEditComponent,
    TrainingLessonListComponent,
    TrainingLessonAddEditComponent,
    TrainingCenterMainComponent,
    TrainingCenterProgressComponent,
    TrainingCenterLessonListComponent,
    TrainingCenterCategoryListComponent,
    CPTListComponent,
    AddEditCptComponent,
    AddEditIcdCodeComponent,
    ListIcdCodeComponent,
    AddEvaluationComponent,
    EvaluationlistComponent,
    CalculateDecisionDoc,
    PatientDetailsComponent,
    PatientHistoryComponent,
    PotentialDiagnosisComponent,
    RecommendedNextStepsComponent,
    BodymericsComponent,
    LabReportsComponent,
    EmcodeComponent,
    PossibleDiagnosisComponent,
    TreatmentProtocalComponent,
    MedicationComponent,
    LifeStyleChangesComponent,
    SampleConversationComponent,
    ProfessionalOpinionComponent,
    PinionModal,
    sampleconversationModal,
    AssayRangeDialog,

    SignModalLabReports,
    SymptomReasonModalLabReports,
    alertModalLabReports
 




  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    ListingAngular15Module,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    BlockUIModule.forRoot()



  ],
  exports: [

    HeaderComponent,
    FooterComponent,
    DemoMaterialModule,
    // DashboardComponent,
    // UserListComponent,
    // AddUserBottomSheet,
    ListingAngular15Module,



  ],
  providers: [HttpServiceService, AuthService],
  bootstrap: [
    UserListComponent, locationMmodal
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  entryComponents: [Searchmodal, PracticeUpgradeAlert]
})
export class SharedModule { }
