import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent {
  public practiceAddForm: any = {}
  public enrollmentForm: any = {}
  // emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;  
  emailregex: RegExp = /^\s*[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,4}\s*$/;
  // phoneregex: RegExp = (/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/); 

  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public laboratory_details: any;
  public sealsperson_details: any = null;
  public doctor_details: any = null;
  public taxonomyList: any;
  public locationList: any;
  public logoUrl: any = {};
  public progressBar: any = false;

  public landingpageShow: any;

  public laboratoryData: any = {}

  public stateList: any = [];

  public demoDoctorDashboard: boolean = false;

  constructor(private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public matSnackBar: MatSnackBar, public router: Router,) {





    if (environment.slug == 'landingpage' || environment.slug == 'development' || environment.slug == 'production') {
      if (this.activatedRoute.routeConfig.path.includes('practice-signup')) {
        // alert('ffff')

        this.landingpageShow = 'practiceadd'


      }

      // this.landingpageShow = 'practiceadd';
    }
    if (environment.slug == 'leadaddlanding' || environment.slug == 'development' || environment.slug == 'production') {

      if (this.activatedRoute.routeConfig.path.includes('lead-add')) {
        // alert('ffff')

        this.landingpageShow = 'leadadd'
      }

      // this.landingpageShow = 'leadadd';
    }
    console.warn(this.activatedRoute, ">>>>>>>", environment.slug);
    this.activatedRoute.data.subscribe((response: any) => {
      this.laboratoryData = response.data.results.laboratory
      if (this.laboratoryData.logo) {
        this.logoUrl = 'https://pece-training-files.s3.amazonaws.com/' + this.laboratoryData.logo.path + this.laboratoryData.logo.fileservername
        console.log("logoUrl", this.logoUrl, this.laboratoryData);
      } else {
        this.logoUrl = "null"

      }
      console.log("this.logoUrl", this.logoUrl);

      console.log('RESS>>>', this.laboratoryData);

      if (response.data.status == "error") {
        console.log("aaaaaaa");

        this.router.navigateByUrl(`404`);
      }

      if (typeof response.data.results.laboratory != 'undefined' && response.data.results.laboratory != null) {
        this.laboratory_details = response.data.results.laboratory;
      }
      if (typeof response.data.results.salesperson != 'undefined' && response.data.results.salesperson != null) {
        this.sealsperson_details = response.data.results.salesperson;
        console.log("this.sealsperson_details", this.sealsperson_details);
      }

      if (response) {
        this.doctor_details = response.data.results.laboratory;
        console.log("doctor_details", this.doctor_details);
      }

      // this.taxonomyFetching();
      // this.locationListlab();
    });
  }
  ngOnInit() {





    if (this.activatedRoute.routeConfig.path.includes('practice-signup')) {
      this.taxonomyFetching();
      this.locationListlab();
    }

    if (this.activatedRoute.routeConfig.path.includes('lead-add')) {
      this.getStates();
    }
    if (window.location.pathname.includes('demo')) {
      this.demoDoctorDashboard = true;
    }





    this.practicesubmitForm();
    this.enrollmentsubmitForm();
  }

  visit() {
    document.getElementById("leadaddlib").scrollIntoView({
      behavior: "smooth",
    });
  }

  ScrollIntoView(elem: any) {
    // console.log("elem--------->", elem);
    document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  practicesubmitForm() {
    console.log('taxonomyList>>>', this.taxonomyList);

    this.practiceAddForm =
    {

      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          label: 'Practice Name',
          name: 'practice_name',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Practice Name is Required' },

          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'text',
          hint: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]
        },
        {

          label: 'Contact Person First Name',
          name: 'contact_person_firstname',
          type: "text",
          hint: '',
          value: '',
          validations: [
            { rule: 'required', message: 'First Name is required' },
          ]
          // ckeConfig: this.ckeConfig,

        },


        {

          label: 'Contact Person Last Name',
          name: 'contact_person_lastname',
          type: 'text',
          hint: '',
          // val: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Last Name is required' },
          ]

        },
        {

          label: 'Contact Person Phone',
          name: 'contact_phone',
          type: 'numberformat',
          formatflag: true,
          hint: '',
          // val: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Contact Phone is required' },
            // { rule: 'min', value: 14 ,message: "Formating Phone Number min 10"},
            // {
            //   rule: 'pattern',
            //   value: this.phoneregex,
            //   messege: 'Must be a valid Number',
            // }
          ]

        },
        {

          label: 'Contact Person Email',
          name: 'contact_email',
          type: 'text',
          hint: '',
          // val: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Contact Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]

        },
        {

          label: 'Taxonomy',
          name: 'taxonomy',
          type: 'select',
          hint: '',
          val: this.taxonomyList,
          multiple: true,
          value: '',
          validations: [
            { rule: 'required', message: 'Taxonomy is required' },
          ]
        },
        {

          label: 'Location',
          name: 'parent_location',
          type: 'select',
          hint: '',
          val: this.locationList,
          value: '',
          multiple: true,
          validations: [
            { rule: 'required', message: 'Select Location is required' },
          ]
        },

        {
          label: "Parent Laboratory",
          name: "parent_laboratory",
          type: 'hidden',
          value: this.laboratory_details && this.laboratory_details._id ? this.laboratory_details._id : '',
        },
        {
          label: "User Type",
          name: "user_type",
          type: 'hidden',
          value: 'practice',
        }

      ]


    }
  }


  enrollmentsubmitForm() {

    this.enrollmentForm = {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          label: 'Name',
          name: 'patient_name',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },

          ],
        },

        {

          label: 'Phone',
          name: 'patient_phone',
          type: 'numberformat',
          formatflag: true,
          hint: '',
          // val: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Phone is required' },
            // {
            //   rule: 'pattern',
            //   value: this.phoneregex,
            //   messege: 'Must be a valid Number',
            // }
          ]

        },
        {

          label: 'Email',
          name: 'patient_email',
          type: 'text',
          hint: '',
          // val: '',
          value: '',
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]

        },

        {

          label: 'Gender',
          name: 'patient_gender',
          type: 'select',
          // group: "grp1",
          hint: '',
          val: [{ val: 'male', name: 'Male' }, { val: 'female', name: 'Female' }],
          multiple: false,
          value: '',
          validations: [
            { rule: 'required', message: 'Gender is required' },
          ]
        },

        {
          label: 'State',
          name: 'state',
          val: this.stateList,

          type: 'select',

          validations: [
            { rule: 'required', message: 'State is required' },
          ]
        },

        {
          label: 'City',
          name: 'city',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },
        {
          label: 'Zip',
          name: 'zip',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },
      ]
    }

  }


  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log('resolveval', this.stateList)
    })
  }


  taxonomyFetching() {
    let data: any = {
      sort: { type: 'desc', field: '_id' },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('users/taxonomyautocomplete', data).subscribe((response) => {
      console.log("taxonomy", response)
      if (response) {
        if (response.status == "success") {
          this.taxonomyList = response.res;
          if (this.taxonomyList.length > 0) {
            this.taxonomyList.forEach((e: any, i: number) => [
              e.val = e._id
            ])
          }
          this.practiceAddForm.fields = this.practiceAddForm.fields.filter((e) => e.name == 'taxonomy' ? e.val = this.taxonomyList : []);
        }
      }
    })
  }

  locationListlab() {
    let data = { "_id": this.laboratory_details._id }
    this.apiservice.httpViaPost('users/fetchlabLoation', data).subscribe((response) => {
      console.log("my change response++++", response.res)
      this.locationList = response.res;
      if (this.locationList.length > 0) {
        this.locationList.forEach((e: any, i: number) => {
          e.val = e._id
        })
      }
      this.practiceAddForm.fields = this.practiceAddForm.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationList : []);

    })
  }

  listenFormFieldChange(val: any) {
    console.log("dataobj", val)

    // if (val){
    //   if (val.field.name == "email" || val.field.name == "contact_email"){
    //     val.fieldval = val.fieldval.toLowerCase()
    //     val.fromval.email = val.fromval.email.toLowerCase()
    //   }


    //   console.log("val.fromval.email", val.fromval.email);

    // }

    // if (val.fieldval == "validationerror") {
    //   this.progressBar = false;
    // }

    if (val.field && val.field == "fromsubmit") {
      this.progressBar = true;
      let dataobj = {
        data: val.fromval
      }
      dataobj.data.landing_page = true;
      if (this.sealsperson_details) {
        dataobj.data.sales_person = this.sealsperson_details._id
      }
      if (this.landingpageShow == 'practiceadd') {
        dataobj.data.parent_laboratory = this.laboratory_details._id
      }

      console.log(" this.sealsperson_details", this.sealsperson_details)
      this.apiservice.httpViaPost('landingpage/addFormData', dataobj).subscribe((response) => {
        console.log("dataobj>>>>", response)

        if (response && response.status == 'success') {
          console.log("test dataobj", response.results.res)
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.progressBar = false;
          // console.log("response>>>>>>>", response);


          this.router.navigateByUrl(`practice-signup/thankyou-page/${response.results.res._id}`);
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.progressBar = false;
        }

      })
    }
  }

  listenFormFieldChangelead(val: any) {


    if (val.field && val.field == "fromsubmit") {
      this.progressBar = true;
      let dataobj = {
        data: val.fromval
      }
      dataobj.data.landing_page = true;
      dataobj.data.doctor_id = this.doctor_details._id;
      dataobj.data.practice_id = this.doctor_details.practice_id;
      dataobj.data.status = 0
      if (this.doctor_details.parent_laboratory) {
        dataobj.data.parent_laboratory = this.doctor_details.parent_laboratory
      }


      if (this.doctor_details.user_type == "practice") {
        dataobj.data.practice_id = this.doctor_details._id;
        delete dataobj.data.doctor_id;
      }
      dataobj.data.is_demo = false;
      if (this.demoDoctorDashboard) {
        dataobj.data.is_demo = true;
        if (this.sealsperson_details) {
          dataobj.data.doctor_id = this.sealsperson_details._id;
          dataobj.data.practice_id = this.sealsperson_details._id;
          dataobj.data.parent_laboratory = this.sealsperson_details.parent_laboratory
        }
      }
      dataobj.data.patient_email = dataobj.data.patient_email.trim();
      dataobj.data.is_partial_done = true;
      dataobj.data.intake_submitted_by = 'quick';
      console.log("dataobj>>>>", dataobj)
      this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {

        if (response && response.status == 'success') {
          console.log("test dataobj", response.results.res)
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.progressBar = false;
          if (this.demoDoctorDashboard) {
            this.router.navigateByUrl(`demo/quick-intake/${response.results.res._id}`);
          } else {
            this.router.navigateByUrl(`quick-intake/${response.results.res._id}`);
          }
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.progressBar = false;
        }

      })
    }
  }
}
