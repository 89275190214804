import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-center-progress',
  templateUrl: './training-center-progress.component.html',
  styleUrls: ['./training-center-progress.component.css']
})
export class TrainingCenterProgressComponent {
  public totalLessonCount: number = null;
  @Input() set totalLesson(value: any) {
    if (value) {
      this.totalLessonCount = value;
    }
  }
  public totalLessonComplete: any = null;
  @Input() set totalLessonDone(value: any) {
    if (value) {
      this.totalLessonComplete = value;
    }
  }
public completedPercentageValue: any = null;
constructor(){}
ngOnInit(){
if(this.totalLessonCount && this.totalLessonComplete){
this.completedPercentageValue = this.totalLessonComplete/this.totalLessonCount;
}
}


}
