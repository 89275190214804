<app-header>
</app-header>
<mat-card>
    <mat-card-content class="aiUpgradeBody">

        <div class="aiUpgradeBodyContent">
            <div class="videoBlock">
                <iframe src="https://www.youtube.com/embed/J_YLjCTOFWE?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <br />
            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
            <div class="btnDiv">
                <button (click)="upgradePractice()">Upgrade</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>