import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-intake-page',
  templateUrl: './intake-page.component.html',
  styleUrls: ['./intake-page.component.css']
})
export class IntakePageComponent {
  public intakeForm: any = null;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public laboratory_details: any;
  public sealsperson_details: any = null;
  public locationList: any;
  public logoUrl: any = {};
  public progressBar: any = false;
  public editFormData: any = null;
  public addFormLoader: boolean = false;
  public stateList: any = [];
  public paramslink: any;
  public subBtn: any = false;
  public aspinnerLoader: any = true;
  objectKeys = Object.keys;
  public patient_intake_flag: boolean = false;
  public quick_intake_flag: boolean = false;
  public email_intake_flag: boolean = false;
  public intake_sign: any;

  public intakesigninput: any;
  public practiceDetail: any = '';

  public intakesignForm: FormGroup;

  // public signlibCon: any = false;

  public errorclass: boolean = false;

  public cookiedata: any = '';


  public resetformcss: boolean = false;


  public datafetchIMG: any = null;


  public intake_submitted_by: any = '';

  public emailparams: any = '';

  public emailIntakeData: any = '';

  public preview_intakesignblock: boolean = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public matSnackBar: MatSnackBar, public router: Router, public cookieService: CookieService) {




    console.log('cookiedata', this.cookiedata)


    this.paramslink = this.activatedRoute.snapshot.params
    console.log('activatedRoutelink', this.activatedRoute.snapshot.params)




    if (this.activatedRoute.snapshot.routeConfig.path.includes(':usertype/preview-intake/:_id')) {
      this.subBtn = true;


    }

    if (this.activatedRoute.snapshot.routeConfig.path.includes(':user_type/patient-intake')) {
      if (this.cookieService.getAll()['login_user_details']) {
        this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
      }
      if (this.cookiedata.user_type !== 'admin' && this.cookiedata.user_type !== 'doctor' && this.cookiedata.user_type !== 'practice') {
        this.router.navigateByUrl('404')
      }
      this.patient_intake_flag = true;
    }



    console.log('activatedRoute+++', this.activatedRoute)
    if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id')) {

      this.preview_intakesignblock = true;

      this.activatedRoute.data.subscribe((response: any) => {
        this.emailIntakeData = response.data.results.res[0]

        console.log('emailIntakeData', response)
      });




      this.email_intake_flag = true;

      if (this.cookiedata.user_type == 'doctor') {

        this.practiceLogo();

      }


    }


    if (this.activatedRoute.snapshot.routeConfig.path.includes('quick-intake/:_id')) {

      this.quick_intake_flag = true;
      this.preview_intakesignblock = true;

      // if (this.cookiedata.user_type == 'doctor') {

      //   this.practiceLogo();

      // }
      console.log("quick-intake here")


    }



    this.getStates();








  }

  practiceLogo() {




    let datafetch: any = {

      'condition': {
        'limit': 10, 'skip': 0
      },
      sort: {
        'type': 'desc', 'field': '_id'
      },

      searchcondition: { _id: this.cookiedata.practice_id }
    }

    this.apiservice.httpViaPost('users/fetchUserDetails', datafetch).subscribe((response: any) => {

      console.log('response', response)


      if (response.status == "success") {


        if (response.results.res[0].image) {
          this.datafetchIMG = response.results.res[0].image

          console.log('datafetchIMG', this.datafetchIMG)

        }



      }






    })


  }


  ngOnInit() {








    if (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params["_id"]) {


      this.activatedRoute.data.subscribe((response: any) => {
        console.log("editdata", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            // this.aspinnerLoader =true;

            this.editFormData = response.data.results.res[0];
            this.intakeFormCreate()
            console.log("editFormData", this.editFormData)


            this.intake_sign = this.editFormData.patient_signature

            if(typeof this.intake_sign != 'undefined') {

              this.preview_intakesignblock = true;
              // this.patient_intake_flag = 

             
            }

            console.log('preview_intakesignblock', "this.preview_intakesignblock",this.preview_intakesignblock, this.patient_intake_flag)
            console.log('apinnerLoader', this.preview_intakesignblock)




            // fetchUserDetails data call for practice detail
            let practicedata: any = {
      
              searchcondition: {
                _id: this.editFormData.practice_id
              }
              
            }

            
        
            this.apiservice.httpViaPost('users/fetchUserDetails', practicedata).subscribe((response: any) => {

              

                if (response && response.status == "success") {
                  
                  console.log('response++++++',response.results.res[0])

                  this.practiceDetail=response.results.res[0]
                }

             
        
            })

               // fetchUserDetails data call for practice detail end






          }
        }
      });
    } else {

      this.intakeFormCreate()
    }








    this.intakesignForm = new FormGroup({
      intake_sign: new FormControl('', Validators.required),



    });


    this.intakesignForm = this.formBuilder.group({
      intake_sign: [{ value: '', disabled: true }, [Validators.required]],
    });

    console.log('intakesignForm', this.intakesignForm)

  }





  intakeFormCreate() {



    this.intakeForm =
    {
      successmessage: 'Added Successfully !!',
      submittext: this.subBtn ? '' : 'Submit',
      // submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      groups: ["grp0", "grp1", "grp2", "grp3", "grp4", "grp5"],
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          heading: "PERSONAL INFORMATION",
          group: "grp0",
        },
        {

          label: 'Patient Name',
          name: 'patient_name',
          value: this.editFormData && this.editFormData.patient_name ? this.editFormData.patient_name : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Patient Name is Required' },

          ],
        },

        {
          label: 'Patient Email',
          name: 'patient_email',
          type: 'text',
          group: "grp1",
          hint: '',
          value: this.editFormData && this.editFormData.patient_email ? this.editFormData.patient_email : '',
          validations: [
            { rule: 'required', message: 'Patient Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]
        },
        {

          label: 'Phone No',
          name: 'patient_phone',
          type: 'numberformat',
          formatflag: true,
          group: "grp1",
          hint: '',
          // val: '',
          value: this.editFormData && this.editFormData.patient_phone ? this.editFormData.patient_phone : '',
          validations: [
            { rule: 'required', message: 'Phone No is required' },
          ]

        },
        {

          label: 'Gender',
          name: 'patient_gender',
          type: 'select',
          group: "grp1",
          hint: '',
          val: [{ val: 'male', name: 'Male' }, { val: 'female', name: 'Female' }],
          multiple: false,
          value: this.editFormData && this.editFormData.patient_gender ? this.editFormData.patient_gender : '',
          validations: [
            { rule: 'required', message: 'Gender is required' },
          ]
        },
        {
          label: 'Address',
          name: 'patient_address',
          value: this.editFormData && this.editFormData.patient_address ? this.editFormData.patient_address : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Address is Required' },

          ],
        },
        {
          label: 'State',
          name: 'state',
          value: this.editFormData && this.editFormData.state ? this.editFormData.state : '',
          group: "grp1",
          val: this.stateList,
          type: 'select',
          validations: [
            { rule: 'required', message: 'State is Required' },

          ],
        },







        {
          label: 'City',
          name: 'city',
          value: this.editFormData && this.editFormData.city ? this.editFormData.city : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'City is Required' },

          ],
        },
        {
          label: 'Zip',
          name: 'zip',
          value: this.editFormData && this.editFormData.zip ? this.editFormData.zip : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Zip is Required' },

          ],
        },

        {
          label: "Date of Birth (MM/DD/YYYY)",
          name: "patient_dob",
          type: "date",
          group: "grp1",
          value: this.editFormData && this.editFormData.patient_dob ? this.editFormData.patient_dob : '',
          // hint: "MM/DD/YYYY",
          // minDate: new Date(),
          maxDate: new Date(),
          validations: [
            { rule: 'required', message: "Date of Birth is required" }
          ]
        },

        {
          label: 'Height',
          name: 'height',
          value: this.editFormData && this.editFormData.height ? this.editFormData.height : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Height is Required' },

          ],
        },

        {
          label: 'Weight',
          name: 'weight',
          value: this.editFormData && this.editFormData.weight ? this.editFormData.weight : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Weight is Required' },

          ],
        },

        {
          heading: "ADDITIONAL QUESTIONS",
          group: "grp2",
        },


        {
          label: "Have you ever been diagnosed with PVD (Peripheral Vascular Disease)?",
          name: "diagnosed",
          type: 'radio',
          group: "grp3",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diagnosed !== undefined && this.editFormData.diagnosed !== null ? this.editFormData.diagnosed == false ? false : true : '',
          // value: this.editFormData.diagnosed,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Are you currently diagnosed with Diabetes?",
          name: "diabetes",
          type: 'radio',
          group: "grp3",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diabetes !== undefined && this.editFormData.diabetes !== null ? this.editFormData.diabetes == false ? false : true : '',
          // value: this.editFormData.diabetes,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Have you been diagnosed and/or treated for Covid19 in the last year?",
          name: "covid19",
          type: 'radio',
          group: "grp3",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.covid19 !== undefined && this.editFormData.covid19 !== null ? this.editFormData.covid19 == false ? false : true : '',
          // value: this.editFormData.covid19,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },
        {
          heading: "SYMPTOMS CHECKLIST",
          group: "grp4",
        },

        // ========== Headaches ======== //
        {
          heading: "Headaches",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "headaches_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_six_months ? this.editFormData.headaches_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "headaches_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_last_month ? this.editFormData.headaches_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "headaches_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_today ? this.editFormData.headaches_today : false,
          group: "grp5",
        },

        // ========== Skin and eyes that appear yellowish (jaundice) ======== //
        {
          heading: "Skin and eyes that appear yellowish (jaundice)",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "skin_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_six_months ? this.editFormData.skin_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "skin_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_last_month ? this.editFormData.skin_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_today ? this.editFormData.skin_today : false,
          group: "grp5",
        },

        // ========== Abdominal pain and swelling ======== //
        {
          heading: "Abdominal pain and swelling",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "abdominal_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_six_months ? this.editFormData.abdominal_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "abdominal_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_last_month ? this.editFormData.abdominal_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "abdominal_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_today ? this.editFormData.abdominal_today : false,
          group: "grp5",
        },

        // ========== Swelling in the legs and ankles ======== //
        {
          heading: "Swelling in the legs and ankles",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "selling_legs_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_six_months ? this.editFormData.selling_legs_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "selling_legs_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_last_month ? this.editFormData.selling_legs_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "selling_legs_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_today ? this.editFormData.selling_legs_today : false,
          group: "grp5",
        },

        // ========== Dry, itchy skin ======== //
        {
          heading: "Dry, itchy skin",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "itchy_skin_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_six_months ? this.editFormData.itchy_skin_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "itchy_skin_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_last_month ? this.editFormData.itchy_skin_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "itchy_skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_today ? this.editFormData.itchy_skin_today : false,
          group: "grp5",
        },

        // ========== Dark urine color ======== //
        {
          heading: "Dark urine color",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "dark_urine_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_six_months ? this.editFormData.dark_urine_color_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "dark_urine_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_last_month ? this.editFormData.dark_urine_color_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "dark_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_today ? this.editFormData.dark_urine_color_today : false,
          group: "grp5",
        },

        // ========== Pale stool color ======== //
        {
          heading: "Pale stool color",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "pale_stool_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_six_months ? this.editFormData.pale_stool_color_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "pale_stool_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_last_month ? this.editFormData.pale_stool_color_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "pale_stool_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_today ? this.editFormData.pale_stool_color_today : false,
          group: "grp5",
        },

        // ========== Chronic fatigue ======== //
        {
          heading: "Chronic fatigue",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chronic_fatigue_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_six_months ? this.editFormData.chronic_fatigue_six_months : false,
          group: "grp5",
        },

        {
          label: "Last month",
          name: "chronic_fatigue_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_last_month ? this.editFormData.chronic_fatigue_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chronic_fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_today ? this.editFormData.chronic_fatigue_today : false,
          group: "grp5",
        },

        // ========== Nausea or vomiting ======== //
        {
          heading: "Nausea or vomiting",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_six_months ? this.editFormData.vomiting_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_last_month ? this.editFormData.vomiting_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_today ? this.editFormData.vomiting_today : false,
          group: "grp5",
        },

        // ========== Loss of appetite ======== //
        {
          heading: "Loss of appetite",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "lossofappetite_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_six_months ? this.editFormData.lossofappetite_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "lossofappetite_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_last_month ? this.editFormData.lossofappetite_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "lossofappetite_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_today ? this.editFormData.lossofappetite_today : false,
          group: "grp5",
        },

        // ========== Tendency to bruise easily ======== //
        {
          heading: "Tendency to bruise easily",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "bruise_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_six_months ? this.editFormData.bruise_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "bruise_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_last_month ? this.editFormData.bruise_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "bruise_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_today ? this.editFormData.bruise_today : false,
          group: "grp5",
        },

        // ========== Fever ======== //
        {
          heading: "Fever",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "fever_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_six_months ? this.editFormData.fever_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "fever_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_last_month ? this.editFormData.fever_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "fever_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_today ? this.editFormData.fever_today : false,
          group: "grp5",
        },

        // ========== Insomnia ======== //
        {
          heading: "Insomnia",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "insomnia_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_six_months ? this.editFormData.insomnia_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "insomnia_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_last_month ? this.editFormData.insomnia_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "insomnia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_today ? this.editFormData.insomnia_today : false,
          group: "grp5",
        },

        // ========== Urinating less ======== //
        {
          heading: "Urinating less",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "urinating_less_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_six_months ? this.editFormData.urinating_less_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "urinating_less_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_last_month ? this.editFormData.urinating_less_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "urinating_less_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_today ? this.editFormData.urinating_less_today : false,
          group: "grp5",
        },

        // ========== Muscle cramps ======== //
        {
          heading: "Muscle cramps",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "muscle_cramps_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_six_months ? this.editFormData.muscle_cramps_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "muscle_cramps_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_last_month ? this.editFormData.muscle_cramps_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "muscle_cramps_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_today ? this.editFormData.muscle_cramps_today : false,
          group: "grp5",
        },


        // ========== Swelling of feet and ankles ======== //
        {
          heading: "Swelling of feet and ankles",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "swelling_feet_ankles_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_six_months ? this.editFormData.swelling_feet_ankles_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "swelling_feet_ankles_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_last_month ? this.editFormData.swelling_feet_ankles_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "swelling_feet_ankles_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_today ? this.editFormData.swelling_feet_ankles_today : false,
          group: "grp5",
        },


        // ========== Shortness of breath ======== //
        {
          heading: "Shortness of breath",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "shortness_of_breath_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_six_months ? this.editFormData.shortness_of_breath_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "shortness_of_breath_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_last_month ? this.editFormData.shortness_of_breath_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "shortness_of_breath_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_today ? this.editFormData.shortness_of_breath_today : false,
          group: "grp5",
        },
        // ========== Chest pain ======== //
        {
          heading: "Chest pain",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chest_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_six_months ? this.editFormData.chest_pain_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chest_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_last_month ? this.editFormData.chest_pain_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chest_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_today ? this.editFormData.chest_pain_today : false,
          group: "grp5",
        },// ========== Back Pain ======== //
        {
          heading: "Back Pain",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "back_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_six_months ? this.editFormData.back_pain_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "back_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_last_month ? this.editFormData.back_pain_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "back_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_today ? this.editFormData.back_pain_today : false,
          group: "grp5",
        },// ========== Frequent urination ======== //
        {
          heading: "Frequent urination",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "frequent_urination_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_six_months ? this.editFormData.frequent_urination_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "frequent_urination_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_last_month ? this.editFormData.frequent_urination_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "frequent_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_today ? this.editFormData.frequent_urination_today : false,
          group: "grp5",
        },// ========== Foamy urine ======== //
        {
          heading: "Foamy urine",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "foamy_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_six_months ? this.editFormData.foamy_urine_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "foamy_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dddd ? this.editFormData.dddd : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "foamy_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_last_month ? this.editFormData.foamy_urine_last_month : false,
          group: "grp5",
        },// ========== Blood in your urine ======== //
        {
          heading: "Blood in your urine",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "blood_in_your_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_six_months ? this.editFormData.blood_in_your_urine_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "blood_in_your_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_last_month ? this.editFormData.blood_in_your_urine_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "blood_in_your_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_today ? this.editFormData.blood_in_your_urine_today : false,
          group: "grp5",
        },
        // ========== Dizziness ======== //
        {
          heading: "Dizziness",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "dizziness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_six_months ? this.editFormData.dizziness_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "dizziness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_last_month ? this.editFormData.dizziness_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "dizziness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_today ? this.editFormData.dizziness_today : false,
          group: "grp5",
        },// ========== Swelling around your eyes ======== //
        {
          heading: "Swelling around your eyes",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "swelling_around_your_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_six_months ? this.editFormData.swelling_around_your_eyes_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "swelling_around_your_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_last_month ? this.editFormData.swelling_around_your_eyes_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "swelling_around_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_today ? this.editFormData.swelling_around_your_eyes_today : false,
          group: "grp5",
        },// ========== High blood sugar ======== //
        {
          heading: "High blood sugar",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "high_blood_sugar_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_six_months ? this.editFormData.high_blood_sugar_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "high_blood_sugar_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_last_month ? this.editFormData.high_blood_sugar_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "high_blood_sugar_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_today ? this.editFormData.high_blood_sugar_today : false,
          group: "grp5",
        },
        // ========== Weight loss ======== //
        {
          heading: "Weight loss",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "weight_loss_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_six_months ? this.editFormData.weight_loss_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "weight_loss_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_last_month ? this.editFormData.weight_loss_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "weight_loss_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_today ? this.editFormData.weight_loss_today : false,
          group: "grp5",
        },
        // ========== Joint pain ======== //
        {
          heading: "Joint pain",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "joint_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_six_months ? this.editFormData.joint_pain_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "joint_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_last_month ? this.editFormData.joint_pain_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "joint_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_today ? this.editFormData.joint_pain_today : false,
          group: "grp5",
        },// ========== Joint Inflammation ======== //
        {
          heading: "Joint Inflammation",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "joint_inflammation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_six_months ? this.editFormData.joint_inflammation_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "joint_inflammation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_last_month ? this.editFormData.joint_inflammation_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "joint_inflammation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_today ? this.editFormData.joint_inflammation_today : false,
          group: "grp5",
        },
        // ========== Limited range of motion ======== //
        {
          heading: "Limited range of motion",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "limited_range_motion_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_six_months ? this.editFormData.limited_range_motion_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "limited_range_motion_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_last_month ? this.editFormData.limited_range_motion_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "limited_range_motion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_today ? this.editFormData.limited_range_motion_today : false,
          group: "grp5",
        },
        // ========== Coughing, especially at night ======== //
        {
          heading: "Coughing, especially at night",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "coughing_especially_at_night_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_six_months ? this.editFormData.coughing_especially_at_night_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "coughing_especially_at_night_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_last_month ? this.editFormData.coughing_especially_at_night_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "coughing_especially_at_night_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_today ? this.editFormData.coughing_especially_at_night_today : false,
          group: "grp5",
        },
        // ========== Wheezing or squeaking when you breathe ======== //
        {
          heading: "Wheezing or squeaking when you breathe",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "wheezing_squeaking_when_you_breathe_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_six_months ? this.editFormData.wheezing_squeaking_when_you_breathe_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "wheezing_squeaking_when_you_breathe_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_last_month ? this.editFormData.wheezing_squeaking_when_you_breathe_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "wheezing_squeaking_when_you_breathe_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_today ? this.editFormData.wheezing_squeaking_when_you_breathe_today : false,
          group: "grp5",
        },
        // ========== Chest tightness or pain ======== //
        {
          heading: "Chest tightness or pain",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chest_tightness_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_six_months ? this.editFormData.chest_tightness_pain_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chest_tightness_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_last_month ? this.editFormData.chest_tightness_pain_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chest_tightness_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_today ? this.editFormData.chest_tightness_pain_today : false,
          group: "grp5",
        },
        // ========== Low energy ======== //
        {
          heading: "Low energy",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "low_energy_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_six_months ? this.editFormData.low_energy_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "low_energy_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_last_month ? this.editFormData.low_energy_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "low_energy_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_today ? this.editFormData.low_energy_today : false,
          group: "grp5",
        },
        // ========== Blue fingernails ======== //
        {
          heading: "Blue fingernails",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "blue_fingernails_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_six_months ? this.editFormData.blue_fingernails_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "blue_fingernails_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_last_month ? this.editFormData.blue_fingernails_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "blue_fingernails_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_today ? this.editFormData.blue_fingernails_today : false,
          group: "grp5",
        },
        // ========== Frequent colds or flu ======== //
        {
          heading: "Frequent colds or flu",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "frequent_colds_flu_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_six_months ? this.editFormData.frequent_colds_flu_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "frequent_colds_flu_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_last_month ? this.editFormData.frequent_colds_flu_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "frequent_colds_flu_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_today ? this.editFormData.frequent_colds_flu_today : false,
          group: "grp5",
        },
        // ========== Coughing up lots of mucus ======== //
        {
          heading: "Coughing up lots of mucus",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "coughing_up_lots_mucus_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_six_months ? this.editFormData.coughing_up_lots_mucus_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "coughing_up_lots_mucus_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_last_month ? this.editFormData.coughing_up_lots_mucus_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "coughing_up_lots_mucus_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_today ? this.editFormData.coughing_up_lots_mucus_today : false,
          group: "grp5",
        },
        // ========== A cough that doesn't go away ======== //
        {
          heading: "A cough that doesn't go away",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "cough_that_doesnt_go_away_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_six_months ? this.editFormData.cough_that_doesnt_go_away_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "cough_that_doesnt_go_away_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_last_month ? this.editFormData.cough_that_doesnt_go_away_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "cough_that_doesnt_go_away_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_today ? this.editFormData.cough_that_doesnt_go_away_today : false,
          group: "grp5",
        },
        // ========== Depression or anxiety ======== //
        {
          heading: "Depression or anxiety",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "depression_anxiety_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_six_months ? this.editFormData.depression_anxiety_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "depression_anxiety_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_last_month ? this.editFormData.depression_anxiety_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "depression_anxiety_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_today ? this.editFormData.depression_anxiety_today : false,
          group: "grp5",
        },
        // ========== Restlessness while sleeping ======== //
        {
          heading: "Restlessness while sleeping",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "restlessness_while_sleeping_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_six_months ? this.editFormData.restlessness_while_sleeping_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "restlessness_while_sleeping_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_last_month ? this.editFormData.restlessness_while_sleeping_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "restlessness_while_sleeping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_today ? this.editFormData.restlessness_while_sleeping_today : false,
          group: "grp5",
        },
        // ========== Fatigue ======== //
        {
          heading: "Fatigue",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "fatigue_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_six_months ? this.editFormData.fatigue_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "fatigue_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_last_month ? this.editFormData.fatigue_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_today ? this.editFormData.fatigue_today : false,
          group: "grp5",
        },
        // ========== Nausea and vomiting ======== //
        {
          heading: "Nausea and vomiting",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "nausea_vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_six_months ? this.editFormData.nausea_vomiting_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "nausea_vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_last_month ? this.editFormData.nausea_vomiting_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "nausea_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_today ? this.editFormData.nausea_vomiting_today : false,
          group: "grp5",
        },
        // ========== Arrhythmia ======== //
        {
          heading: "Arrhythmia",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "arrhythmia_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_six_months ? this.editFormData.arrhythmia_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "arrhythmia_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_last_month ? this.editFormData.arrhythmia_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "arrhythmia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_today ? this.editFormData.arrhythmia_today : false,
          group: "grp5",
        },
        // ========== Confusion ======== //
        {
          heading: "Confusion",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "confusion_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_six_months ? this.editFormData.confusion_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "confusion_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_last_month ? this.editFormData.confusion_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "confusion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_today ? this.editFormData.confusion_today : false,
          group: "grp5",
        },
        // ========== Blurred vision ======== //
        {
          heading: "Blurred vision",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "blurred_vision_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_six_months ? this.editFormData.blurred_vision_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "blurred_vision_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_last_month ? this.editFormData.blurred_vision_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "blurred_vision_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_today ? this.editFormData.blurred_vision_today : false,
          group: "grp5",
        },
        // ========== Extreme thirst ======== //
        {
          heading: "Extreme thirst",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "extreme_thirst_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_six_months ? this.editFormData.extreme_thirst_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "extreme_thirst_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_last_month ? this.editFormData.extreme_thirst_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "extreme_thirst_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_today ? this.editFormData.extreme_thirst_today : false,
          group: "grp5",
        },
        // ========== Extreme Hunger ======== //
        {
          heading: "Extreme Hunger",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "extreme_hunger_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_six_months ? this.editFormData.extreme_hunger_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "extreme_hunger_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_last_month ? this.editFormData.extreme_hunger_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "extreme_hunger_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_today ? this.editFormData.extreme_hunger_today : false,
          group: "grp5",
        },
        // ========== Painful urination ======== //
        {
          heading: "Painful urination",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "painful_urination_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_six_months ? this.editFormData.painful_urination_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "painful_urination_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_last_month ? this.editFormData.painful_urination_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "painful_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_today ? this.editFormData.painful_urination_today : false,
          group: "grp5",
        },
        // ========== Numb or tingling feet ======== //
        {
          heading: "Numb or tingling feet",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "numb_or_tingling_feet_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_six_months ? this.editFormData.numb_or_tingling_feet_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "numb_or_tingling_feet_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_last_month ? this.editFormData.numb_or_tingling_feet_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "numb_or_tingling_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_today ? this.editFormData.numb_or_tingling_feet_today : false,
          group: "grp5",
        },
        // ========== Sexual Difficulties ======== //
        {
          heading: "Sexual Difficulties",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "sexual_difficulties_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_six_months ? this.editFormData.sexual_difficulties_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "sexual_difficulties_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_last_month ? this.editFormData.sexual_difficulties_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "sexual_difficulties_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_today ? this.editFormData.sexual_difficulties_today : false,
          group: "grp5",
        },
        // ========== Urinary problems ======== //
        {
          heading: "Urinary problems",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "urinary_problems_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_six_months ? this.editFormData.urinary_problems_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "urinary_problems_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_last_month ? this.editFormData.urinary_problems_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "urinary_problems_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_today ? this.editFormData.urinary_problems_today : false,
          group: "grp5",
        },
        // ========== Tingling Hands & Feet ======== //
        {
          heading: "Tingling Hands & Feet",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "tingling_hands_feet_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_six_months ? this.editFormData.tingling_hands_feet_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "tingling_hands_feet_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_last_month ? this.editFormData.tingling_hands_feet_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "tingling_hands_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_today ? this.editFormData.tingling_hands_feet_today : false,
          group: "grp5",
        },
        // ========== Burning Sensation ======== //
        {
          heading: "Burning Sensation",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "burning_sensation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_six_months ? this.editFormData.burning_sensation_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "burning_sensation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_last_month ? this.editFormData.burning_sensation_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "burning_sensation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_today ? this.editFormData.burning_sensation_today : false,
          group: "grp5",
        },
        // ========== Angina ======== //
        {
          heading: "Angina",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "angina_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_six_months ? this.editFormData.angina_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "angina_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_last_month ? this.editFormData.angina_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "angina_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_today ? this.editFormData.angina_today : false,
          group: "grp5",
        },
        // ========== Chest Pain That Goes Away With Rest ======== //
        {
          heading: "Chest Pain That Goes Away With Rest",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chest_pain_that_goesaway_with_rest_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_six_months ? this.editFormData.chest_pain_that_goesaway_with_rest_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chest_pain_that_goesaway_with_rest_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_last_month ? this.editFormData.chest_pain_that_goesaway_with_rest_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chest_pain_that_goesaway_with_rest_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_today ? this.editFormData.chest_pain_that_goesaway_with_rest_today : false,
          group: "grp5",
        },
        // ========== Heartburn ======== //
        {
          heading: "Heartburn",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "heartburn_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_six_months ? this.editFormData.heartburn_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "heartburn_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_last_month ? this.editFormData.heartburn_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "heartburn_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_today ? this.editFormData.heartburn_today : false,
          group: "grp5",
        },
        // ========== Pain In Calves ======== //
        {
          heading: "Pain In Calves",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "pain_in_calves_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_six_months ? this.editFormData.pain_in_calves_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "pain_in_calves_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_last_month ? this.editFormData.pain_in_calves_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "pain_in_calves_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_today ? this.editFormData.pain_in_calves_today : false,
          group: "grp5",
        },
        // ========== Dizziness Or Lightheadedness ======== //
        {
          heading: "Dizziness Or Lightheadedness",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "dizziness_lightheadedness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_six_months ? this.editFormData.dizziness_lightheadedness_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "dizziness_lightheadedness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_last_month ? this.editFormData.dizziness_lightheadedness_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "dizziness_lightheadedness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_today ? this.editFormData.dizziness_lightheadedness_today : false,
          group: "grp5",
        },
        // ========== Irregular Heartbeat, Too Fast/Slow ======== //
        {
          heading: "Irregular Heartbeat, Too Fast/Slow",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "irregular_heartbeat_toofastslow_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_six_months ? this.editFormData.irregular_heartbeat_toofastslow_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "irregular_heartbeat_toofastslow_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_last_month ? this.editFormData.irregular_heartbeat_toofastslow_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "irregular_heartbeat_toofastslow_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_today ? this.editFormData.irregular_heartbeat_toofastslow_today : false,
          group: "grp5",
        },
        // ========== Heart Attack ======== //
        {
          heading: "Heart Attack",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "heart_attack_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_six_months ? this.editFormData.heart_attack_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "heart_attack_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_last_month ? this.editFormData.heart_attack_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "heart_attack_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_today ? this.editFormData.heart_attack_today : false,
          group: "grp5",
        },
        // ========== Stroke ======== //
        {
          heading: "Stroke",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "stroke_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_six_months ? this.editFormData.stroke_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "stroke_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_last_month ? this.editFormData.stroke_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_today ? this.editFormData.stroke_today : false,
          group: "grp5",
        },
        // ========== Blood Clot In A Vein (Venous Thrombosis) ======== //
        {
          heading: "Blood Clot In A Vein (Venous Thrombosis)",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "blood_clot_inavein_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_six_months ? this.editFormData.blood_clot_inavein_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "blood_clot_inavein_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_last_month ? this.editFormData.blood_clot_inavein_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "blood_clot_inavein_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_today ? this.editFormData.blood_clot_inavein_today : false,
          group: "grp5",
        },
        // ========== Tia (Mini Stroke) ======== //
        {
          heading: "Tia (Mini Stroke)",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "tia_mini_stroke_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_six_months ? this.editFormData.tia_mini_stroke_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "tia_mini_stroke_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_last_month ? this.editFormData.tia_mini_stroke_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "tia_mini_stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_today ? this.editFormData.tia_mini_stroke_today : false,
          group: "grp5",
        },
        // ========== Chest Pain Or Discomfort ======== //
        {
          heading: "Chest Pain Or Discomfort",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chest_painor_discomfort_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_six_months ? this.editFormData.chest_painor_discomfort_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chest_painor_discomfort_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_last_month ? this.editFormData.chest_painor_discomfort_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chest_painor_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_today ? this.editFormData.chest_painor_discomfort_today : false,
          group: "grp5",
        },
        // ========== Fainting ======== //
        {
          heading: "Fainting",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "fainting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_six_months ? this.editFormData.fainting_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "fainting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_last_month ? this.editFormData.fainting_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "fainting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_today ? this.editFormData.fainting_today : false,
          group: "grp5",
        },
        // ========== Weakness ======== //
        {
          heading: "Weakness",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "weakness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_six_months ? this.editFormData.weakness_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "weakness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_last_month ? this.editFormData.weakness_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "weakness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_today ? this.editFormData.weakness_today : false,
          group: "grp5",
        },
        // ========== Lingering discomfort ======== //
        {
          heading: "Lingering discomfort",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "lingering_discomfort_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_six_months ? this.editFormData.lingering_discomfort_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "lingering_discomfort_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_last_month ? this.editFormData.lingering_discomfort_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "lingering_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_today ? this.editFormData.lingering_discomfort_today : false,
          group: "grp5",
        },
        // ========== Pain in affected joint ======== //
        {
          heading: "Pain in affected joint",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "pain_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_six_months ? this.editFormData.pain_in_affected_joint_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "pain_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_last_month ? this.editFormData.pain_in_affected_joint_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "pain_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_today ? this.editFormData.pain_in_affected_joint_today : false,
          group: "grp5",
        },
        // ========== Swelling in affected joint ======== //
        {
          heading: "Swelling in affected joint",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "swelling_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_six_months ? this.editFormData.swelling_in_affected_joint_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "swelling_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_last_month ? this.editFormData.swelling_in_affected_joint_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "swelling_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_today ? this.editFormData.swelling_in_affected_joint_today : false,
          group: "grp5",
        },
        // ========== Redness in affected joint ======== //
        {
          heading: "Redness in affected joint",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "redness_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_six_months ? this.editFormData.redness_in_affected_joint_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "redness_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_last_month ? this.editFormData.redness_in_affected_joint_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "redness_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_today ? this.editFormData.redness_in_affected_joint_today : false,
          group: "grp5",
        },
        // ========== Chills ======== //
        {
          heading: "Chills",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chills_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_six_months ? this.editFormData.chills_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chills_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_last_month ? this.editFormData.chills_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chills_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_today ? this.editFormData.chills_today : false,
          group: "grp5",
        },
        // ========== Cough ======== //
        {
          heading: "Cough",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "cough_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_six_months ? this.editFormData.cough_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "cough_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_last_month ? this.editFormData.cough_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "cough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_today ? this.editFormData.cough_today : false,
          group: "grp5",
        },
        // ========== Chest pain when you breathe or cough ======== //
        {
          heading: "Chest pain when you breathe or cough",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "chest_pain_when_you_breatheorcough_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_six_months ? this.editFormData.chest_pain_when_you_breatheorcough_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "chest_pain_when_you_breatheorcough_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_last_month ? this.editFormData.chest_pain_when_you_breatheorcough_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "chest_pain_when_you_breatheorcough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_today ? this.editFormData.chest_pain_when_you_breatheorcough_today : false,
          group: "grp5",
        },
        // ========== Nausea ======== //
        {
          heading: "Nausea",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "nausea_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_six_months ? this.editFormData.nausea_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "nausea_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_last_month ? this.editFormData.nausea_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "nausea_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_today ? this.editFormData.nausea_today : false,
          group: "grp5",
        },
        // ========== Pain or burning while urinating ======== //
        {
          heading: "Pain or burning while urinating",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "painorburning_while_urinating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_six_months ? this.editFormData.painorburning_while_urinating_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "painorburning_while_urinating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_last_month ? this.editFormData.painorburning_while_urinating_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "painorburning_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_today ? this.editFormData.painorburning_while_urinating_today : false,
          group: "grp5",
        },
        // ========== Bloody urine ======== //
        {
          heading: "Bloody urine",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "bloody_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_six_months ? this.editFormData.bloody_urine_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "bloody_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_last_month ? this.editFormData.bloody_urine_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "bloody_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_today ? this.editFormData.bloody_urine_today : false,
          group: "grp5",
        },
        // ========== Runny nose ======== //
        {
          heading: "Runny nose",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "runny_nose_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_six_months ? this.editFormData.runny_nose_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "runny_nose_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_last_month ? this.editFormData.runny_nose_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "runny_nose_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_today ? this.editFormData.runny_nose_today : false,
          group: "grp5",
        },
        // ========== Sore throat ======== //
        {
          heading: "Sore throat",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "sore_throat_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_six_months ? this.editFormData.sore_throat_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "sore_throat_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_last_month ? this.editFormData.sore_throat_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "sore_throat_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_today ? this.editFormData.sore_throat_today : false,
          group: "grp5",
        },
        // ========== Swollen glands ======== //
        {
          heading: "Swollen glands",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "swollen_glands_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_six_months ? this.editFormData.swollen_glands_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "swollen_glands_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_last_month ? this.editFormData.swollen_glands_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "swollen_glands_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_today ? this.editFormData.swollen_glands_today : false,
          group: "grp5",
        },
        // ========== Trouble breathing ======== //
        {
          heading: "Trouble breathing",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "trouble_breathing_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_six_months ? this.editFormData.trouble_breathing_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "trouble_breathing_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_last_month ? this.editFormData.trouble_breathing_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "trouble_breathing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_today ? this.editFormData.trouble_breathing_today : false,
          group: "grp5",
        },
        // ========== High-pitched sound when breathing in ======== //
        {
          heading: "High-pitched sound when breathing in",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "high_pitched_sound_when_breathing_in_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_six_months ? this.editFormData.high_pitched_sound_when_breathing_in_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "high_pitched_sound_when_breathing_in_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_last_month ? this.editFormData.high_pitched_sound_when_breathing_in_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "high_pitched_sound_when_breathing_in_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_today ? this.editFormData.high_pitched_sound_when_breathing_in_today : false,
          group: "grp5",
        },
        // ========== Burning and pain while urinating ======== //
        {
          heading: "Burning and pain while urinating",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "burning_and_pain_while_urinating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_six_months ? this.editFormData.burning_and_pain_while_urinating_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "burning_and_pain_while_urinating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_last_month ? this.editFormData.burning_and_pain_while_urinating_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "burning_and_pain_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_today ? this.editFormData.burning_and_pain_while_urinating_today : false,
          group: "grp5",
        },

        // ========== Red eyes ======== //
        {
          heading: "Red eyes",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "red_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_six_months ? this.editFormData.red_eyes_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "red_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_last_month ? this.editFormData.red_eyes_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "red_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_today ? this.editFormData.red_eyes_today : false,
          group: "grp5",
        },
        // ========== Discharge from your eyes ======== //
        {
          heading: "Discharge from your eyes",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "discharge_from_your_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_six_months ? this.editFormData.discharge_from_your_eyes_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "discharge_from_your_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_last_month ? this.editFormData.discharge_from_your_eyes_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "discharge_from_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_today ? this.editFormData.discharge_from_your_eyes_today : false,
          group: "grp5",
        },
        // ========== Abdominal pain ======== //
        {
          heading: "Abdominal pain",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "abdominal_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_six_months ? this.editFormData.abdominal_pain_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "abdominal_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_last_month ? this.editFormData.abdominal_pain_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "abdominal_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_today ? this.editFormData.abdominal_pain_today : false,
          group: "grp5",
        },
        // ========== Change in urine color ======== //
        {
          heading: "Change in urine color",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "change_in_urine_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_six_months ? this.editFormData.change_in_urine_color_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "change_in_urine_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_last_month ? this.editFormData.change_in_urine_color_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "change_in_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_today ? this.editFormData.change_in_urine_color_today : false,
          group: "grp5",
        },
        // ========== Painful ejaculation ======== //
        {
          heading: "Painful ejaculation",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "painful_ejaculation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_six_months ? this.editFormData.painful_ejaculation_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "painful_ejaculation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_last_month ? this.editFormData.painful_ejaculation_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "painful_ejaculation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_today ? this.editFormData.painful_ejaculation_today : false,
          group: "grp5",
        },
        // ========== A missed period ======== //
        {
          heading: "A missed period",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "amissed_period_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_six_months ? this.editFormData.amissed_period_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "amissed_period_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_last_month ? this.editFormData.amissed_period_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "amissed_period_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_today ? this.editFormData.amissed_period_today : false,
          group: "grp5",
        },
        // ========== Swollen or tender breasts ======== //
        {
          heading: "Swollen or tender breasts",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "swollenortender_breasts_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_six_months ? this.editFormData.swollenortender_breasts_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "swollenortender_breasts_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_last_month ? this.editFormData.swollenortender_breasts_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "swollenortender_breasts_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_today ? this.editFormData.swollenortender_breasts_today : false,
          group: "grp5",
        },
        // ========== Nausea, with or without vomiting ======== //
        {
          heading: "Nausea, with or without vomiting",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "nausea_withorwithout_vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_six_months ? this.editFormData.nausea_withorwithout_vomiting_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "nausea_withorwithout_vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_last_month ? this.editFormData.nausea_withorwithout_vomiting_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "nausea_withorwithout_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_today ? this.editFormData.nausea_withorwithout_vomiting_today : false,
          group: "grp5",
        },
        // ========== Light spotting ======== //
        {
          heading: "Light spotting",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "light_spotting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_six_months ? this.editFormData.light_spotting_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "light_spotting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_last_month ? this.editFormData.light_spotting_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "light_spotting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_today ? this.editFormData.light_spotting_today : false,
          group: "grp5",
        },
        // ========== Cramping ======== //
        {
          heading: "Cramping",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "cramping_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_six_months ? this.editFormData.cramping_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "cramping_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_last_month ? this.editFormData.cramping_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "cramping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_today ? this.editFormData.cramping_today : false,
          group: "grp5",
        },
        // ========== Bloating ======== //
        {
          heading: "Bloating",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "bloating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_six_months ? this.editFormData.bloating_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "bloating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_last_month ? this.editFormData.bloating_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "bloating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_today ? this.editFormData.bloating_today : false,
          group: "grp5",
        },
        // ========== Mood swings ======== //
        {
          heading: "Mood swings",
          group: "grp5",
        },

        {
          label: "6 months",
          name: "mood_swings_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_six_months ? this.editFormData.mood_swings_six_months : false,
          group: "grp5",
        },
        {
          label: "Last month",
          name: "mood_swings_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_last_month ? this.editFormData.mood_swings_last_month : false,
          group: "grp5",
        },
        {
          label: "Today",
          name: "mood_swings_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_today ? this.editFormData.mood_swings_today : false,
          group: "grp5",
        },



      ]


    }

    console.log('intakeForm', this.intakeForm)

    setTimeout(() => {
      this.aspinnerLoader = false;
    }, 500);
  }

  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log('resolveval', this.stateList)
    })
  }

  intakesign(dataobj?) {



    this.intakesignForm.markAllAsTouched()
    console.log('dataobj+++', dataobj)
    console.log('intakesignForm', this.intakesignForm.value.intake_sign, this.intakesignForm.valid)
    if (this.intakesignForm.value.intake_sign && this.intakesignForm.value.intake_sign !== '') {
      dataobj.data.patient_signature = this.intakesignForm.value.intake_sign
      dataobj.data._id = this.editFormData._id
      console.log('dataobj0000', dataobj.patient_signature)
      this.addFormLoader = true;


      if (this.email_intake_flag) {
        delete dataobj.data._id



        if (this.emailIntakeData && this.emailIntakeData.practice_id) { dataobj.data.practice_id = this.emailIntakeData.practice_id; }
        if (this.emailIntakeData && this.emailIntakeData.parent_laboratory) { dataobj.data.parent_laboratory = this.emailIntakeData.parent_laboratory; }
        if (this.emailIntakeData && this.emailIntakeData.doctor_id) { dataobj.data.doctor_id = this.emailIntakeData.doctor_id; }



      }

      if (this.quick_intake_flag) {
        dataobj.data.parent_laboratory = this.cookiedata.parent_laboratory;



      }
      if (dataobj.data.patient_phone.length > 14) {
        dataobj.data.patient_phone = dataobj.data.patient_phone.substring(0, 14)
      }

      this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {
        console.log("dataobj>>>>", response)
        if (response && response.status == 'success') {
          this.emailparams = response.results.res._id;
          console.log('emailparams', this.emailparams)
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          // email intake submit end point call
          this.addFormLoader = false;
          if (this.email_intake_flag) {
            let dataobjemail: any = {
              _id: this.paramslink._id,
              patient_id: response.results.res._id
            }
            this.apiservice.httpViaPost('email/resetemailintake', dataobjemail).subscribe((response) => {
            })

            this.router.navigateByUrl(`thankyou-page/${this.emailparams}`);
          }




          if (this.quick_intake_flag) {

            this.router.navigateByUrl(`thankyou-page/${this.paramslink._id}`);


          }


        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }


      })

    }

  }


  listenFormFieldChange(val) {

    // console.log('intakesignForm22',this.intakesignForm)

    console.warn('listenFormFieldChange>>>>', val);

    let dataobj = {
      data: val.fromval
    }

    // dataobj.data._id = this.editFormData._id

    if (val.fromval.undefined == '') {
      delete dataobj.data.undefined
    }




    console.log("dataobjdiabetes", val.fromval.patient_signature)

    // this.intake_sign= new FormControl('', Validators.required)
    if (val.field == "fromsubmiterror") {
      this.intakesignForm.markAllAsTouched()
    }
    if (val.field && val.field == "fromsubmit") {

      console.log('dataobj+++', dataobj)

      // this.intakesign(dataobj)

      //  for patient intake  submit

      if (val.fromval.undefined == '') {
        delete dataobj.data.undefined
      }

      console.log('flag', this.email_intake_flag, this.quick_intake_flag, this.patient_intake_flag)

      if (this.email_intake_flag) {
        dataobj.data['intake_submitted_by'] = 'email'
        this.intakesign(dataobj)
        console.log('dataobjemail', dataobj)
      }

      if (this.quick_intake_flag) {
        dataobj.data['intake_submitted_by'] = 'quick'
        this.intakesign(dataobj)
        console.log('cookiedata+++++++', dataobj.data)

      }

      if (this.patient_intake_flag) {



        this.addFormLoader = true;

        dataobj.data['intake_submitted_by'] = 'patient'

        dataobj.data.parent_laboratory = this.cookiedata.parent_laboratory;


        if (this.cookiedata.user_type == 'practice') {

          dataobj.data.practice_id = this.cookiedata._id;

        }

        if (this.cookiedata.user_type == 'doctor') {

          dataobj.data.practice_id = this.cookiedata.practice_id;
          dataobj.data.doctor_id = this.cookiedata._id;

        }
        if (dataobj.data.patient_phone.length > 14) {
          dataobj.data.patient_phone = dataobj.data.patient_phone.substring(0, 14)
        }

        this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {
          console.log("dataobj>>>>", response)


          if (response && response.status == 'success') {

            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.addFormLoader = false;
            this.openDialogPatientIintake()

          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }


        })

      }

    }

  }

  signDialog(): void {
    // const dialogRef = this.dialog.open(signPage, {

    //   data: { heading: this.intake_sign },
    //   panelClass: 'signPageDialog'
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed', result);
    //   // this.animal = result;
    //   if (result) {
    //     this.intakesignForm.patchValue({ intake_sign: result.signinput })
    //   }
    //   this.intake_sign = result.signinput;

    //   this.errorclass = true;
    // });
  }

  openDialogPatientIintake(): void {

    // const dialogRef = this.dialog.open(PatientIintakesuccess, {

    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   window.scroll(0, 0)
    //   this.resetform();

    //   this.resetformcss = true;


    // });
  }

  resetform() {
    setTimeout(() => {
      this.formfieldrefreshdata = {
        field: "resetform",
      };
    }, 500);

  }
}

// @Component({
//   selector: 'signPage',
//   templateUrl: 'signPage.html',
// })
// export class signPage {

//   public signvalu: any = '';
//   public addsignForm: FormGroup;
//   public modaldata: any = {};



//   constructor(
//     public dialogRef: MatDialogRef<signPage>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData,
//   ) {

//     this.modaldata = data;
//     console.log(this.modaldata, 'modal data++');

//     this.signvalu = this.modaldata.heading;

//     console.log('signvalu222', this.signvalu)
//   }
//   // ngAfterViewInit(){
//   //   window.scroll(0,0)
//   //   alert("gghh")

//   // }

//   myFunction(event) {

//     // var div = document.getElementById('divID');
//     console.log('event', event.target.value)

//     this.signvalu = event.target.value

//     console.log('signvalu', this.signvalu)

//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }


//   ngOnInit() {

//     this.addsignForm = new FormGroup({
//       signinput: new FormControl('', Validators.required),

//     });

//     if (this.modaldata && this.modaldata.heading) {
//       this.addsignForm.patchValue({
//         signinput: this.modaldata.heading,


//       })

//     }




//   }


//   addsign() {
//     this.addsignForm.markAllAsTouched()
//     if (this.addsignForm.valid) {

//       // console.log(this.modaldata, 'dataSubmit');


//       if (this.modaldata.item) {
//         this.addsignForm.patchValue({
//           signinput: this.modaldata.item.signinput,
//         });
//       }
//       console.log('signinput', this.addsignForm.value)

//       this.dialogRef.close(this.addsignForm.value);


//     }
//   }



// }



// @Component({
//   selector: 'patient-Iintake-success',
//   templateUrl: 'patient-Iintake-success.html',
// })
// export class PatientIintakesuccess {

//   public cookiedata: any = '';

//   constructor(
//     public dialogRef: MatDialogRef<PatientIintakesuccess>, public router: Router, public cookieService: CookieService,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData,
//   ) {

//     this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
//     dialogRef.disableClose = true;
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//     this.router.navigateByUrl(`${this.cookiedata.user_type}/patient-intake`);

//   }

//   onBackClick(): void {
//     this.dialogRef.close();

//     this.router.navigateByUrl(this.cookiedata.user_type + '/dashboard');
//   }
// }