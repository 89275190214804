import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
  public baseUrl: any = environment.api_url;
  public jwtToken: string = '';
public rapidResponseBaseUrl: any = environment.decisiondoc_rapid_response_access_url;

  constructor(private http: HttpClient) { }

  public getHttpData(url): Observable<any> {
    return this.http.get(url);
  }

 

  httpViaPost(endpoint, jsonData): Observable<any> {
    // console.log("{{}{}{",jsonData);

    /* set common header */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.jwtToken
      })
    };
    return this.http.post(this.baseUrl + endpoint, jsonData, httpOptions)
// .pipe(
//       catchError((err) => {
//           return throwError(err);    //Rethrow it back to component
//         })
//     );
  }

httpViaPostRapidResponse(endpoint, jsonData): Observable<any> {
    // console.log("{{}{}{",jsonData);

    /* set common header */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.jwtToken
      })
    };
    return this.http.post(this.rapidResponseBaseUrl + endpoint, jsonData, httpOptions)
// .pipe(
//       catchError((err) => {
//           return throwError(err);    //Rethrow it back to component
//         })
//     );
  }

 
 


}


