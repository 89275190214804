import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-training-lesson-add-edit',
  templateUrl: './training-lesson-add-edit.component.html',
  styleUrls: ['./training-lesson-add-edit.component.css']
})
export class TrainingLessonAddEditComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public trainingLessonformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public categoryList: any = [];
  private prequisiteLessons: any = [];
  public ckeConfig: any = {
    toolbar: [
      { name: 'editing', items: ['Scayt', 'Find', 'Replace', 'SelectAll'] },
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      {
        name: 'tools',
        items: ['Maximize', 'ShowBlocks', 'Preview', 'Print', 'Templates'],
      },
      { name: 'document', items: ['Source'] },
      {
        name: 'insert',
        items: [
          'Image',
          'Table',
          'HorizontalRule',
          'SpecialChar',
          'Iframe',
          'imageExplorer',
        ],
      },
      '/',
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript',
          '-',
          'RemoveFormat',
        ],
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          'CreateDiv',
          '-',
          'Blockquote',
        ],
      },
      {
        name: 'justify',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
      },
      {
        name: 'styles',
        items: ['Styles', 'Format', 'FontSize', '-', 'TextColor', 'BGColor'],
      },
    ],
  };

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    if (!window.location.pathname.includes('training')) {
      this.router.navigateByUrl('404')
    }
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }

  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response.data.results.res[0])
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            this.initailForm();
            let payload: any = {
              searchcondition: { category_id: this.editFormData.category_id }
            };
            this.apiservice.httpViaPost(`training/getprerequisitelesson`, payload).subscribe((response: any) => {
              this.prequisiteLessons = response?.results?.res && Array.isArray(response.results.res) ?
                response.results.res.map((val: any) => ({
                  val: val._id,
                  name: val.name.trim(),
                }))
                : [];
              console.log(
                'this.prequisiteLessons========>',
                this.prequisiteLessons
              );


            });
          }
        }
      });
      // this.editForm();
    } else {
      this.initailForm();
    }
    this.fetchAllCategories();
  }

  initailForm() {
    this.trainingLessonformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `${this.cookieData.user_type}/training/training-lesson-list`, // use for redirect after cancel the form
      fields: [

        {
          label: 'Lesson Name',
          name: 'lesson_name',
          value: this.editFormData && this.editFormData.lesson_name ? this.editFormData.lesson_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Category Name is Required' },

          ],
        },
        {
          label: 'Category',
          name: 'category_id',
          val: this.categoryList,
          value: this.editFormData && this.editFormData.category_id ? this.editFormData.category_id : '',
          type: 'select',
          validations: [{ rule: 'required', message: 'Category is Required' }],
        },
        {
          label: 'Pre-Requisite Lesson',
          name: 'prerequisite_lesson',
          val: this.prequisiteLessons,
          value: this.editFormData && this.editFormData.prerequisite_lesson ? this.editFormData.prerequisite_lesson : '',
          type: 'select',
          // validations: [{ message: 'Pre-Requisite Lesson is Required' }],
        },
        {
          label: 'Quiz Accuracy',
          name: 'accuracy',
          value: this.editFormData && this.editFormData.accuracy ? this.editFormData.accuracy : '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Quiz Accuracy is Required' },

          ],
        },

        {
          label: "Description",
          name: "description",
          type: "editor",
          ckeConfig: this.ckeConfig,
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description field Needs to be required" },
          ]
        },
        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },
        {
          label: this.editFormData ? 'Edit Images' : 'Upload Images',
          name: 'image',
          type: 'file',
          // validations: [{ rule: 'required', message: 'Image is Required' }],
          // multiple: true,
          prefix: Date.now(),
          // path: 'practice2lab-training/',
          // baseurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
          // bucket: 'pece-training-files',
          // apiurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
          // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value:
            this.editFormData &&
              this.editFormData.image && this.editFormData.image.length > 0 &&
              this.editFormData.image[0].name
              ? {
                url: this.editFormData.image[0].baseurl,
                type: 'image',
                name: this.editFormData.image[0].name,
              }
              : undefined,
        },
        {
          label: this.editFormData ? 'Edit Audio' : 'Upload Audios',
          name: 'audio',
          type: 'file',
          // validations: [{ rule: 'required', message: 'Audio is Required' }],
          // multiple: true,
          prefix: Date.now(),
          // path: 'practice2lab-training/',
          // baseurl:'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
          // bucket: 'pece-training-files',
          // apiurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
          // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value:
            this.editFormData &&
              this.editFormData.audio && this.editFormData.audio.length > 0 &&
              this.editFormData.audio[0].name
              ? {
                url: this.editFormData.audio[0].baseurl,
                type: 'audio',
                name: this.editFormData.audio[0].name,
              }
              : undefined,
        },
        {
          label: this.editFormData ? 'Edit Video' : 'Upload Videos',
          name: 'video',
          type: 'file',
          // validations: [{ rule: 'required', message: 'Videos is Required' }],
          // multiple: true,
          prefix: Date.now(),
          // path: 'practice2lab-training/',
          // baseurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
          // bucket: 'pece-training-files',
          // apiurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
          // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value:
            this.editFormData &&
              this.editFormData.video && this.editFormData.video.length > 0 &&
              this.editFormData.video[0].name
              ? {
                url: this.editFormData.video[0].baseurl,
                type: 'video',
                name: this.editFormData.video[0].name,
              }
              : undefined,
        }
      ]
    }
  }

  fetchAllCategories() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/trainingcategorylist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            response.results.res.forEach((e: any) => {
              let obj: any = { name: e.training_category_name, val: e._id };
              this.categoryList.push(obj);
            })
          };
        }
      }
    })
  }


  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val) {
      if (val && val.field.name === 'category_id') {
        console.log("this.prequisiteLessons============>", this.prequisiteLessons)
        const prequisiteLessonsLength = this.prequisiteLessons.length
        for (let i = 0; i < prequisiteLessonsLength; i++) {
          if (this.prequisiteLessons.length > 0) this.prequisiteLessons.pop()

        }
        console.log("this.prequisiteLessons after pop==========>", this.prequisiteLessons)
        let payload: any = {
          searchcondition: { category_id: val.fieldval }
        }
        this.apiservice
          .httpViaPost(`training/getprerequisitelesson`, payload)
          .subscribe({
            next: (e) => {
              if (e.results && e.results.res.length > 0) {
                for (let i in e.results.res) {
                  const data = e.results.res[i]
                  this.prequisiteLessons.push({
                    val: data._id,
                    name: data.name.trim(),
                  })
                }
                console.log("this.prequisiteLessons after push==========>", this.prequisiteLessons)
                this.formfieldrefreshdata = {
                  field: 'removefromcontrol',
                  value: { name: 'prerequisite_lesson' },
                }

                setTimeout(() => {
                  this.formfieldrefreshdata = {
                    field: 'addfromcontrol',
                    value: {
                      label: 'Pre-Requisite Lesson',
                      name: 'prerequisite_lesson',
                      type: 'select',
                      multiple: false,
                      val:
                        e?.results?.res && Array.isArray(e.results.res)
                          ? e.results.res.map((val: any) => ({
                            val: val._id,
                            name: val.name.trim(),
                          }))
                          : [],
                      after: 'category_id',
                      // validations: [
                      //   {
                      //     rule: 'required',
                      //     message: 'Pre-Requisite Lesson is Required',
                      //   },
                      // ],
                    },
                  };
                }, 10);
              };
            },
            error: (e) => {
              console.log('error==================>', e);
            },
          });
      }
      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        console.log("val.fromval", val.fromval);
        console.log("dataobj this.editFormData", this.editFormData)
        let dataobj: any = {
          data: val.fromval
        }
        if (this.editFormData) {
          dataobj.data._id = this.editFormData._id;
          if (this.editFormData.audio && this.editFormData.audio.length > 0 && this.editFormData.audio[0] !== '') {
            if (dataobj.data.audio.fileservername == undefined) {
              dataobj.data.audio.fileservername = val.this.editFormData.audio[0].fileservername ? this.editFormData.audio[0].fileservername : undefined;
            }
            if (dataobj.data.audio.size == undefined) {
              dataobj.data.audio.size = this.editFormData.audio[0].size ? this.editFormData.audio[0].size : undefined;
            }
          }
          if (this.editFormData.image && this.editFormData.image.length > 0 && this.editFormData.image[0] !== '') {
            if (dataobj.data.image.fileservername == undefined) {
              dataobj.data.image.fileservername = this.editFormData.image[0].fileservername ? this.editFormData.image[0].fileservername : undefined;
            }
            if (dataobj.data.image.size == undefined) {
              dataobj.data.image.size = this.editFormData.image[0].size ? this.editFormData.image[0].size : undefined;
            }
          }
          if (this.editFormData.video && this.editFormData.video.length > 0 && this.editFormData.video[0] !== '') {
            if (dataobj.data.video.fileservername == undefined) {
              dataobj.data.video.fileservername = this.editFormData.video[0].fileservername ? this.editFormData.video[0].fileservername : undefined;
            }
            if (dataobj.data.video.size == undefined) {
              dataobj.data.video.size = this.editFormData.video[0].size ? this.editFormData.video[0].size : undefined;
            }
          }
        }
        console.log("dataobj this.editFormData", this.editFormData)
        console.log("dataobj", dataobj)
        this.apiservice.httpViaPost('training/addeditlesson', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-lesson-list`);
            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })

      }
      if (val.field && val.field == "formreset") {
        this.formfieldrefreshdata = {
          field: "training_category_des",
          value: '',
        };
      }
    }
  }
}
