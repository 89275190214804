<h2 class="alerth2">
    <mat-icon>report_problem</mat-icon>&nbsp;&nbsp;Alert
</h2>
<ng-container>
    <label class="labelRext">Are you sure you want to resend email to all patients who have't submitted assessment form? </label>
    <div class="signmodalgroup">
        <button type="submit" mat-raised-button color="primary" (click)="resendemail('yes')">Confirm</button>
        <button type="button" mat-raised-button color="accent" (click)="resendemail('no')">Cancel</button>
    </div>
</ng-container>
