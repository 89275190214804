<!-- <button class="close_button">
    <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
</button> -->

<div class="approve_modal_mainwrapper">
    <button mat-button (click)="modalClose()" class="closealert"> <mat-icon>close</mat-icon></button>
    <ng-container *ngIf="userType == null">
        <p>YOU ARE ABOUT TO CHANGE STATUS OF THESE RECORD(S)</p>
        <div class="approve_button_wrapper">
            <button mat-button class="accept" (click)="accept()">Accept</button>
            <button mat-button class="reject" (click)="reject()">Reject</button>
        </div>
    </ng-container>
    <ng-container *ngIf="userType !== null">
        <p style="text-align: center;">ADD PATIENT</p>
        <div class="approve_button_wrapper">
            <ng-container *ngIf="cookieData.user_type !== 'sales_person'">
                <button mat-button class="accept" (click)="emailIntake()">Email Intake</button>
            </ng-container>
            <button mat-button class="reject" (click)="patientIntake()">Patient Intake</button>
        </div>
    </ng-container>
    <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
</div>