import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  public toggleStatus: boolean = false;
  public btnName: any = 'menu';
  public cookieData: any = {};
  public fullName: any = '';
  public activemenu: any = '';
  public logoutLoader: boolean = false;
  public userType: any;

  public isLaboratoryUser: boolean = false;
  public ispracticeUser: boolean = false;
  public demoDoctorDashboard: boolean = false;
  public logo_data: any;
  public logo_data_flag: boolean = false;

  constructor(public cookieService: CookieService, public router: Router, public activateRoute: ActivatedRoute, private _snackBar: MatSnackBar, private http: HttpServiceService) {
    window.scroll(0, 0);
  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (window.location.pathname.includes('dashboard/doctor') || window.location.pathname.includes('sales_person/doctor') || window.location.pathname.includes('sales_person/preview-intake')) {
      this.demoDoctorDashboard = true;
    }

    this.userType = this.cookieData.user_type;
    // console.log("userType", this.userType);

    console.log("this.userType", this.userType)

    //  this.fullName = this.cookieData.firstname + ' ' + this.cookieData.lastname;

    switch (this.cookieData.user_type) {
      case 'admin':
        this.fullName = this.cookieData.firstname + ' ' + this.cookieData.lastname;
        break;
      case 'laboratory':
        this.fullName = this.cookieData.laboratory_name;
        this.isLaboratoryUser = true;
        break;

      case 'practice':
        this.fullName = this.cookieData.practice_name;
        this.ispracticeUser = true;
        if (this.cookieService.getAll()['login_user_logo']) {
          this.logo_data = JSON.parse(this.cookieService.get('login_user_logo'));
          this.logo_data_flag = true;
        } else {
          this.findLogo(this.cookieData.parent_laboratory);
        }
        break;


      case 'sales_person':
        this.fullName = this.cookieData.firstname + ' ' + this.cookieData.lastname;
        // this.findLogo(this.cookieData.parent_laboratory);
        break;

      case 'doctor':
        this.fullName = this.cookieData.firstname + ' ' + this.cookieData.lastname;
        if (this.cookieService.getAll()['login_user_logo']) {
          this.logo_data = JSON.parse(this.cookieService.get('login_user_logo'));
          this.logo_data_flag = true;
        } else {
          this.findLogo(this.cookieData.parent_laboratory);
        }
        break;
    }



    // this.fullName = this.cookieData.firstname + ' ' + this.cookieData.lastname;


    console.log("activateRoutesss", this.activateRoute)
    if (this.activateRoute.snapshot.routeConfig.path.includes("user-management")) {
      this.activemenu = 'user'
    } else if (this.activateRoute.snapshot.routeConfig.path == "" || window.location.pathname.includes('dashboard')) {
      this.activemenu = 'dashboard'
    } else if (this.activateRoute.snapshot.routeConfig.path.includes("template") || this.activateRoute.snapshot.routeConfig.path.includes("slug")) {
      this.activemenu = 'email'
    } else if (this.activateRoute.snapshot.routeConfig.path.includes("training-category-list") || this.activateRoute.snapshot.routeConfig.path.includes("training-category-add") || this.activateRoute.snapshot.routeConfig.path.includes("training-category-edit") || this.activateRoute.snapshot.routeConfig.path.includes("training-lesson-list") || this.activateRoute.snapshot.routeConfig.path.includes("training-lesson-add") || this.activateRoute.snapshot.routeConfig.path.includes("training-lesson-edit") || this.activateRoute.snapshot.routeConfig.path.includes("training-center")) {
      this.activemenu = 'training'
    } else if (this.activateRoute.snapshot.routeConfig.path == "list" || window.location.pathname.includes('taxonomies/edit') || window.location.pathname.includes('taxonomies/add')) {
      this.activemenu = 'taxonomies'
    }
    else if (this.activateRoute.snapshot.routeConfig.path.includes("assay-list") || this.activateRoute.snapshot.routeConfig.path.includes("symptoms-list") || this.activateRoute.snapshot.routeConfig.path.includes("diagnostic-category-list") || this.activateRoute.snapshot.routeConfig.path.includes("diagnostic-list") || this.activateRoute.snapshot.routeConfig.path.includes("equipment-list") || window.location.pathname.includes('miscellaneous')) {
      this.activemenu = 'assay'
    }
    else if (this.activateRoute.snapshot.routeConfig.path.includes("email-intake-list") || this.activateRoute.snapshot.routeConfig.path.includes("add-email-intake") || window.location.pathname.includes('sales_person/doctor/patient-intake')) {
      this.activemenu = 'emailintake'
    }
    else if (this.activateRoute.snapshot.routeConfig.path.includes("insurance-list") || this.activateRoute.snapshot.routeConfig.path.includes("insurance-add") || window.location.pathname.includes('insurance-edit')) {
      this.activemenu = 'insurance'
    }
    else if (this.activateRoute.snapshot.routeConfig.path.includes("lab-reports") || this.activateRoute.snapshot.routeConfig.path.includes("ai-reports")) {
      this.activemenu = 'reports'
    }
    else if (this.activateRoute.snapshot.routeConfig.path.includes("partial-patient-list")) {
      this.activemenu = 'partial'
    }
    // else { this.activemenu = 'taxonomies' }
    console.log("this.activemenu", this.activemenu, this.activateRoute);


  }

  findLogo(id) {
    let data = {
      searchcondition: {
        _id: id
      }
    }

    this.http.httpViaPost('users/fetchlogo', data).subscribe((response) => {
      if (response) {
        console.warn('User>>>>>>>>', response);
        if (typeof response.results.res[0].image != 'undefined' && response.results.res[0].image != null && response.results.res[0].image != 'NA') {
          this.logo_data = response.results.res[0].image;
          this.logo_data_flag = true;
          this.cookieService.set('login_user_logo', JSON.stringify(response.results.res[0].image));
        }
      }
    })
  }

  logout() {
    // this.cookieService.delete('jwtToken'); 
    this.logoutLoader = true;
    this.cookieService.delete('login_user_details', '/');
    this.cookieService.delete('loggedin_user', '/');
    this.cookieService.delete('currentUser', '/');
    this.cookieService.delete('currentUserStatus', '/');
    this.cookieService.deleteAll('../');
    this.cookieService.set('loggedout_user', 'true');
    this.cookieService.delete('login_user_logo', '/');
    setTimeout(() => {
      this._snackBar.open('Logging out! ', '', {
        duration: 2000
      });
    }, 1000)
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 3000)

    setTimeout(() => {
      location.reload();
    }, 3100)

    // setTimeout(() => {
    //   this.logoutLoader = false;
    //   this.router.navigateByUrl('login');
    //   this._snackBar.open('You have successfully logged out! ', '', {
    //     duration: 2000
    //   });
    //   // setTimeout(() => {
    //   //   location.reload()
    //   // }, 1000)
    // }, 2000);
  }
  changeLabLogo() {
    if (this.cookieData.user_type) {

      this.router.navigateByUrl(this.cookieData.user_type + '/update-logo');

    }
  }
  myAccount() {
    // console.log("myAccount")
    // this.router.navigateByUrl('account-settings')

    console.log("this.cookieData", this.cookieData)

    if (this.cookieData.user_type) {

      this.router.navigateByUrl(this.cookieData.user_type + '/account-settings');

    }
  }

  manageLocation() {


    if (this.cookieData.user_type) {

      this.router.navigateByUrl(this.cookieData.user_type + '/manage-location');

    }

  }

  changePassword() {

    if (this.cookieData.user_type) {

      this.router.navigateByUrl(this.cookieData.user_type + '/change-password');

    }


    // console.log("changePassword")
    // this.router.navigateByUrl('change-password')
  }




  toggleMenu() {
    this.toggleStatus = !this.toggleStatus;
    if (this.toggleStatus)
      this.btnName = "clear";
    else
      this.btnName = "menu";
  }
  dashboardPage() {
    if (this.cookieData.user_type) {
      if (this.demoDoctorDashboard) {
        this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard/doctor`);
      } else {
        this.router.navigateByUrl(this.cookieData.user_type + '/dashboard');
      }
    }
  }



  userListPage() {
    console.log("this.cookieData", this.cookieData)
    if (this.cookieData.user_type) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/users`);
    }
  }
  trainingCenter() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          let data = {
            condition: {
              limit: 10,
              skip: 0,
            },
            sort: {
              type: 'desc',
              field: '_id'
            },
            searchcondition: {}
          }
          this.http.httpViaPost('training/trainingcategorylist', data).subscribe((response: any) => {
            console.log("fetchAllCategories", response)
            if (response) {
              if (response.results && response.results.res) {
                if (response.results.res.length > 0) {
                  this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-center`);
                };
              }
            }
          })
          break;
      }
    }
  }
  trainingCategory() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-category-list`);
          break;
      }
    }
  }
  trainingLesson() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-lesson-list`);
          break;
      }
    }
  }
  emailTemplate() {
    console.log("this.cookieData", this.cookieData)
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl('admin/email-template/template-list');
          break;
      }
    }
  }
  emailSlug() {
    this.router.navigateByUrl('admin/email-template/slug-list');
  }
  taxonomies() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl('admin/taxonomies/list');
          break;

      }
    }
  }

  labReport() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl('admin/lab-reports');
          break;

      }
    }
  }

  aiReport() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl('admin/ai-reports');
          break;

      }
    }
  }

  insurance() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          this.router.navigateByUrl('admin/manage/insurance-list');
          break;

      }
    }
  }

  partialLeads() {
    if (this.cookieData.user_type) {
      switch (this.cookieData.user_type) {
        case 'doctor':
          this.router.navigateByUrl('doctor/partial-patient-list');
          break;
        case 'practice':
          this.router.navigateByUrl('practice/partial-patient-list');
          break;
        case 'admin':
          this.router.navigateByUrl('admin/partial-patient-list');
          break;
      }
    }
  }

  // miscellaneous() {
  //   if (this.cookieData.user_type) {
  //     switch (this.cookieData.user_type) {
  //       case 'admin':
  //         this.router.navigateByUrl('admin/taxonomies/list');
  //         break;

  //     }
  //   }
  // }


  assay() {

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/assay-list`);
  }

  cptCodeRoute() {

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/cpt-list`);
  }
  icdCodeRoute() {

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/icd-list`);
  }

  diagnosticCategory() {

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-category-list`);
  }
  diagnostic() {
    // this.router.navigateByUrl('admin/miscellaneous/diagnostic-list');

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-list`);
  }

  symptoms() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/symptoms-list`);
  }
  mechinery() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/equipment-list`);

  }
  emailintake() {
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/doctor/email-intake-list`);
    } else {
      this.router.navigateByUrl(`${this.cookieData.user_type}/email-intake-list`);
    }
  }

  patientlintake() {
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/doctor/patient-intake`);
    } else {
      this.router.navigateByUrl(`${this.cookieData.user_type}/patient-intake`);
    }
  }

  labDiagnosis() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/diagnostic-list`);
  }

  evaluation() {

    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/evaluation-list`);
  }


}
