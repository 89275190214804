import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-treatment-protocal',
  templateUrl: './treatment-protocal.component.html',
  styleUrls: ['./treatment-protocal.component.css']
})
 
export class TreatmentProtocalComponent {

  public TreatmentProtocalDataset:any;

 

  @Input() set treatmentProtocal(value: any) {

    if (value) {
    

      this.TreatmentProtocalDataset=value;

   
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}