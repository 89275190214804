import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
// import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

export interface DialogData {
  data: any;
  flag: any;
  name: any;
  value: any;

}

@Component({
  selector: 'app-manage-location',
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.css']
})
export class ManageLocationComponent {

  public managelocationData: any;
  public managelocationNoData: boolean = false;

  public addFormLoader: boolean = false;
  public adduserID: any;

  public isedit: boolean = false;

  public locationcount: any = 0;
  public skipcount: any = 0;
  public limitcount: any = 5;
  public managelocationDATAFlag: boolean = false;

  constructor(public cookieService: CookieService, public apiservice: HttpServiceService, private activateRoute: ActivatedRoute, public matSnackBar: MatSnackBar, public dialog: MatDialog) {

    this.adduserID = JSON.parse(this.cookieService.getAll()['login_user_details']);
    console.log('adduserID', this.adduserID)

    this.activateRoute.data.subscribe((response: any) => {

      console.log("managelocation++", response)



      if (response) {
        if (response.data && response.data.status == "success") {

          this.managelocationData = response.data.results.res;

          if (this.managelocationData.length == 0) {
            this.managelocationNoData = true
          }

          console.log("managelocationdata++", this.managelocationData)

          let datacount: any = {
            condition: {
              condition: {
                skip: this.skipcount,
                limit: this.limitcount,
              },
            },



            sort: {
              "type": "desc",
              "field": "_id"
            },

            user_id: this.adduserID._id
          }


          this.apiservice.httpViaPost('users/fetchLocationDetails-count', datacount).subscribe((response) => {

            if (response) {
              if (response.status == "success") {

                this.locationcount = response.count

                console.log('locationcount', this.locationcount)





              }

            }

          })






        }

      }





    })




  }


  loadloaction(type?: string) {
    let dataload: any = {
      condition: {
        skip: this.skipcount,
        limit: this.limitcount,
      },
      user_id: this.adduserID._id
    }
    this.apiservice.httpViaPost('users/fetchLocationDetails', dataload).subscribe((response) => {
      if (response) {
        if (type) {
          this.managelocationData = response.results.res
        } else {
          response.results.res.map((e: any) => {
            this.managelocationData.push(e)
          })
        }
      }
    });
    this.apiservice.httpViaPost('users/fetchLocationDetails-count', dataload).subscribe((response) => {

    });
  }


  deleteLocation(item: any, index: number) {
    console.log("this.managelocationData", this.managelocationData)

    this.addFormLoader = true;

    this.managelocationData = this.managelocationData.filter((e: any, i: number) => {
      return i != index
    });

    if (item._id) {
      let data: any = {
        "id": item._id
      }
      this.apiservice.httpViaPost('users/deletelocation', data).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {
          this.skipcount = 0
          this.loadloaction('fetch')

          // let dataload: any = { user_id: this.adduserID._id }
          // dataload.condition = {
          //   skip: 0,
          //   limit: 5,
          // }

          // this.apiservice.httpViaPost('users/fetchLocationDetails', dataload).subscribe((response) => { });

          // let datacount: any = {
          //   condition: {
          //     condition: {
          //       skip: 0,
          //       limit: 5,
          //     },
          //   },



          //   sort: {
          //     "type": "desc",
          //     "field": "_id"
          //   }, 
          //   user_id: this.adduserID._id
          // }

          // this.apiservice.httpViaPost('users/fetchLocationDetails-count', datacount).subscribe((response) => {  });


          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }
        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }



      })
    }



  }


  editLocation(item: any, index: number) {
    console.log("this.managelocationData", item, index)

    const dialogRef = this.dialog.open(locationMmodal, {
      height: "500px",
      width: "600px",
      panelClass: "externaldata-class",
      data: {
        heading: "Edit Location Data",
        item: item
      },

    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res', res)

      this.managelocationData.forEach((e: any, i: number) => {
        if (index == i && res && res.formObj) {
          this.managelocationData[i] = res.formObj
        }
      })

    })


  }


  addLocationmodal() {

    // alert("addLocation");
    const dialogRef = this.dialog.open(locationMmodal, {
      height: "500px",
      width: "600px",
      panelClass: "externaldata-class",
      data: {
        heading: "Add Location Data",
        user_id: this.adduserID._id

      },

    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res', res)
      if (res && res.formObj) {
        console.log('managelocationData+++++', this.managelocationData)
        this.managelocationData.push(res.formObj)

        console.log('managelocationData+++++', this.managelocationData)

      }


    })

    dialogRef.afterClosed().subscribe((afterCloseclick: any) => {
      console.log("afterCloseclick", afterCloseclick);
      if (afterCloseclick.existLab) {
        this.skipcount = 0
        this.loadloaction('fetch')
        //  alert('afterCloseclick true')

        // let dataload: any = { user_id: this.adduserID._id }
        // dataload.condition = {
        //   skip: 0,
        //   limit: 5,
        // }

        // this.apiservice.httpViaPost('users/fetchLocationDetails', dataload).subscribe((response) => { });

        // let datacount: any = {
        //   condition: {
        //     condition: {
        //       skip: 0,
        //       limit: 5,
        //     },
        //   },



        //   sort: {
        //     "type": "desc",
        //     "field": "_id"
        //   }, 
        //   user_id: this.adduserID._id
        // }

        // this.apiservice.httpViaPost('users/fetchLocationDetails-count', datacount).subscribe((response) => {  });

      }


    });


  }


  loadmorelocation() {
    this.addFormLoader = true;
    this.managelocationDATAFlag = false;
    let isShowLessloc: boolean = false;
    let balanceItemsloc: number = 0;
    if (this.managelocationData.length == this.locationcount) {
      if (this.managelocationData.length > this.limitcount) {
        balanceItemsloc = this.managelocationData.length - this.limitcount;
        console.log("show less balanceItemsloc", balanceItemsloc)
        if (balanceItemsloc > this.limitcount) {
          this.skipcount = this.skipcount - this.limitcount;
        } else {
          this.skipcount = 0;
        }
      }
      isShowLessloc = true;
    }
    else {
      this.skipcount = this.skipcount + 5;
    }

    console.log("show less", this.managelocationData.length, 'count', this.locationcount, "balanceItemsloc", balanceItemsloc)

    // let data: any = { id: this.folderviewdatalist._id }
    // data.id = this.folderviewdatalist._id;
    // data.condition = {
    //   skip: this.skipcount, limit: this.limitcountloc,
    // }
    // data.sort = { "field": "_id", "type": "desc" };


    let data: any = { user_id: this.adduserID._id }
    data.condition = {
      skip: this.skipcount,
      limit: this.limitcount,
    }


    this.apiservice.httpViaPost('users/fetchLocationDetails', data).subscribe((response) => {
      // console.log("dataobj loadmoreNotes", response)
      if (response) {
        if (response.results.res && response.results.res.length > 0) {
          if (isShowLessloc) {
            this.managelocationData = this.managelocationData.slice(0, this.managelocationData.length - balanceItemsloc);
          } else {
            response.results.res.map((e: any) => {
              this.managelocationData.push(e)
            })
          }
        }
        else {
          // this.loadButtonNotes = "Show Less";
        }
        this.addFormLoader = false;
      }
    })

    let datacount: any = {
      condition: {
        condition: {
          skip: this.skipcount,
          limit: this.limitcount,
        },
      },



      sort: {
        "type": "desc",
        "field": "_id"
      },
      // searchcondition: { _id: this.folderviewdatalist._id }
      user_id: this.adduserID._id
    }
    this.apiservice.httpViaPost('users/fetchLocationDetails-count', datacount).subscribe((response) => {
      if (this.skipcount > this.locationcount) {

      }
      this.locationcount = response.count;

    })



  }







}


@Component({
  selector: 'location-modal',
  templateUrl: 'location-modal.html',
})
export class locationMmodal {
  public modaldata: any = {};
  public addLocationForm: FormGroup;
  public stateList: any = [];
  public isedit: boolean = false;
  public addFormLoader: boolean = false;

  public adduserID: any;
  constructor(
    public dialogRef: MatDialogRef<locationMmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public httpService: HttpServiceService, public matSnackBar: MatSnackBar, public cookieService: CookieService,
  ) {


    this.modaldata = data
    console.log(this.modaldata, "modal data++");


    this.adduserID = JSON.parse(this.cookieService.getAll()['login_user_details']);
  }

  ngOnInit() {
    this.getStates();
    this.addLocationForm = new FormGroup({
      location_name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),

    })
    if (
      this.modaldata.item
    ) {
      this.isedit = true;
      this.addLocationForm.patchValue({

        location_name: this.modaldata.item.location_name,
        address: this.modaldata.item.address,
        city: this.modaldata.item.city,
        state: this.modaldata.item.state,
        zip: this.modaldata.item.zip,
        phone: this.modaldata.item.phone
      })
    }


  }

  getStates() {
    this.httpService.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      // console.log(response, "this.stateList")

      this.stateList = response;
    })
  }

  phoneFormatting(event: any) {
    // console.log("event", this.addLocationForm.controls);
    var formatted = this.formatPhoneText(event.target.value);
    // console.log("event formatted", formatted);
    this.addLocationForm.patchValue({
      phone: formatted
    })
  }
  formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  }

  addLocation() {



    if (this.addLocationForm.valid) {

      this.addFormLoader = true;
      console.log(this.modaldata, "modal dataSubmit");

      console.log(this.addLocationForm.value, "addLocationForm");


      this.addLocationForm.markAllAsTouched();

      if (this.modaldata.item && this.modaldata.item._id && this.modaldata.item.user_id) {
        let data: any = this.addLocationForm.value;
        data._id = this.modaldata.item._id;
        data.user_id = this.modaldata.item.user_id;
        console.log("submit data", data)

        this.httpService.httpViaPost('users/locationaddedit', data).subscribe((response) => {
          console.log("dataobj", response)
          if (response) {

            this.dialogRef.close({ formObj: this.addLocationForm.value, existLab: true });

          }

          if (response && response.status == 'success') {






            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }
          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })


      }
      else {

        let data: any = this.addLocationForm.value;
        //add data user id
        data.user_id = this.modaldata.user_id;


        this.httpService.httpViaPost('users/locationaddedit', data).subscribe((response) => {
          console.log("dataobj", response)

          if (response) {

            this.dialogRef.close({ formObj: this.addLocationForm.value, existLab: true });



          }



          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }
          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })
      }




      // else {
      //   // this.resolveLoader = false;
      //   this.dialogRef.close({ data: this.data, formObj: this.addLocationForm.value });
      // }


    }


  }



  onNoClick(): void {
    this.dialogRef.close();
    // this.data.flag = "no";


  }




}


