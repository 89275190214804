<!-- <button class="close_button">
    <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
</button> -->

<div class="approve_modal_mainwrapper">
    <p>YOU ARE ABOUT TO CHANGE STATUS OF THESE RECORD(S)</p>
    <div class="approve_button_wrapper">
        <button mat-button class="accept" (click)="accept()">Accept</button>
        <button mat-button class="reject" (click)="reject()">Reject</button>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
</div>