import { Component, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-assay-map-with-icd-code',
  templateUrl: './assay-map-with-icd-code.component.html',
  styleUrls: ['./assay-map-with-icd-code.component.css']
})
export class AssayMapWithIcdCodeComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/assaymapicdstatus";
  deleteendpoint = "intake/deleteassaymapicd";
  datacollection: any = "intake/assaymapicdist";
  public listprogressBar: any = false;
  public editprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/assaymapicdist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    mdm_label: "Label",
    em_code_name: "E&M Code",
    icd_code_name: "ICD Code",
    icd_min_value: "Min Value",
    icd_max_value: "Max Value",
    status: "Status"
  };

  public assay_map_with_icd_code_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;

  public addFormLoader: boolean = false;
  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public icdCodeAutocompleteObj: any = null;
  public emSelectionObj: any = null;
  public addEditFormOpen: boolean = false;
  public emlist: any = [];
  public mdmLabelList: any = [{ name: 'Moderate', val: 'Moderate' }, { name: 'Low', val: 'Low' }, { name: 'High', val: 'High' }]

  search_settings: any = {

    textsearch: [{
      label: "Search By Label", field: 'mdm_label'
    }],
    search: [
      { label: "Search By E&M Code", field: 'em_code_id', serversearchdata: { endpoint: 'intake/evaluationautocomplete' } },
      { label: "Search By ICD Code", field: 'icd_id', serversearchdata: { endpoint: 'intake/icdcodeautocomplete' } }
    ],

    // datesearch: [
    // {
    //   startdatelabel: 'Update On Start Date',
    //   enddatelabel: 'Update On End Date',
    //   submit: 'Search',
    //   field: 'updatedon_datetime',
    //   // value: {$gte: createdon_datetime, $lte: 1622962799000}
    // },
    // ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["mdm_label", "em_code_name", "icd_code_name", "status"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Assay',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "mdm_label", val: "Label" },
      { key: "em_code_name", val: "E&M Code" },
      { key: "icd_code_name", val: "ICD Code" },
      { key: "icd_min_value", val: "Min Value" },
      { key: "icd_max_value", val: "Max Value" },
      { key: "status", val: 'Status' }



    ],
    updateendpoint: "intake/assaymapicdstatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/assaymapicdstatus",

    deleteendpointmany: "intake/deleteassaymapicd",
    tableheaders: ["mdm_label", "em_code_name", "icd_code_name", "icd_min_value", "icd_max_value", "status"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      }
    ],

  }




  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public dialogRef: MatDialogRef<AssayMapWithIcdCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    // this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {
    console.log("modal data", this.data)
    this.progressLoader = true;

    this.userListFetching();
    this.fetchEMList();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    console.log("cookieService", this.cookieData)
  }

  fetchEMList() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }



    this.httpService.httpViaPost('intake/evaluationlist', data).subscribe((response: any) => {
      console.log("activatedRoute", response)
      if (response) {
        this.emlist = response.results.res;
        if (this.emlist.length > 0) {
          this.emlist.forEach((e: any, i: number) => {
            e.val = e._id;
            e.name = e.code
          })
        }
      }
    })
  }

  userListFetching() {
    this.listprogressBar = true
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }



    this.httpService.httpViaPost('intake/assaymapicdist', data).subscribe((response: any) => {

      console.log("activatedRoute", response)
      if (response) {

        this.tabledatatalist = response.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })


    this.httpService.httpViaPost('intake/assaymapicdist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })
  }
  addBtn() {
    this.addEditFormOpen = true;
    this.editFormData = null;
    this.initailForm();
  }
  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data && data.custombuttonclick && data.custombuttonclick.btninfo.id == 'edit_btn') {
      this.editprogressBar = true;
      let payload: any = {
        searchcondition: {
          _id: data.custombuttonclick.data._id
        }
      }
      this.httpService.httpViaPost('intake/getmapicddata', payload).subscribe((response: any) => {
        console.log("Op=====>1", response);
        if (response) {
          this.editFormData = response.results.res[0]
          this.editListItem(this.editFormData);
          this.addEditFormOpen = true;
        }
      })



    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.editprogressBar = false;
      this.initailForm();
    }
  }



  initailForm() {
    console.log("this.editFormData", this.editFormData)
    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      //cancelroute: '', // use for redirect after cancel the form

      fields: [

        {
          label: 'Label',
          name: 'mdm_label',
          type: 'select',
          val: this.mdmLabelList,
          value: this.editFormData && this.editFormData.mdm_label ? this.editFormData.mdm_label : '',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Label is Required' },

          ],
        },
        {
          label: 'Min Value',
          name: 'icd_min_value',
          // group: "grp1",
          value: this.editFormData && this.editFormData.icd_min_value ? this.editFormData.icd_min_value : '',
          type: 'number',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Min Value is Required' },
            { rule: 'min', value: 0, message: 'Min Value must be greated than 0' },
          ],
        },
        {
          label: 'Max Value',
          name: 'icd_max_value',
          // group: "grp1",
          value: this.editFormData && this.editFormData.icd_max_value ? this.editFormData.icd_max_value : '',
          type: 'number',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Max Value is Required' },
            { rule: 'min', value: 0, message: 'Min Value must be greated than 0' },
          ],
        },
        {
          label: "ICD Code",
          name: "icd_id",
          // group: "grp1",
          type: 'autocomplete',
          endpoint: "intake/icdcodeautocomplete",
          search_field: "name_search",
          val: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.icd_data_val && this.editFormData.icd_id ? [{ key: this.editFormData.icd_id, val: this.editFormData.icd_data_val }] : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.icd_id ? this.editFormData.icd_id : [],
          validations: [
            { rule: 'required', message: 'ICD Code  is Required' }
          ]

        },
        {
          label: "E&M Code",
          name: "em_code_id",
          type: 'select',
          val: this.emlist,
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.em_code_id : [],
          validations: [
            { rule: 'required', message: 'ICD Code  is Required' }
          ]

        },
        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarray,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
      // this.locationFieldManage(this.editFormData.location)
    }

  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val, this.editFormData)
    if (val.autocompletedata) {
      this.icdCodeAutocompleteObj = val.autocompletedata;
    }
    if (val.fieldval && val.field && val.field.type == 'select' && val.field.name == 'em_code_id') {
      val.field.val.forEach((e: any) => {
        if (e._id == val.fieldval) {
          this.emSelectionObj = e
        }
      })
    }

    if (val.field && val.field == "fromsubmit") {
      if (val.fromval && val.fromval.icd_min_value) {
        if (JSON.parse(val.fromval.icd_min_value) > JSON.parse(val.fromval.icd_max_value)) {
          this.matSnackBar.open("Max Value must be greated than Min value", "Ok", {
            duration: 1000
          });
        } else {
          this.addFormLoader = true;
          let dataobj = {
            data: val.fromval
          }
          dataobj.data.em_code_id = this.emSelectionObj && this.emSelectionObj.val ? this.emSelectionObj.val : this.editFormData && this.editFormData.em_code_id ? this.editFormData.em_code_id : null;
          dataobj.data.icd_id = this.icdCodeAutocompleteObj.key;
          dataobj.data.icd_data_val = this.icdCodeAutocompleteObj.val;
          dataobj.data.assay_id = this.data.modalData;

          if (this.editFormData) {
            dataobj.data._id = this.editFormData._id
          }

          console.log('dataobjTest', dataobj)
          this.httpService.httpViaPost('intake/mapicdwithassay', dataobj).subscribe((response) => {
            console.log("dataobj", response)
            if (response && response.status == 'success') {
              this.matSnackBar.open(response.message, "Ok", {
                duration: 1000
              });
              this.tabledatatalist = []
              this.userListFetching();
              this.assay_map_with_icd_code_updatetable = !this.assay_map_with_icd_code_updatetable;
              setTimeout(() => {
                this.addEditFormOpen = false;
                this.addFormLoader = false;
              }, 1000)
            }

            if (response && response.status == 'error') {
              this.matSnackBar.open(response.message, "", {
                duration: 1000
              });
              this.addFormLoader = false;
            }

          })
        }
      }


    }
    if (val.field && val.field == "formcancel") {
      this.addEditFormOpen = false;
    }
    if (val.field && val.field == "formreset") {

    }
  }
}
