<div class="addEditPageWrapper intake_formwrapper physician_intake_formwrapper">
    <div class="addEditForm">
        <!-- <ng-container *ngIf="aspinnerLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container> -->
        <div class="form-group patientInfoFormHeading ">
            <div class="form_field_wrapper">
                <mat-card>
                    PERSONAL INFORMATION
                </mat-card>
            </div>
        </div>
        <form autocomplete="off" name="patientInfoForm" [formGroup]="patientInfoForm">
            <div class="form-group grp1">
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Patient Name</mat-label>
                        <input matInput type="text" name="patient_name" formControlName="patient_name" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Patient Email</mat-label>
                        <input matInput type="text" name="patient_email" formControlName="patient_email" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Patient Phone</mat-label>
                        <input matInput type="text" name="patient_phone" formControlName="patient_phone" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Patient Gender</mat-label>
                        <input matInput type="text" name="patient_gender" formControlName="patient_gender" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Patient Address</mat-label>
                        <input matInput type="text" name="patient_address" formControlName="patient_address" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>State</mat-label>
                        <input matInput type="text" name="state" formControlName="state" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input matInput type="text" name="city" formControlName="city" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Zip</mat-label>
                        <input matInput type="text" name="zip" formControlName="zip" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Date Of Birth</mat-label>
                        <input matInput type="text" name="patient_dob" formControlName="patient_dob" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Height</mat-label>
                        <input matInput type="text" name="height" formControlName="height" readonly>
                    </mat-form-field>
                </div>
                <div class="form_field_wrapper form_field_wrapperpatient_name">
                    <mat-form-field appearance="fill">
                        <mat-label>Weight</mat-label>
                        <input matInput type="text" name="weight" formControlName="weight" readonly>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <ng-container *ngIf="examinationData">
            <div class="container libformclass">
                <div class="form-group heading">
                    <div class="form_field_wrapper">
                        <mat-card class="mat-mdc-card mdc-card  ">PATIENT HISTORY QUESTIONNAIRE</mat-card>
                    </div>
                </div>
                <div class="questionnaireContainer">
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Neurological</mat-card>
                            </div>
                        </div>
                        <div class="form-group grp0body ">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Seizure Disorder (Epilepsy)</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.seizure_disorder">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.seizure_disorder">
                                    <div class="notes">{{examinationData.seizure_disorder_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Dizziness/Fainting</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.dizziness_or_fainting">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.dizziness_or_fainting">
                                    <div class="notes">{{examinationData.dizziness_or_fainting_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Weakness/Paralysis</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.weakness">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.weakness">
                                    <div class="notes">{{examinationData.weakness_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Swelling of lower extremities</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.swelling_of_lower_extremities">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.swelling_of_lower_extremities">
                                    <div class="notes">{{examinationData.swelling_of_lower_extremities_notes}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Infectious Disease</mat-card>
                            </div>
                        </div>

                        <div class="form-group grp0body ">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Tuberculosis</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.tuberculosis">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.tuberculosis">
                                    <div class="notes">{{examinationData.tuberculosis_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Hepatitis</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.hepatitis">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.hepatitis">
                                    <div class="notes">{{examinationData.hepatitis_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Mumps</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.mumps">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.mumps">
                                    <div class="notes">{{examinationData.mumps_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Measles</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.measles">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.measles">
                                    <div class="notes">{{examinationData.measles_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Syphilis</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.syphilis">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.syphilis">
                                    <div class="notes">{{examinationData.syphilis_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Gonorrhea</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.gonorrhea">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.gonorrhea">
                                    <div class="notes">{{examinationData.gonorrhea_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Venereal</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.venereal">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.venereal">
                                    <div class="notes">{{examinationData.venereal_notes}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Drug Use/Abuse</mat-card>
                            </div>
                        </div>
                        <div class="form-group grp0body ">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Depressants</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.depressants">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.depressants">
                                    <div class="notes">{{examinationData.depressants_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Stimulants</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.stimulants">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.stimulants">
                                    <div class="notes">{{examinationData.stimulants_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Narcotics</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.narcotics">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.narcotics">
                                    <div class="notes">{{examinationData.narcotics_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Alcohol</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.alcohol">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.alcohol">
                                    <div class="notes">{{examinationData.alcohol_notes}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Congenital or Genetic</mat-card>
                            </div>
                        </div>
                        <div class="form-group grp0body ">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Malformations/Deformities</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.malformations">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.malformations">
                                    <div class="notes">{{examinationData.malformations_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Blood Diseases</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.blood_diseases">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.blood_diseases">
                                    <div class="notes">{{examinationData.blood_diseases_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Multiple Births</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.multiple_births">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.multiple_births">
                                    <div class="notes">{{examinationData.multiple_births_notes}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Chronic Diseases</mat-card>
                            </div>
                        </div>
                        <div class="form-group grp0body ">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Neurological Diseases</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.neurological">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.neurological">
                                    <div class="notes">{{examinationData.neurological_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Pulmonary (Lung) Diseases</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.pulmonary">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.pulmonary">
                                    <div class="notes">{{examinationData.pulmonary_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Renal (Kidney) Diseases</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.renal_kidney">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.renal_kidney">
                                    <div class="notes">{{examinationData.renal_kidney_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Immune</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.immune">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.immune">
                                    <div class="notes">{{examinationData.immune_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Hypertension</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.hypertension">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.hypertension">
                                    <div class="notes">{{examinationData.hypertension_notes}}</div>
                                </ng-container>
                            </div>
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <div class="form-element form_field_seizure_disorder ">
                                    <mat-label>Atherosclerosis</mat-label>
                                    <mat-radio-group aria-label="Select an option" disabled="true" [(ngModel)]="examinationData.atherosclerosis">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <ng-container *ngIf="examinationData.atherosclerosis">
                                    <div class="notes">{{examinationData.atherosclerosis_notes}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="questionnaireInnerDiv">
                        <div class="form-group grp0 ">
                            <div class="form_field_wrapper ">
                                <mat-card class="mat-mdc-card mdc-card ">Additional Notes by Physician</mat-card>
                            </div>
                        </div>
                        <div class="form-group grp0body additionalNotes">
                            <div class="form_field_wrapper form_field_wrapperseizure_disorder">
                                <ng-container *ngIf="examinationData.additional_notes">
                                    <div class="notes">{{examinationData.additional_notes}}</div>
                                </ng-container>
                                <ng-container *ngIf="!examinationData.additional_notes">
                                    <div class="notes"></div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="physicianExaminationForm && objectKeys(physicianExaminationForm).length > 5 && !examinationData">
            <div class="intakeFormDiv physicianExaminationForm" [ngClass]="aspinnerLoader == false ? '' : 'hide'">
                <div [ngClass]="!subBtn ? 'libsigndiv' : 'libsigndivnone'">
                    <lib-showform [ngClass]="resetformcss ? 'resetformcss' : ''" [formdata]="physicianExaminationForm" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
                    </lib-showform>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="formSubmissionLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
    </div>
</div>