<app-header></app-header>
<mat-card class="adminBody hidepreviewbtn">
    <mat-card-content class="Emailtemplatelist listingpage_wrapper list_style1">
        <h2 class="listingpageh2"> Manage Email Intake</h2>


        <div class="admin-list_v1">

            <div class="listingtableheading">
                <div class="listingtableheading_wrapper">
                    <h2><label>Email Intake list</label>
                    </h2>
                    <button class="add_btn" (click)="addBtn()">Add Email Intake</button>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" [ngClass]="listprogressBar==true? 'show':'hide' "></mat-progress-bar>
            <div class="taxonomies_liststyle emailIntakeList" [ngClass]="listprogressBar==true? 'hide':'show' ">
                <lib-listing class="adminlisttable_v1 " *ngIf="tabledatatalist != null && tabledatatalist.length > 0" [datasource]="tabledatatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner" [deleteendpoint]="deleteendpoint"
                    [updateendpoint]="updateendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                    [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                    [limitcond]="limitcond" [customlistenbutton]="customlistenbutton" [updatetable]="taxonomy_updatetable" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)">
                </lib-listing>
            </div>
        </div>
    </mat-card-content>
    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>