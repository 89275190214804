import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-clinical-report-view',
  templateUrl: './clinical-report-view.component.html',
  styleUrls: ['./clinical-report-view.component.css']
})
export class ClinicalReportViewComponent {
  public clinicalReportAvailable: boolean = false;
  public pdfFileName: any = [];
  public clinicalReportData: any = null;
  public isPDFFile: boolean = false;
  public slideIndex: number = 0;
  public spinnerFlag: boolean = false;


  constructor(public activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer,private httpService: HttpServiceService) {
    console.warn("patient-folderview data", this.activatedRoute.snapshot.params['_id'])
    this.spinnerFlag = true;
    let data ={
        searchcondition: {
          _id:this.activatedRoute.snapshot.params['_id']
        }
      
    }
    this.httpService.httpViaPost('intake/getleaddata', data).subscribe((response: any) => {
      console.warn('rrrreeesss>>>',response)
      if (response.status == 'success') {
        this.spinnerFlag= false;
        if (response && response.results && response.results.res && response.results.res.length > 0) {

          if (response.results.res[0].clinical_report && response.results.res[0].clinical_report.length > 0) {
            this.clinicalReportAvailable = true;
            this.clinicalReportData = response.results.res[0].clinical_report;

            console.warn(response.results.res[0].clinical_report, 'Clinical>>>>>>>', typeof response.results.res[0].clinical_report);
            if (typeof this.clinicalReportData.length != 'undefined') {
              if (this.clinicalReportData.length > 0) {
                for (const value of this.clinicalReportData) {
                  if (value.type == "application/pdf") {
                    this.isPDFFile = true;
                    // console.log("window.location ", window.location)

                    this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://s3.amazonaws.com/practice2lab.com/" + value.path + value.fileservername));


                  }
                }
                setTimeout(() => {
                  this.showSlides(this.slideIndex)
                }, 1000);
              }

            }
            else {
              this.isPDFFile = true;
              this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://s3.amazonaws.com/practice2lab.com/" + this.clinicalReportData.path + this.clinicalReportData.fileservername));
            }
          }
        }
      }
    })
   }
  ngOnInit() {
    // console.log("patient-folderview data", this.activatedRoute)
    // this.activatedRoute.data.subscribe((response: any) => {
    //   console.log("patient-folderview data", response)
    //   if (response) {
    //     if (response.data && response.data.results && response.data.results.res && response.data.results.res.length > 0) {

    //       if (response.data.results.res[0].clinical_report && response.data.results.res[0].clinical_report.length > 0) {
    //         this.clinicalReportAvailable = true;
    //         this.clinicalReportData = response.data.results.res[0].clinical_report;

    //         console.warn(response.data.results.res[0].clinical_report, 'Clinical>>>>>>>', typeof response.data.results.res[0].clinical_report);
    //         if (typeof this.clinicalReportData.length != 'undefined') {
    //           if (this.clinicalReportData.length > 0) {
    //             for (const value of this.clinicalReportData) {
    //               if (value.type == "application/pdf") {
    //                 this.isPDFFile = true;
    //                 // console.log("window.location ", window.location)

    //                 this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://s3.amazonaws.com/practice2lab.com/" + value.path + value.fileservername));


    //               }
    //             }
    //             setTimeout(() => {
    //               this.showSlides(this.slideIndex)
    //             }, 1000);
    //           }

    //         }
    //         else {
    //           this.isPDFFile = true;
    //           this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://s3.amazonaws.com/practice2lab.com/" + this.clinicalReportData.path + this.clinicalReportData.fileservername));
    //         }
    //       }
    //     }
    //   }
    // })
  }

  slideClicked(type: string) {
    console.log(type)
    if (type == 'pre') {
      this.slideIndex--;;
    } else {
      this.slideIndex++;
    }
    this.showSlides(this.slideIndex);
  };
  showSlides(n) {
    let i;
    let slides = document.querySelectorAll(".isPDFFile");
    console.log("slides", n)
    // let dots = document.getElementsByClassName("dot");
    // if (n > slides.length) { this.slideIndex = 1 }
    // if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {

      if (n === i) {
        slides[i].classList.remove("displayNone");
        slides[i].classList.add("displayBlock");
      } else {
        slides[i].classList.remove("displayBlock");
        slides[i].classList.add("displayNone");
      }
    }
    // for (i = 0; i < dots.length; i++) {
    //   dots[i].className = dots[i].className.replace(" active", "");
    // }
    // let temp = slides[this.slideIndex - 1];
    // if (temp) {
    //   temp.classList.add("displayBlock")
    // }
    // if (dots) {
    //   dots[this.slideIndex - 1].className += " active";
    // }
  }

}
