<div class="signPageDialogBody" *ngIf="!previewFlg">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <h2>Add Signature</h2>

    <form (ngSubmit)="addsign()" autocomplete="off" name="addsignForm" [formGroup]="addsignForm">

        <mat-form-field>
            <mat-label>Type Your Name Here</mat-label>
            <input matInput name="signinput" formControlName="signinput" (keyup)="myFunction($event)" required>

        </mat-form-field>
        <mat-error *ngIf="!addsignForm.controls['signinput'].valid && addsignForm.controls['signinput'].touched">
            <span class="errortext">This Field is Required</span>
        </mat-error>

        <div class="signFormat" *ngIf="signvalu">{{signvalu}}</div>


        <div class="signmodalgroup">
            <button type="submit" mat-raised-button color="primary">Submit</button>

            <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>

        </div>


    </form>

    <ng-container *ngIf="addsignLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

</div>



<div class="signPageDialogBody" *ngIf="previewFlg">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <h2>Preview</h2>
    <div class="signSubmitContainer">
        <app-subjective-final-view [intakeId]="data.paramIDcon"></app-subjective-final-view>

        <div class="additionalNotesDiv" *ngIf="btnpreviewFlg">
            <mat-form-field appearance="fill">
                <mat-label>Additional Notes</mat-label>
                <textarea matInput row="16" placeholder="Additional Notes" name="additional_note" [(ngModel)]="additional_note"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="signmodalgroup" *ngIf="btnpreviewFlg">
        <button type="submit" mat-raised-button color="primary" (click)="finalSubmit()">Submit</button>

        <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>

    </div>
    <ng-container *ngIf="addsignLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

</div>