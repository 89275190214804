import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-email-slug-add',
  templateUrl: './email-slug-add.component.html',
  styleUrls: ['./email-slug-add.component.css'],
})
export class EmailSlugAddComponent {
  public isedit: boolean = false;
  public addFormLoader: boolean = false;
  public emailTemplateformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public cookieData: any;
  public editFormData: any;
  statusarr: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public ckeConfig: any = {
    toolbar: [
      { name: 'editing', items: ['Scayt', 'Find', 'Replace', 'SelectAll'] },
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      {
        name: 'tools',
        items: ['Maximize', 'ShowBlocks', 'Preview', 'Print', 'Templates'],
      },
      { name: 'document', items: ['Source'] },
      {
        name: 'insert',
        items: [
          'Image',
          'Table',
          'HorizontalRule',
          'SpecialChar',
          'Iframe',
          'imageExplorer',
        ],
      },
      '/',
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript',
          '-',
          'RemoveFormat',
        ],
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          'CreateDiv',
          '-',
          'Blockquote',
        ],
      },
      {
        name: 'justify',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
      },
      {
        name: 'styles',
        items: ['Styles', 'Format', 'FontSize', '-', 'TextColor', 'BGColor'],
      },
    ],
  };
  public userTypeArray: any = [
    { val: 'admin', name: 'Admin' },
    { val: 'doctor', name: 'Doctor' },
    { val: 'laboratory', name: 'Laboratory' },
    { val: 'sales_person', name: 'Salesperson' },
    { val: 'practice', name: 'Practice' },
  ];

  public collentionList: any = [];
  public ownedList: any = [
    { val: '1', name: 'Owned By Receiver' },
    { val: '2', name: 'Owned By Sender' },
    { val: '3', name: 'Other Collection' },
  ];

  constructor(
    public cookieService: CookieService,
    public router: Router,
    public apiservice: HttpServiceService,
    public dialog: MatDialog,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute
  ) {
    if (!window.location.pathname.includes('admin/email-template')) {
      this.router.navigateByUrl('404')
    }
  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }
    if (
      this.activateRoute.snapshot.params &&
      this.activateRoute.snapshot.params['_id']
    ) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log('edit data', response);
        if (response) {
          if (response.data.results.res) {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    }
    this.collectionFetching();
    this.initailForm();
  }
  initailForm() {
    this.emailTemplateformdata = {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      redirectpath: 'admin/email-template/slug-list',
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `admin/email-template/slug-list`, // use for redirect after cancel the form
      fields: [
        {
          label: 'Slug Name',
          name: 'slug_name',
          value:
            this.editFormData && this.editFormData.slug_name
              ? this.editFormData.slug_name
              : '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Slug Name is Required' }],
        },

        {
          label: 'Slug Variable',
          name: 'slug_variable',
          type: 'text',
          value:
            this.editFormData && this.editFormData.slug_variable
              ? this.editFormData.slug_variable
              : '',
          validations: [
            { rule: 'required', message: 'Slug Variable is Required' },
          ],
        },
        {
          label: 'Database Variable',
          name: 'data_base_variable',
          value:
            this.editFormData && this.editFormData.data_base_variable
              ? this.editFormData.data_base_variable
              : '',
          type: 'text',
          validations: [
            // { rule: 'required', message: 'Database Variable is Required' },
          ],
        },
        {
          label: 'Collection Name',
          name: 'from_collection',
          type: 'select',
          hint: '',
          val: this.collentionList,
          value:
            this.editFormData && this.editFormData.from_collection
              ? this.editFormData.from_collection
              : '',
          // validations: [
          //   { rule: 'required', message: 'User Type is required' },
          // ]
        },
        {
          label: 'Value',
          name: 'value',
          type: 'text',
          value:
            this.editFormData && this.editFormData.value
              ? this.editFormData.value
              : '',
          validations: [
            // { rule: 'required', message: 'Value is Required' },
          ],
        },
        {
          label: 'Owned By',
          name: 'owned_by',
          type: 'select',
          hint: '',
          val: this.ownedList,
          value:
            this.editFormData && this.editFormData.owned_by
              ? JSON.stringify(this.editFormData.owned_by)
              : '',
          validations: [{ rule: 'required', message: 'Owned By is required' }],
        },
        {
          label: 'Description',
          name: 'description',
          type: 'textarea',
          // group: "grp1",
          rows: 1,
          cols: 2,
          value:
            this.editFormData && this.editFormData.description
              ? this.editFormData.description
              : '',
          // hint: "Desc .... ",
          validations: [
            // { rule: 'required', message: "desc field Needs to be required" },
          ],
        },
        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          hint: '',
          val: this.statusarr,
          value:
            this.editFormData && this.editFormData.status
              ? this.editFormData.status
              : '',
        },
      ],
    };
  }

  collectionFetching() {
    let data: any = {};
    this.apiservice
      .httpViaPost('email/fetch-db-collections', data)
      .subscribe((response) => {
        console.warn('collectionFetching', response);
        if (response) {
          if (response.status) {
            this.collentionList = response.results.res;
            this.emailTemplateformdata.fields =
              this.emailTemplateformdata.fields.filter((e) =>
                e.name == 'from_collection' ? (e.val = this.collentionList) : []
              );
          }
        }
      });
  }

  listenFormFieldChange(val: any) {
    console.log('dataobj', val);
    if (val.field && val.field == 'fromsubmit') {
      val.fromval.owned_by = parseInt(val.fromval.owned_by);
      if (val.fromval.data_base_variable == '') {
        delete val.fromval.data_base_variable;
      }
      if (val.fromval.from_collection == '') {
        delete val.fromval.from_collection;
      }
      let dataobj = {
        data: val.fromval,
      };
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id;
      }

      this.addFormLoader = true;
      // if (dataobj) {
      // if (
      //   dataobj.data.data_base_variable &&
      //   dataobj.data.data_base_variable == ''
      // ) {
      //   alert('dfddf');
      //   delete dataobj.data.data_base_variable;
      // }
      // }
      this.apiservice
        .httpViaPost('email/add-update-email-slug', dataobj)
        .subscribe((response) => {
          console.log('response', response);

          if (response) {
            if (response.status == 'success') {
              this.addFormLoader = false;
              this.matSnackBar.open(
                this.editFormData ? 'Updated Successfully' : response.message,
                'Ok',
                {
                  duration: 2000,
                }
              );
              this.router.navigateByUrl(
                `${this.cookieData.user_type}/email-template/slug-list`
              );
            } else {
              this.matSnackBar.open(response.message, '', {
                duration: 2000,
              });
              this.addFormLoader = false;
            }
          }
        });
    }
    if (val.field && val.field == "formreset") {
      this.formfieldrefreshdata = {
        field: "description",
        value: '',
      };
    }
  }
}
