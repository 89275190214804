<ng-container *ngIf="!issetpassword">
    <app-header></app-header>

    <mat-card class="adminBody">

        <mat-card-content class="addEditPageWrapper changePassWrapper">

            <ng-container *ngIf="changepasswordLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>

            </ng-container>


            <h2 class="listingpageh2">Change Password</h2>
            <div class="AddEditBlog">
                <lib-showform class="addEditForm" *ngIf="formdatachngpass != null" [formdata]="formdatachngpass" [formfieldrefresh]="formfieldrefreshpass" [formfieldrefreshdata]="formfieldrefreshdatapass" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>


            </div>

        </mat-card-content>

        <span class="footerBlock"><app-footer></app-footer></span>
    </mat-card>
</ng-container>

<ng-container *ngIf="issetpassword">
    <mat-card class="adminBody setPassBody">

        <mat-card-content class="addEditPageWrapper changePassWrapper">

            <ng-container *ngIf="changepasswordLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>

            </ng-container>


            <h2 class="listingpageh2">Set Password </h2>
            <div class="AddEditBlog">
                <lib-showform class="addEditForm" *ngIf="formdatachngpassp != null" [formdata]="formdatachngpassp" [formfieldrefresh]="formfieldrefreshpass" [formfieldrefreshdata]="formfieldrefreshdatapass" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>


            </div>

        </mat-card-content>

        <span class="footerBlock"><app-footer></app-footer></span>
    </mat-card>
</ng-container>