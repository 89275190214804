import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription, debounceTime, throttleTime, fromEvent, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent {

  public adduserBTN: boolean = false;

  public libListNoData: boolean = false;

  public progressLoader: boolean = false;
  public datasource: any;
  public activatedRouteData: any = {};
  public tabledatalist: any = [];
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  tabledata_header_skip: any = ["_id"]; // use for Table Header Skip
  tabledata_detail_skip: any = ['_id', 'status', 'usertype', 'login_counts', 'last_login_time']; // view details data skip
  tablename = "users";
  editroute = "";
  updateendpoint = "users/statusChange";
  deleteendpoint = "users/deleteUsers";
  datacollection: any = "users/fetchUsers";
  searchendpoint = "";
  date_search_endpoint: any = "tert";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  public listprogressBar: any = false;




  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  userTypeArray: any = [
    { val: 'admin', name: "Admin" },
    { val: 'doctor', name: "Doctor" },
    { val: 'laboratory', name: "Laboratory" },
    { val: 'practice', name: "Practice" },
    { val: 'sales_person', name: "Salesperson" },
  ]






  modify_header_array: any = {

    fullname: "Full Name",
    phone: "Phone",
    email: "Email",
    fax: "Fax",
    usertype: "Type",
    status: "Status",
    // createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    // textsearch: [
    //   { label: "Search By Name", field: 'fullname' },
    //   { label: "Search By Email", field: 'email' },

    // ],


    search: [{
      label: "Search By Name", field: '_id',
      // values: this.authval,
      serversearchdata: { endpoint: 'users/userNameAutocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },

    {
      label: "Search By Email ", field: 'email',
      // values: this.authval,
      serversearchdata: { endpoint: 'users/userEmailAutocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },
    ],


    datesearch: [
      // {
      //   startdatelabel: 'Create On Start Date',
      //   enddatelabel: 'Create On End Date',
      //   submit: 'Search',
      //   field: 'createdon_datetime',
      //   // value: {}
      // },


      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],


    selectsearch: [
      { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray, multiple: true, },
      { label: 'Search By Status', field: 'status', values: this.statusarray },
      // {label: 'Search By State', field: 'status', values: this.statusarray},
    ],

  }

  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["email", "fullname", "updatedon_datetime", "status", "usertype"],
  };

  libdata: any = {
    basecondition: {},
    preview_header: {
      header: "Preview Details",
      class: 'preheadercls'
    },
    detailview_override: [
      { key: "address", val: "Address" },
      { key: "status_text", val: "Status" },
      { key: "contact_person", val: "Contact Person" },
      { key: "contact_person_email", val: "Contact Person Email" },
      { key: "contact_person_phone", val: "Contact Person Phone" },
      { key: "fullname", val: "Full Name" },
      { key: "fullname", val: "Full Name" },
      { key: "email", val: "Email" },
      { key: "phone", val: "Phone" },
      { key: "fax", val: "Fax" },
      { key: "npi", val: "NPI" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      // { key: 'last_login_time', val: 'Last Login' },
      { key: "last_login_datetime", val: "Last Login" },
      { key: 'parent_laboratory_name', val: "Parent Laboratory Name" },
      { key: 'practicename', val: "Practice Name" },
      { key: 'notescount', val: "Notes" }


    ],
    updateendpoint: "users/statusChange",
    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "users/statusChange",
    deleteendpointmany: "users/deleteUsers",
    tableheaders: ["fullname", "phone", "email", "fax", "usertype", "status", "updatedon_datetime"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: 'Folder View',
        type: "listner",
        id: "folderview",
        classname: "OpenFolderView",
        tooltip: 'Folder View',
      },

      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 1,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'address', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 2,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'npi', 'contact_person', 'contact_person_email', 'contact_person_phone', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 3,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'parent_laboratory_name', 'npi', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 4,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'parent_laboratory_name', 'practicename', 'npi', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 5,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'parent_laboratory_name', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },

      // {
      //         label: "Status Change",
      //         type: 'listner',
      //         id: 'status_change_btn',
      //         tooltip: 'Status Change',
      //         name: 'status_change_btn',
      //         classname: 'status_change_btn'
      //       },
    ]
  };

  public cookieData: any = {};
  public cookieDataID: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;
  public isLaboratoryUser: boolean = false;
  public ispracticeUser: boolean = false;
  userNameModalChange = new Subject<string>();
  nameArr: any = [];

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      {
        label: 'Add User',
        type: 'button',
        name: 'add_user',
        tooltip: 'Add User',
        classname: 'add_adminBTN',
      },

    ],
  };

  public UsersSummaryLoader: boolean = false;




  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {

  }

  openBottomSheet(): void {

    this._bottomSheet.open(AddUserBottomSheet);

  }





  ngOnInit() {
    // this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

    // this.cookieDataID =this.cookieData._id;

    // console.log('cookieDataID',this.cookieDataID)




    // if (this.activatedRoute) {
    //   // this.activatedRouteData = {$in:[this.activatedRoute.snapshot.params['usertype']]}

    //   this.activatedRouteData = this.activatedRoute.snapshot.params['usertype']
    //    this.libdata.basecondition.user_type = this.activatedRouteData
    // } 










    // this.UsersSummaryLoader = true;
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      
      this.libdata.notes = {
        label: "Notes",
        tooltip: 'Add Notes',
        listendpoint: "users/listnotedata",
        deleteendpoint: "users/deletenotedata",
        addendpoint: "users/addupdateNotes",
        user: this.cookieData._id,
        currentuserfullname: this.cookieData.firstname + " " + this.cookieData.lastname,
        header: "fullname",
      }
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;
          this.libdata.notes.currentuserfullname = this.cookieData.firstname + " " + this.cookieData.lastname;

          if (this.activatedRoute) {
            this.activatedRouteData = this.activatedRoute.snapshot.params['usertype']
            if (this.activatedRouteData == 'admin' || this.activatedRouteData == 'laboratory' || this.activatedRouteData == 'practice' || this.activatedRouteData == 'sales_person' || this.activatedRouteData == 'doctor') {
              this.libdata.basecondition.user_type = this.activatedRouteData
              this.search_settings.selectsearch = [
                { label: 'Search By Status', field: 'status', values: this.statusarray },
              ]

            }

          }

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;

        case 'laboratory':
          this.isLaboratoryUser = true;
          this.libdata.notes.currentuserfullname = this.cookieData.name;
          this.userTypeArray = [
            { val: 'doctor', name: "Doctor" },
            { val: 'sales_person', name: "Salesperson" },
            { val: 'practice', name: "Practice" },
          ]

          this.search_settings.selectsearch = [
            { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray, multiple: true, },
            { label: 'Search By Status', field: 'status', values: this.statusarray },
            // {label: 'Search By State', field: 'status', values: this.statusarray},
          ]

          if (this.activatedRoute) {
            this.activatedRouteData = this.activatedRoute.snapshot.params['usertype']
            if (this.activatedRouteData == 'practice' || this.activatedRouteData == 'sales_person' || this.activatedRouteData == 'doctor') {
              this.libdata.basecondition.user_type = this.activatedRouteData
              this.search_settings.selectsearch = [
                { label: 'Search By Status', field: 'status', values: this.statusarray },
              ]

            }

          }



          // this.libdata.notes = {
          //   label: "Notes",
          //   tooltip: 'Add Notes',
          //   listendpoint: "users/listnotedata",
          //   deleteendpoint: "users/deletenotedata",
          //   addendpoint: "users/addupdateNotes",
          //   user: this.cookieData._id,
          //   currentuserfullname:
          //     this.cookieData.firstname + " " + this.cookieData.lastname,
          //   header: "fullname",
          // }


          break;

        case 'practice':
          this.ispracticeUser = true;
          this.isLaboratoryUser = true;
          this.libdata.notes.currentuserfullname = this.cookieData.name;
          this.userTypeArray = [
            { val: 'doctor', name: "Doctor" },
            // { val: 'sales_person', name: "Sales Person" },

          ]
          this.search_settings.selectsearch = [
            { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray, multiple: true, },
            { label: 'Search By Status', field: 'status', values: this.statusarray },
            // {label: 'Search By State', field: 'status', values: this.statusarray},
          ]

          if (this.activatedRoute) {
            this.activatedRouteData = this.activatedRoute.snapshot.params['usertype']
            if (this.activatedRouteData == 'sales_person' || this.activatedRouteData == 'doctor') {
              this.libdata.basecondition.user_type = this.activatedRouteData
              this.search_settings.selectsearch = [
                { label: 'Search By Status', field: 'status', values: this.statusarray },
              ]

            }

          }

          // this.libdata.notes = {
          //   label: "Notes",
          //   tooltip: 'Add Notes',
          //   listendpoint: "users/listnotedata",
          //   deleteendpoint: "users/deletenotedata",
          //   addendpoint: "users/addupdateNotes",
          //   user: this.cookieData._id,
          //   currentuserfullname:
          //     this.cookieData.firstname + " " + this.cookieData.lastname,
          //   header: "fullname",
          // }


          break;




        case 'sales_person':
          this.ispracticeUser = true;
          this.isLaboratoryUser = true;
          this.userTypeArray = [
            { val: 'practice', name: "Practice" },

          ]
          this.search_settings.selectsearch = [
            { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray, multiple: true, },
            { label: 'Search By Status', field: 'status', values: this.statusarray },
            // {label: 'Search By State', field: 'status', values: this.statusarray},
          ]

          if (this.activatedRoute) {
            this.activatedRouteData = this.activatedRoute.snapshot.params['usertype']
            if (this.activatedRouteData == 'practice') {
              this.libdata.basecondition.user_type = this.activatedRouteData
              this.search_settings.selectsearch = [
                { label: 'Search By Status', field: 'status', values: this.statusarray },
              ]

            }

          }

          break;



      }
    }



    console.log("cookieService", this.cookieData)

    this.activatedRoute.data.subscribe((response: any) => {

      console.log("libdata", response)


      if (response) {
        console.log("activatedRoute responseff", response)
        this.listprogressBar = true
        if (response.data && response.data.status == "success") {
          this.tabledatalist = response.data.results.res;
          setTimeout(() => {
            this.listprogressBar = false
          }, 150);

          if (this.tabledatalist.length == 0) {

            // console.log("no record")

            setTimeout(() => {
              this.adduserBTN = true

              let myRow = document.querySelector('.adminBody');
              myRow.classList.add('libListNoDataCSS');
            }, 200);


          }


        }

      }




    })

    this.userListFetching();




  }


  listenFormFieldChange(val: any) {


  } searchcondition



  userListFetching() {
    console.log("cookieService+++", this.cookieData)

    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
      // searchcondition:{ id:  this.cookieData._id}  
    }
    // this.activatedRoute.data.subscribe((response: any) => {
    //   // console.log(response)
    //   if (response) {
    //     this.tabledatalist = response.data.results.res;
    //   }
    // })
    // this.httpService.httpViaPost('users/fetchUsers', data).subscribe((response: any) => {
    //   console.log(response)
    // if (response) {
    //   this.tabledatalist = response.results.res;
    // }
    //   // this.progressLoader = false;
    // });


    switch (this.cookieData.user_type) {

      case 'practice':
        data.searchcondition.practice_id = this.cookieData._id;
        this.libdata.basecondition.practice_id = this.cookieData._id
        //  requestObj.searchcondition.parent_laboratory = this.cookiedata._id;
        break;
      case 'laboratory':
        data.searchcondition.parent_laboratory = this.cookieData._id
        this.libdata.basecondition.parent_laboratory = this.cookieData._id
        break;


      // case 'practice':
      //   data.searchcondition.practice_id = this.cookieData._id
      //   this.libdata.practice.practice_id = this.cookieData._id
      // break;


    }

    if (this.activatedRoute) {
      console.log("aaaaaaa", this.activatedRoute);

      switch (this.cookieData.user_type) {

        case 'admin':
          if (this.activatedRoute.snapshot.params['usertype'] != null) {
            data.searchcondition = { user_type: [this.activatedRoute.snapshot.params['usertype']] };
          }
          else {
            data.searchcondition = {};
          }
          break;
        case 'laboratory':
          console.log('this.activatedRoute.snapshot.params>>>',this.activatedRoute.snapshot.routeConfig.path);
          
          if (this.activatedRoute.snapshot.params['usertype'] != null) {
            data.searchcondition = { user_type: [this.activatedRoute.snapshot.params['usertype']], parent_laboratory: this.cookieData._id };
          }
          else if (this.activatedRoute.snapshot.routeConfig.path == 'user-management/users') {
            data.searchcondition = {  parent_laboratory: this.cookieData._id };
          }
          else {
            data.searchcondition = {};
          }
          // data.searchcondition.parent_laboratory = this.cookieData._id;
          break;
        case 'practice':
          if (this.activatedRoute.snapshot.params['usertype'] != null) {
            data.searchcondition = { user_type: [this.activatedRoute.snapshot.params['usertype']], practice_id: this.cookieData._id };
          }
          else if (this.activatedRoute.snapshot.routeConfig.path == 'user-management/users') {
            data.searchcondition = {  practice_id: this.cookieData._id };
          }
          else {
            data.searchcondition = {};
          }
          break;
        case 'sales_person':
          if (this.activatedRoute.snapshot.params['usertype'] != null) {
            data.searchcondition = { user_type: [this.activatedRoute.snapshot.params['usertype']], refferd_sealsperson: this.cookieData._id };
          }
          else {
            data.searchcondition = {};
          }
          break;
        default:

          data.searchcondition = {};
          break;
        // data.searchcondition.refferd_sealsperson = this.cookieData._id;
      }



    }


    this.httpService.httpViaPost('users/fetchUsers-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        // this.UsersSummaryLoader = false;
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })


  }




  listenLiblistingChange(data: any = null) {
    console.log("listenLiblistingChange test", data);
    console.log("listenLiblistingChange test", this.libdata.basecondition);
    // practice/intake-form-preview/view
    // console.log("Op=====>1", data);
    if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          this.editListItem(data.custombuttonclick.data);
          break;
        case "Delete":
          // this.deleteListItem(data.custombuttonclick.data)
          break;


        case "Folder View":

          this.FolderViewListItem(data.custombuttonclick.data);

          // this.router.navigateByUrl(`user-management/folder-view/${item._id}`);
          break;



        default: null
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
  }

  onLiblistingButtonChange(val: any) {
    this._bottomSheet.dismiss();
    console.log("test", val);
    this._bottomSheet.open(AddUserBottomSheet);
  }

  FolderViewListItem(item: any) {
    if (item) {
      console.log("folderlink", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/folder-view/${item._id}`);
    }

  }

  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      switch (this.cookieData.user_type) {
        case 'admin':
          switch (item.usertype) {
            case 'Doctor':
              this.router.navigateByUrl(`admin/user-management/doctor/edit/${item._id}`);
              break;
            case 'Admin':
              this.router.navigateByUrl(`admin/user-management/admin/edit/${item._id}`);
              break;
            case "Laboratory":
              this.router.navigateByUrl(`admin/user-management/laboratory/edit/${item._id}`);
              break;
            case "Sales Person":
              this.router.navigateByUrl(`admin/user-management/sales_person/edit/${item._id}`);
              break;
            case 'Practice':
              this.router.navigateByUrl(`admin/user-management/practice/edit/${item._id}`)
              break;
          }
          break;
        case 'doctor':
          switch (item.usertype) {
            case 'Doctor':
              this.router.navigateByUrl(`doctor/user-management/doctor/edit/${item._id}`);
              break;
            case 'Admin':
              this.router.navigateByUrl(`doctor/user-management/admin/edit/${item._id}`);
              break;
            case "Laboratory":
              this.router.navigateByUrl(`doctor/user-management/laboratory/edit/${item._id}`);
              break;
            case "Sales Person":
              this.router.navigateByUrl(`doctor/user-management/sales_person/edit/${item._id}`);
              break;
            case 'Practice':
              this.router.navigateByUrl(`doctor/user-management/practice/edit/${item._id}`)
              break;

          }
          break;

        case 'laboratory':
          switch (item.usertype) {
            case 'Doctor':
              this.router.navigateByUrl(`laboratory/user-management/doctor/edit/${item._id}`);
              break;
            case 'Admin':
              this.router.navigateByUrl(`laboratory/user-management/admin/edit/${item._id}`);
              break;
            case "Laboratory":
              this.router.navigateByUrl(`laboratory/user-management/laboratory/edit/${item._id}`);
              break;
            case "Sales Person":
              this.router.navigateByUrl(`laboratory/user-management/sales_person/edit/${item._id}`);
              break;
            case 'Practice':
              this.router.navigateByUrl(`laboratory/user-management/practice/edit/${item._id}`)
              break;

          }
          break;

        // case 'sales-person':
        //   switch (item.usertype) {
        //     case 'Doctor':
        //       this.router.navigateByUrl(`sales-person/user-management/doctor/edit/${item._id}`);
        //       break;
        //     case 'Admin':
        //       this.router.navigateByUrl(`sales-person/user-management/admin/edit/${item._id}`);
        //       break;
        //     case "Laboratory":
        //       this.router.navigateByUrl(`sales-person/user-management/laboratory/edit/${item._id}`);
        //       break;
        //     case "Sales Person":
        //       this.router.navigateByUrl(`sales-person/user-management/sales_person/edit/${item._id}`);
        //       break;
        //     case 'Practice':
        //       this.router.navigateByUrl(`sales-person/user-management/practice/edit/${item._id}`)
        //       break;

        //   }
        //   break;



        case 'practice':
          switch (item.usertype) {
            case 'Doctor':
              this.router.navigateByUrl(`practice/user-management/doctor/edit/${item._id}`);
              break;
            case 'Admin':
              this.router.navigateByUrl(`practice/user-management/admin/edit/${item._id}`);
              break;
            case "Laboratory":
              this.router.navigateByUrl(`practice/user-management/laboratory/edit/${item._id}`);
              break;
            case "Sales Person":
              this.router.navigateByUrl(`practice/user-management/sales_person/edit/${item._id}`);
              break;
            case 'Practice':
              this.router.navigateByUrl(`practice/user-management/practice/edit/${item._id}`)
              break;

          }
          break;


      }

    }
  }


  deleteListItem(item: any) {
    console.log("Op=====>1", item);
    if (item) {
      if (item) {
        // this.router.navigateByUrl(`admin/edit/${item._id}`);
      }
    }
  }

}



@Component({
  selector: 'addUserBottomSheet',
  templateUrl: 'addUserBottomSheet.html',
})
export class AddUserBottomSheet {


  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;
  public isLaboratoryUser: boolean = false;
  public ispracticeUser: boolean = false;
  public isSalespersonUser: boolean = false;


  constructor(private _bottomSheetRef: MatBottomSheetRef<AddUserBottomSheet>, public router: Router, public cookieService: CookieService) {

  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;
          break;
        case 'doctor':
          this.isDoctorUser = true;
          this.isSalespersonUser = true
          break;
        case 'laboratory':
          this.isLaboratoryUser = true;
          break;

        case 'practice':
          this.ispracticeUser = true;
          this.isLaboratoryUser = true;
          this.isSalespersonUser = true
          break;


      }
    }
  }


  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  closeBottomSheet(type: string) {
    this._bottomSheetRef.dismiss();
    console.log('close');
    if (type) {
      switch (this.cookieData.user_type) {
        case 'admin':
          switch (type) {
            case 'admin':
              this.router.navigateByUrl("admin/user-management/admin/add")
              break;
            case 'doctor':
              this.router.navigateByUrl("admin/user-management/doctor/add")
              break;
            case 'laboratory':
              this.router.navigateByUrl("admin/user-management/laboratory/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("admin/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("admin/user-management/practice/add")
              break;
          }
          break;
        case 'doctor':
          switch (type) {
            case 'doctor':
              this.router.navigateByUrl("doctor/user-management/doctor/add")
              break;

            case 'sales_person':
              this.router.navigateByUrl("doctor/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("doctor/user-management/practice/add")
              break;
          }
          break;

        case 'laboratory':
          switch (type) {
            case 'doctor':
              this.router.navigateByUrl("laboratory/user-management/doctor/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("laboratory/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("laboratory/user-management/practice/add")
              break;
          }
          break;


        case 'practice':
          switch (type) {
            case 'doctor':
              this.router.navigateByUrl("practice/user-management/doctor/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("practice/user-management/sales_person/add")
              break;
          }
          break;





      }

    }


  }
}
