<mat-card class="lessonListCard">
    <mat-card-content>
        <div>
            <!-- <div *ngFor="let lesson of lessonList" class="lessonListDiv">
                <div class="lessonName">{{lesson.lesson_name}}</div>
                <div class="lessonVideo" *ngIf="lesson.video.length > 0 && lesson.video[0] !== ''">
                    <video width="320" height="240" id="myVideo" controls="" controlslist="nodownload">
                        <source
                            src="https://pece-training-files.s3.amazonaws.com/{{lesson.video[0].path}}{{lesson.video[0].fileservername}}" />
                    </video>
                </div>
                <div class="lessonVideo" *ngIf="lesson.image.length > 0 && lesson.image[0] !== ''">
                    <img src="https://pece-training-files.s3.amazonaws.com/{{lesson.image[0].path}}{{lesson.image[0].fileservername}}">
                </div>
                <div class="lessonVideo" *ngIf="lesson.audio.length > 0 && lesson.audio[0] !== ''">
                    <audio controls>
                        <source
                            src="https://pece-training-files.s3.amazonaws.com/{{lesson.audio[0].path}}/{{lesson.audio[0].fileservername}}"
                            type="audio/mpeg" />
                    </audio>
                </div>
            </div> -->
            <div class="lessonListDiv">
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item *ngFor="let lesson of lessonList; let index = index;" #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="lesson.active" [attr.aria-controls]="'accordion-body-' + index">
                        <div class="example-accordion-item-header" (click)="selectLesson(lesson)">
                            <div class="lessonName">{{lesson.lesson_name}}</div>
                            <span class="lessonArrow">
                                <mat-icon *ngIf="!lesson.active">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="lesson.active">keyboard_arrow_up</mat-icon>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region" [style.display]="lesson.active ? '' : 'none'" [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="lessonVideo" *ngIf="lesson.video.length > 0 && lesson.video[0] !== ''">
                                <video width="320" height="240" id="myVideo" controls="" controlslist="nodownload">
                                    <source src="https://pece-training-files.s3.amazonaws.com/{{lesson.video[0].path}}{{lesson.video[0].fileservername}}" />
                                </video>
                            </div>
                            <div class="lessonImage" *ngIf="lesson.image.length > 0 && lesson.image[0] !== ''">
                                <img src="https://pece-training-files.s3.amazonaws.com/{{lesson.image[0].path}}{{lesson.image[0].fileservername}}">
                            </div>
                            <div class="lessonAudio" *ngIf="lesson.audio.length > 0 && lesson.audio[0] !== ''">
                                <audio controls>
                                    <source  src="https://pece-training-files.s3.amazonaws.com/{{lesson.audio[0].path}}{{lesson.audio[0].fileservername}}" type="audio/mpeg" />
                                </audio>
                            </div>
                            <div class="markAsDoneDiv" *ngIf="cookieData.user_type !== 'admin'">
                                <button (click)="markAsDone(lesson)">Mark as Done</button>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<!-- https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png -->
<!-- src="https://all-frontend-assets.s3.amazonaws.com/timothy-desmond-videos/Chapter+1+-+Lesson+1.mp4" -->