<app-header></app-header>
<mat-card class="adminBody">
    <mat-card-content class="listingpage_wrapper">

        <!-- <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container> -->



        <h2 class="listingpageh2"> Manage Location</h2>


        <button (click)="addLocationmodal()" class="addLocationBTN">Add Location</button>

        <mat-card class="location_col_wrapper">




            <!-- 
            <ng-container *ngIf="managelocationNoData">
                
                    <div class="noFoundTextinner noFoundTextinnerBG">
                         <span>Oops!<br/> No Record Found</span>
                    </div>
              
            </ng-container> -->

            <div class="noFoundTextinnerwrapper" *ngIf="managelocationData != null && managelocationData.length == 0">
                <div class="noFoundTextinner noFoundTextinnerBG">
                    <span>Oops!<br /> No Record Found</span>
                </div>
            </div>

            <ng-container *ngFor="let item of managelocationData; let i = index">




                <div class="location_col">

                    <p><strong>Location Name :</strong> <span>{{ item?.location_name }}</span></p>
                    <p><strong>Address :</strong> <span>{{ item?.address }}</span></p>
                    <p><strong>City :</strong> <span>{{ item?.city}}</span></p>
                    <p><strong>State :</strong> <span>{{ item?.state }}</span></p>
                    <p><strong>Zip :</strong> <span>{{ item?.zip }}</span></p>
                    <p><strong>Phone :</strong> <span>{{ item?.phone }}</span></p>
                    <div class="location_iconwrapper">

                        <a (click)="deleteLocation(item,i)">
                            <mat-icon>close</mat-icon>
                        </a>
                        <a (click)="editLocation(item,i)">
                            <mat-icon>edit</mat-icon>
                        </a>
                    </div>

                </div>




            </ng-container>

            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
          
            <div class="loadmorediv"> <button mat-raised-button *ngIf="locationcount > 5" (click)="loadmorelocation()">{{managelocationData.length < locationcount ? "Load More" : "Show Less"}} </button></div>

        </mat-card>

     
    </mat-card-content>
    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>