<app-header>
</app-header>
<mat-card>
    <mat-card-content class="userdetail_info">
        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
        <div class="userdetail_info_bottomwrp">
            <ng-container *ngIf="loaderFlag">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
            <div class="userdetail_leftsec userdetail_small userdetail_column">
                <div class="userdetail_head">
                    <h2>General Information</h2>
                </div>
                <div class="userdetail_body">
                    <div class="userdetail_body_row" *ngIf="folderviewdatalist.user_type == 'admin' || folderviewdatalist.user_type == 'doctor' || folderviewdatalist.user_type == 'sales_person'">
                        <b><span class="material-icons"> person </span>Name:</b>
                        <p>{{ folderviewdatalist?.firstname | titlecase }} {{ folderviewdatalist?.lastname | titlecase}}
                        </p>
                    </div>
                    <div class="userdetail_body_row" *ngIf="folderviewdatalist.user_type == 'practice'">
                        <b><span class="material-icons"> person </span>Name:</b>
                        <p>{{ folderviewdatalist?.practice_name | titlecase }}</p>
                    </div>
                    <div class="userdetail_body_row" *ngIf="folderviewdatalist.user_type == 'laboratory'">
                        <b><span class="material-icons"> person </span>Name:</b>
                        <p>{{ folderviewdatalist?.laboratory_name | titlecase }}</p>
                    </div>
                    <div class="userdetail_body_row">
                        <b><span class="material-icons"> email </span>Email:</b>
                        <p class="emailtext">{{ folderviewdatalist?.email }}</p>
                    </div>
                    <div class="userdetail_body_row">
                        <b><span class="material-icons"> phone </span>Phone:</b>
                        <p>{{ folderviewdatalist?.phone }}</p>
                    </div>
                    <div class="userdetail_buttonCon">
                        <button mat-flat-button (click)="changeBtn('info')" [ngClass]="{ BTN_active: personalInfoFlag }" [disabled]="loaderFlag">Personal Information</button>
                        <button mat-flat-button (click)="changeBtn('edit')" [ngClass]="{ BTN_active: editFlag }" [disabled]="loaderFlag">Edit
                            Information</button>
                        <button mat-flat-button (click)="changeBtn('pass')" [ngClass]="{ BTN_active: changePassFlag }" [disabled]="loaderFlag">Set Password</button>
                        <button mat-flat-button (click)="changeBtn('login')" [ngClass]="{ BTN_active: loginActivityFlag }" [disabled]="loaderFlag">Login
                            Activity</button>
                        <button mat-flat-button (click)="changeBtn('notes')" [ngClass]="{ BTN_active: notesManageFlag }" matBadge="{{realtimeNotesUpdate}}" matBadgeColor="warn" [disabled]="loaderFlag">
                            Notes Manage
                        </button>
                        <button mat-flat-button (click)="changeBtn('location')" [ngClass]="{ BTN_active: locationFlag }" *ngIf="folderviewdatalist.user_type == 'laboratory' || folderviewdatalist.user_type == 'practice'" [disabled]="loaderFlag">
                            Manage Location
                        </button>
                    </div>
                </div>
            </div>
            <div class="userdetail_mddlesec userdetail_column">
                <div class="userdetail_head">
                    <h2>{{folderHeader}}</h2>
                </div>
                <div class="userdetail_body" *ngIf="personalInfoFlag">
                    <mat-card class="PersonalInfo_body" *ngIf="folderviewdatalist.user_type == 'admin'">
                        <mat-card-content>
                            <b> Name:</b>
                            <p> {{ folderviewdatalist?.firstname | titlecase }} {{ folderviewdatalist?.lastname | titlecase }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Email:</b>
                            <p class="emailtext">{{ folderviewdatalist?.email }}</p>

                        </mat-card-content>
                        <mat-card-content>
                            <b>Phone:</b>
                            <p>{{ folderviewdatalist?.phone }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Fax:</b>
                            <p>{{ folderviewdatalist?.fax }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Address:</b>
                            <p>{{ folderviewdatalist?.address }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>State:</b>
                            <p>{{ folderviewdatalist?.state }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>City:</b>
                            <p>{{ folderviewdatalist?.city }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Zip:</b>
                            <p>{{ folderviewdatalist?.zip }}</p>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="PersonalInfo_body" *ngIf="folderviewdatalist.user_type == 'doctor'">
                        <mat-card-content>
                            <b>Doctor Name:</b>
                            <p> {{ folderviewdatalist?.firstname | titlecase }} {{ folderviewdatalist?.lastname | titlecase }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Email:</b>
                            <p> {{ folderviewdatalist?.email }}
                        </mat-card-content>
                        <mat-card-content>
                            <b>Phone:</b>
                            <p> {{ folderviewdatalist?.phone }}
                        </mat-card-content>
                        <mat-card-content>
                            <b>Fax:</b>
                            <p> {{ folderviewdatalist?.fax }}
                        </mat-card-content>
                        <mat-card-content>
                            <b>Laboratory Name:</b>
                            <p> {{ labdetailPAC && labdetailPAC.length > 0 ? labdetailPAC : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Practice Name:</b>
                            <p> {{ practicePAC && practicePAC.length > 0 ? practicePAC : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Location:</b>
                            <p> {{ selectedParentLocations? selectedParentLocations : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>NPI:</b>
                            <p> {{ folderviewdatalist?.npi }}
                        </mat-card-content>
                        <mat-card-content>
                            <b>Taxonomy:</b>
                            <p> {{ selectedTaxonomies ? selectedTaxonomies : "N/A" }}</p>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="PersonalInfo_body" *ngIf="folderviewdatalist.user_type == 'laboratory'">
                        <mat-card-content class="logosec_iconwrap">
                            <!-- <img src="https://{{folderviewdatalist?.image}}" alt="User" class="logosec_img"> -->
                            <div class="logosec_img">
                                <!-- *ngIf="!logoimgcss" -->
                                <!-- <img src="{{folderviewdatalist?.image ? (folderviewdatalist.image) : 'https://all-frontend-assets.s3.amazonaws.com/practice2lab/user3.png'}}" alt="User" > -->

                                <img [src]="logoimgcss ? 'https://all-frontend-assets.s3.amazonaws.com/practice2lab/user3.png' : logoimgPAC" />
                                <!-- <img src={{logoimgPAC}} /> -->
                            </div>
                            <mat-card class="logosec_content">
                                <mat-card-content>
                                    <b> Laboratory Name:</b>
                                    <p> {{ folderviewdatalist?.laboratory_name | titlecase }} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Email:</b>
                                    <p class="emailtext"> {{ folderviewdatalist?.email}} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Phone:</b>
                                    <p> {{ folderviewdatalist?.phone}} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Fax:</b>
                                    <p> {{ folderviewdatalist?.fax}} </p>
                                </mat-card-content>
                            </mat-card>
                        </mat-card-content>
                        <mat-card-content>
                            <b>NPI:</b>
                            <p> {{ folderviewdatalist?.npi}} </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Name:</b>
                            <p> {{ folderviewdatalist?.contact_person}} </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Email:</b>
                            <p class="emailtext"> {{ folderviewdatalist?.contact_person_email}} </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Phone:</b>
                            <p> {{ folderviewdatalist?.contact_person_phone}} </p>
                        </mat-card-content>

                        <!-- <mat-card-content>
              <b>Parent Location:</b>
              <p> {{ folderviewdatalist?.parent_location && folderviewdatalist?.parent_location.length >0 ? folderviewdatalist?.parent_location : "N/A" }}</p>
            </mat-card-content> -->

                        <!-- <mat-card-content>
              <b>Logo:</b>
              <p class="logoIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/{{folderviewdatalist?.image}}"> </p>
            </mat-card-content> -->

                    </mat-card>

                    <mat-card class="PersonalInfo_body" *ngIf="folderviewdatalist.user_type == 'practice'">
                        <mat-card-content class="logosec_iconwrap">
                            <!-- <img src="{{folderviewdatalist?.image}}" alt="User" class="logosec_img"> -->
                            <!-- *ngIf="!logoimgcss" [ngClass]="logoimgPAC.length > 0 ? 'showimagediv' : '' " -->
                            <div class="logosec_img">
                                <!-- *ngIf="!logoimgcss" -->
                                <!-- <img src="{{folderviewdatalist?.image ? (folderviewdatalist.image) : 'https://all-frontend-assets.s3.amazonaws.com/practice2lab/user3.png'}}" alt="User" > -->


                                <img [src]="logoimgcss ? 'https://all-frontend-assets.s3.amazonaws.com/practice2lab/user3.png' : logoimgPAC" />
                            </div>
                            <mat-card class="logosec_content">
                                <mat-card-content>
                                    <b> Practice Name:</b>
                                    <p> {{ folderviewdatalist?.practice_name | titlecase }} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Email:</b>
                                    <p class="emailtext"> {{ folderviewdatalist?.email}} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Phone:</b>
                                    <p> {{ folderviewdatalist?.phone}} </p>
                                </mat-card-content>
                                <mat-card-content>
                                    <b>Fax:</b>
                                    <p> {{ folderviewdatalist?.fax}} </p>
                                </mat-card-content>
                            </mat-card>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Name:</b>
                            <p> {{ folderviewdatalist?.contact_person_firstname}} {{ folderviewdatalist?.contact_person_lastname}}
                            </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Email:</b>
                            <p class="emailtext"> {{ folderviewdatalist?.contact_email}} </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Contact Person Phone:</b>
                            <p> {{ folderviewdatalist?.contact_phone}} </p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Laboratory:</b>
                            <p> {{ labdetailsPAC && labdetailsPAC.length > 0 ? labdetailsPAC : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Location:</b>
                            <p> {{ selectedParentLocations? selectedParentLocations : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>NPI:</b>
                            <p> {{ folderviewdatalist?.npi }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Taxonomy:</b>
                            <p> {{ selectedTaxonomies ? selectedTaxonomies : "N/A" }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Salesperson:</b>
                            <p> {{ salesdetailsPAC && salesdetailsPAC.length > 0 ? salesdetailsPAC : "N/A" }}</p>
                        </mat-card-content>
                        <!-- <mat-card-content>
              <b>Logo:</b>
              <p class="logoIMG"> <img src="https://{{folderviewdatalist?.image}}"> </p>
            </mat-card-content> -->
                    </mat-card>
                    <mat-card class="PersonalInfo_body" *ngIf="folderviewdatalist.user_type == 'sales_person'">
                        <mat-card-content>
                            <b>Salesperson Name:</b>
                            <p> {{ folderviewdatalist?.firstname | titlecase }} {{ folderviewdatalist?.lastname | titlecase }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b> Email:</b>
                            <p class="emailtext">{{ folderviewdatalist?.email }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Phone:</b>
                            <p>{{ folderviewdatalist?.phone }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Fax:</b>
                            <p>{{ folderviewdatalist?.fax }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Address:</b>
                            <p>{{ folderviewdatalist?.address }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>State:</b>
                            <p>{{ folderviewdatalist?.state }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>City:</b>
                            <p>{{ folderviewdatalist?.city }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Zip:</b>
                            <p>{{ folderviewdatalist?.zip }}</p>
                        </mat-card-content>
                        <mat-card-content>
                            <b>Laboratory Name:</b>
                            <p> {{ labdetailPAC && labdetailPAC.length > 0 ? labdetailPAC : "N/A" }}</p>
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="userdetail_body addEditPageWrapper form_title_style" *ngIf="editInfoFlag">
                    <div class="AddEditBlog">
                        <lib-showform class="addEditForm" [formdata]="formdata" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)" (onLiblistingChange)="listenLiblistingChange($event)">
                        </lib-showform>
                    </div>
                </div>

                <div class="userdetail_body addEditPageWrapper changePassWrapper" *ngIf="changePassFlag">
                    <!-- <ng-container *ngIf="changepasswordLoader">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container> -->
                    <lib-showform class="addEditForm" *ngIf="formdatachngpass != null" [formdata]="formdatachngpass" [formfieldrefresh]="formfieldrefreshpass" [formfieldrefreshdata]="formfieldrefreshdatapass" (onFormFieldChange)="listenFormChangePassword($event)">
                    </lib-showform>
                </div>


                <div class="userdetail_body loginActWrapper" *ngIf="loginActivityFlag">
                    <!-- <p>Display Login Details </p> -->

                    <ng-container *ngFor="let item of loginactivityCon; let i = index">
                        <ul>
                            <li><strong>Date & Time:</strong> <span>{{ item?.login_time | date :"MM/dd/yyyy,
                                    HH:mm"}}</span> </li>
                            <li><strong>City:</strong> <span> {{item.city }} </span> </li>
                            <li><strong>Region:</strong> <span> {{item.region }} </span> </li>
                            <li><strong>Country:</strong> <span> {{item.country }} </span> </li>
                            <li> <strong>Timezone:</strong> <span> {{item.timezone }}</span> </li>
                        </ul>
                    </ng-container>

                    <!-- <ng-container *ngIf="loaderFlag">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container> -->

                    <div class="loadmorediv"> <button mat-raised-button *ngIf="loginactivity_count > 9" (click)="loadmore()"> {{loginactivityCon.length < loginactivity_count ? "Load More" : "Show Less"}}</button></div>


                    <ng-container *ngIf="loginactivityCon != null && loginactivityCon.length == 0 && loginactivityDATAFlag">
                        <div class="noFoundTextinner">
                            <span>Oops!</span>
                            <p>No Record Found</p>
                        </div>
                    </ng-container>
                </div>

                <div class="userdetail_body notesManageWrapper addEditPageWrapper" *ngIf="notesManageFlag">
                    <form (ngSubmit)="addnote()" name="addNoteForm" [formGroup]="addNoteForm" class="addNoteForm">
                        <mat-form-field class="example-full-width">
                            <mat-label>Add Notes Here !! </mat-label>
                            <textarea matInput name="note" formControlName="note" required></textarea>
                            <ng-container *ngIf="!addNoteForm.controls['note'].valid && addNoteForm.controls['note'].touched">
                                <span class="error">Add Notes is Required.</span>
                            </ng-container>
                        </mat-form-field>
                        <button type="submit" mat-raised-button color="primary" [disabled]="addFormLoader">Add
                            Note</button>
                    </form>
                    <ng-container *ngFor="let item of noteCon; let i = index">
                        <ul>
                            <li>
                                <h1>{{item.notes.note }}</h1>
                            </li>
                            <div class="noteByWrapper">
                                <li><label>By:<span> {{item.notes.user}}</span></label></li>
                                <li>
                                    <strong>On:<span> {{item.notes.created_date | date :"MM/dd/yyyy,
                                            HH:mm"}}</span></strong>
                                </li>
                            </div>
                            <!-- {{cookieData._id}} == {{item.notes.userid}} -->
                            <div class="noteDeleteWrap"><button mat-button (click)="openDialog(item, i )" *ngIf="cookieData._id == item.notes.userid"><mat-icon>delete_outline</mat-icon></button>
                            </div>
                            <!-- <span (click)="delnote(item, i)"><mat-icon>delete_outline</mat-icon></span> -->
                        </ul>
                    </ng-container>

                    <div class="deleteloder">
                        <ng-container *ngIf="addFormLoadernote">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </ng-container>
                    </div>

                    <div class="loadmorediv"> <button mat-raised-button *ngIf="loginactivityNotes > 5" (click)="loadmoreNotes()">{{noteCon.length < loginactivityNotes ? "Load More" : "Show Less"}} </button></div>
                    <!-- {{loadButtonNotes}} -->


                    <ng-container *ngIf="noteCon != null && noteCon.length == 0 && notesManageDATAFlag">
                        <div class="noFoundTextinner">
                            <span>Oops!</span>
                            <p>No Record Found</p>
                        </div>
                    </ng-container>



                </div>

                <div class="userdetail_body  locationwrapper" *ngIf="locationFlag">
                    <button (click)="addLocationmodal()" class="addLocationBTN">Add Location</button>
                    <span *ngIf="managelocationData != null && managelocationData.length == 0 && managelocationDATAFlag">
                        <div class="noFoundTextinner noFoundTextinnerBG">
                            <span>Oops!<br /> No Record Found</span>
                </div>
                </span>
                <div class="location_col_wrapper">

                    <ng-container *ngFor="let item of managelocationData; let i = index">
                        <div class="location_col">
                            <p><strong>Location Name :</strong> <span>{{ item?.location_name }}</span></p>
                            <p><strong>Address :</strong> <span>{{ item?.address }}</span></p>
                            <p><strong>City :</strong> <span>{{ item?.city}}</span></p>
                            <p><strong>State :</strong> <span>{{ item?.state }}</span></p>
                            <p><strong>Zip :</strong> <span>{{ item?.zip }}</span></p>
                            <p><strong>Phone :</strong> <span>{{ item?.phone }}</span></p>
                            <div class="location_iconwrapper">
                                <a (click)="deleteLocation(item,i)">
                                    <mat-icon>close</mat-icon>
                                </a>
                                <a (click)="editLocation(item,i)">
                                    <mat-icon>edit</mat-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>


                </div>
                <div class="locloadmore">
                    <ng-container *ngIf="addFormLoader">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>
                </div>

                <div class="loadmorediv"> <button mat-raised-button *ngIf="location_count > 5" (click)="loadmorelocation()">{{managelocationData.length < location_count ? "Load More" : "Show Less"}} </button></div>
            </div>



            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
        </div>
        <div class="userdetail_rghtsec userdetail_small userdetail_column" *ngIf="logindetatop.length > 0">
            <div class="userdetail_head">
                <h2>Last Login</h2>
            </div>
            <div class="userdetail_body">
                <div *ngIf="!logindata" [ngClass]="!logindetatop[0] ? 'noshowlogin' : 'showlogin' ">
                    <div class="loginconcss">
                        <span>Date & Time:</span> {{logindetatop[0]?.login_time | date :"MM/dd/yyyy, HH:mm" }}
                        <br />
                        <span>City:</span> {{logindetatop[0]?.city }} <br />
                        <span>Region:</span> {{logindetatop[0]?.region }} <br />
                        <span>Country:</span> {{logindetatop[0]?.country }} <br />
                        <span>Timezone:</span> {{logindetatop[0]?.timezone }}
                    </div>
                    <div class="noFoundTextinner loginconcssnotshow">
                        <span>Oops!</span>
                        <p>No Record Found</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </mat-card-content>
    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>