<!-- <mat-card class="landing_pagewrapper" *ngIf="landingpageShow == 'practiceadd'">
  <mat-card class="common_width">
    <mat-card class="landing_banner">
      <div *ngIf="logoUrl" class="landing_topbanner">
      
      </div>
      <div class="landing_bannerbttm">
        <div class="landing_bannerbttm_left">
          <div class="landing_bannerbttm_left_head">
            <h1>
              Lorem Ipsum has been the when an unknown printer but
              <span>PRACTICE 2 LAB</span> also
            </h1>
          </div>
          <div class="landing_bannerleft_card">
            <div class="bannerleft_card_top">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's simply dummy text of
              the printing and Lorem Ipsum has.
            </p>
            <div class="bannerleft_card_bottom">
              <p>
                simply dummy text of the printing and Various
                <span>versions have evolved</span> over All the Lorem Ipsum
                generators on the
              </p>
            </div>
          </div>
          <div class="banner_arrowwrp">
            <p>Contrary to popular belief, Lorem Ipsum</p>
          </div>
        </div>
        <div class="landing_bannerbttm_right">
          <div class="landing_bannerbttm_right_head">
            <h2>Fill out this form so we can start your enrollment today!</h2>
          </div>
          <lib-showform
            class="addEditForm"
            [formdata]="practiceAddForm"
            [formfieldrefresh]="formfieldrefresh"
            [formfieldrefreshdata]="formfieldrefreshdata"
            (onFormFieldChange)="listenFormFieldChange($event)"
          >
          </lib-showform>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="progressBar"
          ></mat-progress-bar>
        </div>
      </div>
    </mat-card>
  </mat-card>

  <div class="landing_block1">
    <div class="common_width">
      <div class="landing_block1_wrap">
        <div class="landing_block1_cntntsec block_heading">
          <h1>Lorem Ipsum is simply dummy text of the with!</h1>
          <p>
            typesetting industry.
            <span>Lorem Ipsum has been the industry's</span> standard dummy text
            ever the 1500s, when an unknown printer took a galley of type and
            scrambled
          </p>
          <div class="landing_block1_cntnt_bttmwrp">
            <img
              src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/p2l_landing_block1_littleimg.png"
              alt="p2l_landing_block1_littleimg"
            />
            <h2>Lorem Ipsum is simply dummy text of the printing the</h2>
            <div class="bullet_wrapper">
              <p>Text ever since the</p>
              <p>Text ever since the</p>

              <p>Dummy text ever since the</p>
              <p>Dummy text ever since the</p>

              <p>Desktop publishing software</p>
              <p>Desktop publishing <span>(software text ever the)</span></p>

              <p>Lorem Ipsum has been the</p>
              <p>Plus many more…</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing_block2">
    <div class="common_width">
      <div class="landing_block_innerwrap">
        <div class="block_heading">
          <h1>Lorem Ipsum is simply dummy text of printing!</h1>
          <p>
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy text when an unknown printer took a galley of type and
            scrambled it to make a type book. survived not only five centuries,
            but also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset containing Lorem Ipsum passages, and more recently with
            desktop publishing including versions.
          </p>
          <p class="landing_block2_dffpara">
            typesetting industry. Lorem Ipsum has been the industry's standard
            <span
              >when an unknown printer took a galley of type and scrambled it to
              make</span
            >
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release.
          </p>
          <div class="orange_block_button">
            <button (click)="ScrollIntoView('.landing_bannerbttm_right')">
              Schedule an Appointment Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing_banner_between_block">
    <div class="common_width">
      <div class="landing_banner_between_block_wrp">
        <h2>There are many variations of passages of Lorem available!</h2>
      </div>
    </div>
  </div>

  <div class="landing_block3">
    <div class="common_width">
      <div class="landing_block_innerwrap">
        <div class="block_heading">
          <h1>Lorem Ipsum is simply dummt text?</h1>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by humour, or
            randomised words which don't look even slightly believable.
            <i
              >If you are going to use a passage of Lorem Ipsum, you need to be
              sure there isn't anything embarrassing hidden in the middle of
              text.</i
            >
            All the Lorem Ipsum it the generators on the Internet tend to repeat
            with it ispredefined chunks as necessary, making this the first true
            generator on the Internet.
          </p>
          <p class="landing_block2_dffpara">
            It uses a dictionary of
            <span>over 200 Latin words, combined with a handful</span> of model
            sentence structures, to generate Lorem Ipsum which looks reasonable.
            The generated Loremis therefore always free from repetition,
            injected humour, or non-characteristic words etc.If you are going to
            use a passage of Lorem Ipsum, you need to be sure there isn't
            anything embarrassing hidden in the middle of text.
          </p>
          <div class="orange_block_button">
            <button (click)="ScrollIntoView('.landing_bannerbttm_right')">
              Schedule an Appointment Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing_bottom_banner landing_bottombanner_1">
    <div class="common_width">
      <img
        src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/p2l_landing_bottombanner_1.webp"
        alt="p2l_landing_bottombanner_1"
      />
    </div>
  </div>

  <div class="landing_bottom_banner landing_bottombanner_2">
    <div class="common_width">
      <img
        src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/p2l_landing_bottombanner_2.webp"
        alt="p2l_landing_bottombanner_2"
      />
    </div>
  </div>

  <div class="footer_block">
    <p>
      Terms & Conditions Privacy Policy Copyright © 2023 All Rights Reserved
    </p>
  </div>

  
</mat-card>   -->


<div *ngIf="landingpageShow == 'practiceadd'">
  <mat-card class="Npractice_signupBG">

    <div class="Npractice_signupTOP">
      <div class="Npractice_signup_wrapper">

        <div *ngIf="logoUrl" class="landing_topbanner">

        </div>

        <div class="Npractice_signup_mainCon">

          <div class="Npractice_signupLeft">

            <!-- <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png"
              class="Npractice_signupLogo"> -->

              <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/repid_respose_virtual_payment.webp" alt="practice2lab_logo"  class="Npractice_signupLogo">

            <h2>We make it</h2>
            <h3>
              <span>easy for you to order </span>
              <span> more labs faster! </span>
            </h3>
            <h4>Here at <span>Practice2Lab</span>, we are committed to helping
              your clinic become the best medical outfit in the country. </h4>

            <div class="Npractice_signupLeftText">
              <label>Benefits for Providers</label>
              <ul>
                <li>Receive more data</li>
                <li>More straightforward process for encounter forms</li>
                <li>ICD and CPT Codes </li>
                <li>Instant access to AI technology</li>
              </ul>


            </div>

            <img
              src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/practice-signup-new/newpractice-signup_img1.webp"
              class="signup_img1new">



            <div class="Npractice_signupLeftarrowText">
              <label>
                <a>Click here to consult with our physician</a>
              </label>

            </div>





          </div>

          <div class="Npractice_signupRight">
            <div class="Npractice_signupRightmain">
              <div class="Npractice_signupRightform">

                <img
                  src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/practice-signup-new/newpractice-signup_formTop.webp"
                  class="signup_formTop">

                <h2>
                  <span>
                    <strong>Fill out</strong>
                    <label>the form to get started</label>
                  </span>
                </h2>


                <lib-showform class="addEditForm" [formdata]="practiceAddForm" [formfieldrefresh]="formfieldrefresh"
                  [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>
                <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card>

  <div class="Npractice_signupBody">

    <div class="Npractice_signup_wrapper">

      <div class="Npractice_signupBodyCon1">

        <div class="newhading">
          <h2><label><span>BENEFITS FOR PROVIDERS</span></label></h2>
        </div>

        <div class="newcon1">

          <div class="newcon1_wrapper">

            <div class="newtextcon">

              <h2><span>Receive more Data</span></h2>
              <p>Thanks to you providing your clients with Practice2Lab, your doctors can enhance diagnostic
                capabilities,
                personalize treatments, improve patient engagement, and help your clients practice preventive care. </p>

            </div>


          </div>


        </div>

        <div class="newcon2">

          <div class="newcon2_wrapper">

            <div class="newtextcon">

              <h2><span>More straightforward Process with Encounter Forms</span></h2>
              <p>Every doctor has to have a process to fill out, upload, and review encounter forms. Why not make it as
                simple as possible for doctors? Our process makes it easier for their patients to fill out encounter
                forms
                before they arrive through email or text or while the patient is at the clinic with an iPad or computer
                screen. Your clients can even upload their current encounter forms into Practice2Lab and still receive
                all
                the benefits of our service. </p>

            </div>


          </div>


        </div>

        <div class="newcon3">

          <div class="newcon3_wrapper">

            <div class="newtextcon">

              <h2><span>Access to ICD and CPT Codes</span></h2>
              <p>One of our doctor’s favorite benefits is that our software provides every patient with the ICD and CPT
                codes. Practice2Lab automatically provides the lab order and encounter forms for every patient and
                doctor
                uploaded into our system. All that is left for them to do is add their notes.</p>

            </div>


          </div>


        </div>

        <div class="newcon4">

          <h2><span>Instant Access to AI Technology</span></h2>

          <div class="newtextcon4">
            <h3>Decision Doc AI is included and offered to all of your doctors. There are many benefits to integrating
              AI
              technology with your clients, such as:</h3>
            <ul>
              <li>Accurate and Fast Diagnostics</li>
              <li>Personalized treatment Recommendations</li>
              <li>Streamlined Workflow</li>
              <li>Continual Learning</li>

            </ul>



          </div>

          <img
            src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/practice-signup-new/newpractice-signup_BodyImg4.webp"
            class="newtextcon4img">

        </div>



      </div>

    </div>


  </div>


  <div class="Npractice_signupBodyBTN">
    <div class="Npractice_signupBodyBTNcon">
      <a href="https://main.d39yapseldgzoj.amplifyapp.com/" target="_blank">If you want to research more about Decision
        Doc AI, go to our website here</a>
    </div>
  </div>


  <div class="Npractice_signupBodyfooter">
    <p>Copyright © 2023 All Rights Reserved</p>

  </div>
</div>




<!-- <------------------------- Landing Page 2 HTML --------------------------->

<!-- <mat-card class="landingpage2_wrapper" *ngIf="landingpageShow == 'leadadd'">

  <div class="landingpage2_banner">
    <div class="common_width">
      <div class="landing_banner ">
        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/landing2_topbanner.webp"
          alt="" />
      </div>
      <div class="landing_mainbanner_wrapper">
        <div class="landing_mainbanner_leftsec">
          <div class="mainbanner_leftsec_top">
            <div class="mainbanner_leftsec_cntnt">
              <div class="mainbanner_leftsec_whtbg">
                <h1>Lorem Ipsum is simply dummy text</h1>
                <p>Of the printing and typesetting</p>
              </div>
              <div class="mainbanner_leftsec_bluebg">
                <h1>Standard industry Lorem</h1>
                <h2>Lorem Ipsum is simply dummy text</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  <span>Lorem Ipsum has been the industry's</span> standard
                  dummy text ever since the 1500s,
                </p>
                <p class="mainbanner_bluebg_cprt">
                  when an unknown printer took a galley of type. Lorem Ipsum has
                  been the industry's standard dummy text
                </p>
              </div>
            </div>
            <div class="mainbanner_leftsec_arrowwrp">
              <p>Join us now and start helping your customers!</p>
            </div>
          </div>
        </div>
        <div class="landing_mainbanner_rghtsec">
          <div class="landing2_bnrformwrapper">
            <div class="landing2_bnrformhead">
              <h2>Fill out this form so we can start your enrollment today!</h2>
            </div>
            <lib-showform class="addEditForm" [formdata]="enrollmentForm" [formfieldrefresh]="formfieldrefresh"
              [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChangelead($event)">
            </lib-showform>
            <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing2_block1">
    <div class="common_width">
      <div class="landing2_block1_innr">
        <div class="landing2_block1_cntnt textwrapper">
          <h1>Lorem Ipsum is simply dummy text of the</h1>
          <h1>printing and typesetting industry</h1>
          <h5>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h5>
          <div class="landing2_block1_cardsec">
            <div class="landing2_cardsec_bullet bullet_section column_count2">
              <p>Lorem Ipsum is simply dummy text</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is simply dummy</p>
              <p>Lorem Ipsum</p>
            </div>
            <div class="landing2_cardsec_bottm textwrapper">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <span>Lorem Ipsum has been the industry's</span> standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </div>
        <div class="landing2_block1_bottom">
          <div class="landing2_block1_bottom_cntnt textwrapper">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
              <span>when an unknown printer took a galley of type and scrambled it
                to make a type specimen book</span>. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <div class="landing2_block1_bottom_head">
              <h1>Lorem Ipsum is simply dummy text!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing2_block2">
    <div class="common_width">
      <div class="landing2_block2_wrapper">
        <div class="landing2_block2_left">
          <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/landing2_block2img.webp"
            alt="" />
        </div>
        <div class="landing2_block2_rght textwrapper">
          <h1>Lorem Ipsum is simply dummy text of the printing</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries.
          </p>
          <div class="landing2_block2_bulletsec bullet_section column_count2">
            <p>Lorem Ipsum is simply dummy text</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply dummy</p>
            <p>Lorem Ipsum</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing2_block3">
    <div class="common_width">
      <div class="landing2_block3_wrapper">
        <h1>Lorem Ipsum is simply dummy text of the printing</h1>
        <div class="landing2_block3_cntntwrp textwrapper">
          <div class="block3_cntnt_top bullet_section column_count2">
            <p>Lorem Ipsum is simply dummy text</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply dummy</p>
            <p>Lorem Ipsum</p>
          </div>
          <p class="block3_cntnt_bttm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <span>Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s,</span>
            when an unknown printer took a galley of type and scrambled it to
            make a type specimen book.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="landing2_block4">
    <div class="common_width">
      <div class="landing2_block4_wrapper">
        <div class="landing2_block4_cntnt textwrapper">
          <h1>
            Lorem Ipsum is simply dummy text of the printing
          </h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <span>Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s,</span>
            when an unknown printer took a galley of type and scrambled it to
            make a type specimen book. It has survived not only five centuries,
            but also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages,
          </p>
          <div class="landing2_block4_cntnt_card common_curve_card">
            <h2>Lorem Ipsum is simply dummy text!</h2>
            <div class="cntntcard_bttm bullet_section column_count2">
              <p>Lorem Ipsum is simply dummy text</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is</p>
              <p>Lorem Ipsum is simply</p>
              <p>Lorem Ipsum is simply dummy</p>
              <p>Lorem Ipsum</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing2_block5">
    <div class="common_width">
      <div class="landing2_block5_wrapper textwrapper">
        <h1>Lorem Ipsum is simply dummy text of the printing</h1>
        <div class="landing2_block5_cntnt">
          <h2>Lorem Ipsum is simply dummy?</h2>
          <p>
            <span>Lorem Ipsum is simply dummy text of the printing and typesetting
              industry</span>. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s.
          </p>
          <div class="landing2_block5_cntnt_card common_curve_card">
            <h2>Lorem Ipsum is simply dummy text!</h2>
            <div class="cntntcard_bttm">
              <h5>
                <span>Lorem Ipsum is simply dummy text</span> of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h5>
            </div>
          </div>

        </div>
        <p class="trns_grd"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's</span> standard dummy text ever since the 1500s, when an unknown printer took a
          galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but
          also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
          with the release of Letraset sheets containing Lorem Ipsum passages, </p>
      </div>
    </div>
  </div>
  <div class="landing_banner landing_banner_bottom">
    <div class="common_width">
      <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_landingpages/landing2_bottombanner.webp"
        alt="">
    </div>
  </div>
  <div class="footer_block">
    <p>
      Terms & Conditions Privacy Policy Copyright © 2023 All Rights Reserved
    </p>
  </div>
 

</mat-card> -->



<div *ngIf="landingpageShow == 'leadadd'">

  <div class="leadaddNewTop">

    <div class="leadaddNewwrapper">

      <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add-new-topBanner.webp" class="topBannerNew">

      <div class="leadaddNewBanner">

        <div class="leadaddNewBannerLeft">
          <h1>We value your time, and filling out your information before you come into our office will help us serve
            you as <span>quickly as possible</span></h1>


          <div class="leadaddNewBannerLeftText">
            <div class="leadaddNewBannerLeftTextBG">
              <h2>As you fill out the symptom list, you will give our doctors crucial information that will assist in
                understanding
                your condition accurately.</h2>

              <div class="leadaddNewBannerLeftText2">
                <div class="leadaddNewBannerLeftText2BG">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_bannerIcon1.webp"
                    class="bannerIcon1">


                  <label> You will have </label>
                  <ul>
                    <li>Enhanced Doctor-Patient communication</li>
                    <li> Saved Time </li>
                    <li>Facilitated and Accurate Diagnoses</li>
                    <li>Tailored Personal Treatment Plans</li>
                    <li>Patient Privacy and Confidentiality</li>
                  </ul>


                </div>
              </div>
            </div>
          </div>

          <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_bannerIMG1New.webp"
            class="add_bannerIMG1">

          <div class="leadaddNewBannerLeftArrow">
            <label>Take 5 minutes to fill out the intake FORM and rep the reward</label>
          </div>

        </div>

        <div class="leadaddNewBannerRight">


          <div class="leadaddNewBannerRightformhead" id="leadaddlib">
            <h2>Fill out this form so we can start your enrollment <span>today!</span></h2>
          </div>
          <lib-showform class="addEditForm" [formdata]="enrollmentForm" [formfieldrefresh]="formfieldrefresh"
            [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChangelead($event)">
          </lib-showform>
          <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
        </div>





      </div>


    </div>

  </div>

  <div class="leadaddNewBody">

    <div class="leadaddNewblock1">

      <div class="leadaddNewwrapper">

        <div class="leadaddNewblock1Con1">

          <div class="leadaddNewblock1Con1_text">
            <h2>Enhanced Doctor-Patient Communication</h2>
            <p>Improving doctor-patient communication is crucial for adequate healthcare. By filling out the symptom
              list, <span>patients can provide their doctors with vital information about their symptoms,</span> helping
              to ensure clear and comprehensive communication. This enables doctors to understand their patients’
              conditions and concerns better, leading to more accurate diagnoses and personalized treatment plans. </p>

          </div>

          <div class="leadaddNewblock1Con1_img">

            <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_newbodyIMG1.webp">

          </div>


        </div>
        <div class="leadaddNewblock1Con1">

          <div class="leadaddNewblock1Con1_text">
            <h2>Saving Valuable Consultation Time</h2>
            <p>Completing the symptom list before the appointment saves valuable consultation time. <span>Doctors can
                focus on the patient’s</span> concerns during the visit by reviewing the information in advance. This
              streamlined process enhances efficiency and allows doctors to provide more focused care, addressing the
              patient’s needs promptly and effectively.</p>

          </div>

          <div class="leadaddNewblock1Con1_img">

            <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_newbodyIMG2.webp">

          </div>


        </div>


      </div>


    </div>

    <div class="leadaddNewblock2">

      <div class="leadaddNewwrapper">
        <h2>Facilitating Accurate Diagnoses</h2>
        <p>Accurate diagnoses rely on accurate symptom reporting. <span>Doctors can make more accurate diagnoses by
            ensuring patients provide detailed</span> and precise information through the symptom list. This proactive
          step leads to appropriate and timely treatment plans, improving patient outcomes and minimizing the need for
          unnecessary investigations or delays in care.</p>

      </div>
    </div>

    <div class="leadaddNewblock1">

      <div class="leadaddNewwrapper">

        <div class="leadaddNewblock1Con1_textBG1">

          <div class="leadaddNewblock1Con1">

            <div class="leadaddNewblock1Con1_text">
              <h2>Tailoring Personalized Treatment Plans</h2>
              <p>Personalized treatment plans are crucial for optimal patient care. Detailed symptom reporting enables
                doctors to develop tailored treatment plans based on each patient’s unique symptoms and needs. Doctors
                can optimize treatment approaches by considering individual factors, improving outcomes
                and patient satisfaction.</p>

            </div>

            <div class="leadaddNewblock1Con1_img">

              <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_newbodyIMG4.webp">

            </div>


          </div>


          <div class="leadaddNewblock1Con1">

            <div class="leadaddNewblock1Con1_text ">
              <h2>Patient Privacy and Confidentiality</h2>
              <p>Patient privacy and confidentiality are of utmost importance. The symptom list ensures that patients’
                information is securely handled and used solely for medical purposes.
                Rest assured that all data is treated with strict confidentiality, adhering to privacy regulations and
                ethical standards.</p>

            </div>

            <div class="leadaddNewblock1Con1_img">

              <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add_newbodyIMG5.webp">

            </div>


          </div>
        </div>

      </div>


    </div>

    <div class="leadaddNewblock3">

      <div class="leadaddNewwrapper">

        <h2>Please take 5 minutes</h2>
        <h3>now to fill out our intake form before <span (click)="visit()">your visit!</span></h3>

        <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add-bottomImg2.webp" class="leadbottomImg2">
        <img src="https://all-frontend-assets.s3.amazonaws.com/leadAdd/lead-add-bottomImg1.webp" class="leadbottomImg1">

      </div>
    </div>

  </div>


  <div class="leadaddNewBodyfooter">
    <p>Copyright © 2023 All Rights Reserved</p>

  </div>

</div>