import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../usermanagement/user-list/user-list.component';



@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.css']
})

export class EmailTemplateListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "status", 'usertype'];
  public listprogressBar: any = false;
  public tabledatatalist: []
  tablename = "email_templates";
  editroute = "";
  updateendpoint = "email/emailstatus";
  deleteendpoint = "email/deletetemplate";
  datacollection: any = "email/emaillisted";

  searchendpoint = "email/emaillisted";
  date_search_endpoint: any = "email/emaillisted";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public userTypeArray: any = [
    { val: 'admin', name: "Admin" },
    { val: 'doctor', name: "Doctor" },
    { val: 'laboratory', name: "Laboratory" },
    { val: 'sales_person', name: "Salesperson" },
    { val: 'practice', name: "Practice" },
  ];

  modify_header_array: any = {
    subject: "Subject",
    slug: "Slug",
    // usertype: "Type",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    user_type: "User Type"
  };
  search_settings: any = {
    search: [{
      label: "Search By Subject", field: '_id',
      // values: this.authval,
      serversearchdata: { endpoint: 'email/autocompletesubject' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },

      // {
      //   label: "Search By Slug ", field: 'slug',
      //   // values: this.authval,
      //   serversearchdata: { endpoint: 'email/autocompleteslug' },
      //   // value: [{ val: 'jessica', name: 'jessica' }]
      // },
    ],
    // textsearch: [
    //   { label: "Search By Subject", field: 'subject' },
    //   { label: "Search By Slug", field: 'slug' },

    // ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray },
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["subject", "createdon_datetime", "updatedon_datetime", "status", "usertype"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Template',
      //   type: 'button',
      //   name: 'add_template',
      //   tooltip: 'Add Template',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "template", val: "Template" },
      { key: "subject", val: "Subject" },
      { key: "slug", val: "Slug" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "user_type", val: "User Type" },
      // {key: 'vd_array',val:"User Type"}

    ],
    updateendpoint: "email/emailstatus",
    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "email/emailstatus",

    deleteendpointmany: "email/deletetemplate",
    tableheaders: ["subject", "slug", "user_type", "createdon_datetime", "updatedon_datetime", "status"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'email/previewemail',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['subject', 'slug', 'user_type', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;

  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    if (!window.location.pathname.includes('admin/email-template')) {
      this.router.navigateByUrl('404')
    }
  }

  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }


  ngOnInit() {
    this.userListFetching();
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      if (response) {
        response.data.results.res.forEach((e) => {
          // e.user_type = e.user_type.replace(/([A-Z])/g, ' $1') .toUpperCase()
          // e.user_type = e.user_type.charAt(0).toLowerCase() + e.user_type.slice(1)
          if (e.user_type == "doctor") {
            e.user_type = "Doctor"
          }
          else if (e.user_type == "laboratory") {
            e.user_type = "Laboratory"
          }
          else if (e.user_type == "admin") {
            e.user_type = "Admin"
          }
          else if (e.user_type == "sales_person") {
            e.user_type = "Salesperson"
          }
          else if (e.user_type == "practice") {
            e.user_type = "Practice"
          }
        }
        );

        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
        console.log("activatedRoute responseff", this.tabledatatalist)
      }
    })
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;


          break;
      }
    }
    console.log("cookieService", this.cookieData)
  }

  addBtn() {
    this.router.navigateByUrl(`admin/email-template/add-template`);
  }
  userListFetching() {

    this.progressLoader = true;
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }


    this.httpService.httpViaPost('email/emaillisted-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;

      }

      this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }

  listenLiblistingChange(data: any = null) {
    console.log("listenLiblistingChange", data);
    // practice/intake-form-preview/view
    // console.log("Op=====>1", data);
    if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          this.router.navigateByUrl(`admin/email-template/template/edit/${data.custombuttonclick.data._id}`)
          break;
        case "Delete":
          // this.deleteListItem(data.custombuttonclick.data)
          break;
        default: null
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
  }

  onLiblistingButtonChange(val: any) {
    console.log("onLiblistingButtonChange val", val)
    if (val.action == "customlistenbutton") {
      if (val.buttondata.name == 'add_template') {
        this.router.navigateByUrl('admin/email-template/add-template')
      }
    }

  }
  listenFormFieldChange(val: any) {


  }
  editListItem(data: any) {
    throw new Error('Method not implemented.');
  }



}