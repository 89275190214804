import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
@Component({
  selector: 'app-add-evaluation',
  templateUrl: './add-evaluation.component.html',
  styleUrls: ['./add-evaluation.component.css']
})
 

export class AddEvaluationComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }
  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    } else {
    }
    console.log("editFormData", this.editFormData);
    this.initailForm();
  }



  initailForm() {
    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `${this.cookieData.user_type}/miscellaneous/evaluation-list`, // use for redirect after cancel the form

      fields: [

        {
          label: 'E & M Code',
          name: 'code',
          value: this.editFormData && this.editFormData.code ? this.editFormData.code : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'E & M Code is Required' },

          ],
        },

        

        {
          label: "Description",
          name: "description",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "E & M Description field Needs to be required" },
          ]
        },

        {
          label: 'Time',
          name: 'time_required',
          value: this.editFormData && this.editFormData.time_required ? this.editFormData.time_required : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Time is Required' },

          ],
        },

        {
          label: 'Non Facility Charge',
          name: 'non_facility_charge',
          value: this.editFormData && this.editFormData.non_facility_charge ? this.editFormData.non_facility_charge : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Time is Required' },

          ],
        },


        {
          label: 'Non Facility Limited Charge',
          name: 'non_facility_limited_charge',
          value: this.editFormData && this.editFormData.non_facility_limited_charge ? this.editFormData.non_facility_limited_charge : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Non Facility Limited Charge is Required' },

          ],
        },

       
       

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
    if (this.editFormData) {
      console.log("sdfsd")
      
      // this.locationFieldManage(this.editFormData.location)
    }

  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val.field && val.field == "fromsubmitservererror") {
      this.addFormLoader = true;
      val.fromval.location = this.externaldatavalue;
      let dataobj = {
        data: val.fromval
      }
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }
 

      console.log('dataobjTest', dataobj)

      this.apiservice.httpViaPost('intake/addevaluation', dataobj).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/evaluation-list`);
          this.addFormLoader = false;
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }

      })

    }
  
  }


}