<app-header></app-header>
<mat-card class="adminBody hidepreviewbtn">
    <mat-card-content class="listingpage_wrapper">
        <h2 class="listingpageh2"> Manage User</h2>

        <div class="admin-list_v2">




            <div class="listingtableheading">
                <!-- <div *ngIf="UsersSummaryLoader">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div> -->
                <div class="listingtableheading_wrapper">
                    <h2><label>User Listing</label> </h2>

                    <button *ngIf="adduserBTN" (click)="openBottomSheet()" class="adduserBTN">Add User</button>

                </div>

            </div>
            <mat-progress-bar mode="indeterminate" [ngClass]="listprogressBar==true? 'show':'hide' "></mat-progress-bar>
            <div [ngClass]="listprogressBar==true? 'hide':'show' ">
                <lib-listing class="adminlisttable_v2 userListTable" *ngIf="tabledatalist != null && tabledatalist.length > 0" [datasource]="tabledatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner"
                    [deleteendpoint]="deleteendpoint" [updateendpoint]="updateendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                    [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                    [limitcond]="limitcond" [customlistenbutton]="customlistenbutton" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-listing>
            </div>



            <ng-container *ngIf="tabledatalist != null && tabledatalist.length == 0">
                <div class="noFoundTextinner">
                    <span>Oops!<br/> No Record Found</span>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>