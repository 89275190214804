import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-addedit-doctor',
  templateUrl: './addedit-doctor.component.html',
  styleUrls: ['./addedit-doctor.component.css']
})
export class AddeditDoctorComponent {


  public stateList: any = [];

  // emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  public addFormLoader: boolean = false;

  public doctorformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;

  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public cookieData: any = {};
  public externaldatavalue: any = [];
  public editFormData: any = null;
  public isedit: boolean = false;
  public isLocationBlank: boolean = false;
  public taxonomyList: any = [];
  public cookieDataID: any = {};
  public locationArray: any = [];

  public locationArrayLAB: any = [];

  public practicenameList: any = [];
  public practiceLocationList: any = [];
  public ispracticenameValueUpdate: boolean = false;
  public isLABpracticenameValueUpdate: boolean = false;
  public practiceList: any = [];
  public parentLabID: any = null

  public practicefields: any = [];
  public otherfields: any = [];

  public laboratoryfields: any = [];

  public isLocationValueUpdate: boolean = false;


  public locationList: any = [];


  constructor(private location: Location, private http: HttpServiceService, private activateRoute: ActivatedRoute, private httpService: HttpServiceService, private _snackBar: MatSnackBar, private router: Router, public matSnackBar: MatSnackBar, public cookieService: CookieService) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes(`${this.cookieData.user_type}/user-management`)) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
    }
  }


  ngOnInit() {
    console.log("event", this.activateRoute);

    // form calection

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

    console.warn("LOCCCC>>>>", this.cookieData);



    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
    };

    this.taxonomyFetching();

    if (this.cookieData.user_type == 'practice') {
      this.practiceAddEdit()
      let data = {
        _id: this.cookieData._id
      }
      this.fetchPracticeLocation(data)
    } else if (this.cookieData.user_type == 'laboratory') {
      this.laboratoryEdit();
      let data = {
        _id: this.cookieData._id
      }
      this.fetchPracticeList(data, 'fetch');
      setTimeout(() => {
        let myRow = document.querySelector('.libformclass');
        myRow.classList.add('labdoctorForm');
      }, 200);
    } else {
      this.otherAddEdit()
    }
    this.initailForm();
  }




  initailForm() {

    const finalFields = (this.cookieData.user_type === 'practice' ? this.practicefields : this.cookieData.user_type === 'laboratory' ? this.laboratoryfields : this.otherfields)
    console.log('finalFields', finalFields)
    this.doctorformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.isedit ? 'Update' : 'Submit',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      // cancelroute: `${this.cookieData.user_type}/user-management/users`, // use for redirect after cancel the form

      fields: finalFields
    }

    if (this.cookieData.user_type !== 'practice') {
      if (this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.practice_id && this.editFormData.practice_id._id) {
        let data: any = { _id: this.editFormData.practice_id._id };
        setTimeout(() => {
          this.fetchPracticeLocation(data)
        }, 200)
      }
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };

    }


  }



  practiceAddEdit() {

    this.practicefields = [
      {
        label: 'First Name',
        name: 'firstname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },



      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },

      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },





      {
        label: "Choose Location",
        name: "parent_location",
        // hint: ',0000',
        type: 'select',

        val: this.locationArray,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_location : '',
        validations: [
          { rule: 'required', message: 'Choose Location is Required' },
        ],
        multiple: true,
      },


      {
        label: "Taxonomies",
        name: "taxonomy",
        // hint: ',0000',
        type: 'select',
        value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
        val: this.taxonomyList,
        // value:[2021,2022],
        multiple: true,
        validations: [
          { rule: 'required', message: 'Select Taxonomies is Required' }
        ]
      },
      {
        label: 'NPI',
        // label: 'NPI(Select practice NPI)',
        name: 'npi',
        value: this.editFormData && this.editFormData.npi ? this.editFormData.npi : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'NPI is Required' },
        ],
      },

      {
        label: 'Password',
        name: 'password',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [
          { rule: 'required', message: 'Password is Required' },
          { rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }
        ],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "doctor",
        type: 'hidden'
      },

      {
        label: "Practice Name",
        name: "practice_id",
        type: 'hidden',
        value: this.cookieData._id,
      },

      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        type: 'hidden',
        value: this.cookieData.parent_laboratory,
      },
    ]
  }


  laboratoryEdit() {
    console.log("this.editFormData", this.editFormData)
    this.laboratoryfields = [
      {
        label: 'First Name',
        name: 'firstname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },



      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },

      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },

      {
        label: "Practice Name",
        name: "practice_id",
        // hint: ',0000',
        type: 'select',
        val: this.practiceList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.practice_id && this.editFormData.practice_id._id ? this.editFormData.practice_id._id : '',
        // multiple: true,
        validations: [
          { rule: 'required', message: 'Practice Name is Required' },
        ],
      },
      {
        label: 'Choose Location',
        name: 'parent_location',
        type: 'select',
        val: this.locationArray,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.parent_location ? this.editFormData.parent_location : '',
        validations: [
          { rule: 'required', message: 'Choose Location is Required' },
        ],
        multiple: true,
      },


      {
        label: "Taxonomies",
        name: "taxonomy",
        // hint: ',0000',
        type: 'select',
        value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
        val: this.taxonomyList,
        // value:[2021,2022],
        multiple: true,
        validations: [
          { rule: 'required', message: 'Select Taxonomies is Required' }
        ]
      },
      {
        label: 'NPI',
        // label: 'NPI(Select practice NPI)',
        name: 'npi',
        value: this.editFormData && this.editFormData.npi ? this.editFormData.npi : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'NPI is Required' },
        ],
      },

      {
        label: 'Password',
        name: 'password',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' },{ rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "doctor",
        type: 'hidden'
      },


      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        type: 'hidden',
        value: this.cookieData.parent_laboratory,
      },
    ]


  }


  otherAddEdit() {

    this.otherfields = [
      {
        label: 'First Name',
        name: 'firstname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },

      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },
      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        // hint: 'In months',
        type: 'autocomplete',
        // multiple: false,
        endpoint: "users/laboratoryAutocomplete",
        search_field: "name_search",

        val: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.parent_laboratory ? [{ key: this.editFormData.parent_laboratory.key, val: this.editFormData.parent_laboratory.val }] : [],
        value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.parent_laboratory ? this.editFormData.parent_laboratory._id : [],
        validations: [
          { rule: 'required', message: 'Parent Laboratory is Required' }
        ]

      },



      {
        label: "Practice Name",
        name: "practice_id",
        // hint: 'In months',
        type: 'select',
        // multiple: false,
        // endpoint: "users/practicenameforlab",
        // search_field: "name_search",

        val: this.practiceList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.practice_id && this.editFormData.practice_id._id ? this.editFormData.practice_id._id : [],
        validations: [
          { rule: 'required', message: 'Practice Name is Required' }
        ]

      },

      {
        label: 'Choose Location',
        name: 'parent_location',
        type: 'select',
        val: this.locationArray,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.parent_location ? this.editFormData.parent_location : '',
        validations: [
          { rule: 'required', message: 'Choose Location is Required' },
        ],
        multiple: true,
      },
      {
        label: "Taxonomies",
        name: "taxonomy",
        // hint: ',0000',
        type: 'select',
        value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
        val: this.taxonomyList,
        // value:[2021,2022],
        multiple: true,
        validations: [
          { rule: 'required', message: 'Select Taxonomies is Required' }
        ]
      },
      {
        label: 'NPI',
        // label: 'NPI(Select practice NPI)',
        name: 'npi',
        value: this.editFormData && this.editFormData.npi ? this.editFormData.npi : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'NPI is Required' },
        ],
      },

      {
        label: 'Password',
        name: 'password',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' },{ rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "doctor",
        type: 'hidden'
      },
    ]
  }



  locationFind() {
    let data: any = {
      "user_id": this.cookieData._id
    }
    this.httpService.httpViaPost('users/fetchLocationDetails', data).subscribe((response) => {
      console.warn("loccc", response)
      if (response.status = "success") {
        for (const key in response.results.res) {
          this.locationArray.push({ val: response.results.res[key]._id, name: response.results.res[key].location_name });
        }
      }
    })
  }
  taxonomyFetching() {
    let data: any = {
      sort: { type: 'desc', field: '_id' },
      searchcondition: {}
    }
    this.httpService.httpViaPost('users/taxonomyautocomplete', data).subscribe((response) => {
      console.log("taxonomy", response)
      if (response) {
        if (response.status == "success") {
          this.taxonomyList = response.res;
          if (this.taxonomyList.length > 0) {
            this.taxonomyList.forEach((e: any, i: number) => {
              e.val = e._id
            })
          }
          this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'taxonomy' ? e.val = this.taxonomyList : []);
        }
      }
    })
  }



  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChange++++", val);
    // console.log("this.doctorformdata.fields",val, this.doctorformdata.fields)

    this.cookieDataID = this.cookieData;

    // console.log('cookieDataID', this.cookieDataID)

    if (val.field && val.field == "formcancel") {
      this.location.back();

    }
    if (val) {
      if (val.autocompletefiledvalue && val.autocompletefiledvalue.parent_laboratory) {
        // reset the Practice name field
        if (this.ispracticenameValueUpdate && val.fromval.practice_id !== undefined) {
          this.ispracticenameValueUpdate = false;
        }
        let data = {
          _id: val.autocompletefiledvalue.parent_laboratory
        }
        this.fetchPracticeList(data);
      }

      if (this.cookieData.user_type == 'laboratory') {


        if (val.field && val.field.name == 'practice_id') {
          if (this.isLABpracticenameValueUpdate && val.fromval.parent_location !== undefined) {
            this.isLocationValueUpdate = false;
          }
          // let data = {
          //   _id: val.fieldval[0]
          // }
          // this.fetchPracticeLocation(data)
        }
      }
      // else {


      if (val.field && val.field.name && val.field.name == 'practice_id') {
        // alert(val.fieldval)
        let data: any = { _id: val.fieldval }
        this.fetchPracticeLocation(data)
      }
      // }




      if (val.field && val.field == "fromsubmit") {
        if (this.isedit) {
          val.fromval._id = this.editFormData._id;
        }

        let dataobj = {
          data: val.fromval,
        }




        // dataobj.data.practice_id = this.cookieData._id
        if (this.cookieData.user_type == 'laboratory') {
          dataobj.data.parent_laboratory = this.cookieData._id;
          if (dataobj.data.practice_id && dataobj.data.practice_id.length > 0) {
            // dataobj.data.practice_id = dataobj.data.practice_id[0]
          }
        } else {
          // dataobj.data.parent_laboratory = this.cookieData.parent_laboratory;
        };
        this.addFormLoader = true;

        if (dataobj.data.phone.length > 14) {
          dataobj.data.phone = dataobj.data.phone.substring(0, 14)
        }


        if (dataobj.data.fax.length > 14) {

          dataobj.data.fax = dataobj.data.fax.substring(0, 14)
        }
        dataobj.data.added_by = this.cookieData._id;
        this.httpService.httpViaPost('users/addUpdateData', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.location.back();
            // this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/users`);

            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }
        })
      }
    }

  }


  fetchPracticeList(data: any, type?: string) {
    this.httpService.httpViaPost('users/practicenameforlab', data).subscribe((response) => {
      console.log("my change response++++", response.res)
      if (response) {
        this.practiceList = response.res;
        if (this.practiceList.length > 0) {
          this.practiceList.forEach((e: any, i: number) => {
            e.val = e._id
          })
        };
        if (type) {
          this.ispracticenameValueUpdate = false;
        }
        if (!this.ispracticenameValueUpdate) {
          this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'practice_id' ? e.val = this.practiceList : []);
          if (!type) {
            this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'parent_location' ? e.val = [] : [])
          }
          this.ispracticenameValueUpdate = true;
        }
        console.log("this.doctorformdata.fields practiceList", type, this.practiceList)
      }
    })
  }
  fetchPracticeLocation(data: any) {
    this.httpService.httpViaPost('users/fetchpracticelocation', data).subscribe((response) => {
      console.log("my change response++++", response.res)
      if (response) {
        this.locationArray = response.res;
        if (this.locationArray.length > 0) {
          this.locationArray.forEach((e: any, i: number) => {
            e.val = e._id
          })
        };
        // if (!this.ispracticenameValueUpdate) {
        // this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationArray : []);
        // this.ispracticenameValueUpdate = true;
        // }

        if (this.cookieData.user_type == 'laboratory') {

          if (!this.isLABpracticenameValueUpdate) {
            // this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationArray : []);
            this.isLABpracticenameValueUpdate = true;
          }
        }
        // else { 
        this.doctorformdata.fields = this.doctorformdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationArray : []);
        // }



      }










    })
  }




}


