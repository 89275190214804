 
<button class="close_button">
    <mat-icon style="cursor: pointer;" (click)="onNoClick()"> Cancel</mat-icon>
</button>

<img src="https://all-frontend-assets.s3.amazonaws.com/Disicion_doc_ai_new/home/as_disicion_doc_header_logo.webp" alt="#" class="as_disicion_doc">

<mat-tab-group>
    <mat-tab label="MEDICAL CODEX DIAGNOSTIC">

        <mat-progress-bar mode="indeterminate" *ngIf="progressBarLoader"></mat-progress-bar>
         
        <div class="ModalTabSubCon"> 

            <mat-tab-group>
                <mat-tab label="Patient Details"> <app-patient-details [patientdetails]="getleadData"></app-patient-details> </mat-tab>
                <mat-tab label="Patient History"> <app-patient-history [patientdetails]="getleadData"></app-patient-history> </mat-tab>
                <mat-tab label="Potential Diagnosis"><app-potential-diagnosis [potentialdiagnosis]="getleadData"></app-potential-diagnosis> </mat-tab>
                <mat-tab label="Recommended Next Steps"><app-recommended-next-steps [recommendednextstep]="rapidresponseID"></app-recommended-next-steps> </mat-tab>
                <!-- <mat-tab label="Lab Report"> <app-lab-reports [labreports]="patientID"></app-lab-reports> </mat-tab> -->
                <mat-tab label="Lab Report"> <app-lab-reports [labreports]="{ patientID: patientID}"></app-lab-reports> </mat-tab>
                
                <mat-tab label="Body Metrics"> <app-bodymetrics  [bodyMetrics]="bodyMetricsDatablock"></app-bodymetrics> </mat-tab>
                
                <mat-tab label="E & M Code"> <app-emcode></app-emcode> </mat-tab>
                 
              </mat-tab-group>

        </div>  
  
    </mat-tab>
    <mat-tab label="AI RECOMMENDATIONS AND DATA">

        <div class="ModalTabSubCon">

            <mat-tab-group>
                <mat-tab label="Possible Diagnosis"> <app-possible-diagnosis [possibleDiagnosis]="possibleDiagnosisData"> </app-possible-diagnosis></mat-tab>
                <mat-tab label="Treatment Protocal"> <app-treatment-protocal [treatmentProtocal]="treatmentProtocalData"></app-treatment-protocal> </mat-tab>
                <mat-tab label="Medication"> <app-medication [medicatio]="medicationData"></app-medication> </mat-tab>
                <mat-tab label="Life Style Changes"> <app-life-style-changes  [lifestylechanges]="lifestylechangesData"></app-life-style-changes></mat-tab>
                <mat-tab label="Sample Conversation"> <app-sample-conversation [sampleconversation]="{ taxonomychangesData: taxonomychangesData, patientID: rapidresponseID }"></app-sample-conversation> </mat-tab>
                <mat-tab label="Professional Opinion"><app-professional-opinion   [professionalopinion]="getleadData"></app-professional-opinion> </mat-tab>
                 
              </mat-tab-group>
 
        </div>
    </mat-tab>
    
  </mat-tab-group>