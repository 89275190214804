import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-recommended-next-steps',
  templateUrl: './recommended-next-steps.component.html',
  styleUrls: ['./recommended-next-steps.component.css']
})
export class RecommendedNextStepsComponent {

  public paramID: any;
  public recommendedData: any;
  public progressBarLoader = false;

  @Input() set recommendednextstep(value: any) {

    if (value) {
      this.paramID = value
      this.fetchRecommendedData();

    }

  }
  constructor(
    private httpService: HttpServiceService,
    public activatedRoute: ActivatedRoute
  ) {
   
  }

  public fetchRecommendedData(): void {
 
    let datasetval = {
      _id: this.paramID
    };
    this.progressBarLoader = true;
    
    console.log(datasetval, 'responseapi5');  

    this.httpService.httpViaPostRapidResponse('api5/getadditionaltest', datasetval).subscribe((response: any) => {
      if (response.status == "success") {
        this.recommendedData = response.res;
        this.progressBarLoader = false;
      }
    });
  }
}




 
