import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthgaurdGuard {
  public baseUrl: any = environment.slug;
  constructor(public authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("auth service route", this.baseUrl)
    switch (this.baseUrl) {
      case "landingpage":
        this.router.navigateByUrl('/landingpage');
        return false;
        break;
      case "leadaddlanding":
        this.router.navigateByUrl('/leadaddlanding');
        return false;
        break;
      default:
        if (this.authService.isLoggedIn()) {
          return true;
        }
        else{
          this.router.navigateByUrl('/login');
          return false;
        }
        
      break;
    }
    // if (this.baseUrl == 'landingpage') {
    //   this.router.navigateByUrl('/landingpage');
    //   return false;
    // }
    // else {
    //   if (this.authService.isLoggedIn()) {
    //     return true;
    //   }
    //   this.router.navigateByUrl('/login');
    //   return false;
    // }

  }

}
