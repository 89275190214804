import { Injectable } from '@angular/core';
import { HttpServiceService } from './http-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.landingpage';

@Injectable({
  providedIn: 'root'
})
export class LandingResolveService {
  public cookiedata: any = '';
  public environment = environment


  constructor(public httpService: HttpServiceService, public cookieService: CookieService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      console.log("environment", environment)
      let endpoint = route.data['endpoint'];
      let requestObj = route.data['requestcondition'];
     
      if (environment.slug == "landingpage") {

        if (route.routeConfig.path.includes('practice-signup')) {
          console.log('LLLLLLLL', route.params);
          requestObj.searchcondition = route.params;
        }
        if (route.routeConfig.path.includes('lead-add')) {
          console.log('LLLLLLLL', route.params);
          requestObj.searchcondition = route.params;
        }

        if (route.routeConfig.path.includes('quick-intake') || route.routeConfig.path.includes('intake-authorization')) {
          // console.log('LLLLLLLL', route.params);
          requestObj.searchcondition = route.params;
        }

        if (route.routeConfig.path.includes('email-intake')) {
          console.log('email-intake LLLLLLLL', route.params);
          requestObj.searchcondition = route.params;
        }

        if (route.routeConfig.path.includes('preview-intake')) {
          // console.log('LLLLLLLL', route.params);           
          requestObj.searchcondition._id = route.params['_id']
        }
        if (route.routeConfig.path.includes('physician-examination')) {
          // console.log('LLLLLLLL', route.params);           
          requestObj.searchcondition._id = route.params['_id']
        }
        if (route.routeConfig.path.includes('patient-folderview')) {
          // console.log('LLLLLLLL', route.params);           
          requestObj.searchcondition._id = route.params['_id'];
        }



        if (route.routeConfig.path.includes('thankyou-page')) {
          // console.log('LLLLLLLL', route.params);
          requestObj.searchcondition = route.params;
        }


        this.httpService.httpViaPost(endpoint, requestObj)
          .subscribe(response => {
            console.log(response, '<<<<<<< resolve response');
            return resolve(response);
          });
      } else {
        return resolve('');
      }

    })
  }
}
