import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-life-style-changes',
  templateUrl: './life-style-changes.component.html',
  styleUrls: ['./life-style-changes.component.css']
})
 

export class LifeStyleChangesComponent {

  public lifestylechangesDataset:any;

 

  @Input() set lifestylechanges(value: any) {

    if (value) {
    

      this.lifestylechangesDataset=value;

   
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}