<div class="order_sheet_body">

    <div class="order_sheet_loderbody">
        <!-- <div>
        <p style="color: red; text-align: center; ">
            <strong style="display: block;">Oops !</strong> NO Record Found
        </p>
    </div> -->

        <div class="my-progress-bar">
            <span>{{loadingPercent}}%</span>
            <mat-progress-bar mode="determinate" [value]="loadingPercent" (animationEnd)="progressInLoading()" >
                
            </mat-progress-bar>
        </div>
    </div>
</div>