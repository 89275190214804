import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { Location } from "@angular/common";

export interface DialogData {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;
}
@Component({
  selector: 'app-addeditpractice',
  templateUrl: './addeditpractice.component.html',
  styleUrls: ['./addeditpractice.component.css']
})
export class AddeditpracticeComponent {


  public stateList: any = [];

  // emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  public addFormLoader: boolean = false;

  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public personList: any = [
    //     {
    //     key : "641d6de1f3bbb586311827ad",
    // name
    //       :
    //       "test sales",
    // val
    //       :
    //       "test sales",
    // _id
    //       :
    //       "641d6de1f3bbb586311827ad"
    // }
  ];


  public locationList: any = [];

  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public cookieData: any = {};
  public externaldatavalue: any = [];
  public taxonomyData: any = [];
  public editFormData: any = null;
  public isedit: boolean = false;
  public isSalesPersonValueUpdate: boolean = false;
  public isLocationValueUpdate: boolean = false;
  public taxonomyList: any = [];

  public otherFormfieldForm: any = [];
  public userData: any;

  public laboratoryAddEditForm: any = [];
  public userImage: string = null;

  constructor(private location: Location, public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes(`${this.cookieData.user_type}/user-management`)) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
    }



  }





  deleteLocation(item: any, index: number) {
    console.log("this.externaldatavalue", this.externaldatavalue)
    this.externaldatavalue = this.externaldatavalue.filter((e: any, i: number) => {
      return i != index
    });
    if (item._id) {
      let data: any = {
        "id": item._id
      }
      this.apiservice.httpViaPost('users/deletelocation', data).subscribe((response) => {
        console.log("dataobj", response)
      })
    }
  }
  editLocation(item: any, index: number) {
    console.log("this.externaldatavalue", item, index)
    const dialogRef = this.dialog.open(ExternalDataLocationModalComponent, {
      panelClass: "externaldata-class",
      height: "500px",
      width: "600px",
      data: {
        heading: "Edit Location Data",
        locationObj: item
      },
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.externaldatavalue.forEach((e: any, i: number) => {
          if (index == i) {
            console.log("externaldatavalue res", res, "this.externaldatavalue ", this.externaldatavalue)
            this.externaldatavalue[i] = res.formObj
          }
        })
      }
      console.log("externaldatavalue res", res, "this.externaldatavalue ", this.externaldatavalue)
      // let form_field_location = document.querySelector(".form_field_location");
      // form_field_location.classList.add('customExternalData')
      // let location_col_wrapper: any = document.querySelector(".location_col_wrapper");
      // form_field_location.appendChild(location_col_wrapper);
      // // dynamically push to library end

      // if (res.data.flag == "yes") {
      //   this.externaldatavalue.push(res.formObj);
      //   for (let i in res.data.externaldatavalue.value) {
      //     this.laboratoryformdata.fields.forEach((e, i) => {
      //       if (e.label === "Location") {
      //         this.laboratoryformdata.fields[i].value.push(
      //           res.data.externaldatavalue.value[i]
      //         );
      //       }
      //     })
      //   }
      // }
      // console.log("externaldatavalue", this.externaldatavalue);
      // let locationlength = this.externaldatavalue.length
      // console.log("locationlength", locationlength);
    })

  }





  ngOnInit() {
    this.userData = JSON.parse(this.cookieService.get("login_user_details"));
    console.log("this.userData", this.userData)
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      // switch (this.cookieData.user_type) {
      //   case 'laboratory':
      //     // alert('laboratory')
      //     this.laboratoryAddEdit()
      //     break;
      //     // case 'admin':
      //     //   // alert('laboratory')
      //     //   this.otherFormfield();
      //     //   break;
      //   default:
      //   //   // alert('admin')
      //     this.otherFormfield()
      //     break
      // }


    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            if (this.editFormData.logo && Object.keys(this.editFormData.logo).length > 0) {
              this.userImage = `https://pece-training-files.s3.amazonaws.com/${this.editFormData.logo.path}${this.editFormData.logo.fileservername}`
            }
            // let tempArr: any = [];
            //     for (let i in this.addLocationForm.value) {
            //       let tempObj = { key: i, label: this.humanize(i), val: this.addLocationForm.value[i] };
            //       tempArr.push(tempObj)
            //     }

          }
        }
      });
      // this.editForm();
    }







    console.log("editFormData", this.editFormData);
    if (this.userData.user_type == 'laboratory') {
      // alert('laboratory')
      console.log('laboratory user')
      let data = {
        "searchcondition": {
          _id: this.userData._id
        }
      }
      let dataloc = {

        _id: this.userData._id

      }
      this.personListlab(data)
      this.locationListlab(dataloc)
      this.laboratoryAddEdit()



    } else {
      console.log('laboratory user not')




      // alert('admin')



      this.otherFormfield()
    }

    this.taxonomyFetching();
    this.initailForm();

    console.log('type000000', this.cookieData.user_type)

  }

  taxonomyFetching() {
    let data: any = {
      sort: { type: 'desc', field: '_id' },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('users/taxonomyautocomplete', data).subscribe((response) => {
      console.log("taxonomy", response)
      if (response) {
        if (response.status == "success") {
          this.taxonomyList = response.res;
          if (this.taxonomyList.length > 0) {
            this.taxonomyList.forEach((e: any, i: number) => [
              e.val = e._id
            ])
          }
          this.practiceformdata.fields = this.practiceformdata.fields.filter((e) => e.name == 'taxonomy' ? e.val = this.taxonomyList : []);
        }
      }
    })
  }

  laboratoryAddEdit() {
    console.log("editFormData", this.editFormData);
    this.laboratoryAddEditForm = [

      {
        heading: "<h1>Parent Information</h1>",
        name: "married",

      },


      {
        label: "Salesperson",
        name: "sales_person",
        // hint: ',0000',
        type: 'select',

        val: this.personList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.sales_person : '',
        multiple: false,
      },

      {
        label: "Location",
        name: "parent_location",
        // hint: ',0000',
        type: 'select',

        val: this.locationList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_location : '',
        multiple: true,
      },


      {
        heading: "<h1>Contact Information</h1>",
        name: "married",
      },


      {
        label: 'First Name',
        name: 'contact_person_firstname',
        value: this.editFormData && this.editFormData.contact_person_firstname ? this.editFormData.contact_person_firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'contact_person_lastname',
        value: this.editFormData && this.editFormData.contact_person_lastname ? this.editFormData.contact_person_lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },
      {
        label: 'Phone Number',
        name: 'contact_phone',
        value: this.editFormData && this.editFormData.contact_phone ? this.editFormData.contact_phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone Number is Required' }],
      },
      {
        label: 'Contact Email',
        name: 'contact_email',
        type: 'email',
        hint: '',
        value: this.editFormData && this.editFormData.contact_email ? this.editFormData.contact_email : '',
        validations: [
          { rule: 'required', message: 'Contact Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        heading: "<h1>Practice Information</h1>",
        name: "married",
      },

      {
        label: 'Practice Name',
        name: 'practice_name',
        value: this.editFormData && this.editFormData.practice_name ? this.editFormData.practice_name : '',
        type: 'text',
        classname: 'aaa',
        validations: [
          { rule: 'required', message: 'Practice Name is Required' },

        ],
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && this.editFormData.email ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Login email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && this.editFormData.phone ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },
      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },
      {
        label: "Taxonomies",
        name: "taxonomy",
        type: 'select',
        value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
        val: this.taxonomyList,
        // value:[2021,2022],
        multiple: true,
      },
      {
        label: 'NPI',
        name: 'npi',
        value: this.editFormData && this.editFormData.npi ? this.editFormData.npi : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'NPI is Required' },
        ],
      },



      {
        label: 'Name of EMR software being used',
        name: 'emr',
        value: this.editFormData && this.editFormData.emr ? this.editFormData.emr : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Contact Person is Required' },
        ],
      },
      {
        label: ' EIN Number',
        name: 'ein',
        value: this.editFormData && this.editFormData.ein ? this.editFormData.ein : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'EIN Number is Required' },
        ],
      },




      {
        label: 'Password',
        name: 'password',
        value: '',
        passwordflag: true,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' }, { rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: '',
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },
      {
        label: "Location",
        name: "location",
        type: 'externaldata',
        value: this.editFormData && this.editFormData.location ? this.editFormData.location : []
      },


      {
        label: this.editFormData ? "Edit Logo" : "Add Logo",
        name: "logo",
        type: "file",
        validations: [
          { rule: 'required', message: 'Logo is Required' },

        ],
        // multiple: false,
        prefix: Date.now(),
        // path: "practice/",
        // baseurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        // bucket: "pece-training-files",
        // apiurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/",
        // apideleteurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
        path: 'practice2lab/',
        bucket: 'all-frontend-assets',
        baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
        apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
        apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
        value: this.editFormData && this.editFormData.logo && this.editFormData.logo.name ? { url: this.editFormData.logo.baseurl, type: 'image', name: this.editFormData.logo.name } : undefined
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "practice",
        type: 'hidden'
      },

      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        type: 'hidden',
        value: this.userData._id,
      },

    ]

  }

  otherFormfield() {
    this.otherFormfieldForm = [

      {
        heading: "<h1>Parent Information</h1>",
        name: "married",

      },

      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        // hint: 'In months',
        type: 'autocomplete',
        // multiple: false,
        endpoint: "users/laboratoryAutocomplete",
        search_field: "name_search",

        val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.parent_laboratory.key, val: this.editFormData.parent_laboratory.val }] : [],
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_laboratory._id : [],
        validations: [
          { rule: 'required', message: 'Parent Laboratory is Required' }
        ]

      },
      {
        label: "Salesperson",
        name: "sales_person",
        // hint: ',0000',
        type: 'select',

        val: this.personList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.sales_person : '',
        multiple: false,
      },

      {
        label: "Location",
        name: "parent_location",
        // hint: ',0000',
        type: 'select',

        val: this.locationList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_location : '',
        multiple: true,
      },


      {
        heading: "<h1>Contact Information</h1>",
        name: "married",
      },


      {
        label: 'First Name',
        name: 'contact_person_firstname',
        value: this.editFormData && this.editFormData.contact_person_firstname ? this.editFormData.contact_person_firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'contact_person_lastname',
        value: this.editFormData && this.editFormData.contact_person_lastname ? this.editFormData.contact_person_lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },
      {
        label: 'Phone Number',
        name: 'contact_phone',
        value: this.editFormData && this.editFormData.contact_phone ? this.editFormData.contact_phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone Number is Required' }],
      },
      {
        label: 'Contact Email',
        name: 'contact_email',
        type: 'email',
        hint: '',
        value: this.editFormData && this.editFormData.contact_email ? this.editFormData.contact_email : '',
        validations: [
          { rule: 'required', message: 'Contact Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        heading: "<h1>Practice Information</h1>",
        name: "married",
      },

      {
        label: 'Practice Name',
        name: 'practice_name',
        value: this.editFormData && this.editFormData.practice_name ? this.editFormData.practice_name : '',
        type: 'text',
        classname: 'aaa',
        validations: [
          { rule: 'required', message: 'Practice Name is Required' },

        ],
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && this.editFormData.email ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Login email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },

      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && this.editFormData.phone ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },
      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },
      {
        label: "Taxonomies",
        name: "taxonomy",
        type: 'select',
        value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
        val: this.taxonomyList,
        // value:[2021,2022],
        multiple: true,
      },
      {
        label: 'NPI',
        name: 'npi',
        value: this.editFormData && this.editFormData.npi ? this.editFormData.npi : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'NPI is Required' },
        ],
      },



      {
        label: 'Name of EMR software being used',
        name: 'emr',
        value: this.editFormData && this.editFormData.emr ? this.editFormData.emr : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Contact Person is Required' },
        ],
      },
      {
        label: ' EIN Number',
        name: 'ein',
        value: this.editFormData && this.editFormData.ein ? this.editFormData.ein : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'EIN Number is Required' },
        ],
      },




      {
        label: 'Password',
        name: 'password',
        value: '',
        passwordflag: true,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' }, { rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        value: '',
        passwordflag: true,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },
      {
        label: "Location",
        name: "location",
        type: 'externaldata',
        value: this.editFormData && this.editFormData.location ? this.editFormData.location : []
      },


      {
        label: this.editFormData ? "Edit Logo" : "Add Logo",
        name: "logo",
        type: "file",
        validations: [
          { rule: 'required', message: 'Logo is Required' },

        ],
        // multiple: false,
        prefix: Date.now(),
        // path: "practice/",
        // baseurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        // bucket: "pece-training-files",
        // apiurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/",
        // apideleteurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
        path: 'practice2lab/',
        bucket: 'all-frontend-assets',
        baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
        apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
        apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
        value: this.editFormData && this.editFormData.logo && this.editFormData.logo.name ? { url: this.editFormData.logo.baseurl, type: 'image', name: this.editFormData.logo.name } : undefined
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "practice",
        type: 'hidden'
      },

    ]

  }


  initailForm() {
    const finalFields = (this.userData.user_type === 'laboratory' ? this.laboratoryAddEditForm : this.otherFormfieldForm)

    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      // cancelroute: `${this.cookieData.user_type}/user-management/users`, // use for redirect after cancel the form
      // cancelroute: this.location.back(),



      fields: finalFields
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
      this.locationFieldManage(this.editFormData.location)
    }
  }

  locationFieldManage(locationArr) {
    this.externaldatavalue = locationArr;
    setTimeout(() => {
      if (document.querySelector(".form_field_location")) {
        let form_field_location = document.querySelector(".form_field_location");
        console.log("form_field_location", form_field_location)
        form_field_location.classList.add('customExternalData')
        let location_col_wrapper: any = document.querySelector(".location_col_wrapper");
        form_field_location.appendChild(location_col_wrapper);
      }
    }, 200);
  }

  listenFormFieldChange(val: any) {

    console.log("listenFormFieldChange++++", val);
    if (val.action != null && val.action == "externaldata") {
      // console.log('listenFormFieldChange', val);

      const dialogRef = this.dialog.open(ExternalDataLocationModalComponent, {
        panelClass: "externaldata-class",
        height: "500px",
        width: "600px",
        data: {
          heading: "Add Field Data",
          name: val.fieldVal.name,
          value: val.fieldVal.value,
          user_id: this.editFormData ? this.editFormData._id : undefined
        },
      });
      dialogRef.disableClose = true;

      dialogRef.afterClosed().subscribe((res) => {
        console.log("externaldatavalue res", res)
        if (res.data.flag == "yes") {
          this.externaldatavalue.push(res.formObj);
          setTimeout(() => {
            let form_field_location = document.querySelector(".form_field_location");
            form_field_location.classList.add('customExternalData')
            let location_col_wrapper: any = document.querySelector(".location_col_wrapper");
            form_field_location.appendChild(location_col_wrapper);
          }, 100)
        }
        // console.log("externaldatavalue", this.externaldatavalue);
        let locationlength = this.externaldatavalue.length
        // console.log("locationlength", locationlength);
      });
    }

    if (val.autocompletefiledvalue && val.autocompletefiledvalue.parent_laboratory) {
      if (this.isSalesPersonValueUpdate && val.fromval.sales_person !== undefined) {
        this.isSalesPersonValueUpdate = false;
      }
      let data = {
        "searchcondition": {
          _id: val.autocompletefiledvalue.parent_laboratory
        }
      }
      this.personListlab(data)
      // this.apiservice.httpViaPost('users/salespersonAutocomplete', data).subscribe((response) => {
      //   // console.log("my change response++++", response.res)
      //   this.personList = response.res;
      //   if (this.personList.length > 0) {
      //     this.personList.forEach((e: any, i: number) => {
      //       e.val = e._id
      //     })
      //   }
      //   if (!this.isSalesPersonValueUpdate) {
      //     this.practiceformdata.fields = this.practiceformdata.fields.filter((e) => e.name == 'sales_person' ? e.val = this.personList : []);
      //     this.isSalesPersonValueUpdate = true;
      //   }
      // })
    }


    if (val.autocompletefiledvalue && val.autocompletefiledvalue.parent_laboratory) {
      if (this.isLocationValueUpdate && val.fromval.sales_person !== undefined) {
        this.isLocationValueUpdate = false;
      }
      let data = {
        // "searchcondition": {
        _id: val.autocompletefiledvalue.parent_laboratory
        // }
      }

      this.locationListlab(data)
      // if (this.userData.user_type !== 'laboratory') {
      // //   let data = {
      // //   "searchcondition": {
      // //     _id: val.autocompletefiledvalue.parent_laboratory
      // //   }
      // // }
      // this.locationListlab(data)
      // }

    }

    if (val.field && val.field == "formcancel") {
      this.location.back();

    }
    if (val.field && val.field == "fromsubmit") {
      val.fromval.location = this.externaldatavalue;
      let dataobj = {
        data: val.fromval
      }
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }

      this.addFormLoader = true;

      if (dataobj.data.phone.length > 14) {
        dataobj.data.phone = dataobj.data.phone.substring(0, 14)
      }

      if (dataobj.data.contact_phone.length > 14) {
        dataobj.data.contact_phone = dataobj.data.contact_phone.substring(0, 14)
      }

      if (dataobj.data.fax.length > 14) {
        dataobj.data.fax = dataobj.data.fax.substring(0, 14)
      }
      dataobj.data.added_by = this.cookieData._id;

      this.apiservice.httpViaPost('users/addUpdateData', dataobj).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.location.back();
          // this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/users`);
          this.addFormLoader = false;
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }

      })



    }



  }

  personListlab(data: any) {
    this.apiservice.httpViaPost('users/salespersonAutocomplete', data).subscribe((response) => {
      // console.log("my change response++++", response.res)
      if (response) {
        this.personList = response.res;
        if (this.personList.length > 0) {
          this.personList.forEach((e: any, i: number) => {
            e.val = e._id
          })
        }
        if (!this.isSalesPersonValueUpdate) {
          this.practiceformdata.fields = this.practiceformdata.fields.filter((e) => e.name == 'sales_person' ? e.val = this.personList : []);
          this.isSalesPersonValueUpdate = true;
        }
      }
    })
  }

  locationListlab(data: any) {
    this.apiservice.httpViaPost('users/fetchlabLoation', data).subscribe((response) => {
      console.log("my change response++++", response.res)
      this.locationList = response.res;
      if (this.locationList.length > 0) {
        this.locationList.forEach((e: any, i: number) => {
          e.val = e._id
        })
      }
      if (!this.isLocationValueUpdate) {
        this.practiceformdata.fields = this.practiceformdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationList : []);
        this.isLocationValueUpdate = true;
      }
    })
  }

}

@Component({
  selector: "externaldatamodal",
  templateUrl: "../externaldatamodal.html",
})
export class ExternalDataLocationModalComponent {
  public resolveLoader: boolean = false;
  companyName: any = "";
  companyemail: any = "";
  public modaldata: any = {};
  public stateList: any = [];
  public addLocationForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ExternalDataLocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public httpService: HttpServiceService, public apiservice: HttpServiceService
  ) {
    this.modaldata = data
    console.log(data, "modal data++");
  }
  ngOnInit() {
    this.getStates();
    this.addLocationForm = new FormGroup({
      location_name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)),
    })

    if (this.modaldata.locationObj) {
      this.addLocationForm.patchValue({
        location_name: this.modaldata.locationObj.location_name,
        address: this.modaldata.locationObj.address,
        city: this.modaldata.locationObj.city,
        state: this.modaldata.locationObj.state,
        zip: this.modaldata.locationObj.zip,
        phone: this.modaldata.locationObj.phone
      })
    }
  }

  getStates() {
    this.httpService.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      // console.log(response, "this.stateList")
      this.stateList = response;
    })
  }
  phoneFormatting(event: any) {
    // console.log("event", this.addLocationForm.controls);
    var formatted = this.formatPhoneText(event.target.value);
    // console.log("event formatted", formatted);
    this.addLocationForm.patchValue({
      phone: formatted
    })
  }
  formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  }

  onNoClick(): void {
    this.data.flag = "no";
    this.dialogRef.close();
  }

  addLocation() {
    this.addLocationForm.markAllAsTouched();
    if (this.addLocationForm.valid) {
      this.resolveLoader = true;
      this.data.flag = "yes";
      let tempArr: any = [];
      for (let i in this.addLocationForm.value) {
        let tempObj = { key: i, label: this.humanize(i), val: this.addLocationForm.value[i] };
        tempArr.push(tempObj)
      }
      this.data.externaldatavalue = {
        name: this.data.name,
        value: tempArr
      };
      if (this.modaldata.locationObj && this.modaldata.locationObj._id && this.modaldata.locationObj.user_id) {
        let data: any = this.addLocationForm.value;
        data._id = this.modaldata.locationObj._id;
        data.user_id = this.modaldata.locationObj.user_id;
        console.log("submit data", data)
        this.apiservice.httpViaPost('users/locationaddedit', data).subscribe((response) => {
          console.log("dataobj", response)
          if (response) {
            this.resolveLoader = false;
            this.dialogRef.close({ data: this.data, formObj: data });
          }
        })
      } else if (this.modaldata.user_id) {
        let data: any = this.addLocationForm.value;
        data.user_id = this.modaldata.user_id;
        console.log("submit data", data)
        this.apiservice.httpViaPost('users/locationaddedit', data).subscribe((response) => {
          console.log("dataobj", response)
          if (response) {
            this.resolveLoader = false;
            this.dialogRef.close({ data: this.data, formObj: response.results.res, existLab: true });
          }
        })
      } else {
        this.resolveLoader = false;
        this.dialogRef.close({ data: this.data, formObj: this.addLocationForm.value });
      }
    }
  }



  humanize(str: string) {
    var i: any, frags: any = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
}
