import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-training-center-lesson-list',
  templateUrl: './training-center-lesson-list.component.html',
  styleUrls: ['./training-center-lesson-list.component.css']
})
export class TrainingCenterLessonListComponent {
  public lessonList: any = [];
  public cookieData: any = null;
  @Input() set lessonListFetched(value: any) {
    if (value) {
      this.lessonList = value;
      if (this.lessonList.length > 0 && this.activateRoute.snapshot.params["lesson_id"]) {
        this.lessonList.forEach((e: any, i: number) => {
          // e.active = false;
          if (e._id == this.activateRoute.snapshot.params["lesson_id"]) {
            // e.active = true
            this.selectLesson(e)
          }
        })
      }
      console.log("TrainingCenterLessonListComponent centre this.lessonList", this.lessonList)
    }
  }

  constructor(public apiservice: HttpServiceService, public activateRoute: ActivatedRoute, public router: Router, public cookieService: CookieService) { }
  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }

  fetchAllLessons() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/lessonlist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            this.lessonList = response.results.res;
          };
        }
      }
    })
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log("SimpleChanges lessonlist", changes)
  //   if (changes && changes['lessonListFetched']) {
  //     if (changes['lessonListFetched'].previousValue == undefined) {

  //     }
  //   }
  // }
  selectLesson(lesson: any) {
    console.log("selectLesson", lesson)
    this.lessonList.forEach((e: any) => {
      e.active = false;
      if (e._id == lesson._id) {
        e.active = true;
      }
    })
    this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-center/${lesson.category_id}/${lesson._id}`);
  }
  markAsDone(lesson: any) {
    console.log("markAsDone", lesson)
    if (lesson) {
      let data: any = {
        user_id: this.cookieData._id,
        training_id: lesson.category_id,
        lesson_id: lesson._id,
      }
      this.apiservice.httpViaPost('training/markdonelesson', data).subscribe((response: any) => {
        console.log(response)
      })
    }
  }
}
