import { Component, Inject } from '@angular/core';
import { flush } from '@angular/core/testing';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-lead-intakes',
  templateUrl: './lead-intakes.component.html',
  styleUrls: ['./lead-intakes.component.css']
})
export class LeadIntakesComponent {
  public intakeForm: any = null;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public laboratory_details: any;
  public sealsperson_details: any = null;
  public locationList: any;
  public logoUrl: any = {};
  public progressBar: any = false;
  public editFormData: any = null;
  public addFormLoader: boolean = false;
  public stateList: any = [];
  public paramslink: any;
  public subBtn: any = false;
  public aspinnerLoader: any = true;
  objectKeys = Object.keys;
  public patient_intake_flag: boolean = false;
  public quick_intake_flag: boolean = false;
  public email_intake_flag: boolean = false;
  public other_email_intake_flag: boolean = false;
  public through_patient_intake: boolean = false;
  public intake_sign: any;
  public intakesigninput: any;
  public intakesignForm: FormGroup;
  // public signlibCon: any = false;
  public errorclass: boolean = false;
  public cookiedata: any = '';
  public resetformcss: boolean = false;
  public datafetchIMG: any = null;
  public intake_submitted_by: any = '';
  public emailparams: any = '';
  public emailIntakeData: any = '';
  public preview_intakesignblock: boolean = false;
  public intakeAuthorize: boolean = false;
  public practiceDetail: any = '';
  public resubmitBtnFlag: boolean = false;
  public emailintakeAlreadyDoneFlag: boolean = false;
  public demoDoctorDashboard: boolean = false;
  public demoDoctorDashboardPreviewIntake: boolean = false;
  public primaryinsurancename: any = null;
  public secondaryinsurancename: any;
  public selectedDoctorId: any;

  public patientID: any = {};
  public autoAPICallFlag: boolean = true;
  public symptomNotesObj: any = {};

  public allSymptomUnchecked: boolean = false;
  public sixMonthUnchecked: boolean = false;
  public lastMonthUnchecked: boolean = false;
  public todayUnchecked: boolean = false;
  public activeSymptomList: any = {};
  public findEditedSymptoms: any = [];

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public matSnackBar: MatSnackBar, public router: Router, public cookieService: CookieService) {

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }




    console.log('activatedRoute', this.activatedRoute)


    this.paramslink = this.activatedRoute.snapshot.params
    console.log('activatedRoutelink', this.activatedRoute)


    // if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
    //  this.subBtn = true;
    // }

    if (this.activatedRoute.snapshot.routeConfig.path.includes('preview-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      this.activatedRoute.data.subscribe((response: any) => {
        if (response) {
          console.log("activatedRoute responce", response)
          if (response.data.results.res[0].physician_examination == true) {
            this.subBtn = true;
          }
        }
      })
      if (this.cookiedata.user_type == 'doctor') {
        this.subBtn = false;
        this.resubmitBtnFlag = true;
      }
    }
    if (this.cookiedata.user_type == 'sales_person' && window.location.pathname.includes("sales_person/preview-intake")) {
      this.subBtn = false;
      this.demoDoctorDashboardPreviewIntake = true;
    }

    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-intake')) {
      if (this.cookieService.getAll()['login_user_details']) {
        this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
        if (this.cookiedata.user_type !== 'admin' && this.cookiedata.user_type !== 'doctor' && this.cookiedata.user_type !== 'practice' && !window.location.pathname.includes('sales_person/doctor')) {
          this.router.navigateByUrl('404')
        }
      }
      if (window.location.pathname.includes('sales_person/doctor')) {
        this.demoDoctorDashboard = true;
      }
      this.patient_intake_flag = true;
      setTimeout(() => {
        if (this.cookiedata.user_type == 'practice') {
          this.doctorChosseModal();
        }
      }, 2000);

    }



    console.log('activatedRoute+++', this.activatedRoute)
    if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('email-intake-form/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('intake-authorization/:_id')) {

      this.preview_intakesignblock = true;

      this.activatedRoute.data.subscribe((response: any) => {
        if (response.data.results.res.length > 0) {
          if (response.data.results.res[0].resend_emai && response.data.results.res[0].resend_emai == 1) {
            this.emailintakeAlreadyDoneFlag = true;
          }

          this.emailIntakeData = response.data.results.res[0]

        }
        console.log('emailIntakeData', this.emailIntakeData)
      });


      if (this.activatedRoute.snapshot.routeConfig.path.includes('intake-authorization/:_id')) {
        this.email_intake_flag = false;
        this.patient_intake_flag = true;
        this.intakeAuthorize = true
        if (typeof this.emailIntakeData.patient_signature != 'undefined') {
          this.emailintakeAlreadyDoneFlag = true;
        }
      } else {
        this.email_intake_flag = true;
      }


      // if (this.cookiedata.user_type == 'doctor') {

      //   this.practiceLogo();

      // }


    }


    if (this.activatedRoute.snapshot.routeConfig.path.includes('quick-intake/:_id') || window.location.pathname.includes('demo/quick-intake')) {

      this.preview_intakesignblock = true;
      this.patientID = this.activatedRoute.snapshot.params;
      this.quick_intake_flag = true;
      this.through_patient_intake = true;
      // if (this.cookiedata.user_type == 'doctor') {

      //   this.practiceLogo();

      // }
      console.log("quick-intake here")


    }

    if (window.location.pathname.includes('demo/quick-intake')) {
      this.demoDoctorDashboard = true;
    }

    this.getStates();

    if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake-form/:_id')) {
      this.other_email_intake_flag = true;
    }
    if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id')) {
      this.through_patient_intake = true;
    }




  }

  practiceLogo() {
    let datafetch: any = {
      'condition': {
        'limit': 10, 'skip': 0
      },
      sort: {
        'type': 'desc', 'field': '_id'
      },
      searchcondition: { _id: this.cookiedata.practice_id }
    }
    this.apiservice.httpViaPost('users/fetchUserDetails', datafetch).subscribe((response: any) => {
      console.log('response', response)
      if (response.status == "success") {
        if (response.results.res[0].image) {
          this.datafetchIMG = response.results.res[0].image;
        }
      }
    })
  }


  ngOnInit() {





    console.log('apinnerLoader', this.patient_intake_flag)



    if (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params["_id"]) {


      this.activatedRoute.data.subscribe((response: any) => {
        console.log("editdata", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            // this.aspinnerLoader =true;
            if (response.data.results.res.length == 0) {
              if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('email-intake-form/:_id')) {
                let datasetval = { "searchcondition": { "_id": this.paramslink._id } }
                this.apiservice.httpViaPost('intake/getleaddata', datasetval).subscribe((response: any) => {
                  console.log("datasetval getleaddata", response)
                  if (response) {
                    this.editFormData = response.results.res[0];

                    if (this.quick_intake_flag || this.through_patient_intake) {
                      if (Object.keys(this.editFormData).length > 0) {
                        for (let key in this.editFormData) {
                          let splitKey = key.split("_");
                          if (splitKey[splitKey.length - 1] == 'notes' && Object.keys(this.editFormData[key]).length > 0) {
                            let symtomName = splitKey.slice(0, splitKey.length - 1).join("_");
                            let symtomNameSixMonths = symtomName + "_six_months";
                            let symtomNameLastMonths = symtomName + "_last_months";
                            let symtomNameToday = symtomName + "_today";
                            this.editFormData[symtomNameSixMonths] = true;
                            this.editFormData[symtomNameLastMonths] = true;
                            this.editFormData[symtomNameToday] = true;
                          }
                        }
                      }
                      this.intakeFormCreate();
                    } else {
                      this.withoutPatient()
                    }

                    if (typeof this.intake_sign != 'undefined') {
                      this.preview_intakesignblock = true;
                      // this.patient_intake_flag = 
                    }
                    // fetchUserDetails data call for practice detail
                    let practicedata: any = {
                      searchcondition: {
                        _id: this.editFormData.practice_id
                      }
                    }

                    this.apiservice.httpViaPost('users/fetchUserDetails', practicedata).subscribe((response: any) => {
                      if (response && response.status == "success") {
                        console.log('response++++++', response.results.res[0])
                        this.practiceDetail = response.results.res[0]
                      }
                    })
                    // fetchUserDetails data call for practice detail end
                    this.intake_sign = this.editFormData.patient_signature ? this.editFormData.patient_signature : null
                  }
                })
              }
            } else {
              this.editFormData = response.data.results.res[0];
              if (this.quick_intake_flag || this.through_patient_intake) {
                // console.log("this.editFormData", this.editFormData)
                if (Object.keys(this.editFormData).length > 0) {
                  for (let key in this.editFormData) {
                    let splitKey = key.split("_");
                    if (splitKey[splitKey.length - 1] == 'notes' && Object.keys(this.editFormData[key]).length > 0) {
                      let symtomName = splitKey.slice(0, splitKey.length - 1).join("_");
                      let symtomNameSixMonths = symtomName + "_six_months";
                      let symtomNameLastMonths = symtomName + "_last_months";
                      let symtomNameToday = symtomName + "_today";
                      this.editFormData[symtomNameSixMonths] = true;
                      this.editFormData[symtomNameLastMonths] = true;
                      this.editFormData[symtomNameToday] = true;
                    }
                  }
                }
                this.intakeFormCreate();
              } else {
                this.withoutPatient()
              }

              if (typeof this.intake_sign != 'undefined') {
                this.preview_intakesignblock = true;
                // this.patient_intake_flag = 
              }
              // fetchUserDetails data call for practice detail
              let practicedata: any = {
                searchcondition: {
                  _id: this.editFormData.practice_id
                }
              }

              this.apiservice.httpViaPost('users/fetchUserDetails', practicedata).subscribe((response: any) => {
                if (response && response.status == "success") {
                  console.log('response++++++', response.results.res[0])
                  this.practiceDetail = response.results.res[0]
                }
              })
              // fetchUserDetails data call for practice detail end
              this.intake_sign = this.editFormData.patient_signature ? this.editFormData.patient_signature : null
            }

            // if (this.editFormData.primary_isurance_name) {
            //   let dataSet: any = {
            //     "condition": {
            //       "limit": 10,
            //       "skip": 0
            //     },
            //     "sort": {
            //       "field": "_id",
            //       "type": "desc"
            //     },
            //     "searchcondition": {
            //       "$and": [{ "$or": [{ "_id": this.editFormData.primary_isurance_name }] }]
            //     }
            //   }
            //   this.apiservice.httpViaPost('intake/insurancelist', dataSet).subscribe((response: any) => {
            //     console.log("editFormData", response)

            //     if(response.status == "success") {
            //       this.primaryinsurance_data['key'] =response.results.res[0]._id
            //       this.primaryinsurance_data['val'] =response.results.res[0].insurance_name
            //       console.log("editFormData", this.primaryinsurance_data)
            //     }
            //   })
            // }

          }
        }
      });
    } else {
      if (this.quick_intake_flag || this.through_patient_intake) {
        if (Object.keys(this.editFormData).length > 0) {
          for (let key in this.editFormData) {
            let splitKey = key.split("_");
            if (splitKey[splitKey.length - 1] == 'notes' && Object.keys(this.editFormData[key]).length > 0) {
              let symtomName = splitKey.slice(0, splitKey.length - 1).join("_");
              let symtomNameSixMonths = symtomName + "_six_months";
              let symtomNameLastMonths = symtomName + "_last_months";
              let symtomNameToday = symtomName + "_today";
              this.editFormData[symtomNameSixMonths] = true;
              this.editFormData[symtomNameLastMonths] = true;
              this.editFormData[symtomNameToday] = true;
            }
          }
        }
        this.intakeFormCreate();
      } else {
        this.withoutPatient()
      }
    }

    this.intakesignForm = new FormGroup({
      intake_sign: new FormControl('', Validators.required),
    });


    this.intakesignForm = this.formBuilder.group({
      intake_sign: [{ value: '', disabled: true }, [Validators.required]],
    });


    console.log('intakesignForm', this.intakesignForm)

  }





  intakeFormCreate() {



    this.intakeForm =
    {
      successmessage: 'Added Successfully !!',
      submittext: this.subBtn ? '' : 'Submit',
      // submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      groups: ["grp0", "grp1", "grp2", "grp3", "grp4", "grp5", "grp6", "grp7"],
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          heading: "PERSONAL INFORMATION",
          group: "grp0",
        },
        {

          label: 'Patient Name',
          name: 'patient_name',
          value: this.editFormData && this.editFormData.patient_name ? this.editFormData.patient_name : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Patient Name is Required' },

          ],
        },

        {
          label: 'Patient Email',
          name: 'patient_email',
          type: 'text',
          group: "grp1",
          hint: '',
          value: this.editFormData && this.editFormData.patient_email ? this.editFormData.patient_email : '',
          validations: [
            { rule: 'required', message: 'Patient Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]
        },
        {

          label: 'Phone No',
          name: 'patient_phone',
          type: 'numberformat',
          formatflag: true,
          group: "grp1",
          hint: '',
          // val: '',
          value: this.editFormData && this.editFormData.patient_phone ? this.editFormData.patient_phone : '',
          validations: [
            { rule: 'required', message: 'Phone No is required' },
          ]

        },
        {

          label: 'Gender',
          name: 'patient_gender',
          type: 'select',
          group: "grp1",
          hint: '',
          val: [{ val: 'male', name: 'Male' }, { val: 'female', name: 'Female' }],
          multiple: false,
          value: this.editFormData && this.editFormData.patient_gender ? this.editFormData.patient_gender : '',
          validations: [
            { rule: 'required', message: 'Gender is required' },
          ]
        },
        {
          label: 'Address',
          name: 'patient_address',
          value: this.editFormData && this.editFormData.patient_address ? this.editFormData.patient_address : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Address is Required' },

          ],
        },
        {
          label: 'State',
          name: 'state',
          value: this.editFormData && this.editFormData.state ? this.editFormData.state : '',
          group: "grp1",
          val: this.stateList,
          type: 'select',
          validations: [
            { rule: 'required', message: 'State is Required' },

          ],
        },

        {
          label: 'City',
          name: 'city',
          value: this.editFormData && this.editFormData.city ? this.editFormData.city : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'City is Required' },

          ],
        },
        {
          label: 'Zip',
          name: 'zip',
          value: this.editFormData && this.editFormData.zip ? this.editFormData.zip : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Zip is Required' },

          ],
        },

        {
          label: "Date of Birth (MM/DD/YYYY)",
          name: "patient_dob",
          type: "date",
          group: "grp1",
          value: this.editFormData && this.editFormData.patient_dob ? this.editFormData.patient_dob : '',
          // hint: "MM/DD/YYYY",
          // minDate: new Date(),
          maxDate: new Date(),
          validations: [
            { rule: 'required', message: "Date of Birth is required" }
          ]
        },

        {
          label: 'Height (Example: 5\'4\")',
          name: 'height',
          value: this.editFormData && this.editFormData.height ? this.editFormData.height : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Height is Required' },

          ],
        },

        {
          label: 'Weight (Example: 150 Lbs)',
          name: 'weight',
          value: this.editFormData && this.editFormData.weight ? this.editFormData.weight : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Weight is Required' },

          ],
        },

        {
          heading: "INSURANCE DETAILS",
          group: "grp2",
        },

        {
          label: "Primary Insurance Name",
          name: "primary_isurance_id",
          group: "grp3",
          type: 'autocomplete',
          endpoint: "intake/insuranceautocomplete",
          search_field: "name_search",
          val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.primary_isurance_id, val: this.editFormData.primary_isurance_name }] : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.primary_isurance_id : [],
          validations: [
            { rule: 'required', message: 'Primary Insurance  is Required' }
          ]

        },

        // {
        //   label: 'Primary Insurance Name',
        //   name: 'primary_isurance_name',
        //   value: this.editFormData && this.editFormData.primary_isurance_name ? this.editFormData.primary_isurance_name : '',
        //   group: "grp3",
        //   type: 'text',
        //   validations: [
        //     { rule: 'required', message: 'Primary Insurance Name is Required' },

        //   ],
        // },

        {
          label: 'Primary Insurance Number',
          name: 'primary_isurance_no',
          value: this.editFormData && this.editFormData.primary_isurance_no ? this.editFormData.primary_isurance_no : null,
          group: "grp3",
          type: 'number',
          validations: [
            { rule: 'required', message: 'Primary Insurance Number is Required' },

          ],
        },

        {
          label: "Secondary Insurance Name",
          name: "secondary_isurance_id",
          group: "grp3",
          type: 'autocomplete',
          endpoint: "intake/insuranceautocomplete",
          search_field: "name_search",
          val: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.secondary_isurance_name ? [{ key: this.editFormData.secondary_isurance_id, val: this.editFormData.secondary_isurance_name }] : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.secondary_isurance_id : [],
          validations: [
            // { rule: 'required', message: 'Secondary Insurance  is Required' }
          ]

        },

        // {
        //   label: 'Secondary Insurance Name',
        //   name: 'secondary_isurance_name',
        //   value: this.editFormData && this.editFormData.secondary_isurance_name ? this.editFormData.secondary_isurance_name : '',
        //   group: "grp3",
        //   type: 'text',
        //   validations: [
        //     { rule: 'required', message: 'Secondary Insurance Name is Required' },

        //   ],
        // },

        {
          label: 'Secondary Insurance Number',
          name: 'secondary_isurance_no',
          value: this.editFormData && this.editFormData.secondary_isurance_no ? this.editFormData.secondary_isurance_no : null,
          group: "grp3",
          type: 'number',
          validations: [
            // { rule: 'required', message: 'Secondary Insurance Number is Required' },

          ],
        },

        {
          heading: "ADDITIONAL QUESTIONS",
          group: "grp4",
        },


        {
          label: "Have you ever been diagnosed with PVD (Peripheral Vascular Disease)?",
          name: "diagnosed",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diagnosed !== undefined && this.editFormData.diagnosed !== null ? this.editFormData.diagnosed == false ? false : true : '',
          // value: this.editFormData.diagnosed,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Are you currently diagnosed with Diabetes?",
          name: "diabetes",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diabetes !== undefined && this.editFormData.diabetes !== null ? this.editFormData.diabetes == false ? false : true : '',
          // value: this.editFormData.diabetes,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Have you been diagnosed and/or treated for Covid19 in the last year?",
          name: "covid19",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.covid19 !== undefined && this.editFormData.covid19 !== null ? this.editFormData.covid19 == false ? false : true : '',
          // value: this.editFormData.covid19,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },
        {
          heading: "SYMPTOMS CHECKLIST",
          group: "grp6",
        },

        // ========== Headaches ======== //
        {
          heading: "Headaches",
          group: "grp7",
          name: 'headaches'
        },

        // {
        //   label: "6 months",
        //   name: "headaches_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.headaches_six_months ? this.editFormData.headaches_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "headaches_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.headaches_last_month ? this.editFormData.headaches_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "headaches_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_today ? this.editFormData.headaches_today : false,
          group: "grp7",
        },


        // ========== Skin and eyes that appear yellowish (jaundice) ======== //
        {
          heading: "Skin and eyes that appear yellowish (jaundice)",
          group: "grp7",
          name: 'skinEyesYellowwish'
        },

        // {
        //   label: "6 months",
        //   name: "skin_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.skin_six_months ? this.editFormData.skin_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "skin_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.skin_last_month ? this.editFormData.skin_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_today ? this.editFormData.skin_today : false,
          group: "grp7",
        },

        // ========== Abdominal pain and swelling ======== //
        {
          heading: "Abdominal pain and swelling",
          group: "grp7",
          name: 'abdominalSwelling'
        },

        // {
        //   label: "6 months",
        //   name: "abdominal_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_six_months ? this.editFormData.abdominal_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "abdominal_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_last_month ? this.editFormData.abdominal_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "abdominal_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_today ? this.editFormData.abdominal_today : false,
          group: "grp7",
        },

        // ========== Swelling in the legs and ankles ======== //
        {
          heading: "Swelling in the legs and ankles",
          group: "grp7",
          name: 'swellingAnkles'
        },

        // {
        //   label: "6 months",
        //   name: "selling_legs_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.selling_legs_six_months ? this.editFormData.selling_legs_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "selling_legs_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.selling_legs_last_month ? this.editFormData.selling_legs_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "selling_legs_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_today ? this.editFormData.selling_legs_today : false,
          group: "grp7",
        },

        // ========== Dry, itchy skin ======== //
        {
          heading: "Dry, itchy skin",
          group: "grp7",
          name: 'dryItchySkin'
        },

        // {
        //   label: "6 months",
        //   name: "itchy_skin_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.itchy_skin_six_months ? this.editFormData.itchy_skin_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "itchy_skin_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.itchy_skin_last_month ? this.editFormData.itchy_skin_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "itchy_skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_today ? this.editFormData.itchy_skin_today : false,
          group: "grp7",
        },

        // ========== Dark urine color ======== //
        {
          heading: "Dark urine color",
          group: "grp7",
          name: 'darkUrineColor'
        },

        // {
        //   label: "6 months",
        //   name: "dark_urine_color_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dark_urine_color_six_months ? this.editFormData.dark_urine_color_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "dark_urine_color_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dark_urine_color_last_month ? this.editFormData.dark_urine_color_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "dark_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_today ? this.editFormData.dark_urine_color_today : false,
          group: "grp7",
        },

        // ========== Pale stool color ======== //
        {
          heading: "Pale stool color",
          group: "grp7",
          name: 'paleStoolColor'
        },

        // {
        //   label: "6 months",
        //   name: "pale_stool_color_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pale_stool_color_six_months ? this.editFormData.pale_stool_color_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pale_stool_color_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pale_stool_color_last_month ? this.editFormData.pale_stool_color_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pale_stool_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_today ? this.editFormData.pale_stool_color_today : false,
          group: "grp7",
        },

        // ========== Chronic fatigue ======== //
        {
          heading: "Chronic fatigue",
          group: "grp7",
          name: 'chronicFatigue'
        },

        // {
        //   label: "6 months",
        //   name: "chronic_fatigue_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chronic_fatigue_six_months ? this.editFormData.chronic_fatigue_six_months : false,
        //   group: "grp7",
        // },

        // {
        //   label: "Last month",
        //   name: "chronic_fatigue_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chronic_fatigue_last_month ? this.editFormData.chronic_fatigue_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chronic_fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_today ? this.editFormData.chronic_fatigue_today : false,
          group: "grp7",
        },

        // ========== Nausea or vomiting ======== //
        {
          heading: "Nausea or vomiting",
          group: "grp7",
          name: 'nauseaOrVomiting'
        },

        // {
        //   label: "6 months",
        //   name: "vomiting_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.vomiting_six_months ? this.editFormData.vomiting_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "vomiting_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.vomiting_last_month ? this.editFormData.vomiting_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_today ? this.editFormData.vomiting_today : false,
          group: "grp7",
        },

        // ========== Loss of appetite ======== //
        {
          heading: "Loss of appetite",
          group: "grp7",
          name: 'lossAppetite'
        },

        // {
        //   label: "6 months",
        //   name: "lossofappetite_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lossofappetite_six_months ? this.editFormData.lossofappetite_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "lossofappetite_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lossofappetite_last_month ? this.editFormData.lossofappetite_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "lossofappetite_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_today ? this.editFormData.lossofappetite_today : false,
          group: "grp7",
        },

        // ========== Tendency to bruise easily ======== //
        {
          heading: "Tendency to bruise easily",
          group: "grp7",
          name: 'tendencyEasily'
        },

        // {
        //   label: "6 months",
        //   name: "bruise_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bruise_six_months ? this.editFormData.bruise_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "bruise_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bruise_last_month ? this.editFormData.bruise_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "bruise_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_today ? this.editFormData.bruise_today : false,
          group: "grp7",
        },

        // ========== Fever ======== //
        {
          heading: "Fever",
          group: "grp7",
          name: 'fever'
        },

        // {
        //   label: "6 months",
        //   name: "fever_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fever_six_months ? this.editFormData.fever_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "fever_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fever_last_month ? this.editFormData.fever_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "fever_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_today ? this.editFormData.fever_today : false,
          group: "grp7",
        },

        // ========== Insomnia ======== //
        {
          heading: "Insomnia",
          group: "grp7",
          name: 'insomnia'
        },

        // {
        //   label: "6 months",
        //   name: "insomnia_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.insomnia_six_months ? this.editFormData.insomnia_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "insomnia_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.insomnia_last_month ? this.editFormData.insomnia_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "insomnia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_today ? this.editFormData.insomnia_today : false,
          group: "grp7",
        },

        // ========== Urinating less ======== //
        {
          heading: "Urinating less",
          group: "grp7",
          name: 'urinatingLess'
        },

        // {
        //   label: "6 months",
        //   name: "urinating_less_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urinating_less_six_months ? this.editFormData.urinating_less_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "urinating_less_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urinating_less_last_month ? this.editFormData.urinating_less_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "urinating_less_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_today ? this.editFormData.urinating_less_today : false,
          group: "grp7",
        },

        // ========== Muscle cramps ======== //
        {
          heading: "Muscle cramps",
          group: "grp7",
          name: 'muscleCramps'
        },

        // {
        //   label: "6 months",
        //   name: "muscle_cramps_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.muscle_cramps_six_months ? this.editFormData.muscle_cramps_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "muscle_cramps_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.muscle_cramps_last_month ? this.editFormData.muscle_cramps_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "muscle_cramps_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_today ? this.editFormData.muscle_cramps_today : false,
          group: "grp7",
        },


        // ========== Swelling of feet and ankles ======== //
        {
          heading: "Swelling of feet and ankles",
          group: "grp7",
          name: 'swellingFeetAnkles'
        },

        // {
        //   label: "6 months",
        //   name: "swelling_feet_ankles_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_feet_ankles_six_months ? this.editFormData.swelling_feet_ankles_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swelling_feet_ankles_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_feet_ankles_last_month ? this.editFormData.swelling_feet_ankles_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swelling_feet_ankles_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_today ? this.editFormData.swelling_feet_ankles_today : false,
          group: "grp7",
        },


        // ========== Shortness of breath ======== //
        {
          heading: "Shortness of breath",
          group: "grp7",
          name: 'shortnessBreath'
        },

        // {
        //   label: "6 months",
        //   name: "shortness_of_breath_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.shortness_of_breath_six_months ? this.editFormData.shortness_of_breath_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "shortness_of_breath_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.shortness_of_breath_last_month ? this.editFormData.shortness_of_breath_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "shortness_of_breath_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_today ? this.editFormData.shortness_of_breath_today : false,
          group: "grp7",
        },
        // ========== Chest pain ======== //
        {
          heading: "Chest pain",
          group: "grp7",
          name: 'chestPain'
        },

        // {
        //   label: "6 months",
        //   name: "chest_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_six_months ? this.editFormData.chest_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chest_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_last_month ? this.editFormData.chest_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chest_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_today ? this.editFormData.chest_pain_today : false,
          group: "grp7",
        },// ========== Back Pain ======== //
        {
          heading: "Back Pain",
          group: "grp7",
          name: 'backPain'
        },

        // {
        //   label: "6 months",
        //   name: "back_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.back_pain_six_months ? this.editFormData.back_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "back_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.back_pain_last_month ? this.editFormData.back_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "back_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_today ? this.editFormData.back_pain_today : false,
          group: "grp7",
        },// ========== Frequent urination ======== //
        {
          heading: "Frequent urination",
          group: "grp7",
          name: 'frequentUrination'
        },

        // {
        //   label: "6 months",
        //   name: "frequent_urination_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.frequent_urination_six_months ? this.editFormData.frequent_urination_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "frequent_urination_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.frequent_urination_last_month ? this.editFormData.frequent_urination_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "frequent_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_today ? this.editFormData.frequent_urination_today : false,
          group: "grp7",
        },// ========== Foamy urine ======== //
        {
          heading: "Foamy urine",
          group: "grp7",
          name: 'foamyUrine'
        },

        // {
        //   label: "6 months",
        //   name: "foamy_urine_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.foamy_urine_six_months ? this.editFormData.foamy_urine_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "foamy_urine_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.foamy_urine_last_month ? this.editFormData.foamy_urine_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "foamy_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_today ? this.editFormData.foamy_urine_today : false,
          group: "grp7",
        },// ========== Blood in your urine ======== //
        {
          heading: "Blood in your urine",
          group: "grp7",
          name: 'bloodUrine'
        },

        // {
        //   label: "6 months",
        //   name: "blood_in_your_urine_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_in_your_urine_six_months ? this.editFormData.blood_in_your_urine_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "blood_in_your_urine_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_in_your_urine_last_month ? this.editFormData.blood_in_your_urine_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "blood_in_your_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_today ? this.editFormData.blood_in_your_urine_today : false,
          group: "grp7",
        },
        // ========== Dizziness ======== //
        {
          heading: "Dizziness",
          group: "grp7",
          name: 'dizziness'
        },

        // {
        //   label: "6 months",
        //   name: "dizziness_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dizziness_six_months ? this.editFormData.dizziness_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "dizziness_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dizziness_last_month ? this.editFormData.dizziness_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "dizziness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_today ? this.editFormData.dizziness_today : false,
          group: "grp7",
        },// ========== Swelling around your eyes ======== //
        {
          heading: "Swelling around your eyes",
          group: "grp7",
          name: 'swellingAroundEyes'
        },

        // {
        //   label: "6 months",
        //   name: "swelling_around_your_eyes_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_around_your_eyes_six_months ? this.editFormData.swelling_around_your_eyes_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swelling_around_your_eyes_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_around_your_eyes_last_month ? this.editFormData.swelling_around_your_eyes_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swelling_around_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_today ? this.editFormData.swelling_around_your_eyes_today : false,
          group: "grp7",
        },// ========== High blood sugar ======== //
        {
          heading: "High blood sugar",
          group: "grp7",
          name: 'highBloodSugar'
        },

        // {
        //   label: "6 months",
        //   name: "high_blood_sugar_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.high_blood_sugar_six_months ? this.editFormData.high_blood_sugar_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "high_blood_sugar_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.high_blood_sugar_last_month ? this.editFormData.high_blood_sugar_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "high_blood_sugar_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_today ? this.editFormData.high_blood_sugar_today : false,
          group: "grp7",
        },
        // ========== Weight loss ======== //
        {
          heading: "Weight loss",
          group: "grp7",
          name: 'weightLoss'
        },

        // {
        //   label: "6 months",
        //   name: "weight_loss_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weight_loss_six_months ? this.editFormData.weight_loss_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "weight_loss_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weight_loss_last_month ? this.editFormData.weight_loss_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "weight_loss_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_today ? this.editFormData.weight_loss_today : false,
          group: "grp7",
        },
        // ========== Joint pain ======== //
        {
          heading: "Joint pain",
          group: "grp7",
          name: 'jointPain'
        },

        // {
        //   label: "6 months",
        //   name: "joint_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.joint_pain_six_months ? this.editFormData.joint_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "joint_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.joint_pain_last_month ? this.editFormData.joint_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "joint_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_today ? this.editFormData.joint_pain_today : false,
          group: "grp7",
        },// ========== Joint Inflammation ======== //
        {
          heading: "Joint Inflammation",
          group: "grp7",
          name: 'jointInflammation'
        },

        // {
        //   label: "6 months",
        //   name: "joint_inflammation_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.joint_inflammation_six_months ? this.editFormData.joint_inflammation_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "joint_inflammation_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.joint_inflammation_last_month ? this.editFormData.joint_inflammation_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "joint_inflammation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_today ? this.editFormData.joint_inflammation_today : false,
          group: "grp7",
        },
        // ========== Limited range of motion ======== //
        {
          heading: "Limited range of motion",
          group: "grp7",
          name: 'limitedRangeMotion'
        },

        // {
        //   label: "6 months",
        //   name: "limited_range_motion_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.limited_range_motion_six_months ? this.editFormData.limited_range_motion_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "limited_range_motion_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.limited_range_motion_last_month ? this.editFormData.limited_range_motion_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "limited_range_motion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_today ? this.editFormData.limited_range_motion_today : false,
          group: "grp7",
        },
        // ========== Coughing, especially at night ======== //
        {
          heading: "Coughing, especially at night",
          group: "grp7",
          name: 'coughingEspeciallyNight'
        },

        // {
        //   label: "6 months",
        //   name: "coughing_especially_at_night_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_especially_at_night_six_months ? this.editFormData.coughing_especially_at_night_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "coughing_especially_at_night_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_especially_at_night_last_month ? this.editFormData.coughing_especially_at_night_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "coughing_especially_at_night_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_today ? this.editFormData.coughing_especially_at_night_today : false,
          group: "grp7",
        },
        // ========== Wheezing or squeaking when you breathe ======== //
        {
          heading: "Wheezing or squeaking when you breathe",
          group: "grp7",
          name: 'wheezingSqueakingBreathe'
        },

        // {
        //   label: "6 months",
        //   name: "wheezing_squeaking_when_you_breathe_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_six_months ? this.editFormData.wheezing_squeaking_when_you_breathe_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "wheezing_squeaking_when_you_breathe_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_last_month ? this.editFormData.wheezing_squeaking_when_you_breathe_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "wheezing_squeaking_when_you_breathe_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_today ? this.editFormData.wheezing_squeaking_when_you_breathe_today : false,
          group: "grp7",
        },
        // ========== Chest tightness or pain ======== //
        {
          heading: "Chest tightness or pain",
          group: "grp7",
          name: 'chestTightnessPain'
        },

        // {
        //   label: "6 months",
        //   name: "chest_tightness_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_tightness_pain_six_months ? this.editFormData.chest_tightness_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chest_tightness_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_tightness_pain_last_month ? this.editFormData.chest_tightness_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chest_tightness_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_today ? this.editFormData.chest_tightness_pain_today : false,
          group: "grp7",
        },
        // ========== Low energy ======== //
        {
          heading: "Low energy",
          group: "grp7",
          name: 'lowEnergy'
        },

        // {
        //   label: "6 months",
        //   name: "low_energy_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.low_energy_six_months ? this.editFormData.low_energy_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "low_energy_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.low_energy_last_month ? this.editFormData.low_energy_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "low_energy_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_today ? this.editFormData.low_energy_today : false,
          group: "grp7",
        },
        // ========== Blue fingernails ======== //
        {
          heading: "Blue fingernails",
          group: "grp7",
          name: 'blueFingernails'
        },

        // {
        //   label: "6 months",
        //   name: "blue_fingernails_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blue_fingernails_six_months ? this.editFormData.blue_fingernails_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "blue_fingernails_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blue_fingernails_last_month ? this.editFormData.blue_fingernails_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "blue_fingernails_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_today ? this.editFormData.blue_fingernails_today : false,
          group: "grp7",
        },
        // ========== Frequent colds or flu ======== //
        {
          heading: "Frequent colds or flu",
          group: "grp7",
          name: 'frequentColdsFlu'
        },

        // {
        //   label: "6 months",
        //   name: "frequent_colds_flu_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.frequent_colds_flu_six_months ? this.editFormData.frequent_colds_flu_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "frequent_colds_flu_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.frequent_colds_flu_last_month ? this.editFormData.frequent_colds_flu_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "frequent_colds_flu_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_today ? this.editFormData.frequent_colds_flu_today : false,
          group: "grp7",
        },
        // ========== Coughing up lots of mucus ======== //
        {
          heading: "Coughing up lots of mucus",
          group: "grp7",
          name: 'coughingLotsMucus'
        },

        // {
        //   label: "6 months",
        //   name: "coughing_up_lots_mucus_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_up_lots_mucus_six_months ? this.editFormData.coughing_up_lots_mucus_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "coughing_up_lots_mucus_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_up_lots_mucus_last_month ? this.editFormData.coughing_up_lots_mucus_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "coughing_up_lots_mucus_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_today ? this.editFormData.coughing_up_lots_mucus_today : false,
          group: "grp7",
        },
        // ========== A cough that doesn't go away ======== //
        {
          heading: "A cough that doesn't go away",
          group: "grp7",
          name: 'coughDoesnotAway'
        },

        // {
        //   label: "6 months",
        //   name: "cough_that_doesnt_go_away_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_six_months ? this.editFormData.cough_that_doesnt_go_away_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "cough_that_doesnt_go_away_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_last_month ? this.editFormData.cough_that_doesnt_go_away_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "cough_that_doesnt_go_away_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_today ? this.editFormData.cough_that_doesnt_go_away_today : false,
          group: "grp7",
        },
        // ========== Depression or anxiety ======== //
        {
          heading: "Depression or anxiety",
          group: "grp7",
          name: 'depressionAnxiety'
        },

        // {
        //   label: "6 months",
        //   name: "depression_anxiety_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.depression_anxiety_six_months ? this.editFormData.depression_anxiety_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "depression_anxiety_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.depression_anxiety_last_month ? this.editFormData.depression_anxiety_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "depression_anxiety_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_today ? this.editFormData.depression_anxiety_today : false,
          group: "grp7",
        },
        // ========== Restlessness while sleeping ======== //
        {
          heading: "Restlessness while sleeping",
          group: "grp7",
          name: 'restlessnessSleeping'
        },

        // {
        //   label: "6 months",
        //   name: "restlessness_while_sleeping_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.restlessness_while_sleeping_six_months ? this.editFormData.restlessness_while_sleeping_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "restlessness_while_sleeping_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.restlessness_while_sleeping_last_month ? this.editFormData.restlessness_while_sleeping_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "restlessness_while_sleeping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_today ? this.editFormData.restlessness_while_sleeping_today : false,
          group: "grp7",
        },
        // ========== Fatigue ======== //
        {
          heading: "Fatigue",
          group: "grp7",
          name: 'fatigue'
        },

        // {
        //   label: "6 months",
        //   name: "fatigue_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fatigue_six_months ? this.editFormData.fatigue_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "fatigue_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fatigue_last_month ? this.editFormData.fatigue_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_today ? this.editFormData.fatigue_today : false,
          group: "grp7",
        },
        // ========== Nausea and vomiting ======== //
        {
          heading: "Nausea and vomiting",
          group: "grp7",
          name: 'nauseaAndVomoting'
        },

        // {
        //   label: "6 months",
        //   name: "nausea_vomiting_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_vomiting_six_months ? this.editFormData.nausea_vomiting_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "nausea_vomiting_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_vomiting_last_month ? this.editFormData.nausea_vomiting_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "nausea_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_today ? this.editFormData.nausea_vomiting_today : false,
          group: "grp7",
        },
        // ========== Arrhythmia ======== //
        {
          heading: "Arrhythmia: Irregular heartbeat rhythm",
          group: "grp7",
          name: 'arrhythmiaHeartbeatRhythm'
        },

        // {
        //   label: "6 months",
        //   name: "arrhythmia_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.arrhythmia_six_months ? this.editFormData.arrhythmia_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "arrhythmia_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.arrhythmia_last_month ? this.editFormData.arrhythmia_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "arrhythmia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_today ? this.editFormData.arrhythmia_today : false,
          group: "grp7",
        },
        // ========== Confusion ======== //
        {
          heading: "Confusion",
          group: "grp7",
          name: 'confusion'
        },

        // {
        //   label: "6 months",
        //   name: "confusion_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.confusion_six_months ? this.editFormData.confusion_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "confusion_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.confusion_last_month ? this.editFormData.confusion_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "confusion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_today ? this.editFormData.confusion_today : false,
          group: "grp7",
        },
        // ========== Blurred vision ======== //
        {
          heading: "Blurred vision",
          group: "grp7",
          name: 'blurredVision'
        },

        // {
        //   label: "6 months",
        //   name: "blurred_vision_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blurred_vision_six_months ? this.editFormData.blurred_vision_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "blurred_vision_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blurred_vision_last_month ? this.editFormData.blurred_vision_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "blurred_vision_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_today ? this.editFormData.blurred_vision_today : false,
          group: "grp7",
        },
        // ========== Extreme thirst ======== //
        {
          heading: "Extreme thirst",
          group: "grp7",
          name: 'extremeThirst'
        },

        // {
        //   label: "6 months",
        //   name: "extreme_thirst_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.extreme_thirst_six_months ? this.editFormData.extreme_thirst_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "extreme_thirst_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.extreme_thirst_last_month ? this.editFormData.extreme_thirst_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "extreme_thirst_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_today ? this.editFormData.extreme_thirst_today : false,
          group: "grp7",
        },
        // ========== Extreme Hunger ======== //
        {
          heading: "Extreme Hunger",
          group: "grp7",
          name: 'extremeHunger'
        },

        // {
        //   label: "6 months",
        //   name: "extreme_hunger_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.extreme_hunger_six_months ? this.editFormData.extreme_hunger_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "extreme_hunger_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.extreme_hunger_last_month ? this.editFormData.extreme_hunger_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "extreme_hunger_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_today ? this.editFormData.extreme_hunger_today : false,
          group: "grp7",
        },
        // ========== Painful urination ======== //
        {
          heading: "Painful urination",
          group: "grp7",
          name: 'painfulUrination'
        },

        // {
        //   label: "6 months",
        //   name: "painful_urination_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painful_urination_six_months ? this.editFormData.painful_urination_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "painful_urination_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painful_urination_last_month ? this.editFormData.painful_urination_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "painful_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_today ? this.editFormData.painful_urination_today : false,
          group: "grp7",
        },
        // ========== Numb or tingling feet ======== //
        {
          heading: "Numb or tingling feet",
          group: "grp7",
          name: 'numbTinglingFeet'
        },

        // {
        //   label: "6 months",
        //   name: "numb_or_tingling_feet_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.numb_or_tingling_feet_six_months ? this.editFormData.numb_or_tingling_feet_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "numb_or_tingling_feet_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.numb_or_tingling_feet_last_month ? this.editFormData.numb_or_tingling_feet_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "numb_or_tingling_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_today ? this.editFormData.numb_or_tingling_feet_today : false,
          group: "grp7",
        },
        // ========== Sexual Difficulties ======== //
        {
          heading: "Sexual Difficulties",
          group: "grp7",
          name: 'sexualDifficulties'
        },

        // {
        //   label: "6 months",
        //   name: "sexual_difficulties_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sexual_difficulties_six_months ? this.editFormData.sexual_difficulties_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "sexual_difficulties_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sexual_difficulties_last_month ? this.editFormData.sexual_difficulties_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "sexual_difficulties_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_today ? this.editFormData.sexual_difficulties_today : false,
          group: "grp7",
        },
        // ========== Urinary problems ======== //
        {
          heading: "Urinary problems",
          group: "grp7",
          name: 'urinaryProblems'
        },

        // {
        //   label: "6 months",
        //   name: "urinary_problems_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urinary_problems_six_months ? this.editFormData.urinary_problems_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "urinary_problems_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urinary_problems_last_month ? this.editFormData.urinary_problems_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "urinary_problems_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_today ? this.editFormData.urinary_problems_today : false,
          group: "grp7",
        },
        // ========== Tingling Hands & Feet ======== //
        {
          heading: "Tingling Hands & Feet",
          group: "grp7",
          name: 'tinglingHandsFeet'
        },

        // {
        //   label: "6 months",
        //   name: "tingling_hands_feet_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tingling_hands_feet_six_months ? this.editFormData.tingling_hands_feet_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "tingling_hands_feet_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tingling_hands_feet_last_month ? this.editFormData.tingling_hands_feet_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "tingling_hands_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_today ? this.editFormData.tingling_hands_feet_today : false,
          group: "grp7",
        },
        // ========== Burning Sensation ======== //
        {
          heading: "Burning Sensation",
          group: "grp7",
          name: 'burningSensation'
        },

        // {
        //   label: "6 months",
        //   name: "burning_sensation_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.burning_sensation_six_months ? this.editFormData.burning_sensation_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "burning_sensation_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.burning_sensation_last_month ? this.editFormData.burning_sensation_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "burning_sensation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_today ? this.editFormData.burning_sensation_today : false,
          group: "grp7",
        },
        // ========== Angina ======== //
        {
          heading: "Angina (Chest pain that goes away with rest)",
          group: "grp7",
          name: 'angina'
        },

        // {
        //   label: "6 months",
        //   name: "angina_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.angina_six_months ? this.editFormData.angina_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "angina_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.angina_last_month ? this.editFormData.angina_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "angina_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_today ? this.editFormData.angina_today : false,
          group: "grp7",
        },
        // ========== Chest Pain That Goes Away With Rest ======== //
        {
          heading: "Chest Pain That Goes Away With Rest",
          group: "grp7",
          name: 'chestPainWithRest'
        },

        // {
        //   label: "6 months",
        //   name: "chest_pain_that_goesaway_with_rest_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_six_months ? this.editFormData.chest_pain_that_goesaway_with_rest_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chest_pain_that_goesaway_with_rest_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_last_month ? this.editFormData.chest_pain_that_goesaway_with_rest_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chest_pain_that_goesaway_with_rest_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_today ? this.editFormData.chest_pain_that_goesaway_with_rest_today : false,
          group: "grp7",
        },
        // ========== Heartburn ======== //
        {
          heading: "Heartburn",
          group: "grp7",
          name: 'heartburn'
        },

        // {
        //   label: "6 months",
        //   name: "heartburn_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.heartburn_six_months ? this.editFormData.heartburn_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "heartburn_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.heartburn_last_month ? this.editFormData.heartburn_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "heartburn_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_today ? this.editFormData.heartburn_today : false,
          group: "grp7",
        },
        // ========== Pain In Calves ======== //
        {
          heading: "Pain In Calves",
          group: "grp7",
          name: 'painInCalves'
        },

        // {
        //   label: "6 months",
        //   name: "pain_in_calves_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_in_calves_six_months ? this.editFormData.pain_in_calves_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pain_in_calves_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_in_calves_last_month ? this.editFormData.pain_in_calves_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pain_in_calves_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_today ? this.editFormData.pain_in_calves_today : false,
          group: "grp7",
        },
        // ========== Dizziness Or Lightheadedness ======== //
        {
          heading: "Dizziness Or Lightheadedness",
          group: "grp7",
          name: 'dizzinessOrLightheadedness'
        },

        // {
        //   label: "6 months",
        //   name: "dizziness_lightheadedness_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dizziness_lightheadedness_six_months ? this.editFormData.dizziness_lightheadedness_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "dizziness_lightheadedness_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dizziness_lightheadedness_last_month ? this.editFormData.dizziness_lightheadedness_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "dizziness_lightheadedness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_today ? this.editFormData.dizziness_lightheadedness_today : false,
          group: "grp7",
        },
        // ========== Irregular Heartbeat, Too Fast/Slow ======== //
        {
          heading: "Irregular Heartbeat, Too Fast/Slow",
          group: "grp7",
          name: 'irregularHeartbeatToo'
        },

        // {
        //   label: "6 months",
        //   name: "irregular_heartbeat_toofastslow_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_six_months ? this.editFormData.irregular_heartbeat_toofastslow_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "irregular_heartbeat_toofastslow_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_last_month ? this.editFormData.irregular_heartbeat_toofastslow_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "irregular_heartbeat_toofastslow_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_today ? this.editFormData.irregular_heartbeat_toofastslow_today : false,
          group: "grp7",
        },
        // ========== Heart Attack ======== //
        {
          heading: "Heart Attack",
          group: "grp7",
          name: 'heartAttack'
        },

        // {
        //   label: "6 months",
        //   name: "heart_attack_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.heart_attack_six_months ? this.editFormData.heart_attack_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "heart_attack_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.heart_attack_last_month ? this.editFormData.heart_attack_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "heart_attack_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_today ? this.editFormData.heart_attack_today : false,
          group: "grp7",
        },
        // ========== Stroke ======== //
        {
          heading: "Stroke",
          group: "grp7",
          name: 'stroke'
        },

        // {
        //   label: "6 months",
        //   name: "stroke_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.stroke_six_months ? this.editFormData.stroke_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "stroke_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.stroke_last_month ? this.editFormData.stroke_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_today ? this.editFormData.stroke_today : false,
          group: "grp7",
        },
        // ========== Blood Clot In A Vein (Venous Thrombosis) ======== //
        {
          heading: "Blood Clot In A Vein (Venous Thrombosis)",
          group: "grp7",
          name: 'bloodClotInVein'
        },

        // {
        //   label: "6 months",
        //   name: "blood_clot_inavein_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_clot_inavein_six_months ? this.editFormData.blood_clot_inavein_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "blood_clot_inavein_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_clot_inavein_last_month ? this.editFormData.blood_clot_inavein_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "blood_clot_inavein_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_today ? this.editFormData.blood_clot_inavein_today : false,
          group: "grp7",
        },
        // ========== Tia (Mini Stroke) ======== //
        {
          heading: "Tia (Mini Stroke)",
          group: "grp7",
          name: 'tiaMiniStroke'
        },

        // {
        //   label: "6 months",
        //   name: "tia_mini_stroke_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tia_mini_stroke_six_months ? this.editFormData.tia_mini_stroke_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "tia_mini_stroke_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tia_mini_stroke_last_month ? this.editFormData.tia_mini_stroke_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "tia_mini_stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_today ? this.editFormData.tia_mini_stroke_today : false,
          group: "grp7",
        },
        // ========== Chest Pain Or Discomfort ======== //
        {
          heading: "Chest Pain Or Discomfort",
          group: "grp7",
          name: 'chestPainOrDiscomfort'
        },

        // {
        //   label: "6 months",
        //   name: "chest_painor_discomfort_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_painor_discomfort_six_months ? this.editFormData.chest_painor_discomfort_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chest_painor_discomfort_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_painor_discomfort_last_month ? this.editFormData.chest_painor_discomfort_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chest_painor_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_today ? this.editFormData.chest_painor_discomfort_today : false,
          group: "grp7",
        },
        // ========== Fainting ======== //
        {
          heading: "Fainting",
          group: "grp7",
          name: 'fainting'
        },

        // {
        //   label: "6 months",
        //   name: "fainting_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fainting_six_months ? this.editFormData.fainting_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "fainting_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.fainting_last_month ? this.editFormData.fainting_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "fainting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_today ? this.editFormData.fainting_today : false,
          group: "grp7",
        },
        // ========== Weakness ======== //
        {
          heading: "Weakness",
          group: "grp7",
          name: 'weakness'
        },

        // {
        //   label: "6 months",
        //   name: "weakness_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weakness_six_months ? this.editFormData.weakness_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "weakness_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weakness_last_month ? this.editFormData.weakness_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "weakness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_today ? this.editFormData.weakness_today : false,
          group: "grp7",
        },
        // ========== Lingering discomfort ======== //
        {
          heading: "Lingering discomfort",
          group: "grp7",
          name: 'lingeringDiscomfort'
        },

        // {
        //   label: "6 months",
        //   name: "lingering_discomfort_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lingering_discomfort_six_months ? this.editFormData.lingering_discomfort_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "lingering_discomfort_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lingering_discomfort_last_month ? this.editFormData.lingering_discomfort_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "lingering_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_today ? this.editFormData.lingering_discomfort_today : false,
          group: "grp7",
        },
        // ========== Pain in affected joint ======== //
        {
          heading: "Pain in affected joint",
          group: "grp7",
          name: 'painAffectedJoint'
        },

        // {
        //   label: "6 months",
        //   name: "pain_in_affected_joint_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_in_affected_joint_six_months ? this.editFormData.pain_in_affected_joint_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pain_in_affected_joint_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_in_affected_joint_last_month ? this.editFormData.pain_in_affected_joint_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pain_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_today ? this.editFormData.pain_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Swelling in affected joint ======== //
        {
          heading: "Swelling in affected joint",
          group: "grp7",
          name: 'swellingAffectedJoint'
        },

        // {
        //   label: "6 months",
        //   name: "swelling_in_affected_joint_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_in_affected_joint_six_months ? this.editFormData.swelling_in_affected_joint_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swelling_in_affected_joint_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swelling_in_affected_joint_last_month ? this.editFormData.swelling_in_affected_joint_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swelling_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_today ? this.editFormData.swelling_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Redness in affected joint ======== //
        {
          heading: "Redness in affected joint",
          group: "grp7",
          name: 'rednessAffectedJoint'
        },

        // {
        //   label: "6 months",
        //   name: "redness_in_affected_joint_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.redness_in_affected_joint_six_months ? this.editFormData.redness_in_affected_joint_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "redness_in_affected_joint_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.redness_in_affected_joint_last_month ? this.editFormData.redness_in_affected_joint_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "redness_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_today ? this.editFormData.redness_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Chills ======== //
        {
          heading: "Chills",
          group: "grp7",
          name: 'chills'
        },

        // {
        //   label: "6 months",
        //   name: "chills_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chills_six_months ? this.editFormData.chills_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chills_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chills_last_month ? this.editFormData.chills_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chills_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_today ? this.editFormData.chills_today : false,
          group: "grp7",
        },
        // ========== Cough ======== //
        {
          heading: "Cough",
          group: "grp7",
          name: 'cough'
        },

        // {
        //   label: "6 months",
        //   name: "cough_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cough_six_months ? this.editFormData.cough_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "cough_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cough_last_month ? this.editFormData.cough_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "cough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_today ? this.editFormData.cough_today : false,
          group: "grp7",
        },
        // ========== Chest pain when you breathe or cough ======== //
        {
          heading: "Chest pain when you breathe or cough",
          group: "grp7",
          name: 'chestPainBreathe'
        },

        // {
        //   label: "6 months",
        //   name: "chest_pain_when_you_breatheorcough_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_six_months ? this.editFormData.chest_pain_when_you_breatheorcough_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "chest_pain_when_you_breatheorcough_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_last_month ? this.editFormData.chest_pain_when_you_breatheorcough_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "chest_pain_when_you_breatheorcough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_today ? this.editFormData.chest_pain_when_you_breatheorcough_today : false,
          group: "grp7",
        },
        // ========== Nausea ======== //
        {
          heading: "Nausea",
          group: "grp7",
          name: 'nausea'
        },

        // {
        //   label: "6 months",
        //   name: "nausea_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_six_months ? this.editFormData.nausea_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "nausea_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_last_month ? this.editFormData.nausea_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "nausea_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_today ? this.editFormData.nausea_today : false,
          group: "grp7",
        },
        // ========== Pain or burning while urinating ======== //
        {
          heading: "Pain or burning while urinating",
          group: "grp7",
          name: 'painBurningUrinating'
        },

        // {
        //   label: "6 months",
        //   name: "painorburning_while_urinating_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painorburning_while_urinating_six_months ? this.editFormData.painorburning_while_urinating_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "painorburning_while_urinating_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painorburning_while_urinating_last_month ? this.editFormData.painorburning_while_urinating_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "painorburning_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_today ? this.editFormData.painorburning_while_urinating_today : false,
          group: "grp7",
        },
        // ========== Bloody urine ======== //
        {
          heading: "Bloody urine",
          group: "grp7",
          name: 'bloodyUrine'
        },

        // {
        //   label: "6 months",
        //   name: "bloody_urine_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bloody_urine_six_months ? this.editFormData.bloody_urine_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "bloody_urine_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bloody_urine_last_month ? this.editFormData.bloody_urine_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "bloody_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_today ? this.editFormData.bloody_urine_today : false,
          group: "grp7",
        },
        // ========== Runny nose ======== //
        {
          heading: "Runny nose",
          group: "grp7",
          name: 'runnyNose'
        },

        // {
        //   label: "6 months",
        //   name: "runny_nose_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.runny_nose_six_months ? this.editFormData.runny_nose_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "runny_nose_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.runny_nose_last_month ? this.editFormData.runny_nose_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "runny_nose_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_today ? this.editFormData.runny_nose_today : false,
          group: "grp7",
        },
        // ========== Sore throat ======== //
        {
          heading: "Sore throat",
          group: "grp7",
          name: 'soreThroat'
        },

        // {
        //   label: "6 months",
        //   name: "sore_throat_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sore_throat_six_months ? this.editFormData.sore_throat_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "sore_throat_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sore_throat_last_month ? this.editFormData.sore_throat_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "sore_throat_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_today ? this.editFormData.sore_throat_today : false,
          group: "grp7",
        },
        // ========== Swollen glands ======== //
        {
          heading: "Swollen glands",
          group: "grp7",
          name: 'swollenGlands'
        },

        // {
        //   label: "6 months",
        //   name: "swollen_glands_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollen_glands_six_months ? this.editFormData.swollen_glands_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swollen_glands_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollen_glands_last_month ? this.editFormData.swollen_glands_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swollen_glands_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_today ? this.editFormData.swollen_glands_today : false,
          group: "grp7",
        },
        // ========== Trouble breathing ======== //
        {
          heading: "Trouble breathing",
          group: "grp7",
          name: 'troubleBreathing'
        },

        // {
        //   label: "6 months",
        //   name: "trouble_breathing_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_breathing_six_months ? this.editFormData.trouble_breathing_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "trouble_breathing_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_breathing_last_month ? this.editFormData.trouble_breathing_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "trouble_breathing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_today ? this.editFormData.trouble_breathing_today : false,
          group: "grp7",
        },
        // ========== High-pitched sound when breathing in ======== //
        {
          heading: "High-pitched sound when breathing in",
          group: "grp7",
          name: 'highPitchedBreathing'
        },

        // {
        //   label: "6 months",
        //   name: "high_pitched_sound_when_breathing_in_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_six_months ? this.editFormData.high_pitched_sound_when_breathing_in_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "high_pitched_sound_when_breathing_in_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_last_month ? this.editFormData.high_pitched_sound_when_breathing_in_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "high_pitched_sound_when_breathing_in_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_today ? this.editFormData.high_pitched_sound_when_breathing_in_today : false,
          group: "grp7",
        },
        // ========== Burning and pain while urinating ======== //
        {
          heading: "Burning and pain while urinating",
          group: "grp7",
          name: 'burningPainUrinating'
        },

        // {
        //   label: "6 months",
        //   name: "burning_and_pain_while_urinating_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_six_months ? this.editFormData.burning_and_pain_while_urinating_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "burning_and_pain_while_urinating_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_last_month ? this.editFormData.burning_and_pain_while_urinating_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "burning_and_pain_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_today ? this.editFormData.burning_and_pain_while_urinating_today : false,
          group: "grp7",
        },

        // ========== Red eyes ======== //
        {
          heading: "Red eyes",
          group: "grp7",
          name: 'redEyes'
        },

        // {
        //   label: "6 months",
        //   name: "red_eyes_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.red_eyes_six_months ? this.editFormData.red_eyes_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "red_eyes_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.red_eyes_last_month ? this.editFormData.red_eyes_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "red_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_today ? this.editFormData.red_eyes_today : false,
          group: "grp7",
        },
        // ========== Discharge from your eyes ======== //
        {
          heading: "Discharge from your eyes",
          group: "grp7",
          name: 'dischargeYourEyes'
        },

        // {
        //   label: "6 months",
        //   name: "discharge_from_your_eyes_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discharge_from_your_eyes_six_months ? this.editFormData.discharge_from_your_eyes_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "discharge_from_your_eyes_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discharge_from_your_eyes_last_month ? this.editFormData.discharge_from_your_eyes_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "discharge_from_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_today ? this.editFormData.discharge_from_your_eyes_today : false,
          group: "grp7",
        },
        // ========== Abdominal pain ======== //
        {
          heading: "Abdominal pain",
          group: "grp7",
          name: 'abdominalPain'
        },

        // {
        //   label: "6 months",
        //   name: "abdominal_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_pain_six_months ? this.editFormData.abdominal_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "abdominal_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_pain_last_month ? this.editFormData.abdominal_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "abdominal_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_today ? this.editFormData.abdominal_pain_today : false,
          group: "grp7",
        },
        // ========== Change in urine color ======== //
        {
          heading: "Change in urine color",
          group: "grp7",
          name: 'changeUrineColor'
        },

        // {
        //   label: "6 months",
        //   name: "change_in_urine_color_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.change_in_urine_color_six_months ? this.editFormData.change_in_urine_color_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "change_in_urine_color_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.change_in_urine_color_last_month ? this.editFormData.change_in_urine_color_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "change_in_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_today ? this.editFormData.change_in_urine_color_today : false,
          group: "grp7",
        },
        // ========== Painful ejaculation ======== //
        {
          heading: "Painful ejaculation",
          group: "grp7",
          name: 'painfulEjaculation'
        },

        // {
        //   label: "6 months",
        //   name: "painful_ejaculation_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painful_ejaculation_six_months ? this.editFormData.painful_ejaculation_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "painful_ejaculation_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.painful_ejaculation_last_month ? this.editFormData.painful_ejaculation_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "painful_ejaculation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_today ? this.editFormData.painful_ejaculation_today : false,
          group: "grp7",
        },
        // ========== A missed period ======== //
        {
          heading: "A missed period",
          group: "grp7",
          name: 'missedPeriod'
        },

        // {
        //   label: "6 months",
        //   name: "amissed_period_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.amissed_period_six_months ? this.editFormData.amissed_period_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "amissed_period_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.amissed_period_last_month ? this.editFormData.amissed_period_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "amissed_period_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_today ? this.editFormData.amissed_period_today : false,
          group: "grp7",
        },
        // ========== Swollen or tender breasts ======== //
        {
          heading: "Swollen or tender breasts",
          group: "grp7",
          name: 'swollenTenderBreasts'
        },

        // {
        //   label: "6 months",
        //   name: "swollenortender_breasts_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollenortender_breasts_six_months ? this.editFormData.swollenortender_breasts_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swollenortender_breasts_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollenortender_breasts_last_month ? this.editFormData.swollenortender_breasts_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swollenortender_breasts_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_today ? this.editFormData.swollenortender_breasts_today : false,
          group: "grp7",
        },
        // ========== Nausea, with or without vomiting ======== //
        {
          heading: "Nausea, with or without vomiting",
          group: "grp7",
          name: 'nauseaWithVomiting'
        },

        // {
        //   label: "6 months",
        //   name: "nausea_withorwithout_vomiting_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_six_months ? this.editFormData.nausea_withorwithout_vomiting_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "nausea_withorwithout_vomiting_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_last_month ? this.editFormData.nausea_withorwithout_vomiting_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "nausea_withorwithout_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_today ? this.editFormData.nausea_withorwithout_vomiting_today : false,
          group: "grp7",
        },
        // ========== Light spotting ======== //
        {
          heading: "Light spotting",
          group: "grp7",
          name: 'lightSpotting'
        },

        // {
        //   label: "6 months",
        //   name: "light_spotting_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.light_spotting_six_months ? this.editFormData.light_spotting_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "light_spotting_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.light_spotting_last_month ? this.editFormData.light_spotting_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "light_spotting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_today ? this.editFormData.light_spotting_today : false,
          group: "grp7",
        },
        // ========== Cramping ======== //
        {
          heading: "Cramping",
          group: "grp7",
          name: 'cramping'
        },

        // {
        //   label: "6 months",
        //   name: "cramping_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cramping_six_months ? this.editFormData.cramping_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "cramping_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.cramping_last_month ? this.editFormData.cramping_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "cramping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_today ? this.editFormData.cramping_today : false,
          group: "grp7",
        },
        // ========== Bloating ======== //
        {
          heading: "Bloating",
          group: "grp7",
          name: 'bloating'
        },

        // {
        //   label: "6 months",
        //   name: "bloating_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bloating_six_months ? this.editFormData.bloating_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "bloating_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.bloating_last_month ? this.editFormData.bloating_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "bloating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_today ? this.editFormData.bloating_today : false,
          group: "grp7",
        },
        // ========== Mood swings ======== //
        {
          heading: "Mood swings",
          group: "grp7",
          name: 'moodSwings'
        },

        // {
        //   label: "6 months",
        //   name: "mood_swings_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.mood_swings_six_months ? this.editFormData.mood_swings_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "mood_swings_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.mood_swings_last_month ? this.editFormData.mood_swings_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "mood_swings_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_today ? this.editFormData.mood_swings_today : false,
          group: "grp7",
        },


        // ========== Skin irritation or dimpling ======== //
        {
          heading: "Skin irritation or dimpling",
          group: "grp7",
          name: 'skinIrritationDimpling'
        },

        // {
        //   label: "6 months",
        //   name: "skin_dimpling_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.skin_dimpling_six_months ? this.editFormData.skin_dimpling_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "skin_dimpling_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.skin_dimpling_last_month ? this.editFormData.skin_dimpling_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "skin_dimpling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_dimpling_today ? this.editFormData.skin_dimpling_today : false,
          group: "grp7",
        },

        // ========== Breast or nipple pain ======== //
        {
          heading: "Breast or nipple pain",
          group: "grp7",
          name: 'breastNipplePain'
        },

        // {
        //   label: "6 months",
        //   name: "breast_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.breast_pain_six_months ? this.editFormData.breast_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "breast_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.breast_pain_last_month ? this.editFormData.breast_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "breast_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.breast_pain_today ? this.editFormData.breast_pain_today : false,
          group: "grp7",
        },

        // ========== Nipple retraction ======== //
        {
          heading: "Nipple retraction",
          group: "grp7",
          name: 'nippleRetraction'
        },

        // {
        //   label: "6 months",
        //   name: "nipple_retraction_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nipple_retraction_six_months ? this.editFormData.nipple_retraction_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "nipple_retraction_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nipple_retraction_last_month ? this.editFormData.nipple_retraction_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "nipple_retraction_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_retraction_today ? this.editFormData.nipple_retraction_today : false,
          group: "grp7",
        },

        // ========== Redness, scaliness, or thickening of the nipple or breast skin ======== //
        {
          heading: "Redness, scaliness, or thickening of the nipple or breast skin",
          group: "grp7",
          name: 'rednessScaliness'
        },

        // {
        //   label: "6 months",
        //   name: "redness_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.redness_six_months ? this.editFormData.redness_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "redness_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.redness_last_month ? this.editFormData.redness_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "redness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_today ? this.editFormData.redness_today : false,
          group: "grp7",
        },

        // ========== Nipple discharge ======== //
        {
          heading: "Nipple discharge",
          group: "grp7",
          name: 'nippleDischarge'
        },

        // {
        //   label: "6 months",
        //   name: "nipple_discharge_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nipple_discharge_six_months ? this.editFormData.nipple_discharge_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "nipple_discharge_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.nipple_discharge_last_month ? this.editFormData.nipple_discharge_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "nipple_discharge_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_discharge_today ? this.editFormData.nipple_discharge_today : false,
          group: "grp7",
        },

        // ========== Abdominal bloating or swelling ======== //
        {
          heading: "Abdominal bloating or swelling",
          group: "grp7",
          name: 'abdominalBloatingSwelling'
        },

        // {
        //   label: "6 months",
        //   name: "abdominal_swelling_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_swelling_six_months ? this.editFormData.abdominal_swelling_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "abdominal_swelling_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.abdominal_swelling_last_month ? this.editFormData.abdominal_swelling_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "abdominal_swelling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_swelling_today ? this.editFormData.abdominal_swelling_today : false,
          group: "grp7",
        },

        // ========== Quickly feeling full when eating ======== //
        {
          heading: "Quickly feeling full when eating",
          group: "grp7",
          name: 'quicklyFellingEating'
        },

        // {
        //   label: "6 months",
        //   name: "quickly_feeling_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.quickly_feeling_six_months ? this.editFormData.quickly_feeling_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "quickly_feeling_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.quickly_feeling_last_month ? this.editFormData.quickly_feeling_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "quickly_feeling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.quickly_feeling_today ? this.editFormData.quickly_feeling_today : false,
          group: "grp7",
        },

        // ========== Discomfort in the pelvic area ======== //
        {
          heading: "Discomfort in the pelvic area",
          group: "grp7",
          name: 'discomfortPelvicArea'
        },

        // {
        //   label: "6 months",
        //   name: "discomfort_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discomfort_six_months ? this.editFormData.discomfort_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "discomfort_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discomfort_last_month ? this.editFormData.discomfort_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_today ? this.editFormData.discomfort_today : false,
          group: "grp7",
        },

        // ========== Blood in Stool ======== //
        {
          heading: "Blood in Stool",
          group: "grp7",
          name: 'bloodInStool'
        },

        // {
        //   label: "6 months",
        //   name: "blood_stool_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_stool_six_months ? this.editFormData.blood_stool_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "blood_stool_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.blood_stool_last_month ? this.editFormData.blood_stool_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "blood_stool_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_stool_today ? this.editFormData.blood_stool_today : false,
          group: "grp7",
        },

        // ========== Constipation ======== //
        {
          heading: "Constipation",
          group: "grp7",
          name: 'constipation'
        },

        // {
        //   label: "6 months",
        //   name: "constipation_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.constipation_six_months ? this.editFormData.constipation_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "constipation_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.constipation_last_month ? this.editFormData.constipation_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "constipation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.constipation_today ? this.editFormData.constipation_today : false,
          group: "grp7",
        },

        // ========== Diarrhea ======== //
        {
          heading: "Diarrhea",
          group: "grp7",
          name: 'diarrhea'
        },

        // {
        //   label: "6 months",
        //   name: "diarrhea_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.diarrhea_six_months ? this.editFormData.diarrhea_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "diarrhea_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.diarrhea_last_month ? this.editFormData.diarrhea_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "diarrhea_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diarrhea_today ? this.editFormData.diarrhea_today : false,
          group: "grp7",
        },

        // ========== Coughing up blood ======== //
        {
          heading: "Coughing up blood",
          group: "grp7",
          name: 'coughingUpBlood'
        },

        // {
        //   label: "6 months",
        //   name: "coughing_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_six_months ? this.editFormData.coughing_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "coughing_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.coughing_last_month ? this.editFormData.coughing_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "coughing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_today ? this.editFormData.coughing_today : false,
          group: "grp7",
        },

        // ========== Dark colored urine ======== //
        {
          heading: "Dark colored urine",
          group: "grp7",
          name: 'darkColoredUrine'
        },

        // {
        //   label: "6 months",
        //   name: "dark_colored_urine_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dark_colored_urine_six_months ? this.editFormData.dark_colored_urine_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "dark_colored_urine_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dark_colored_urine_last_month ? this.editFormData.dark_colored_urine_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "dark_colored_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_colored_urine_today ? this.editFormData.dark_colored_urine_today : false,
          group: "grp7",
        },

        // ========== Stools that look black ======== //
        {
          heading: "Stools that look black",
          group: "grp7",
          name: 'stollsLookBlack'
        },

        // {
        //   label: "6 months",
        //   name: "stools_black_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.stools_black_six_months ? this.editFormData.stools_black_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "stools_black_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.stools_black_last_month ? this.editFormData.stools_black_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "stools_black_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stools_black_today ? this.editFormData.stools_black_today : false,
          group: "grp7",
        },

        // ========== Belly pain ======== //
        {
          heading: "Belly pain",
          group: "grp7",
          name: 'bellyPain'
        },

        // {
        //   label: "6 months",
        //   name: "belly_pain_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.belly_pain_six_months ? this.editFormData.belly_pain_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "belly_pain_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.belly_pain_last_month ? this.editFormData.belly_pain_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "belly_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.belly_pain_today ? this.editFormData.belly_pain_today : false,
          group: "grp7",
        },

        // ========== Trouble swallowing ======== //
        {
          heading: "Trouble swallowing",
          group: "grp7",
          name: 'troubleSwallowing'
        },

        // {
        //   label: "6 months",
        //   name: "trouble_swallowing_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_swallowing_six_months ? this.editFormData.trouble_swallowing_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "trouble_swallowing_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_swallowing_last_month ? this.editFormData.trouble_swallowing_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "trouble_swallowing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_swallowing_today ? this.editFormData.trouble_swallowing_today : false,
          group: "grp7",
        },

        // ========== Feeling bloated after eating ======== //
        {
          heading: "Feeling bloated after eating",
          group: "grp7",
          name: 'feelingBloatedEating'
        },

        // {
        //   label: "6 months",
        //   name: "feeling_bloated_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.feeling_bloated_six_months ? this.editFormData.feeling_bloated_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "feeling_bloated_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.feeling_bloated_last_month ? this.editFormData.feeling_bloated_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "feeling_bloated_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feeling_bloated_today ? this.editFormData.feeling_bloated_today : false,
          group: "grp7",
        },


        // ========== Indigestion ======== //
        {
          heading: "Indigestion",
          group: "grp7",
          name: 'indigestion'
        },

        // {
        //   label: "6 months",
        //   name: "indigestion_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.indigestion_six_months ? this.editFormData.indigestion_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "indigestion_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.indigestion_last_month ? this.editFormData.indigestion_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "indigestion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.indigestion_today ? this.editFormData.indigestion_today : false,
          group: "grp7",
        },


        // ========== Difficulty starting urination ======== //
        {
          heading: "Difficulty starting urination",
          group: "grp7",
          name: 'difficultyStartingUrination'
        },

        // {
        //   label: "6 months",
        //   name: "diff_urination_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.diff_urination_six_months ? this.editFormData.diff_urination_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "diff_urination_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.diff_urination_last_month ? this.editFormData.diff_urination_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "diff_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diff_urination_today ? this.editFormData.diff_urination_today : false,
          group: "grp7",
        },

        // ========== Weak or interrupted flow of urine ======== //
        {
          heading: "Weak or interrupted flow of urine",
          group: "grp7",
          name: 'weakFlowUrine'
        },

        // {
        //   label: "6 months",
        //   name: "weak_urine_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weak_urine_six_months ? this.editFormData.weak_urine_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "weak_urine_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.weak_urine_last_month ? this.editFormData.weak_urine_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "weak_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weak_urine_today ? this.editFormData.weak_urine_today : false,
          group: "grp7",
        },


        // ========== Urinating often, especially at night ======== //
        {
          heading: "Urinating often, especially at night",
          group: "grp7",
          name: 'urinatingOftenNight'
        },

        // {
        //   label: "6 months",
        //   name: "urin_often_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urin_often_six_months ? this.editFormData.urin_often_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "urin_often_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.urin_often_last_month ? this.editFormData.urin_often_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "urin_often_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urin_often_today ? this.editFormData.urin_often_today : false,
          group: "grp7",
        },


        // ========== Trouble emptying the bladder completely ======== //
        {
          heading: "Trouble emptying the bladder completely",
          group: "grp7",
          name: 'troubleBladderCompletely'
        },

        // {
        //   label: "6 months",
        //   name: "trouble_empty_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_empty_six_months ? this.editFormData.trouble_empty_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "trouble_empty_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.trouble_empty_last_month ? this.editFormData.trouble_empty_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "trouble_empty_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_empty_today ? this.editFormData.trouble_empty_today : false,
          group: "grp7",
        },


        // ========== Pain or burning during urination ======== //
        {
          heading: "Pain or burning during urination",
          group: "grp7",
          name: 'painDuringUrination'
        },

        // {
        //   label: "6 months",
        //   name: "pain_during_urin_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_during_urin_six_months ? this.editFormData.pain_during_urin_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pain_during_urin_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_during_urin_last_month ? this.editFormData.pain_during_urin_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pain_during_urin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_during_urin_today ? this.editFormData.pain_during_urin_today : false,
          group: "grp7",
        },


        // ========== Pain in the back, hips, or pelvis that doesn’t go away ======== //
        {
          heading: "Pain in the back, hips, or pelvis that doesn't go away",
          group: "grp7",
          name: 'painBackHipsPelvis'
        },

        // {
        //   label: "6 months",
        //   name: "pain_hips_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_hips_six_months ? this.editFormData.pain_hips_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pain_hips_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_hips_last_month ? this.editFormData.pain_hips_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pain_hips_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_hips_today ? this.editFormData.pain_hips_today : false,
          group: "grp7",
        },


        // ========== A lump or swelling in either testicle ======== //
        {
          heading: "A lump or swelling in either testicle",
          group: "grp7",
          name: 'lumpSwellingTestcle'
        },

        // {
        //   label: "6 months",
        //   name: "lump_or_swelling_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lump_or_swelling_six_months ? this.editFormData.lump_or_swelling_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "lump_or_swelling_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lump_or_swelling_last_month ? this.editFormData.lump_or_swelling_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "lump_or_swelling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_or_swelling_today ? this.editFormData.lump_or_swelling_today : false,
          group: "grp7",
        },


        // ========== A feeling of heaviness in the scrotum ======== //
        {
          heading: "A feeling of heaviness in the scrotum",
          group: "grp7",
          name: 'feelingHeavinessScrotum'
        },

        // {
        //   label: "6 months",
        //   name: "feel_heaviness_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.feel_heaviness_six_months ? this.editFormData.feel_heaviness_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "feel_heaviness_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.feel_heaviness_last_month ? this.editFormData.feel_heaviness_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "feel_heaviness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feel_heaviness_today ? this.editFormData.feel_heaviness_today : false,
          group: "grp7",
        },


        // ========== A dull ache in the lower belly or groin ======== //
        {
          heading: "A dull ache in the lower belly or groin",
          group: "grp7",
          name: 'dullLowerBelly'
        },

        // {
        //   label: "6 months",
        //   name: "dull_ache_lower_belly_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dull_ache_lower_belly_six_months ? this.editFormData.dull_ache_lower_belly_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "dull_ache_lower_belly_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.dull_ache_lower_belly_last_month ? this.editFormData.dull_ache_lower_belly_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "dull_ache_lower_belly_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dull_ache_lower_belly_today ? this.editFormData.dull_ache_lower_belly_today : false,
          group: "grp7",
        },


        // ========== Sudden swelling in the scrotum ======== //
        {
          heading: "Sudden swelling in the scrotum",
          group: "grp7",
          name: 'suddenSwellingScrotum'
        },

        // {
        //   label: "6 months",
        //   name: "sudden_swelling_scrotum_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sudden_swelling_scrotum_six_months ? this.editFormData.sudden_swelling_scrotum_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "sudden_swelling_scrotum_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.sudden_swelling_scrotum_last_month ? this.editFormData.sudden_swelling_scrotum_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "sudden_swelling_scrotum_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sudden_swelling_scrotum_today ? this.editFormData.sudden_swelling_scrotum_today : false,
          group: "grp7",
        },


        // ========== Pain or discomfort in a testicle or the scrotum ======== //
        {
          heading: "Pain or discomfort in a testicle or the scrotum",
          group: "grp7",
          name: 'painDiscomfortScrotum'
        },

        // {
        //   label: "6 months",
        //   name: "discomfort_testicle_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discomfort_testicle_six_months ? this.editFormData.discomfort_testicle_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "discomfort_testicle_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.discomfort_testicle_last_month ? this.editFormData.discomfort_testicle_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "discomfort_testicle_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_testicle_today ? this.editFormData.discomfort_testicle_today : false,
          group: "grp7",
        },


        // ========== A lump in the front of your neck ======== //
        {
          heading: "A lump in the front of your neck",
          group: "grp7",
          name: 'lumpFrontNeck'
        },

        // {
        //   label: "6 months",
        //   name: "lump_front_neck_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lump_front_neck_six_months ? this.editFormData.lump_front_neck_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "lump_front_neck_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.lump_front_neck_last_month ? this.editFormData.lump_front_neck_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "lump_front_neck_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_front_neck_today ? this.editFormData.lump_front_neck_today : false,
          group: "grp7",
        },


        // ========== Swollen lymph nodes in your neck ======== //
        {
          heading: "Swollen lymph nodes in your neck",
          group: "grp7",
          name: 'swollenLymphNeck'
        },

        // {
        //   label: "6 months",
        //   name: "swollen_lymph_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollen_lymph_six_months ? this.editFormData.swollen_lymph_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "swollen_lymph_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.swollen_lymph_last_month ? this.editFormData.swollen_lymph_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "swollen_lymph_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_lymph_today ? this.editFormData.swollen_lymph_today : false,
          group: "grp7",
        },


        // ========== Pain in your throat and/or neck ======== //
        {
          heading: "Pain in your throat and/or neck",
          group: "grp7",
          name: 'painThroatNeck'
        },

        // {
        //   label: "6 months",
        //   name: "pain_throat_neck_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_throat_neck_six_months ? this.editFormData.pain_throat_neck_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "pain_throat_neck_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.pain_throat_neck_last_month ? this.editFormData.pain_throat_neck_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "pain_throat_neck_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_throat_neck_today ? this.editFormData.pain_throat_neck_today : false,
          group: "grp7",
        },


        // ========== Change to your voice, such as hoarseness ======== //
        {
          heading: "Change to your voice, such as hoarseness",
          group: "grp7",
          name: 'changeVoiceHoarseness'
        },

        // {
        //   label: "6 months",
        //   name: "change_voice_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.change_voice_six_months ? this.editFormData.change_voice_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "change_voice_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.change_voice_last_month ? this.editFormData.change_voice_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "change_voice_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_voice_today ? this.editFormData.change_voice_today : false,
          group: "grp7",
        },


        // ========== Enlargement or tenderness of the breast tissue ======== //
        {
          heading: "Enlargement or tenderness of the breast tissue",
          group: "grp7",
          name: 'enlargementTendernessBreast'
        },

        // {
        //   label: "6 months",
        //   name: "tenderness_breast_tissue_six_months",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tenderness_breast_tissue_six_months ? this.editFormData.tenderness_breast_tissue_six_months : false,
        //   group: "grp7",
        // },
        // {
        //   label: "Last month",
        //   name: "tenderness_breast_tissue_last_month",
        //   type: 'checkbox',
        //   labelPosition: 'before',
        //   value: this.editFormData && this.editFormData.tenderness_breast_tissue_last_month ? this.editFormData.tenderness_breast_tissue_last_month : false,
        //   group: "grp7",
        // },
        {
          // label: "Today",
          name: "tenderness_breast_tissue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tenderness_breast_tissue_today ? this.editFormData.tenderness_breast_tissue_today : false,
          group: "grp7",
        },

      ]

    }

    console.log('intakeForm', this.intakeForm)

    setTimeout(() => {
      if (this.quick_intake_flag || this.through_patient_intake) {
        this.wrapUpWithDiv();
      }
      if (this.editFormData) {
        this.symptomNotesObj = this.editFormData;
        this.setEditButton(this.symptomNotesObj);
      }
      if (this.subBtn) {
        if (document.querySelector(".grp7")) {
          document.querySelector(".grp7").classList.add("disbledAllCheckbox")
        };
      }
      this.aspinnerLoader = false;
    }, 500);
  }
  withoutPatient() {

    this.intakeForm =
    {
      successmessage: 'Added Successfully !!',
      submittext: this.subBtn ? '' : 'Submit',
      // submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      groups: ["grp0", "grp1", "grp2", "grp3", "grp4", "grp5", "grp6", "grp7"],
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          heading: "PERSONAL INFORMATION",
          group: "grp0",
        },
        {

          label: 'Patient Name',
          name: 'patient_name',
          value: this.editFormData && this.editFormData.patient_name ? this.editFormData.patient_name : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Patient Name is Required' },

          ],
        },

        {
          label: 'Patient Email',
          name: 'patient_email',
          type: 'text',
          group: "grp1",
          hint: '',
          value: this.editFormData && this.editFormData.patient_email ? this.editFormData.patient_email : '',
          validations: [
            { rule: 'required', message: 'Patient Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ]
        },
        {

          label: 'Phone No',
          name: 'patient_phone',
          type: 'numberformat',
          formatflag: true,
          group: "grp1",
          hint: '',
          // val: '',
          value: this.editFormData && this.editFormData.patient_phone ? this.editFormData.patient_phone : '',
          validations: [
            { rule: 'required', message: 'Phone No is required' },
          ]

        },
        {

          label: 'Gender',
          name: 'patient_gender',
          type: 'select',
          group: "grp1",
          hint: '',
          val: [{ val: 'male', name: 'Male' }, { val: 'female', name: 'Female' }],
          multiple: false,
          value: this.editFormData && this.editFormData.patient_gender ? this.editFormData.patient_gender : '',
          validations: [
            { rule: 'required', message: 'Gender is required' },
          ]
        },
        {
          label: 'Address',
          name: 'patient_address',
          value: this.editFormData && this.editFormData.patient_address ? this.editFormData.patient_address : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Address is Required' },

          ],
        },
        {
          label: 'State',
          name: 'state',
          value: this.editFormData && this.editFormData.state ? this.editFormData.state : '',
          group: "grp1",
          val: this.stateList,
          type: 'select',
          validations: [
            { rule: 'required', message: 'State is Required' },

          ],
        },

        {
          label: 'City',
          name: 'city',
          value: this.editFormData && this.editFormData.city ? this.editFormData.city : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'City is Required' },

          ],
        },
        {
          label: 'Zip',
          name: 'zip',
          value: this.editFormData && this.editFormData.zip ? this.editFormData.zip : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Zip is Required' },

          ],
        },

        {
          label: "Date of Birth (MM/DD/YYYY)",
          name: "patient_dob",
          type: "date",
          group: "grp1",
          value: this.editFormData && this.editFormData.patient_dob ? this.editFormData.patient_dob : '',
          // hint: "MM/DD/YYYY",
          // minDate: new Date(),
          maxDate: new Date(),
          validations: [
            { rule: 'required', message: "Date of Birth is required" }
          ]
        },

        {
          label: 'Height (Example: 5\'4\")',
          name: 'height',
          value: this.editFormData && this.editFormData.height ? this.editFormData.height : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Height is Required' },

          ],
        },

        {
          label: 'Weight (Example: 150 Lbs)',
          name: 'weight',
          value: this.editFormData && this.editFormData.weight ? this.editFormData.weight : '',
          group: "grp1",
          type: 'text',
          validations: [
            { rule: 'required', message: 'Weight is Required' },

          ],
        },

        {
          heading: "INSURANCE DETAILS",
          group: "grp2",
        },

        {
          label: "Primary Insurance Name",
          name: "primary_isurance_id",
          group: "grp3",
          type: 'autocomplete',
          endpoint: "intake/insuranceautocomplete",
          search_field: "name_search",
          val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.primary_isurance_id, val: this.editFormData.primary_isurance_name }] : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.primary_isurance_id : [],
          validations: [
            { rule: 'required', message: 'Primary Insurance  is Required' }
          ]

        },

        // {
        //   label: 'Primary Insurance Name',
        //   name: 'primary_isurance_name',
        //   value: this.editFormData && this.editFormData.primary_isurance_name ? this.editFormData.primary_isurance_name : '',
        //   group: "grp3",
        //   type: 'text',
        //   validations: [
        //     { rule: 'required', message: 'Primary Insurance Name is Required' },

        //   ],
        // },

        {
          label: 'Primary Insurance Number',
          name: 'primary_isurance_no',
          value: this.editFormData && this.editFormData.primary_isurance_no ? this.editFormData.primary_isurance_no : null,
          group: "grp3",
          type: 'number',
          validations: [
            { rule: 'required', message: 'Primary Insurance Number is Required' },

          ],
        },

        {
          label: "Secondary Insurance Name",
          name: "secondary_isurance_id",
          group: "grp3",
          type: 'autocomplete',
          endpoint: "intake/insuranceautocomplete",
          search_field: "name_search",
          val: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.secondary_isurance_name ? [{ key: this.editFormData.secondary_isurance_id, val: this.editFormData.secondary_isurance_name }] : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.secondary_isurance_id : [],
          validations: [
            // { rule: 'required', message: 'Secondary Insurance  is Required' }
          ]

        },

        // {
        //   label: 'Secondary Insurance Name',
        //   name: 'secondary_isurance_name',
        //   value: this.editFormData && this.editFormData.secondary_isurance_name ? this.editFormData.secondary_isurance_name : '',
        //   group: "grp3",
        //   type: 'text',
        //   validations: [
        //     { rule: 'required', message: 'Secondary Insurance Name is Required' },

        //   ],
        // },

        {
          label: 'Secondary Insurance Number',
          name: 'secondary_isurance_no',
          value: this.editFormData && this.editFormData.secondary_isurance_no ? this.editFormData.secondary_isurance_no : null,
          group: "grp3",
          type: 'number',
          validations: [
            // { rule: 'required', message: 'Secondary Insurance Number is Required' },

          ],
        },

        {
          heading: "ADDITIONAL QUESTIONS",
          group: "grp4",
        },


        {
          label: "Have you ever been diagnosed with PVD (Peripheral Vascular Disease)?",
          name: "diagnosed",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diagnosed !== undefined && this.editFormData.diagnosed !== null ? this.editFormData.diagnosed == false ? false : true : '',
          // value: this.editFormData.diagnosed,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Are you currently diagnosed with Diabetes?",
          name: "diabetes",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.diabetes !== undefined && this.editFormData.diabetes !== null ? this.editFormData.diabetes == false ? false : true : '',
          // value: this.editFormData.diabetes,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },

        {
          label: "Have you been diagnosed and/or treated for Covid19 in the last year?",
          name: "covid19",
          type: 'radio',
          group: "grp5",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: this.editFormData && this.editFormData.covid19 !== undefined && this.editFormData.covid19 !== null ? this.editFormData.covid19 == false ? false : true : '',
          // value: this.editFormData.covid19,
          validations: [
            { rule: 'required', message: 'This Field is Required' },

          ],
        },
        {
          heading: "SYMPTOMS CHECKLIST",
          group: "grp6",
        },

        // ========== Headaches ======== //
        {
          heading: "Headaches",
          group: "grp7",
          name: 'headaches'
        },

        {
          label: "6 months",
          name: "headaches_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_six_months ? this.editFormData.headaches_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "headaches_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_last_month ? this.editFormData.headaches_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "headaches_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.headaches_today ? this.editFormData.headaches_today : false,
          group: "grp7",
        },

        // ========== Skin and eyes that appear yellowish (jaundice) ======== //
        {
          heading: "Skin and eyes that appear yellowish (jaundice)",
          group: "grp7",
          name: 'skinEyesYellowwish'
        },

        {
          label: "6 months",
          name: "skin_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_six_months ? this.editFormData.skin_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "skin_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_last_month ? this.editFormData.skin_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_today ? this.editFormData.skin_today : false,
          group: "grp7",
        },

        // ========== Abdominal pain and swelling ======== //
        {
          heading: "Abdominal pain and swelling",
          group: "grp7",
          name: 'abdominalSwelling'
        },

        {
          label: "6 months",
          name: "abdominal_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_six_months ? this.editFormData.abdominal_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "abdominal_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_last_month ? this.editFormData.abdominal_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "abdominal_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_today ? this.editFormData.abdominal_today : false,
          group: "grp7",
        },

        // ========== Swelling in the legs and ankles ======== //
        {
          heading: "Swelling in the legs and ankles",
          group: "grp7",
          name: 'swellingAnkles'
        },

        {
          label: "6 months",
          name: "selling_legs_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_six_months ? this.editFormData.selling_legs_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "selling_legs_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_last_month ? this.editFormData.selling_legs_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "selling_legs_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.selling_legs_today ? this.editFormData.selling_legs_today : false,
          group: "grp7",
        },

        // ========== Dry, itchy skin ======== //
        {
          heading: "Dry, itchy skin",
          group: "grp7",
          name: 'dryItchySkin'
        },

        {
          label: "6 months",
          name: "itchy_skin_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_six_months ? this.editFormData.itchy_skin_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "itchy_skin_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_last_month ? this.editFormData.itchy_skin_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "itchy_skin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.itchy_skin_today ? this.editFormData.itchy_skin_today : false,
          group: "grp7",
        },

        // ========== Dark urine color ======== //
        {
          heading: "Dark urine color",
          group: "grp7",
          name: 'darkUrineColor'
        },

        {
          label: "6 months",
          name: "dark_urine_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_six_months ? this.editFormData.dark_urine_color_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "dark_urine_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_last_month ? this.editFormData.dark_urine_color_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "dark_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_urine_color_today ? this.editFormData.dark_urine_color_today : false,
          group: "grp7",
        },

        // ========== Pale stool color ======== //
        {
          heading: "Pale stool color",
          group: "grp7",
          name: 'paleStoolColor'
        },

        {
          label: "6 months",
          name: "pale_stool_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_six_months ? this.editFormData.pale_stool_color_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pale_stool_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_last_month ? this.editFormData.pale_stool_color_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pale_stool_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pale_stool_color_today ? this.editFormData.pale_stool_color_today : false,
          group: "grp7",
        },

        // ========== Chronic fatigue ======== //
        {
          heading: "Chronic fatigue",
          group: "grp7",
          name: 'chronicFatigue'
        },

        {
          label: "6 months",
          name: "chronic_fatigue_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_six_months ? this.editFormData.chronic_fatigue_six_months : false,
          group: "grp7",
        },

        {
          label: "Last month",
          name: "chronic_fatigue_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_last_month ? this.editFormData.chronic_fatigue_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chronic_fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chronic_fatigue_today ? this.editFormData.chronic_fatigue_today : false,
          group: "grp7",
        },

        // ========== Nausea or vomiting ======== //
        {
          heading: "Nausea or vomiting",
          group: "grp7",
          name: 'nauseaOrVomiting'
        },

        {
          label: "6 months",
          name: "vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_six_months ? this.editFormData.vomiting_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_last_month ? this.editFormData.vomiting_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.vomiting_today ? this.editFormData.vomiting_today : false,
          group: "grp7",
        },

        // ========== Loss of appetite ======== //
        {
          heading: "Loss of appetite",
          group: "grp7",
          name: 'lossAppetite'
        },

        {
          label: "6 months",
          name: "lossofappetite_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_six_months ? this.editFormData.lossofappetite_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "lossofappetite_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_last_month ? this.editFormData.lossofappetite_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "lossofappetite_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lossofappetite_today ? this.editFormData.lossofappetite_today : false,
          group: "grp7",
        },

        // ========== Tendency to bruise easily ======== //
        {
          heading: "Tendency to bruise easily",
          group: "grp7",
          name: 'tendencyEasily'
        },

        {
          label: "6 months",
          name: "bruise_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_six_months ? this.editFormData.bruise_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "bruise_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_last_month ? this.editFormData.bruise_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "bruise_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bruise_today ? this.editFormData.bruise_today : false,
          group: "grp7",
        },

        // ========== Fever ======== //
        {
          heading: "Fever",
          group: "grp7",
          name: 'fever'
        },

        {
          label: "6 months",
          name: "fever_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_six_months ? this.editFormData.fever_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "fever_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_last_month ? this.editFormData.fever_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "fever_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fever_today ? this.editFormData.fever_today : false,
          group: "grp7",
        },

        // ========== Insomnia ======== //
        {
          heading: "Insomnia",
          group: "grp7",
          name: 'insomnia'
        },

        {
          label: "6 months",
          name: "insomnia_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_six_months ? this.editFormData.insomnia_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "insomnia_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_last_month ? this.editFormData.insomnia_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "insomnia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.insomnia_today ? this.editFormData.insomnia_today : false,
          group: "grp7",
        },

        // ========== Urinating less ======== //
        {
          heading: "Urinating less",
          group: "grp7",
          name: 'urinatingLess'
        },

        {
          label: "6 months",
          name: "urinating_less_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_six_months ? this.editFormData.urinating_less_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "urinating_less_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_last_month ? this.editFormData.urinating_less_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "urinating_less_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinating_less_today ? this.editFormData.urinating_less_today : false,
          group: "grp7",
        },

        // ========== Muscle cramps ======== //
        {
          heading: "Muscle cramps",
          group: "grp7",
          name: 'muscleCramps'
        },

        {
          label: "6 months",
          name: "muscle_cramps_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_six_months ? this.editFormData.muscle_cramps_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "muscle_cramps_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_last_month ? this.editFormData.muscle_cramps_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "muscle_cramps_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.muscle_cramps_today ? this.editFormData.muscle_cramps_today : false,
          group: "grp7",
        },


        // ========== Swelling of feet and ankles ======== //
        {
          heading: "Swelling of feet and ankles",
          group: "grp7",
          name: 'swellingFeetAnkles'
        },

        {
          label: "6 months",
          name: "swelling_feet_ankles_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_six_months ? this.editFormData.swelling_feet_ankles_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swelling_feet_ankles_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_last_month ? this.editFormData.swelling_feet_ankles_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swelling_feet_ankles_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_feet_ankles_today ? this.editFormData.swelling_feet_ankles_today : false,
          group: "grp7",
        },


        // ========== Shortness of breath ======== //
        {
          heading: "Shortness of breath",
          group: "grp7",
          name: 'shortnessBreath'
        },

        {
          label: "6 months",
          name: "shortness_of_breath_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_six_months ? this.editFormData.shortness_of_breath_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "shortness_of_breath_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_last_month ? this.editFormData.shortness_of_breath_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "shortness_of_breath_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.shortness_of_breath_today ? this.editFormData.shortness_of_breath_today : false,
          group: "grp7",
        },
        // ========== Chest pain ======== //
        {
          heading: "Chest pain",
          group: "grp7",
          name: 'chestPain'
        },

        {
          label: "6 months",
          name: "chest_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_six_months ? this.editFormData.chest_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chest_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_last_month ? this.editFormData.chest_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chest_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_today ? this.editFormData.chest_pain_today : false,
          group: "grp7",
        },// ========== Back Pain ======== //
        {
          heading: "Back Pain",
          group: "grp7",
          name: 'backPain'
        },

        {
          label: "6 months",
          name: "back_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_six_months ? this.editFormData.back_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "back_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_last_month ? this.editFormData.back_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "back_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.back_pain_today ? this.editFormData.back_pain_today : false,
          group: "grp7",
        },// ========== Frequent urination ======== //
        {
          heading: "Frequent urination",
          group: "grp7",
          name: 'frequentUrination'
        },

        {
          label: "6 months",
          name: "frequent_urination_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_six_months ? this.editFormData.frequent_urination_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "frequent_urination_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_last_month ? this.editFormData.frequent_urination_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "frequent_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_urination_today ? this.editFormData.frequent_urination_today : false,
          group: "grp7",
        },// ========== Foamy urine ======== //
        {
          heading: "Foamy urine",
          group: "grp7",
          name: 'foamyUrine'
        },

        {
          label: "6 months",
          name: "foamy_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_six_months ? this.editFormData.foamy_urine_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "foamy_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_last_month ? this.editFormData.foamy_urine_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "foamy_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.foamy_urine_today ? this.editFormData.foamy_urine_today : false,
          group: "grp7",
        },// ========== Blood in your urine ======== //
        {
          heading: "Blood in your urine",
          group: "grp7",
          name: 'bloodUrine'
        },

        {
          label: "6 months",
          name: "blood_in_your_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_six_months ? this.editFormData.blood_in_your_urine_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "blood_in_your_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_last_month ? this.editFormData.blood_in_your_urine_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "blood_in_your_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_in_your_urine_today ? this.editFormData.blood_in_your_urine_today : false,
          group: "grp7",
        },
        // ========== Dizziness ======== //
        {
          heading: "Dizziness",
          group: "grp7",
          name: 'dizziness'
        },

        {
          label: "6 months",
          name: "dizziness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_six_months ? this.editFormData.dizziness_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "dizziness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_last_month ? this.editFormData.dizziness_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "dizziness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_today ? this.editFormData.dizziness_today : false,
          group: "grp7",
        },// ========== Swelling around your eyes ======== //
        {
          heading: "Swelling around your eyes",
          group: "grp7",
          name: 'swellingAroundEyes'
        },

        {
          label: "6 months",
          name: "swelling_around_your_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_six_months ? this.editFormData.swelling_around_your_eyes_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swelling_around_your_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_last_month ? this.editFormData.swelling_around_your_eyes_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swelling_around_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_around_your_eyes_today ? this.editFormData.swelling_around_your_eyes_today : false,
          group: "grp7",
        },// ========== High blood sugar ======== //
        {
          heading: "High blood sugar",
          group: "grp7",
          name: 'highBloodSugar'
        },

        {
          label: "6 months",
          name: "high_blood_sugar_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_six_months ? this.editFormData.high_blood_sugar_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "high_blood_sugar_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_last_month ? this.editFormData.high_blood_sugar_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "high_blood_sugar_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_blood_sugar_today ? this.editFormData.high_blood_sugar_today : false,
          group: "grp7",
        },
        // ========== Weight loss ======== //
        {
          heading: "Weight loss",
          group: "grp7",
          name: 'weightLoss'
        },

        {
          label: "6 months",
          name: "weight_loss_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_six_months ? this.editFormData.weight_loss_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "weight_loss_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_last_month ? this.editFormData.weight_loss_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "weight_loss_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weight_loss_today ? this.editFormData.weight_loss_today : false,
          group: "grp7",
        },
        // ========== Joint pain ======== //
        {
          heading: "Joint pain",
          group: "grp7",
          name: 'jointPain'
        },

        {
          label: "6 months",
          name: "joint_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_six_months ? this.editFormData.joint_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "joint_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_last_month ? this.editFormData.joint_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "joint_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_pain_today ? this.editFormData.joint_pain_today : false,
          group: "grp7",
        },// ========== Joint Inflammation ======== //
        {
          heading: "Joint Inflammation",
          group: "grp7",
          name: 'jointInflammation'
        },

        {
          label: "6 months",
          name: "joint_inflammation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_six_months ? this.editFormData.joint_inflammation_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "joint_inflammation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_last_month ? this.editFormData.joint_inflammation_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "joint_inflammation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.joint_inflammation_today ? this.editFormData.joint_inflammation_today : false,
          group: "grp7",
        },
        // ========== Limited range of motion ======== //
        {
          heading: "Limited range of motion",
          group: "grp7",
          name: 'limitedRangeMotion'
        },

        {
          label: "6 months",
          name: "limited_range_motion_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_six_months ? this.editFormData.limited_range_motion_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "limited_range_motion_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_last_month ? this.editFormData.limited_range_motion_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "limited_range_motion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.limited_range_motion_today ? this.editFormData.limited_range_motion_today : false,
          group: "grp7",
        },
        // ========== Coughing, especially at night ======== //
        {
          heading: "Coughing, especially at night",
          group: "grp7",
          name: 'coughingEspeciallyNight'
        },

        {
          label: "6 months",
          name: "coughing_especially_at_night_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_six_months ? this.editFormData.coughing_especially_at_night_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "coughing_especially_at_night_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_last_month ? this.editFormData.coughing_especially_at_night_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "coughing_especially_at_night_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_especially_at_night_today ? this.editFormData.coughing_especially_at_night_today : false,
          group: "grp7",
        },
        // ========== Wheezing or squeaking when you breathe ======== //
        {
          heading: "Wheezing or squeaking when you breathe",
          group: "grp7",
          name: 'wheezingSqueakingBreathe'
        },

        {
          label: "6 months",
          name: "wheezing_squeaking_when_you_breathe_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_six_months ? this.editFormData.wheezing_squeaking_when_you_breathe_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "wheezing_squeaking_when_you_breathe_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_last_month ? this.editFormData.wheezing_squeaking_when_you_breathe_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "wheezing_squeaking_when_you_breathe_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.wheezing_squeaking_when_you_breathe_today ? this.editFormData.wheezing_squeaking_when_you_breathe_today : false,
          group: "grp7",
        },
        // ========== Chest tightness or pain ======== //
        {
          heading: "Chest tightness or pain",
          group: "grp7",
          name: 'chestTightnessPain'
        },

        {
          label: "6 months",
          name: "chest_tightness_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_six_months ? this.editFormData.chest_tightness_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chest_tightness_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_last_month ? this.editFormData.chest_tightness_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chest_tightness_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_tightness_pain_today ? this.editFormData.chest_tightness_pain_today : false,
          group: "grp7",
        },
        // ========== Low energy ======== //
        {
          heading: "Low energy",
          group: "grp7",
          name: 'lowEnergy'
        },

        {
          label: "6 months",
          name: "low_energy_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_six_months ? this.editFormData.low_energy_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "low_energy_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_last_month ? this.editFormData.low_energy_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "low_energy_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.low_energy_today ? this.editFormData.low_energy_today : false,
          group: "grp7",
        },
        // ========== Blue fingernails ======== //
        {
          heading: "Blue fingernails",
          group: "grp7",
          name: 'blueFingernails'
        },

        {
          label: "6 months",
          name: "blue_fingernails_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_six_months ? this.editFormData.blue_fingernails_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "blue_fingernails_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_last_month ? this.editFormData.blue_fingernails_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "blue_fingernails_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blue_fingernails_today ? this.editFormData.blue_fingernails_today : false,
          group: "grp7",
        },
        // ========== Frequent colds or flu ======== //
        {
          heading: "Frequent colds or flu",
          group: "grp7",
          name: 'frequentColdsFlu'
        },

        {
          label: "6 months",
          name: "frequent_colds_flu_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_six_months ? this.editFormData.frequent_colds_flu_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "frequent_colds_flu_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_last_month ? this.editFormData.frequent_colds_flu_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "frequent_colds_flu_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.frequent_colds_flu_today ? this.editFormData.frequent_colds_flu_today : false,
          group: "grp7",
        },
        // ========== Coughing up lots of mucus ======== //
        {
          heading: "Coughing up lots of mucus",
          group: "grp7",
          name: 'coughingLotsMucus'
        },

        {
          label: "6 months",
          name: "coughing_up_lots_mucus_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_six_months ? this.editFormData.coughing_up_lots_mucus_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "coughing_up_lots_mucus_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_last_month ? this.editFormData.coughing_up_lots_mucus_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "coughing_up_lots_mucus_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_up_lots_mucus_today ? this.editFormData.coughing_up_lots_mucus_today : false,
          group: "grp7",
        },
        // ========== A cough that doesn't go away ======== //
        {
          heading: "A cough that doesn't go away",
          group: "grp7",
          name: 'coughDoesnotAway'
        },

        {
          label: "6 months",
          name: "cough_that_doesnt_go_away_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_six_months ? this.editFormData.cough_that_doesnt_go_away_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "cough_that_doesnt_go_away_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_last_month ? this.editFormData.cough_that_doesnt_go_away_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "cough_that_doesnt_go_away_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_that_doesnt_go_away_today ? this.editFormData.cough_that_doesnt_go_away_today : false,
          group: "grp7",
        },
        // ========== Depression or anxiety ======== //
        {
          heading: "Depression or anxiety",
          group: "grp7",
          name: 'depressionAnxiety'
        },

        {
          label: "6 months",
          name: "depression_anxiety_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_six_months ? this.editFormData.depression_anxiety_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "depression_anxiety_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_last_month ? this.editFormData.depression_anxiety_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "depression_anxiety_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.depression_anxiety_today ? this.editFormData.depression_anxiety_today : false,
          group: "grp7",
        },
        // ========== Restlessness while sleeping ======== //
        {
          heading: "Restlessness while sleeping",
          group: "grp7",
          name: 'restlessnessSleeping'
        },

        {
          label: "6 months",
          name: "restlessness_while_sleeping_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_six_months ? this.editFormData.restlessness_while_sleeping_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "restlessness_while_sleeping_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_last_month ? this.editFormData.restlessness_while_sleeping_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "restlessness_while_sleeping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.restlessness_while_sleeping_today ? this.editFormData.restlessness_while_sleeping_today : false,
          group: "grp7",
        },
        // ========== Fatigue ======== //
        {
          heading: "Fatigue",
          group: "grp7",
          name: 'fatigue'
        },

        {
          label: "6 months",
          name: "fatigue_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_six_months ? this.editFormData.fatigue_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "fatigue_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_last_month ? this.editFormData.fatigue_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "fatigue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fatigue_today ? this.editFormData.fatigue_today : false,
          group: "grp7",
        },
        // ========== Nausea and vomiting ======== //
        {
          heading: "Nausea and vomiting",
          group: "grp7",
          name: 'nauseaAndVomoting'
        },

        {
          label: "6 months",
          name: "nausea_vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_six_months ? this.editFormData.nausea_vomiting_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "nausea_vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_last_month ? this.editFormData.nausea_vomiting_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "nausea_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_vomiting_today ? this.editFormData.nausea_vomiting_today : false,
          group: "grp7",
        },
        // ========== Arrhythmia ======== //
        {
          heading: "Arrhythmia: Irregular heartbeat rhythm",
          group: "grp7",
          name: 'arrhythmiaHeartbeatRhythm'
        },

        {
          label: "6 months",
          name: "arrhythmia_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_six_months ? this.editFormData.arrhythmia_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "arrhythmia_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_last_month ? this.editFormData.arrhythmia_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "arrhythmia_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.arrhythmia_today ? this.editFormData.arrhythmia_today : false,
          group: "grp7",
        },
        // ========== Confusion ======== //
        {
          heading: "Confusion",
          group: "grp7",
          name: 'confusion'
        },

        {
          label: "6 months",
          name: "confusion_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_six_months ? this.editFormData.confusion_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "confusion_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_last_month ? this.editFormData.confusion_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "confusion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.confusion_today ? this.editFormData.confusion_today : false,
          group: "grp7",
        },
        // ========== Blurred vision ======== //
        {
          heading: "Blurred vision",
          group: "grp7",
          name: 'blurredVision'
        },

        {
          label: "6 months",
          name: "blurred_vision_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_six_months ? this.editFormData.blurred_vision_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "blurred_vision_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_last_month ? this.editFormData.blurred_vision_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "blurred_vision_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blurred_vision_today ? this.editFormData.blurred_vision_today : false,
          group: "grp7",
        },
        // ========== Extreme thirst ======== //
        {
          heading: "Extreme thirst",
          group: "grp7",
          name: 'extremeThirst'
        },

        {
          label: "6 months",
          name: "extreme_thirst_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_six_months ? this.editFormData.extreme_thirst_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "extreme_thirst_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_last_month ? this.editFormData.extreme_thirst_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "extreme_thirst_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_thirst_today ? this.editFormData.extreme_thirst_today : false,
          group: "grp7",
        },
        // ========== Extreme Hunger ======== //
        {
          heading: "Extreme Hunger",
          group: "grp7",
          name: 'extremeHunger'
        },

        {
          label: "6 months",
          name: "extreme_hunger_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_six_months ? this.editFormData.extreme_hunger_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "extreme_hunger_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_last_month ? this.editFormData.extreme_hunger_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "extreme_hunger_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.extreme_hunger_today ? this.editFormData.extreme_hunger_today : false,
          group: "grp7",
        },
        // ========== Painful urination ======== //
        {
          heading: "Painful urination",
          group: "grp7",
          name: 'painfulUrination'
        },

        {
          label: "6 months",
          name: "painful_urination_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_six_months ? this.editFormData.painful_urination_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "painful_urination_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_last_month ? this.editFormData.painful_urination_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "painful_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_urination_today ? this.editFormData.painful_urination_today : false,
          group: "grp7",
        },
        // ========== Numb or tingling feet ======== //
        {
          heading: "Numb or tingling feet",
          group: "grp7",
          name: 'numbTinglingFeet'
        },

        {
          label: "6 months",
          name: "numb_or_tingling_feet_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_six_months ? this.editFormData.numb_or_tingling_feet_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "numb_or_tingling_feet_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_last_month ? this.editFormData.numb_or_tingling_feet_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "numb_or_tingling_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.numb_or_tingling_feet_today ? this.editFormData.numb_or_tingling_feet_today : false,
          group: "grp7",
        },
        // ========== Sexual Difficulties ======== //
        {
          heading: "Sexual Difficulties",
          group: "grp7",
          name: 'sexualDifficulties'
        },

        {
          label: "6 months",
          name: "sexual_difficulties_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_six_months ? this.editFormData.sexual_difficulties_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "sexual_difficulties_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_last_month ? this.editFormData.sexual_difficulties_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "sexual_difficulties_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sexual_difficulties_today ? this.editFormData.sexual_difficulties_today : false,
          group: "grp7",
        },
        // ========== Urinary problems ======== //
        {
          heading: "Urinary problems",
          group: "grp7",
          name: 'urinaryProblems'
        },

        {
          label: "6 months",
          name: "urinary_problems_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_six_months ? this.editFormData.urinary_problems_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "urinary_problems_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_last_month ? this.editFormData.urinary_problems_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "urinary_problems_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urinary_problems_today ? this.editFormData.urinary_problems_today : false,
          group: "grp7",
        },
        // ========== Tingling Hands & Feet ======== //
        {
          heading: "Tingling Hands & Feet",
          group: "grp7",
          name: 'tinglingHandsFeet'
        },

        {
          label: "6 months",
          name: "tingling_hands_feet_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_six_months ? this.editFormData.tingling_hands_feet_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "tingling_hands_feet_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_last_month ? this.editFormData.tingling_hands_feet_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "tingling_hands_feet_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tingling_hands_feet_today ? this.editFormData.tingling_hands_feet_today : false,
          group: "grp7",
        },
        // ========== Burning Sensation ======== //
        {
          heading: "Burning Sensation",
          group: "grp7",
          name: 'burningSensation'
        },

        {
          label: "6 months",
          name: "burning_sensation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_six_months ? this.editFormData.burning_sensation_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "burning_sensation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_last_month ? this.editFormData.burning_sensation_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "burning_sensation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_sensation_today ? this.editFormData.burning_sensation_today : false,
          group: "grp7",
        },
        // ========== Angina ======== //
        {
          heading: "Angina (Chest pain that goes away with rest)",
          group: "grp7",
          name: 'angina'
        },

        {
          label: "6 months",
          name: "angina_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_six_months ? this.editFormData.angina_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "angina_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_last_month ? this.editFormData.angina_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "angina_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.angina_today ? this.editFormData.angina_today : false,
          group: "grp7",
        },
        // ========== Chest Pain That Goes Away With Rest ======== //
        {
          heading: "Chest Pain That Goes Away With Rest",
          group: "grp7",
          name: 'chestPainWithRest'
        },

        {
          label: "6 months",
          name: "chest_pain_that_goesaway_with_rest_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_six_months ? this.editFormData.chest_pain_that_goesaway_with_rest_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chest_pain_that_goesaway_with_rest_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_last_month ? this.editFormData.chest_pain_that_goesaway_with_rest_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chest_pain_that_goesaway_with_rest_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_that_goesaway_with_rest_today ? this.editFormData.chest_pain_that_goesaway_with_rest_today : false,
          group: "grp7",
        },
        // ========== Heartburn ======== //
        {
          heading: "Heartburn",
          group: "grp7",
          name: 'heartburn'
        },

        {
          label: "6 months",
          name: "heartburn_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_six_months ? this.editFormData.heartburn_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "heartburn_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_last_month ? this.editFormData.heartburn_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "heartburn_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heartburn_today ? this.editFormData.heartburn_today : false,
          group: "grp7",
        },
        // ========== Pain In Calves ======== //
        {
          heading: "Pain In Calves",
          group: "grp7",
          name: 'painInCalves'
        },

        {
          label: "6 months",
          name: "pain_in_calves_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_six_months ? this.editFormData.pain_in_calves_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pain_in_calves_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_last_month ? this.editFormData.pain_in_calves_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pain_in_calves_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_calves_today ? this.editFormData.pain_in_calves_today : false,
          group: "grp7",
        },
        // ========== Dizziness Or Lightheadedness ======== //
        {
          heading: "Dizziness Or Lightheadedness",
          group: "grp7",
          name: 'dizzinessOrLightheadedness'
        },

        {
          label: "6 months",
          name: "dizziness_lightheadedness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_six_months ? this.editFormData.dizziness_lightheadedness_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "dizziness_lightheadedness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_last_month ? this.editFormData.dizziness_lightheadedness_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "dizziness_lightheadedness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dizziness_lightheadedness_today ? this.editFormData.dizziness_lightheadedness_today : false,
          group: "grp7",
        },
        // ========== Irregular Heartbeat, Too Fast/Slow ======== //
        {
          heading: "Irregular Heartbeat, Too Fast/Slow",
          group: "grp7",
          name: 'irregularHeartbeatToo'
        },

        {
          label: "6 months",
          name: "irregular_heartbeat_toofastslow_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_six_months ? this.editFormData.irregular_heartbeat_toofastslow_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "irregular_heartbeat_toofastslow_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_last_month ? this.editFormData.irregular_heartbeat_toofastslow_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "irregular_heartbeat_toofastslow_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.irregular_heartbeat_toofastslow_today ? this.editFormData.irregular_heartbeat_toofastslow_today : false,
          group: "grp7",
        },
        // ========== Heart Attack ======== //
        {
          heading: "Heart Attack",
          group: "grp7",
          name: 'heartAttack'
        },

        {
          label: "6 months",
          name: "heart_attack_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_six_months ? this.editFormData.heart_attack_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "heart_attack_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_last_month ? this.editFormData.heart_attack_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "heart_attack_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.heart_attack_today ? this.editFormData.heart_attack_today : false,
          group: "grp7",
        },
        // ========== Stroke ======== //
        {
          heading: "Stroke",
          group: "grp7",
          name: 'stroke'
        },

        {
          label: "6 months",
          name: "stroke_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_six_months ? this.editFormData.stroke_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "stroke_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_last_month ? this.editFormData.stroke_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stroke_today ? this.editFormData.stroke_today : false,
          group: "grp7",
        },
        // ========== Blood Clot In A Vein (Venous Thrombosis) ======== //
        {
          heading: "Blood Clot In A Vein (Venous Thrombosis)",
          group: "grp7",
          name: 'bloodClotInVein'
        },

        {
          label: "6 months",
          name: "blood_clot_inavein_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_six_months ? this.editFormData.blood_clot_inavein_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "blood_clot_inavein_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_last_month ? this.editFormData.blood_clot_inavein_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "blood_clot_inavein_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_clot_inavein_today ? this.editFormData.blood_clot_inavein_today : false,
          group: "grp7",
        },
        // ========== Tia (Mini Stroke) ======== //
        {
          heading: "Tia (Mini Stroke)",
          group: "grp7",
          name: 'tiaMiniStroke'
        },

        {
          label: "6 months",
          name: "tia_mini_stroke_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_six_months ? this.editFormData.tia_mini_stroke_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "tia_mini_stroke_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_last_month ? this.editFormData.tia_mini_stroke_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "tia_mini_stroke_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tia_mini_stroke_today ? this.editFormData.tia_mini_stroke_today : false,
          group: "grp7",
        },
        // ========== Chest Pain Or Discomfort ======== //
        {
          heading: "Chest Pain Or Discomfort",
          group: "grp7",
          name: 'chestPainOrDiscomfort'
        },

        {
          label: "6 months",
          name: "chest_painor_discomfort_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_six_months ? this.editFormData.chest_painor_discomfort_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chest_painor_discomfort_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_last_month ? this.editFormData.chest_painor_discomfort_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chest_painor_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_painor_discomfort_today ? this.editFormData.chest_painor_discomfort_today : false,
          group: "grp7",
        },
        // ========== Fainting ======== //
        {
          heading: "Fainting",
          group: "grp7",
          name: 'fainting'
        },

        {
          label: "6 months",
          name: "fainting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_six_months ? this.editFormData.fainting_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "fainting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_last_month ? this.editFormData.fainting_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "fainting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.fainting_today ? this.editFormData.fainting_today : false,
          group: "grp7",
        },
        // ========== Weakness ======== //
        {
          heading: "Weakness",
          group: "grp7",
          name: 'weakness'
        },

        {
          label: "6 months",
          name: "weakness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_six_months ? this.editFormData.weakness_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "weakness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_last_month ? this.editFormData.weakness_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "weakness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weakness_today ? this.editFormData.weakness_today : false,
          group: "grp7",
        },
        // ========== Lingering discomfort ======== //
        {
          heading: "Lingering discomfort",
          group: "grp7",
          name: 'lingeringDiscomfort'
        },

        {
          label: "6 months",
          name: "lingering_discomfort_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_six_months ? this.editFormData.lingering_discomfort_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "lingering_discomfort_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_last_month ? this.editFormData.lingering_discomfort_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "lingering_discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lingering_discomfort_today ? this.editFormData.lingering_discomfort_today : false,
          group: "grp7",
        },
        // ========== Pain in affected joint ======== //
        {
          heading: "Pain in affected joint",
          group: "grp7",
          name: 'painAffectedJoint'
        },

        {
          label: "6 months",
          name: "pain_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_six_months ? this.editFormData.pain_in_affected_joint_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pain_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_last_month ? this.editFormData.pain_in_affected_joint_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pain_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_in_affected_joint_today ? this.editFormData.pain_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Swelling in affected joint ======== //
        {
          heading: "Swelling in affected joint",
          group: "grp7",
          name: 'swellingAffectedJoint'
        },

        {
          label: "6 months",
          name: "swelling_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_six_months ? this.editFormData.swelling_in_affected_joint_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swelling_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_last_month ? this.editFormData.swelling_in_affected_joint_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swelling_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swelling_in_affected_joint_today ? this.editFormData.swelling_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Redness in affected joint ======== //
        {
          heading: "Redness in affected joint",
          group: "grp7",
          name: 'rednessAffectedJoint'
        },

        {
          label: "6 months",
          name: "redness_in_affected_joint_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_six_months ? this.editFormData.redness_in_affected_joint_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "redness_in_affected_joint_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_last_month ? this.editFormData.redness_in_affected_joint_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "redness_in_affected_joint_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_in_affected_joint_today ? this.editFormData.redness_in_affected_joint_today : false,
          group: "grp7",
        },
        // ========== Chills ======== //
        {
          heading: "Chills",
          group: "grp7",
          name: 'chills'
        },

        {
          label: "6 months",
          name: "chills_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_six_months ? this.editFormData.chills_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chills_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_last_month ? this.editFormData.chills_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chills_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chills_today ? this.editFormData.chills_today : false,
          group: "grp7",
        },
        // ========== Cough ======== //
        {
          heading: "Cough",
          group: "grp7",
          name: 'cough'
        },

        {
          label: "6 months",
          name: "cough_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_six_months ? this.editFormData.cough_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "cough_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_last_month ? this.editFormData.cough_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "cough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cough_today ? this.editFormData.cough_today : false,
          group: "grp7",
        },
        // ========== Chest pain when you breathe or cough ======== //
        {
          heading: "Chest pain when you breathe or cough",
          group: "grp7",
          name: 'chestPainBreathe'
        },

        {
          label: "6 months",
          name: "chest_pain_when_you_breatheorcough_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_six_months ? this.editFormData.chest_pain_when_you_breatheorcough_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "chest_pain_when_you_breatheorcough_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_last_month ? this.editFormData.chest_pain_when_you_breatheorcough_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "chest_pain_when_you_breatheorcough_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.chest_pain_when_you_breatheorcough_today ? this.editFormData.chest_pain_when_you_breatheorcough_today : false,
          group: "grp7",
        },
        // ========== Nausea ======== //
        {
          heading: "Nausea",
          group: "grp7",
          name: 'nausea'
        },

        {
          label: "6 months",
          name: "nausea_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_six_months ? this.editFormData.nausea_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "nausea_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_last_month ? this.editFormData.nausea_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "nausea_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_today ? this.editFormData.nausea_today : false,
          group: "grp7",
        },
        // ========== Pain or burning while urinating ======== //
        {
          heading: "Pain or burning while urinating",
          group: "grp7",
          name: 'painBurningUrinating'
        },

        {
          label: "6 months",
          name: "painorburning_while_urinating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_six_months ? this.editFormData.painorburning_while_urinating_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "painorburning_while_urinating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_last_month ? this.editFormData.painorburning_while_urinating_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "painorburning_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painorburning_while_urinating_today ? this.editFormData.painorburning_while_urinating_today : false,
          group: "grp7",
        },
        // ========== Bloody urine ======== //
        {
          heading: "Bloody urine",
          group: "grp7",
          name: 'bloodyUrine'
        },

        {
          label: "6 months",
          name: "bloody_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_six_months ? this.editFormData.bloody_urine_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "bloody_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_last_month ? this.editFormData.bloody_urine_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "bloody_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloody_urine_today ? this.editFormData.bloody_urine_today : false,
          group: "grp7",
        },
        // ========== Runny nose ======== //
        {
          heading: "Runny nose",
          group: "grp7",
          name: 'runnyNose'
        },

        {
          label: "6 months",
          name: "runny_nose_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_six_months ? this.editFormData.runny_nose_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "runny_nose_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_last_month ? this.editFormData.runny_nose_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "runny_nose_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.runny_nose_today ? this.editFormData.runny_nose_today : false,
          group: "grp7",
        },
        // ========== Sore throat ======== //
        {
          heading: "Sore throat",
          group: "grp7",
          name: 'soreThroat'
        },

        {
          label: "6 months",
          name: "sore_throat_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_six_months ? this.editFormData.sore_throat_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "sore_throat_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_last_month ? this.editFormData.sore_throat_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "sore_throat_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sore_throat_today ? this.editFormData.sore_throat_today : false,
          group: "grp7",
        },
        // ========== Swollen glands ======== //
        {
          heading: "Swollen glands",
          group: "grp7",
          name: 'swollenGlands'
        },

        {
          label: "6 months",
          name: "swollen_glands_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_six_months ? this.editFormData.swollen_glands_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swollen_glands_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_last_month ? this.editFormData.swollen_glands_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swollen_glands_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_glands_today ? this.editFormData.swollen_glands_today : false,
          group: "grp7",
        },
        // ========== Trouble breathing ======== //
        {
          heading: "Trouble breathing",
          group: "grp7",
          name: 'troubleBreathing'
        },

        {
          label: "6 months",
          name: "trouble_breathing_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_six_months ? this.editFormData.trouble_breathing_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "trouble_breathing_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_last_month ? this.editFormData.trouble_breathing_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "trouble_breathing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_breathing_today ? this.editFormData.trouble_breathing_today : false,
          group: "grp7",
        },
        // ========== High-pitched sound when breathing in ======== //
        {
          heading: "High-pitched sound when breathing in",
          group: "grp7",
          name: 'highPitchedBreathing'
        },

        {
          label: "6 months",
          name: "high_pitched_sound_when_breathing_in_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_six_months ? this.editFormData.high_pitched_sound_when_breathing_in_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "high_pitched_sound_when_breathing_in_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_last_month ? this.editFormData.high_pitched_sound_when_breathing_in_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "high_pitched_sound_when_breathing_in_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.high_pitched_sound_when_breathing_in_today ? this.editFormData.high_pitched_sound_when_breathing_in_today : false,
          group: "grp7",
        },
        // ========== Burning and pain while urinating ======== //
        {
          heading: "Burning and pain while urinating",
          group: "grp7",
          name: 'burningPainUrinating'
        },

        {
          label: "6 months",
          name: "burning_and_pain_while_urinating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_six_months ? this.editFormData.burning_and_pain_while_urinating_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "burning_and_pain_while_urinating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_last_month ? this.editFormData.burning_and_pain_while_urinating_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "burning_and_pain_while_urinating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.burning_and_pain_while_urinating_today ? this.editFormData.burning_and_pain_while_urinating_today : false,
          group: "grp7",
        },

        // ========== Red eyes ======== //
        {
          heading: "Red eyes",
          group: "grp7",
          name: 'redEyes'
        },

        {
          label: "6 months",
          name: "red_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_six_months ? this.editFormData.red_eyes_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "red_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_last_month ? this.editFormData.red_eyes_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "red_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.red_eyes_today ? this.editFormData.red_eyes_today : false,
          group: "grp7",
        },
        // ========== Discharge from your eyes ======== //
        {
          heading: "Discharge from your eyes",
          group: "grp7",
          name: 'dischargeYourEyes'
        },

        {
          label: "6 months",
          name: "discharge_from_your_eyes_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_six_months ? this.editFormData.discharge_from_your_eyes_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "discharge_from_your_eyes_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_last_month ? this.editFormData.discharge_from_your_eyes_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "discharge_from_your_eyes_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discharge_from_your_eyes_today ? this.editFormData.discharge_from_your_eyes_today : false,
          group: "grp7",
        },
        // ========== Abdominal pain ======== //
        {
          heading: "Abdominal pain",
          group: "grp7",
          name: 'abdominalPain'
        },

        {
          label: "6 months",
          name: "abdominal_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_six_months ? this.editFormData.abdominal_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "abdominal_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_last_month ? this.editFormData.abdominal_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "abdominal_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_pain_today ? this.editFormData.abdominal_pain_today : false,
          group: "grp7",
        },
        // ========== Change in urine color ======== //
        {
          heading: "Change in urine color",
          group: "grp7",
          name: 'changeUrineColor'
        },

        {
          label: "6 months",
          name: "change_in_urine_color_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_six_months ? this.editFormData.change_in_urine_color_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "change_in_urine_color_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_last_month ? this.editFormData.change_in_urine_color_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "change_in_urine_color_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_in_urine_color_today ? this.editFormData.change_in_urine_color_today : false,
          group: "grp7",
        },
        // ========== Painful ejaculation ======== //
        {
          heading: "Painful ejaculation",
          group: "grp7",
          name: 'painfulEjaculation'
        },

        {
          label: "6 months",
          name: "painful_ejaculation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_six_months ? this.editFormData.painful_ejaculation_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "painful_ejaculation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_last_month ? this.editFormData.painful_ejaculation_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "painful_ejaculation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.painful_ejaculation_today ? this.editFormData.painful_ejaculation_today : false,
          group: "grp7",
        },
        // ========== A missed period ======== //
        {
          heading: "A missed period",
          group: "grp7",
          name: 'missedPeriod'
        },

        {
          label: "6 months",
          name: "amissed_period_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_six_months ? this.editFormData.amissed_period_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "amissed_period_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_last_month ? this.editFormData.amissed_period_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "amissed_period_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.amissed_period_today ? this.editFormData.amissed_period_today : false,
          group: "grp7",
        },
        // ========== Swollen or tender breasts ======== //
        {
          heading: "Swollen or tender breasts",
          group: "grp7",
          name: 'swollenTenderBreasts'
        },

        {
          label: "6 months",
          name: "swollenortender_breasts_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_six_months ? this.editFormData.swollenortender_breasts_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swollenortender_breasts_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_last_month ? this.editFormData.swollenortender_breasts_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swollenortender_breasts_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollenortender_breasts_today ? this.editFormData.swollenortender_breasts_today : false,
          group: "grp7",
        },
        // ========== Nausea, with or without vomiting ======== //
        {
          heading: "Nausea, with or without vomiting",
          group: "grp7",
          name: 'nauseaWithVomiting'
        },

        {
          label: "6 months",
          name: "nausea_withorwithout_vomiting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_six_months ? this.editFormData.nausea_withorwithout_vomiting_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "nausea_withorwithout_vomiting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_last_month ? this.editFormData.nausea_withorwithout_vomiting_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "nausea_withorwithout_vomiting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nausea_withorwithout_vomiting_today ? this.editFormData.nausea_withorwithout_vomiting_today : false,
          group: "grp7",
        },
        // ========== Light spotting ======== //
        {
          heading: "Light spotting",
          group: "grp7",
          name: 'lightSpotting'
        },

        {
          label: "6 months",
          name: "light_spotting_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_six_months ? this.editFormData.light_spotting_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "light_spotting_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_last_month ? this.editFormData.light_spotting_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "light_spotting_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.light_spotting_today ? this.editFormData.light_spotting_today : false,
          group: "grp7",
        },
        // ========== Cramping ======== //
        {
          heading: "Cramping",
          group: "grp7",
          name: 'cramping'
        },

        {
          label: "6 months",
          name: "cramping_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_six_months ? this.editFormData.cramping_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "cramping_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_last_month ? this.editFormData.cramping_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "cramping_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.cramping_today ? this.editFormData.cramping_today : false,
          group: "grp7",
        },
        // ========== Bloating ======== //
        {
          heading: "Bloating",
          group: "grp7",
          name: 'bloating'
        },

        {
          label: "6 months",
          name: "bloating_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_six_months ? this.editFormData.bloating_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "bloating_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_last_month ? this.editFormData.bloating_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "bloating_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.bloating_today ? this.editFormData.bloating_today : false,
          group: "grp7",
        },
        // ========== Mood swings ======== //
        {
          heading: "Mood swings",
          group: "grp7",
          name: 'moodSwings'
        },

        {
          label: "6 months",
          name: "mood_swings_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_six_months ? this.editFormData.mood_swings_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "mood_swings_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_last_month ? this.editFormData.mood_swings_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "mood_swings_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.mood_swings_today ? this.editFormData.mood_swings_today : false,
          group: "grp7",
        },


        // ========== Skin irritation or dimpling ======== //
        {
          heading: "Skin irritation or dimpling",
          group: "grp7",
          name: 'skinIrritationDimpling'
        },

        {
          label: "6 months",
          name: "skin_dimpling_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_dimpling_six_months ? this.editFormData.skin_dimpling_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "skin_dimpling_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_dimpling_last_month ? this.editFormData.skin_dimpling_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "skin_dimpling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.skin_dimpling_today ? this.editFormData.skin_dimpling_today : false,
          group: "grp7",
        },

        // ========== Breast or nipple pain ======== //
        {
          heading: "Breast or nipple pain",
          group: "grp7",
          name: 'breastNipplePain'
        },

        {
          label: "6 months",
          name: "breast_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.breast_pain_six_months ? this.editFormData.breast_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "breast_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.breast_pain_last_month ? this.editFormData.breast_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "breast_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.breast_pain_today ? this.editFormData.breast_pain_today : false,
          group: "grp7",
        },

        // ========== Nipple retraction ======== //
        {
          heading: "Nipple retraction",
          group: "grp7",
          name: 'nippleRetraction'
        },

        {
          label: "6 months",
          name: "nipple_retraction_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_retraction_six_months ? this.editFormData.nipple_retraction_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "nipple_retraction_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_retraction_last_month ? this.editFormData.nipple_retraction_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "nipple_retraction_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_retraction_today ? this.editFormData.nipple_retraction_today : false,
          group: "grp7",
        },

        // ========== Redness, scaliness, or thickening of the nipple or breast skin ======== //
        {
          heading: "Redness, scaliness, or thickening of the nipple or breast skin",
          group: "grp7",
          name: 'rednessScaliness'
        },

        {
          label: "6 months",
          name: "redness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_six_months ? this.editFormData.redness_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "redness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_last_month ? this.editFormData.redness_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "redness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.redness_today ? this.editFormData.redness_today : false,
          group: "grp7",
        },

        // ========== Nipple discharge ======== //
        {
          heading: "Nipple discharge",
          group: "grp7",
          name: 'nippleDischarge'
        },

        {
          label: "6 months",
          name: "nipple_discharge_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_discharge_six_months ? this.editFormData.nipple_discharge_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "nipple_discharge_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_discharge_last_month ? this.editFormData.nipple_discharge_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "nipple_discharge_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.nipple_discharge_today ? this.editFormData.nipple_discharge_today : false,
          group: "grp7",
        },

        // ========== Abdominal bloating or swelling ======== //
        {
          heading: "Abdominal bloating or swelling",
          group: "grp7",
          name: 'abdominalBloatingSwelling'
        },

        {
          label: "6 months",
          name: "abdominal_swelling_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_swelling_six_months ? this.editFormData.abdominal_swelling_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "abdominal_swelling_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_swelling_last_month ? this.editFormData.abdominal_swelling_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "abdominal_swelling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.abdominal_swelling_today ? this.editFormData.abdominal_swelling_today : false,
          group: "grp7",
        },

        // ========== Quickly feeling full when eating ======== //
        {
          heading: "Quickly feeling full when eating",
          group: "grp7",
          name: 'quicklyFellingEating'
        },

        {
          label: "6 months",
          name: "quickly_feeling_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.quickly_feeling_six_months ? this.editFormData.quickly_feeling_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "quickly_feeling_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.quickly_feeling_last_month ? this.editFormData.quickly_feeling_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "quickly_feeling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.quickly_feeling_today ? this.editFormData.quickly_feeling_today : false,
          group: "grp7",
        },

        // ========== Discomfort in the pelvic area ======== //
        {
          heading: "Discomfort in the pelvic area",
          group: "grp7",
          name: 'discomfortPelvicArea'
        },

        {
          label: "6 months",
          name: "discomfort_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_six_months ? this.editFormData.discomfort_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "discomfort_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_last_month ? this.editFormData.discomfort_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "discomfort_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_today ? this.editFormData.discomfort_today : false,
          group: "grp7",
        },

        // ========== Blood in Stool ======== //
        {
          heading: "Blood in Stool",
          group: "grp7",
          name: 'bloodInStool'
        },

        {
          label: "6 months",
          name: "blood_stool_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_stool_six_months ? this.editFormData.blood_stool_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "blood_stool_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_stool_last_month ? this.editFormData.blood_stool_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "blood_stool_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.blood_stool_today ? this.editFormData.blood_stool_today : false,
          group: "grp7",
        },

        // ========== Constipation ======== //
        {
          heading: "Constipation",
          group: "grp7",
          name: 'constipation'
        },

        {
          label: "6 months",
          name: "constipation_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.constipation_six_months ? this.editFormData.constipation_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "constipation_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.constipation_last_month ? this.editFormData.constipation_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "constipation_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.constipation_today ? this.editFormData.constipation_today : false,
          group: "grp7",
        },

        // ========== Diarrhea ======== //
        {
          heading: "Diarrhea",
          group: "grp7",
          name: 'diarrhea'
        },

        {
          label: "6 months",
          name: "diarrhea_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diarrhea_six_months ? this.editFormData.diarrhea_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "diarrhea_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diarrhea_last_month ? this.editFormData.diarrhea_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "diarrhea_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diarrhea_today ? this.editFormData.diarrhea_today : false,
          group: "grp7",
        },

        // ========== Coughing up blood ======== //
        {
          heading: "Coughing up blood",
          group: "grp7",
          name: 'coughingUpBlood'
        },

        {
          label: "6 months",
          name: "coughing_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_six_months ? this.editFormData.coughing_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "coughing_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_last_month ? this.editFormData.coughing_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "coughing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.coughing_today ? this.editFormData.coughing_today : false,
          group: "grp7",
        },

        // ========== Dark colored urine ======== //
        {
          heading: "Dark colored urine",
          group: "grp7",
          name: 'darkColoredUrine'
        },

        {
          label: "6 months",
          name: "dark_colored_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_colored_urine_six_months ? this.editFormData.dark_colored_urine_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "dark_colored_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_colored_urine_last_month ? this.editFormData.dark_colored_urine_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "dark_colored_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dark_colored_urine_today ? this.editFormData.dark_colored_urine_today : false,
          group: "grp7",
        },

        // ========== Stools that look black ======== //
        {
          heading: "Stools that look black",
          group: "grp7",
          name: 'stollsLookBlack'
        },

        {
          label: "6 months",
          name: "stools_black_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stools_black_six_months ? this.editFormData.stools_black_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "stools_black_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stools_black_last_month ? this.editFormData.stools_black_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "stools_black_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.stools_black_today ? this.editFormData.stools_black_today : false,
          group: "grp7",
        },

        // ========== Belly pain ======== //
        {
          heading: "Belly pain",
          group: "grp7",
          name: 'bellyPain'
        },

        {
          label: "6 months",
          name: "belly_pain_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.belly_pain_six_months ? this.editFormData.belly_pain_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "belly_pain_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.belly_pain_last_month ? this.editFormData.belly_pain_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "belly_pain_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.belly_pain_today ? this.editFormData.belly_pain_today : false,
          group: "grp7",
        },

        // ========== Trouble swallowing ======== //
        {
          heading: "Trouble swallowing",
          group: "grp7",
          name: 'troubleSwallowing'
        },

        {
          label: "6 months",
          name: "trouble_swallowing_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_swallowing_six_months ? this.editFormData.trouble_swallowing_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "trouble_swallowing_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_swallowing_last_month ? this.editFormData.trouble_swallowing_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "trouble_swallowing_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_swallowing_today ? this.editFormData.trouble_swallowing_today : false,
          group: "grp7",
        },

        // ========== Feeling bloated after eating ======== //
        {
          heading: "Feeling bloated after eating",
          group: "grp7",
          name: 'feelingBloatedEating'
        },

        {
          label: "6 months",
          name: "feeling_bloated_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feeling_bloated_six_months ? this.editFormData.feeling_bloated_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "feeling_bloated_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feeling_bloated_last_month ? this.editFormData.feeling_bloated_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "feeling_bloated_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feeling_bloated_today ? this.editFormData.feeling_bloated_today : false,
          group: "grp7",
        },


        // ========== Indigestion ======== //
        {
          heading: "Indigestion",
          group: "grp7",
          name: 'indigestion'
        },

        {
          label: "6 months",
          name: "indigestion_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.indigestion_six_months ? this.editFormData.indigestion_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "indigestion_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.indigestion_last_month ? this.editFormData.indigestion_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "indigestion_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.indigestion_today ? this.editFormData.indigestion_today : false,
          group: "grp7",
        },


        // ========== Difficulty starting urination ======== //
        {
          heading: "Difficulty starting urination",
          group: "grp7",
          name: 'difficultyStartingUrination'
        },

        {
          label: "6 months",
          name: "diff_urination_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diff_urination_six_months ? this.editFormData.diff_urination_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "diff_urination_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diff_urination_last_month ? this.editFormData.diff_urination_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "diff_urination_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.diff_urination_today ? this.editFormData.diff_urination_today : false,
          group: "grp7",
        },

        // ========== Weak or interrupted flow of urine ======== //
        {
          heading: "Weak or interrupted flow of urine",
          group: "grp7",
          name: 'weakFlowUrine'
        },

        {
          label: "6 months",
          name: "weak_urine_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weak_urine_six_months ? this.editFormData.weak_urine_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "weak_urine_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weak_urine_last_month ? this.editFormData.weak_urine_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "weak_urine_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.weak_urine_today ? this.editFormData.weak_urine_today : false,
          group: "grp7",
        },


        // ========== Urinating often, especially at night ======== //
        {
          heading: "Urinating often, especially at night",
          group: "grp7",
          name: 'urinatingOftenNight'
        },

        {
          label: "6 months",
          name: "urin_often_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urin_often_six_months ? this.editFormData.urin_often_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "urin_often_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urin_often_last_month ? this.editFormData.urin_often_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "urin_often_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.urin_often_today ? this.editFormData.urin_often_today : false,
          group: "grp7",
        },


        // ========== Trouble emptying the bladder completely ======== //
        {
          heading: "Trouble emptying the bladder completely",
          group: "grp7",
          name: 'troubleBladderCompletely'
        },

        {
          label: "6 months",
          name: "trouble_empty_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_empty_six_months ? this.editFormData.trouble_empty_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "trouble_empty_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_empty_last_month ? this.editFormData.trouble_empty_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "trouble_empty_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.trouble_empty_today ? this.editFormData.trouble_empty_today : false,
          group: "grp7",
        },


        // ========== Pain or burning during urination ======== //
        {
          heading: "Pain or burning during urination",
          group: "grp7",
          name: 'painDuringUrination'
        },

        {
          label: "6 months",
          name: "pain_during_urin_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_during_urin_six_months ? this.editFormData.pain_during_urin_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pain_during_urin_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_during_urin_last_month ? this.editFormData.pain_during_urin_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pain_during_urin_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_during_urin_today ? this.editFormData.pain_during_urin_today : false,
          group: "grp7",
        },


        // ========== Pain in the back, hips, or pelvis that doesn’t go away ======== //
        {
          heading: "Pain in the back, hips, or pelvis that doesn't go away",
          group: "grp7",
          name: 'painBackHipsPelvis'
        },

        {
          label: "6 months",
          name: "pain_hips_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_hips_six_months ? this.editFormData.pain_hips_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pain_hips_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_hips_last_month ? this.editFormData.pain_hips_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pain_hips_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_hips_today ? this.editFormData.pain_hips_today : false,
          group: "grp7",
        },


        // ========== A lump or swelling in either testicle ======== //
        {
          heading: "A lump or swelling in either testicle",
          group: "grp7",
          name: 'lumpSwellingTestcle'
        },

        {
          label: "6 months",
          name: "lump_or_swelling_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_or_swelling_six_months ? this.editFormData.lump_or_swelling_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "lump_or_swelling_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_or_swelling_last_month ? this.editFormData.lump_or_swelling_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "lump_or_swelling_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_or_swelling_today ? this.editFormData.lump_or_swelling_today : false,
          group: "grp7",
        },


        // ========== A feeling of heaviness in the scrotum ======== //
        {
          heading: "A feeling of heaviness in the scrotum",
          group: "grp7",
          name: 'feelingHeavinessScrotum'
        },

        {
          label: "6 months",
          name: "feel_heaviness_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feel_heaviness_six_months ? this.editFormData.feel_heaviness_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "feel_heaviness_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feel_heaviness_last_month ? this.editFormData.feel_heaviness_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "feel_heaviness_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.feel_heaviness_today ? this.editFormData.feel_heaviness_today : false,
          group: "grp7",
        },


        // ========== A dull ache in the lower belly or groin ======== //
        {
          heading: "A dull ache in the lower belly or groin",
          group: "grp7",
          name: 'dullLowerBelly'
        },

        {
          label: "6 months",
          name: "dull_ache_lower_belly_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dull_ache_lower_belly_six_months ? this.editFormData.dull_ache_lower_belly_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "dull_ache_lower_belly_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dull_ache_lower_belly_last_month ? this.editFormData.dull_ache_lower_belly_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "dull_ache_lower_belly_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.dull_ache_lower_belly_today ? this.editFormData.dull_ache_lower_belly_today : false,
          group: "grp7",
        },


        // ========== Sudden swelling in the scrotum ======== //
        {
          heading: "Sudden swelling in the scrotum",
          group: "grp7",
          name: 'suddenSwellingScrotum'
        },

        {
          label: "6 months",
          name: "sudden_swelling_scrotum_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sudden_swelling_scrotum_six_months ? this.editFormData.sudden_swelling_scrotum_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "sudden_swelling_scrotum_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sudden_swelling_scrotum_last_month ? this.editFormData.sudden_swelling_scrotum_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "sudden_swelling_scrotum_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.sudden_swelling_scrotum_today ? this.editFormData.sudden_swelling_scrotum_today : false,
          group: "grp7",
        },


        // ========== Pain or discomfort in a testicle or the scrotum ======== //
        {
          heading: "Pain or discomfort in a testicle or the scrotum",
          group: "grp7",
          name: 'painDiscomfortScrotum'
        },

        {
          label: "6 months",
          name: "discomfort_testicle_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_testicle_six_months ? this.editFormData.discomfort_testicle_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "discomfort_testicle_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_testicle_last_month ? this.editFormData.discomfort_testicle_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "discomfort_testicle_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.discomfort_testicle_today ? this.editFormData.discomfort_testicle_today : false,
          group: "grp7",
        },


        // ========== A lump in the front of your neck ======== //
        {
          heading: "A lump in the front of your neck",
          group: "grp7",
          name: 'lumpFrontNeck'
        },

        {
          label: "6 months",
          name: "lump_front_neck_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_front_neck_six_months ? this.editFormData.lump_front_neck_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "lump_front_neck_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_front_neck_last_month ? this.editFormData.lump_front_neck_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "lump_front_neck_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.lump_front_neck_today ? this.editFormData.lump_front_neck_today : false,
          group: "grp7",
        },


        // ========== Swollen lymph nodes in your neck ======== //
        {
          heading: "Swollen lymph nodes in your neck",
          group: "grp7",
          name: 'swollenLymphNeck'
        },

        {
          label: "6 months",
          name: "swollen_lymph_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_lymph_six_months ? this.editFormData.swollen_lymph_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "swollen_lymph_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_lymph_last_month ? this.editFormData.swollen_lymph_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "swollen_lymph_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.swollen_lymph_today ? this.editFormData.swollen_lymph_today : false,
          group: "grp7",
        },


        // ========== Pain in your throat and/or neck ======== //
        {
          heading: "Pain in your throat and/or neck",
          group: "grp7",
          name: 'painThroatNeck'
        },

        {
          label: "6 months",
          name: "pain_throat_neck_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_throat_neck_six_months ? this.editFormData.pain_throat_neck_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "pain_throat_neck_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_throat_neck_last_month ? this.editFormData.pain_throat_neck_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "pain_throat_neck_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.pain_throat_neck_today ? this.editFormData.pain_throat_neck_today : false,
          group: "grp7",
        },


        // ========== Change to your voice, such as hoarseness ======== //
        {
          heading: "Change to your voice, such as hoarseness",
          group: "grp7",
          name: 'changeVoiceHoarseness'
        },

        {
          label: "6 months",
          name: "change_voice_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_voice_six_months ? this.editFormData.change_voice_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "change_voice_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_voice_last_month ? this.editFormData.change_voice_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "change_voice_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.change_voice_today ? this.editFormData.change_voice_today : false,
          group: "grp7",
        },


        // ========== Enlargement or tenderness of the breast tissue ======== //
        {
          heading: "Enlargement or tenderness of the breast tissue",
          group: "grp7",
          name: 'enlargementTendernessBreast'
        },

        {
          label: "6 months",
          name: "tenderness_breast_tissue_six_months",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tenderness_breast_tissue_six_months ? this.editFormData.tenderness_breast_tissue_six_months : false,
          group: "grp7",
        },
        {
          label: "Last month",
          name: "tenderness_breast_tissue_last_month",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tenderness_breast_tissue_last_month ? this.editFormData.tenderness_breast_tissue_last_month : false,
          group: "grp7",
        },
        {
          label: "Today",
          name: "tenderness_breast_tissue_today",
          type: 'checkbox',
          labelPosition: 'before',
          value: this.editFormData && this.editFormData.tenderness_breast_tissue_today ? this.editFormData.tenderness_breast_tissue_today : false,
          group: "grp7",
        },





      ]

    }


    console.log('intakeForm', this.intakeForm)

    setTimeout(() => {
      this.aspinnerLoader = false;
      if (this.subBtn) {
        if (document.querySelector(".grp7")) {
          document.querySelector(".grp7").classList.add("disbledAllCheckbox")
        };
      }
      this.wrapUpWithDiv('without');
      if (this.editFormData) {
        this.symptomNotesObj = this.editFormData;
        this.setEditButton(this.symptomNotesObj);
      }
    }, 500);
  }
  wrapUpWithDiv(type?: string) {
    let form_field_wrapperheadaches = document.querySelector(".form_field_wrapperheadaches");
    // let form_field_wrapperheadaches_six_months = document.querySelector(".form_field_wrapperheadaches_six_months");
    // let form_field_wrapperheadaches_last_month = document.querySelector(".form_field_wrapperheadaches_last_month");
    let form_field_wrapperheadaches_today = document.querySelector(".form_field_wrapperheadaches_today");
    let createHeadcheDivElement: any = document.createElement("div");
    createHeadcheDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperheadaches_six_months = document.querySelector(".form_field_wrapperheadaches_six_months");
      let form_field_wrapperheadaches_last_month = document.querySelector(".form_field_wrapperheadaches_last_month");
      createHeadcheDivElement.append(form_field_wrapperheadaches, form_field_wrapperheadaches_six_months, form_field_wrapperheadaches_last_month, form_field_wrapperheadaches_today);
    } else {
      createHeadcheDivElement.append(form_field_wrapperheadaches, form_field_wrapperheadaches_today);
    }
    let form_field_wrapperskinEyesYellowwish = document.querySelector(".form_field_wrapperskinEyesYellowwish");
    // let form_field_wrapperskin_six_months = document.querySelector(".form_field_wrapperskin_six_months");
    // let form_field_wrapperskin_last_month = document.querySelector(".form_field_wrapperskin_last_month");
    let form_field_wrapperskin_today = document.querySelector(".form_field_wrapperskin_today");
    let createSkinEyesYellowwishDivElement = document.createElement("div");
    createSkinEyesYellowwishDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperskin_six_months = document.querySelector(".form_field_wrapperskin_six_months");
      let form_field_wrapperskin_last_month = document.querySelector(".form_field_wrapperskin_last_month");
      createSkinEyesYellowwishDivElement.append(form_field_wrapperskinEyesYellowwish, form_field_wrapperskin_six_months, form_field_wrapperskin_last_month, form_field_wrapperskin_today);
    } else {
      createSkinEyesYellowwishDivElement.append(form_field_wrapperskinEyesYellowwish, form_field_wrapperskin_today);
    }
    let form_field_wrapperabdominalSwelling = document.querySelector(".form_field_wrapperabdominalSwelling");
    // let form_field_wrapperabdominal_six_months = document.querySelector(".form_field_wrapperabdominal_six_months");
    // let form_field_wrapperabdominal_last_month = document.querySelector(".form_field_wrapperabdominal_last_month");
    let form_field_wrapperabdominal_today = document.querySelector(".form_field_wrapperabdominal_today");
    let createAbdominalDivElement = document.createElement("div");
    createAbdominalDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperabdominal_six_months = document.querySelector(".form_field_wrapperabdominal_six_months");
      let form_field_wrapperabdominal_last_month = document.querySelector(".form_field_wrapperabdominal_last_month");
      createAbdominalDivElement.append(form_field_wrapperabdominalSwelling, form_field_wrapperabdominal_six_months, form_field_wrapperabdominal_last_month, form_field_wrapperabdominal_today);
    } else {
      createAbdominalDivElement.append(form_field_wrapperabdominalSwelling, form_field_wrapperabdominal_today);
    }
    let form_field_wrapperswellingAnkles = document.querySelector(".form_field_wrapperswellingAnkles");
    // let form_field_wrapperselling_legs_six_months = document.querySelector(".form_field_wrapperselling_legs_six_months");
    // let form_field_wrapperselling_legs_last_month = document.querySelector(".form_field_wrapperselling_legs_last_month");
    let form_field_wrapperselling_legs_today = document.querySelector(".form_field_wrapperselling_legs_today");
    let createSwellingAnklesDivElement = document.createElement("div");
    createSwellingAnklesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperselling_legs_six_months = document.querySelector(".form_field_wrapperselling_legs_six_months");
      let form_field_wrapperselling_legs_last_month = document.querySelector(".form_field_wrapperselling_legs_last_month");
      createSwellingAnklesDivElement.append(form_field_wrapperswellingAnkles, form_field_wrapperselling_legs_six_months, form_field_wrapperselling_legs_last_month, form_field_wrapperselling_legs_today);
    } else {
      createSwellingAnklesDivElement.append(form_field_wrapperswellingAnkles, form_field_wrapperselling_legs_today);
    }
    let form_field_wrapperdryItchySkin = document.querySelector(".form_field_wrapperdryItchySkin");
    // let form_field_wrapperitchy_skin_six_months = document.querySelector(".form_field_wrapperitchy_skin_six_months");
    // let form_field_wrapperitchy_skin_last_month = document.querySelector(".form_field_wrapperitchy_skin_last_month");
    let form_field_wrapperitchy_skin_today = document.querySelector(".form_field_wrapperitchy_skin_today");
    let createDryItchySkinDivElement = document.createElement("div");
    createDryItchySkinDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperitchy_skin_six_months = document.querySelector(".form_field_wrapperitchy_skin_six_months");
      let form_field_wrapperitchy_skin_last_month = document.querySelector(".form_field_wrapperitchy_skin_last_month");
      createDryItchySkinDivElement.append(form_field_wrapperdryItchySkin, form_field_wrapperitchy_skin_six_months, form_field_wrapperitchy_skin_last_month, form_field_wrapperitchy_skin_today);
    } else {
      createDryItchySkinDivElement.append(form_field_wrapperdryItchySkin, form_field_wrapperitchy_skin_today);
    }
    let form_field_wrapperdarkUrineColor = document.querySelector(".form_field_wrapperdarkUrineColor");
    // let form_field_wrapperdark_urine_color_six_months = document.querySelector(".form_field_wrapperdark_urine_color_six_months");
    // let form_field_wrapperdark_urine_color_last_month = document.querySelector(".form_field_wrapperdark_urine_color_last_month");
    let form_field_wrapperdark_urine_color_today = document.querySelector(".form_field_wrapperdark_urine_color_today");
    let createDarkUrineColorDivElement = document.createElement("div");
    createDarkUrineColorDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdark_urine_color_six_months = document.querySelector(".form_field_wrapperdark_urine_color_six_months");
      let form_field_wrapperdark_urine_color_last_month = document.querySelector(".form_field_wrapperdark_urine_color_last_month");
      createDarkUrineColorDivElement.append(form_field_wrapperdarkUrineColor, form_field_wrapperdark_urine_color_six_months, form_field_wrapperdark_urine_color_last_month, form_field_wrapperdark_urine_color_today);
    } else {
      createDarkUrineColorDivElement.append(form_field_wrapperdarkUrineColor, form_field_wrapperdark_urine_color_today);
    }
    let form_field_wrapperpaleStoolColor = document.querySelector(".form_field_wrapperpaleStoolColor");
    // let form_field_wrapperpale_stool_color_six_months = document.querySelector(".form_field_wrapperpale_stool_color_six_months");
    // let form_field_wrapperpale_stool_color_last_month = document.querySelector(".form_field_wrapperpale_stool_color_last_month");
    let form_field_wrapperpale_stool_color_today = document.querySelector(".form_field_wrapperpale_stool_color_today");
    let createPaleStoolColorDivElement = document.createElement("div");
    createPaleStoolColorDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpale_stool_color_six_months = document.querySelector(".form_field_wrapperpale_stool_color_six_months");
      let form_field_wrapperpale_stool_color_last_month = document.querySelector(".form_field_wrapperpale_stool_color_last_month");
      createPaleStoolColorDivElement.append(form_field_wrapperpaleStoolColor, form_field_wrapperpale_stool_color_six_months, form_field_wrapperpale_stool_color_last_month, form_field_wrapperpale_stool_color_today);
    } else {
      createPaleStoolColorDivElement.append(form_field_wrapperpaleStoolColor, form_field_wrapperpale_stool_color_today);
    }
    let form_field_wrapperchronicFatigue = document.querySelector(".form_field_wrapperchronicFatigue");
    // let form_field_wrapperchronic_fatigue_six_months = document.querySelector(".form_field_wrapperchronic_fatigue_six_months");
    // let form_field_wrapperchronic_fatigue_last_month = document.querySelector(".form_field_wrapperchronic_fatigue_last_month");
    let form_field_wrapperchronic_fatigue_today = document.querySelector(".form_field_wrapperchronic_fatigue_today");
    let createChronicFatigueDivElement = document.createElement("div");
    createChronicFatigueDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchronic_fatigue_six_months = document.querySelector(".form_field_wrapperchronic_fatigue_six_months");
      let form_field_wrapperchronic_fatigue_last_month = document.querySelector(".form_field_wrapperchronic_fatigue_last_month");
      createChronicFatigueDivElement.append(form_field_wrapperchronicFatigue, form_field_wrapperchronic_fatigue_six_months, form_field_wrapperchronic_fatigue_last_month, form_field_wrapperchronic_fatigue_today);
    } else {
      createChronicFatigueDivElement.append(form_field_wrapperchronicFatigue, form_field_wrapperchronic_fatigue_today);
    }
    let form_field_wrappernauseaOrVomiting = document.querySelector(".form_field_wrappernauseaOrVomiting");
    // let form_field_wrappervomiting_six_months = document.querySelector(".form_field_wrappervomiting_six_months");
    // let form_field_wrappervomiting_last_month = document.querySelector(".form_field_wrappervomiting_last_month");
    let form_field_wrappervomiting_today = document.querySelector(".form_field_wrappervomiting_today");
    let createNauseaOrVomitingDivElement = document.createElement("div");
    createNauseaOrVomitingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappervomiting_six_months = document.querySelector(".form_field_wrappervomiting_six_months");
      let form_field_wrappervomiting_last_month = document.querySelector(".form_field_wrappervomiting_last_month");
      createNauseaOrVomitingDivElement.append(form_field_wrappernauseaOrVomiting, form_field_wrappervomiting_six_months, form_field_wrappervomiting_last_month, form_field_wrappervomiting_today);
    } else {
      createNauseaOrVomitingDivElement.append(form_field_wrappernauseaOrVomiting, form_field_wrappervomiting_today);
    }
    let form_field_wrapperlossAppetite = document.querySelector(".form_field_wrapperlossAppetite");
    // let form_field_wrapperlossofappetite_six_months = document.querySelector(".form_field_wrapperlossofappetite_six_months");
    // let form_field_wrapperlossofappetite_last_month = document.querySelector(".form_field_wrapperlossofappetite_last_month");
    let form_field_wrapperlossofappetite_today = document.querySelector(".form_field_wrapperlossofappetite_today");
    let createLossAppetiteDivElement = document.createElement("div");
    createLossAppetiteDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlossofappetite_six_months = document.querySelector(".form_field_wrapperlossofappetite_six_months");
      let form_field_wrapperlossofappetite_last_month = document.querySelector(".form_field_wrapperlossofappetite_last_month");
      createLossAppetiteDivElement.append(form_field_wrapperlossAppetite, form_field_wrapperlossofappetite_six_months, form_field_wrapperlossofappetite_last_month, form_field_wrapperlossofappetite_today);
    } else {
      createLossAppetiteDivElement.append(form_field_wrapperlossAppetite, form_field_wrapperlossofappetite_today);
    }
    let form_field_wrappertendencyEasily = document.querySelector(".form_field_wrappertendencyEasily");
    // let form_field_wrapperbruise_six_months = document.querySelector(".form_field_wrapperbruise_six_months");
    // let form_field_wrapperbruise_last_month = document.querySelector(".form_field_wrapperbruise_last_month");
    let form_field_wrapperbruise_today = document.querySelector(".form_field_wrapperbruise_today");
    let createTendencyEasilyDivElement = document.createElement("div");
    createTendencyEasilyDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperbruise_six_months = document.querySelector(".form_field_wrapperbruise_six_months");
      let form_field_wrapperbruise_last_month = document.querySelector(".form_field_wrapperbruise_last_month");
      createTendencyEasilyDivElement.append(form_field_wrappertendencyEasily, form_field_wrapperbruise_six_months, form_field_wrapperbruise_last_month, form_field_wrapperbruise_today);
    } else {
      createTendencyEasilyDivElement.append(form_field_wrappertendencyEasily, form_field_wrapperbruise_today);
    }
    let form_field_wrapperfever = document.querySelector(".form_field_wrapperfever");
    // let form_field_wrapperfever_six_months = document.querySelector(".form_field_wrapperfever_six_months");
    // let form_field_wrapperfever_last_month = document.querySelector(".form_field_wrapperfever_last_month");
    let form_field_wrapperfever_today = document.querySelector(".form_field_wrapperfever_today");
    let createFeverDivElement = document.createElement("div");
    createFeverDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfever_six_months = document.querySelector(".form_field_wrapperfever_six_months");
      let form_field_wrapperfever_last_month = document.querySelector(".form_field_wrapperfever_last_month");
      createFeverDivElement.append(form_field_wrapperfever, form_field_wrapperfever_six_months, form_field_wrapperfever_last_month, form_field_wrapperfever_today);
    } else {
      createFeverDivElement.append(form_field_wrapperfever, form_field_wrapperfever_today);
    }
    let form_field_wrapperinsomnia = document.querySelector(".form_field_wrapperinsomnia");
    // let form_field_wrapperinsomnia_six_months = document.querySelector(".form_field_wrapperinsomnia_six_months");
    // let form_field_wrapperinsomnia_last_month = document.querySelector(".form_field_wrapperinsomnia_last_month");
    let form_field_wrapperinsomnia_today = document.querySelector(".form_field_wrapperinsomnia_today");
    let createInsomniaDivElement = document.createElement("div");
    createInsomniaDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperinsomnia_six_months = document.querySelector(".form_field_wrapperinsomnia_six_months");
      let form_field_wrapperinsomnia_last_month = document.querySelector(".form_field_wrapperinsomnia_last_month");
      createInsomniaDivElement.append(form_field_wrapperinsomnia, form_field_wrapperinsomnia_six_months, form_field_wrapperinsomnia_last_month, form_field_wrapperinsomnia_today);
    } else {
      createInsomniaDivElement.append(form_field_wrapperinsomnia, form_field_wrapperinsomnia_today);
    }
    let form_field_wrapperurinatingLess = document.querySelector(".form_field_wrapperurinatingLess");
    // let form_field_wrapperurinating_less_six_months = document.querySelector(".form_field_wrapperurinating_less_six_months");
    // let form_field_wrapperurinating_less_last_month = document.querySelector(".form_field_wrapperurinating_less_last_month");
    let form_field_wrapperurinating_less_today = document.querySelector(".form_field_wrapperurinating_less_today");
    let createUrinatingLessDivElement = document.createElement("div");
    createUrinatingLessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperurinating_less_six_months = document.querySelector(".form_field_wrapperurinating_less_six_months");
      let form_field_wrapperurinating_less_last_month = document.querySelector(".form_field_wrapperurinating_less_last_month");
      createUrinatingLessDivElement.append(form_field_wrapperurinatingLess, form_field_wrapperurinating_less_six_months, form_field_wrapperurinating_less_last_month, form_field_wrapperurinating_less_today);
    } else {
      createUrinatingLessDivElement.append(form_field_wrapperurinatingLess, form_field_wrapperurinating_less_today);
    }
    let form_field_wrappermuscleCramps = document.querySelector(".form_field_wrappermuscleCramps");
    // let form_field_wrappermuscle_cramps_six_months = document.querySelector(".form_field_wrappermuscle_cramps_six_months");
    // let form_field_wrappermuscle_cramps_last_month = document.querySelector(".form_field_wrappermuscle_cramps_last_month");
    let form_field_wrappermuscle_cramps_today = document.querySelector(".form_field_wrappermuscle_cramps_today");
    let createMuscleCrampsDivElement = document.createElement("div");
    createMuscleCrampsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappermuscle_cramps_six_months = document.querySelector(".form_field_wrappermuscle_cramps_six_months");
      let form_field_wrappermuscle_cramps_last_month = document.querySelector(".form_field_wrappermuscle_cramps_last_month");
      createMuscleCrampsDivElement.append(form_field_wrappermuscleCramps, form_field_wrappermuscle_cramps_six_months, form_field_wrappermuscle_cramps_last_month, form_field_wrappermuscle_cramps_today);
    } else {
      createMuscleCrampsDivElement.append(form_field_wrappermuscleCramps, form_field_wrappermuscle_cramps_today);
    }
    let form_field_wrapperswellingFeetAnkles = document.querySelector(".form_field_wrapperswellingFeetAnkles");
    // let form_field_wrapperswelling_feet_ankles_six_months = document.querySelector(".form_field_wrapperswelling_feet_ankles_six_months");
    // let form_field_wrapperswelling_feet_ankles_last_month = document.querySelector(".form_field_wrapperswelling_feet_ankles_last_month");
    let form_field_wrapperswelling_feet_ankles_today = document.querySelector(".form_field_wrapperswelling_feet_ankles_today");
    let createSwellingFeetAnklesDivElement = document.createElement("div");
    createSwellingFeetAnklesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswelling_feet_ankles_six_months = document.querySelector(".form_field_wrapperswelling_feet_ankles_six_months");
      let form_field_wrapperswelling_feet_ankles_last_month = document.querySelector(".form_field_wrapperswelling_feet_ankles_last_month");
      createSwellingFeetAnklesDivElement.append(form_field_wrapperswellingFeetAnkles, form_field_wrapperswelling_feet_ankles_six_months, form_field_wrapperswelling_feet_ankles_last_month, form_field_wrapperswelling_feet_ankles_today);
    } else {
      createSwellingFeetAnklesDivElement.append(form_field_wrapperswellingFeetAnkles, form_field_wrapperswelling_feet_ankles_today);
    }
    let form_field_wrappershortnessBreath = document.querySelector(".form_field_wrappershortnessBreath");
    // let form_field_wrappershortness_of_breath_six_months = document.querySelector(".form_field_wrappershortness_of_breath_six_months");
    // let form_field_wrappershortness_of_breath_last_month = document.querySelector(".form_field_wrappershortness_of_breath_last_month");
    let form_field_wrappershortness_of_breath_today = document.querySelector(".form_field_wrappershortness_of_breath_today");
    let createShortnessBreathDivElement = document.createElement("div");
    createShortnessBreathDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappershortness_of_breath_six_months = document.querySelector(".form_field_wrappershortness_of_breath_six_months");
      let form_field_wrappershortness_of_breath_last_month = document.querySelector(".form_field_wrappershortness_of_breath_last_month");
      createShortnessBreathDivElement.append(form_field_wrappershortnessBreath, form_field_wrappershortness_of_breath_six_months, form_field_wrappershortness_of_breath_last_month, form_field_wrappershortness_of_breath_today);
    } else {
      createShortnessBreathDivElement.append(form_field_wrappershortnessBreath, form_field_wrappershortness_of_breath_today);
    }
    let form_field_wrapperchestPain = document.querySelector(".form_field_wrapperchestPain");
    // let form_field_wrapperchest_pain_six_months = document.querySelector(".form_field_wrapperchest_pain_six_months");
    // let form_field_wrapperchest_pain_last_month = document.querySelector(".form_field_wrapperchest_pain_last_month");
    let form_field_wrapperchest_pain_today = document.querySelector(".form_field_wrapperchest_pain_today");
    let createChestPainDivElement = document.createElement("div");
    createChestPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchest_pain_six_months = document.querySelector(".form_field_wrapperchest_pain_six_months");
      let form_field_wrapperchest_pain_last_month = document.querySelector(".form_field_wrapperchest_pain_last_month");
      createChestPainDivElement.append(form_field_wrapperchestPain, form_field_wrapperchest_pain_six_months, form_field_wrapperchest_pain_last_month, form_field_wrapperchest_pain_today);
    } else {
      createChestPainDivElement.append(form_field_wrapperchestPain, form_field_wrapperchest_pain_today);
    }
    let form_field_wrapperbackPain = document.querySelector(".form_field_wrapperbackPain");
    // let form_field_wrapperback_pain_six_months = document.querySelector(".form_field_wrapperback_pain_six_months");
    // let form_field_wrapperback_pain_last_month = document.querySelector(".form_field_wrapperback_pain_last_month");
    let form_field_wrapperback_pain_today = document.querySelector(".form_field_wrapperback_pain_today");
    let createBackPainDivElement = document.createElement("div");
    createBackPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperback_pain_six_months = document.querySelector(".form_field_wrapperback_pain_six_months");
      let form_field_wrapperback_pain_last_month = document.querySelector(".form_field_wrapperback_pain_last_month");
      createBackPainDivElement.append(form_field_wrapperbackPain, form_field_wrapperback_pain_six_months, form_field_wrapperback_pain_last_month, form_field_wrapperback_pain_today);
    } else {
      createBackPainDivElement.append(form_field_wrapperbackPain, form_field_wrapperback_pain_today);
    }
    let form_field_wrapperfrequentUrination = document.querySelector(".form_field_wrapperfrequentUrination");
    // let form_field_wrapperfrequent_urination_six_months = document.querySelector(".form_field_wrapperfrequent_urination_six_months");
    // let form_field_wrapperfrequent_urination_last_month = document.querySelector(".form_field_wrapperfrequent_urination_last_month");
    let form_field_wrapperfrequent_urination_today = document.querySelector(".form_field_wrapperfrequent_urination_today");
    let createFrequentUrinationDivElement = document.createElement("div");
    createFrequentUrinationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfrequent_urination_six_months = document.querySelector(".form_field_wrapperfrequent_urination_six_months");
      let form_field_wrapperfrequent_urination_last_month = document.querySelector(".form_field_wrapperfrequent_urination_last_month");
      createFrequentUrinationDivElement.append(form_field_wrapperfrequentUrination, form_field_wrapperfrequent_urination_six_months, form_field_wrapperfrequent_urination_last_month, form_field_wrapperfrequent_urination_today);
    } else {
      createFrequentUrinationDivElement.append(form_field_wrapperfrequentUrination, form_field_wrapperfrequent_urination_today);
    }
    let form_field_wrapperfoamyUrine = document.querySelector(".form_field_wrapperfoamyUrine");
    // let form_field_wrapperfoamy_urine_six_months = document.querySelector(".form_field_wrapperfoamy_urine_six_months");
    // let form_field_wrapperfoamy_urine_last_month = document.querySelector(".form_field_wrapperfoamy_urine_last_month");
    let form_field_wrapperfoamy_urine_today = document.querySelector(".form_field_wrapperfoamy_urine_today");
    let createFoamyUrineDivElement = document.createElement("div");
    createFoamyUrineDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfoamy_urine_six_months = document.querySelector(".form_field_wrapperfoamy_urine_six_months");
      let form_field_wrapperfoamy_urine_last_month = document.querySelector(".form_field_wrapperfoamy_urine_last_month");
      createFoamyUrineDivElement.append(form_field_wrapperfoamyUrine, form_field_wrapperfoamy_urine_six_months, form_field_wrapperfoamy_urine_last_month, form_field_wrapperfoamy_urine_today);
    } else {
      createFoamyUrineDivElement.append(form_field_wrapperfoamyUrine, form_field_wrapperfoamy_urine_today);
    }
    let form_field_wrapperbloodUrine = document.querySelector(".form_field_wrapperbloodUrine");
    // let form_field_wrapperblood_in_your_urine_six_months = document.querySelector(".form_field_wrapperblood_in_your_urine_six_months");
    // let form_field_wrapperblood_in_your_urine_last_month = document.querySelector(".form_field_wrapperblood_in_your_urine_last_month");
    let form_field_wrapperblood_in_your_urine_today = document.querySelector(".form_field_wrapperblood_in_your_urine_today");
    let createBloodUrineDivElement = document.createElement("div");
    createBloodUrineDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperblood_in_your_urine_six_months = document.querySelector(".form_field_wrapperblood_in_your_urine_six_months");
      let form_field_wrapperblood_in_your_urine_last_month = document.querySelector(".form_field_wrapperblood_in_your_urine_last_month");
      createBloodUrineDivElement.append(form_field_wrapperbloodUrine, form_field_wrapperblood_in_your_urine_six_months, form_field_wrapperblood_in_your_urine_last_month, form_field_wrapperblood_in_your_urine_today);
    } else {
      createBloodUrineDivElement.append(form_field_wrapperbloodUrine, form_field_wrapperblood_in_your_urine_today);
    }
    let form_field_wrapperdizziness = document.querySelector(".form_field_wrapperdizziness");
    // let form_field_wrapperdizziness_six_months = document.querySelector(".form_field_wrapperdizziness_six_months");
    // let form_field_wrapperdizziness_last_month = document.querySelector(".form_field_wrapperdizziness_last_month");
    let form_field_wrapperdizziness_today = document.querySelector(".form_field_wrapperdizziness_today");
    let createDizzinessDivElement = document.createElement("div");
    createDizzinessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdizziness_six_months = document.querySelector(".form_field_wrapperdizziness_six_months");
      let form_field_wrapperdizziness_last_month = document.querySelector(".form_field_wrapperdizziness_last_month");
      createDizzinessDivElement.append(form_field_wrapperdizziness, form_field_wrapperdizziness_six_months, form_field_wrapperdizziness_last_month, form_field_wrapperdizziness_today);
    } else {
      createDizzinessDivElement.append(form_field_wrapperdizziness, form_field_wrapperdizziness_today);
    }
    let form_field_wrapperswellingAroundEyes = document.querySelector(".form_field_wrapperswellingAroundEyes");
    // let form_field_wrapperswelling_around_your_eyes_six_months = document.querySelector(".form_field_wrapperswelling_around_your_eyes_six_months");
    // let form_field_wrapperswelling_around_your_eyes_last_month = document.querySelector(".form_field_wrapperswelling_around_your_eyes_last_month");
    let form_field_wrapperswelling_around_your_eyes_today = document.querySelector(".form_field_wrapperswelling_around_your_eyes_today");
    let createSwellingAroundEyesDivElement = document.createElement("div");
    createSwellingAroundEyesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswelling_around_your_eyes_six_months = document.querySelector(".form_field_wrapperswelling_around_your_eyes_six_months");
      let form_field_wrapperswelling_around_your_eyes_last_month = document.querySelector(".form_field_wrapperswelling_around_your_eyes_last_month");
      createSwellingAroundEyesDivElement.append(form_field_wrapperswellingAroundEyes, form_field_wrapperswelling_around_your_eyes_six_months, form_field_wrapperswelling_around_your_eyes_last_month, form_field_wrapperswelling_around_your_eyes_today);
    } else {
      createSwellingAroundEyesDivElement.append(form_field_wrapperswellingAroundEyes, form_field_wrapperswelling_around_your_eyes_today);
    }
    let form_field_wrapperhighBloodSugar = document.querySelector(".form_field_wrapperhighBloodSugar");
    // let form_field_wrapperhigh_blood_sugar_six_months = document.querySelector(".form_field_wrapperhigh_blood_sugar_six_months");
    // let form_field_wrapperhigh_blood_sugar_last_month = document.querySelector(".form_field_wrapperhigh_blood_sugar_last_month");
    let form_field_wrapperhigh_blood_sugar_today = document.querySelector(".form_field_wrapperhigh_blood_sugar_today");
    let createHighBloodSugarDivElement = document.createElement("div");
    createHighBloodSugarDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperhigh_blood_sugar_six_months = document.querySelector(".form_field_wrapperhigh_blood_sugar_six_months");
      let form_field_wrapperhigh_blood_sugar_last_month = document.querySelector(".form_field_wrapperhigh_blood_sugar_last_month");
      createHighBloodSugarDivElement.append(form_field_wrapperhighBloodSugar, form_field_wrapperhigh_blood_sugar_six_months, form_field_wrapperhigh_blood_sugar_last_month, form_field_wrapperhigh_blood_sugar_today);
    } else {
      createHighBloodSugarDivElement.append(form_field_wrapperhighBloodSugar, form_field_wrapperhigh_blood_sugar_today);
    }
    let form_field_wrapperweightLoss = document.querySelector(".form_field_wrapperweightLoss");
    // let form_field_wrapperweight_loss_six_months = document.querySelector(".form_field_wrapperweight_loss_six_months");
    // let form_field_wrapperweight_loss_last_month = document.querySelector(".form_field_wrapperweight_loss_last_month");
    let form_field_wrapperweight_loss_today = document.querySelector(".form_field_wrapperweight_loss_today");
    let createWeightLossDivElement = document.createElement("div");
    createWeightLossDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperweight_loss_six_months = document.querySelector(".form_field_wrapperweight_loss_six_months");
      let form_field_wrapperweight_loss_last_month = document.querySelector(".form_field_wrapperweight_loss_last_month");
      createWeightLossDivElement.append(form_field_wrapperweightLoss, form_field_wrapperweight_loss_six_months, form_field_wrapperweight_loss_last_month, form_field_wrapperweight_loss_today);
    } else {
      createWeightLossDivElement.append(form_field_wrapperweightLoss, form_field_wrapperweight_loss_today);
    }
    let form_field_wrapperjointPain = document.querySelector(".form_field_wrapperjointPain");
    // let form_field_wrapperjoint_pain_six_months = document.querySelector(".form_field_wrapperjoint_pain_six_months");
    // let form_field_wrapperjoint_pain_last_month = document.querySelector(".form_field_wrapperjoint_pain_last_month");
    let form_field_wrapperjoint_pain_today = document.querySelector(".form_field_wrapperjoint_pain_today");
    let createJointPainDivElement = document.createElement("div");
    createJointPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperjoint_pain_six_months = document.querySelector(".form_field_wrapperjoint_pain_six_months");
      let form_field_wrapperjoint_pain_last_month = document.querySelector(".form_field_wrapperjoint_pain_last_month");
      createJointPainDivElement.append(form_field_wrapperjointPain, form_field_wrapperjoint_pain_six_months, form_field_wrapperjoint_pain_last_month, form_field_wrapperjoint_pain_today);
    } else {
      createJointPainDivElement.append(form_field_wrapperjointPain, form_field_wrapperjoint_pain_today);
    }
    let form_field_wrapperjointInflammation = document.querySelector(".form_field_wrapperjointInflammation");
    // let form_field_wrapperjoint_inflammation_six_months = document.querySelector(".form_field_wrapperjoint_inflammation_six_months");
    // let form_field_wrapperjoint_inflammation_last_month = document.querySelector(".form_field_wrapperjoint_inflammation_last_month");
    let form_field_wrapperjoint_inflammation_today = document.querySelector(".form_field_wrapperjoint_inflammation_today");
    let createJointInflammationDivElement = document.createElement("div");
    createJointInflammationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperjoint_inflammation_six_months = document.querySelector(".form_field_wrapperjoint_inflammation_six_months");
      let form_field_wrapperjoint_inflammation_last_month = document.querySelector(".form_field_wrapperjoint_inflammation_last_month");
      createJointInflammationDivElement.append(form_field_wrapperjointInflammation, form_field_wrapperjoint_inflammation_six_months, form_field_wrapperjoint_inflammation_last_month, form_field_wrapperjoint_inflammation_today);
    } else {
      createJointInflammationDivElement.append(form_field_wrapperjointInflammation, form_field_wrapperjoint_inflammation_today);
    }
    let form_field_wrapperlimitedRangeMotion = document.querySelector(".form_field_wrapperlimitedRangeMotion");
    // let form_field_wrapperlimited_range_motion_six_months = document.querySelector(".form_field_wrapperlimited_range_motion_six_months");
    // let form_field_wrapperlimited_range_motion_last_month = document.querySelector(".form_field_wrapperlimited_range_motion_last_month");
    let form_field_wrapperlimited_range_motion_today = document.querySelector(".form_field_wrapperlimited_range_motion_today");
    let createLimitedRangeMotionDivElement = document.createElement("div");
    createLimitedRangeMotionDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlimited_range_motion_six_months = document.querySelector(".form_field_wrapperlimited_range_motion_six_months");
      let form_field_wrapperlimited_range_motion_last_month = document.querySelector(".form_field_wrapperlimited_range_motion_last_month");
      createLimitedRangeMotionDivElement.append(form_field_wrapperlimitedRangeMotion, form_field_wrapperlimited_range_motion_six_months, form_field_wrapperlimited_range_motion_last_month, form_field_wrapperlimited_range_motion_today);
    } else {
      createLimitedRangeMotionDivElement.append(form_field_wrapperlimitedRangeMotion, form_field_wrapperlimited_range_motion_today);
    }
    let form_field_wrappercoughingEspeciallyNight = document.querySelector(".form_field_wrappercoughingEspeciallyNight");
    // let form_field_wrappercoughing_especially_at_night_six_months = document.querySelector(".form_field_wrappercoughing_especially_at_night_six_months");
    // let form_field_wrappercoughing_especially_at_night_last_month = document.querySelector(".form_field_wrappercoughing_especially_at_night_last_month");
    let form_field_wrappercoughing_especially_at_night_today = document.querySelector(".form_field_wrappercoughing_especially_at_night_today");
    let createCoughingEspeciallyNightnDivElement = document.createElement("div");
    createCoughingEspeciallyNightnDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercoughing_especially_at_night_six_months = document.querySelector(".form_field_wrappercoughing_especially_at_night_six_months");
      let form_field_wrappercoughing_especially_at_night_last_month = document.querySelector(".form_field_wrappercoughing_especially_at_night_last_month");
      createCoughingEspeciallyNightnDivElement.append(form_field_wrappercoughingEspeciallyNight, form_field_wrappercoughing_especially_at_night_six_months, form_field_wrappercoughing_especially_at_night_last_month, form_field_wrappercoughing_especially_at_night_today);
    } else {
      createCoughingEspeciallyNightnDivElement.append(form_field_wrappercoughingEspeciallyNight, form_field_wrappercoughing_especially_at_night_today);
    }
    let form_field_wrapperwheezingSqueakingBreathe = document.querySelector(".form_field_wrapperwheezingSqueakingBreathe");
    // let form_field_wrapperwheezing_squeaking_when_you_breathe_six_months = document.querySelector(".form_field_wrapperwheezing_squeaking_when_you_breathe_six_months");
    // let form_field_wrapperwheezing_squeaking_when_you_breathe_last_month = document.querySelector(".form_field_wrapperwheezing_squeaking_when_you_breathe_last_month");
    let form_field_wrapperwheezing_squeaking_when_you_breathe_today = document.querySelector(".form_field_wrapperwheezing_squeaking_when_you_breathe_today");
    let createWheezingSqueakingBreatheDivElement = document.createElement("div");
    createWheezingSqueakingBreatheDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperwheezing_squeaking_when_you_breathe_six_months = document.querySelector(".form_field_wrapperwheezing_squeaking_when_you_breathe_six_months");
      let form_field_wrapperwheezing_squeaking_when_you_breathe_last_month = document.querySelector(".form_field_wrapperwheezing_squeaking_when_you_breathe_last_month");
      createWheezingSqueakingBreatheDivElement.append(form_field_wrapperwheezingSqueakingBreathe, form_field_wrapperwheezing_squeaking_when_you_breathe_six_months, form_field_wrapperwheezing_squeaking_when_you_breathe_last_month, form_field_wrapperwheezing_squeaking_when_you_breathe_today);
    } else {
      createWheezingSqueakingBreatheDivElement.append(form_field_wrapperwheezingSqueakingBreathe, form_field_wrapperwheezing_squeaking_when_you_breathe_today);
    }
    let form_field_wrapperchestTightnessPain = document.querySelector(".form_field_wrapperchestTightnessPain");
    // let form_field_wrapperchest_tightness_pain_six_months = document.querySelector(".form_field_wrapperchest_tightness_pain_six_months");
    // let form_field_wrapperchest_tightness_pain_last_month = document.querySelector(".form_field_wrapperchest_tightness_pain_last_month");
    let form_field_wrapperchest_tightness_pain_today = document.querySelector(".form_field_wrapperchest_tightness_pain_today");
    let createChestTightnessPainDivElement = document.createElement("div");
    createChestTightnessPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchest_tightness_pain_six_months = document.querySelector(".form_field_wrapperchest_tightness_pain_six_months");
      let form_field_wrapperchest_tightness_pain_last_month = document.querySelector(".form_field_wrapperchest_tightness_pain_last_month");
      createChestTightnessPainDivElement.append(form_field_wrapperchestTightnessPain, form_field_wrapperchest_tightness_pain_six_months, form_field_wrapperchest_tightness_pain_last_month, form_field_wrapperchest_tightness_pain_today);
    } else {
      createChestTightnessPainDivElement.append(form_field_wrapperchestTightnessPain, form_field_wrapperchest_tightness_pain_today);
    }
    let form_field_wrapperlowEnergy = document.querySelector(".form_field_wrapperlowEnergy");
    // let form_field_wrapperlow_energy_six_months = document.querySelector(".form_field_wrapperlow_energy_six_months");
    // let form_field_wrapperlow_energy_last_month = document.querySelector(".form_field_wrapperlow_energy_last_month");
    let form_field_wrapperlow_energy_today = document.querySelector(".form_field_wrapperlow_energy_today");
    let createLowEnergyDivElement = document.createElement("div");
    createLowEnergyDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlow_energy_six_months = document.querySelector(".form_field_wrapperlow_energy_six_months");
      let form_field_wrapperlow_energy_last_month = document.querySelector(".form_field_wrapperlow_energy_last_month");
      createLowEnergyDivElement.append(form_field_wrapperlowEnergy, form_field_wrapperlow_energy_six_months, form_field_wrapperlow_energy_last_month, form_field_wrapperlow_energy_today);
    } else {
      createLowEnergyDivElement.append(form_field_wrapperlowEnergy, form_field_wrapperlow_energy_today);
    }
    let form_field_wrapperblueFingernails = document.querySelector(".form_field_wrapperblueFingernails");
    // let form_field_wrapperblue_fingernails_six_months = document.querySelector(".form_field_wrapperblue_fingernails_six_months");
    // let form_field_wrapperblue_fingernails_last_month = document.querySelector(".form_field_wrapperblue_fingernails_last_month");
    let form_field_wrapperblue_fingernails_today = document.querySelector(".form_field_wrapperblue_fingernails_today");
    let createBlueFingernailsDivElement = document.createElement("div");
    createBlueFingernailsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperblue_fingernails_six_months = document.querySelector(".form_field_wrapperblue_fingernails_six_months");
      let form_field_wrapperblue_fingernails_last_month = document.querySelector(".form_field_wrapperblue_fingernails_last_month");
      createBlueFingernailsDivElement.append(form_field_wrapperblueFingernails, form_field_wrapperblue_fingernails_six_months, form_field_wrapperblue_fingernails_last_month, form_field_wrapperblue_fingernails_today);
    } else {
      createBlueFingernailsDivElement.append(form_field_wrapperblueFingernails, form_field_wrapperblue_fingernails_today);
    }

    let form_field_wrapperfrequentColdsFlu = document.querySelector(".form_field_wrapperfrequentColdsFlu");
    // let form_field_wrapperfrequent_colds_flu_six_months = document.querySelector(".form_field_wrapperfrequent_colds_flu_six_months");
    // let form_field_wrapperfrequent_colds_flu_last_month = document.querySelector(".form_field_wrapperfrequent_colds_flu_last_month");
    let form_field_wrapperfrequent_colds_flu_today = document.querySelector(".form_field_wrapperfrequent_colds_flu_today");
    let createFrequentColdsFluDivElement = document.createElement("div");
    createFrequentColdsFluDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfrequent_colds_flu_six_months = document.querySelector(".form_field_wrapperfrequent_colds_flu_six_months");
      let form_field_wrapperfrequent_colds_flu_last_month = document.querySelector(".form_field_wrapperfrequent_colds_flu_last_month");
      createFrequentColdsFluDivElement.append(form_field_wrapperfrequentColdsFlu, form_field_wrapperfrequent_colds_flu_six_months, form_field_wrapperfrequent_colds_flu_last_month, form_field_wrapperfrequent_colds_flu_today);
    } else {
      createFrequentColdsFluDivElement.append(form_field_wrapperfrequentColdsFlu, form_field_wrapperfrequent_colds_flu_today);
    }

    let form_field_wrappercoughingLotsMucus = document.querySelector(".form_field_wrappercoughingLotsMucus");
    // let form_field_wrappercoughing_up_lots_mucus_six_months = document.querySelector(".form_field_wrappercoughing_up_lots_mucus_six_months");
    // let form_field_wrappercoughing_up_lots_mucus_last_month = document.querySelector(".form_field_wrappercoughing_up_lots_mucus_last_month");
    let form_field_wrappercoughing_up_lots_mucus_today = document.querySelector(".form_field_wrappercoughing_up_lots_mucus_today");
    let createCoughingLotsMucusDivElement = document.createElement("div");
    createCoughingLotsMucusDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercoughing_up_lots_mucus_six_months = document.querySelector(".form_field_wrappercoughing_up_lots_mucus_six_months");
      let form_field_wrappercoughing_up_lots_mucus_last_month = document.querySelector(".form_field_wrappercoughing_up_lots_mucus_last_month");
      createCoughingLotsMucusDivElement.append(form_field_wrappercoughingLotsMucus, form_field_wrappercoughing_up_lots_mucus_six_months, form_field_wrappercoughing_up_lots_mucus_last_month, form_field_wrappercoughing_up_lots_mucus_today);
    } else {
      createCoughingLotsMucusDivElement.append(form_field_wrappercoughingLotsMucus, form_field_wrappercoughing_up_lots_mucus_today);
    }

    let form_field_wrappercoughDoesnotAway = document.querySelector(".form_field_wrappercoughDoesnotAway");
    // let form_field_wrappercough_that_doesnt_go_away_six_months = document.querySelector(".form_field_wrappercough_that_doesnt_go_away_six_months");
    // let form_field_wrappercough_that_doesnt_go_away_last_month = document.querySelector(".form_field_wrappercough_that_doesnt_go_away_last_month");
    let form_field_wrappercough_that_doesnt_go_away_today = document.querySelector(".form_field_wrappercough_that_doesnt_go_away_today");
    let createCoughDoesnotAwayDivElement = document.createElement("div");
    createCoughDoesnotAwayDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercough_that_doesnt_go_away_six_months = document.querySelector(".form_field_wrappercough_that_doesnt_go_away_six_months");
      let form_field_wrappercough_that_doesnt_go_away_last_month = document.querySelector(".form_field_wrappercough_that_doesnt_go_away_last_month");
      createCoughDoesnotAwayDivElement.append(form_field_wrappercoughDoesnotAway, form_field_wrappercough_that_doesnt_go_away_six_months, form_field_wrappercough_that_doesnt_go_away_last_month, form_field_wrappercough_that_doesnt_go_away_today);
    } else {
      createCoughDoesnotAwayDivElement.append(form_field_wrappercoughDoesnotAway, form_field_wrappercough_that_doesnt_go_away_today);
    }

    let form_field_wrapperdepressionAnxiety = document.querySelector(".form_field_wrapperdepressionAnxiety");
    // let form_field_wrapperdepression_anxiety_six_months = document.querySelector(".form_field_wrapperdepression_anxiety_six_months");
    // let form_field_wrapperdepression_anxiety_last_month = document.querySelector(".form_field_wrapperdepression_anxiety_last_month");
    let form_field_wrapperdepression_anxiety_today = document.querySelector(".form_field_wrapperdepression_anxiety_today");
    let createDepressionAnxietyDivElement = document.createElement("div");
    createDepressionAnxietyDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdepression_anxiety_six_months = document.querySelector(".form_field_wrapperdepression_anxiety_six_months");
      let form_field_wrapperdepression_anxiety_last_month = document.querySelector(".form_field_wrapperdepression_anxiety_last_month");
      createDepressionAnxietyDivElement.append(form_field_wrapperdepressionAnxiety, form_field_wrapperdepression_anxiety_six_months, form_field_wrapperdepression_anxiety_last_month, form_field_wrapperdepression_anxiety_today);
    } else {
      createDepressionAnxietyDivElement.append(form_field_wrapperdepressionAnxiety, form_field_wrapperdepression_anxiety_today);
    }

    let form_field_wrapperrestlessnessSleeping = document.querySelector(".form_field_wrapperrestlessnessSleeping");
    // let form_field_wrapperrestlessness_while_sleeping_six_months = document.querySelector(".form_field_wrapperrestlessness_while_sleeping_six_months");
    // let form_field_wrapperrestlessness_while_sleeping_last_month = document.querySelector(".form_field_wrapperrestlessness_while_sleeping_last_month");
    let form_field_wrapperrestlessness_while_sleeping_today = document.querySelector(".form_field_wrapperrestlessness_while_sleeping_today");
    let createRestlessnessSleepingDivElement = document.createElement("div");
    createRestlessnessSleepingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperrestlessness_while_sleeping_six_months = document.querySelector(".form_field_wrapperrestlessness_while_sleeping_six_months");
      let form_field_wrapperrestlessness_while_sleeping_last_month = document.querySelector(".form_field_wrapperrestlessness_while_sleeping_last_month");
      createRestlessnessSleepingDivElement.append(form_field_wrapperrestlessnessSleeping, form_field_wrapperrestlessness_while_sleeping_six_months, form_field_wrapperrestlessness_while_sleeping_last_month, form_field_wrapperrestlessness_while_sleeping_today);
    } else {
      createRestlessnessSleepingDivElement.append(form_field_wrapperrestlessnessSleeping, form_field_wrapperrestlessness_while_sleeping_today);
    }

    let form_field_wrapperfatigue = document.querySelector(".form_field_wrapperfatigue");
    // let form_field_wrapperfatigue_six_months = document.querySelector(".form_field_wrapperfatigue_six_months");
    // let form_field_wrapperfatigue_last_month = document.querySelector(".form_field_wrapperfatigue_last_month");
    let form_field_wrapperfatigue_today = document.querySelector(".form_field_wrapperfatigue_today");
    let createFatigueDivElement = document.createElement("div");
    createFatigueDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfatigue_six_months = document.querySelector(".form_field_wrapperfatigue_six_months");
      let form_field_wrapperfatigue_last_month = document.querySelector(".form_field_wrapperfatigue_last_month");
      createFatigueDivElement.append(form_field_wrapperfatigue, form_field_wrapperfatigue_six_months, form_field_wrapperfatigue_last_month, form_field_wrapperfatigue_today);
    } else {
      createFatigueDivElement.append(form_field_wrapperfatigue, form_field_wrapperfatigue_today);
    }

    let form_field_wrappernauseaAndVomoting = document.querySelector(".form_field_wrappernauseaAndVomoting");
    // let form_field_wrappernausea_vomiting_six_months = document.querySelector(".form_field_wrappernausea_vomiting_six_months");
    // let form_field_wrappernausea_vomiting_last_month = document.querySelector(".form_field_wrappernausea_vomiting_last_month");
    let form_field_wrappernausea_vomiting_today = document.querySelector(".form_field_wrappernausea_vomiting_today");
    let createNauseaAndVomotingDivElement = document.createElement("div");
    createNauseaAndVomotingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernausea_vomiting_six_months = document.querySelector(".form_field_wrappernausea_vomiting_six_months");
      let form_field_wrappernausea_vomiting_last_month = document.querySelector(".form_field_wrappernausea_vomiting_last_month");
      createNauseaAndVomotingDivElement.append(form_field_wrappernauseaAndVomoting, form_field_wrappernausea_vomiting_six_months, form_field_wrappernausea_vomiting_last_month, form_field_wrappernausea_vomiting_today);
    } else {
      createNauseaAndVomotingDivElement.append(form_field_wrappernauseaAndVomoting, form_field_wrappernausea_vomiting_today);
    }

    let form_field_wrapperarrhythmiaHeartbeatRhythm = document.querySelector(".form_field_wrapperarrhythmiaHeartbeatRhythm");
    // let form_field_wrapperarrhythmia_six_months = document.querySelector(".form_field_wrapperarrhythmia_six_months");
    // let form_field_wrapperarrhythmia_last_month = document.querySelector(".form_field_wrapperarrhythmia_last_month");
    let form_field_wrapperarrhythmia_today = document.querySelector(".form_field_wrapperarrhythmia_today");
    let createArrhythmiaHeartbeatRhythmDivElement = document.createElement("div");
    createArrhythmiaHeartbeatRhythmDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperarrhythmia_six_months = document.querySelector(".form_field_wrapperarrhythmia_six_months");
      let form_field_wrapperarrhythmia_last_month = document.querySelector(".form_field_wrapperarrhythmia_last_month");
      createArrhythmiaHeartbeatRhythmDivElement.append(form_field_wrapperarrhythmiaHeartbeatRhythm, form_field_wrapperarrhythmia_six_months, form_field_wrapperarrhythmia_last_month, form_field_wrapperarrhythmia_today);
    } else {
      createArrhythmiaHeartbeatRhythmDivElement.append(form_field_wrapperarrhythmiaHeartbeatRhythm, form_field_wrapperarrhythmia_today);
    }

    let form_field_wrapperconfusion = document.querySelector(".form_field_wrapperconfusion");
    // let form_field_wrapperconfusion_six_months = document.querySelector(".form_field_wrapperconfusion_six_months");
    // let form_field_wrapperconfusion_last_month = document.querySelector(".form_field_wrapperconfusion_last_month");
    let form_field_wrapperconfusion_today = document.querySelector(".form_field_wrapperconfusion_today");
    let createConfusionDivElement = document.createElement("div");
    createConfusionDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperconfusion_six_months = document.querySelector(".form_field_wrapperconfusion_six_months");
      let form_field_wrapperconfusion_last_month = document.querySelector(".form_field_wrapperconfusion_last_month");
      createConfusionDivElement.append(form_field_wrapperconfusion, form_field_wrapperconfusion_six_months, form_field_wrapperconfusion_last_month, form_field_wrapperconfusion_today);
    } else {
      createConfusionDivElement.append(form_field_wrapperconfusion, form_field_wrapperconfusion_today);
    }

    let form_field_wrapperblurredVision = document.querySelector(".form_field_wrapperblurredVision");
    // let form_field_wrapperblurred_vision_six_months = document.querySelector(".form_field_wrapperblurred_vision_six_months");
    // let form_field_wrapperblurred_vision_last_month = document.querySelector(".form_field_wrapperblurred_vision_last_month");
    let form_field_wrapperblurred_vision_today = document.querySelector(".form_field_wrapperblurred_vision_today");
    let createBlurredVisionDivElement = document.createElement("div");
    createBlurredVisionDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperblurred_vision_six_months = document.querySelector(".form_field_wrapperblurred_vision_six_months");
      let form_field_wrapperblurred_vision_last_month = document.querySelector(".form_field_wrapperblurred_vision_last_month");
      createBlurredVisionDivElement.append(form_field_wrapperblurredVision, form_field_wrapperblurred_vision_six_months, form_field_wrapperblurred_vision_last_month, form_field_wrapperblurred_vision_today);
    } else {
      createBlurredVisionDivElement.append(form_field_wrapperblurredVision, form_field_wrapperblurred_vision_today);
    }

    let form_field_wrapperextremeThirst = document.querySelector(".form_field_wrapperextremeThirst");
    // let form_field_wrapperextreme_thirst_six_months = document.querySelector(".form_field_wrapperextreme_thirst_six_months");
    // let form_field_wrapperextreme_thirst_last_month = document.querySelector(".form_field_wrapperextreme_thirst_last_month");
    let form_field_wrapperextreme_thirst_today = document.querySelector(".form_field_wrapperextreme_thirst_today");
    let createExtremeThirstDivElement = document.createElement("div");
    createExtremeThirstDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperextreme_thirst_six_months = document.querySelector(".form_field_wrapperextreme_thirst_six_months");
      let form_field_wrapperextreme_thirst_last_month = document.querySelector(".form_field_wrapperextreme_thirst_last_month");
      createExtremeThirstDivElement.append(form_field_wrapperextremeThirst, form_field_wrapperextreme_thirst_six_months, form_field_wrapperextreme_thirst_last_month, form_field_wrapperextreme_thirst_today);
    } else {
      createExtremeThirstDivElement.append(form_field_wrapperextremeThirst, form_field_wrapperextreme_thirst_today);
    }

    let form_field_wrapperextremeHunger = document.querySelector(".form_field_wrapperextremeHunger");
    // let form_field_wrapperextreme_hunger_six_months = document.querySelector(".form_field_wrapperextreme_hunger_six_months");
    // let form_field_wrapperextreme_hunger_last_month = document.querySelector(".form_field_wrapperextreme_hunger_last_month");
    let form_field_wrapperextreme_hunger_today = document.querySelector(".form_field_wrapperextreme_hunger_today");
    let createExtremeHungerDivElement = document.createElement("div");
    createExtremeHungerDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperextreme_hunger_six_months = document.querySelector(".form_field_wrapperextreme_hunger_six_months");
      let form_field_wrapperextreme_hunger_last_month = document.querySelector(".form_field_wrapperextreme_hunger_last_month");
      createExtremeHungerDivElement.append(form_field_wrapperextremeHunger, form_field_wrapperextreme_hunger_six_months, form_field_wrapperextreme_hunger_last_month, form_field_wrapperextreme_hunger_today);
    } else {
      createExtremeHungerDivElement.append(form_field_wrapperextremeHunger, form_field_wrapperextreme_hunger_today);
    }

    let form_field_wrapperpainfulUrination = document.querySelector(".form_field_wrapperpainfulUrination");
    // let form_field_wrapperpainful_urination_six_months = document.querySelector(".form_field_wrapperpainful_urination_six_months");
    // let form_field_wrapperpainful_urination_last_month = document.querySelector(".form_field_wrapperpainful_urination_last_month");
    let form_field_wrapperpainful_urination_today = document.querySelector(".form_field_wrapperpainful_urination_today");
    let createPainfulUrinationDivElement = document.createElement("div");
    createPainfulUrinationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpainful_urination_six_months = document.querySelector(".form_field_wrapperpainful_urination_six_months");
      let form_field_wrapperpainful_urination_last_month = document.querySelector(".form_field_wrapperpainful_urination_last_month");
      createPainfulUrinationDivElement.append(form_field_wrapperpainfulUrination, form_field_wrapperpainful_urination_six_months, form_field_wrapperpainful_urination_last_month, form_field_wrapperpainful_urination_today);
    } else {
      createPainfulUrinationDivElement.append(form_field_wrapperpainfulUrination, form_field_wrapperpainful_urination_today);
    }

    let form_field_wrappernumbTinglingFeet = document.querySelector(".form_field_wrappernumbTinglingFeet");
    // let form_field_wrappernumb_or_tingling_feet_six_months = document.querySelector(".form_field_wrappernumb_or_tingling_feet_six_months");
    // let form_field_wrappernumb_or_tingling_feet_last_month = document.querySelector(".form_field_wrappernumb_or_tingling_feet_last_month");
    let form_field_wrappernumb_or_tingling_feet_today = document.querySelector(".form_field_wrappernumb_or_tingling_feet_today");
    let createNumbTinglingFeetDivElement = document.createElement("div");
    createNumbTinglingFeetDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernumb_or_tingling_feet_six_months = document.querySelector(".form_field_wrappernumb_or_tingling_feet_six_months");
      let form_field_wrappernumb_or_tingling_feet_last_month = document.querySelector(".form_field_wrappernumb_or_tingling_feet_last_month");
      createNumbTinglingFeetDivElement.append(form_field_wrappernumbTinglingFeet, form_field_wrappernumb_or_tingling_feet_six_months, form_field_wrappernumb_or_tingling_feet_last_month, form_field_wrappernumb_or_tingling_feet_today);
    } else {
      createNumbTinglingFeetDivElement.append(form_field_wrappernumbTinglingFeet, form_field_wrappernumb_or_tingling_feet_today);
    }

    let form_field_wrappersexualDifficulties = document.querySelector(".form_field_wrappersexualDifficulties");
    // let form_field_wrappersexual_difficulties_six_months = document.querySelector(".form_field_wrappersexual_difficulties_six_months");
    // let form_field_wrappersexual_difficulties_last_month = document.querySelector(".form_field_wrappersexual_difficulties_last_month");
    let form_field_wrappersexual_difficulties_today = document.querySelector(".form_field_wrappersexual_difficulties_today");
    let createSexualDifficultiesDivElement = document.createElement("div");
    createSexualDifficultiesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappersexual_difficulties_six_months = document.querySelector(".form_field_wrappersexual_difficulties_six_months");
      let form_field_wrappersexual_difficulties_last_month = document.querySelector(".form_field_wrappersexual_difficulties_last_month");
      createSexualDifficultiesDivElement.append(form_field_wrappersexualDifficulties, form_field_wrappersexual_difficulties_six_months, form_field_wrappersexual_difficulties_last_month, form_field_wrappersexual_difficulties_today);
    } else {
      createSexualDifficultiesDivElement.append(form_field_wrappersexualDifficulties, form_field_wrappersexual_difficulties_today);
    }

    let form_field_wrapperurinaryProblems = document.querySelector(".form_field_wrapperurinaryProblems");
    // let form_field_wrapperurinary_problems_six_months = document.querySelector(".form_field_wrapperurinary_problems_six_months");
    // let form_field_wrapperurinary_problems_last_month = document.querySelector(".form_field_wrapperurinary_problems_last_month");
    let form_field_wrapperurinary_problems_today = document.querySelector(".form_field_wrapperurinary_problems_today");
    let createUrinaryProblemsDivElement = document.createElement("div");
    createUrinaryProblemsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperurinary_problems_six_months = document.querySelector(".form_field_wrapperurinary_problems_six_months");
      let form_field_wrapperurinary_problems_last_month = document.querySelector(".form_field_wrapperurinary_problems_last_month");
      createUrinaryProblemsDivElement.append(form_field_wrapperurinaryProblems, form_field_wrapperurinary_problems_six_months, form_field_wrapperurinary_problems_last_month, form_field_wrapperurinary_problems_today);
    } else {
      createUrinaryProblemsDivElement.append(form_field_wrapperurinaryProblems, form_field_wrapperurinary_problems_today);
    }

    let form_field_wrappertinglingHandsFeet = document.querySelector(".form_field_wrappertinglingHandsFeet");
    // let form_field_wrappertingling_hands_feet_six_months = document.querySelector(".form_field_wrappertingling_hands_feet_six_months");
    // let form_field_wrappertingling_hands_feet_last_month = document.querySelector(".form_field_wrappertingling_hands_feet_last_month");
    let form_field_wrappertingling_hands_feet_today = document.querySelector(".form_field_wrappertingling_hands_feet_today");
    let createTinglingHandsFeetDivElement = document.createElement("div");
    createTinglingHandsFeetDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertingling_hands_feet_six_months = document.querySelector(".form_field_wrappertingling_hands_feet_six_months");
      let form_field_wrappertingling_hands_feet_last_month = document.querySelector(".form_field_wrappertingling_hands_feet_last_month");
      createTinglingHandsFeetDivElement.append(form_field_wrappertinglingHandsFeet, form_field_wrappertingling_hands_feet_six_months, form_field_wrappertingling_hands_feet_last_month, form_field_wrappertingling_hands_feet_today);
    } else {
      createTinglingHandsFeetDivElement.append(form_field_wrappertinglingHandsFeet, form_field_wrappertingling_hands_feet_today);
    }

    let form_field_wrapperburningSensation = document.querySelector(".form_field_wrapperburningSensation");
    // let form_field_wrapperburning_sensation_six_months = document.querySelector(".form_field_wrapperburning_sensation_six_months");
    // let form_field_wrapperburning_sensation_last_month = document.querySelector(".form_field_wrapperburning_sensation_last_month");
    let form_field_wrapperburning_sensation_today = document.querySelector(".form_field_wrapperburning_sensation_today");
    let createBurningSensationDivElement = document.createElement("div");
    createBurningSensationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperburning_sensation_six_months = document.querySelector(".form_field_wrapperburning_sensation_six_months");
      let form_field_wrapperburning_sensation_last_month = document.querySelector(".form_field_wrapperburning_sensation_last_month");
      createBurningSensationDivElement.append(form_field_wrapperburningSensation, form_field_wrapperburning_sensation_six_months, form_field_wrapperburning_sensation_last_month, form_field_wrapperburning_sensation_today);
    } else {
      createBurningSensationDivElement.append(form_field_wrapperburningSensation, form_field_wrapperburning_sensation_today);
    }

    let form_field_wrapperangina = document.querySelector(".form_field_wrapperangina");
    // let form_field_wrapperangina_six_months = document.querySelector(".form_field_wrapperangina_six_months");
    // let form_field_wrapperangina_last_month = document.querySelector(".form_field_wrapperangina_last_month");
    let form_field_wrapperangina_today = document.querySelector(".form_field_wrapperangina_today");
    let createAnginaDivElement = document.createElement("div");
    createAnginaDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperangina_six_months = document.querySelector(".form_field_wrapperangina_six_months");
      let form_field_wrapperangina_last_month = document.querySelector(".form_field_wrapperangina_last_month");
      createAnginaDivElement.append(form_field_wrapperangina, form_field_wrapperangina_six_months, form_field_wrapperangina_last_month, form_field_wrapperangina_today);
    } else {
      createAnginaDivElement.append(form_field_wrapperangina, form_field_wrapperangina_today);
    }

    let form_field_wrapperchestPainWithRest = document.querySelector(".form_field_wrapperchestPainWithRest");
    // let form_field_wrapperchest_pain_that_goesaway_with_rest_six_months = document.querySelector(".form_field_wrapperchest_pain_that_goesaway_with_rest_six_months");
    // let form_field_wrapperchest_pain_that_goesaway_with_rest_last_month = document.querySelector(".form_field_wrapperchest_pain_that_goesaway_with_rest_last_month");
    let form_field_wrapperchest_pain_that_goesaway_with_rest_today = document.querySelector(".form_field_wrapperchest_pain_that_goesaway_with_rest_today");
    let createChestPainWithRestDivElement = document.createElement("div");
    createChestPainWithRestDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchest_pain_that_goesaway_with_rest_six_months = document.querySelector(".form_field_wrapperchest_pain_that_goesaway_with_rest_six_months");
      let form_field_wrapperchest_pain_that_goesaway_with_rest_last_month = document.querySelector(".form_field_wrapperchest_pain_that_goesaway_with_rest_last_month");
      createChestPainWithRestDivElement.append(form_field_wrapperchestPainWithRest, form_field_wrapperchest_pain_that_goesaway_with_rest_six_months, form_field_wrapperchest_pain_that_goesaway_with_rest_last_month, form_field_wrapperchest_pain_that_goesaway_with_rest_today);
    } else {
      createChestPainWithRestDivElement.append(form_field_wrapperchestPainWithRest, form_field_wrapperchest_pain_that_goesaway_with_rest_today);
    }

    let form_field_wrapperheartburn = document.querySelector(".form_field_wrapperheartburn");
    // let form_field_wrapperheartburn_six_months = document.querySelector(".form_field_wrapperheartburn_six_months");
    // let form_field_wrapperheartburn_last_month = document.querySelector(".form_field_wrapperheartburn_last_month");
    let form_field_wrapperheartburn_today = document.querySelector(".form_field_wrapperheartburn_today");
    let createHeartburnDivElement = document.createElement("div");
    createHeartburnDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperheartburn_six_months = document.querySelector(".form_field_wrapperheartburn_six_months");
      let form_field_wrapperheartburn_last_month = document.querySelector(".form_field_wrapperheartburn_last_month");
      createHeartburnDivElement.append(form_field_wrapperheartburn, form_field_wrapperheartburn_six_months, form_field_wrapperheartburn_last_month, form_field_wrapperheartburn_today);
    } else {
      createHeartburnDivElement.append(form_field_wrapperheartburn, form_field_wrapperheartburn_today);
    }

    let form_field_wrapperpainInCalves = document.querySelector(".form_field_wrapperpainInCalves");
    // let form_field_wrapperpain_in_calves_six_months = document.querySelector(".form_field_wrapperpain_in_calves_six_months");
    // let form_field_wrapperpain_in_calves_last_month = document.querySelector(".form_field_wrapperpain_in_calves_last_month");
    let form_field_wrapperpain_in_calves_today = document.querySelector(".form_field_wrapperpain_in_calves_today");
    let createPainInCalvesDivElement = document.createElement("div");
    createPainInCalvesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpain_in_calves_six_months = document.querySelector(".form_field_wrapperpain_in_calves_six_months");
      let form_field_wrapperpain_in_calves_last_month = document.querySelector(".form_field_wrapperpain_in_calves_last_month");
      createPainInCalvesDivElement.append(form_field_wrapperpainInCalves, form_field_wrapperpain_in_calves_six_months, form_field_wrapperpain_in_calves_last_month, form_field_wrapperpain_in_calves_today);
    } else {
      createPainInCalvesDivElement.append(form_field_wrapperpainInCalves, form_field_wrapperpain_in_calves_today);
    }

    let form_field_wrapperdizzinessOrLightheadedness = document.querySelector(".form_field_wrapperdizzinessOrLightheadedness");
    // let form_field_wrapperdizziness_lightheadedness_six_months = document.querySelector(".form_field_wrapperdizziness_lightheadedness_six_months");
    // let form_field_wrapperdizziness_lightheadedness_last_month = document.querySelector(".form_field_wrapperdizziness_lightheadedness_last_month");
    let form_field_wrapperdizziness_lightheadedness_today = document.querySelector(".form_field_wrapperdizziness_lightheadedness_today");
    let createDizzinessOrLightheadednessDivElement = document.createElement("div");
    createDizzinessOrLightheadednessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdizziness_lightheadedness_six_months = document.querySelector(".form_field_wrapperdizziness_lightheadedness_six_months");
      let form_field_wrapperdizziness_lightheadedness_last_month = document.querySelector(".form_field_wrapperdizziness_lightheadedness_last_month");
      createDizzinessOrLightheadednessDivElement.append(form_field_wrapperdizzinessOrLightheadedness, form_field_wrapperdizziness_lightheadedness_six_months, form_field_wrapperdizziness_lightheadedness_last_month, form_field_wrapperdizziness_lightheadedness_today);
    } else {
      createDizzinessOrLightheadednessDivElement.append(form_field_wrapperdizzinessOrLightheadedness, form_field_wrapperdizziness_lightheadedness_today);
    }

    let form_field_wrapperirregularHeartbeatToo = document.querySelector(".form_field_wrapperirregularHeartbeatToo");
    // let form_field_wrapperirregular_heartbeat_toofastslow_six_months = document.querySelector(".form_field_wrapperirregular_heartbeat_toofastslow_six_months");
    // let form_field_wrapperirregular_heartbeat_toofastslow_last_month = document.querySelector(".form_field_wrapperirregular_heartbeat_toofastslow_last_month");
    let form_field_wrapperirregular_heartbeat_toofastslow_today = document.querySelector(".form_field_wrapperirregular_heartbeat_toofastslow_today");
    let createIrregularHeartbeatTooDivElement = document.createElement("div");
    createIrregularHeartbeatTooDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperirregular_heartbeat_toofastslow_six_months = document.querySelector(".form_field_wrapperirregular_heartbeat_toofastslow_six_months");
      let form_field_wrapperirregular_heartbeat_toofastslow_last_month = document.querySelector(".form_field_wrapperirregular_heartbeat_toofastslow_last_month");
      createIrregularHeartbeatTooDivElement.append(form_field_wrapperirregularHeartbeatToo, form_field_wrapperirregular_heartbeat_toofastslow_six_months, form_field_wrapperirregular_heartbeat_toofastslow_last_month, form_field_wrapperirregular_heartbeat_toofastslow_today);
    } else {
      createIrregularHeartbeatTooDivElement.append(form_field_wrapperirregularHeartbeatToo, form_field_wrapperirregular_heartbeat_toofastslow_today);
    }

    let form_field_wrapperheartAttack = document.querySelector(".form_field_wrapperheartAttack");
    // let form_field_wrapperheart_attack_six_months = document.querySelector(".form_field_wrapperheart_attack_six_months");
    // let form_field_wrapperheart_attack_last_month = document.querySelector(".form_field_wrapperheart_attack_last_month");
    let form_field_wrapperheart_attack_today = document.querySelector(".form_field_wrapperheart_attack_today");
    let createHeartAttackDivElement = document.createElement("div");
    createHeartAttackDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperheart_attack_six_months = document.querySelector(".form_field_wrapperheart_attack_six_months");
      let form_field_wrapperheart_attack_last_month = document.querySelector(".form_field_wrapperheart_attack_last_month");
      createHeartAttackDivElement.append(form_field_wrapperheartAttack, form_field_wrapperheart_attack_six_months, form_field_wrapperheart_attack_last_month, form_field_wrapperheart_attack_today);
    } else {
      createHeartAttackDivElement.append(form_field_wrapperheartAttack, form_field_wrapperheart_attack_today);
    }

    let form_field_wrapperstroke = document.querySelector(".form_field_wrapperstroke");
    // let form_field_wrapperstroke_six_months = document.querySelector(".form_field_wrapperstroke_six_months");
    // let form_field_wrapperstroke_last_month = document.querySelector(".form_field_wrapperstroke_last_month");
    let form_field_wrapperstroke_today = document.querySelector(".form_field_wrapperstroke_today");
    let createStrokeDivElement = document.createElement("div");
    createStrokeDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperstroke_six_months = document.querySelector(".form_field_wrapperstroke_six_months");
      let form_field_wrapperstroke_last_month = document.querySelector(".form_field_wrapperstroke_last_month");
      createStrokeDivElement.append(form_field_wrapperstroke, form_field_wrapperstroke_six_months, form_field_wrapperstroke_last_month, form_field_wrapperstroke_today);
    } else {
      createStrokeDivElement.append(form_field_wrapperstroke, form_field_wrapperstroke_today);
    }

    let form_field_wrapperbloodClotInVein = document.querySelector(".form_field_wrapperbloodClotInVein");
    // let form_field_wrapperblood_clot_inavein_six_months = document.querySelector(".form_field_wrapperblood_clot_inavein_six_months");
    // let form_field_wrapperblood_clot_inavein_last_month = document.querySelector(".form_field_wrapperblood_clot_inavein_last_month");
    let form_field_wrapperblood_clot_inavein_today = document.querySelector(".form_field_wrapperblood_clot_inavein_today");
    let createBloodClotInVeinDivElement = document.createElement("div");
    createBloodClotInVeinDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperblood_clot_inavein_six_months = document.querySelector(".form_field_wrapperblood_clot_inavein_six_months");
      let form_field_wrapperblood_clot_inavein_last_month = document.querySelector(".form_field_wrapperblood_clot_inavein_last_month");
      createBloodClotInVeinDivElement.append(form_field_wrapperbloodClotInVein, form_field_wrapperblood_clot_inavein_six_months, form_field_wrapperblood_clot_inavein_last_month, form_field_wrapperblood_clot_inavein_today);
    } else {
      createBloodClotInVeinDivElement.append(form_field_wrapperbloodClotInVein, form_field_wrapperblood_clot_inavein_today);
    }

    let form_field_wrappertiaMiniStroke = document.querySelector(".form_field_wrappertiaMiniStroke");
    // let form_field_wrappertia_mini_stroke_six_months = document.querySelector(".form_field_wrappertia_mini_stroke_six_months");
    // let form_field_wrappertia_mini_stroke_last_month = document.querySelector(".form_field_wrappertia_mini_stroke_last_month");
    let form_field_wrappertia_mini_stroke_today = document.querySelector(".form_field_wrappertia_mini_stroke_today");
    let createTiaMiniStrokeDivElement = document.createElement("div");
    createTiaMiniStrokeDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertia_mini_stroke_six_months = document.querySelector(".form_field_wrappertia_mini_stroke_six_months");
      let form_field_wrappertia_mini_stroke_last_month = document.querySelector(".form_field_wrappertia_mini_stroke_last_month");
      createTiaMiniStrokeDivElement.append(form_field_wrappertiaMiniStroke, form_field_wrappertia_mini_stroke_six_months, form_field_wrappertia_mini_stroke_last_month, form_field_wrappertia_mini_stroke_today);
    } else {
      createTiaMiniStrokeDivElement.append(form_field_wrappertiaMiniStroke, form_field_wrappertia_mini_stroke_today);
    }

    let form_field_wrapperchestPainOrDiscomfort = document.querySelector(".form_field_wrapperchestPainOrDiscomfort");
    // let form_field_wrapperchest_painor_discomfort_six_months = document.querySelector(".form_field_wrapperchest_painor_discomfort_six_months");
    // let form_field_wrapperchest_painor_discomfort_last_month = document.querySelector(".form_field_wrapperchest_painor_discomfort_last_month");
    let form_field_wrapperchest_painor_discomfort_today = document.querySelector(".form_field_wrapperchest_painor_discomfort_today");
    let createChestPainOrDiscomfortDivElement = document.createElement("div");
    createChestPainOrDiscomfortDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchest_painor_discomfort_six_months = document.querySelector(".form_field_wrapperchest_painor_discomfort_six_months");
      let form_field_wrapperchest_painor_discomfort_last_month = document.querySelector(".form_field_wrapperchest_painor_discomfort_last_month");
      createChestPainOrDiscomfortDivElement.append(form_field_wrapperchestPainOrDiscomfort, form_field_wrapperchest_painor_discomfort_six_months, form_field_wrapperchest_painor_discomfort_last_month, form_field_wrapperchest_painor_discomfort_today);
    } else {
      createChestPainOrDiscomfortDivElement.append(form_field_wrapperchestPainOrDiscomfort, form_field_wrapperchest_painor_discomfort_today);
    }

    let form_field_wrapperfainting = document.querySelector(".form_field_wrapperfainting");
    // let form_field_wrapperfainting_six_months = document.querySelector(".form_field_wrapperfainting_six_months");
    // let form_field_wrapperfainting_last_month = document.querySelector(".form_field_wrapperfainting_last_month");
    let form_field_wrapperfainting_today = document.querySelector(".form_field_wrapperfainting_today");
    let createFaintingDivElement = document.createElement("div");
    createFaintingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfainting_six_months = document.querySelector(".form_field_wrapperfainting_six_months");
      let form_field_wrapperfainting_last_month = document.querySelector(".form_field_wrapperfainting_last_month");
      createFaintingDivElement.append(form_field_wrapperfainting, form_field_wrapperfainting_six_months, form_field_wrapperfainting_last_month, form_field_wrapperfainting_today);
    } else {
      createFaintingDivElement.append(form_field_wrapperfainting, form_field_wrapperfainting_today);
    }

    let form_field_wrapperweakness = document.querySelector(".form_field_wrapperweakness");
    // let form_field_wrapperweakness_six_months = document.querySelector(".form_field_wrapperweakness_six_months");
    // let form_field_wrapperweakness_last_month = document.querySelector(".form_field_wrapperweakness_last_month");
    let form_field_wrapperweakness_today = document.querySelector(".form_field_wrapperweakness_today");
    let createWeaknessDivElement = document.createElement("div");
    createWeaknessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperweakness_six_months = document.querySelector(".form_field_wrapperweakness_six_months");
      let form_field_wrapperweakness_last_month = document.querySelector(".form_field_wrapperweakness_last_month");
      createWeaknessDivElement.append(form_field_wrapperweakness, form_field_wrapperweakness_six_months, form_field_wrapperweakness_last_month, form_field_wrapperweakness_today);
    } else {
      createWeaknessDivElement.append(form_field_wrapperweakness, form_field_wrapperweakness_today);
    }

    let form_field_wrapperlingeringDiscomfort = document.querySelector(".form_field_wrapperlingeringDiscomfort");
    // let form_field_wrapperlingering_discomfort_six_months = document.querySelector(".form_field_wrapperlingering_discomfort_six_months");
    // let form_field_wrapperlingering_discomfort_last_month = document.querySelector(".form_field_wrapperlingering_discomfort_last_month");
    let form_field_wrapperlingering_discomfort_today = document.querySelector(".form_field_wrapperlingering_discomfort_today");
    let createLingeringDiscomfortDivElement = document.createElement("div");
    createLingeringDiscomfortDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlingering_discomfort_six_months = document.querySelector(".form_field_wrapperlingering_discomfort_six_months");
      let form_field_wrapperlingering_discomfort_last_month = document.querySelector(".form_field_wrapperlingering_discomfort_last_month");
      createLingeringDiscomfortDivElement.append(form_field_wrapperlingeringDiscomfort, form_field_wrapperlingering_discomfort_six_months, form_field_wrapperlingering_discomfort_last_month, form_field_wrapperlingering_discomfort_today);
    } else {
      createLingeringDiscomfortDivElement.append(form_field_wrapperlingeringDiscomfort, form_field_wrapperlingering_discomfort_today);
    }

    let form_field_wrapperpainAffectedJoint = document.querySelector(".form_field_wrapperpainAffectedJoint");
    // let form_field_wrapperpain_in_affected_joint_six_months = document.querySelector(".form_field_wrapperpain_in_affected_joint_six_months");
    // let form_field_wrapperpain_in_affected_joint_last_month = document.querySelector(".form_field_wrapperpain_in_affected_joint_last_month");
    let form_field_wrapperpain_in_affected_joint_today = document.querySelector(".form_field_wrapperpain_in_affected_joint_today");
    let createPainAffectedJointDivElement = document.createElement("div");
    createPainAffectedJointDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpain_in_affected_joint_six_months = document.querySelector(".form_field_wrapperpain_in_affected_joint_six_months");
      let form_field_wrapperpain_in_affected_joint_last_month = document.querySelector(".form_field_wrapperpain_in_affected_joint_last_month");
      createPainAffectedJointDivElement.append(form_field_wrapperpainAffectedJoint, form_field_wrapperpain_in_affected_joint_six_months, form_field_wrapperpain_in_affected_joint_last_month, form_field_wrapperpain_in_affected_joint_today);
    } else {
      createPainAffectedJointDivElement.append(form_field_wrapperpainAffectedJoint, form_field_wrapperpain_in_affected_joint_today);
    }

    let form_field_wrapperswellingAffectedJoint = document.querySelector(".form_field_wrapperswellingAffectedJoint");
    // let form_field_wrapperswelling_in_affected_joint_six_months = document.querySelector(".form_field_wrapperswelling_in_affected_joint_six_months");
    // let form_field_wrapperswelling_in_affected_joint_last_month = document.querySelector(".form_field_wrapperswelling_in_affected_joint_last_month");
    let form_field_wrapperswelling_in_affected_joint_today = document.querySelector(".form_field_wrapperswelling_in_affected_joint_today");
    let createSwellingAffectedJointDivElement = document.createElement("div");
    createSwellingAffectedJointDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswelling_in_affected_joint_six_months = document.querySelector(".form_field_wrapperswelling_in_affected_joint_six_months");
      let form_field_wrapperswelling_in_affected_joint_last_month = document.querySelector(".form_field_wrapperswelling_in_affected_joint_last_month");
      createSwellingAffectedJointDivElement.append(form_field_wrapperswellingAffectedJoint, form_field_wrapperswelling_in_affected_joint_six_months, form_field_wrapperswelling_in_affected_joint_last_month, form_field_wrapperswelling_in_affected_joint_today);
    } else {
      createSwellingAffectedJointDivElement.append(form_field_wrapperswellingAffectedJoint, form_field_wrapperswelling_in_affected_joint_today);
    }

    let form_field_wrapperrednessAffectedJoint = document.querySelector(".form_field_wrapperrednessAffectedJoint");
    // let form_field_wrapperredness_in_affected_joint_six_months = document.querySelector(".form_field_wrapperredness_in_affected_joint_six_months");
    // let form_field_wrapperredness_in_affected_joint_last_month = document.querySelector(".form_field_wrapperredness_in_affected_joint_last_month");
    let form_field_wrapperredness_in_affected_joint_today = document.querySelector(".form_field_wrapperredness_in_affected_joint_today");
    let createRednessAffectedJointDivElement = document.createElement("div");
    createRednessAffectedJointDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperredness_in_affected_joint_six_months = document.querySelector(".form_field_wrapperredness_in_affected_joint_six_months");
      let form_field_wrapperredness_in_affected_joint_last_month = document.querySelector(".form_field_wrapperredness_in_affected_joint_last_month");
      createRednessAffectedJointDivElement.append(form_field_wrapperrednessAffectedJoint, form_field_wrapperredness_in_affected_joint_six_months, form_field_wrapperredness_in_affected_joint_last_month, form_field_wrapperredness_in_affected_joint_today);
    } else {
      createRednessAffectedJointDivElement.append(form_field_wrapperrednessAffectedJoint, form_field_wrapperredness_in_affected_joint_today);
    }

    let form_field_wrapperchills = document.querySelector(".form_field_wrapperchills");
    // let form_field_wrapperchills_six_months = document.querySelector(".form_field_wrapperchills_six_months");
    // let form_field_wrapperchills_last_month = document.querySelector(".form_field_wrapperchills_last_month");
    let form_field_wrapperchills_today = document.querySelector(".form_field_wrapperchills_today");
    let createChillsDivElement = document.createElement("div");
    createChillsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchills_six_months = document.querySelector(".form_field_wrapperchills_six_months");
      let form_field_wrapperchills_last_month = document.querySelector(".form_field_wrapperchills_last_month");
      createChillsDivElement.append(form_field_wrapperchills, form_field_wrapperchills_six_months, form_field_wrapperchills_last_month, form_field_wrapperchills_today);
    } else {
      createChillsDivElement.append(form_field_wrapperchills, form_field_wrapperchills_today);
    }

    let form_field_wrappercough = document.querySelector(".form_field_wrappercough");
    // let form_field_wrappercough_six_months = document.querySelector(".form_field_wrappercough_six_months");
    // let form_field_wrappercough_last_month = document.querySelector(".form_field_wrappercough_last_month");
    let form_field_wrappercough_today = document.querySelector(".form_field_wrappercough_today");
    let createCoughDivElement = document.createElement("div");
    createCoughDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercough_six_months = document.querySelector(".form_field_wrappercough_six_months");
      let form_field_wrappercough_last_month = document.querySelector(".form_field_wrappercough_last_month");
      createCoughDivElement.append(form_field_wrappercough, form_field_wrappercough_six_months, form_field_wrappercough_last_month, form_field_wrappercough_today);
    } else {
      createCoughDivElement.append(form_field_wrappercough, form_field_wrappercough_today);
    }

    let form_field_wrapperchestPainBreathe = document.querySelector(".form_field_wrapperchestPainBreathe");
    // let form_field_wrapperchest_pain_when_you_breatheorcough_six_months = document.querySelector(".form_field_wrapperchest_pain_when_you_breatheorcough_six_months");
    // let form_field_wrapperchest_pain_when_you_breatheorcough_last_month = document.querySelector(".form_field_wrapperchest_pain_when_you_breatheorcough_last_month");
    let form_field_wrapperchest_pain_when_you_breatheorcough_today = document.querySelector(".form_field_wrapperchest_pain_when_you_breatheorcough_today");
    let createChestPainBreatheDivElement = document.createElement("div");
    createChestPainBreatheDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchest_pain_when_you_breatheorcough_six_months = document.querySelector(".form_field_wrapperchest_pain_when_you_breatheorcough_six_months");
      let form_field_wrapperchest_pain_when_you_breatheorcough_last_month = document.querySelector(".form_field_wrapperchest_pain_when_you_breatheorcough_last_month");
      createChestPainBreatheDivElement.append(form_field_wrapperchestPainBreathe, form_field_wrapperchest_pain_when_you_breatheorcough_six_months, form_field_wrapperchest_pain_when_you_breatheorcough_last_month, form_field_wrapperchest_pain_when_you_breatheorcough_today);
    } else {
      createChestPainBreatheDivElement.append(form_field_wrapperchestPainBreathe, form_field_wrapperchest_pain_when_you_breatheorcough_today);
    }

    let form_field_wrappernausea = document.querySelector(".form_field_wrappernausea");
    // let form_field_wrappernausea_six_months = document.querySelector(".form_field_wrappernausea_six_months");
    // let form_field_wrappernausea_last_month = document.querySelector(".form_field_wrappernausea_last_month");
    let form_field_wrappernausea_today = document.querySelector(".form_field_wrappernausea_today");
    let createNauseaDivElement = document.createElement("div");
    createNauseaDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernausea_six_months = document.querySelector(".form_field_wrappernausea_six_months");
      let form_field_wrappernausea_last_month = document.querySelector(".form_field_wrappernausea_last_month");
      createNauseaDivElement.append(form_field_wrappernausea, form_field_wrappernausea_six_months, form_field_wrappernausea_last_month, form_field_wrappernausea_today);
    } else {
      createNauseaDivElement.append(form_field_wrappernausea, form_field_wrappernausea_today);
    }

    let form_field_wrapperpainBurningUrinating = document.querySelector(".form_field_wrapperpainBurningUrinating");
    // let form_field_wrapperpainorburning_while_urinating_six_months = document.querySelector(".form_field_wrapperpainorburning_while_urinating_six_months");
    // let form_field_wrapperpainorburning_while_urinating_last_month = document.querySelector(".form_field_wrapperpainorburning_while_urinating_last_month");
    let form_field_wrapperpainorburning_while_urinating_today = document.querySelector(".form_field_wrapperpainorburning_while_urinating_today");
    let createPainBurningUrinatingDivElement = document.createElement("div");
    createPainBurningUrinatingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpainorburning_while_urinating_six_months = document.querySelector(".form_field_wrapperpainorburning_while_urinating_six_months");
      let form_field_wrapperpainorburning_while_urinating_last_month = document.querySelector(".form_field_wrapperpainorburning_while_urinating_last_month");
      createPainBurningUrinatingDivElement.append(form_field_wrapperpainBurningUrinating, form_field_wrapperpainorburning_while_urinating_six_months, form_field_wrapperpainorburning_while_urinating_last_month, form_field_wrapperpainorburning_while_urinating_today);
    } else {
      createPainBurningUrinatingDivElement.append(form_field_wrapperpainBurningUrinating, form_field_wrapperpainorburning_while_urinating_today);
    }

    let form_field_wrapperbloodyUrine = document.querySelector(".form_field_wrapperbloodyUrine");
    // let form_field_wrapperbloody_urine_six_months = document.querySelector(".form_field_wrapperbloody_urine_six_months");
    // let form_field_wrapperbloody_urine_last_month = document.querySelector(".form_field_wrapperbloody_urine_last_month");
    let form_field_wrapperbloody_urine_today = document.querySelector(".form_field_wrapperbloody_urine_today");
    let createBloodyUrineDivElement = document.createElement("div");
    createBloodyUrineDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperbloody_urine_six_months = document.querySelector(".form_field_wrapperbloody_urine_six_months");
      let form_field_wrapperbloody_urine_last_month = document.querySelector(".form_field_wrapperbloody_urine_last_month");
      createBloodyUrineDivElement.append(form_field_wrapperbloodyUrine, form_field_wrapperbloody_urine_six_months, form_field_wrapperbloody_urine_last_month, form_field_wrapperbloody_urine_today);
    } else {
      createBloodyUrineDivElement.append(form_field_wrapperbloodyUrine, form_field_wrapperbloody_urine_today);
    }

    let form_field_wrapperrunnyNose = document.querySelector(".form_field_wrapperrunnyNose");
    // let form_field_wrapperrunny_nose_six_months = document.querySelector(".form_field_wrapperrunny_nose_six_months");
    // let form_field_wrapperrunny_nose_last_month = document.querySelector(".form_field_wrapperrunny_nose_last_month");
    let form_field_wrapperrunny_nose_today = document.querySelector(".form_field_wrapperrunny_nose_today");
    let createRunnyNoseDivElement = document.createElement("div");
    createRunnyNoseDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperrunny_nose_six_months = document.querySelector(".form_field_wrapperrunny_nose_six_months");
      let form_field_wrapperrunny_nose_last_month = document.querySelector(".form_field_wrapperrunny_nose_last_month");
      createRunnyNoseDivElement.append(form_field_wrapperrunnyNose, form_field_wrapperrunny_nose_six_months, form_field_wrapperrunny_nose_last_month, form_field_wrapperrunny_nose_today);
    } else {
      createRunnyNoseDivElement.append(form_field_wrapperrunnyNose, form_field_wrapperrunny_nose_today);
    }

    let form_field_wrappersoreThroat = document.querySelector(".form_field_wrappersoreThroat");
    // let form_field_wrappersore_throat_six_months = document.querySelector(".form_field_wrappersore_throat_six_months");
    // let form_field_wrappersore_throat_last_month = document.querySelector(".form_field_wrappersore_throat_last_month");
    let form_field_wrappersore_throat_today = document.querySelector(".form_field_wrappersore_throat_today");
    let createSoreThroatDivElement = document.createElement("div");
    createSoreThroatDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappersore_throat_six_months = document.querySelector(".form_field_wrappersore_throat_six_months");
      let form_field_wrappersore_throat_last_month = document.querySelector(".form_field_wrappersore_throat_last_month");
      createSoreThroatDivElement.append(form_field_wrappersoreThroat, form_field_wrappersore_throat_six_months, form_field_wrappersore_throat_last_month, form_field_wrappersore_throat_today);
    } else {
      createSoreThroatDivElement.append(form_field_wrappersoreThroat, form_field_wrappersore_throat_today);
    }

    let form_field_wrapperswollenGlands = document.querySelector(".form_field_wrapperswollenGlands");
    // let form_field_wrapperswollen_glands_six_months = document.querySelector(".form_field_wrapperswollen_glands_six_months");
    // let form_field_wrapperswollen_glands_last_month = document.querySelector(".form_field_wrapperswollen_glands_last_month");
    let form_field_wrapperswollen_glands_today = document.querySelector(".form_field_wrapperswollen_glands_today");
    let createSwollenGlandsDivElement = document.createElement("div");
    createSwollenGlandsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswollen_glands_six_months = document.querySelector(".form_field_wrapperswollen_glands_six_months");
      let form_field_wrapperswollen_glands_last_month = document.querySelector(".form_field_wrapperswollen_glands_last_month");
      createSwollenGlandsDivElement.append(form_field_wrapperswollenGlands, form_field_wrapperswollen_glands_six_months, form_field_wrapperswollen_glands_last_month, form_field_wrapperswollen_glands_today);
    } else {
      createSwollenGlandsDivElement.append(form_field_wrapperswollenGlands, form_field_wrapperswollen_glands_today);
    }

    let form_field_wrappertroubleBreathing = document.querySelector(".form_field_wrappertroubleBreathing");
    // let form_field_wrappertrouble_breathing_six_months = document.querySelector(".form_field_wrappertrouble_breathing_six_months");
    // let form_field_wrappertrouble_breathing_last_month = document.querySelector(".form_field_wrappertrouble_breathing_last_month");
    let form_field_wrappertrouble_breathing_today = document.querySelector(".form_field_wrappertrouble_breathing_today");
    let createTroubleBreathingDivElement = document.createElement("div");
    createTroubleBreathingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertrouble_breathing_six_months = document.querySelector(".form_field_wrappertrouble_breathing_six_months");
      let form_field_wrappertrouble_breathing_last_month = document.querySelector(".form_field_wrappertrouble_breathing_last_month");
      createTroubleBreathingDivElement.append(form_field_wrappertroubleBreathing, form_field_wrappertrouble_breathing_six_months, form_field_wrappertrouble_breathing_last_month, form_field_wrappertrouble_breathing_today);
    } else {
      createTroubleBreathingDivElement.append(form_field_wrappertroubleBreathing, form_field_wrappertrouble_breathing_today);
    }

    let form_field_wrapperhighPitchedBreathing = document.querySelector(".form_field_wrapperhighPitchedBreathing");
    // let form_field_wrapperhigh_pitched_sound_when_breathing_in_six_months = document.querySelector(".form_field_wrapperhigh_pitched_sound_when_breathing_in_six_months");
    // let form_field_wrapperhigh_pitched_sound_when_breathing_in_last_month = document.querySelector(".form_field_wrapperhigh_pitched_sound_when_breathing_in_last_month");
    let form_field_wrapperhigh_pitched_sound_when_breathing_in_today = document.querySelector(".form_field_wrapperhigh_pitched_sound_when_breathing_in_today");
    let createHighPitchedBreathingDivElement = document.createElement("div");
    createHighPitchedBreathingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperhigh_pitched_sound_when_breathing_in_six_months = document.querySelector(".form_field_wrapperhigh_pitched_sound_when_breathing_in_six_months");
      let form_field_wrapperhigh_pitched_sound_when_breathing_in_last_month = document.querySelector(".form_field_wrapperhigh_pitched_sound_when_breathing_in_last_month");
      createHighPitchedBreathingDivElement.append(form_field_wrapperhighPitchedBreathing, form_field_wrapperhigh_pitched_sound_when_breathing_in_six_months, form_field_wrapperhigh_pitched_sound_when_breathing_in_last_month, form_field_wrapperhigh_pitched_sound_when_breathing_in_today);
    } else {
      createHighPitchedBreathingDivElement.append(form_field_wrapperhighPitchedBreathing, form_field_wrapperhigh_pitched_sound_when_breathing_in_today);
    }

    let form_field_wrapperburningPainUrinating = document.querySelector(".form_field_wrapperburningPainUrinating");
    // let form_field_wrapperburning_and_pain_while_urinating_six_months = document.querySelector(".form_field_wrapperburning_and_pain_while_urinating_six_months");
    // let form_field_wrapperburning_and_pain_while_urinating_last_month = document.querySelector(".form_field_wrapperburning_and_pain_while_urinating_last_month");
    let form_field_wrapperburning_and_pain_while_urinating_today = document.querySelector(".form_field_wrapperburning_and_pain_while_urinating_today");
    let createBurningPainUrinatingDivElement = document.createElement("div");
    createBurningPainUrinatingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperburning_and_pain_while_urinating_six_months = document.querySelector(".form_field_wrapperburning_and_pain_while_urinating_six_months");
      let form_field_wrapperburning_and_pain_while_urinating_last_month = document.querySelector(".form_field_wrapperburning_and_pain_while_urinating_last_month");
      createBurningPainUrinatingDivElement.append(form_field_wrapperburningPainUrinating, form_field_wrapperburning_and_pain_while_urinating_six_months, form_field_wrapperburning_and_pain_while_urinating_last_month, form_field_wrapperburning_and_pain_while_urinating_today);
    } else {
      createBurningPainUrinatingDivElement.append(form_field_wrapperburningPainUrinating, form_field_wrapperburning_and_pain_while_urinating_today);
    }

    let form_field_wrapperredEyes = document.querySelector(".form_field_wrapperredEyes");
    // let form_field_wrapperred_eyes_six_months = document.querySelector(".form_field_wrapperred_eyes_six_months");
    // let form_field_wrapperred_eyes_last_month = document.querySelector(".form_field_wrapperred_eyes_last_month");
    let form_field_wrapperred_eyes_today = document.querySelector(".form_field_wrapperred_eyes_today");
    let createRedEyesDivElement = document.createElement("div");
    createRedEyesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperred_eyes_six_months = document.querySelector(".form_field_wrapperred_eyes_six_months");
      let form_field_wrapperred_eyes_last_month = document.querySelector(".form_field_wrapperred_eyes_last_month");
      createRedEyesDivElement.append(form_field_wrapperredEyes, form_field_wrapperred_eyes_six_months, form_field_wrapperred_eyes_last_month, form_field_wrapperred_eyes_today);
    } else {
      createRedEyesDivElement.append(form_field_wrapperredEyes, form_field_wrapperred_eyes_today);
    }
    let form_field_wrapperdischargeYourEyes = document.querySelector(".form_field_wrapperdischargeYourEyes");
    // let form_field_wrapperdischarge_from_your_eyes_six_months = document.querySelector(".form_field_wrapperdischarge_from_your_eyes_six_months");
    // let form_field_wrapperdischarge_from_your_eyes_last_month = document.querySelector(".form_field_wrapperdischarge_from_your_eyes_last_month");
    let form_field_wrapperdischarge_from_your_eyes_today = document.querySelector(".form_field_wrapperdischarge_from_your_eyes_today");
    let createDischargeYourEyesDivElement = document.createElement("div");
    createDischargeYourEyesDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdischarge_from_your_eyes_six_months = document.querySelector(".form_field_wrapperdischarge_from_your_eyes_six_months");
      let form_field_wrapperdischarge_from_your_eyes_last_month = document.querySelector(".form_field_wrapperdischarge_from_your_eyes_last_month");
      createDischargeYourEyesDivElement.append(form_field_wrapperdischargeYourEyes, form_field_wrapperdischarge_from_your_eyes_six_months, form_field_wrapperdischarge_from_your_eyes_last_month, form_field_wrapperdischarge_from_your_eyes_today);
    } else {
      createDischargeYourEyesDivElement.append(form_field_wrapperdischargeYourEyes, form_field_wrapperdischarge_from_your_eyes_today);
    }

    let form_field_wrapperabdominalPain = document.querySelector(".form_field_wrapperabdominalPain");
    // let form_field_wrapperabdominal_pain_six_months = document.querySelector(".form_field_wrapperabdominal_pain_six_months");
    // let form_field_wrapperabdominal_pain_last_month = document.querySelector(".form_field_wrapperabdominal_pain_last_month");
    let form_field_wrapperabdominal_pain_today = document.querySelector(".form_field_wrapperabdominal_pain_today");
    let createAbdominalPainDivElement = document.createElement("div");
    createAbdominalPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperabdominal_pain_six_months = document.querySelector(".form_field_wrapperabdominal_pain_six_months");
      let form_field_wrapperabdominal_pain_last_month = document.querySelector(".form_field_wrapperabdominal_pain_last_month");
      createAbdominalPainDivElement.append(form_field_wrapperabdominalPain, form_field_wrapperabdominal_pain_six_months, form_field_wrapperabdominal_pain_last_month, form_field_wrapperabdominal_pain_today);
    } else {
      createAbdominalPainDivElement.append(form_field_wrapperabdominalPain, form_field_wrapperabdominal_pain_today);
    }

    let form_field_wrapperchangeUrineColor = document.querySelector(".form_field_wrapperchangeUrineColor");
    // let form_field_wrapperchange_in_urine_color_six_months = document.querySelector(".form_field_wrapperchange_in_urine_color_six_months");
    // let form_field_wrapperchange_in_urine_color_last_month = document.querySelector(".form_field_wrapperchange_in_urine_color_last_month");
    let form_field_wrapperchange_in_urine_color_today = document.querySelector(".form_field_wrapperchange_in_urine_color_today");
    let createChangeUrineColorDivElement = document.createElement("div");
    createChangeUrineColorDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchange_in_urine_color_six_months = document.querySelector(".form_field_wrapperchange_in_urine_color_six_months");
      let form_field_wrapperchange_in_urine_color_last_month = document.querySelector(".form_field_wrapperchange_in_urine_color_last_month");
      createChangeUrineColorDivElement.append(form_field_wrapperchangeUrineColor, form_field_wrapperchange_in_urine_color_six_months, form_field_wrapperchange_in_urine_color_last_month, form_field_wrapperchange_in_urine_color_today);
    } else {
      createChangeUrineColorDivElement.append(form_field_wrapperchangeUrineColor, form_field_wrapperchange_in_urine_color_today);
    }

    let form_field_wrapperpainfulEjaculation = document.querySelector(".form_field_wrapperpainfulEjaculation");
    // let form_field_wrapperpainful_ejaculation_six_months = document.querySelector(".form_field_wrapperpainful_ejaculation_six_months");
    // let form_field_wrapperpainful_ejaculation_last_month = document.querySelector(".form_field_wrapperpainful_ejaculation_last_month");
    let form_field_wrapperpainful_ejaculation_today = document.querySelector(".form_field_wrapperpainful_ejaculation_today");
    let createPainfulEjaculationDivElement = document.createElement("div");
    createPainfulEjaculationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpainful_ejaculation_six_months = document.querySelector(".form_field_wrapperpainful_ejaculation_six_months");
      let form_field_wrapperpainful_ejaculation_last_month = document.querySelector(".form_field_wrapperpainful_ejaculation_last_month");
      createPainfulEjaculationDivElement.append(form_field_wrapperpainfulEjaculation, form_field_wrapperpainful_ejaculation_six_months, form_field_wrapperpainful_ejaculation_last_month, form_field_wrapperpainful_ejaculation_today);
    } else {
      createPainfulEjaculationDivElement.append(form_field_wrapperpainfulEjaculation, form_field_wrapperpainful_ejaculation_today);
    }

    let form_field_wrappermissedPeriod = document.querySelector(".form_field_wrappermissedPeriod");
    // let form_field_wrapperamissed_period_six_months = document.querySelector(".form_field_wrapperamissed_period_six_months");
    // let form_field_wrapperamissed_period_last_month = document.querySelector(".form_field_wrapperamissed_period_last_month");
    let form_field_wrapperamissed_period_today = document.querySelector(".form_field_wrapperamissed_period_today");
    let createMissedPeriodDivElement = document.createElement("div");
    createMissedPeriodDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperamissed_period_six_months = document.querySelector(".form_field_wrapperamissed_period_six_months");
      let form_field_wrapperamissed_period_last_month = document.querySelector(".form_field_wrapperamissed_period_last_month");
      createMissedPeriodDivElement.append(form_field_wrappermissedPeriod, form_field_wrapperamissed_period_six_months, form_field_wrapperamissed_period_last_month, form_field_wrapperamissed_period_today);
    } else {
      createMissedPeriodDivElement.append(form_field_wrappermissedPeriod, form_field_wrapperamissed_period_today);
    }

    let form_field_wrapperswollenTenderBreasts = document.querySelector(".form_field_wrapperswollenTenderBreasts");
    // let form_field_wrapperswollenortender_breasts_six_months = document.querySelector(".form_field_wrapperswollenortender_breasts_six_months");
    // let form_field_wrapperswollenortender_breasts_last_month = document.querySelector(".form_field_wrapperswollenortender_breasts_last_month");
    let form_field_wrapperswollenortender_breasts_today = document.querySelector(".form_field_wrapperswollenortender_breasts_today");
    let createSwollenTenderBreastsDivElement = document.createElement("div");
    createSwollenTenderBreastsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswollenortender_breasts_six_months = document.querySelector(".form_field_wrapperswollenortender_breasts_six_months");
      let form_field_wrapperswollenortender_breasts_last_month = document.querySelector(".form_field_wrapperswollenortender_breasts_last_month");
      createSwollenTenderBreastsDivElement.append(form_field_wrapperswollenTenderBreasts, form_field_wrapperswollenortender_breasts_six_months, form_field_wrapperswollenortender_breasts_last_month, form_field_wrapperswollenortender_breasts_today);
    } else {
      createSwollenTenderBreastsDivElement.append(form_field_wrapperswollenTenderBreasts, form_field_wrapperswollenortender_breasts_today);
    }

    let form_field_wrappernauseaWithVomiting = document.querySelector(".form_field_wrappernauseaWithVomiting");
    // let form_field_wrappernausea_withorwithout_vomiting_six_months = document.querySelector(".form_field_wrappernausea_withorwithout_vomiting_six_months");
    // let form_field_wrappernausea_withorwithout_vomiting_last_month = document.querySelector(".form_field_wrappernausea_withorwithout_vomiting_last_month");
    let form_field_wrappernausea_withorwithout_vomiting_today = document.querySelector(".form_field_wrappernausea_withorwithout_vomiting_today");
    let createNauseaWithVomitingDivElement = document.createElement("div");
    createNauseaWithVomitingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernausea_withorwithout_vomiting_six_months = document.querySelector(".form_field_wrappernausea_withorwithout_vomiting_six_months");
      let form_field_wrappernausea_withorwithout_vomiting_last_month = document.querySelector(".form_field_wrappernausea_withorwithout_vomiting_last_month");
      createNauseaWithVomitingDivElement.append(form_field_wrappernauseaWithVomiting, form_field_wrappernausea_withorwithout_vomiting_six_months, form_field_wrappernausea_withorwithout_vomiting_last_month, form_field_wrappernausea_withorwithout_vomiting_today);
    } else {
      createNauseaWithVomitingDivElement.append(form_field_wrappernauseaWithVomiting, form_field_wrappernausea_withorwithout_vomiting_today);
    }

    let form_field_wrapperlightSpotting = document.querySelector(".form_field_wrapperlightSpotting");
    // let form_field_wrapperlight_spotting_six_months = document.querySelector(".form_field_wrapperlight_spotting_six_months");
    // let form_field_wrapperlight_spotting_last_month = document.querySelector(".form_field_wrapperlight_spotting_last_month");
    let form_field_wrapperlight_spotting_today = document.querySelector(".form_field_wrapperlight_spotting_today");
    let createLightSpottingDivElement = document.createElement("div");
    createLightSpottingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlight_spotting_six_months = document.querySelector(".form_field_wrapperlight_spotting_six_months");
      let form_field_wrapperlight_spotting_last_month = document.querySelector(".form_field_wrapperlight_spotting_last_month");
      createLightSpottingDivElement.append(form_field_wrapperlightSpotting, form_field_wrapperlight_spotting_six_months, form_field_wrapperlight_spotting_last_month, form_field_wrapperlight_spotting_today);
    } else {
      createLightSpottingDivElement.append(form_field_wrapperlightSpotting, form_field_wrapperlight_spotting_today);
    }

    let form_field_wrappercramping = document.querySelector(".form_field_wrappercramping");
    // let form_field_wrappercramping_six_months = document.querySelector(".form_field_wrappercramping_six_months");
    // let form_field_wrappercramping_last_month = document.querySelector(".form_field_wrappercramping_last_month");
    let form_field_wrappercramping_today = document.querySelector(".form_field_wrappercramping_today");
    let createCrampingDivElement = document.createElement("div");
    createCrampingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercramping_six_months = document.querySelector(".form_field_wrappercramping_six_months");
      let form_field_wrappercramping_last_month = document.querySelector(".form_field_wrappercramping_last_month");
      createCrampingDivElement.append(form_field_wrappercramping, form_field_wrappercramping_six_months, form_field_wrappercramping_last_month, form_field_wrappercramping_today);
    } else {
      createCrampingDivElement.append(form_field_wrappercramping, form_field_wrappercramping_today);
    }

    let form_field_wrapperbloating = document.querySelector(".form_field_wrapperbloating");
    // let form_field_wrapperbloating_six_months = document.querySelector(".form_field_wrapperbloating_six_months");
    // let form_field_wrapperbloating_last_month = document.querySelector(".form_field_wrapperbloating_last_month");
    let form_field_wrapperbloating_today = document.querySelector(".form_field_wrapperbloating_today");
    let createBloatingDivElement = document.createElement("div");
    createBloatingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperbloating_six_months = document.querySelector(".form_field_wrapperbloating_six_months");
      let form_field_wrapperbloating_last_month = document.querySelector(".form_field_wrapperbloating_last_month");
      createBloatingDivElement.append(form_field_wrapperbloating, form_field_wrapperbloating_six_months, form_field_wrapperbloating_last_month, form_field_wrapperbloating_today);
    } else {
      createBloatingDivElement.append(form_field_wrapperbloating, form_field_wrapperbloating_today);
    }

    let form_field_wrappermoodSwings = document.querySelector(".form_field_wrappermoodSwings");
    // let form_field_wrappermood_swings_six_months = document.querySelector(".form_field_wrappermood_swings_six_months");
    // let form_field_wrappermood_swings_last_month = document.querySelector(".form_field_wrappermood_swings_last_month");
    let form_field_wrappermood_swings_today = document.querySelector(".form_field_wrappermood_swings_today");
    let createMoodSwingsDivElement = document.createElement("div");
    createMoodSwingsDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappermood_swings_six_months = document.querySelector(".form_field_wrappermood_swings_six_months");
      let form_field_wrappermood_swings_last_month = document.querySelector(".form_field_wrappermood_swings_last_month");
      createMoodSwingsDivElement.append(form_field_wrappermoodSwings, form_field_wrappermood_swings_six_months, form_field_wrappermood_swings_last_month, form_field_wrappermood_swings_today);
    } else {
      createMoodSwingsDivElement.append(form_field_wrappermoodSwings, form_field_wrappermood_swings_today);
    }

    let form_field_wrapperskinIrritationDimpling = document.querySelector(".form_field_wrapperskinIrritationDimpling");
    // let form_field_wrapperskin_dimpling_six_months = document.querySelector(".form_field_wrapperskin_dimpling_six_months");
    // let form_field_wrapperskin_dimpling_last_month = document.querySelector(".form_field_wrapperskin_dimpling_last_month");
    let form_field_wrapperskin_dimpling_today = document.querySelector(".form_field_wrapperskin_dimpling_today");
    let createSkinIrritationDimplingDivElement = document.createElement("div");
    createSkinIrritationDimplingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperskin_dimpling_six_months = document.querySelector(".form_field_wrapperskin_dimpling_six_months");
      let form_field_wrapperskin_dimpling_last_month = document.querySelector(".form_field_wrapperskin_dimpling_last_month");
      createSkinIrritationDimplingDivElement.append(form_field_wrapperskinIrritationDimpling, form_field_wrapperskin_dimpling_six_months, form_field_wrapperskin_dimpling_last_month, form_field_wrapperskin_dimpling_today);
    } else {
      createSkinIrritationDimplingDivElement.append(form_field_wrapperskinIrritationDimpling, form_field_wrapperskin_dimpling_today);
    }

    let form_field_wrapperbreastNipplePain = document.querySelector(".form_field_wrapperbreastNipplePain");
    // let form_field_wrapperbreast_pain_six_months = document.querySelector(".form_field_wrapperbreast_pain_six_months");
    // let form_field_wrapperbreast_pain_last_month = document.querySelector(".form_field_wrapperbreast_pain_last_month");
    let form_field_wrapperbreast_pain_today = document.querySelector(".form_field_wrapperbreast_pain_today");
    let createBreastNipplePainDivElement = document.createElement("div");
    createBreastNipplePainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperbreast_pain_six_months = document.querySelector(".form_field_wrapperbreast_pain_six_months");
      let form_field_wrapperbreast_pain_last_month = document.querySelector(".form_field_wrapperbreast_pain_last_month");
      createBreastNipplePainDivElement.append(form_field_wrapperbreastNipplePain, form_field_wrapperbreast_pain_six_months, form_field_wrapperbreast_pain_last_month, form_field_wrapperbreast_pain_today);
    } else {
      createBreastNipplePainDivElement.append(form_field_wrapperbreastNipplePain, form_field_wrapperbreast_pain_today);
    }

    let form_field_wrappernippleRetraction = document.querySelector(".form_field_wrappernippleRetraction");
    // let form_field_wrappernipple_retraction_six_months = document.querySelector(".form_field_wrappernipple_retraction_six_months");
    // let form_field_wrappernipple_retraction_last_month = document.querySelector(".form_field_wrappernipple_retraction_last_month");
    let form_field_wrappernipple_retraction_today = document.querySelector(".form_field_wrappernipple_retraction_today");
    let createNippleRetractionDivElement = document.createElement("div");
    createNippleRetractionDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernipple_retraction_six_months = document.querySelector(".form_field_wrappernipple_retraction_six_months");
      let form_field_wrappernipple_retraction_last_month = document.querySelector(".form_field_wrappernipple_retraction_last_month");
      createNippleRetractionDivElement.append(form_field_wrappernippleRetraction, form_field_wrappernipple_retraction_six_months, form_field_wrappernipple_retraction_last_month, form_field_wrappernipple_retraction_today);
    } else {
      createNippleRetractionDivElement.append(form_field_wrappernippleRetraction, form_field_wrappernipple_retraction_today);
    }

    let form_field_wrapperrednessScaliness = document.querySelector(".form_field_wrapperrednessScaliness");
    // let form_field_wrapperredness_six_months = document.querySelector(".form_field_wrapperredness_six_months");
    // let form_field_wrapperredness_last_month = document.querySelector(".form_field_wrapperredness_last_month");
    let form_field_wrapperredness_today = document.querySelector(".form_field_wrapperredness_today");
    let createRednessScalinessDivElement = document.createElement("div");
    createRednessScalinessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperredness_six_months = document.querySelector(".form_field_wrapperredness_six_months");
      let form_field_wrapperredness_last_month = document.querySelector(".form_field_wrapperredness_last_month");
      createRednessScalinessDivElement.append(form_field_wrapperrednessScaliness, form_field_wrapperredness_six_months, form_field_wrapperredness_last_month, form_field_wrapperredness_today);
    } else {
      createRednessScalinessDivElement.append(form_field_wrapperrednessScaliness, form_field_wrapperredness_today);
    }

    let form_field_wrappernippleDischarge = document.querySelector(".form_field_wrappernippleDischarge");
    // let form_field_wrappernipple_discharge_six_months = document.querySelector(".form_field_wrappernipple_discharge_six_months");
    // let form_field_wrappernipple_discharge_last_month = document.querySelector(".form_field_wrappernipple_discharge_last_month");
    let form_field_wrappernipple_discharge_today = document.querySelector(".form_field_wrappernipple_discharge_today");
    let createNippleDischargeDivElement = document.createElement("div");
    createNippleDischargeDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappernipple_discharge_six_months = document.querySelector(".form_field_wrappernipple_discharge_six_months");
      let form_field_wrappernipple_discharge_last_month = document.querySelector(".form_field_wrappernipple_discharge_last_month");
      createNippleDischargeDivElement.append(form_field_wrappernippleDischarge, form_field_wrappernipple_discharge_six_months, form_field_wrappernipple_discharge_last_month, form_field_wrappernipple_discharge_today);
    } else {
      createNippleDischargeDivElement.append(form_field_wrappernippleDischarge, form_field_wrappernipple_discharge_today);
    }

    let form_field_wrapperabdominalBloatingSwelling = document.querySelector(".form_field_wrapperabdominalBloatingSwelling");
    // let form_field_wrapperabdominal_swelling_six_months = document.querySelector(".form_field_wrapperabdominal_swelling_six_months");
    // let form_field_wrapperabdominal_swelling_last_month = document.querySelector(".form_field_wrapperabdominal_swelling_last_month");
    let form_field_wrapperabdominal_swelling_today = document.querySelector(".form_field_wrapperabdominal_swelling_today");
    let createAbdominalBloatingSwellingDivElement = document.createElement("div");
    createAbdominalBloatingSwellingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperabdominal_swelling_six_months = document.querySelector(".form_field_wrapperabdominal_swelling_six_months");
      let form_field_wrapperabdominal_swelling_last_month = document.querySelector(".form_field_wrapperabdominal_swelling_last_month");
      createAbdominalBloatingSwellingDivElement.append(form_field_wrapperabdominalBloatingSwelling, form_field_wrapperabdominal_swelling_six_months, form_field_wrapperabdominal_swelling_last_month, form_field_wrapperabdominal_swelling_today);
    } else {
      createAbdominalBloatingSwellingDivElement.append(form_field_wrapperabdominalBloatingSwelling, form_field_wrapperabdominal_swelling_today);
    }

    let form_field_wrapperquicklyFellingEating = document.querySelector(".form_field_wrapperquicklyFellingEating");
    // let form_field_wrapperquickly_feeling_six_months = document.querySelector(".form_field_wrapperquickly_feeling_six_months");
    // let form_field_wrapperquickly_feeling_last_month = document.querySelector(".form_field_wrapperquickly_feeling_last_month");
    let form_field_wrapperquickly_feeling_today = document.querySelector(".form_field_wrapperquickly_feeling_today");
    let createQuicklyFellingEatingDivElement = document.createElement("div");
    createQuicklyFellingEatingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperquickly_feeling_six_months = document.querySelector(".form_field_wrapperquickly_feeling_six_months");
      let form_field_wrapperquickly_feeling_last_month = document.querySelector(".form_field_wrapperquickly_feeling_last_month");
      createQuicklyFellingEatingDivElement.append(form_field_wrapperquicklyFellingEating, form_field_wrapperquickly_feeling_six_months, form_field_wrapperquickly_feeling_last_month, form_field_wrapperquickly_feeling_today);
    } else {
      createQuicklyFellingEatingDivElement.append(form_field_wrapperquicklyFellingEating, form_field_wrapperquickly_feeling_today);
    }

    let form_field_wrapperdiscomfortPelvicArea = document.querySelector(".form_field_wrapperdiscomfortPelvicArea");
    // let form_field_wrapperdiscomfort_six_months = document.querySelector(".form_field_wrapperdiscomfort_six_months");
    // let form_field_wrapperdiscomfort_last_month = document.querySelector(".form_field_wrapperdiscomfort_last_month");
    let form_field_wrapperdiscomfort_today = document.querySelector(".form_field_wrapperdiscomfort_today");
    let createDiscomfortPelvicAreaDivElement = document.createElement("div");
    createDiscomfortPelvicAreaDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdiscomfort_six_months = document.querySelector(".form_field_wrapperdiscomfort_six_months");
      let form_field_wrapperdiscomfort_last_month = document.querySelector(".form_field_wrapperdiscomfort_last_month");
      createDiscomfortPelvicAreaDivElement.append(form_field_wrapperdiscomfortPelvicArea, form_field_wrapperdiscomfort_six_months, form_field_wrapperdiscomfort_last_month, form_field_wrapperdiscomfort_today);
    } else {
      createDiscomfortPelvicAreaDivElement.append(form_field_wrapperdiscomfortPelvicArea, form_field_wrapperdiscomfort_today);
    }
    let form_field_wrapperbloodInStool = document.querySelector(".form_field_wrapperbloodInStool");
    // let form_field_wrapperblood_stool_six_months = document.querySelector(".form_field_wrapperblood_stool_six_months");
    // let form_field_wrapperblood_stool_last_month = document.querySelector(".form_field_wrapperblood_stool_last_month");
    let form_field_wrapperblood_stool_today = document.querySelector(".form_field_wrapperblood_stool_today");
    let createBloodInStoolDivElement = document.createElement("div");
    createBloodInStoolDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperblood_stool_six_months = document.querySelector(".form_field_wrapperblood_stool_six_months");
      let form_field_wrapperblood_stool_last_month = document.querySelector(".form_field_wrapperblood_stool_last_month");
      createBloodInStoolDivElement.append(form_field_wrapperbloodInStool, form_field_wrapperblood_stool_six_months, form_field_wrapperblood_stool_last_month, form_field_wrapperblood_stool_today);
    } else {
      createBloodInStoolDivElement.append(form_field_wrapperbloodInStool, form_field_wrapperblood_stool_today);
    }

    let form_field_wrapperconstipation = document.querySelector(".form_field_wrapperconstipation");
    // let form_field_wrapperconstipation_six_months = document.querySelector(".form_field_wrapperconstipation_six_months");
    // let form_field_wrapperconstipation_last_month = document.querySelector(".form_field_wrapperconstipation_last_month");
    let form_field_wrapperconstipation_today = document.querySelector(".form_field_wrapperconstipation_today");
    let createConstipationDivElement = document.createElement("div");
    createConstipationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperconstipation_six_months = document.querySelector(".form_field_wrapperconstipation_six_months");
      let form_field_wrapperconstipation_last_month = document.querySelector(".form_field_wrapperconstipation_last_month");
      createConstipationDivElement.append(form_field_wrapperconstipation, form_field_wrapperconstipation_six_months, form_field_wrapperconstipation_last_month, form_field_wrapperconstipation_today);
    } else {
      createConstipationDivElement.append(form_field_wrapperconstipation, form_field_wrapperconstipation_today);
    }

    let form_field_wrapperdiarrhea = document.querySelector(".form_field_wrapperdiarrhea");
    // let form_field_wrapperdiarrhea_six_months = document.querySelector(".form_field_wrapperdiarrhea_six_months");
    // let form_field_wrapperdiarrhea_last_month = document.querySelector(".form_field_wrapperdiarrhea_last_month");
    let form_field_wrapperdiarrhea_today = document.querySelector(".form_field_wrapperdiarrhea_today");
    let createDiarrheaDivElement = document.createElement("div");
    createDiarrheaDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdiarrhea_six_months = document.querySelector(".form_field_wrapperdiarrhea_six_months");
      let form_field_wrapperdiarrhea_last_month = document.querySelector(".form_field_wrapperdiarrhea_last_month");
      createDiarrheaDivElement.append(form_field_wrapperdiarrhea, form_field_wrapperdiarrhea_six_months, form_field_wrapperdiarrhea_last_month, form_field_wrapperdiarrhea_today);
    } else {
      createDiarrheaDivElement.append(form_field_wrapperdiarrhea, form_field_wrapperdiarrhea_today);
    }

    let form_field_wrappercoughingUpBlood = document.querySelector(".form_field_wrappercoughingUpBlood");
    // let form_field_wrappercoughing_six_months = document.querySelector(".form_field_wrappercoughing_six_months");
    // let form_field_wrappercoughing_last_month = document.querySelector(".form_field_wrappercoughing_last_month");
    let form_field_wrappercoughing_today = document.querySelector(".form_field_wrappercoughing_today");
    let createCoughingUpBloodDivElement = document.createElement("div");
    createCoughingUpBloodDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappercoughing_six_months = document.querySelector(".form_field_wrappercoughing_six_months");
      let form_field_wrappercoughing_last_month = document.querySelector(".form_field_wrappercoughing_last_month");
      createCoughingUpBloodDivElement.append(form_field_wrappercoughingUpBlood, form_field_wrappercoughing_six_months, form_field_wrappercoughing_last_month, form_field_wrappercoughing_today);
    } else {
      createCoughingUpBloodDivElement.append(form_field_wrappercoughingUpBlood, form_field_wrappercoughing_today);
    }

    let form_field_wrapperdarkColoredUrine = document.querySelector(".form_field_wrapperdarkColoredUrine");
    // let form_field_wrapperdark_colored_urine_six_months = document.querySelector(".form_field_wrapperdark_colored_urine_six_months");
    // let form_field_wrapperdark_colored_urine_last_month = document.querySelector(".form_field_wrapperdark_colored_urine_last_month");
    let form_field_wrapperdark_colored_urine_today = document.querySelector(".form_field_wrapperdark_colored_urine_today");
    let createDarkColoredUrineDivElement = document.createElement("div");
    createDarkColoredUrineDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdark_colored_urine_six_months = document.querySelector(".form_field_wrapperdark_colored_urine_six_months");
      let form_field_wrapperdark_colored_urine_last_month = document.querySelector(".form_field_wrapperdark_colored_urine_last_month");
      createDarkColoredUrineDivElement.append(form_field_wrapperdarkColoredUrine, form_field_wrapperdark_colored_urine_six_months, form_field_wrapperdark_colored_urine_last_month, form_field_wrapperdark_colored_urine_today);
    } else {
      createDarkColoredUrineDivElement.append(form_field_wrapperdarkColoredUrine, form_field_wrapperdark_colored_urine_today);
    }

    let form_field_wrapperstollsLookBlack = document.querySelector(".form_field_wrapperstollsLookBlack");
    // let form_field_wrapperstools_black_six_months = document.querySelector(".form_field_wrapperstools_black_six_months");
    // let form_field_wrapperstools_black_last_month = document.querySelector(".form_field_wrapperstools_black_last_month");
    let form_field_wrapperstools_black_today = document.querySelector(".form_field_wrapperstools_black_today");
    let createStollsLookBlackDivElement = document.createElement("div");
    createStollsLookBlackDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperstools_black_six_months = document.querySelector(".form_field_wrapperstools_black_six_months");
      let form_field_wrapperstools_black_last_month = document.querySelector(".form_field_wrapperstools_black_last_month");
      createStollsLookBlackDivElement.append(form_field_wrapperstollsLookBlack, form_field_wrapperstools_black_six_months, form_field_wrapperstools_black_last_month, form_field_wrapperstools_black_today);
    } else {
      createStollsLookBlackDivElement.append(form_field_wrapperstollsLookBlack, form_field_wrapperstools_black_today);
    }

    let form_field_wrapperbellyPain = document.querySelector(".form_field_wrapperbellyPain");
    // let form_field_wrapperbelly_pain_six_months = document.querySelector(".form_field_wrapperbelly_pain_six_months");
    // let form_field_wrapperbelly_pain_last_month = document.querySelector(".form_field_wrapperbelly_pain_last_month");
    let form_field_wrapperbelly_pain_today = document.querySelector(".form_field_wrapperbelly_pain_today");
    let createBellyPainDivElement = document.createElement("div");
    createBellyPainDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperbelly_pain_six_months = document.querySelector(".form_field_wrapperbelly_pain_six_months");
      let form_field_wrapperbelly_pain_last_month = document.querySelector(".form_field_wrapperbelly_pain_last_month");
      createBellyPainDivElement.append(form_field_wrapperbellyPain, form_field_wrapperbelly_pain_six_months, form_field_wrapperbelly_pain_last_month, form_field_wrapperbelly_pain_today);
    } else {
      createBellyPainDivElement.append(form_field_wrapperbellyPain, form_field_wrapperbelly_pain_today);
    }

    let form_field_wrappertroubleSwallowing = document.querySelector(".form_field_wrappertroubleSwallowing");
    // let form_field_wrappertrouble_swallowing_six_months = document.querySelector(".form_field_wrappertrouble_swallowing_six_months");
    // let form_field_wrappertrouble_swallowing_last_month = document.querySelector(".form_field_wrappertrouble_swallowing_last_month");
    let form_field_wrappertrouble_swallowing_today = document.querySelector(".form_field_wrappertrouble_swallowing_today");
    let createTroubleSwallowingDivElement = document.createElement("div");
    createTroubleSwallowingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertrouble_swallowing_six_months = document.querySelector(".form_field_wrappertrouble_swallowing_six_months");
      let form_field_wrappertrouble_swallowing_last_month = document.querySelector(".form_field_wrappertrouble_swallowing_last_month");
      createTroubleSwallowingDivElement.append(form_field_wrappertroubleSwallowing, form_field_wrappertrouble_swallowing_six_months, form_field_wrappertrouble_swallowing_last_month, form_field_wrappertrouble_swallowing_today);
    } else {
      createTroubleSwallowingDivElement.append(form_field_wrappertroubleSwallowing, form_field_wrappertrouble_swallowing_today);
    }

    let form_field_wrapperfeelingBloatedEating = document.querySelector(".form_field_wrapperfeelingBloatedEating");
    // let form_field_wrapperfeeling_bloated_six_months = document.querySelector(".form_field_wrapperfeeling_bloated_six_months");
    // let form_field_wrapperfeeling_bloated_last_month = document.querySelector(".form_field_wrapperfeeling_bloated_last_month");
    let form_field_wrapperfeeling_bloated_today = document.querySelector(".form_field_wrapperfeeling_bloated_today");
    let createFeelingBloatedEatingDivElement = document.createElement("div");
    createFeelingBloatedEatingDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfeeling_bloated_six_months = document.querySelector(".form_field_wrapperfeeling_bloated_six_months");
      let form_field_wrapperfeeling_bloated_last_month = document.querySelector(".form_field_wrapperfeeling_bloated_last_month");
      createFeelingBloatedEatingDivElement.append(form_field_wrapperfeelingBloatedEating, form_field_wrapperfeeling_bloated_six_months, form_field_wrapperfeeling_bloated_last_month, form_field_wrapperfeeling_bloated_today);
    } else {
      createFeelingBloatedEatingDivElement.append(form_field_wrapperfeelingBloatedEating, form_field_wrapperfeeling_bloated_today);
    }

    let form_field_wrapperindigestion = document.querySelector(".form_field_wrapperindigestion");
    // let form_field_wrapperindigestion_six_months = document.querySelector(".form_field_wrapperindigestion_six_months");
    // let form_field_wrapperindigestion_last_month = document.querySelector(".form_field_wrapperindigestion_last_month");
    let form_field_wrapperindigestion_today = document.querySelector(".form_field_wrapperindigestion_today");
    let createIndigestionDivElement = document.createElement("div");
    createIndigestionDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperindigestion_six_months = document.querySelector(".form_field_wrapperindigestion_six_months");
      let form_field_wrapperindigestion_last_month = document.querySelector(".form_field_wrapperindigestion_last_month");
      createIndigestionDivElement.append(form_field_wrapperindigestion, form_field_wrapperindigestion_six_months, form_field_wrapperindigestion_last_month, form_field_wrapperindigestion_today);
    } else {
      createIndigestionDivElement.append(form_field_wrapperindigestion, form_field_wrapperindigestion_today);
    }

    let form_field_wrapperdifficultyStartingUrination = document.querySelector(".form_field_wrapperdifficultyStartingUrination");
    // let form_field_wrapperdiff_urination_six_months = document.querySelector(".form_field_wrapperdiff_urination_six_months");
    // let form_field_wrapperdiff_urination_last_month = document.querySelector(".form_field_wrapperdiff_urination_last_month");
    let form_field_wrapperdiff_urination_today = document.querySelector(".form_field_wrapperdiff_urination_today");
    let createDifficultyStartingUrinationDivElement = document.createElement("div");
    createDifficultyStartingUrinationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdiff_urination_six_months = document.querySelector(".form_field_wrapperdiff_urination_six_months");
      let form_field_wrapperdiff_urination_last_month = document.querySelector(".form_field_wrapperdiff_urination_last_month");
      createDifficultyStartingUrinationDivElement.append(form_field_wrapperdifficultyStartingUrination, form_field_wrapperdiff_urination_six_months, form_field_wrapperdiff_urination_last_month, form_field_wrapperdiff_urination_today);
    } else {
      createDifficultyStartingUrinationDivElement.append(form_field_wrapperdifficultyStartingUrination, form_field_wrapperdiff_urination_today);
    }

    let form_field_wrapperweakFlowUrine = document.querySelector(".form_field_wrapperweakFlowUrine");
    // let form_field_wrapperweak_urine_six_months = document.querySelector(".form_field_wrapperweak_urine_six_months");
    // let form_field_wrapperweak_urine_last_month = document.querySelector(".form_field_wrapperweak_urine_last_month");
    let form_field_wrapperweak_urine_today = document.querySelector(".form_field_wrapperweak_urine_today");
    let createWeakFlowUrineDivElement = document.createElement("div");
    createWeakFlowUrineDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperweak_urine_six_months = document.querySelector(".form_field_wrapperweak_urine_six_months");
      let form_field_wrapperweak_urine_last_month = document.querySelector(".form_field_wrapperweak_urine_last_month");
      createWeakFlowUrineDivElement.append(form_field_wrapperweakFlowUrine, form_field_wrapperweak_urine_six_months, form_field_wrapperweak_urine_last_month, form_field_wrapperweak_urine_today);
    } else {
      createWeakFlowUrineDivElement.append(form_field_wrapperweakFlowUrine, form_field_wrapperweak_urine_today);
    }

    let form_field_wrapperurinatingOftenNight = document.querySelector(".form_field_wrapperurinatingOftenNight");
    // let form_field_wrapperurin_often_six_months = document.querySelector(".form_field_wrapperurin_often_six_months");
    // let form_field_wrapperurin_often_last_month = document.querySelector(".form_field_wrapperurin_often_last_month");
    let form_field_wrapperurin_often_today = document.querySelector(".form_field_wrapperurin_often_today");
    let createUrinatingOftenNightDivElement = document.createElement("div");
    createUrinatingOftenNightDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperurin_often_six_months = document.querySelector(".form_field_wrapperurin_often_six_months");
      let form_field_wrapperurin_often_last_month = document.querySelector(".form_field_wrapperurin_often_last_month");
      createUrinatingOftenNightDivElement.append(form_field_wrapperurinatingOftenNight, form_field_wrapperurin_often_six_months, form_field_wrapperurin_often_last_month, form_field_wrapperurin_often_today);
    } else {
      createUrinatingOftenNightDivElement.append(form_field_wrapperurinatingOftenNight, form_field_wrapperurin_often_today);
    }

    let form_field_wrappertroubleBladderCompletely = document.querySelector(".form_field_wrappertroubleBladderCompletely");
    // let form_field_wrappertrouble_empty_six_months = document.querySelector(".form_field_wrappertrouble_empty_six_months");
    // let form_field_wrappertrouble_empty_last_month = document.querySelector(".form_field_wrappertrouble_empty_last_month");
    let form_field_wrappertrouble_empty_today = document.querySelector(".form_field_wrappertrouble_empty_today");
    let createTroubleBladderCompletelyDivElement = document.createElement("div");
    createTroubleBladderCompletelyDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertrouble_empty_six_months = document.querySelector(".form_field_wrappertrouble_empty_six_months");
      let form_field_wrappertrouble_empty_last_month = document.querySelector(".form_field_wrappertrouble_empty_last_month");
      createTroubleBladderCompletelyDivElement.append(form_field_wrappertroubleBladderCompletely, form_field_wrappertrouble_empty_six_months, form_field_wrappertrouble_empty_last_month, form_field_wrappertrouble_empty_today);
    } else {
      createTroubleBladderCompletelyDivElement.append(form_field_wrappertroubleBladderCompletely, form_field_wrappertrouble_empty_today);
    }

    let form_field_wrapperpainDuringUrination = document.querySelector(".form_field_wrapperpainDuringUrination");
    // let form_field_wrapperpain_during_urin_six_months = document.querySelector(".form_field_wrapperpain_during_urin_six_months");
    // let form_field_wrapperpain_during_urin_last_month = document.querySelector(".form_field_wrapperpain_during_urin_last_month");
    let form_field_wrapperpain_during_urin_today = document.querySelector(".form_field_wrapperpain_during_urin_today");
    let createPainDuringUrinationDivElement = document.createElement("div");
    createPainDuringUrinationDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpain_during_urin_six_months = document.querySelector(".form_field_wrapperpain_during_urin_six_months");
      let form_field_wrapperpain_during_urin_last_month = document.querySelector(".form_field_wrapperpain_during_urin_last_month");
      createPainDuringUrinationDivElement.append(form_field_wrapperpainDuringUrination, form_field_wrapperpain_during_urin_six_months, form_field_wrapperpain_during_urin_last_month, form_field_wrapperpain_during_urin_today);
    } else {
      createPainDuringUrinationDivElement.append(form_field_wrapperpainDuringUrination, form_field_wrapperpain_during_urin_today);
    }

    let form_field_wrapperpainBackHipsPelvis = document.querySelector(".form_field_wrapperpainBackHipsPelvis");
    // let form_field_wrapperpain_hips_six_months = document.querySelector(".form_field_wrapperpain_hips_six_months");
    // let form_field_wrapperpain_hips_last_month = document.querySelector(".form_field_wrapperpain_hips_last_month");
    let form_field_wrapperpain_hips_today = document.querySelector(".form_field_wrapperpain_hips_today");
    let createPainBackHipsPelvisDivElement = document.createElement("div");
    createPainBackHipsPelvisDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpain_hips_six_months = document.querySelector(".form_field_wrapperpain_hips_six_months");
      let form_field_wrapperpain_hips_last_month = document.querySelector(".form_field_wrapperpain_hips_last_month");
      createPainBackHipsPelvisDivElement.append(form_field_wrapperpainBackHipsPelvis, form_field_wrapperpain_hips_six_months, form_field_wrapperpain_hips_last_month, form_field_wrapperpain_hips_today);
    } else {
      createPainBackHipsPelvisDivElement.append(form_field_wrapperpainBackHipsPelvis, form_field_wrapperpain_hips_today);
    }

    let form_field_wrapperlumpSwellingTestcle = document.querySelector(".form_field_wrapperlumpSwellingTestcle");
    // let form_field_wrapperlump_or_swelling_six_months = document.querySelector(".form_field_wrapperlump_or_swelling_six_months");
    // let form_field_wrapperlump_or_swelling_last_month = document.querySelector(".form_field_wrapperlump_or_swelling_last_month");
    let form_field_wrapperlump_or_swelling_today = document.querySelector(".form_field_wrapperlump_or_swelling_today");
    let createLumpSwellingTestcleDivElement = document.createElement("div");
    createLumpSwellingTestcleDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlump_or_swelling_six_months = document.querySelector(".form_field_wrapperlump_or_swelling_six_months");
      let form_field_wrapperlump_or_swelling_last_month = document.querySelector(".form_field_wrapperlump_or_swelling_last_month");
      createLumpSwellingTestcleDivElement.append(form_field_wrapperlumpSwellingTestcle, form_field_wrapperlump_or_swelling_six_months, form_field_wrapperlump_or_swelling_last_month, form_field_wrapperlump_or_swelling_today);
    } else {
      createLumpSwellingTestcleDivElement.append(form_field_wrapperlumpSwellingTestcle, form_field_wrapperlump_or_swelling_today);
    }

    let form_field_wrapperfeelingHeavinessScrotum = document.querySelector(".form_field_wrapperfeelingHeavinessScrotum");
    // let form_field_wrapperfeel_heaviness_six_months = document.querySelector(".form_field_wrapperfeel_heaviness_six_months");
    // let form_field_wrapperfeel_heaviness_last_month = document.querySelector(".form_field_wrapperfeel_heaviness_last_month");
    let form_field_wrapperfeel_heaviness_today = document.querySelector(".form_field_wrapperfeel_heaviness_today");
    let createFeelingHeavinessScrotumDivElement = document.createElement("div");
    createFeelingHeavinessScrotumDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperfeel_heaviness_six_months = document.querySelector(".form_field_wrapperfeel_heaviness_six_months");
      let form_field_wrapperfeel_heaviness_last_month = document.querySelector(".form_field_wrapperfeel_heaviness_last_month");
      createFeelingHeavinessScrotumDivElement.append(form_field_wrapperfeelingHeavinessScrotum, form_field_wrapperfeel_heaviness_six_months, form_field_wrapperfeel_heaviness_last_month, form_field_wrapperfeel_heaviness_today);
    } else {
      createFeelingHeavinessScrotumDivElement.append(form_field_wrapperfeelingHeavinessScrotum, form_field_wrapperfeel_heaviness_today);
    }

    let form_field_wrapperdullLowerBelly = document.querySelector(".form_field_wrapperdullLowerBelly");
    // let form_field_wrapperdull_ache_lower_belly_six_months = document.querySelector(".form_field_wrapperdull_ache_lower_belly_six_months");
    // let form_field_wrapperdull_ache_lower_belly_last_month = document.querySelector(".form_field_wrapperdull_ache_lower_belly_last_month");
    let form_field_wrapperdull_ache_lower_belly_today = document.querySelector(".form_field_wrapperdull_ache_lower_belly_today");
    let createDullLowerBellyDivElement = document.createElement("div");
    createDullLowerBellyDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdull_ache_lower_belly_six_months = document.querySelector(".form_field_wrapperdull_ache_lower_belly_six_months");
      let form_field_wrapperdull_ache_lower_belly_last_month = document.querySelector(".form_field_wrapperdull_ache_lower_belly_last_month");
      createDullLowerBellyDivElement.append(form_field_wrapperdullLowerBelly, form_field_wrapperdull_ache_lower_belly_six_months, form_field_wrapperdull_ache_lower_belly_last_month, form_field_wrapperdull_ache_lower_belly_today);
    } else {
      createDullLowerBellyDivElement.append(form_field_wrapperdullLowerBelly, form_field_wrapperdull_ache_lower_belly_today);
    }

    let form_field_wrappersuddenSwellingScrotum = document.querySelector(".form_field_wrappersuddenSwellingScrotum");
    // let form_field_wrappersudden_swelling_scrotum_six_months = document.querySelector(".form_field_wrappersudden_swelling_scrotum_six_months");
    // let form_field_wrappersudden_swelling_scrotum_last_month = document.querySelector(".form_field_wrappersudden_swelling_scrotum_last_month");
    let form_field_wrappersudden_swelling_scrotum_today = document.querySelector(".form_field_wrappersudden_swelling_scrotum_today");
    let createSuddenSwellingScrotumDivElement = document.createElement("div");
    createSuddenSwellingScrotumDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappersudden_swelling_scrotum_six_months = document.querySelector(".form_field_wrappersudden_swelling_scrotum_six_months");
      let form_field_wrappersudden_swelling_scrotum_last_month = document.querySelector(".form_field_wrappersudden_swelling_scrotum_last_month");
      createSuddenSwellingScrotumDivElement.append(form_field_wrappersuddenSwellingScrotum, form_field_wrappersudden_swelling_scrotum_six_months, form_field_wrappersudden_swelling_scrotum_last_month, form_field_wrappersudden_swelling_scrotum_today);
    } else {
      createSuddenSwellingScrotumDivElement.append(form_field_wrappersuddenSwellingScrotum, form_field_wrappersudden_swelling_scrotum_today);
    }

    let form_field_wrapperpainDiscomfortScrotum = document.querySelector(".form_field_wrapperpainDiscomfortScrotum");
    // let form_field_wrapperdiscomfort_testicle_six_months = document.querySelector(".form_field_wrapperdiscomfort_testicle_six_months");
    // let form_field_wrapperdiscomfort_testicle_last_month = document.querySelector(".form_field_wrapperdiscomfort_testicle_last_month");
    let form_field_wrapperdiscomfort_testicle_today = document.querySelector(".form_field_wrapperdiscomfort_testicle_today");
    let createPainDiscomfortScrotumDivElement = document.createElement("div");
    createPainDiscomfortScrotumDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperdiscomfort_testicle_six_months = document.querySelector(".form_field_wrapperdiscomfort_testicle_six_months");
      let form_field_wrapperdiscomfort_testicle_last_month = document.querySelector(".form_field_wrapperdiscomfort_testicle_last_month");
      createPainDiscomfortScrotumDivElement.append(form_field_wrapperpainDiscomfortScrotum, form_field_wrapperdiscomfort_testicle_six_months, form_field_wrapperdiscomfort_testicle_last_month, form_field_wrapperdiscomfort_testicle_today);
    } else {
      createPainDiscomfortScrotumDivElement.append(form_field_wrapperpainDiscomfortScrotum, form_field_wrapperdiscomfort_testicle_today);
    }

    let form_field_wrapperlumpFrontNeck = document.querySelector(".form_field_wrapperlumpFrontNeck");
    // let form_field_wrapperlump_front_neck_six_months = document.querySelector(".form_field_wrapperlump_front_neck_six_months");
    // let form_field_wrapperlump_front_neck_last_month = document.querySelector(".form_field_wrapperlump_front_neck_last_month");
    let form_field_wrapperlump_front_neck_today = document.querySelector(".form_field_wrapperlump_front_neck_today");
    let createLumpFrontNeckDivElement = document.createElement("div");
    createLumpFrontNeckDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperlump_front_neck_six_months = document.querySelector(".form_field_wrapperlump_front_neck_six_months");
      let form_field_wrapperlump_front_neck_last_month = document.querySelector(".form_field_wrapperlump_front_neck_last_month");
      createLumpFrontNeckDivElement.append(form_field_wrapperlumpFrontNeck, form_field_wrapperlump_front_neck_six_months, form_field_wrapperlump_front_neck_last_month, form_field_wrapperlump_front_neck_today);
    } else {
      createLumpFrontNeckDivElement.append(form_field_wrapperlumpFrontNeck, form_field_wrapperlump_front_neck_today);
    }

    let form_field_wrapperswollenLymphNeck = document.querySelector(".form_field_wrapperswollenLymphNeck");
    // let form_field_wrapperswollen_lymph_six_months = document.querySelector(".form_field_wrapperswollen_lymph_six_months");
    // let form_field_wrapperswollen_lymph_last_month = document.querySelector(".form_field_wrapperswollen_lymph_last_month");
    let form_field_wrapperswollen_lymph_today = document.querySelector(".form_field_wrapperswollen_lymph_today");
    let createSwollenLymphNeckDivElement = document.createElement("div");
    createSwollenLymphNeckDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperswollen_lymph_six_months = document.querySelector(".form_field_wrapperswollen_lymph_six_months");
      let form_field_wrapperswollen_lymph_last_month = document.querySelector(".form_field_wrapperswollen_lymph_last_month");
      createSwollenLymphNeckDivElement.append(form_field_wrapperswollenLymphNeck, form_field_wrapperswollen_lymph_six_months, form_field_wrapperswollen_lymph_last_month, form_field_wrapperswollen_lymph_today);
    } else {
      createSwollenLymphNeckDivElement.append(form_field_wrapperswollenLymphNeck, form_field_wrapperswollen_lymph_today);
    }

    let form_field_wrapperpainThroatNeck = document.querySelector(".form_field_wrapperpainThroatNeck");
    // let form_field_wrapperpain_throat_neck_six_months = document.querySelector(".form_field_wrapperpain_throat_neck_six_months");
    // let form_field_wrapperpain_throat_neck_last_month = document.querySelector(".form_field_wrapperpain_throat_neck_last_month");
    let form_field_wrapperpain_throat_neck_today = document.querySelector(".form_field_wrapperpain_throat_neck_today");
    let createPainThroatNeckDivElement = document.createElement("div");
    createPainThroatNeckDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperpain_throat_neck_six_months = document.querySelector(".form_field_wrapperpain_throat_neck_six_months");
      let form_field_wrapperpain_throat_neck_last_month = document.querySelector(".form_field_wrapperpain_throat_neck_last_month");
      createPainThroatNeckDivElement.append(form_field_wrapperpainThroatNeck, form_field_wrapperpain_throat_neck_six_months, form_field_wrapperpain_throat_neck_last_month, form_field_wrapperpain_throat_neck_today);
    } else {
      createPainThroatNeckDivElement.append(form_field_wrapperpainThroatNeck, form_field_wrapperpain_throat_neck_today);
    }

    let form_field_wrapperchangeVoiceHoarseness = document.querySelector(".form_field_wrapperchangeVoiceHoarseness");
    // let form_field_wrapperchange_voice_six_months = document.querySelector(".form_field_wrapperchange_voice_six_months");
    // let form_field_wrapperchange_voice_last_month = document.querySelector(".form_field_wrapperchange_voice_last_month");
    let form_field_wrapperchange_voice_today = document.querySelector(".form_field_wrapperchange_voice_today");
    let createChangeVoiceHoarsenessDivElement = document.createElement("div");
    createChangeVoiceHoarsenessDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrapperchange_voice_six_months = document.querySelector(".form_field_wrapperchange_voice_six_months");
      let form_field_wrapperchange_voice_last_month = document.querySelector(".form_field_wrapperchange_voice_last_month");
      createChangeVoiceHoarsenessDivElement.append(form_field_wrapperchangeVoiceHoarseness, form_field_wrapperchange_voice_six_months, form_field_wrapperchange_voice_last_month, form_field_wrapperchange_voice_today);
    } else {
      createChangeVoiceHoarsenessDivElement.append(form_field_wrapperchangeVoiceHoarseness, form_field_wrapperchange_voice_today);
    }

    let form_field_wrapperenlargementTendernessBreast = document.querySelector(".form_field_wrapperenlargementTendernessBreast");
    // let form_field_wrappertenderness_breast_tissue_six_months = document.querySelector(".form_field_wrappertenderness_breast_tissue_six_months");
    // let form_field_wrappertenderness_breast_tissue_last_month = document.querySelector(".form_field_wrappertenderness_breast_tissue_last_month");
    let form_field_wrappertenderness_breast_tissue_today = document.querySelector(".form_field_wrappertenderness_breast_tissue_today");
    let createEnlargementTendernessBreastDivElement = document.createElement("div");
    createEnlargementTendernessBreastDivElement.classList.add("innerDiv");
    if (type) {
      let form_field_wrappertenderness_breast_tissue_six_months = document.querySelector(".form_field_wrappertenderness_breast_tissue_six_months");
      let form_field_wrappertenderness_breast_tissue_last_month = document.querySelector(".form_field_wrappertenderness_breast_tissue_last_month");
      createEnlargementTendernessBreastDivElement.append(form_field_wrapperenlargementTendernessBreast, form_field_wrappertenderness_breast_tissue_six_months, form_field_wrappertenderness_breast_tissue_last_month, form_field_wrappertenderness_breast_tissue_today);
    } else {
      createEnlargementTendernessBreastDivElement.append(form_field_wrapperenlargementTendernessBreast, form_field_wrappertenderness_breast_tissue_today);
    }




    let physicianExaminationForm = document.querySelector('.leadIntakeFormDiv .grp7');
    console.log("createHeadcheDivElement", createHeadcheDivElement, type, physicianExaminationForm)
    physicianExaminationForm.appendChild(createHeadcheDivElement);
    physicianExaminationForm.appendChild(createSkinEyesYellowwishDivElement);
    physicianExaminationForm.appendChild(createAbdominalDivElement);
    physicianExaminationForm.appendChild(createSwellingAnklesDivElement);
    physicianExaminationForm.appendChild(createDryItchySkinDivElement);
    physicianExaminationForm.appendChild(createDarkUrineColorDivElement);
    physicianExaminationForm.appendChild(createPaleStoolColorDivElement);
    physicianExaminationForm.appendChild(createChronicFatigueDivElement);
    physicianExaminationForm.appendChild(createNauseaOrVomitingDivElement);
    physicianExaminationForm.appendChild(createLossAppetiteDivElement);
    physicianExaminationForm.appendChild(createTendencyEasilyDivElement);
    physicianExaminationForm.appendChild(createFeverDivElement);
    physicianExaminationForm.appendChild(createInsomniaDivElement);
    physicianExaminationForm.appendChild(createUrinatingLessDivElement);
    physicianExaminationForm.appendChild(createMuscleCrampsDivElement);
    physicianExaminationForm.appendChild(createSwellingFeetAnklesDivElement);
    physicianExaminationForm.appendChild(createShortnessBreathDivElement);
    physicianExaminationForm.appendChild(createChestPainDivElement);
    physicianExaminationForm.appendChild(createBackPainDivElement);
    physicianExaminationForm.appendChild(createFrequentUrinationDivElement);
    physicianExaminationForm.appendChild(createFoamyUrineDivElement);
    physicianExaminationForm.appendChild(createBloodUrineDivElement);
    physicianExaminationForm.appendChild(createDizzinessDivElement);
    physicianExaminationForm.appendChild(createSwellingAroundEyesDivElement);
    physicianExaminationForm.appendChild(createHighBloodSugarDivElement);
    physicianExaminationForm.appendChild(createWeightLossDivElement);
    physicianExaminationForm.appendChild(createJointPainDivElement);
    physicianExaminationForm.appendChild(createJointInflammationDivElement);
    physicianExaminationForm.appendChild(createLimitedRangeMotionDivElement);
    physicianExaminationForm.appendChild(createCoughingEspeciallyNightnDivElement);
    physicianExaminationForm.appendChild(createWheezingSqueakingBreatheDivElement);
    physicianExaminationForm.appendChild(createChestTightnessPainDivElement);
    physicianExaminationForm.appendChild(createLowEnergyDivElement);
    physicianExaminationForm.appendChild(createBlueFingernailsDivElement);
    physicianExaminationForm.appendChild(createFrequentColdsFluDivElement);
    physicianExaminationForm.appendChild(createCoughingLotsMucusDivElement);
    physicianExaminationForm.appendChild(createCoughDoesnotAwayDivElement);
    physicianExaminationForm.appendChild(createDepressionAnxietyDivElement);
    physicianExaminationForm.appendChild(createRestlessnessSleepingDivElement);
    physicianExaminationForm.appendChild(createFatigueDivElement);
    physicianExaminationForm.appendChild(createNauseaAndVomotingDivElement);
    physicianExaminationForm.appendChild(createArrhythmiaHeartbeatRhythmDivElement);
    physicianExaminationForm.appendChild(createConfusionDivElement);
    physicianExaminationForm.appendChild(createBlurredVisionDivElement);
    physicianExaminationForm.appendChild(createExtremeThirstDivElement);
    physicianExaminationForm.appendChild(createExtremeHungerDivElement);
    physicianExaminationForm.appendChild(createPainfulUrinationDivElement);
    physicianExaminationForm.appendChild(createNumbTinglingFeetDivElement);
    physicianExaminationForm.appendChild(createSexualDifficultiesDivElement);
    physicianExaminationForm.appendChild(createUrinaryProblemsDivElement);
    physicianExaminationForm.appendChild(createTinglingHandsFeetDivElement);
    physicianExaminationForm.appendChild(createBurningSensationDivElement);
    physicianExaminationForm.appendChild(createAnginaDivElement);
    physicianExaminationForm.appendChild(createChestPainWithRestDivElement);
    physicianExaminationForm.appendChild(createHeartburnDivElement);
    physicianExaminationForm.appendChild(createPainInCalvesDivElement);
    physicianExaminationForm.appendChild(createDizzinessOrLightheadednessDivElement);
    physicianExaminationForm.appendChild(createIrregularHeartbeatTooDivElement);
    physicianExaminationForm.appendChild(createHeartAttackDivElement);
    physicianExaminationForm.appendChild(createStrokeDivElement);
    physicianExaminationForm.appendChild(createBloodClotInVeinDivElement);
    physicianExaminationForm.appendChild(createTiaMiniStrokeDivElement);
    physicianExaminationForm.appendChild(createChestPainOrDiscomfortDivElement);
    physicianExaminationForm.appendChild(createFaintingDivElement);
    physicianExaminationForm.appendChild(createWeaknessDivElement);
    physicianExaminationForm.appendChild(createLingeringDiscomfortDivElement);
    physicianExaminationForm.appendChild(createPainAffectedJointDivElement);
    physicianExaminationForm.appendChild(createSwellingAffectedJointDivElement);
    physicianExaminationForm.appendChild(createRednessAffectedJointDivElement);
    physicianExaminationForm.appendChild(createChillsDivElement);
    physicianExaminationForm.appendChild(createCoughDivElement);
    physicianExaminationForm.appendChild(createChestPainBreatheDivElement);
    physicianExaminationForm.appendChild(createNauseaDivElement);
    physicianExaminationForm.appendChild(createPainBurningUrinatingDivElement);
    physicianExaminationForm.appendChild(createBloodyUrineDivElement);
    physicianExaminationForm.appendChild(createRunnyNoseDivElement);
    physicianExaminationForm.appendChild(createSoreThroatDivElement);
    physicianExaminationForm.appendChild(createSwollenGlandsDivElement);
    physicianExaminationForm.appendChild(createTroubleBreathingDivElement);
    physicianExaminationForm.appendChild(createHighPitchedBreathingDivElement);
    physicianExaminationForm.appendChild(createBurningPainUrinatingDivElement);
    physicianExaminationForm.appendChild(createRedEyesDivElement);
    physicianExaminationForm.appendChild(createDischargeYourEyesDivElement);
    physicianExaminationForm.appendChild(createAbdominalPainDivElement);
    physicianExaminationForm.appendChild(createChangeUrineColorDivElement);
    physicianExaminationForm.appendChild(createPainfulEjaculationDivElement);
    physicianExaminationForm.appendChild(createMissedPeriodDivElement);
    physicianExaminationForm.appendChild(createSwollenTenderBreastsDivElement);
    physicianExaminationForm.appendChild(createNauseaWithVomitingDivElement);
    physicianExaminationForm.appendChild(createLightSpottingDivElement);
    physicianExaminationForm.appendChild(createCrampingDivElement);
    physicianExaminationForm.appendChild(createBloatingDivElement);
    physicianExaminationForm.appendChild(createMoodSwingsDivElement);
    physicianExaminationForm.appendChild(createSkinIrritationDimplingDivElement);
    physicianExaminationForm.appendChild(createBreastNipplePainDivElement);
    physicianExaminationForm.appendChild(createNippleRetractionDivElement);
    physicianExaminationForm.appendChild(createRednessScalinessDivElement);
    physicianExaminationForm.appendChild(createNippleDischargeDivElement);
    physicianExaminationForm.appendChild(createAbdominalBloatingSwellingDivElement);
    physicianExaminationForm.appendChild(createQuicklyFellingEatingDivElement);
    physicianExaminationForm.appendChild(createDiscomfortPelvicAreaDivElement);
    physicianExaminationForm.appendChild(createBloodInStoolDivElement);
    physicianExaminationForm.appendChild(createConstipationDivElement);
    physicianExaminationForm.appendChild(createDiarrheaDivElement);
    physicianExaminationForm.appendChild(createCoughingUpBloodDivElement);
    physicianExaminationForm.appendChild(createDarkColoredUrineDivElement);
    physicianExaminationForm.appendChild(createStollsLookBlackDivElement);
    physicianExaminationForm.appendChild(createBellyPainDivElement);
    physicianExaminationForm.appendChild(createTroubleSwallowingDivElement);
    physicianExaminationForm.appendChild(createFeelingBloatedEatingDivElement);
    physicianExaminationForm.appendChild(createIndigestionDivElement);
    physicianExaminationForm.appendChild(createDifficultyStartingUrinationDivElement);
    physicianExaminationForm.appendChild(createWeakFlowUrineDivElement);
    physicianExaminationForm.appendChild(createUrinatingOftenNightDivElement);
    physicianExaminationForm.appendChild(createTroubleBladderCompletelyDivElement);
    physicianExaminationForm.appendChild(createPainDuringUrinationDivElement);
    physicianExaminationForm.appendChild(createPainBackHipsPelvisDivElement);
    physicianExaminationForm.appendChild(createLumpSwellingTestcleDivElement);
    physicianExaminationForm.appendChild(createFeelingHeavinessScrotumDivElement);
    physicianExaminationForm.appendChild(createDullLowerBellyDivElement);
    physicianExaminationForm.appendChild(createSuddenSwellingScrotumDivElement);
    physicianExaminationForm.appendChild(createPainDiscomfortScrotumDivElement);
    physicianExaminationForm.appendChild(createLumpFrontNeckDivElement);
    physicianExaminationForm.appendChild(createSwollenLymphNeckDivElement);
    physicianExaminationForm.appendChild(createPainThroatNeckDivElement);
    physicianExaminationForm.appendChild(createChangeVoiceHoarsenessDivElement);
    physicianExaminationForm.appendChild(createEnlargementTendernessBreastDivElement);



    console.log("physicianExaminationForm", physicianExaminationForm)
  }




  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log('resolveval', this.stateList)
    })
  }

  intakesign(dataobj?) {



    this.intakesignForm.markAllAsTouched()
    console.log('dataobj+++', dataobj)
    console.log('intakesignForm', this.intakesignForm.value.intake_sign, this.intakesignForm.valid)
    if (this.intakesignForm.value.intake_sign && this.intakesignForm.value.intake_sign !== '') {
      dataobj.data.patient_signature = this.intakesignForm.value.intake_sign
      dataobj.data._id = this.editFormData._id
      console.log('dataobj0000', dataobj.patient_signature)
      this.addFormLoader = true;


      if (this.email_intake_flag) {
        delete dataobj.data._id;
        if (this.emailIntakeData && this.emailIntakeData.practice_id) { dataobj.data.practice_id = this.emailIntakeData.practice_id; }
        if (this.emailIntakeData && this.emailIntakeData.parent_laboratory) { dataobj.data.parent_laboratory = this.emailIntakeData.parent_laboratory; }
        if (this.emailIntakeData && this.emailIntakeData.doctor_id) { dataobj.data.doctor_id = this.emailIntakeData.doctor_id; }
      }

      if (this.quick_intake_flag) {
        dataobj.data.parent_laboratory = this.cookiedata ? this.cookiedata.parent_laboratory : undefined;
        dataobj.data.status = 1;
      }
      if (dataobj.data.patient_phone.length > 14) {
        dataobj.data.patient_phone = dataobj.data.patient_phone.substring(0, 14);
      }
      if (this.paramslink._id) {
        dataobj.data._id = this.paramslink._id;
      }
      if (this.email_intake_flag) {
        if (dataobj.data.status == undefined || dataobj.data.status == null || dataobj.data.status == 0) {
          dataobj.data.status = 1
        }
        delete dataobj.data._id
      }
      dataobj.data.is_demo = false;
      if (this.demoDoctorDashboard) {
        dataobj.data.is_demo = true;
        dataobj.data.parent_laboratory = this.editFormData.parent_laboratory;
      }

      if (this.editFormData && this.editFormData.is_demo) {
        dataobj.data.is_demo = true;
      }

      console.log('dataobj', dataobj)
      this.submitIntakeAuto(dataobj, 'intakesign')


    }

  }


  resubmitIntake(dataobj) {

    this.intakesignForm.markAllAsTouched()
    console.log('dataobj+++', dataobj)

    dataobj.data._id = this.editFormData._id;
    dataobj.data.status = 1;
    dataobj.data.is_partial_done = false;
    this.addFormLoader = true;

    if (dataobj.data.patient_phone.length > 14) {
      dataobj.data.patient_phone = dataobj.data.patient_phone.substring(0, 14);
    }

    this.apiservice.httpViaPost('intake/editPatientDetails', dataobj).subscribe((response) => {
      console.log("dataobj>>>>", response)
      if (response && response.status == 'success') {
        this.matSnackBar.open(response.message, "Ok", {
          duration: 2000
        });
        this.addFormLoader = false;
        if (this.activatedRoute.snapshot.routeConfig.path.includes('preview-intake/:_id')) {
          if (this.demoDoctorDashboardPreviewIntake) {
            this.router.navigateByUrl(`sales_person/preview-intake/${this.editFormData._id}`);
          } else {
            this.router.navigateByUrl(`doctor/preview-intake/${this.editFormData._id}`);
          }
        }
        if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
          if (this.demoDoctorDashboardPreviewIntake) {
            this.router.navigateByUrl(`sales_person/patient-folderview/${this.editFormData._id}`);
          } else {
            this.router.navigateByUrl(`doctor/patient-folderview/${this.editFormData._id}`);
          }
        }
      }

      if (response && response.status == 'error') {
        this.matSnackBar.open(response.message, "", {
          duration: 1000
        });
        this.addFormLoader = false;
      }
    })
  }

  listenFormFieldChange(val) {
    // console.log('intakesignForm22',this.intakesignForm)
    console.log('listenFormFieldChange>>>>', val);
    let autoSaveData: any = {};
    if (val.field) {
      if (val.field.name == "primary_isurance_id" && typeof val.autocompletedata != "undefined") {
        this.primaryinsurancename = val.autocompletedata.val;
      }
      if (val.field.name == "secondary_isurance_id" && typeof val.autocompletedata != "undefined") {
        this.secondaryinsurancename = val.autocompletedata.val;
      }
    }
    // console.log("listenFormFieldChange start", this.primaryinsurancename)
    if (this.primaryinsurancename) {
      let primaryInsuranceNumberField
      setTimeout(() => {
        primaryInsuranceNumberField = document.querySelector(".form_field_wrapperprimary_isurance_no");
      }, 100)
      if (val.removedDataSet) {
        if (val.removedDataSet.val == this.primaryinsurancename) {
          this.primaryinsurancename = null;
          this.intakeForm.fields.filter((e: any, i: number) => {
            if (e.name == 'primary_isurance_no') {
              this.formfieldrefreshdata = {
                field: "primary_isurance_no",
                value: ''
              };
            }
          })
          setTimeout(() => {
            if (primaryInsuranceNumberField) {
              primaryInsuranceNumberField.classList.remove("hightAdjust");
            }
          }, 100)
        } else {
          console.log("listenFormFieldChange primaryInsuranceNumberField", primaryInsuranceNumberField)
          if (primaryInsuranceNumberField) {
            primaryInsuranceNumberField.classList.add("hightAdjust");
          }
        }
      } else {
        setTimeout(() => {
          if (primaryInsuranceNumberField) {
            primaryInsuranceNumberField.classList.add("hightAdjust");
          }
        }, 100)
      }
    } else {
      let primaryInsuranceNumberField = document.querySelector(".form_field_wrapperprimary_isurance_no");
      if (primaryInsuranceNumberField) {
        primaryInsuranceNumberField.classList.remove("hightAdjust");
      }
    }
    if (this.secondaryinsurancename) {
      let secondaryInsuranceNumberField
      setTimeout(() => {
        secondaryInsuranceNumberField = document.querySelector(".form_field_wrappersecondary_isurance_no");
      }, 100)
      if (val.removedDataSet) {
        if (val.removedDataSet.val == this.secondaryinsurancename) {
          this.secondaryinsurancename = null;
          this.intakeForm.fields.filter((e: any, i: number) => {
            if (e.name == 'secondary_isurance_no') {
              this.formfieldrefreshdata = {
                field: "secondary_isurance_no",
                value: ''
              };
            }
          })
          setTimeout(() => {
            if (secondaryInsuranceNumberField) {
              secondaryInsuranceNumberField.classList.remove("hightAdjust");
            }
          }, 100)
        } else {
          console.log("listenFormFieldChange secondaryInsuranceNumberField", secondaryInsuranceNumberField)
          if (secondaryInsuranceNumberField) {
            secondaryInsuranceNumberField.classList.add("hightAdjust");
          }
        }
      } else {
        setTimeout(() => {
          if (secondaryInsuranceNumberField) {
            secondaryInsuranceNumberField.classList.add("hightAdjust");
          }
        }, 100)
      }
    } else {
      let secondaryInsuranceNumberField = document.querySelector(".form_field_wrappersecondary_isurance_no");
      if (secondaryInsuranceNumberField) {
        secondaryInsuranceNumberField.classList.remove("hightAdjust");
      }
    }

    // form_field_wrappersecondary_isurance_no
    // console.log("listenFormFieldChange this.primaryinsurancename", this.primaryinsurancename)

    // console.log("dataobjdiabetes", val.fromval.patient_signature)
    // this.intake_sign= new FormControl('', Validators.required)
    if (this.quick_intake_flag || this.through_patient_intake) {
      if (val.fromval) {
        if (val.fromval.headaches_today) {
          val.fromval.headaches_six_months = true;
          val.fromval.headaches_last_month = true;
        } else {
          val.fromval.headaches_six_months = false;
          val.fromval.headaches_last_month = false;
        }
        if (val.fromval.skin_today) {
          val.fromval.skin_six_months = true;
          val.fromval.skin_last_month = true;
        } else {
          val.fromval.skin_six_months = false;
          val.fromval.skin_last_month = false;
        }
        if (val.fromval.abdominal_today) {
          val.fromval.abdominal_six_months = true;
          val.fromval.abdominal_last_month = true;
        } else {
          val.fromval.abdominal_six_months = false;
          val.fromval.abdominal_last_month = false;
        }
        if (val.fromval.selling_legs_today) {
          val.fromval.selling_legs_six_months = true;
          val.fromval.selling_legs_last_month = true;
        } else {
          val.fromval.selling_legs_six_months = false;
          val.fromval.selling_legs_last_month = false;
        }
        if (val.fromval.itchy_skin_today) {
          val.fromval.itchy_skin_six_months = true;
          val.fromval.itchy_skin_last_month = true;
        } else {
          val.fromval.itchy_skin_six_months = false;
          val.fromval.itchy_skin_last_month = false;
        }
        if (val.fromval.dark_urine_color_today) {
          val.fromval.dark_urine_color_six_months = true;
          val.fromval.dark_urine_color_last_month = true;
        } else {
          val.fromval.dark_urine_color_six_months = false;
          val.fromval.dark_urine_color_last_month = false;
        }
        if (val.fromval.pale_stool_color_today) {
          val.fromval.pale_stool_color_six_months = true;
          val.fromval.pale_stool_color_last_month = true;
        } else {
          val.fromval.pale_stool_color_six_months = false;
          val.fromval.pale_stool_color_last_month = false;
        }
        if (val.fromval.chronic_fatigue_today) {
          val.fromval.chronic_fatigue_six_months = true;
          val.fromval.chronic_fatigue_last_month = true;
        } else {
          val.fromval.chronic_fatigue_six_months = false;
          val.fromval.chronic_fatigue_last_month = false;
        }
        if (val.fromval.vomiting_today) {
          val.fromval.vomiting_six_months = true;
          val.fromval.vomiting_last_month = true;
        } else {
          val.fromval.vomiting_six_months = false;
          val.fromval.vomiting_last_month = false;
        }
        if (val.fromval.lossofappetite_today) {
          val.fromval.lossofappetite_six_months = true;
          val.fromval.lossofappetite_last_month = true;
        } else {
          val.fromval.lossofappetite_six_months = false;
          val.fromval.lossofappetite_last_month = false;
        }
        if (val.fromval.bruise_today) {
          val.fromval.bruise_six_months = true;
          val.fromval.bruise_last_month = true;
        } else {
          val.fromval.bruise_six_months = false;
          val.fromval.bruise_last_month = false;
        }
        if (val.fromval.fever_today) {
          val.fromval.fever_six_months = true;
          val.fromval.fever_last_month = true;
        } else {
          val.fromval.fever_six_months = false;
          val.fromval.fever_last_month = false;
        }
        if (val.fromval.insomnia_today) {
          val.fromval.insomnia_six_months = true;
          val.fromval.insomnia_last_month = true;
        } else {
          val.fromval.insomnia_six_months = false;
          val.fromval.insomnia_last_month = false;
        }
        if (val.fromval.urinating_less_today) {
          val.fromval.urinating_less_six_months = true;
          val.fromval.urinating_less_last_month = true;
        } else {
          val.fromval.urinating_less_six_months = false;
          val.fromval.urinating_less_last_month = false;
        }
        if (val.fromval.muscle_cramps_today) {
          val.fromval.muscle_cramps_six_months = true;
          val.fromval.muscle_cramps_last_month = true;
        } else {
          val.fromval.muscle_cramps_six_months = false;
          val.fromval.muscle_cramps_last_month = false;
        }
        if (val.fromval.swelling_feet_ankles_today) {
          val.fromval.swelling_feet_ankles_six_months = true;
          val.fromval.swelling_feet_ankles_last_month = true;
        } else {
          val.fromval.swelling_feet_ankles_six_months = false;
          val.fromval.swelling_feet_ankles_last_month = false;
        }
        if (val.fromval.shortness_of_breath_today) {
          val.fromval.shortness_of_breath_six_months = true;
          val.fromval.shortness_of_breath_last_month = true;
        } else {
          val.fromval.shortness_of_breath_six_months = false;
          val.fromval.shortness_of_breath_last_month = false;
        }
        if (val.fromval.chest_pain_today) {
          val.fromval.chest_pain_six_months = true;
          val.fromval.chest_pain_last_month = true;
        } else {
          val.fromval.chest_pain_six_months = false;
          val.fromval.chest_pain_last_month = false;
        }
        if (val.fromval.back_pain_today) {
          val.fromval.back_pain_six_months = true;
          val.fromval.back_pain_last_month = true;
        } else {
          val.fromval.back_pain_six_months = false;
          val.fromval.back_pain_last_month = false;
        }
        if (val.fromval.frequent_urination_today) {
          val.fromval.frequent_urination_six_months = true;
          val.fromval.frequent_urination_last_month = true;
        } else {
          val.fromval.frequent_urination_six_months = false;
          val.fromval.frequent_urination_last_month = false;
        }
        if (val.fromval.foamy_urine_today) {
          val.fromval.foamy_urine_six_months = true;
          val.fromval.foamy_urine_last_month = true;
        } else {
          val.fromval.foamy_urine_six_months = false;
          val.fromval.foamy_urine_last_month = false;
        }
        if (val.fromval.blood_in_your_urine_today) {
          val.fromval.blood_in_your_urine_six_months = true;
          val.fromval.blood_in_your_urine_last_month = true;
        } else {
          val.fromval.blood_in_your_urine_six_months = false;
          val.fromval.blood_in_your_urine_last_month = false;
        }
        if (val.fromval.dizziness_today) {
          val.fromval.dizziness_six_months = true;
          val.fromval.dizziness_last_month = true;
        } else {
          val.fromval.dizziness_six_months = false;
          val.fromval.dizziness_last_month = false;
        }
        if (val.fromval.swelling_around_your_eyes_today) {
          val.fromval.swelling_around_your_eyes_six_months = true;
          val.fromval.swelling_around_your_eyes_last_month = true;
        } else {
          val.fromval.swelling_around_your_eyes_six_months = false;
          val.fromval.swelling_around_your_eyes_last_month = false;
        }
        if (val.fromval.high_blood_sugar_today) {
          val.fromval.high_blood_sugar_six_months = true;
          val.fromval.high_blood_sugar_last_month = true;
        } else {
          val.fromval.high_blood_sugar_six_months = false;
          val.fromval.high_blood_sugar_last_month = false;
        }
        if (val.fromval.weight_loss_today) {
          val.fromval.weight_loss_six_months = true;
          val.fromval.weight_loss_last_month = true;
        } else {
          val.fromval.weight_loss_six_months = false;
          val.fromval.weight_loss_last_month = false;
        }
        if (val.fromval.joint_pain_today) {
          val.fromval.joint_pain_six_months = true;
          val.fromval.joint_pain_last_month = true;
        } else {
          val.fromval.joint_pain_six_months = false;
          val.fromval.joint_pain_last_month = false;
        }
        if (val.fromval.joint_inflammation_today) {
          val.fromval.joint_inflammation_six_months = true;
          val.fromval.joint_inflammation_last_month = true;
        } else {
          val.fromval.joint_inflammation_six_months = false;
          val.fromval.joint_inflammation_last_month = false;
        }
        if (val.fromval.limited_range_motion_today) {
          val.fromval.limited_range_motion_six_months = true;
          val.fromval.limited_range_motion_last_month = true;
        } else {
          val.fromval.limited_range_motion_six_months = false;
          val.fromval.limited_range_motion_last_month = false;
        }
        if (val.fromval.coughing_especially_at_night_today) {
          val.fromval.coughing_especially_at_night_six_months = true;
          val.fromval.coughing_especially_at_night_last_month = true;
        } else {
          val.fromval.coughing_especially_at_night_six_months = false;
          val.fromval.coughing_especially_at_night_last_month = false;
        }
        if (val.fromval.wheezing_squeaking_when_you_breathe_today) {
          val.fromval.wheezing_squeaking_when_you_breathe_six_months = true;
          val.fromval.wheezing_squeaking_when_you_breathe_last_month = true;
        } else {
          val.fromval.wheezing_squeaking_when_you_breathe_six_months = false;
          val.fromval.wheezing_squeaking_when_you_breathe_last_month = false;
        }
        if (val.fromval.chest_tightness_pain_today) {
          val.fromval.chest_tightness_pain_six_months = true;
          val.fromval.chest_tightness_pain_last_month = true;
        } else {
          val.fromval.chest_tightness_pain_six_months = false;
          val.fromval.chest_tightness_pain_last_month = false;
        }
        if (val.fromval.low_energy_today) {
          val.fromval.low_energy_six_months = true;
          val.fromval.low_energy_last_month = true;
        } else {
          val.fromval.low_energy_six_months = false;
          val.fromval.low_energy_last_month = false;
        }
        if (val.fromval.blue_fingernails_today) {
          val.fromval.blue_fingernails_six_months = true;
          val.fromval.blue_fingernails_last_month = true;
        } else {
          val.fromval.blue_fingernails_six_months = false;
          val.fromval.blue_fingernails_last_month = false;
        }
        if (val.fromval.frequent_colds_flu_today) {
          val.fromval.frequent_colds_flu_six_months = true;
          val.fromval.frequent_colds_flu_last_month = true;
        } else {
          val.fromval.frequent_colds_flu_six_months = false;
          val.fromval.frequent_colds_flu_last_month = false;
        }
        if (val.fromval.coughing_up_lots_mucus_today) {
          val.fromval.coughing_up_lots_mucus_six_months = true;
          val.fromval.coughing_up_lots_mucus_last_month = true;
        } else {
          val.fromval.coughing_up_lots_mucus_six_months = false;
          val.fromval.coughing_up_lots_mucus_last_month = false;
        }
        if (val.fromval.cough_that_doesnt_go_away_today) {
          val.fromval.cough_that_doesnt_go_away_six_months = true;
          val.fromval.cough_that_doesnt_go_away_last_month = true;
        } else {
          val.fromval.cough_that_doesnt_go_away_six_months = false;
          val.fromval.cough_that_doesnt_go_away_last_month = false;
        }
        if (val.fromval.depression_anxiety_today) {
          val.fromval.depression_anxiety_six_months = true;
          val.fromval.depression_anxiety_last_month = true;
        } else {
          val.fromval.depression_anxiety_six_months = false;
          val.fromval.depression_anxiety_last_month = false;
        }
        if (val.fromval.restlessness_while_sleeping_today) {
          val.fromval.restlessness_while_sleeping_six_months = true;
          val.fromval.restlessness_while_sleeping_last_month = true;
        } else {
          val.fromval.restlessness_while_sleeping_six_months = false;
          val.fromval.restlessness_while_sleeping_last_month = false;
        }
        if (val.fromval.fatigue_today) {
          val.fromval.fatigue_six_months = true;
          val.fromval.fatigue_last_month = true;
        } else {
          val.fromval.fatigue_six_months = false;
          val.fromval.fatigue_last_month = false;
        }
        if (val.fromval.nausea_vomiting_today) {
          val.fromval.nausea_vomiting_six_months = true;
          val.fromval.nausea_vomiting_last_month = true;
        } else {
          val.fromval.nausea_vomiting_six_months = false;
          val.fromval.nausea_vomiting_last_month = false;
        }
        if (val.fromval.arrhythmia_today) {
          val.fromval.arrhythmia_six_months = true;
          val.fromval.arrhythmia_last_month = true;
        } else {
          val.fromval.arrhythmia_six_months = false;
          val.fromval.arrhythmia_last_month = false;
        }
        if (val.fromval.confusion_today) {
          val.fromval.confusion_six_months = true;
          val.fromval.confusion_last_month = true;
        } else {
          val.fromval.confusion_six_months = false;
          val.fromval.confusion_last_month = false;
        }
        if (val.fromval.blurred_vision_today) {
          val.fromval.blurred_vision_six_months = true;
          val.fromval.blurred_vision_last_month = true;
        } else {
          val.fromval.blurred_vision_six_months = false;
          val.fromval.blurred_vision_last_month = false;
        }
        if (val.fromval.extreme_thirst_today) {
          val.fromval.extreme_thirst_six_months = true;
          val.fromval.extreme_thirst_last_month = true;
        } else {
          val.fromval.extreme_thirst_six_months = false;
          val.fromval.extreme_thirst_last_month = false;
        }
        if (val.fromval.extreme_hunger_today) {
          val.fromval.extreme_hunger_six_months = true;
          val.fromval.extreme_hunger_last_month = true;
        } else {
          val.fromval.extreme_hunger_six_months = false;
          val.fromval.extreme_hunger_last_month = false;
        }
        if (val.fromval.painful_urination_today) {
          val.fromval.painful_urination_six_months = true;
          val.fromval.painful_urination_last_month = true;
        } else {
          val.fromval.painful_urination_six_months = false;
          val.fromval.painful_urination_last_month = false;
        }
        if (val.fromval.numb_or_tingling_feet_today) {
          val.fromval.numb_or_tingling_feet_six_months = true;
          val.fromval.numb_or_tingling_feet_last_month = true;
        } else {
          val.fromval.numb_or_tingling_feet_six_months = false;
          val.fromval.numb_or_tingling_feet_last_month = false;
        }
        if (val.fromval.sexual_difficulties_today) {
          val.fromval.sexual_difficulties_six_months = true;
          val.fromval.sexual_difficulties_last_month = true;
        } else {
          val.fromval.sexual_difficulties_six_months = false;
          val.fromval.sexual_difficulties_last_month = false;
        }
        if (val.fromval.urinary_problems_today) {
          val.fromval.urinary_problems_six_months = true;
          val.fromval.urinary_problems_last_month = true;
        } else {
          val.fromval.urinary_problems_six_months = false;
          val.fromval.urinary_problems_last_month = false;
        }
        if (val.fromval.tingling_hands_feet_today) {
          val.fromval.tingling_hands_feet_six_months = true;
          val.fromval.tingling_hands_feet_last_month = true;
        } else {
          val.fromval.tingling_hands_feet_six_months = false;
          val.fromval.tingling_hands_feet_last_month = false;
        }
        if (val.fromval.burning_sensation_today) {
          val.fromval.burning_sensation_six_months = true;
          val.fromval.burning_sensation_last_month = true;
        } else {
          val.fromval.burning_sensation_six_months = false;
          val.fromval.burning_sensation_last_month = false;
        }
        if (val.fromval.angina_today) {
          val.fromval.angina_six_months = true;
          val.fromval.angina_last_month = true;
        } else {
          val.fromval.angina_six_months = false;
          val.fromval.angina_last_month = false;
        }
        if (val.fromval.chest_pain_that_goesaway_with_rest_today) {
          val.fromval.chest_pain_that_goesaway_with_rest_six_months = true;
          val.fromval.chest_pain_that_goesaway_with_rest_last_month = true;
        } else {
          val.fromval.chest_pain_that_goesaway_with_rest_six_months = false;
          val.fromval.chest_pain_that_goesaway_with_rest_last_month = false;
        }
        if (val.fromval.heartburn_today) {
          val.fromval.heartburn_six_months = true;
          val.fromval.heartburn_last_month = true;
        } else {
          val.fromval.heartburn_six_months = false;
          val.fromval.heartburn_last_month = false;
        }
        if (val.fromval.pain_in_calves_today) {
          val.fromval.pain_in_calves_six_months = true;
          val.fromval.pain_in_calves_last_month = true;
        } else {
          val.fromval.pain_in_calves_six_months = false;
          val.fromval.pain_in_calves_last_month = false;
        }
        if (val.fromval.dizziness_lightheadedness_today) {
          val.fromval.dizziness_lightheadedness_six_months = true;
          val.fromval.dizziness_lightheadedness_last_month = true;
        } else {
          val.fromval.dizziness_lightheadedness_six_months = false;
          val.fromval.dizziness_lightheadedness_last_month = false;
        }
        if (val.fromval.irregular_heartbeat_toofastslow_today) {
          val.fromval.irregular_heartbeat_toofastslow_six_months = true;
          val.fromval.irregular_heartbeat_toofastslow_last_month = true;
        } else {
          val.fromval.irregular_heartbeat_toofastslow_six_months = false;
          val.fromval.irregular_heartbeat_toofastslow_last_month = false;
        }
        if (val.fromval.heart_attack_today) {
          val.fromval.heart_attack_six_months = true;
          val.fromval.heart_attack_last_month = true;
        } else {
          val.fromval.heart_attack_six_months = false;
          val.fromval.heart_attack_last_month = false;
        }
        if (val.fromval.stroke_today) {
          val.fromval.stroke_six_months = true;
          val.fromval.stroke_last_month = true;
        } else {
          val.fromval.stroke_six_months = false;
          val.fromval.stroke_last_month = false;
        }
        if (val.fromval.blood_clot_inavein_today) {
          val.fromval.blood_clot_inavein_six_months = true;
          val.fromval.blood_clot_inavein_last_month = true;
        } else {
          val.fromval.blood_clot_inavein_six_months = false;
          val.fromval.blood_clot_inavein_last_month = false;
        }
        if (val.fromval.tia_mini_stroke_today) {
          val.fromval.tia_mini_stroke_six_months = true;
          val.fromval.tia_mini_stroke_last_month = true;
        } else {
          val.fromval.tia_mini_stroke_six_months = false;
          val.fromval.tia_mini_stroke_last_month = false;
        }
        if (val.fromval.chest_painor_discomfort_today) {
          val.fromval.chest_painor_discomfort_six_months = true;
          val.fromval.chest_painor_discomfort_last_month = true;
        } else {
          val.fromval.chest_painor_discomfort_six_months = false;
          val.fromval.chest_painor_discomfort_last_month = false;
        }
        if (val.fromval.fainting_today) {
          val.fromval.fainting_six_months = true;
          val.fromval.fainting_last_month = true;
        } else {
          val.fromval.fainting_six_months = false;
          val.fromval.fainting_last_month = false;
        }
        if (val.fromval.weakness_today) {
          val.fromval.weakness_six_months = true;
          val.fromval.weakness_last_month = true;
        } else {
          val.fromval.weakness_six_months = false;
          val.fromval.weakness_last_month = false;
        }
        if (val.fromval.lingering_discomfort_today) {
          val.fromval.lingering_discomfort_six_months = true;
          val.fromval.lingering_discomfort_last_month = true;
        } else {
          val.fromval.lingering_discomfort_six_months = false;
          val.fromval.lingering_discomfort_last_month = false;
        }
        if (val.fromval.pain_in_affected_joint_today) {
          val.fromval.pain_in_affected_joint_six_months = true;
          val.fromval.pain_in_affected_joint_last_month = true;
        } else {
          val.fromval.pain_in_affected_joint_six_months = false;
          val.fromval.pain_in_affected_joint_last_month = false;
        }
        if (val.fromval.swelling_in_affected_joint_today) {
          val.fromval.swelling_in_affected_joint_six_months = true;
          val.fromval.swelling_in_affected_joint_last_month = true;
        } else {
          val.fromval.swelling_in_affected_joint_six_months = false;
          val.fromval.swelling_in_affected_joint_last_month = false;
        }
        if (val.fromval.redness_in_affected_joint_today) {
          val.fromval.redness_in_affected_joint_six_months = true;
          val.fromval.redness_in_affected_joint_last_month = true;
        } else {
          val.fromval.redness_in_affected_joint_six_months = false;
          val.fromval.redness_in_affected_joint_last_month = false;
        }
        if (val.fromval.chills_today) {
          val.fromval.chills_six_months = true;
          val.fromval.chills_last_month = true;
        } else {
          val.fromval.chills_six_months = false;
          val.fromval.chills_last_month = false;
        }
        if (val.fromval.cough_today) {
          val.fromval.cough_six_months = true;
          val.fromval.cough_last_month = true;
        } else {
          val.fromval.cough_six_months = false;
          val.fromval.cough_last_month = false;
        }
        if (val.fromval.nausea_today) {
          val.fromval.nausea_six_months = true;
          val.fromval.nausea_last_month = true;
        } else {
          val.fromval.nausea_six_months = false;
          val.fromval.nausea_last_month = false;
        }
        if (val.fromval.painorburning_while_urinating_today) {
          val.fromval.painorburning_while_urinating_six_months = true;
          val.fromval.painorburning_while_urinating_last_month = true;
        } else {
          val.fromval.painorburning_while_urinating_six_months = false;
          val.fromval.painorburning_while_urinating_last_month = false;
        }
        if (val.fromval.bloody_urine_today) {
          val.fromval.bloody_urine_six_months = true;
          val.fromval.bloody_urine_last_month = true;
        } else {
          val.fromval.bloody_urine_six_months = false;
          val.fromval.bloody_urine_last_month = false;
        }
        if (val.fromval.runny_nose_today) {
          val.fromval.runny_nose_six_months = true;
          val.fromval.runny_nose_last_month = true;
        } else {
          val.fromval.runny_nose_six_months = false;
          val.fromval.runny_nose_last_month = false;
        }
        if (val.fromval.sore_throat_today) {
          val.fromval.sore_throat_six_months = true;
          val.fromval.sore_throat_last_month = true;
        } else {
          val.fromval.sore_throat_six_months = false;
          val.fromval.sore_throat_last_month = false;
        }
        if (val.fromval.swollen_glands_today) {
          val.fromval.swollen_glands_six_months = true;
          val.fromval.swollen_glands_last_month = true;
        } else {
          val.fromval.swollen_glands_six_months = false;
          val.fromval.swollen_glands_last_month = false;
        }
        if (val.fromval.trouble_breathing_today) {
          val.fromval.trouble_breathing_six_months = true;
          val.fromval.trouble_breathing_last_month = true;
        } else {
          val.fromval.trouble_breathing_six_months = false;
          val.fromval.trouble_breathing_last_month = false;
        }
        if (val.fromval.high_pitched_sound_when_breathing_in_today) {
          val.fromval.high_pitched_sound_when_breathing_in_six_months = true;
          val.fromval.high_pitched_sound_when_breathing_in_last_month = true;
        } else {
          val.fromval.high_pitched_sound_when_breathing_in_six_months = false;
          val.fromval.high_pitched_sound_when_breathing_in_last_month = false;
        }
        if (val.fromval.burning_and_pain_while_urinating_today) {
          val.fromval.burning_and_pain_while_urinating_six_months = true;
          val.fromval.burning_and_pain_while_urinating_last_month = true;
        } else {
          val.fromval.burning_and_pain_while_urinating_six_months = false;
          val.fromval.burning_and_pain_while_urinating_last_month = false;
        }
        if (val.fromval.red_eyes_today) {
          val.fromval.red_eyes_six_months = true;
          val.fromval.red_eyes_last_month = true;
        } else {
          val.fromval.red_eyes_six_months = false;
          val.fromval.red_eyes_last_month = false;
        }
        if (val.fromval.discharge_from_your_eyes_today) {
          val.fromval.discharge_from_your_eyes_six_months = true;
          val.fromval.discharge_from_your_eyes_last_month = true;
        } else {
          val.fromval.discharge_from_your_eyes_six_months = false;
          val.fromval.discharge_from_your_eyes_last_month = false;
        }
        if (val.fromval.abdominal_pain_today) {
          val.fromval.abdominal_pain_six_months = true;
          val.fromval.abdominal_pain_last_month = true;
        } else {
          val.fromval.abdominal_pain_six_months = false;
          val.fromval.abdominal_pain_last_month = false;
        }
        if (val.fromval.change_in_urine_color_today) {
          val.fromval.change_in_urine_color_six_months = true;
          val.fromval.change_in_urine_color_last_month = true;
        } else {
          val.fromval.change_in_urine_color_six_months = false;
          val.fromval.change_in_urine_color_last_month = false;
        }
        if (val.fromval.painful_ejaculation_today) {
          val.fromval.painful_ejaculation_six_months = true;
          val.fromval.painful_ejaculation_last_month = true;
        } else {
          val.fromval.painful_ejaculation_six_months = false;
          val.fromval.painful_ejaculation_last_month = false;
        }
        if (val.fromval.amissed_period_today) {
          val.fromval.amissed_period_six_months = true;
          val.fromval.amissed_period_last_month = true;
        } else {
          val.fromval.amissed_period_six_months = false;
          val.fromval.amissed_period_last_month = false;
        }
        if (val.fromval.swollenortender_breasts_today) {
          val.fromval.swollenortender_breasts_six_months = true;
          val.fromval.swollenortender_breasts_last_month = true;
        } else {
          val.fromval.swollenortender_breasts_six_months = false;
          val.fromval.swollenortender_breasts_last_month = false;
        }
        if (val.fromval.nausea_withorwithout_vomiting_today) {
          val.fromval.nausea_withorwithout_vomiting_six_months = true;
          val.fromval.nausea_withorwithout_vomiting_last_month = true;
        } else {
          val.fromval.nausea_withorwithout_vomiting_six_months = false;
          val.fromval.nausea_withorwithout_vomiting_last_month = false;
        }
        if (val.fromval.light_spotting_today) {
          val.fromval.light_spotting_six_months = true;
          val.fromval.light_spotting_last_month = true;
        } else {
          val.fromval.light_spotting_six_months = false;
          val.fromval.light_spotting_last_month = false;
        }
        if (val.fromval.cramping_today) {
          val.fromval.cramping_six_months = true;
          val.fromval.cramping_last_month = true;
        } else {
          val.fromval.cramping_six_months = false;
          val.fromval.cramping_last_month = false;
        }
        if (val.fromval.bloating_today) {
          val.fromval.bloating_six_months = true;
          val.fromval.bloating_last_month = true;
        } else {
          val.fromval.bloating_six_months = false;
          val.fromval.bloating_last_month = false;
        }
        if (val.fromval.mood_swings_today) {
          val.fromval.mood_swings_six_months = true;
          val.fromval.mood_swings_last_month = true;
        } else {
          val.fromval.mood_swings_six_months = false;
          val.fromval.mood_swings_last_month = false;
        }
        if (val.fromval.skin_dimpling_today) {
          val.fromval.skin_dimpling_six_months = true;
          val.fromval.skin_dimpling_last_month = true;
        } else {
          val.fromval.skin_dimpling_six_months = false;
          val.fromval.skin_dimpling_last_month = false;
        }
        if (val.fromval.breast_pain_today) {
          val.fromval.breast_pain_six_months = true;
          val.fromval.breast_pain_last_month = true;
        } else {
          val.fromval.breast_pain_six_months = false;
          val.fromval.breast_pain_last_month = false;
        }
        if (val.fromval.nipple_retraction_today) {
          val.fromval.nipple_retraction_six_months = true;
          val.fromval.nipple_retraction_last_month = true;
        } else {
          val.fromval.nipple_retraction_six_months = false;
          val.fromval.nipple_retraction_last_month = false;
        }
        if (val.fromval.redness_today) {
          val.fromval.redness_six_months = true;
          val.fromval.redness_last_month = true;
        } else {
          val.fromval.redness_six_months = false;
          val.fromval.redness_last_month = false;
        }
        if (val.fromval.nipple_discharge_today) {
          val.fromval.nipple_discharge_six_months = true;
          val.fromval.nipple_discharge_last_month = true;
        } else {
          val.fromval.nipple_discharge_six_months = false;
          val.fromval.nipple_discharge_last_month = false;
        }
        if (val.fromval.abdominal_swelling_today) {
          val.fromval.abdominal_swelling_six_months = true;
          val.fromval.abdominal_swelling_last_month = true;
        } else {
          val.fromval.abdominal_swelling_six_months = false;
          val.fromval.abdominal_swelling_last_month = false;
        }
        if (val.fromval.quickly_feeling_today) {
          val.fromval.quickly_feeling_six_months = true;
          val.fromval.quickly_feeling_last_month = true;
        } else {
          val.fromval.quickly_feeling_six_months = false;
          val.fromval.quickly_feeling_last_month = false;
        }
        if (val.fromval.discomfort_today) {
          val.fromval.discomfort_six_months = true;
          val.fromval.discomfort_last_month = true;
        } else {
          val.fromval.discomfort_six_months = false;
          val.fromval.discomfort_last_month = false;
        }
        if (val.fromval.blood_stool_today) {
          val.fromval.blood_stool_six_months = true;
          val.fromval.blood_stool_last_month = true;
        } else {
          val.fromval.blood_stool_six_months = false;
          val.fromval.blood_stool_last_month = false;
        }
        if (val.fromval.constipation_today) {
          val.fromval.constipation_six_months = true;
          val.fromval.constipation_last_month = true;
        } else {
          val.fromval.constipation_six_months = false;
          val.fromval.constipation_last_month = false;
        }
        if (val.fromval.diarrhea_today) {
          val.fromval.diarrhea_six_months = true;
          val.fromval.diarrhea_last_month = true;
        } else {
          val.fromval.diarrhea_six_months = false;
          val.fromval.diarrhea_last_month = false;
        }
        if (val.fromval.coughing_today) {
          val.fromval.coughing_six_months = true;
          val.fromval.coughing_last_month = true;
        } else {
          val.fromval.coughing_six_months = false;
          val.fromval.coughing_last_month = false;
        }
        if (val.fromval.dark_colored_urine_today) {
          val.fromval.dark_colored_urine_six_months = true;
          val.fromval.dark_colored_urine_last_month = true;
        } else {
          val.fromval.dark_colored_urine_six_months = false;
          val.fromval.dark_colored_urine_last_month = false;
        }
        if (val.fromval.stools_black_today) {
          val.fromval.stools_black_six_months = true;
          val.fromval.stools_black_last_month = true;
        } else {
          val.fromval.stools_black_six_months = false;
          val.fromval.stools_black_last_month = false;
        }
        if (val.fromval.belly_pain_today) {
          val.fromval.belly_pain_six_months = true;
          val.fromval.belly_pain_last_month = true;
        } else {
          val.fromval.belly_pain_six_months = false;
          val.fromval.belly_pain_last_month = false;
        }
        if (val.fromval.trouble_swallowing_today) {
          val.fromval.trouble_swallowing_six_months = true;
          val.fromval.trouble_swallowing_last_month = true;
        } else {
          val.fromval.trouble_swallowing_six_months = false;
          val.fromval.trouble_swallowing_last_month = false;
        }
        if (val.fromval.feeling_bloated_today) {
          val.fromval.feeling_bloated_six_months = true;
          val.fromval.feeling_bloated_last_month = true;
        } else {
          val.fromval.feeling_bloated_six_months = false;
          val.fromval.feeling_bloated_last_month = false;
        }
        if (val.fromval.indigestion_today) {
          val.fromval.indigestion_six_months = true;
          val.fromval.indigestion_last_month = true;
        } else {
          val.fromval.indigestion_six_months = false;
          val.fromval.indigestion_last_month = false;
        }
        if (val.fromval.diff_urination_today) {
          val.fromval.diff_urination_six_months = true;
          val.fromval.diff_urination_last_month = true;
        } else {
          val.fromval.diff_urination_six_months = false;
          val.fromval.diff_urination_last_month = false;
        }
        if (val.fromval.weak_urine_today) {
          val.fromval.weak_urine_six_months = true;
          val.fromval.weak_urine_last_month = true;
        } else {
          val.fromval.weak_urine_six_months = false;
          val.fromval.weak_urine_last_month = false;
        }
        if (val.fromval.urin_often_today) {
          val.fromval.urin_often_six_months = true;
          val.fromval.urin_often_last_month = true;
        } else {
          val.fromval.urin_often_six_months = false;
          val.fromval.urin_often_last_month = false;
        }
        if (val.fromval.trouble_empty_today) {
          val.fromval.trouble_empty_six_months = true;
          val.fromval.trouble_empty_last_month = true;
        } else {
          val.fromval.trouble_empty_six_months = false;
          val.fromval.trouble_empty_last_month = false;
        }
        if (val.fromval.pain_during_urin_today) {
          val.fromval.pain_during_urin_six_months = true;
          val.fromval.pain_during_urin_last_month = true;
        } else {
          val.fromval.pain_during_urin_six_months = false;
          val.fromval.pain_during_urin_last_month = false;
        }
        if (val.fromval.pain_hips_today) {
          val.fromval.pain_hips_six_months = true;
          val.fromval.pain_hips_last_month = true;
        } else {
          val.fromval.pain_hips_six_months = false;
          val.fromval.pain_hips_last_month = false;
        }
        if (val.fromval.lump_or_swelling_today) {
          val.fromval.lump_or_swelling_six_months = true;
          val.fromval.lump_or_swelling_last_month = true;
        } else {
          val.fromval.lump_or_swelling_six_months = false;
          val.fromval.lump_or_swelling_last_month = false;
        }
        if (val.fromval.feel_heaviness_today) {
          val.fromval.feel_heaviness_six_months = true;
          val.fromval.feel_heaviness_last_month = true;
        } else {
          val.fromval.feel_heaviness_six_months = false;
          val.fromval.feel_heaviness_last_month = false;
        }
        if (val.fromval.dull_ache_lower_belly_today) {
          val.fromval.dull_ache_lower_belly_six_months = true;
          val.fromval.dull_ache_lower_belly_last_month = true;
        } else {
          val.fromval.dull_ache_lower_belly_six_months = false;
          val.fromval.dull_ache_lower_belly_last_month = false;
        }
        if (val.fromval.sudden_swelling_scrotum_today) {
          val.fromval.sudden_swelling_scrotum_six_months = true;
          val.fromval.sudden_swelling_scrotum_last_month = true;
        } else {
          val.fromval.sudden_swelling_scrotum_six_months = false;
          val.fromval.sudden_swelling_scrotum_last_month = false;
        }
        if (val.fromval.discomfort_testicle_today) {
          val.fromval.discomfort_testicle_six_months = true;
          val.fromval.discomfort_testicle_last_month = true;
        } else {
          val.fromval.discomfort_testicle_six_months = false;
          val.fromval.discomfort_testicle_last_month = false;
        }
        if (val.fromval.lump_front_neck_today) {
          val.fromval.lump_front_neck_six_months = true;
          val.fromval.lump_front_neck_last_month = true;
        } else {
          val.fromval.lump_front_neck_six_months = false;
          val.fromval.lump_front_neck_last_month = false;
        }
        if (val.fromval.swollen_lymph_today) {
          val.fromval.swollen_lymph_six_months = true;
          val.fromval.swollen_lymph_last_month = true;
        } else {
          val.fromval.swollen_lymph_six_months = false;
          val.fromval.swollen_lymph_last_month = false;
        }
        if (val.fromval.pain_throat_neck_today) {
          val.fromval.pain_throat_neck_six_months = true;
          val.fromval.pain_throat_neck_last_month = true;
        } else {
          val.fromval.pain_throat_neck_six_months = false;
          val.fromval.pain_throat_neck_last_month = false;
        }
        if (val.fromval.change_voice_today) {
          val.fromval.change_voice_six_months = true;
          val.fromval.change_voice_last_month = true;
        } else {
          val.fromval.change_voice_six_months = false;
          val.fromval.change_voice_last_month = false;
        }
        if (val.fromval.tenderness_breast_tissue_today) {
          val.fromval.tenderness_breast_tissue_six_months = true;
          val.fromval.tenderness_breast_tissue_last_month = true;
        } else {
          val.fromval.tenderness_breast_tissue_six_months = false;
          val.fromval.tenderness_breast_tissue_last_month = false;
        }


      }
    }
    // console.log('listenFormFieldChange>>>>', val.fromval);

    if (val) {
      if (val.fromval.covid19 == '') {
        val.fromval.covid19 = false;
      }
      if (val.fromval.diabetes == '') {
        val.fromval.diabetes = false;
      }
      if (val.fromval.diagnosed == '') {
        val.fromval.diagnosed = false;
      }
      if (val.fieldval !== '' && val.fieldval && val.fieldval !== "formresetbutton" && val.field !== "fromsubmit" && val.field.group !== "grp7") {
        autoSaveData = val.fromval;
        if (this.patientID && this.patientID._id) {
          autoSaveData['_id'] = this.patientID._id
        }
        if (this.autoAPICallFlag) {
          // console.log("this.submitIntakeAuto2 22222", autoSaveData, this.patientID)
          this.submitIntakeAuto(autoSaveData, 'partial')
        }
        // console.log("this.patientID", this.patientID)
      }
      if (typeof val.fieldval == 'boolean' && val.field.group == "grp7") {
        this.openSymptomDescriptionModal(val);
      }
    }


    if (val.field == "fromsubmiterror") {
      this.intakesignForm.markAllAsTouched()
    }

    if (val.field && val.field == "fromsubmit") {

      let dataobj = {
        data: val.fromval
      }
      if (val.fromval.undefined == '') {
        delete dataobj.data.undefined
      }

      if (typeof val.fromval.primary_isurance_id != 'undefined' && val.fromval.primary_isurance_id != null && val.fromval.primary_isurance_id != '') {
        dataobj.data['primary_isurance_name'] = this.primaryinsurancename;
      }
      if (typeof val.fromval.secondary_isurance_id != 'undefined' && val.fromval.secondary_isurance_id != null && val.fromval.secondary_isurance_id != '') {
        dataobj.data['secondary_isurance_name'] = this.secondaryinsurancename;
      }

      console.log('dataobj+++', dataobj)
      // return;
      // this.intakesign(dataobj)

      //  for patient intake  submit

      if (val.fromval.undefined == '') {
        delete dataobj.data.undefined
      }

      console.log('flag', this.email_intake_flag, this.quick_intake_flag, this.patient_intake_flag, this.intakeAuthorize)

      if (this.email_intake_flag) {
        dataobj.data['intake_submitted_by'] = 'email'
        this.intakesign(dataobj)
        console.log('dataobjemail', dataobj)
      }

      if (this.quick_intake_flag) {
        dataobj.data['intake_submitted_by'] = 'quick'
        this.intakesign(dataobj)

      }
      if (this.resubmitBtnFlag) {
        this.resubmitIntake(dataobj)
      }

      if (this.patient_intake_flag && !this.intakeAuthorize) {
        this.addFormLoader = true;
        dataobj.data['intake_submitted_by'] = 'patient'
        dataobj.data.parent_laboratory = this.cookiedata.parent_laboratory;



        if (this.cookiedata.user_type == 'practice') {
          dataobj.data.practice_id = this.cookiedata._id;
          dataobj.data.doctor_id = this.selectedDoctorId;
        }

        if (this.cookiedata.user_type == 'doctor') {
          dataobj.data.practice_id = this.cookiedata.practice_id;
          dataobj.data.doctor_id = this.cookiedata._id;
        }
        if (dataobj.data.patient_phone.length > 14) {
          dataobj.data.patient_phone = dataobj.data.patient_phone.substring(0, 14)
        }

        if (this.paramslink._id) {
          dataobj.data._id = this.paramslink._id;
        }
        if (this.email_intake_flag) {
          delete dataobj.data._id
        }
        dataobj.data.is_demo = false;
        if (this.demoDoctorDashboard) {
          dataobj.data.is_demo = true;
          dataobj.data.practice_id = this.cookiedata._id;
          dataobj.data.doctor_id = this.cookiedata._id;
        }
        if (this.editFormData && this.editFormData.is_demo) {
          dataobj.data.is_demo = true;
        }
        // console.log('dataobj', dataobj.data)
        this.submitIntakeAuto(dataobj, 'patient_intake_flag');
        // this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {
        //   console.log("dataobj>>>>", response)
        //   if (response && response.status == 'success') {
        //     this.matSnackBar.open(response.message, "Ok", {
        //       duration: 1000
        //     });
        //     this.addFormLoader = false;
        //     this.openDialogPatientIintake();
        //   }

        //   if (response && response.status == 'error') {
        //     this.matSnackBar.open(response.message, "", {
        //       duration: 1000
        //     });
        //     this.addFormLoader = false;
        //   }
        // })

      } else {
        if (this.quick_intake_flag) {
          dataobj.data['intake_submitted_by'] = 'quick'
          if (this.demoDoctorDashboardPreviewIntake) {
            this.resubmitIntake(dataobj)
          } else {
            this.intakesign(dataobj)
          }
        }

        if (this.intakeAuthorize) {
          dataobj.data['patient_signature'] = this.intake_sign;
          dataobj.data['_id'] = this.paramslink._id
          if (this.editFormData && this.editFormData.is_demo) {
            dataobj.data.is_demo = true;
          }
          // return;
          // console.log('dataobj', dataobj.data)

          this.submitIntakeAuto(dataobj, 'intakeAuthorize')
          // this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {
          //   console.log("dataobj>>>>", response)


          //   if (response && response.status == 'success') {
          //     this.matSnackBar.open(response.message, "Ok", {
          //       duration: 1000
          //     });
          //     this.addFormLoader = false;
          //     this.router.navigateByUrl(`thankyou-page/${this.paramslink._id}`);


          //   }

          //   if (response && response.status == 'error') {
          //     this.matSnackBar.open(response.message, "", {
          //       duration: 1000
          //     });
          //     this.addFormLoader = false;
          //   }
          // })
        }
      }

    }

  }




  openSymptomDescriptionModal(symptom: any) {
    console.log("symptomNotesObj name symptom", symptom)
    let autoSaveData: any = {};
    autoSaveData = symptom.fromval;
    if (this.patientID && this.patientID._id) {
      autoSaveData['_id'] = this.patientID._id
    }
    if (this.editFormData) {
      autoSaveData['_id'] = this.editFormData._id
    }
    if (symptom) {
      if (symptom.fieldval) {
        let fieldName = this.setSymptomName(symptom.field.name.split('_'));
        const dialogRef = this.dialog.open(IntakeSymptomDescriptionModal, {
          data: { symptom: symptom.field.name, allNotes: this.symptomNotesObj, fieldName: fieldName }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('openSymptomDescriptionModal symptomNotesObj result', result);
          if (result) {
            let tempObj: any = {};
            for (let key in symptom.fromval) {
              if (key == symptom.field.name) {
                let name: any;
                let newName: any = '';
                name = key.split('_');
                if (name.length > 0) {
                  newName = this.setSymptomName(name);
                }
                if (this.symptomNotesObj[newName]) {
                  delete this.symptomNotesObj[newName];
                }
                // let tempNewName: any = newName.split('_');
                // tempNewName = tempNewName.slice(0, tempNewName.length - 1).join("_");
                tempObj[newName] = { note: result.reason, unsure: result.unsureSymptomSelected };
                this.symptomNotesObj[newName] = { note: result.reason, unsure: result.unsureSymptomSelected };
              }
            }
            if (this.autoAPICallFlag) {
              if (this.patientID && this.patientID._id) {
                autoSaveData['_id'] = this.patientID._id
              }
              autoSaveData = { ...this.symptomNotesObj, ...autoSaveData }
              this.setEditButton(this.symptomNotesObj, 'add');
              // console.log("autoSaveData",autoSaveData)
              this.submitIntakeAuto(autoSaveData, 'partial');
            }
          } else {
            this.formfieldrefreshdata = {
              field: symptom.field.name,
              value: false
            };
          }
        })
      } else {
        for (let key in symptom.fromval) {
          let keyname: any = key;
          keyname = keyname.split('_');
          let fieldname: any = symptom.field.name;
          fieldname = fieldname.split('_');
          let name: any;
          let newName: any = '';
          name = key.split('_');
          if (key == symptom.field.name) {
            newName = this.setSymptomName(name);
            if (this.symptomNotesObj[newName]) {
              //               if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('quick-intake/:_id')) {
              //                 // delete this.symptomNotesObj[newName];
              //                 this.symptomNotesObj[newName] = {};
              // this.findSymptomValue(symptom.field.name, symptom.fromval);
              //               } else {
              //               }
              this.findSymptomValue(symptom.field.name, symptom.fromval);
              // let buttonName = newName.split('_');
              // buttonName = buttonName.slice(0, buttonName.length - 1).join("_");
              // buttonName = buttonName + '_today_editButton';
              // if (document.querySelector(`.${buttonName}`)) {
              //   document.querySelector(`.${buttonName}`).remove();
              // }
            }
          }
        }
        if (this.autoAPICallFlag) {
          autoSaveData = { ...this.symptomNotesObj, ...autoSaveData }
          // console.log("autoSaveData", autoSaveData)
          this.submitIntakeAuto(autoSaveData, 'partial');
        }
      }
    }
  }

  setSymptomName(name: any) {
    let newName: any = '';
    if (name.length < 3) {
      name.push("_")
      name.push("_")
      newName = name.slice(0, name.length - 3).join("");
      newName = newName + '_notes';
    } else if (name.length < 4) {
      if ((name[name.length - 2] == 'last') || (name[name.length - 2] == 'six')) {
        name.push("_");
        name.push("_");
        newName = name.slice(0, name.length - 4).join("_");
      } else {
        name.push("_");
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName + '_notes';
    } else if (name.length > 4) {
      if ((name[name.length - 1] == 'today')) {
        newName = name.slice(0, name.length - 1).join("_");
      } else {
        console.log('name', name)
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName + '_notes';
    } else {
      if ((name[name.length - 1] == 'today') && name.length > 3) {
        newName = name.slice(0, name.length - 1).join("_");
      } else {
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName + '_notes';
    }
    return newName;
  }
  findSymptomValue(clickedItem: string, allValues: any) {
    let unCheckedValue: any = null;
    let allSymptoms: any = allValues;
    let newName: any = '';
    let newNameToday: boolean = true;
    let newNameLastMonth: boolean = true;
    let newNameSixMonth: boolean = true;
    unCheckedValue = clickedItem.split("_");
    if (unCheckedValue.length < 3) {
      unCheckedValue.push("_")
      unCheckedValue.push("_")
      newName = unCheckedValue.slice(0, unCheckedValue.length - 3).join("");
    } else if (unCheckedValue.length < 4) {
      if ((unCheckedValue[unCheckedValue.length - 2] == 'last') || (unCheckedValue[unCheckedValue.length - 2] == 'six')) {
        unCheckedValue.push("_");
        unCheckedValue.push("_");
        newName = unCheckedValue.slice(0, unCheckedValue.length - 4).join("_");
      } else {
        unCheckedValue.push("_");
        newName = unCheckedValue.slice(0, unCheckedValue.length - 2).join("_");
      }
    } else if (unCheckedValue.length > 4) {
      if ((unCheckedValue[unCheckedValue.length - 1] == 'today')) {
        newName = unCheckedValue.slice(0, unCheckedValue.length - 1).join("_");
      } else {
        newName = unCheckedValue.slice(0, unCheckedValue.length - 2).join("_");
      }
    } else {
      if ((unCheckedValue[unCheckedValue.length - 1] == 'today') && unCheckedValue.length > 3) {
        newName = unCheckedValue.slice(0, unCheckedValue.length - 1).join("_");
      } else {
        newName = unCheckedValue.slice(0, unCheckedValue.length - 2).join("_");
      }
    }
    console.log("newName", newName)
    for (let key in allSymptoms) {
      if (newName) {
        let newNameTodayKey: any = (newName + "_today");
        let newNameLastMonthKey: any = (newName + "_last_month");
        let newNameSixMonthKey: any = (newName + "_six_months");
        let noteName: any = (newName + "_notes");
        if (newNameTodayKey == key) {
          newNameToday = allSymptoms[key];
        }
        if (newNameLastMonthKey == key) {
          newNameLastMonth = allSymptoms[key];
        }
        if (newNameSixMonthKey == key) {
          newNameSixMonth = allSymptoms[key];
        }
        if (!newNameToday && !newNameLastMonth && !newNameSixMonth) {
          // let buttonName = newName.split('_');
          // buttonName = buttonName.slice(0, buttonName.length - 1).join("_");
          let buttonName: any = (newName + '_today_editButton');
          if (document.querySelector(`.${buttonName}`)) {
            document.querySelector(`.${buttonName}`).remove();
          }
          this.symptomNotesObj[noteName] = {};
        }
      }
    }
    if (this.findEditedSymptoms && this.findEditedSymptoms.length > 0) {
      this.findEditedSymptoms = this.findEditedSymptoms.filter((e: any) => {
        return e !== (newName + '_today')
      })
    };
  }

  setEditButton(dataObj: any, type?: string) {
    let self = this;
    let newName: any = '';
    let parentElement: any;
    for (let key in dataObj) {
      let name: any = key.split("_");
      newName = '';
      if (name.length < 3) {
        name.push("_")
        name.push("_")
        newName = name.slice(0, name.length - 3).join("");
      } else if (name.length < 4) {
        if ((name[name.length - 2] == 'last') || (name[name.length - 2] == 'six')) {
          name.push("_");
          name.push("_");
          newName = name.slice(0, name.length - 4).join("_");
        } else {
          name.push("_");
          newName = name.slice(0, name.length - 2).join("_");
        }
      } else if (name.length > 4) {
        if ((name[name.length - 1] == 'today')) {
          newName = name.slice(0, name.length - 1).join("_");
        } else {
          if ((name[name.length - 1] == 'notes') && name.length > 3) {
            newName = name.slice(0, name.length - 1).join("_");
          } else {
            newName = name.slice(0, name.length - 2).join("_");
          }
        }
      } else {
        if ((name[name.length - 1] == 'today') && name.length > 3) {
          newName = name.slice(0, name.length - 1).join("_");
        } else {
          if ((name[name.length - 1] == 'notes') && name.length > 3) {
            newName = name.slice(0, name.length - 1).join("_");
          } else {
            newName = name.slice(0, name.length - 2).join("_");
          }
        }
      }
      let noteKey: any = `${newName}_notes`;
      if (noteKey == key) {
        if (this.symptomNotesObj[key] && Object.keys(this.symptomNotesObj[key]).length > 0) {
          this.findEditedSymptoms.push(`${newName}_today`);
        }
      }
    }
    if (this.findEditedSymptoms.length > 0) {
      this.findEditedSymptoms = this.findEditedSymptoms.filter((item: any, index: number) => this.findEditedSymptoms.indexOf(item) === index);
      this.findEditedSymptoms.forEach((e: any, i: number) => {
        if (document.querySelector(`.form_field_wrapper${e}`)) {
          let buttonName = e.split('_');
          buttonName = buttonName.slice(0, buttonName.length - 1).join("_");
          buttonName = buttonName + '_today_editButton';
          if (document.querySelector(`.${buttonName}`)) {
            document.querySelector(`.${buttonName}`).remove();
          }
          let getCheckedSymptom = document.querySelector(`.form_field_wrapper${e}`);
          parentElement = getCheckedSymptom.parentNode;
          let editButtunCreate = document.createElement('button');
          editButtunCreate.type = 'button';
          editButtunCreate.innerHTML = `<span class="material-icons">mode_edit</span>`;
          editButtunCreate.classList.add("symptomNoteEditButton");
          editButtunCreate.classList.add(`${e}_editButton`);
          editButtunCreate.addEventListener('click', function (event) {
            self.getSymptomDetails(e);
          });
          parentElement.appendChild(editButtunCreate);
        }
      })
    }
  }

  getSymptomDetails(symptom: any) {
    // console.log("symptom", symptom, this.symptomNotesObj)

    // if (this.patientID && this.patientID._id) {
    //   autoSaveData['_id'] = this.patientID._id
    // }
    const dialogRef = this.dialog.open(IntakeSymptomDescriptionModal, {
      data: { symptom: symptom, allNotes: this.symptomNotesObj, type: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let symptomName: any = symptom.split("_");
        symptomName = symptomName.slice(0, symptomName.length - 1).join("_");
        symptomName = symptomName + '_notes';
        for (let key in this.symptomNotesObj) {
          if (key == symptomName) {
            if (this.symptomNotesObj[symptomName]) {
              delete this.symptomNotesObj[symptomName];
            }
            this.symptomNotesObj[symptomName] = { note: result.reason, unsure: result.unsureSymptomSelected };
          }
        }

        // console.log('this.symptomNotesObj', this.symptomNotesObj);
        this.submitIntakeAuto(this.symptomNotesObj, 'partial');
        // console.log("symptom", symptom, this.symptomNotesObj, "this.editFormData", this.editFormData)
      }
    })
  }
  submitIntakeAuto(dataobj: any, type: string) {
    // console.log("submitIntakeAuto", dataobj, type)
    this.autoAPICallFlag = true;
    if (type == 'partial') {
      if (Object.keys(this.patientID).length == 0) {
        this.autoAPICallFlag = false;
      }
      if (dataobj.primary_isurance_id == '') {
        delete dataobj.primary_isurance_id;
      }

      dataobj.is_partial_done = true;
      if (this.email_intake_flag) {
        dataobj.email_intake_id = this.paramslink._id;
        dataobj['intake_submitted_by'] = 'email';
      }
      if (this.quick_intake_flag) {
        dataobj['intake_submitted_by'] = 'quick';
      }
      if (this.patient_intake_flag && !this.intakeAuthorize) {
        dataobj['intake_submitted_by'] = 'patient';
      }
      if (this.quick_intake_flag) {
        dataobj['intake_submitted_by'] = 'quick'
      }
      if (this.emailIntakeData != '' && this.emailIntakeData != null) {
        dataobj['doctor_id'] = this.emailIntakeData.doctor_id;
        dataobj['practice_id'] = this.emailIntakeData.practice_id;
      }
    } else {
      if (this.patientID && this.patientID._id) {
        dataobj.data['_id'] = this.patientID._id
      }
      dataobj.data.is_partial_done = false;
    }
    if (dataobj.data && Object.keys(dataobj.data).length > 0) {
      for (let key in dataobj.data) {
        if (dataobj.data[key] == null) {
          dataobj.data[key] = false;
        }
      }
    }
    console.log('dataobj', dataobj, "this.emailIntakeData", this.patientID)


    // console.log("this.activatedRoute", this.activatedRoute)
    if (this.cookiedata.user_type == "sales_person" && this.activatedRoute.snapshot.routeConfig.path.includes('doctor/patient-intake')) {
      this.autoAPICallFlag = true;
      if (dataobj?.data?.is_partial_done !== undefined && dataobj?.data?.is_partial_done !== null && dataobj?.data?.is_partial_done == false) {
        this.matSnackBar.open("This is For Demo Purpose!", "", {
          duration: 1000
        });
        setTimeout(() => {
          this.addFormLoader = false;
          this.router.navigateByUrl("sales_person/dashboard/doctor");
        }, 1000);
      }
    } else {
      this.apiservice.httpViaPost('intake/leadadd', dataobj).subscribe((response) => {
        console.log("dataobj>>>>", response, "this.patientID", this.patientID)
        if (response) {
          if (type !== 'partial') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
          }
          this.addFormLoader = false;
          if (response && response.status == 'success') {
            if (type == 'partial') {
              this.patientID = response.results.res;
              if (Object.keys(this.patientID).length == 0) {
                setTimeout(() => {
                  this.autoAPICallFlag = true;
                }, 200)
              } else {
                this.autoAPICallFlag = true;
              }
            }
            if (type == 'patient_intake_flag') {
              this.openDialogPatientIintake();
            }
            if (type == 'intakeAuthorize') {
              this.router.navigateByUrl(`thankyou-page/${this.paramslink._id}`);
            }
            if (type == 'intakesign') {
              this.emailparams = response.results.res._id;
              console.log('emailparams', this.emailparams)
              this.matSnackBar.open(response.message, "Ok", {
                duration: 1000
              });
              // email intake submit end point call
              this.addFormLoader = false;
              if (this.email_intake_flag) {
                let dataobjemail: any = {
                  _id: this.paramslink._id,
                  patient_id: response.results.res._id
                }
                this.apiservice.httpViaPost('email/resetemailintake', dataobjemail).subscribe((response) => {
                })
                this.router.navigateByUrl(`thankyou-page/${this.emailparams}`);
              }

              if (this.intakeAuthorize) {
                this.router.navigateByUrl(`thankyou-page/${this.paramslink._id}`);
              }

              if (this.quick_intake_flag) {
                this.router.navigateByUrl(`thankyou-page/${this.paramslink._id}`);
              }
            }
          }
        }


      })
    }
  }

  signDialog(): void {
    const dialogRef = this.dialog.open(signPage, {

      data: { heading: this.intake_sign, practiceDetails: this.practiceDetail },
      panelClass: 'signPageDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // this.animal = result;
      if (result) {
        this.intakesignForm.patchValue({ intake_sign: result.signinput })
        this.intake_sign = result.signinput;
        this.errorclass = true;
      }

    });
  }

  openDialogPatientIintake(): void {

    const dialogRef = this.dialog.open(PatientIintakesuccess, {
      data: { is_demo: this.demoDoctorDashboard }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      window.scroll(0, 0)
      this.resetform();
      if (document.querySelectorAll('.symptomNoteEditButton') && document.querySelectorAll('.symptomNoteEditButton').length > 0) {
        document.querySelectorAll('.symptomNoteEditButton').forEach((e: any) => {
          e.remove();
        })
      }
      this.resetformcss = true;
      if (this.cookiedata.user_type == 'practice' && result == 1) {
        this.doctorChosseModal();
      }
    });
  }

  resetform() {
    setTimeout(() => {
      this.formfieldrefreshdata = {
        field: "resetform",
      };
      this.patientID = {}
      this.findEditedSymptoms = [];
      this.symptomNotesObj = {};
    }, 500);

  }

  doctorChosseModal() {
    const dialogRef = this.dialog.open(DoctorChooseModal, {
      data: { practice_id: this.cookiedata._id },
      panelClass: 'doctorChosseModal',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.selectedDoctorId = result

    });
  }
}

@Component({
  selector: 'signPage',
  templateUrl: 'signPage.html',
})
export class signPage {

  public signvalu: any = '';
  public addsignForm: FormGroup;
  public modaldata: any = {};
  public practiceData: any = null;



  constructor(
    public dialogRef: MatDialogRef<signPage>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    this.modaldata = data;
    if (this.modaldata.practiceDetails) {
      this.practiceData = this.modaldata.practiceDetails;
    }
    console.log(this.modaldata, 'modal data++');

    this.signvalu = this.modaldata.heading;

    console.log('signvalu222', this.signvalu)
  }
  // ngAfterViewInit(){
  //   window.scroll(0,0)
  //   alert("gghh")

  // }

  myFunction(event) {

    // var div = document.getElementById('divID');
    console.log('event', event.target.value)

    this.signvalu = event.target.value

    console.log('signvalu', this.signvalu)

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {

    this.addsignForm = new FormGroup({
      signinput: new FormControl('', Validators.required),

    });

    if (this.modaldata && this.modaldata.heading) {
      this.addsignForm.patchValue({
        signinput: this.modaldata.heading,


      })

    }




  }


  addsign() {
    this.addsignForm.markAllAsTouched()
    if (this.addsignForm.valid) {

      // console.log(this.modaldata, 'dataSubmit');


      if (this.modaldata.item) {
        this.addsignForm.patchValue({
          signinput: this.modaldata.item.signinput,
        });
      }
      console.log('signinput', this.addsignForm.value)

      this.dialogRef.close(this.addsignForm.value);


    }
  }



}



@Component({
  selector: 'patient-Iintake-success',
  templateUrl: 'patient-Iintake-success.html',
})
export class PatientIintakesuccess {

  public cookiedata: any = '';
  public modalData: any = null;

  constructor(
    public dialogRef: MatDialogRef<PatientIintakesuccess>, public router: Router, public cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.modalData = data;
    }
    this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    if (this.cookiedata.user_type == 'practice') {
      this.dialogRef.close(1);
    } else {
      this.dialogRef.close();
    }
    if (this.modalData && this.modalData.is_demo) {
      this.router.navigateByUrl(`${this.cookiedata.user_type}/doctor/patient-intake`);
    } else {
      this.router.navigateByUrl(`${this.cookiedata.user_type}/patient-intake`);
    }

  }

  onBackClick(): void {
    this.dialogRef.close();
    if (this.modalData && this.modalData.is_demo) {
      this.router.navigateByUrl(`${this.cookiedata.user_type}/dashboard/doctor`);
    } else {
      this.router.navigateByUrl(this.cookiedata.user_type + '/dashboard');
    }
  }
}


@Component({
  selector: 'choose-doctor',
  templateUrl: 'choose-doctor.html',
})
export class DoctorChooseModal {

  public cookiedata: any = '';
  public modalData: any = null;
  public practiceformdata: any;
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  constructor(
    public dialogRef: MatDialogRef<DoctorChooseModal>, public router: Router, public cookieService: CookieService, public apiservice: HttpServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.modalData = data;
    }
    this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
    dialogRef.disableClose = true;


    this.practiceformdata =
    {
      // from start
      successmessage: 'Save Successfully !!', // success message
      submittext: 'Save',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: true,
      hidecancel: true,
      jwttoken: '',
      fields: [

        {
          label: "Select Doctor",
          name: "doctor_id",
          type: 'autocomplete',
          endpoint: "users/doctorautocomplete",
          search_field: "name_search",
          val: [],
          value: [],
          validations: [
            { rule: 'required', message: 'Doctor Name is Required' }
          ]

        },

        {
          label: 'Practice Id',
          name: 'practice_id',
          value: this.modalData.practice_id,
          type: 'hidden',
          classname: 'aaa',
          // validations: [
          //   { rule: 'required', message: 'patient Name is Required' },

          // ],
        },

      ]
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  listenFormFieldChange(val) {
    console.warn('listen>>>>', val);
    if (val.field == "fromsubmit" && val.fieldval == "success") {
      this.dialogRef.close(val.fromval.doctor_id);
    }

  }
}


@Component({
  selector: 'intake-symptom-description-modal',
  templateUrl: 'intake-symptom-description-modal.html',
})
export class IntakeSymptomDescriptionModal {

  public cookiedata: any = '';
  public modalData: any = null;
  public symptomReasonForm: FormGroup;
  public addsignLoader: boolean = false;
  public unsureSymptomChecked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<IntakeSymptomDescriptionModal>, public router: Router, public cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.modalData = data;
    }
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    console.log("symptom data", this.data)
    this.symptomReasonForm = new FormGroup({
      reason: new FormControl('', Validators.required),
      unsureSymptomSelected: new FormControl(false)
    });
    // this.symptomReasonForm.markAllAsTouched();
    if (this.data) {
      if (this.data.type) {
        if (this.data.allNotes && Object.keys(this.data.allNotes).length > 0) {
          let symptomName: any = this.data.symptom.split("_");
          symptomName = symptomName.slice(0, symptomName.length - 1).join("_");
          symptomName = symptomName + '_notes';
          for (let key in this.data.allNotes) {
            if (key == symptomName) {
              this.symptomReasonForm.patchValue({
                reason: this.data.allNotes[key].note,
                unsureSymptomSelected: this.data.allNotes[key].unsure
              })
              if (this.data.allNotes[key].unsure) {
                this.unsureSymptomChecked = true;
                this.symptomReasonForm.controls['reason'].disable();
              }
            }
          }
        }
      } else {
        if (this.data.allNotes && Object.keys(this.data.allNotes).length > 0) {
          for (let key in this.data.allNotes) {
            if (this.data.fieldName == key) {
              this.symptomReasonForm.patchValue({
                reason: this.data.allNotes[key].note,
                unsureSymptomSelected: this.data.allNotes[key].unsure
              })
              if (this.data.allNotes[key] && this.data.allNotes[key].unsure !== undefined && this.data.allNotes[key].unsure !== null && this.data.allNotes[key].unsure) {
                this.unsureSymptomChecked = true;
                this.symptomReasonForm.controls['reason'].disable();
              }
            }
          }
        }
      }

    }

  }


  setSymptomName(name: any) {
    let newName: any = '';
    if (name.length < 3) {
      name.push("_")
      name.push("_")
      newName = name.slice(0, name.length - 3).join("");
      newName = newName;
    } else if (name.length < 4) {
      if ((name[name.length - 2] == 'last') || (name[name.length - 2] == 'six')) {
        name.push("_");
        name.push("_");
        newName = name.slice(0, name.length - 4).join("_");
      } else {
        name.push("_");
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName;
    } else if (name.length > 4) {
      if ((name[name.length - 1] == 'today')) {
        newName = name.slice(0, name.length - 1).join("_");
      } else {
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName;
    } else {
      if ((name[name.length - 1] == 'today') && name.length > 3) {
        newName = name.slice(0, name.length - 1).join("_");
      } else {
        newName = name.slice(0, name.length - 2).join("_");
      }
      newName = newName;
    }
    return newName;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  addSymptomReasonMessage() {
    this.symptomReasonForm.markAllAsTouched();
    if (this.symptomReasonForm.valid) {
      if (this.unsureSymptomChecked) {
        let obj: any = {
          // reason: this.symptomReasonForm.controls['reason'].value,
          reason: '',
          unsureSymptomSelected: true
        }
        this.dialogRef.close(obj);
      } else {
        this.dialogRef.close(this.symptomReasonForm.value);
      }
    }
  }
  unsureSymptom(event: any) {
    console.log("event", event)
    this.unsureSymptomChecked = false;
    this.symptomReasonForm.controls['reason'].enable();
    if (event.checked) {
      this.unsureSymptomChecked = true;
      this.symptomReasonForm.controls['reason'].disable();
    }
    console.log(this.symptomReasonForm)
  }

}