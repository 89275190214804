import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgetpasswordComponent } from './components/auth/forgetpassword/forgetpassword.component';
import { AuthgaurdGuard } from './shared/authgaurd.guard';
import { AccountSettingsComponent } from './layouts/account-settings/account-settings.component';
import { ResolveService } from 'src/app/service/resolve.service';
import { ChangePasswordComponent } from './layouts/change-password/change-password.component';
import { ManageLocationComponent } from './layouts/manage-location/manage-location.component';
import { NotFoundComponent } from './components/auth/not-found/not-found.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { LandingResolveService } from './service/landing-resolve.service';
import { LeadIntakesComponent } from './components/leads/lead-intakes/lead-intakes.component';
import { ChangeLabLogoComponent } from './layouts/change-lab-logo/change-lab-logo.component';
import { AiUpgradationComponent } from './components/dashboard/ai-upgradation/ai-upgradation.component';
import { ClinicalReportViewComponent } from './components/leads/clinical-report-view/clinical-report-view.component';
import { IntakePdfSheetComponent } from './components/leads/intake-pdf/intake-pdf-sheet/intake-pdf-sheet.component';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: '404', component: NotFoundComponent, canActivate: [AuthgaurdGuard] },

  { path: 'login', component: LoginComponent },
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'updatepassword/:accesscode', component: ForgetpasswordComponent },
  { path: 'admin', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
  { path: 'doctor', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
  { path: 'laboratory', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
  { path: 'sales_person', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
  { path: 'practice', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },


  { path: 'dashboard', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'admin', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'admin/dashboard', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'doctor', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'laboratory/dashboard', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'sales_person/dashboard', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'practice/dashboard', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },


  { path: 'admin/taxonomies', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/taxonomies/taxonomies.module').then(m => m.TaxonomiesModule) },
  { path: 'admin/email-template', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/email-template/email-template.module').then(m => m.EmailTemplateModule) },
  // { path: 'admin/account-settings', component: AccountSettingsComponent },
  { path: ':usertype/miscellaneous', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule) },
  { path: ':usertype', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule) },
  { path: ':usertype/training', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/trainingmanagement/trainingmanagement.module').then(m => m.TrainingManagementModule) },

  { path: 'admin/manage', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/taxonomies/taxonomies.module').then(m => m.TaxonomiesModule) },
  // { path: 'order-sheet', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule) },
  { path: ':usertype', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule) },
  { path: 'intake', loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule) },
  { path: 'patient-pdf-view/:_id', component: IntakePdfSheetComponent },
  {
    path: 'quick-intake/:_id', component: LeadIntakesComponent,
    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        // source: 'data_pece',
        // 'condition': {
        //   'limit': 10, 'skip': 0
        // },
        searchcondition: {}
      },
      endpoint: 'intake/getleaddata'
    },
  },
  {
    path: 'email-intake/:_id', component: LeadIntakesComponent,
    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        'condition': { 'limit': 10, 'skip': 0 },
        sort: { type: 'desc', field: '_id' },
        searchcondition: {}
      },
      endpoint: 'email/emailintake'
    },
  },
  {
    path: 'email-intake-form/:_id', component: LeadIntakesComponent,
    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        'condition': { 'limit': 10, 'skip': 0 },
        sort: { type: 'desc', field: '_id' },
        searchcondition: {}
      },
      endpoint: 'email/emailintake'
    },
  },
  {
    path: 'intake-authorization/:_id', component: LeadIntakesComponent,
    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        'condition': { 'limit': 10, 'skip': 0 },
        sort: { type: 'desc', field: '_id' },
        searchcondition: {}
      },
      endpoint: 'intake/getleaddata'
    },
  },

  // { path: ':usertype', canActivate: [AuthgaurdGuard], loadChildren: () => import('./components/leads/leads.module').then(m => m.LeadsModule) },

  { path: '', loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule) },


  { path: ':usertype/change-password', canActivate: [AuthgaurdGuard], component: ChangePasswordComponent },
  { path: ':usertype/set-password', canActivate: [AuthgaurdGuard], component: ChangePasswordComponent },


  {
    path: ':usertype/manage-location', component: ManageLocationComponent,


    resolve: { data: ResolveService },
    data: {
      requestcondition: {
        'condition': {
          'limit': 5, 'skip': 0
        },

      },
      endpoint: 'users/fetchLocationDetails'
    },
  },

  {
    path: ':usertype/account-settings',
    canActivate: [AuthgaurdGuard],
    component: AccountSettingsComponent,
    resolve: { data: ResolveService },
    data: {
      requestcondition: {
        // source: 'data_pece',
        'condition': {
          'limit': 10, 'skip': 0
        },
        sort: {},
        searchcondition: {}
      },
      endpoint: 'users/fetchUserDetails'
    },
  },
  {
    path: ':usertype/update-logo',
    canActivate: [AuthgaurdGuard],
    component: ChangeLabLogoComponent,
    resolve: { data: ResolveService },
    data: {
      requestcondition: {
        // // source: 'data_pece',
        // 'condition': {
        //   'limit': 10, 'skip': 0
        // },
        // sort: {},
        searchcondition: {}
      },
      endpoint: 'users/fetchlogo'
    },
  },

  {
    path: 'practice-signup/:uniquecode', component: LandingPageComponent,

    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        // source: 'data_pece',
        // 'condition': {
        //   'limit': 10, 'skip': 0
        // },
        searchcondition: {}
      },
      endpoint: 'landingpage/findsender'
    },
  },
  /******* Sales Person Demo Doctor*********/
  {
    path: 'demo/quick-intake/:_id', component: LeadIntakesComponent,
    resolve: { data: LandingResolveService },
    data: {
      requestcondition: {
        // source: 'data_pece',
        // 'condition': {
        //   'limit': 10, 'skip': 0
        // },
        searchcondition: {}
      },
      endpoint: 'intake/getleaddata'
    },
  },
  /******* Sales Person Demo Doctor*********/

  /*******AI Upgradation********/
  {
    path: 'practice/ai-upgradation', component: AiUpgradationComponent,
    // resolve: { data: ResolveService },
    //     data: {
    //       requestcondition: {
    //         // source: 'data_pece',
    //         'condition': {
    //           'limit': 10, 'skip': 0
    //         },
    //         sort: {
    //           'type': 'desc', 'field': '_id'
    //         },
    //         searchcondition: {}
    //       },
    //       endpoint: 'users/fetchUsers'
    //     }
  },
  {
    path: 'clinical-report-view/:_id', component: ClinicalReportViewComponent
    // resolve: { data: ResolveService },
    //     data: {
    //       requestcondition: {
    //         // source: 'data_pece',
    //         // 'condition': {
    //         //   'limit': 10, 'skip': 0
    //         // },
    //         searchcondition: {}
    //       },
    //       endpoint: 'intake/getleaddata'
    //     },
  },

  { path: '**', component: LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
