<!-- <button (click)="pdfGen()">PDf</button>
<button (click)="print()">Print</button> -->
<div id="element-to-print">
    <ng-container *ngIf="intakeFlag">

        <div class="main_block_intake">
            <!-- <mat-icon>account_circle</mat-icon> -->
            <!-- <div class="show_img_block">
            <p class="show_img"></p>
        </div> -->
            <img class="img_block" src="../../../../../assets/images/practice2lab_logo.ico" />

            <div class="second_main_block_intake">

                <h2>personal information</h2>

                <div class="content_block_intake">

                    <table class="tbl_class_intake_details">

                        <tr>

                            <td>
                                <strong>Patient
                                    Name</strong> <span>{{patient_details.patient_name}}</span>
                            </td>


                            <td>
                                <strong>Patient
                                    Email</strong> <span>{{patient_details.patient_email}}</span>
                            </td>


                            <td>
                                <strong>Patient
                                    Phone</strong> <span>{{patient_details.patient_phone}}</span>
                            </td>

                        </tr>

                        <tr>

                            <td>
                                <strong>Gender</strong>
                                <span>{{patient_details.patient_gender}}</span>
                            </td>


                            <td>
                                <strong>Address</strong>
                                <span>{{patient_details.patient_address}}</span>
                            </td>


                            <td>
                                <strong>State
                                </strong> <span>{{patient_details.state}}</span>
                            </td>

                        </tr>

                        <tr>

                            <td>
                                <strong>City</strong>
                                <span>{{patient_details.city}}</span>
                            </td>


                            <td>
                                <strong>Zip</strong>
                                <span>{{patient_details.zip}}</span>
                            </td>


                            <td>
                                <strong>Date of
                                    Birth</strong> <span>{{patient_details.patient_dob | date: 'MM/dd/yyyy'}} </span>
                            </td>

                        </tr>

                        <tr>

                            <td>
                                <strong>Height</strong>
                                <span>{{patient_details.height}}</span>
                            </td>


                            <td>
                                <strong>Weight</strong>
                                <span>{{patient_details.weight}}</span>
                            </td>




                        </tr>




                    </table>

                </div>

                <h2>INSURANCE DETAILS</h2>

                <div class="content_block_intake">

                    <table class="tbl_class_intake_details_insurance">

                        <tr>

                            <td>
                                <strong>Primary Insurance </strong>
                                <span>{{patient_details.primary_isurance_name}}</span>
                            </td>


                            <td>
                                <strong>Primary Insurance No</strong>
                                <span>{{patient_details.primary_isurance_no}}</span>
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <strong>Secondary Insurance</strong>
                                <span>{{patient_details.secondary_isurance_name}}</span>
                            </td>

                            <td>
                                <strong>Secondary Insurance No</strong>
                                <span>{{patient_details.secondary_isurance_no}}</span>
                            </td>

                        </tr>

                    </table>

                </div>

                <h2 class="additonal_head">ADDITIONAL QUESTIONS</h2>

                <div class="content_block_intake">

                    <p>Have you ever been diagnosed with PVD (Peripheral Vascular Disease)? : <span *ngIf="patient_details.diagnosed">Yes</span> <span *ngIf="!patient_details.diagnosed">No</span>
                    </p>

                    <p>Are you currently diagnosed with Diabetes? : <span *ngIf="patient_details.diabetes">Yes</span>
                        <span *ngIf="!patient_details.diabetes">No</span>
                    </p>

                    <p>Have you been diagnosed and/or treated for Covid19 in the last year? : <span *ngIf="patient_details.covid19">Yes</span> <span *ngIf="!patient_details.covid19">No</span>
                    </p>

                </div>

                <h2 class="additonal_head"> SYMPTOMS CHECKLIST</h2>

                <div class="content_block_intake">

                    <table class="tbl_class_intake">

                        <tr>

                            <td class="class_td_container">

                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Headaches</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.headaches_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.headaches_last_month">
                                                Today <input type="checkbox"
                                                    [checked]="patient_details.headaches_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.headaches_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.headaches_notes.unsure == false"><strong>Note:</strong> {{patient_details.headaches_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.headaches_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">

                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Skin
                                                and eyes that appear yellowish (jaundice)</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.skin_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.skin_last_month">
                                                Today <input type="checkbox" [checked]="patient_details.skin_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.skin_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.skin_notes.unsure == false"><strong>Note:</strong> {{patient_details.skin_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.skin_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>

                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Abdominal
                                                pain and swelling</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.abdominal_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.abdominal_last_month">
                                                Today <input type="checkbox"
                                                    [checked]="patient_details.abdominal_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.abdominal_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.abdominal_notes.unsure == false"><strong>Note:</strong> {{patient_details.abdominal_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.abdominal_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>

                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Swelling
                                                in the legs and ankles</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.selling_legs_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.selling_legs_last_month">
                                                Today <input type="checkbox"
                                                    [checked]="patient_details.selling_legs_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.selling_legs_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.selling_legs_notes.unsure == false"><strong>Note:</strong> {{patient_details.selling_legs_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.selling_legs_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>



                    </table>

                    <div id="page2el" class="html2pdf__page-break"></div>

                    <br />

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Dry,
                                                itchy skin</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.itchy_skin_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.itchy_skin_last_month">
                                                Today <input type="checkbox"
                                                    [checked]="patient_details.itchy_skin_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.itchy_skin_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.itchy_skin_notes.unsure == false"><strong>Note:</strong> {{patient_details.itchy_skin_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.itchy_skin_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Dark
                                                urine color</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.dark_urine_color_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.dark_urine_color_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.dark_urine_color_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.dark_urine_color_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.dark_urine_color_notes.unsure == false"><strong>Note:</strong> {{patient_details.dark_urine_color_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.dark_urine_color_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <!-- 
                    <br />
                    <br /> -->

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Pale
                                                stool color</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.pale_stool_color_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.pale_stool_color_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.pale_stool_color_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pale_stool_color_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pale_stool_color_notes.unsure == false"><strong>Note:</strong> {{patient_details.pale_stool_color_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pale_stool_color_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Chronic
                                                fatigue</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chronic_fatigue_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.chronic_fatigue_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.chronic_fatigue_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chronic_fatigue_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chronic_fatigue_notes.unsure == false"><strong>Note:</strong> {{patient_details.chronic_fatigue_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chronic_fatigue_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Nausea
                                                or vomiting</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.vomiting_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.vomiting_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.vomiting_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.vomiting_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.vomiting_notes.unsure == false"><strong>Note:</strong> {{patient_details.vomiting_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.vomiting_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Loss
                                                of appetite</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.lossofappetite_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.lossofappetite_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.lossofappetite_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.lossofappetite_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.lossofappetite_notes.unsure == false"><strong>Note:</strong> {{patient_details.lossofappetite_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.lossofappetite_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Tendency
                                                to bruise easily</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.bruise_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.bruise_last_month"> Today
                                                <input type="checkbox" [checked]="patient_details.bruise_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.bruise_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.bruise_notes.unsure == false"><strong>Note:</strong> {{patient_details.bruise_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.bruise_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Fever</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.fever_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.fever_last_month"> Today
                                                <input type="checkbox" [checked]="patient_details.fever_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.fever_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.fever_notes.unsure == false"><strong>Note:</strong> {{patient_details.fever_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.fever_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Insomnia</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.insomnia_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.insomnia_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.insomnia_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.insomnia_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.insomnia_notes.unsure == false"><strong>Note:</strong> {{patient_details.insomnia_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.insomnia_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Urinating less</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.urinating_less_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.urinating_less_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.urinating_less_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.urinating_less_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.urinating_less_notes.unsure == false"><strong>Note:</strong> {{patient_details.urinating_less_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.urinating_less_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Muscle cramps</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.muscle_cramps_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.muscle_cramps_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.muscle_cramps_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.muscle_cramps_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.muscle_cramps_notes.unsure == false"><strong>Note:</strong> {{patient_details.muscle_cramps_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.muscle_cramps_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Swelling of feet and ankles</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.swelling_feet_ankles_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.swelling_feet_ankles_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.swelling_feet_ankles_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swelling_feet_ankles_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swelling_feet_ankles_notes.unsure == false"><strong>Note:</strong> {{patient_details.swelling_feet_ankles_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swelling_feet_ankles_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Shortness
                                                of breath</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.shortness_of_breath_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.shortness_of_breath_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.shortness_of_breath_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.shortness_of_breath_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.shortness_of_breath_notes.unsure == false"><strong>Note:</strong> {{patient_details.shortness_of_breath_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.shortness_of_breath_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Chest pain</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chest_pain_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.chest_pain_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.chest_pain_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chest_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chest_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.chest_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chest_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Back
                                                Pain</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.back_pain_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.back_pain_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.back_pain_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.back_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.back_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.back_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.back_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Frequent urination</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.frequent_urination_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.frequent_urination_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.frequent_urination_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.frequent_urination_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.frequent_urination_notes.unsure == false"><strong>Note:</strong> {{patient_details.frequent_urination_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.frequent_urination_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Foamy
                                                urine</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.foamy_urine_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.foamy_urine_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.foamy_urine_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.foamy_urine_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.foamy_urine_notes.unsure == false"><strong>Note:</strong> {{patient_details.foamy_urine_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.foamy_urine_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Blood in your urine</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.blood_in_your_urine_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.blood_in_your_urine_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.blood_in_your_urine_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.blood_in_your_urine_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.blood_in_your_urine_notes.unsure == false"><strong>Note:</strong> {{patient_details.blood_in_your_urine_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.blood_in_your_urine_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">

                        <tr>

                            <td class="class_td_container">

                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Dizziness</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.dizziness_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.dizziness_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.dizziness_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.dizziness_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.dizziness_notes.unsure == false"><strong>Note:</strong> {{patient_details.dizziness_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.dizziness_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Swelling around your eyes</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.swelling_around_your_eyes_six_months">
                                                Last
                                                month <input type="checkbox"
                                                    [checked]="patient_details.swelling_around_your_eyes_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.swelling_around_your_eyes_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swelling_around_your_eyes_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swelling_around_your_eyes_notes.unsure == false"><strong>Note:</strong> {{patient_details.swelling_around_your_eyes_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swelling_around_your_eyes_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>High
                                                blood sugar</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.high_blood_sugar_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.high_blood_sugar_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.high_blood_sugar_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.high_blood_sugar_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.high_blood_sugar_notes.unsure == false"><strong>Note:</strong> {{patient_details.high_blood_sugar_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.high_blood_sugar_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Weight loss</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.weight_loss_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.weight_loss_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.weight_loss_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.weight_loss_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.weight_loss_notes.unsure == false"><strong>Note:</strong> {{patient_details.weight_loss_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.weight_loss_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Joint
                                                pain</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.joint_pain_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.joint_pain_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.joint_pain_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.joint_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.joint_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.joint_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.joint_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Joint
                                                Inflammation</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.joint_inflammation_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.joint_inflammation_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.joint_inflammation_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.joint_inflammation_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.joint_inflammation_notes.unsure == false"><strong>Note:</strong> {{patient_details.joint_inflammation_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.joint_inflammation_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Limited
                                                range of motion</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.limited_range_motion_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.limited_range_motion_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.limited_range_motion_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.limited_range_motion_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.limited_range_motion_notes.unsure == false"><strong>Note:</strong> {{patient_details.limited_range_motion_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.limited_range_motion_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Coughing,
                                                especially at night</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.coughing_especially_at_night_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.coughing_especially_at_night_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.coughing_especially_at_night_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.coughing_especially_at_night_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.coughing_especially_at_night_notes.unsure == false"><strong>Note:</strong> {{patient_details.coughing_especially_at_night_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.coughing_especially_at_night_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Wheezing
                                                or squeaking when you breathe</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.wheezing_squeaking_when_you_breathe_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.wheezing_squeaking_when_you_breathe_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.wheezing_squeaking_when_you_breathe_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.wheezing_squeaking_when_you_breathe_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.wheezing_squeaking_when_you_breathe_notes.unsure == false"><strong>Note:</strong> {{patient_details.wheezing_squeaking_when_you_breathe_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.wheezing_squeaking_when_you_breathe_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Chest
                                                tightness or pain</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chest_tightness_pain_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.chest_tightness_pain_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.chest_tightness_pain_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chest_tightness_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chest_tightness_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.chest_tightness_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chest_tightness_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Low
                                                energy</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.low_energy_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.low_energy_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.low_energy_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.low_energy_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.low_energy_notes.unsure == false"><strong>Note:</strong> {{patient_details.low_energy_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.low_energy_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Blue
                                                fingernails</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.blue_fingernails_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.blue_fingernails_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.blue_fingernails_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.blue_fingernails_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.blue_fingernails_notes.unsure == false"><strong>Note:</strong> {{patient_details.blue_fingernails_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.blue_fingernails_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>



                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Frequent
                                                colds or flu</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.frequent_colds_flu_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.frequent_colds_flu_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.frequent_colds_flu_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.frequent_colds_flu_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.frequent_colds_flu_notes.unsure == false"><strong>Note:</strong> {{patient_details.frequent_colds_flu_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.frequent_colds_flu_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Coughing
                                                up lots of mucus</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.coughing_up_lots_mucus_six_months"> Last
                                                month <input type="checkbox"
                                                    [checked]="patient_details.coughing_up_lots_mucus_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.coughing_up_lots_mucus_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.coughing_up_lots_mucus_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.coughing_up_lots_mucus_notes.unsure == false"><strong>Note:</strong> {{patient_details.coughing_up_lots_mucus_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.coughing_up_lots_mucus_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>A
                                                cough that doesn't go away</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.cough_that_doesnt_go_away_six_months">
                                                Last
                                                month <input type="checkbox"
                                                    [checked]="patient_details.cough_that_doesnt_go_away_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.cough_that_doesnt_go_away_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.cough_that_doesnt_go_away_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.cough_that_doesnt_go_away_notes.unsure == false"><strong>Note:</strong> {{patient_details.cough_that_doesnt_go_away_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.cough_that_doesnt_go_away_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Depression
                                                or anxiety</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.depression_anxiety_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.depression_anxiety_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.depression_anxiety_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.depression_anxiety_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.depression_anxiety_notes.unsure == false"><strong>Note:</strong> {{patient_details.depression_anxiety_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.depression_anxiety_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Restlessness
                                                while sleeping</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.restlessness_while_sleeping_six_months">
                                                Last
                                                month <input type="checkbox"
                                                    [checked]="patient_details.restlessness_while_sleeping_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.restlessness_while_sleeping_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.restlessness_while_sleeping_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.restlessness_while_sleeping_notes.unsure == false"><strong>Note:</strong> {{patient_details.restlessness_while_sleeping_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.restlessness_while_sleeping_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Fatigue</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.fatigue_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.fatigue_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.fatigue_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.fatigue_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.fatigue_notes.unsure == false"><strong>Note:</strong> {{patient_details.fatigue_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.fatigue_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <div id="page2el" class="html2pdf__page-break"></div>
                    <br />

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Nausea
                                                and vomiting</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.nausea_vomiting_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.nausea_vomiting_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.nausea_vomiting_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.nausea_vomiting_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.nausea_vomiting_notes.unsure == false"><strong>Note:</strong> {{patient_details.nausea_vomiting_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.nausea_vomiting_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Arrhythmia: Irregular heartbeat
                                                rhythm</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.arrhythmia_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.arrhythmia_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.arrhythmia_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.arrhythmia_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.arrhythmia_notes.unsure == false"><strong>Note:</strong> {{patient_details.arrhythmia_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.arrhythmia_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Confusion</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.confusion_six_months"> Last month <input
                                                    type="checkbox" [checked]="patient_details.confusion_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.confusion_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.confusion_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.confusion_notes.unsure == false"><strong>Note:</strong> {{patient_details.confusion_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.confusion_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Blurred vision</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.blurred_vision_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.blurred_vision_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.blurred_vision_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.blurred_vision_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.blurred_vision_notes.unsure == false"><strong>Note:</strong> {{patient_details.blurred_vision_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.blurred_vision_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <!-- 
                    <br />
                    <br />
                    <br />
                    <br /> -->


                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Extreme
                                                thirst</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.extreme_thirst_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.extreme_thirst_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.extreme_thirst_today"></span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.extreme_thirst_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.extreme_thirst_notes.unsure == false"><strong>Note:</strong> {{patient_details.extreme_thirst_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.extreme_thirst_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Extreme Hunger</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.extreme_hunger_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.extreme_hunger_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.extreme_hunger_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.extreme_hunger_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.extreme_hunger_notes.unsure == false"><strong>Note:</strong> {{patient_details.extreme_hunger_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.extreme_hunger_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>



                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Painful
                                                urination</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.painful_urination_six_months"> Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.painful_urination_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.painful_urination_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.painful_urination_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.painful_urination_notes.unsure == false"><strong>Note:</strong> {{patient_details.painful_urination_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.painful_urination_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Numb
                                                or tingling feet</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.numb_or_tingling_feet_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.numb_or_tingling_feet_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.numb_or_tingling_feet_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.numb_or_tingling_feet_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.numb_or_tingling_feet_notes.unsure == false"><strong>Note:</strong> {{patient_details.numb_or_tingling_feet_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.numb_or_tingling_feet_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Sexual
                                                Difficulties</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.sexual_difficulties_six_months"> Last
                                                month
                                                <input type="checkbox"
                                                    [checked]="patient_details.sexual_difficulties_last_month"> Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.sexual_difficulties_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.sexual_difficulties_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.sexual_difficulties_notes.unsure == false"><strong>Note:</strong> {{patient_details.sexual_difficulties_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.sexual_difficulties_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Urinary problems</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.urinary_problems_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.urinary_problems_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.urinary_problems_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.urinary_problems_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.urinary_problems_notes.unsure == false"><strong>Note:</strong> {{patient_details.urinary_problems_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.urinary_problems_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Tingling Hands & Feet</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.tingling_hands_feet_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.tingling_hands_feet_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.tingling_hands_feet_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.tingling_hands_feet_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.tingling_hands_feet_notes.unsure == false"><strong>Note:</strong> {{patient_details.tingling_hands_feet_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.tingling_hands_feet_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Burning
                                                Sensation</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.burning_sensation_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.burning_sensation_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.burning_sensation_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.burning_sensation_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.burning_sensation_notes.unsure == false"><strong>Note:</strong> {{patient_details.burning_sensation_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.burning_sensation_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Angina (Chest pain that goes away
                                                with rest)</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.angina_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.angina_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.angina_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.angina_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.angina_notes.unsure == false"><strong>Note:</strong> {{patient_details.angina_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.angina_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Chest Pain That Goes Away With Rest</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chest_pain_that_goesaway_with_rest_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.chest_pain_that_goesaway_with_rest_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.chest_pain_that_goesaway_with_rest_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chest_pain_that_goesaway_with_rest_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chest_pain_that_goesaway_with_rest_notes.unsure == false"><strong>Note:</strong> {{patient_details.chest_pain_that_goesaway_with_rest_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chest_pain_that_goesaway_with_rest_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>


                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Heartburn</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.heartburn_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.heartburn_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.heartburn_today">
                                            </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.heartburn_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.heartburn_notes.unsure == false"><strong>Note:</strong> {{patient_details.heartburn_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.heartburn_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Pain
                                                In Calves</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.pain_in_calves_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.pain_in_calves_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.pain_in_calves_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pain_in_calves_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pain_in_calves_notes.unsure == false"><strong>Note:</strong> {{patient_details.pain_in_calves_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pain_in_calves_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Dizziness
                                                Or Lightheadedness</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.dizziness_lightheadedness_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.dizziness_lightheadedness_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.dizziness_lightheadedness_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.dizziness_lightheadedness_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.dizziness_lightheadedness_notes.unsure == false"><strong>Note:</strong> {{patient_details.dizziness_lightheadedness_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.dizziness_lightheadedness_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Irregular
                                                Heartbeat, Too Fast/Slow</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.irregular_heartbeat_toofastslow_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.irregular_heartbeat_toofastslow_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.irregular_heartbeat_toofastslow_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.irregular_heartbeat_toofastslow_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.irregular_heartbeat_toofastslow_notes.unsure == false"><strong>Note:</strong> {{patient_details.irregular_heartbeat_toofastslow_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.irregular_heartbeat_toofastslow_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Heart
                                                Attack</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.heart_attack_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.heart_attack_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.heart_attack_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.heart_attack_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.heart_attack_notes.unsure == false"><strong>Note:</strong> {{patient_details.heart_attack_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.heart_attack_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Stroke</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.stroke_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.stroke_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.stroke_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.stroke_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.stroke_notes.unsure == false"><strong>Note:</strong> {{patient_details.stroke_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.stroke_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Blood
                                                Clot In A Vein (Venous Thrombosis)</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.blood_clot_inavein_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.blood_clot_inavein_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.blood_clot_inavein_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.blood_clot_inavein_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.blood_clot_inavein_notes.unsure == false"><strong>Note:</strong> {{patient_details.blood_clot_inavein_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.blood_clot_inavein_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Tia
                                                (Mini Stroke)</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.tia_mini_stroke_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.tia_mini_stroke_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.tia_mini_stroke_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.tia_mini_stroke_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.tia_mini_stroke_notes.unsure == false"><strong>Note:</strong> {{patient_details.tia_mini_stroke_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.tia_mini_stroke_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Chest
                                                Pain Or Discomfort</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chest_painor_discomfort_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.chest_painor_discomfort_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.chest_painor_discomfort_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chest_painor_discomfort_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chest_painor_discomfort_notes.unsure == false"><strong>Note:</strong> {{patient_details.chest_painor_discomfort_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chest_painor_discomfort_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Fainting</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.fainting_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.fainting_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.fainting_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.fainting_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.fainting_notes.unsure == false"><strong>Note:</strong> {{patient_details.fainting_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.fainting_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>



                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Weakness</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.weakness_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.weakness_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.weakness_today">
                                            </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.weakness_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.weakness_notes.unsure == false"><strong>Note:</strong> {{patient_details.weakness_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.weakness_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Lingering discomfort</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.lingering_discomfort_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.lingering_discomfort_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.lingering_discomfort_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.lingering_discomfort_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.lingering_discomfort_notes.unsure == false"><strong>Note:</strong> {{patient_details.lingering_discomfort_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.lingering_discomfort_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Pain
                                                in affected joint</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.pain_in_affected_joint_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.pain_in_affected_joint_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.pain_in_affected_joint_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pain_in_affected_joint_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pain_in_affected_joint_notes.unsure == false"><strong>Note:</strong> {{patient_details.pain_in_affected_joint_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pain_in_affected_joint_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Swelling in affected joint</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.swelling_in_affected_joint_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.swelling_in_affected_joint_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.swelling_in_affected_joint_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swelling_in_affected_joint_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swelling_in_affected_joint_notes.unsure == false"><strong>Note:</strong> {{patient_details.swelling_in_affected_joint_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swelling_in_affected_joint_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Redness
                                                in affected joint</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.redness_in_affected_joint_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.redness_in_affected_joint_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.redness_in_affected_joint_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.redness_in_affected_joint_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.redness_in_affected_joint_notes.unsure == false"><strong>Note:</strong> {{patient_details.redness_in_affected_joint_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.redness_in_affected_joint_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Chills</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chills_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.chills_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.chills_today">
                                            </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chills_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chills_notes.unsure == false"><strong>Note:</strong> {{patient_details.chills_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chills_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Cough</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.cough_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.cough_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.cough_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.cough_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.cough_notes.unsure == false"><strong>Note:</strong> {{patient_details.cough_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.cough_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Chest pain when you breathe or cough</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.chest_pain_when_you_breatheorcough_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.chest_pain_when_you_breatheorcough_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.chest_pain_when_you_breatheorcough_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.chest_pain_when_you_breatheorcough_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.chest_pain_when_you_breatheorcough_notes.unsure == false"><strong>Note:</strong> {{patient_details.chest_pain_when_you_breatheorcough_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.chest_pain_when_you_breatheorcough_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Nausea</strong>
                                        </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.nausea_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.nausea_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.nausea_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.nausea_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.nausea_notes.unsure == false"><strong>Note:</strong> {{patient_details.nausea_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.nausea_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">

                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Pain or burning while urinating</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.painorburning_while_urinating_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.painorburning_while_urinating_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.painorburning_while_urinating_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.painorburning_while_urinating_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.painorburning_while_urinating_notes.unsure == false"><strong>Note:</strong> {{patient_details.painorburning_while_urinating_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.painorburning_while_urinating_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Bloody
                                                urine</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.bloody_urine_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.bloody_urine_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.bloody_urine_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.bloody_urine_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.bloody_urine_notes.unsure == false"><strong>Note:</strong> {{patient_details.bloody_urine_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.bloody_urine_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Runny nose</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.runny_nose_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.runny_nose_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.runny_nose_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.runny_nose_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.runny_nose_notes.unsure == false"><strong>Note:</strong> {{patient_details.runny_nose_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.runny_nose_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <div id="page2el" class="html2pdf__page-break"></div>
                    <br />
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Sore
                                                throat</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.sore_throat_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.sore_throat_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.sore_throat_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.sore_throat_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.sore_throat_notes.unsure == false"><strong>Note:</strong> {{patient_details.sore_throat_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.sore_throat_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Swollen glands</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.swollen_glands_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.swollen_glands_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.swollen_glands_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swollen_glands_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swollen_glands_notes.unsure == false"><strong>Note:</strong> {{patient_details.swollen_glands_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swollen_glands_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Trouble
                                                breathing</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.trouble_breathing_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.trouble_breathing_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.trouble_breathing_today"> </span></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.trouble_breathing_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.trouble_breathing_notes.unsure == false"><strong>Note:</strong> {{patient_details.trouble_breathing_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.trouble_breathing_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                High-pitched sound when breathing in</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.high_pitched_sound_when_breathing_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.high_pitched_sound_when_breathing_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.high_pitched_sound_when_breathing_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.high_pitched_sound_when_breathing_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.high_pitched_sound_when_breathing_notes.unsure == false"><strong>Note:</strong> {{patient_details.high_pitched_sound_when_breathing_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.high_pitched_sound_when_breathing_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Burning
                                                and pain while urinating</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.burning_and_pain_while_urinating_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.burning_and_pain_while_urinating_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.burning_and_pain_while_urinating_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.burning_and_pain_while_urinating_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.burning_and_pain_while_urinating_notes.unsure == false"><strong>Note:</strong> {{patient_details.burning_and_pain_while_urinating_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.burning_and_pain_while_urinating_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Red eyes</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.red_eyes_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.red_eyes_last_month">
                                                Today<input type="checkbox" [checked]="patient_details.red_eyes_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.red_eyes_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.red_eyes_notes.unsure == false"><strong>Note:</strong> {{patient_details.red_eyes_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.red_eyes_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <!-- 
                    <br />
                    <br /> -->

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Discharge
                                                from your eyes</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.discharge_from_your_eyes_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.discharge_from_your_eyes_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.discharge_from_your_eyes_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.discharge_from_your_eyes_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.discharge_from_your_eyes_notes.unsure == false"><strong>Note:</strong> {{patient_details.discharge_from_your_eyes_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.discharge_from_your_eyes_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Abdominal pain</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.abdominal_pain_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.abdominal_pain_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.abdominal_pain_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.abdominal_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.abdominal_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.abdominal_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.abdominal_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Change
                                                in urine color</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.change_in_urine_color_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.change_in_urine_color_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.change_in_urine_color_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.change_in_urine_color_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.change_in_urine_color_notes.unsure == false"><strong>Note:</strong> {{patient_details.change_in_urine_color_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.change_in_urine_color_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Painful ejaculation</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.painful_ejaculation_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.painful_ejaculation_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.painful_ejaculation_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.painful_ejaculation_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.painful_ejaculation_notes.unsure == false"><strong>Note:</strong> {{patient_details.painful_ejaculation_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.painful_ejaculation_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>A
                                                missed period</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.amissed_period_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.amissed_period_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.amissed_period_today"> </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.amissed_period_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.amissed_period_notes.unsure == false"><strong>Note:</strong> {{patient_details.amissed_period_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.amissed_period_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>
                                                Swollen or tender breasts</strong> </td>
                                        <td class="sub_class_td_data"> <span>
                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.swollenortender_breasts_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.swollenortender_breasts_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.swollenortender_breasts_today"> </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swollenortender_breasts_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swollenortender_breasts_notes.unsure == false"><strong>Note:</strong> {{patient_details.swollenortender_breasts_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swollenortender_breasts_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Nausea, with or without vomiting</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.nausea_withorwithout_vomiting_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.nausea_withorwithout_vomiting_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.nausea_withorwithout_vomiting_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.nausea_withorwithout_vomiting_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.nausea_withorwithout_vomiting_notes.unsure == false"><strong>Note:</strong> {{patient_details.nausea_withorwithout_vomiting_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.nausea_withorwithout_vomiting_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Light spotting</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span> 
6 months
                                                <input type="checkbox" [checked]="patient_details.light_spotting_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.light_spotting_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.light_spotting_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.light_spotting_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.light_spotting_notes.unsure == false"><strong>Note:</strong> {{patient_details.light_spotting_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.light_spotting_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Cramping</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.cramping_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.cramping_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.cramping_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.cramping_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.cramping_notes.unsure == false"><strong>Note:</strong> {{patient_details.cramping_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.cramping_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Bloating</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.bloating_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.bloating_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.bloating_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.bloating_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.bloating_notes.unsure == false"><strong>Note:</strong> {{patient_details.bloating_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.bloating_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Mood swings</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.mood_swings_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.mood_swings_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.mood_swings_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.mood_swings_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.mood_swings_notes.unsure == false"><strong>Note:</strong> {{patient_details.mood_swings_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.mood_swings_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Skin irritation or dimpling</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.skin_dimpling_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.skin_dimpling_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.skin_dimpling_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.skin_dimpling_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.skin_dimpling_notes.unsure == false"><strong>Note:</strong> {{patient_details.skin_dimpling_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.skin_dimpling_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Breast or nipple pain</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.breast_pain_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.breast_pain_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.breast_pain_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.breast_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.breast_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.breast_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.breast_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Nipple retraction</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_retraction_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_retraction_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_retraction_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.nipple_retraction_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.nipple_retraction_notes.unsure == false"><strong>Note:</strong> {{patient_details.nipple_retraction_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.nipple_retraction_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Redness, scaliness, or thickening of
                                                the nipple or breast skin</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.redness_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.redness_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.redness_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.redness_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.redness_notes.unsure == false"><strong>Note:</strong> {{patient_details.redness_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.redness_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Nipple discharge</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_discharge_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_discharge_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.nipple_discharge_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.nipple_discharge_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.nipple_discharge_notes.unsure == false"><strong>Note:</strong> {{patient_details.nipple_discharge_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.nipple_discharge_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Abdominal bloating or swelling</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.abdominal_swelling_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.abdominal_swelling_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.abdominal_swelling_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.abdominal_swelling_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.abdominal_swelling_notes.unsure == false"><strong>Note:</strong> {{patient_details.abdominal_swelling_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.abdominal_swelling_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Quickly feeling full when eating</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.quickly_feeling_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.quickly_feeling_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.quickly_feeling_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.quickly_feeling_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.quickly_feeling_notes.unsure == false"><strong>Note:</strong> {{patient_details.quickly_feeling_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.quickly_feeling_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Discomfort in the pelvic area</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.discomfort_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.discomfort_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.discomfort_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.discomfort_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.discomfort_notes.unsure == false"><strong>Note:</strong> {{patient_details.discomfort_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.discomfort_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Blood in Stool</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.blood_stool_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.blood_stool_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.blood_stool_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.blood_stool_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.blood_stool_notes.unsure == false"><strong>Note:</strong> {{patient_details.blood_stool_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.blood_stool_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Constipation</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.constipation_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.constipation_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.constipation_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.constipation_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.constipation_notes.unsure == false"><strong>Note:</strong> {{patient_details.constipation_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.constipation_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Diarrhea</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.diarrhea_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.diarrhea_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.diarrhea_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.diarrhea_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.diarrhea_notes.unsure == false"><strong>Note:</strong> {{patient_details.diarrhea_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.diarrhea_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Coughing up blood</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.coughing_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.coughing_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.coughing_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.coughing_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.coughing_notes.unsure == false"><strong>Note:</strong> {{patient_details.coughing_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.coughing_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Dark colored urine</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.dark_colored_urine_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.dark_colored_urine_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.dark_colored_urine_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.dark_colored_urine_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.dark_colored_urine_notes.unsure == false"><strong>Note:</strong> {{patient_details.dark_colored_urine_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.dark_colored_urine_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Stools that look black</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.stools_black_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.stools_black_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.stools_black_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.stools_black_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.stools_black_notes.unsure == false"><strong>Note:</strong> {{patient_details.stools_black_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.stools_black_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Belly pain</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.belly_pain_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.belly_pain_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.belly_pain_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.belly_pain_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.belly_pain_notes.unsure == false"><strong>Note:</strong> {{patient_details.belly_pain_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.belly_pain_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Trouble swallowing</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.trouble_swallowing_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.trouble_swallowing_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.trouble_swallowing_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.trouble_swallowing_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.trouble_swallowing_notes.unsure == false"><strong>Note:</strong> {{patient_details.trouble_swallowing_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.trouble_swallowing_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Feeling bloated after eating</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.feeling_bloated_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.feeling_bloated_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.feeling_bloated_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.feeling_bloated_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.feeling_bloated_notes.unsure == false"><strong>Note:</strong> {{patient_details.feeling_bloated_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.feeling_bloated_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Indigestion</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.indigestion_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.indigestion_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.indigestion_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.indigestion_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.indigestion_notes.unsure == false"><strong>Note:</strong> {{patient_details.indigestion_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.indigestion_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Difficulty starting urination</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.diff_urination_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.diff_urination_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.diff_urination_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.diff_urination_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.diff_urination_notes.unsure == false"><strong>Note:</strong> {{patient_details.diff_urination_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.diff_urination_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Weak or interrupted flow of urine</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.weak_urine_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.weak_urine_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.weak_urine_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.weak_urine_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.weak_urine_notes.unsure == false"><strong>Note:</strong> {{patient_details.weak_urine_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.weak_urine_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Urinating often, especially at night</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.urin_often_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.urin_often_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.urin_often_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.urin_often_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.urin_often_notes.unsure == false"><strong>Note:</strong> {{patient_details.urin_often_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.urin_often_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Trouble emptying the bladder completely</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.trouble_empty_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.trouble_empty_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.trouble_empty_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.trouble_empty_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.trouble_empty_notes.unsure == false"><strong>Note:</strong> {{patient_details.trouble_empty_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.trouble_empty_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Pain or burning during urination</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.pain_during_urin_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.pain_during_urin_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.pain_during_urin_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pain_during_urin_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pain_during_urin_notes.unsure == false"><strong>Note:</strong> {{patient_details.pain_during_urin_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pain_during_urin_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <div id="page2el" class="html2pdf__page-break"></div>
                    <br />
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Pain in the back, hips, or pelvis that doesn't go away</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.pain_hips_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.pain_hips_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.pain_hips_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pain_hips_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pain_hips_notes.unsure == false"><strong>Note:</strong> {{patient_details.pain_hips_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pain_hips_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>A lump or swelling in either testicle</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.lump_or_swelling_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.lump_or_swelling_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.lump_or_swelling_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.lump_or_swelling_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.lump_or_swelling_notes.unsure == false"><strong>Note:</strong> {{patient_details.lump_or_swelling_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.lump_or_swelling_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>A feeling of heaviness in the scrotum</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.feel_heaviness_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.feel_heaviness_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.feel_heaviness_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.feel_heaviness_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.feel_heaviness_notes.unsure == false"><strong>Note:</strong> {{patient_details.feel_heaviness_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.feel_heaviness_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>A dull ache in the lower belly or groin</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.dull_ache_lower_belly_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.dull_ache_lower_belly_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.dull_ache_lower_belly_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.dull_ache_lower_belly_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.dull_ache_lower_belly_notes.unsure == false"><strong>Note:</strong> {{patient_details.dull_ache_lower_belly_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.dull_ache_lower_belly_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Sudden swelling in the scrotum</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.sudden_swelling_scrotum_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.sudden_swelling_scrotum_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.sudden_swelling_scrotum_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.sudden_swelling_scrotum_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.sudden_swelling_scrotum_notes.unsure == false"><strong>Note:</strong> {{patient_details.sudden_swelling_scrotum_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.sudden_swelling_scrotum_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Pain or discomfort in a testicle or the scrotum</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.discomfort_testicle_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.discomfort_testicle_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.discomfort_testicle_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.discomfort_testicle_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.discomfort_testicle_notes.unsure == false"><strong>Note:</strong> {{patient_details.discomfort_testicle_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.discomfort_testicle_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>A lump in the front of your neck</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.lump_front_neck_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.lump_front_neck_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.lump_front_neck_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.lump_front_neck_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.lump_front_neck_notes.unsure == false"><strong>Note:</strong> {{patient_details.lump_front_neck_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.lump_front_neck_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Swollen lymph nodes in your neck</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.swollen_lymph_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.swollen_lymph_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.swollen_lymph_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.swollen_lymph_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.swollen_lymph_notes.unsure == false"><strong>Note:</strong> {{patient_details.swollen_lymph_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.swollen_lymph_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="tbl_class_intake">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container"> <strong>Pain in your throat and/or neck</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox" [checked]="patient_details.pain_throat_neck_six_months">
                                                Last month
                                                <input type="checkbox" [checked]="patient_details.pain_throat_neck_last_month">
                                                Today
                                                <input type="checkbox" [checked]="patient_details.pain_throat_neck_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.pain_throat_neck_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.pain_throat_neck_notes.unsure == false"><strong>Note:</strong> {{patient_details.pain_throat_neck_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.pain_throat_neck_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container">
                                            <strong>Change to your voice, such as hoarseness</strong>
                                        </td>
                                        <td class="sub_class_td_data">
                                            <span>
                                                6 months
                                                <input type="checkbox"
                                                    [checked]="patient_details.change_voice_six_months">
                                                Last month
                                                <input type="checkbox"
                                                    [checked]="patient_details.change_voice_last_month">
                                                Today
                                                <input type="checkbox"
                                                    [checked]="patient_details.change_voice_today">
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.change_voice_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.change_voice_notes.unsure == false"><strong>Note:</strong> {{patient_details.change_voice_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.change_voice_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table class="last_tbl_class">
                        <tr>
                            <td class="class_td_container">
                                <table>
                                    <tr>
                                        <td class="sub_class_td_container_last"> <strong>Enlargement or tenderness of
                                                the breast tissue</strong> </td>
                                        <td class="sub_class_td_data"> <span>

                                                6 months <input type="checkbox"
                                                    [checked]="patient_details.tenderness_breast_tissue_six_months">
                                                Last month <input type="checkbox"
                                                    [checked]="patient_details.tenderness_breast_tissue_last_month">
                                                Today<input type="checkbox"
                                                    [checked]="patient_details.tenderness_breast_tissue_today">
                                            </span> </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr *ngIf="patient_details.tenderness_breast_tissue_notes">
                                        <td class="notesTD">
                                            <span class="notes" *ngIf="patient_details.tenderness_breast_tissue_notes.unsure == false"><strong>Note:</strong> {{patient_details.tenderness_breast_tissue_notes.note}}</span>
                                            <span class="notes" *ngIf="patient_details.tenderness_breast_tissue_notes.unsure == true"><strong>Note:</strong> Unsure how to describe the symptom</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="signature_main_block">
            <span><strong>Patient Signature :</strong> {{patient_details.patient_signature}}</span>
        </div>
    </ng-container>


    <ng-container *ngIf="physicianExaminationFlag">
        <div id="page2el" class="html2pdf__page-break"></div>
        <div class="order_sheet_body">


            <div class="order_sheet_wrapper">


                <div class="order_head">
                    <h2>PATIENT HISTORY QUESTIONNAIRE </h2>
                </div>

                <!-- Neurological -->
                <div class="examination_head">
                    <h2>Neurological</h2>
                </div>

                <div class="examination_contain">
                    <div class="examination_contain_block">
                        <p>Seizure Disorder (Epilepsy)</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.seizure_disorder">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.seizure_disorder">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.seizure_disorder">
                        <p><strong>*Notes: </strong> {{examination_details.seizure_disorder_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Dizziness/Fainting</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.dizziness_or_fainting">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.dizziness_or_fainting">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.dizziness_or_fainting">
                        <p><strong>*Notes: </strong> {{examination_details.dizziness_or_fainting_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Weakness/Paralysis</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.weakness">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.weakness">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.weakness">
                        <p><strong>*Notes: </strong> {{examination_details.weakness_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Swelling of lower extremities</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.swelling_of_lower_extremities">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.swelling_of_lower_extremities">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.swelling_of_lower_extremities">
                        <p><strong>*Notes: </strong> {{examination_details.swelling_of_lower_extremities_notes}}</p>
                    </div>

                </div>



                <!-- Infectious Disease -->
                <div class="examination_head">
                    <h2>Infectious Disease</h2>
                </div>

                <div class="examination_contain">
                    <div class="examination_contain_block">
                        <p>Tuberculosis</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.tuberculosis">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.tuberculosis">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.tuberculosis">
                        <p><strong>*Notes: </strong> {{examination_details.tuberculosis_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Hepatitis</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.hepatitis">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.hepatitis">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.hepatitis">
                        <p><strong>*Notes: </strong> {{examination_details.hepatitis_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Mumps</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.mumps">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.mumps">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.mumps">
                        <p><strong>*Notes: </strong> {{examination_details.mumps_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Measles</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.measles">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.measles">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.measles">
                        <p><strong>*Notes: </strong> {{examination_details.measles_notes}}</p>
                    </div>


                    <div class="examination_contain_block">
                        <p>Syphilis</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.syphilis">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.syphilis">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.syphilis">
                        <p><strong>*Notes: </strong> {{examination_details.syphilis_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Gonorrhea</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.gonorrhea">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.gonorrhea">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.gonorrhea">
                        <p><strong>*Notes: </strong> {{examination_details.gonorrhea_notes}}</p>
                    </div>


                    <div class="examination_contain_block">
                        <p>Venereal</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.venereal">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.venereal">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.venereal">
                        <p><strong>*Notes: </strong> {{examination_details.venereal_notes}}</p>
                    </div>
                </div>



                <!-- Drug Use/Abuse -->
                <div class="examination_head">
                    <h2>Drug Use/Abuse</h2>
                </div>

                <div class="examination_contain">
                    <div class="examination_contain_block">
                        <p>Depressants</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.depressants">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.depressants">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.depressants">
                        <p><strong>*Notes: </strong> {{examination_details.depressants_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Stimulants</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.stimulants">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.stimulants">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.stimulants">
                        <p><strong>*Notes: </strong> {{examination_details.stimulants_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Narcotics</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.narcotics">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.narcotics">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.narcotics">
                        <p><strong>*Notes: </strong> {{examination_details.narcotics_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Alcohol</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.alcohol">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.alcohol">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.alcohol">
                        <p><strong>*Notes: </strong> {{examination_details.alcohol_notes}}</p>
                    </div>
                </div>

                <div id="page2el" class="html2pdf__page-break"></div>
                <br />
                <!-- Congenital or Genetic -->
                <div class="examination_head">
                    <h2>Congenital or Genetic</h2>
                </div>

                <div class="examination_contain">
                    <div class="examination_contain_block">
                        <p>Malformations/Deformities</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.malformations">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.malformations">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.malformations">
                        <p><strong>*Notes: </strong> {{examination_details.malformations_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Blood Diseases</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.blood_diseases">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.blood_diseases">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.blood_diseases">
                        <p><strong>*Notes: </strong> {{examination_details.blood_diseases_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Multiple Births</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.multiple_births">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.multiple_births">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.multiple_births">
                        <p><strong>*Notes: </strong> {{examination_details.multiple_births_notes}}</p>
                    </div>

                </div>



                <!-- Chronic Diseases -->
                <div class="examination_head">
                    <h2>Chronic Diseases</h2>
                </div>

                <div class="examination_contain">
                    <div class="examination_contain_block">
                        <p>Neurological Diseases</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.neurological">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.neurological">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.neurological">
                        <p><strong>*Notes: </strong> {{examination_details.neurological_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Pulmonary (Lung) Diseases</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.pulmonary">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.pulmonary">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.pulmonary">
                        <p><strong>*Notes: </strong> {{examination_details.pulmonary_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Renal (Kidney) Diseases</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.renal_kidney">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.renal_kidney">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.renal_kidney">
                        <p><strong>*Notes: </strong> {{examination_details.renal_kidney_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Immune</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.immune">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.immune">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.immune">
                        <p><strong>*Notes: </strong> {{examination_details.immune_notes}}</p>
                    </div>


                    <div class="examination_contain_block">
                        <p>Hypertension</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.hypertension">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.hypertension">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.hypertension">
                        <p><strong>*Notes: </strong> {{examination_details.hypertension_notes}}</p>
                    </div>

                    <div class="examination_contain_block">
                        <p>Atherosclerosis</p>
                    </div>
                    <div class="examination_contain_block">
                        <p *ngIf="examination_details.atherosclerosis">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-circle-o" aria-hidden="true"></i> No
                        </p>
                        <p *ngIf="!examination_details.atherosclerosis">
                            <i class="fa fa-circle-o" aria-hidden="true"></i> Yes
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> No
                        </p>
                    </div>
                    <div class="examination_contain_block_notes" *ngIf="examination_details.atherosclerosis">
                        <p><strong>*Notes: </strong> {{examination_details.atherosclerosis_notes}}</p>
                    </div>

                </div>



                <!-- Additional Notes by Physician -->
                <div class="examination_head">
                    <h2>Additional Notes by Physician</h2>
                </div>
                <div class="examination_contain">
                    <div class="examination_contain_block_notes">
                        <p>{{examination_details.additional_notes}}</p>
                    </div>
                </div>


            </div>
        </div>
    </ng-container>


    <ng-container>
        <!-- *ngIf="patient_details && patient_details.status && patient_details.status > 2" -->
        <ng-container *ngIf="ordersheetFlag">
            <div id="page2el" class="html2pdf__page-break"></div>

            <div class="order_sheet_body">


                <div class="order_sheet_wrapper">

                    <ng-container *ngIf="order_sheet_data.length > 0">
                        <div class="order_head">
                            <h2>Order Sheet </h2>
                        </div>
                        <br />
                        <div class="orderAllCon" *ngFor="let item of order_sheet_data;let i=index;">
                            <!-- <ng-container *ngIf="i == 3 || i == 6 || i == 9 || i == 12">
                                <div id="page2el" class="html2pdf__page-break"></div>
                                <br />
                                <br />
                            </ng-container> -->
                            <div class="orderAllConh2 hidecon" id="diagnostic_cat_{{item.diagnostic_category_id}}">
                                {{item.diagnostic_categories_name}}
                            </div>
                            <ng-container>
                                <div class="orderAllConSubbody" id="diagnostic_{{item.diagnosis_id}}">
                                    <div class="orderAllConSubTop">
                                        <h2>
                                            <strong>
                                                {{item.diagnostic_name}}
                                                <!-- <a *ngIf="item.diago_reference_link" [href]="item.diago_reference_link"
                                            target="_blank"><i class="fa fa-info-circle"></i></a> -->
                                            </strong>
                                        </h2>


                                        <!-- <div class="orderAllConSubTop_right">
                                    <div class="percentagecss">
                                        <div class="percentageloder" [style.width.%]='item.matched_percentage'></div>
                                    </div>
                                    <label>{{item.matched_percentage}}%</label>
                                </div> -->


                                    </div>
                                    <div>
                                        <div class="orderAllConSub">
                                            <!-- <span>{{item.diagnostic_reason}}</span> -->
                                            <p *ngIf="item.notes_by_doctor">
                                                <strong><mat-icon>description</mat-icon></strong><span>{{item.notes_by_doctor}}</span>
                                            </p>
                                            <div class="orderAllConSub2">
                                                <h3>Assay Name:</h3>
                                                <div class="spantext_test" [innerHtml]="item?.assay_name"></div>
                                            </div>
                                            <div class="icdcodesblock">
                                                <h3>Potential Diagnosis:</h3>
                                                <div class="icdcodesblockdes">
                                                    <span>{{item.diagnostic_des}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="orderAllConSub_body2">
                                    <div class="orderAllConSub2">
                                        <h3>Assay Name:</h3>
                                        <div class="spantext" [innerHtml]="item?.assay_name"></div>
                                    </div>
                                    <div class="orderAllConSub2">
                                        <h3>Matched Symptoms:</h3>
                                        <div class="spantext" [innerHtml]="item?.matched_symptoms"></div>
                                    </div> 
                                </div> -->
                                    </div>
                                    <!-- <p class="diagnostic_des">{{item.diagnostic_des}}</p> -->
                                </div>
                            </ng-container>
                        </div>

                    </ng-container>


                </div>

                <!-- <div class="order_sheet_loderbody"> -->
                <!-- <div>
            <p style="color: red; text-align: center; ">
                <strong style="display: block;">Oops !</strong> NO Record Found
            </p>
        </div> -->

                <!-- <div class="my-progress-bar">
                <span>{{loadingPercent}}%</span>
                <mat-progress-bar mode="determinate" [value]="loadingPercent" (animationEnd)="progressInLoading()" >
                    
                </mat-progress-bar>
            </div> -->
                <!-- </div> -->
            </div>
            <div class="signature_main_block">

                <span><strong>Doctor Signature :</strong> {{patient_details.doctor_signature}}</span>
            </div>
        </ng-container>
    </ng-container>

    <!-- <ng-container *ngIf="pdfFlag"> 
             <div id="page2el" class="html2pdf__page-break"></div>
            <pdf-viewer [src]="pdfFiles" [rotation]="0" [original-size]="true" [show-all]="true" [fit-to-page]="true" [zoom]="0.60" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true" 
                [style]="hightcss"></pdf-viewer> 
     </ng-container>  -->

</div>