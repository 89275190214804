import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent {
  public email: any = '';
  public passwordHide = true;
  public confirmPasswordHide = true;
  public accesscode: string = null;
  public forgotForm: FormGroup;
  public updatePasswordForm: FormGroup;
  public updatePasswordPage: boolean = false;
  public forgotEmailErrorMessage: string = null;
  public progressLoader: boolean = false;

  constructor(public activateRoute: ActivatedRoute, public httpService: HttpServiceService, public matSnackBar: MatSnackBar, public router: Router) { }

  ngOnInit() {
    console.log("activateRoute ", this.activateRoute);
    if (this.activateRoute.snapshot.params['accesscode']) {
      this.updatePasswordPage = true;
      this.accesscode = this.activateRoute.snapshot.params['accesscode'];
      this.updatePasswordForm = new FormGroup({
        password: new FormControl('',Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$`)),
        confirm_password: new FormControl('',Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$`))
      });
    } else {
      this.forgotForm = new FormGroup({
        email: new FormControl('', Validators.pattern(`^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$`)),
      });
    }
  }

  forgot() {
    console.log(this.forgotForm)
    this.forgotForm.markAllAsTouched();
    if (this.forgotForm.valid) {
      this.progressLoader = true;
      let dataobj: any = {
        email: this.forgotForm.value.email
      };
      this.httpService.httpViaPost('auth/forget-password', dataobj).subscribe((response) => {
        console.log("forgot response", response)
        if (response) {
          if (response.status == 'error') {
            this.forgotEmailErrorMessage = response.message;
          } else {
            this.matSnackBar.open(response.message, '', {
              duration: 3000,
            });
            this.router.navigateByUrl('login')
          }
          this.progressLoader = false;
          // this.forgotForm.reset();
          // this.forgotForm.patchValue({
          //   email: null
          // })
        }
      })
    }
  }
  changePassword() {
    this.updatePasswordForm.markAllAsTouched();
    console.log("changePassword",this.updatePasswordForm)
    if (this.updatePasswordForm.valid && (this.updatePasswordForm.value.password == this.updatePasswordForm.value.confirm_password)) {
      this.progressLoader = true;
      let dataobj: any = {
        accesscode: this.accesscode,
        new_password: this.updatePasswordForm.value.password,
        confirm_password: this.updatePasswordForm.value.confirm_password
      };
      this.httpService.httpViaPost('auth/reset-password', dataobj).subscribe((response) => {
        console.log("forgot response", response)
        if (response) {
          if (response.status == 'error') {
            this.forgotEmailErrorMessage = response.message;
          } else {
            this.matSnackBar.open(response.message, '', {
              duration: 3000,
            });
            this.router.navigateByUrl('login');
          }
          this.progressLoader = false;
          // this.forgotForm.reset();
          // this.updatePasswordForm.patchValue({
          //   accesscode: null,
          //   password: null,
          //   confirm_password: null
          // })
        }
      })
    }
  }

  keyDownChangePassword(event) {
    if (event.keyCode === 13) {
      this.changePassword();
    }
  }
  keyDownForgot(event) {
    if (event.keyCode === 13) {
      this.forgot();
    }
  }

}
