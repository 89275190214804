import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
export interface DialogData {
  data: any;
  flag: any;
  patient_id: any;
  taxonomy: any;
}



@Component({
  selector: 'app-professional-opinion',
  templateUrl: './professional-opinion.component.html',
  styleUrls: ['./professional-opinion.component.css']
})
export class ProfessionalOpinionComponent {

  public professionalopinionDataset: any;
  public patientID: any;

  @Input() set professionalopinion(value: any) {
    console.log(value, 'professionalopinion');

    if (value) {

      console.log(value._id, 'activeRoute>>>')

      this.patientID = value._id

      this.professionalopinionDataset = value.all_icd_codes;

      console.log(this.professionalopinionDataset, 'professionalopinion>>>')

    }

  }


  constructor(
    public dialog: MatDialog, private httpService: HttpServiceService, public activeRoute: ActivatedRoute) {





  }

  OpenPinion(data: any,  taxonomy: any) {

    const dialogRef = this.dialog.open(PinionModal, {

      panelClass: ['PinionModal'],
      // data: data

      data: { data: data, patient_id: this.patientID, taxonomy }

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}



@Component({
  selector: 'PinionModal',
  templateUrl: 'PinionModal.html',

})
export class PinionModal {

  public professionalopinionHTML: any;

  public progressBarLoader =true

  constructor(
    public dialogRef: MatDialogRef<PinionModal>, private httpService: HttpServiceService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {


    console.log('data>>>>>>>>>>>>>>>>>', data)


 
    let payload: any = {
 
      taxonomy: data.taxonomy,
      icd_description: data.data,
      // patient_id: data.patient_id
    };


    this.httpService.httpViaPostRapidResponse('api7/professionalopinions', payload).subscribe((response: any) => {


     

      if (response) {
        if (response.results) {

          console.log('response>>>>>>>>>>>>>>>>>', response.results)

          this.professionalopinionHTML=response.results

          this.progressBarLoader =false
        }

      }


    })

 

  }



  onNoClick(): void {
    this.dialogRef.close();
  }
}

