import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-partial-done-leads-list',
  templateUrl: './partial-done-leads-list.component.html',
  styleUrls: ['./partial-done-leads-list.component.css']
})
export class PartialDoneLeadsListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'status', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "users";
  editroute = "";
  updateendpoint = "";
  deleteendpoint = "";
  datacollection: any = "intake/leadlist";
  public statusCode: any;
  public listTitleName: any;
  public UsersSummaryLoader = false


  searchendpoint = "";
  date_search_endpoint: any = "intake/leadlist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    patient_name: "Patient Name",
    patient_email: "Patient Email",
    createdon_datetime: "Created On",


  };
  search_settings: any = {


    search: [{
      label: "Search By Patient Name",
      field: '_id',
      serversearchdata: { endpoint: 'intake/patientnameautocomplete' },
    },
    {
      label: "Search By Patient Email",
      field: 'patient_email',
      serversearchdata: { endpoint: 'intake/patientemailautocomplete' },
    },
    ],


    textsearch: [
      // { label: "Search By Priority", field: 'priority' },
    ],
    datesearch: [
      {
        startdatelabel: 'Created On Start Date',
        enddatelabel: 'Created On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      // { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["createdon_datetime", "patient_name", "patient_email"],
  };
  public cookieData: any = {};
  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
    ],
  }
  libdata: any = {
    basecondition: { status: {$in:[0,1]},is_partial_done: true},
    detailview_override: [
    ],
    updateendpoint: "",
    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: true,
    hideaction: false,
    updateendpointmany: "",
    deleteendpointmany: "",
    tableheaders: ['patient_name', "patient_email", "createdon_datetime"],


    custombuttons: [
      {
        label: "Send Email",
        type: "listner",
        id: "resend_email",
        classname: 'resend ',
        tooltip: 'Send Email',

      },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;

  constructor(public dialog: MatDialog, public matSnackBar: MatSnackBar, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {

    this.activatedRoute.data.subscribe((response: any) => {
      if (response.data.results.res.length > 0) {
        this.tabledatatalist = response.data.results;
      }
    });

  }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.libdata.hideaction=true;
          break;
      }
    }
    this.userListFetching();
  }

  userListFetching() {
    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        status: {$in:[0,1]},
        is_partial_done: true
      }
    }

    switch (this.cookieData.user_type) {
      case 'doctor':
        data.searchcondition.doctor_id =  this.cookieData._id;
        this.libdata.basecondition = {status: {$in:[0,1]},is_partial_done: true,doctor_id:this.cookieData._id}
        break;
      case 'practice':
        data.searchcondition.practice_id = this.cookieData._id;
        this.libdata.basecondition = {status: {$in:[0,1]},is_partial_done: true,practice_id:this.cookieData._id}
        break;
    }
    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })

    this.httpService.httpViaPost('intake/leadlist-count', data).subscribe((response: any) => {
      console.log("response list", response)
      if (response) {
        this.date_search_source_count = response.count;
      }
    })
  }

  listenLiblistingChange(data: any) {
    
    if(data.action == "custombuttonclick"){
      if(data.custombuttonclick.btninfo.id == "resend_email"){
        console.log(data.custombuttonclick.data);
        const dialogRef = this.dialog.open(ConformModal, {
          data: {patient:data.custombuttonclick.data, successFlag: 'alert'},
          panelClass: 'signPageDialog'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'yes') {
            this.progressLoader = true;
            let emaildata = data.custombuttonclick.data
            this.httpService.httpViaPost('intake/sendemailforpartialleads', emaildata).subscribe((response: any) => {
              console.log("response list", response)
              if (response) {
                this.progressLoader = false;
                const dialogRef = this.dialog.open(ConformModal, {
                  data: {patient:data.custombuttonclick.data, successFlag: 'yes'},
                  panelClass: 'signPageDialog'
                });
              }
            })
          }
        })
      }
    }
  }



}


@Component({
  selector: 'conformationModal',
  templateUrl: 'conformationModal.html',
})

export class ConformModal {
  public cookieData: any = {};
  public approveProgressBar = false;
  // public error = false;
  public modalValue: any;

  constructor(
    public dialogRef: MatDialogRef<ConformModal>,
    public apiservice: HttpServiceService,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService
  ) {
    this.modalValue = data;
    console.log("modal Data test", data);

  }



  ngOnInit() {}

  sendemail(val) {
    this.dialogRef.close(val);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  modalClose() {
    this.dialogRef.close();
  }

}














