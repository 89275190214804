import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-diagnostic-category-list',
  templateUrl: './diagnostic-category-list.component.html',
  styleUrls: ['./diagnostic-category-list.component.css']
})
export class DiagnosticCategoryListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "diagnostic_category_priority"];
  public listprogressBar: any = false;
  public tabledatatalist: []
  tablename = "diagnostic_category";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deletediagnosticcategory";
  datacollection: any = "intake/diagnosticcategorylist";

  searchendpoint = "intake/diagnosticcategorylist";
  date_search_endpoint: any = "intake/diagnosticcategorylist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  modify_header_array: any = {
    diagnostic_category_name: "Category Name",
    diagnostic_category_des: "Category Description",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [

      {
        label: "Search By Name ", field: 'diagnostic_category_name',

        serversearchdata: { endpoint: 'intake/diagnosticcatautocomplete' },

      },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [

      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["diagnostic_category_name", "updatedon_datetime", "status"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Category',
      //   type: 'button',
      //   name: 'add_category',
      //   tooltip: 'Add Category',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "diagnostic_category_name", val: "Category Name" },
      { key: "diagnostic_category_des", val: "Category Description" },
      { key: "status", val: "Status" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "diagnostic_category_priority", val: "Priority" },



    ],
    updateendpoint: "intake/diagnosticcategorystatus",
    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/diagnosticcategorystatus",

    deleteendpointmany: "intake/deletediagnosticcategory",
    tableheaders: ["diagnostic_category_name", "diagnostic_category_des",  "updatedon_datetime", "status"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewdiagnosticcat',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['diagnostic_category_name', 'diagnostic_category_des', 'diagnostic_category_priority', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;

  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }

  ngOnInit() {
    this.progressLoader = true;
    this.userListFetching();
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute responseff", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
        if (this.tabledatatalist.length == 0) {

          // console.log("no record")

          setTimeout(() => {
            this.adduserBTN = true

            let myRow = document.querySelector('.adminBody');
            myRow.classList.add('libListNoDataCSS');
          }, 200);


        }
      }
    })

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {

        case 'laboratory':
          this.libdata.basecondition = { addedby: this.cookieData._id }
          break;

      }
    }




  }

  addBtn() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/addEdit-diagnostic-category`);
  }

  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }

    if (this.cookieData.user_type == "laboratory") {
      data.searchcondition = {

        addedby: this.cookieData._id,
      }

    }


    this.httpService.httpViaPost('intake/diagnosticcategorylist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }


  listenLiblistingChange(data: any = null) {
    console.log("listenLiblistingChange", data.custombuttonclick.data._id);
    // practice/intake-form-preview/view
    // console.log("Op=====>1", data);
    if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/addEdit-diagnostic-category/${data.custombuttonclick.data._id}`)



          break;
        case "Delete":
          // this.deleteListItem(data.custombuttonclick.data)
          break;
        default: null
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
  }

  // addNewCategory() {

  //   // this.router.navigateByUrl('admin/miscellaneous/addEdit-diagnostic-category')
  // }

  onLiblistingButtonChange(val: any) {
    console.log("onLiblistingButtonChange val", val)
    if (val.action == "customlistenbutton") {
      if (val.buttondata.name == 'add_category') {
        this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/addEdit-diagnostic-category`)


      }
    }

  }
  listenFormFieldChange(val: any) {


  }






}
