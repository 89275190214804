<div class="lesson_prgrscont">
    <div class="prgrscont">
        <div class="prgrscont1">
            <label>You have completed <span id="done">{{totalLessonComplete}}</span>/<span id="notdone">{{totalLessonCount}}</span> lesson</label>
            <div class="loderBodyCon">
                <div class="loderBody">
                    <div class="lodercon" style="width:0%"></div>
                </div>
                <span><span id="percentage">{{completedPercentageValue?completedPercentageValue:'0.00'}} %</span> </span>
            </div>

        </div>
        <div class="prgrscont2">

            <div class="lessonactivity completed">
                <span></span>
                <label>Completed Lesson</label>


            </div>
            <div class="lessonactivity ongoing">
                <span></span>
                <label>Ongoing Lesson</label>

            </div>
            <div class="lessonactivity incomplete">
                <span></span>
                <label>Incomplete Lesson</label>
            </div>


        </div>
    </div>

</div>