<app-header></app-header>
<mat-card class="adminBody hidepreviewbtn">
    <mat-card-content class="Emailtemplatelist listingpage_wrapper list_style1">
        <h2 class="listingpageh2"> Training Center</h2>
        <ng-container *ngIf="alldonelessonData">
            <app-training-center-progress [totalLesson]="totalLessonCount" [totalLessonDone]="alldonelessonData?.completelesson"></app-training-center-progress>
        </ng-container>
        <div class="astroLessons_Outerwrap">
            <div class="astroLessons_wrap">
                <div class="lessonList">
                    <mat-progress-bar mode="indeterminate" *ngIf="lessonLoading "></mat-progress-bar>
                    <ng-container *ngIf="!lessonLoading && lessonList.length > 0">
                        <h3 class="currentSelectedCategory">{{currentSelectedCategory.training_category_name}}</h3>
                        <app-training-center-lesson-list [lessonListFetched]="lessonList"></app-training-center-lesson-list>
                    </ng-container>
                </div>
                <div class="categoryList">
                    <mat-progress-bar mode="indeterminate" *ngIf="categoryLoading"></mat-progress-bar>
                    <ng-container *ngIf="!categoryLoading && categoryList.length > 0">
                        <app-training-center-category-list [categoryListFetched]="categoryList" (trainingChanging)="trainingChangedFetched($event)"></app-training-center-category-list>
                    </ng-container>
                </div>
                <div class="trainingNotFoundDiv" *ngIf="!categoryLoading && categoryList.length == 0">There is no training for {{cookieData.name}}.</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>