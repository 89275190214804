import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-training-lesson-list',
  templateUrl: './training-lesson-list.component.html',
  styleUrls: ['./training-lesson-list.component.css']
})
export class TrainingLessonListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'status', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "training";
  editroute = "";
  updateendpoint = "training/lessonstatus";
  deleteendpoint = "training/deletelesson";
  datacollection: any = "training/lessonlist";

  searchendpoint = "";
  date_search_endpoint: any = "training/lessonlist";
  date_search_source: any = "training";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    lesson_name: "Lesson Name",
    description: "Description",
    category_name: "Category Name",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status_text: "Status",
    prerequisite_name: "Prerequisite Name",
    accuracy: "Accuracy"
  };
  search_settings: any = {


    search: [{
      label: "Search By Lesson Name", field: '_id',
      serversearchdata: { endpoint: 'training/autocompletelesson' },
    },
    ],

    textsearch: [
      { label: "Search By Priority", field: 'priority' },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["lesson_name", "createdon_datetime", "updatedon_datetime", "status_text",],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Taxonomies',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "lesson_name", val: "Lesson Name" },
      { key: "description", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "category_name", val: "Category Name" },
      { key: "status_text", val: "Status" },
      { key: "prerequisite_name", val: "Prerequisite Name" },
      { key: "accuracy", val: "Accuracy" }

    ],
    updateendpoint: "training/lessonstatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "training/lessonstatus",

    deleteendpointmany: "training/deletelesson",
    tableheaders: ["lesson_name", "description", "createdon_datetime", "category_name", "prerequisite_name", 'accuracy', "status_text", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'training/previewtraininglesson',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['lesson_name', 'description',  'createdon_datetime', 'prerequisite_name', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;

  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    if (!window.location.pathname.includes('training')) {
      this.router.navigateByUrl('404')
    }
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }
  ngOnInit() {
    this.lessonListCountFetching();
  }
  lessonListCountFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }

    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })

    this.httpService.httpViaPost('training/lessonlist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }
  addBtn() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-lesson-add`);
  }

  onLiblistingButtonChange(data: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", data);
    this.router.navigateByUrl(`${this.cookieData.user_type}/ttraining/training-lesson-add`);

  }
  listenLiblistingChange(data: any = null) {
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults && data.totalresults.status == "success") {
        this.taxonomy_updatetable = !this.taxonomy_updatetable;
      }
    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-lesson-edit/${item._id}`);
    }
  }
}
