import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogData } from 'listing-angular15';

@Component({
  selector: 'app-folder-view',
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.css'],
})
export class FolderViewComponent {
  public folderviewdatalist: any = '';
  public folderHeader: any = '';
  public cookieData: any;
  public loaderFlag: boolean = false;
  public personalInfoFlag: boolean = false;
  public editInfoFlag: boolean = false;
  public editFlag: boolean = false;

  public changePassFlag: boolean = false;
  public loginActivityFlag: boolean = false;
  public notesManageFlag: boolean = false;

  public notesManageDATAFlag: boolean = false;

  public loginactivityDATAFlag: boolean = false;

  public locationFlag: boolean = false;
  public resolveval: any;
  public taxonomyList: any = [];
  public usertype: any = '';
  public formdata: any = {};
  public stateList: any = [];
  public parent_locationList: any = [];
  public addFormLoader: boolean = false;
  public addFormLoadernote: boolean = false;
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public personList: any = [];
  public locationList: any = [];
  public practiceList: any = [];
  public selectedParentLocations: string = null;
  public salesdetailsPAC: any = [];
  public labdetailsPAC: any = [];
  public selectedTaxonomies: string = null;
  public logoimgPAC: any = [];
  public isedit: boolean = false;
  public labdetailPAC: any = [];
  public practicePAC: any = [];

  public logindata: boolean = true;

  public editinfoDatablock: any = [];
  // public changepasswordLoader: boolean = false;

  public logoimgcss: boolean = true;

  public formdatachngpass: any;
  formfieldrefreshdatapass: any = null;
  formfieldrefreshpass: any = true;
  public userdatapass: any;

  public noteConlist: any;

  public loginactivityCon: any = [];

  public loginactivity_count: any = 0;

  public location_count: any = 0;

  public logindetatop: any = [];

  public skipcount: any = 0;

  public skipcountNotes: any = 0;
  public loginactivityNotes: number = 0;
  public limitcountNotes: any = 5;
  public loadButtonNotes: string = 'Load More';

  public realtimeNotesUpdate: any = null;


  public loadButtonlocation: string = 'Load More';

  public limitcount: any = 9;
  public limitcountloc: any = 5;

  public loadButtonText: string = 'Load More';

  public Loginnodatafound: boolean = true;

  public ispracticenameValueUpdate: boolean = false;

  public noteCondatafound: boolean = true;
  public isLABpracticenameValueUpdate: boolean = false;
  public noteCon: any = [];
  public locationArray: any = [];
  public addNoteForm: FormGroup;

  public managelocationDATAFlag: boolean = false;

  public isSalesPersonValueUpdate: boolean = false;

  public isLocationValueUpdate: boolean = false;

  public managelocationData: any;
  public managelocationNoData: boolean = false;

  // public n: boolean = false;

  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  public cookieValue: any;
  constructor(
    private httpService: HttpServiceService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public cookieService: CookieService,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    if (this.cookieService.getAll()['login_user_details']) {
      console.log('this.cookieData', this.cookieData);
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
      // this.firstBlockData = this.cookieData.last_login_time;
      console.log('thiscookieData', this.cookieData._id);
    }
    this.changeBtn('info');

    // console.log(this.cookieData.user_type,'cookieDatatype')
  }

  ngOnInit() {
    this.addNoteForm = new FormGroup({
      note: new FormControl('', Validators.required),
    });

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }

    this.activatedRoute.data.subscribe((response: any) => {
      // console.log("response folderviewdatalist", response)
      this.loaderFlag = true;
      if (response) {
        if (response.data && response.data.status == 'success') {
          this.folderviewdatalist = response.data.results.res[0];
          console.log("folderviewdatalist---------", this.folderviewdatalist);
          this.realtimeNotesUpdate = this.folderviewdatalist.notescount
          this.setPersonalInfo();
          let dataLogin: any = {
            condition: {
              limit: 1,
              skip: 0,
            },
            sort: {
              type: 'desc',
              field: '_id',
            },
            searchcondition: { _id: this.folderviewdatalist._id },
          };

          this.httpService
            .httpViaPost('users/loginactivity', dataLogin)
            .subscribe((response) => {
              if (response) {
                if (response.status == 'success') {
                  this.logindata = false;
                  this.logindetatop = response.res;
                  this.loaderFlag = false;
                  console.log('logindetatop', this.logindetatop);
                } else {
                  this.loaderFlag = false;
                }
              }
            });
          this.usertype = this.folderviewdatalist.user_type;
          console.log('folderviewdatalist', this.folderviewdatalist);
        }
      }

      // console.log(this.folderviewdatalist.user_type, 'cookieDatatype')
    });
  }

  setPersonalInfo(type?: string) {
    if (this.folderviewdatalist.parent_location.length > 0) {
      let tempParentLocation: any = [];
      this.folderviewdatalist.parent_location.forEach((e: any, i: number) => {
        tempParentLocation.push(e.location_name);
      });
      this.selectedParentLocations = tempParentLocation.join(', ');
    }
    if (this.folderviewdatalist.salesdetails.length > 0) {
      this.folderviewdatalist.salesdetails.forEach((e: any, i: number) => {
        this.salesdetailsPAC.push(e.firstname + ' ' + e.lastname);
      });
    }
    if (this.folderviewdatalist.labdetails.length > 0) {
      this.folderviewdatalist.labdetails.forEach((e: any, i: number) => {
        this.labdetailsPAC.push(e.laboratory_name);
      });
    }
    if (this.folderviewdatalist.taxonomy.length > 0) {
      let tempTaxonomies: any = [];
      this.folderviewdatalist.taxonomy.forEach((e: any, i: number) => {
        tempTaxonomies.push(e.taxonomy);
      });
      this.selectedTaxonomies = tempTaxonomies.join(', ');
    }
    if (this.folderviewdatalist.labdetails.length > 0) {
      this.folderviewdatalist.labdetails.forEach((e: any, i: number) => {
        this.labdetailPAC.push(e.laboratory_name);
      });
    }
    if (this.folderviewdatalist.practice_details.length > 0) {
      this.folderviewdatalist.practice_details.forEach((e: any, i: number) => {
        this.practicePAC.push(e.practice_name);
      });
    }
    this.logoimgPAC = this.folderviewdatalist.image;
    if (this.logoimgPAC && this.logoimgPAC.length > 0) {
      this.logoimgcss = false;
    }
    if (type) {
      this.addFormLoader = false;
      this.personalInfoFlag = true;
      this.editFlag = false;
      this.editInfoFlag = false;
    }
  }

  getStates() {
    this.httpService
      .getHttpData('../../../../../assets/data-set/state.json')
      .subscribe((response) => {
        console.log(response, 'this.stateList');
        // this.stateList = response;
        if (response && response.length > 0) {
          response.forEach((e, i) => {
            let obj = { val: e.abbreviation, name: e.name };
            this.stateList.push(obj);
          });
        }
        console.log('resolveval', this.stateList);
      });
  }

  taxonomyFetching() {
    let data: any = {
      sort: { type: 'desc', field: '_id' },
      searchcondition: {},
    };
    this.httpService
      .httpViaPost('users/taxonomyautocomplete', data)
      .subscribe((response) => {
        console.log('taxonomy', response);
        if (response) {
          if (response.status == 'success') {
            this.taxonomyList = response.res;
            if (this.taxonomyList.length > 0) {
              this.taxonomyList.forEach((e: any, i: number) => {
                e.val = e.key;
              });
            }
            this.formdata.fields = this.formdata.fields.filter((e) =>
              e.name == 'taxonomy' ? (e.val = this.taxonomyList) : []
            );
          }
        }
      });
  }

  // salesPersonFetching() {

  //   console.log('folderviewdatalist00', this.folderviewdatalist.parent_laboratory)
  //   let data: any = {
  //     // sort: { type: 'desc', field: '_id' },
  //     searchcondition: { _id: this.folderviewdatalist.parent_laboratory }
  //     //  _id:this.cookieData._id}
  //   }
  //   this.httpService.httpViaPost('users/salespersonAutocomplete', data).subscribe((response) => {
  //     console.log("sales_person", response)
  //     if (response) {
  //       if (response.status == "success") {
  //         this.salespersonList = response.res;
  //         console.log("sales_persondata", this.salespersonList)

  //         if (this.salespersonList.length > 0) {
  //           this.salespersonList.forEach((e: any, i: number) => {
  //             e.val = e._id

  //             console.log("000", e)
  //           })

  //         }
  //         this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'sales_person' ? e.val = this.salespersonList : []);

  //         console.log("formdatatest", this.formdata.fields)

  //       }
  //     }
  //   })
  // }

  salesPersonFetching(data: any) {
    this.httpService
      .httpViaPost('users/salespersonAutocomplete', data)
      .subscribe((response) => {
        // console.log("my change response++++", response.res)
        if (response) {
          this.personList = response.res;
          if (this.personList.length > 0) {
            this.personList.forEach((e: any, i: number) => {
              e.val = e._id;
            });
          }
          if (!this.isSalesPersonValueUpdate) {
            this.formdata.fields = this.formdata.fields.filter((e) =>
              e.name == 'sales_person' ? (e.val = this.personList) : []
            );
            this.isSalesPersonValueUpdate = true;
          }
        }
      });
  }

  // locationListlab() {

  //   console.log('folderviewdatalistloc', this.folderviewdatalist)
  //   let data: any = {
  //     _id: this.folderviewdatalist.parent_laboratory
  //   }
  //   this.httpService.httpViaPost('users/fetchlabLoation', data).subscribe((response) => {
  //     console.log("locationList000", response)
  //     if (response) {
  //       if (response.status == "success") {
  //         this.locationList = response.res;
  //         if (this.locationList.length > 0) {
  //           this.locationList.forEach((e: any, i: number) => {
  //             e.val = e._id;
  //           })
  //         }
  //         console.log("this.formdata.fields", this.formdata.fields)
  //         this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationList : []);
  //       }
  //     }
  //   })
  // }

  // locationListlab() {

  //   console.log('folderviewdatalistloc', this.folderviewdatalist)
  //   let data: any = {
  //     _id: this.folderviewdatalist.parent_laboratory
  //   }
  //   this.httpService.httpViaPost('users/fetchlabLoation', data).subscribe((response) => {
  //     console.log("locationList000", response)
  //     if (response) {
  //       if (response.status == "success") {
  //         this.locationList = response.res;
  //         if (this.locationList.length > 0) {
  //           this.locationList.forEach((e: any, i: number) => {
  //             e.val = e._id;
  //           })
  //         }
  //         console.log("this.formdata.fields", this.formdata.fields)
  //         this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.locationList : []);
  //       }
  //     }
  //   })
  // }

  locationListlab(data: any) {
    this.httpService
      .httpViaPost('users/fetchlabLoation', data)
      .subscribe((response) => {
        console.log('my change response++++', response.res);
        this.locationList = response.res;
        if (this.locationList.length > 0) {
          this.locationList.forEach((e: any, i: number) => {
            e.val = e._id;
          });
        }
        if (!this.isLocationValueUpdate) {
          this.formdata.fields = this.formdata.fields.filter((e) =>
            e.name == 'parent_location' ? (e.val = this.locationList) : []
          );
          this.isLocationValueUpdate = true;
        }
      });
  }

  adminMyAccount() {
    this.formdata = {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute:
        this.cookieData.user_type +
        '/user-management/folder-view/' +
        this.folderviewdatalist._id,
      hidereset: true,

      fields: [
        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },
          ],
        },

        {
          heading: '',
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [{ rule: 'required', message: 'Last Name is Required' }],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'Phone No',
          name: 'phone',
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },

        {
          label: 'Address',
          name: 'address',
          value: this.resolveval.address,
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Address is Required' }],
        },

        {
          label: 'State',
          name: 'state',
          val: this.stateList,
          value: this.resolveval.state,
          type: 'select',
          validations: [{ message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: this.resolveval.city,
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: this.resolveval.zip,
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },

        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'checkbox',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },

        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ],
    };
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('adminForm');
    }, 200);
  }

  practiceyMyAccount() {
    console.log('resolvevalpac', this.resolveval, this.cookieData);
    if (this.resolveval) {
      this.formdata = {
        // from start
        successmessage: 'Updated Successfully', // success message
        submittext: 'Update',
        apiUrl: this.httpService.baseUrl,
        // endpoint: 'users/addUpdateData',
        // redirectpath: "/dashboard",
        resettext: 'Reset',
        canceltext: 'Cancel',
        // jwttoken: this.cookieService.get('jwtToken'),
        // cancelroute: '/dashboard', // use for redirect after cancel the form
        cancelroute:
          this.cookieData.user_type +
          '/user-management/folder-view/' +
          this.folderviewdatalist._id,

        hidereset: true,

        fields: [
          {
            heading: '<h1>Parent Information</h1>',
            name: 'married',
          },

          {
            label: 'Parent Laboratory',
            name: 'parent_laboratory',
            // hint: 'In months',
            type: 'autocomplete',
            // multiple: false,
            endpoint: 'users/laboratoryAutocomplete',
            search_field: 'name_search',

            val:
              this.resolveval && Object.keys(this.resolveval).length > 0
                ? [
                  {
                    key: this.resolveval.parent_laboratory.key,
                    val: this.resolveval.parent_laboratory.val,
                  },
                ]
                : [],
            value:
              this.resolveval && Object.keys(this.resolveval).length > 0
                ? this.resolveval.parent_laboratory._id
                : [],
            validations: [
              { rule: 'required', message: 'Parent Laboratory is Required' },
            ],
          },
          {
            label: 'Salesperson',
            name: 'sales_person',
            // hint: ',0000',
            type: 'select',

            val: this.personList,
            value:
              this.resolveval && Object.keys(this.resolveval).length > 0
                ? this.resolveval.sales_person
                : '',
            multiple: false,
          },

          // {
          //   label: "Location",
          //   name: "parent_location",
          //   // hint: ',0000',
          //   type: 'select',

          //   val: this.locationList,
          //   value: this.resolveval && Object.keys(this.resolveval).length > 0 ? this.resolveval.parent_location : '',
          //   multiple: true,
          // },

          {
            label: 'Location',
            name: 'parent_location',
            // hint: ',0000',
            type: 'select',

            val: this.locationList,
            value:
              this.resolveval && Object.keys(this.resolveval).length > 0
                ? this.resolveval.parent_location
                : '',
            multiple: true,
          },

          {
            heading: '<h1>Contact Information</h1>',
            name: 'married',
          },

          {
            label: 'First Name',
            name: 'contact_person_firstname',
            value:
              this.resolveval && this.resolveval.contact_person_firstname
                ? this.resolveval.contact_person_firstname
                : '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'First Name is Required' },
            ],
          },
          {
            label: 'Last Name',
            name: 'contact_person_lastname',
            value:
              this.resolveval && this.resolveval.contact_person_lastname
                ? this.resolveval.contact_person_lastname
                : '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'Last Name is Required' },
            ],
          },
          {
            label: 'Phone Number',
            name: 'contact_phone',
            value:
              this.resolveval && this.resolveval.contact_phone
                ? this.resolveval.contact_phone
                : '',
            formatflag: true,
            type: 'numberformat',
            // type: 'number',
            validations: [
              { rule: 'required', message: 'Phone Number is Required' },
            ],
          },
          {
            label: 'Contact Email',
            name: 'contact_email',
            type: 'email',
            hint: '',
            value:
              this.resolveval && this.resolveval.contact_email
                ? this.resolveval.contact_email
                : '',
            validations: [
              { rule: 'required', message: 'Contact Email is required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
            ],
          },

          {
            heading: '<h1>Practice Information</h1>',
            name: 'married',
          },

          {
            label: 'Practice Name',
            name: 'practice_name',
            value:
              this.resolveval && this.resolveval.practice_name
                ? this.resolveval.practice_name
                : '',
            type: 'text',
            classname: 'aaa',
            validations: [
              { rule: 'required', message: 'Practice Name is Required' },
            ],
          },
          {
            label: 'Email',
            name: 'email',
            type: 'email',
            disabled: this.resolveval ? true : false,
            hint: '',
            value:
              this.resolveval && this.resolveval.email
                ? this.resolveval.email
                : '',
            validations: [
              { rule: 'required', message: 'Login email is required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
            ],
          },

          {
            label: 'Phone',
            name: 'phone',
            value:
              this.resolveval && this.resolveval.phone
                ? this.resolveval.phone
                : '',
            formatflag: true,
            type: 'numberformat',
            // type: 'number',
            validations: [{ rule: 'required', message: 'Phone is Required' }],
          },
          {
            label: 'Fax',
            name: 'fax',
            value:
              this.resolveval && this.resolveval.fax ? this.resolveval.fax : '',
            formatflag: true,
            type: 'numberformat',
            // type: 'number',
            validations: [{ rule: 'required', message: 'Fax is Required' }],
          },
          {
            label: 'Taxonomies',
            name: 'taxonomy',
            type: 'select',
            value:
              this.resolveval && this.resolveval.taxonomy
                ? this.resolveval.taxonomy
                : '',
            val: this.taxonomyList,
            // value:[2021,2022],
            multiple: true,
          },
          {
            label: 'NPI',
            name: 'npi',
            value:
              this.resolveval && this.resolveval.npi ? this.resolveval.npi : '',
            type: 'number',
            validations: [{ rule: 'required', message: 'NPI is Required' }],
          },

          {
            label: 'Name of EMR software being used',
            name: 'emr',
            value:
              this.resolveval && this.resolveval.emr ? this.resolveval.emr : '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'EMR software is Required' },
            ],
          },
          {
            label: ' EIN Number',
            name: 'ein',
            value:
              this.resolveval && this.resolveval.ein ? this.resolveval.ein : '',
            type: 'number',
            validations: [
              { rule: 'required', message: 'EIN Number is Required' },
            ],
          },

          {
            label: 'Active',
            name: 'status',
            type: 'checkbox',
            value:
              this.resolveval && this.resolveval.status
                ? this.resolveval.status
                : 0,
            disabled: this.cookieData && this.cookieData.user_type && this.cookieData.user_type == "sales_person" ? true : false,
            validations: [
              // { rule: 'required', message: 'Required Type' },
            ],
          },

          {
            label: this.resolveval ? 'Edit Logo' : 'Add Logo',
            name: 'logo',
            type: 'file',
            validations: [{ rule: 'required', message: 'Logo is Required' }],
            // multiple: false,
            prefix: Date.now(),
            // path: 'practice/',
            // baseurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
            // bucket: 'pece-training-files',
            // apiurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
            // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
            path: 'practice2lab/',
            bucket: 'all-frontend-assets',
            baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
            apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
            apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
            value:
              this.resolveval &&
                this.resolveval.logo &&
                this.resolveval.logo.name
                ? {
                  url: this.resolveval.logo.baseurl,
                  type: 'image',
                  name: this.resolveval.logo.name,
                }
                : undefined,
          },

          {
            label: 'User Type',
            name: 'user_type',
            value: 'practice',
            type: 'hidden',
          },

          {
            label: 'id',
            name: '_id',
            value: this.resolveval._id,
            type: 'hidden',
          },

          // {
          //   label: 'Priority',
          //   name: 'priority',
          //   value: 1,
          //   type: 'hidden',
          //   // validations: [

          //   // ]
          // },
        ],
      };
      console.log(' this.resolveval', this.formdata);
    }
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('practiceForm');
    }, 200);
  }

  laboratoryMyAccount() {
    this.formdata = {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute:
        this.cookieData.user_type +
        '/user-management/folder-view/' +
        this.folderviewdatalist._id,
      hidereset: true,
      fields: [
        {
          label: 'Laboratory Name',
          name: 'laboratory_name',
          value: this.resolveval.laboratory_name,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Laboratory Name is Required' },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'NPI',
          name: 'npi',
          value: this.resolveval.npi,
          type: 'number',
          validations: [{ rule: 'required', message: 'NPI is Required' }],
        },
        {
          label: 'Phone',
          name: 'phone',
          value: this.resolveval.phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Phone is Required' }],
        },
        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: 'Contact Person',
          name: 'contact_person',
          value: this.resolveval.contact_person,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Contact Person is Required' },
          ],
        },
        {
          label: 'Contact Person Email',
          name: 'contact_person_email',
          type: 'email',
          hint: '',
          value: this.resolveval.contact_person_email,
          validations: [
            { rule: 'required', message: 'Contact Person Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Contact Person Phone',
          name: 'contact_person_phone',
          value: this.resolveval.contact_person_phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [
            { rule: 'required', message: 'Contact Person Phone is Required' },
          ],
        },

        {
          label: this.resolveval ? 'Edit Logo' : 'Add Logo',
          name: 'logo',
          type: 'file',
          // multiple: false,
          prefix: Date.now(),
          // path: 'laboratory/',
          // baseurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
          // bucket: 'pece-training-files',
          // apiurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
          // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value:
            this.resolveval && this.resolveval.logo && this.resolveval.logo.name
              ? {
                url: this.resolveval.logo.baseurl,
                type: 'image',
                name: this.resolveval.logo.name,
              }
              : undefined,
        },

        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'checkbox',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },

        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ],
    };
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('laboratoryForm');
    }, 200);
  }

  salespersonMyAccount() {
    this.formdata = {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute:
        this.cookieData.user_type +
        '/user-management/folder-view/' +
        this.folderviewdatalist._id,
      hidereset: true,
      fields: [
        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name Name is Required' },
          ],
        },

        {
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name Name is Required' },
          ],
        },

        {
          label: 'Parent Laboratory',
          name: 'parent_laboratory',
          // hint: 'In months',
          type: 'autocomplete',
          // multiple: false,
          endpoint: 'users/laboratoryAutocomplete',
          search_field: 'name_search',

          val:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.parent_laboratory
              ? [
                {
                  key: this.resolveval.parent_laboratory.key,
                  val: this.resolveval.parent_laboratory.val,
                },
              ]
              : [],
          value:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.parent_laboratory
              ? this.resolveval.parent_laboratory._id
              : [],
          validations: [
            { rule: 'required', message: 'Parent Laboratory is Required' },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'Phone No',
          name: 'phone',
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },

        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: 'Address',
          name: 'address',
          value: this.resolveval.address,
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Address is Required' }],
        },

        {
          label: 'State',
          name: 'state',
          val: this.stateList,
          value: this.resolveval.state,
          type: 'select',
          validations: [{ message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: this.resolveval.city,
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: this.resolveval.zip,
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },

        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'checkbox',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },

        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ],
    };
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('salespersonForm');
    }, 200);
  }

  doctorMyAccount() {
    this.formdata = {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute:
        this.cookieData.user_type +
        '/user-management/folder-view/' +
        this.folderviewdatalist._id,

      hidereset: true,
      fields: [
        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name Name is Required' },
          ],
        },

        {
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name Name is Required' },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'Phone No',
          name: 'phone',
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },

        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: 'Parent Laboratory',
          name: 'parent_laboratory',
          // hint: 'In months',
          type: 'autocomplete',
          // multiple: false,
          endpoint: 'users/laboratoryAutocomplete',
          search_field: 'name_search',

          val:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.parent_laboratory
              ? [
                {
                  key: this.resolveval.parent_laboratory.key,
                  val: this.resolveval.parent_laboratory.val,
                },
              ]
              : [],
          value:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.parent_laboratory
              ? this.resolveval.parent_laboratory._id
              : [],
          validations: [
            { rule: 'required', message: 'Parent Laboratory is Required' },
          ],
        },

        {
          label: 'Practice Name',
          name: 'practice_id',
          // hint: ',0000',
          type: 'select',
          val: this.practiceList,
          value:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.practice_id &&
              this.resolveval.practice_id._id
              ? this.resolveval.practice_id._id
              : '',
          // multiple: true,
          validations: [
            { rule: 'required', message: 'Choose Location is Required' },
          ],
        },

        {
          label: 'Choose Location',
          name: 'parent_location',
          type: 'select',
          val: this.locationArray,
          value:
            this.resolveval &&
              Object.keys(this.resolveval).length > 0 &&
              this.resolveval.parent_location
              ? this.resolveval.parent_location[0]
              : '',
          validations: [
            { rule: 'required', message: 'Choose Location is Required' },
          ],
        },

        // {
        //   label: "Choose Location",
        //   name: 'parent_location',
        //   type: 'select',
        //   val: this.parent_locationList,
        //   value: this.resolveval.parent_location[0],
        //   formatflag: true,
        //   // type: 'number',
        //   validations: [{ rule: 'required', message: 'Parent Laboratory is Required' }],
        //   // disabled: true,
        // },

        {
          label: 'Taxonomies',
          name: 'taxonomy',
          // hint: ',0000',
          type: 'select',
          value: this.resolveval.taxonomy,
          val: this.taxonomyList,
          // value:[2021,2022],
          multiple: true,
          validations: [
            { rule: 'required', message: 'Select Taxonomies is Required' },
          ],
        },

        {
          label: 'NPI',
          // label: 'NPI(Select practice NPI)',
          name: 'npi',
          value: this.resolveval.npi,
          type: 'number',
          validations: [{ rule: 'required', message: 'NPI is Required' }],
        },
        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'checkbox',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },

        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ],
    };

    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('doctorForm');
    }, 200);
  }

  listenFormFieldChange(val) {
    console.log('listenFormFieldChange val+++++', val);
    if (val) {
      if (
        val.autocompletefiledvalue &&
        val.autocompletefiledvalue.parent_laboratory &&
        this.usertype !== 'practice'
      ) {
        // reset the Practice name field
        if (
          this.ispracticenameValueUpdate &&
          val.fromval.practice_id !== undefined
        ) {
          this.ispracticenameValueUpdate = false;
        }
        let data = {
          _id: val.autocompletefiledvalue.parent_laboratory,
        };
        if (
          this.folderviewdatalist.parent_laboratory !==
          val.autocompletefiledvalue.parent_laboratory
        ) {
          this.fetchPracticeList(data, 'clicked');
        } else {
          this.fetchPracticeList(data);
        }
      }
      if (this.usertype == 'practice') {
        if (
          val.autocompletefiledvalue &&
          val.autocompletefiledvalue.parent_laboratory
        ) {
          if (
            this.isSalesPersonValueUpdate &&
            val.fromval.sales_person !== undefined
          ) {
            this.isSalesPersonValueUpdate = false;
          }
          let data = {
            searchcondition: {
              _id: val.autocompletefiledvalue.parent_laboratory,
            },
          };
          if (
            this.folderviewdatalist.parent_laboratory !==
            val.autocompletefiledvalue.parent_laboratory
          ) {
          }
          console.log('this.folderviewdatalist data', data);
          this.salesPersonFetching(data);
          let data2 = {
            _id: val.autocompletefiledvalue.parent_laboratory,
          };
          this.fetchPracticeLocation(data2);
        }
      }

      // console.log('listenFormFieldChange datatest', this.folderviewdatalist)
      // reset the Practice name field
      // if (this.isLABpracticenameValueUpdate && val.fromval.practice_id !== undefined) {
      //   this.isLABpracticenameValueUpdate = false;
      // }

      if (val.field && val.field.name == 'practice_id') {
        let data = {
          _id: val.fieldval,
        };
        this.fetchPracticeLocation(data, 'clicked');
      } else {
        // let data = {
        //   _id: this.folderviewdatalist.practice_id
        // }
        // if(this.folderviewdatalist.parent_laboratory && val.autocompletefiledvalue?.parent_laboratory && (this.folderviewdatalist.parent_laboratory !== val.autocompletefiledvalue.parent_laboratory)){
        // this.fetchPracticeLocation(data,'clicked');
        // }else{
        //   this.fetchPracticeLocation(data);
        // }
      }

      if (val.field && val.field == 'fromsubmit') {
        this.addFormLoader = true;
        // get user details from cookie & update local cookie objet
        this.cookieValue = JSON.parse(
          this.cookieService.get('login_user_details')
        );
        for (let key in this.cookieValue) {
          for (let name in val.fromval) {
            if (key == name) {
              this.cookieValue[key] = val.fromval[name];
            }
          }
        }

        if (this.resolveval && this.resolveval._id) {
          val.fromval._id = this.resolveval._id;
        }
        let dataobj = {
          data: val.fromval,
        };

        this.httpService
          .httpViaPost('users/addUpdateData', dataobj)
          .subscribe((response) => {
            console.log('dataobj', response);
            if (response && response.status == 'success') {
              this.matSnackBar.open(response.message, 'Ok', {
                duration: 1000,
              });
              // cookie delete of User details
              // this.cookieService.delete('login_user_details', '/');
              setTimeout(() => {
                // cookie set User details after api get success
                // this.cookieService.set('login_user_details', JSON.stringify(this.cookieValue));
                // this.router.navigateByUrl(this.cookieData.user_type + "/user-management/folder-view/" + this.folderviewdatalist._id,);
                let dataedit = {
                  condition: {},
                  sort: {
                    type: 'desc',
                    field: '_id',
                  },
                  searchcondition: { _id: this.folderviewdatalist._id },
                };

                this.httpService
                  .httpViaPost('users/folderviewdata', dataedit)
                  .subscribe((response) => {
                    this.folderviewdatalist = response.results.res[0];
                    console.log(
                      'dataobj this.folderviewdatalist',
                      this.folderviewdatalist
                    );

                    this.salesdetailsPAC = [];
                    this.labdetailsPAC = [];
                    this.selectedParentLocations = null
                    this.selectedTaxonomies = null;
                    this.labdetailPAC = [];
                    this.practicePAC = [];
                    this.logoimgPAC = [];
                    this.setPersonalInfo('editSuccess');
                  });
              }, 1000);
            }

            if (response && response.status == 'error') {
              this.matSnackBar.open(response.message, '', {
                duration: 1000,
              });
              this.addFormLoader = false;
            }
          });
      }
      if (val.field == 'formcancel') {
        // this.personalInfoFlag = true;
        // this.editInfoFlag = false;
        this.changeBtn('info');
      }
    }
  }

  listenLiblistingChange(data: any = null) {
    console.log('listenFormFieldChange test', data);
  }

  editinfoData() {
    console.log('folderviewdatalistID', this.folderviewdatalist._id);
    let data: any = {
      // sort: { type: 'desc', field: '_id' },
      searchcondition: { _id: this.folderviewdatalist._id },
    };

    this.httpService
      .httpViaPost('users/fetchUserDetails', data)
      .subscribe((response) => {
        if (response) {
          if (response.status == 'success') {
            this.editInfoFlag = true;
            this.resolveval = response.results.res[0];
            console.log("editinfoData++++", this.resolveval)
            console.log('this.usertype', this.usertype);
            // setTimeout(() => {
            switch (this.usertype) {
              case 'admin':
                this.adminMyAccount();
                this.getStates();
                break;

              case 'laboratory':
                this.laboratoryMyAccount();
                break;

              case 'practice':
                // this.locationListlab();
                this.practiceyMyAccount();

                this.taxonomyFetching();
                // this.salesPersonFetching();
                // this.taxonomyFetching();
                // this.salesPersonFetching();
                break;

              case 'sales_person':
                this.salespersonMyAccount();
                this.getStates();
                break;

              case 'doctor':
                this.taxonomyFetching();
                // if (this.resolveval && Object.keys(this.resolveval).length > 0 && this.resolveval.practice_id && this.resolveval.practice_id._id) {
                //   let data: any = { _id: this.resolveval.practice_id._id };
                //   this.fetchPracticeLocation(data)
                // }
                this.doctorMyAccount();
                break;
            }
            this.folderHeader = 'Edit Information';
            this.loaderFlag = false;
          } else {
            this.loaderFlag = false;
          }
        }
      });
  }

  fetchPracticeList(data: any, type?: string) {
    this.httpService
      .httpViaPost('users/practicenameforlab', data)
      .subscribe((response) => {
        console.log('my change response++++', response.res);
        if (response) {
          this.practiceList = response.res;
          if (this.practiceList.length > 0) {
            this.practiceList.forEach((e: any, i: number) => {
              e.val = e._id;
            });
          }

          console.log('fetchPracticeLocation type fetchPracticeList', type);

          this.formdata.fields = this.formdata.fields.filter((e) =>
            e.name == 'practice_id' ? (e.val = this.practiceList) : []
          );
          if (!type) {
            let data = {
              _id: this.folderviewdatalist.practice_id,
            };
            this.fetchPracticeLocation(data, 'clicked');
          } else {
            this.formdata.fields = this.formdata.fields.filter((e) =>
              e.name == 'parent_location' ? (e.val = []) : []
            );
          }
        }
      });
  }

  listenFormChangePassword(val) {
    console.log('val+++++', val);
    let dataobj: any = {};

    if (val.field == 'fromsubmit' && val.fieldval == 'success') {
      dataobj.data = val.fromval;
      this.addFormLoader = true;

      this.httpService
        .httpViaPost('auth/setpassword', dataobj)
        .subscribe((response) => {
          console.log('response>>>5555', response);

          if (response) {
            if (response.status == 'success') {
              this.matSnackBar.open(response.message, '', {
                duration: 3000,
              });
              // this.router.navigateByUrl('/dashboard');
              this.addFormLoader = false;
              this.personalInfoFlag = true;
              this.changePassFlag = false;
            } else {
              this.matSnackBar.open(response.message, '', {
                duration: 3000,
              });

              this.addFormLoader = false;
            }
          }
        });
      // this._snackBar.open(response.message, '', {
      //   duration: 3000
      // })
    }

    if (val.field == 'formcancel') {
      this.personalInfoFlag = true;
      this.changePassFlag = false;
    }
  }

  changepassinfoData() {
    this.userdatapass = this.folderviewdatalist;
    console.log('changepassinfoData', this.userdatapass);

    this.formdatachngpass = {
      // successmessage: 'Updated Successfully',
      submittext: 'Update',
      apiUrl: this.httpService.baseUrl,
      // endpoint: 'auth/changePassword',
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute:
        this.cookieData.user_type +
        '/user-management/folder-view/' +
        this.folderviewdatalist._id,
      // redirectpath: "/dashboard",
      resettable: false,

      fields: [
        // {
        //   label: 'Current Password',
        //   name: 'current_password',
        //   type: 'password',
        //   passwordflag: true,
        //   value: '',

        //   validations: [
        //     {
        //       rule: 'required',
        //       message: 'This field is required',
        //     },
        //     {
        //       rule: 'pattern',
        //       value: '',
        //       message: "Current Password doesn't match",
        //     },
        //   ],
        // },
        {
          label: 'New Password',
          name: 'new_password',
          type: 'password',
          passwordflag: true,
          value: '',

          validations: [
            {
              rule: 'required',
              message: 'This field is required',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Your password must contain 8 characters',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirm_password',
          type: 'password',
          passwordflag: true,
          value: '',
          validations: [
            {
              rule: 'required',
              message: 'This field is required',
            },

            // {
            //   rule: 'match',
            //   message: 'Confirm Password field Needs to  match New Password',
            // },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Confirm password field value must be matched',
            },
          ],
        },
        {
          label: 'id',
          name: '_id',
          type: 'hidden',
          value: this.userdatapass._id,
        },
      ],
    };
  }

  fetchPracticeLocation(data: any, type?: string) {
    this.httpService
      .httpViaPost('users/fetchpracticelocation', data)
      .subscribe((response) => {
        console.log('my change response++++', response.res);
        if (response) {
          this.locationArray = response.res;
          if (this.locationArray.length > 0) {
            this.locationArray.forEach((e: any, i: number) => {
              e.val = e._id;
            });
          }
          console.log('fetchPracticeLocation type', type, this.locationArray);

          // if(type){
          //   this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'parent_location' ? e.val = [] : []);
          // }else{
          this.formdata.fields = this.formdata.fields.filter((e) =>
            e.name == 'parent_location' ? (e.val = this.locationArray) : []
          );
          // }
          // else {
          // }
        }
      });
  }

  loginfoData() {
    // alert('000')
    let data: any = {
      condition: {
        skip: this.skipcount,
        limit: this.limitcount,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: { _id: this.folderviewdatalist._id },
    };

    this.httpService
      .httpViaPost('users/loginactivity', data)
      .subscribe((response) => {
        if (response) {
          if (response.status == 'success') {
            this.loginactivityCon = response.res;

            console.log('loginactivityCon', this.loginactivityCon);

            // if (this.loginactivityCon.length > 0) {
            //   this.Loginnodatafound = false
            // };

            this.loaderFlag = false;

            this.loginactivityDATAFlag = true;
          } else {
            this.loaderFlag = false;
          }
        }
      });

    let datacount: any = {
      condition: {
        condition: {
          skip: this.skipcount,
          limit: this.limitcount,
        },
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: { _id: this.folderviewdatalist._id },
    };

    this.httpService
      .httpViaPost('users/loginactivity-count', datacount)
      .subscribe((response) => {
        if (response) {
          if (response.status == 'success') {
            this.loginactivity_count = response.count;

            console.log('loginactivity_count', this.loginactivity_count);
          }
        }
      });
  }

  loadmore() {
    this.addFormLoader = true;
    this.notesManageDATAFlag = false;
    let isShowLess: boolean = false;
    let balanceItems: number = 0;
    if (this.loginactivityCon.length == this.loginactivity_count) {
      console.log("show less 1 >> ", 'length', this.loginactivityCon.length, "this.loginactivity_count ", this.loginactivity_count, "balanceItems ", balanceItems, "this.skipcount ", this.skipcount)
      if (this.loginactivityCon.length > this.limitcount) {
        this.skipcount = 0;
        balanceItems = this.loginactivityCon.length - this.limitcount;
        // if (balanceItems > this.limitcount) {
        //   this.skipcount = this.skipcount - this.limitcount;
        // } else {
        //   this.skipcount = 0;
        // }
      }
      isShowLess = true;
    } else {
      this.skipcount = this.skipcount + 9;
      // this.loadButtonText = "Load More";
    }
    console.log("show less 2 >> ", 'length', this.loginactivityCon.length, "this.loginactivity_count ", this.loginactivity_count, "balanceItems ", balanceItems, "this.skipcount ", this.skipcount)
    let data: any = {
      condition: {
        skip: this.skipcount,
        limit: this.limitcount,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: { _id: this.folderviewdatalist._id },
    };

    this.httpService
      .httpViaPost('users/loginactivity', data)
      .subscribe((response: any) => {
        // console.log("response-----------", response);

        if (response) {
          if (response.res && response.res.length > 0) {
            // console.log("this.loginactivityCon", this.loginactivityCon)
            // setTimeout(() => {

            if (isShowLess) {
              this.loginactivityCon = this.loginactivityCon.slice(0, this.loginactivityCon.length - balanceItems);
            } else {
              response.res.forEach((e: any) => {
                this.loginactivityCon.push(e);
              });
            }
          }
          //  else {
          //   this.loadButtonText = "Show Less";
          //   // this.loaderFlag=true;
          // }
          // },500)
          this.addFormLoader = false;
        }
      });
    this.httpService
      .httpViaPost('users/loginactivity-count', data)
      .subscribe((response) => {
        if (this.skipcount > this.loginactivity_count) {
          // this.loginactivity_count = 10;
        }
        this.loginactivity_count = response.count;
      });
  }

  notesData() {
    let data: any = { id: this.folderviewdatalist._id };
    data.id = this.folderviewdatalist._id;
    data.condition = {
      skip: 0,
      limit: 5,
    };
    (data.sort = { field: '_id', type: 'desc' }),
      // data.user = this.cookieData._id;
      this.httpService
        .httpViaPost('users/listnotedata', data)
        .subscribe((response) => {
          console.log('response================', response);

          if (response) {
            if (response.status == 'success') {
              this.noteCon = response.res;

              this.addFormLoader = false;
              this.loaderFlag = false;
              this.notesManageDATAFlag = true;
            } else {
              this.loaderFlag = false;
              this.addFormLoader = false;
            }
          }
        });
    this.httpService
      .httpViaPost('users/listnotedata-count', data)
      .subscribe((response) => {
        if (this.skipcount > this.loginactivityNotes) {
        }
        this.loginactivityNotes = response.count;
      });
  }

  loadmoreNotes() {
    this.addFormLoader = true;
    this.notesManageDATAFlag = false;
    let isShowLess: boolean = false;
    let balanceItems: number = 0;
    if (this.noteCon.length == this.loginactivityNotes) {
      if (this.noteCon.length > this.limitcountNotes) {
        balanceItems = this.noteCon.length - this.limitcountNotes;
        this.skipcountNotes = 0;
        // if (balanceItems > this.limitcountNotes) {
        //   this.skipcountNotes = this.skipcountNotes - this.limitcountNotes;
        // } else {
        //   this.skipcountNotes = 0;
        // }
      }
      isShowLess = true;
    } else {
      this.skipcountNotes = this.skipcountNotes + 5;
    }

    // console.log("show less 2 >> ", 'count',this.noteCon.length, "this.loginactivityNotes ", this.loginactivityNotes,"balanceItems ",balanceItems,"this.skipcountNotes ",this.skipcountNotes)
    let data: any = { id: this.folderviewdatalist._id };
    data.id = this.folderviewdatalist._id;
    data.condition = {
      skip: this.skipcountNotes,
      limit: this.limitcountNotes,
    };
    data.sort = { field: '_id', type: 'desc' };

    this.httpService
      .httpViaPost('users/listnotedata', data)
      .subscribe((response) => {
        // console.log("dataobj loadmoreNotes", response)
        if (response) {
          if (response.res && response.res.length > 0) {
            if (isShowLess) {
              this.noteCon = this.noteCon.slice(
                0,
                this.noteCon.length - balanceItems
              );
            } else {
              response.res.map((e: any) => {
                this.noteCon.push(e);
              });
            }
          }
          // else {
          //   this.loadButtonNotes = "Show Less";
          // }
          this.addFormLoader = false;
        }
      });

    this.httpService
      .httpViaPost('users/listnotedata-count', data)
      .subscribe((response) => {
        if (this.skipcount > this.loginactivityNotes) {
        }
        this.loginactivityNotes = response.count;
      });
  }

  addnote() {
    this.addFormLoader = true;
    this.addNoteForm.markAllAsTouched();
    // let data: any = this.addNoteForm.value;
    // data.id = this.folderviewdatalist._id

    let data: any = this.addNoteForm.value;
    data.id = this.folderviewdatalist._id;
    data.user = this.cookieData._id;

    if (this.addNoteForm.valid) {
      // this.addNoteForm.patchValue({

      //   note: [],
      // })

      this.httpService
        .httpViaPost('users/addupdateNotes', data)
        .subscribe((response) => {
          console.log('dataobj', response);
          if (response) {
            this.addNoteForm.reset();
            this.skipcountNotes = 0;
            this.notesData();
            this.realtimeNotesUpdate = this.realtimeNotesUpdate + 1
            // setTimeout(() => {
            //   // let dataadd: any = this.addNoteForm.value;
            //   // dataadd.id = this.folderviewdatalist._id
            //   // dataadd.user = this.cookieData._id

            //   let dataadd: any = { id: this.folderviewdatalist._id }
            //   dataadd.id = this.folderviewdatalist._id;
            //   // dataadd.user = this.cookieData._id
            //   dataadd.condition = {
            //     skip: 0, limit: 10
            //   }

            //   dataadd.sort = { "field": "_id", "type": "desc" },
            //     this.httpService.httpViaPost('users/listnotedata', dataadd).subscribe((response) => {

            //       if (response) {
            //         if (response.status == "success") {

            //           this.addFormLoader = false;

            //           this.addNoteForm.reset();

            //         }
            //         else {

            //           this.addFormLoader = false;
            //         }
            //       }

            //       console.log("listnotedata", response)

            //       this.noteCon = response.res

            //     })

            //   let dataedit = {
            //     condition: {},
            //     sort: {
            //       'type': 'desc', 'field': '_id'
            //     },
            //     searchcondition: { _id: this.folderviewdatalist._id }
            //   }

            //   this.httpService.httpViaPost('users/folderviewdata', dataedit).subscribe((response) => {

            //     this.folderviewdatalist = response.results.res[0];

            //   })

            // }, 1000)
          }
        });
    } else {
      this.addFormLoader = false;
    }
  }

  locationData() {
    let data: any = { user_id: this.folderviewdatalist._id };
    data.condition = {
      skip: this.skipcount,
      limit: this.limitcountloc,
    };

    this.httpService
      .httpViaPost('users/fetchLocationDetails', data)
      .subscribe((response) => {
        console.log('managelocationdataresponse', response);
        if (response) {
          if (response.status == 'success') {
            this.loaderFlag = false;
            this.managelocationDATAFlag = true;

            this.managelocationData = response.results.res;

            if (this.managelocationData.length == 0) {
              this.managelocationNoData = true;
            }

            console.log('managelocationdata++', this.managelocationData);

            let datacount: any = {
              condition: {
                condition: {
                  skip: this.skipcount,
                  limit: this.limitcountloc,
                },
              },

              sort: {
                type: 'desc',
                field: '_id',
              },
              // searchcondition: { _id: this.folderviewdatalist._id }
              user_id: this.folderviewdatalist._id,
            };

            this.httpService
              .httpViaPost('users/fetchLocationDetails-count', datacount)
              .subscribe((response) => {
                if (response) {
                  if (response.status == 'success') {
                    this.location_count = response.count;

                    console.log('location_count', this.location_count);
                  }
                }
              });
          }
        }
      });
  }

  loadmorelocation() {
    this.addFormLoader = true;
    this.managelocationDATAFlag = false;
    let isShowLessloc: boolean = false;
    let balanceItemsloc: number = 0;
    if (this.managelocationData.length == this.location_count) {
      if (this.managelocationData.length > this.limitcountloc) {
        balanceItemsloc = this.managelocationData.length - this.limitcountloc;
        console.log('show less balanceItemsloc', balanceItemsloc);
        if (balanceItemsloc > this.limitcountloc) {
          this.skipcount = this.skipcount - this.limitcountloc;
        } else {
          this.skipcount = 0;
        }
      }
      isShowLessloc = true;
    } else {
      this.skipcount = this.skipcount + 5;
    }

    console.log(
      'show less',
      this.managelocationData.length,
      'count',
      this.location_count,
      'balanceItemsloc',
      balanceItemsloc
    );

    // let data: any = { id: this.folderviewdatalist._id }
    // data.id = this.folderviewdatalist._id;
    // data.condition = {
    //   skip: this.skipcount, limit: this.limitcountloc,
    // }
    // data.sort = { "field": "_id", "type": "desc" };

    let data: any = { user_id: this.folderviewdatalist._id };
    data.condition = {
      skip: this.skipcount,
      limit: this.limitcountloc,
    };

    this.httpService
      .httpViaPost('users/fetchLocationDetails', data)
      .subscribe((response) => {
        // console.log("dataobj loadmoreNotes", response)
        if (response) {
          if (response.results.res && response.results.res.length > 0) {
            if (isShowLessloc) {
              this.managelocationData = this.managelocationData.slice(
                0,
                this.managelocationData.length - balanceItemsloc
              );
            } else {
              response.results.res.map((e: any) => {
                this.managelocationData.push(e);
              });
            }
          } else {
            // this.loadButtonNotes = "Show Less";
          }
          this.addFormLoader = false;
        }
      });

    let datacount: any = {
      condition: {
        condition: {
          skip: this.skipcount,
          limit: this.limitcountloc,
        },
      },

      sort: {
        type: 'desc',
        field: '_id',
      },
      // searchcondition: { _id: this.folderviewdatalist._id }
      user_id: this.folderviewdatalist._id,
    };
    this.httpService
      .httpViaPost('users/fetchLocationDetails-count', datacount)
      .subscribe((response) => {
        if (this.skipcount > this.location_count) {
        }
        this.location_count = response.count;
      });
  }

  deleteLocation(item: any, index: number) {
    console.log('this.managelocationData', this.managelocationData);

    this.addFormLoader = true;

    this.managelocationData = this.managelocationData.filter(
      (e: any, i: number) => {
        return i != index;
      }
    );

    if (item._id) {
      let data: any = {
        id: item._id,
      };
      this.httpService
        .httpViaPost('users/deletelocation', data)
        .subscribe((response) => {
          console.log('dataobj', response);
          if (response && response.status == 'success') {
            this.skipcount = 0;

            this.matSnackBar.open(response.message, '', {
              duration: 1000,
            });

            this.skipcount = 0;

            this.locationData();
            this.addFormLoader = false;
          }
          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, '', {
              duration: 1000,
            });
            this.addFormLoader = false;
          }
        });
    }
  }

  editLocation(item: any, index: number) {
    console.log('this.managelocationData', item, index);

    const dialogRef = this.dialog.open(locationMmodalFV, {
      height: '500px',
      width: '600px',
      panelClass: 'externaldata-class',
      data: {
        heading: 'Edit Location Data',
        item: item,
      },
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res', res);

      this.managelocationData.forEach((e: any, i: number) => {
        if (index == i && res && res.formObj) {
          this.managelocationData[i] = res.formObj;
        }
      });
    });
  }

  addLocationmodal() {
    // alert("addLocation");
    const dialogRef = this.dialog.open(locationMmodalFV, {
      height: '500px',
      width: '600px',
      panelClass: 'externaldata-class',
      data: {
        heading: 'Add Location Data',
        user_id: this.folderviewdatalist._id,
      },
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res', res);
      if (res && res.formObj) {
        console.log('managelocationData+++++', this.managelocationData);
        this.managelocationData.push(res.formObj);

        this.skipcount = 0;
        this.locationData();

        console.log('managelocationData+++++', this.managelocationData);
      }
    });
  }

  changeBtn(flag: any) {
    console.log('flag-------------->>>>>>>>>>', flag);

    this.personalInfoFlag = false;
    this.editInfoFlag = false;
    this.editFlag = false;

    this.changePassFlag = false;
    this.loginActivityFlag = false;
    this.notesManageFlag = false;
    this.locationFlag = false;
    if (flag == 'info') {
      this.folderHeader = 'Personal Information';
      this.personalInfoFlag = true;
    }
    if (flag == 'edit') {
      this.loaderFlag = true;
      this.editFlag = true;
      // this.taxonomyFetching();
      // this.salesPersonFetching();
      // this.locationListlab();
      this.editinfoData();
    }
    if (flag == 'pass') {
      this.folderHeader = 'Change Password';
      this.changePassFlag = true;
      this.changepassinfoData();
    }
    if (flag == 'login') {
      this.folderHeader = 'Login Activity';
      this.loginActivityFlag = true;
      this.loaderFlag = true;
      this.loginfoData();

      this.loginactivityDATAFlag = false;
    }
    if (flag == 'notes') {
      this.folderHeader = 'Notes Manage';
      this.notesManageFlag = true;
      this.loaderFlag = true;
      this.notesData();
      this.notesManageDATAFlag = false;
    }

    if (flag == 'location') {
      this.folderHeader = 'Manage Location';
      this.locationFlag = true;
      this.loaderFlag = true;
      this.locationData();
      this.managelocationDATAFlag = false;
    }
  }

  openDialog(user, i) {
    // this.dialog.open(deleteNote);
    console.log('i, user==>', i, user);

    const dialogReference = this.dialog.open(deleteNote, {
      // data: user,
      data: { folderviewID: this.folderviewdatalist._id, index: i },

      // panelClass: "custom-modalbox",
    });

    dialogReference.afterClosed().subscribe((afterCloseclick: any) => {
      console.log('afterCloseclick==>', afterCloseclick);

      if (afterCloseclick == 'true') {
        this.noteCon.splice(i, 1);

        this.loadmoreNotes();
        this.realtimeNotesUpdate = this.realtimeNotesUpdate - 1;
      }

      // if (afterCloseclick == "Yes") {
      //   console.log("going to delete");

      //   console.log("i, user==>", i, user);

      //   let data: any = { id: this.folderviewdatalist._id }
      //   data.index = i,

      //     this.addFormLoader = true;
      //     this.addFormLoadernote = true;

      //   this.httpService.httpViaPost('users/deletenotedata', data).subscribe((response) => {
      //     if (response) {

      //       // this.addFormLoader = true;
      //       setTimeout(() => {
      //         let dataadd: any = this.addNoteForm.value;
      //         dataadd.id = this.folderviewdatalist._id
      //         // dataadd.user = this.cookieData._id
      //         this.httpService.httpViaPost('users/listnotedata', dataadd).subscribe((response) => {
      //           this.noteCon = response.res
      //           if (response) {

      //             if (response.status == "success") {
      //               this.addFormLoader = false;
      //               this.addFormLoadernote = false;
      //             }

      //           }
      //         })

      //         let dataedit = {
      //           condition: {},
      //           sort: {
      //             'type': 'desc', 'field': '_id'
      //           },
      //           searchcondition: { _id: this.folderviewdatalist._id }
      //         }

      //         this.httpService.httpViaPost('users/folderviewdata', dataedit).subscribe((response) => {
      //           this.folderviewdatalist = response.results.res[0];
      //         })
      //       }, 1000);
      //     }

      //   })

      // }
    });
  }
}

@Component({
  selector: 'deleteNote',
  templateUrl: 'deleteNote.html',
})
export class deleteNote {
  public modaldata: any = {};
  public folderviewdatalist: any = '';
  public addFormLoader: boolean = false;
  public addNoteForm: FormGroup;
  public addFormLoadernote: boolean = false;
  public noteCon: any = [];
  constructor(
    public dialog: MatDialogRef<deleteNote>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log('data0000', this.data);
  }

  yesclick(): void {
    this.addFormLoader = true;

    let data: any = { id: this.data.folderviewID, index: this.data.index };
    0;
    this.httpService
      .httpViaPost('users/deletenotedata', data)
      .subscribe((response) => {
        console.log("response==============", response);

        if (response) {
          if (response.status == 'success') {
            this.dialog.close('true');

          }
          this.addFormLoader = false;
          this.matSnackBar.open(response.msg, '', {
            duration: 1000,
          });

        }
      });

    // this.dialog.close(clickvalue);
    // this.dialog.close();
  }
  noclick() {
    this.dialog.close();
    // this.dialog.close();
  }
}

@Component({
  selector: 'location-modal',
  templateUrl: 'location-modal.html',
})
export class locationMmodalFV {
  public modaldata: any = {};
  public addLocationForm: FormGroup;
  public stateList: any = [];

  public addFormLoader: boolean = false;

  public addFormLoadernote: boolean = false;
  public isedit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<locationMmodalFV>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar
  ) {
    this.modaldata = data;
    console.log(this.modaldata, 'modal data++');
  }

  ngOnInit() {
    this.getStates();

    this.addLocationForm = new FormGroup({
      location_name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      phone: new FormControl(
        '',
        Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)
      ),
    });
    if (this.modaldata.item) {
      this.isedit = true;
      this.addLocationForm.patchValue({
        location_name: this.modaldata.item.location_name,
        address: this.modaldata.item.address,
        city: this.modaldata.item.city,
        state: this.modaldata.item.state,
        zip: this.modaldata.item.zip,
        phone: this.modaldata.item.phone,
      });
    }
  }

  getStates() {
    this.httpService
      .getHttpData('../../../../../assets/data-set/state.json')
      .subscribe((response) => {
        // console.log(response, "this.stateList")

        this.stateList = response;
      });
  }

  phoneFormatting(event: any) {
    // console.log("event", this.addLocationForm.controls);
    var formatted = this.formatPhoneText(event.target.value);
    // console.log("event formatted", formatted);
    this.addLocationForm.patchValue({
      phone: formatted,
    });
  }
  formatPhoneText(value) {
    value = value.trim().replaceAll('-', '');
    value = value.trim().replaceAll('(', '');
    value = value.trim().replaceAll(')', '');
    value = value.trim().replaceAll(' ', '');

    if (value.length > 3 && value.length <= 6)
      value = '(' + value.slice(0, 3) + ')' + ' ' + value.slice(3);
    else if (value.length > 6)
      value =
        '(' +
        value.slice(0, 3) +
        ')' +
        ' ' +
        value.slice(3, 6) +
        '-' +
        value.slice(6);

    return value;
  }

  addLocation() {
    if (this.addLocationForm.valid) {
      this.addFormLoader = true;
      console.log(this.modaldata, 'modal dataSubmit');

      console.log(this.addLocationForm.value, 'addLocationForm');

      this.addLocationForm.markAllAsTouched();

      if (
        this.modaldata.item &&
        this.modaldata.item._id &&
        this.modaldata.item.user_id
      ) {
        let data: any = this.addLocationForm.value;
        data._id = this.modaldata.item._id;
        data.user_id = this.modaldata.item.user_id;
        console.log('submit data', data);

        this.httpService
          .httpViaPost('users/locationaddedit', data)
          .subscribe((response) => {
            console.log('dataobj', response);
            if (response) {
              this.dialogRef.close({
                formObj: this.addLocationForm.value,
                existLab: true,
              });
            }

            if (response && response.status == 'success') {
              this.matSnackBar.open(response.message, '', {
                duration: 1000,
              });
              this.addFormLoader = false;
            }
            if (response && response.status == 'error') {
              this.matSnackBar.open(response.message, '', {
                duration: 1000,
              });
              this.addFormLoader = false;
            }
          });
      } else {
        let data: any = this.addLocationForm.value;
        //add data user id
        data.user_id = this.modaldata.user_id;

        this.httpService
          .httpViaPost('users/locationaddedit', data)
          .subscribe((response) => {
            console.log('dataobj', response);

            if (response) {
              this.dialogRef.close({
                formObj: this.addLocationForm.value,
                existLab: true,
              });
            }

            if (response && response.status == 'success') {
              this.matSnackBar.open(response.message, '', {
                duration: 1000,
              });
              this.addFormLoader = false;
            }
            if (response && response.status == 'error') {
              this.matSnackBar.open(response.message, '', {
                duration: 1000,
              });
              this.addFormLoader = false;
            }
          });
      }

      // else {
      //   // this.resolveLoader = false;
      //   this.dialogRef.close({ data: this.data, formObj: this.addLocationForm.value });
      // }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // this.data.flag = "no";
  }
}
