<button class="close_button" style="right: 0;top: 0;">
    <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
</button>

<h2 class="alerth2">
    {{header_txt}}
</h2>
<div class="fileUploadDiv" *ngIf="!showFileFlag">
    <div class="error_txt" *ngIf="errorFlag">
        <span>* Click On Upload Then Hit Save </span>
    </div>
    <!-- <input type="file" [(ngModel)]="uploadedReport" name="uploadedReport" (ngModelChange)="uploadInputChanged($event)">
    <div class="uploadingText"><i class="fa fa-upload" aria-hidden="true"></i> Choose a file or drag your file here </div> -->
    <ng-container *ngIf="loadForm"></ng-container>
    <div class="fileUploadLibForm">
        <lib-showform [formdata]="reportUploadFormData" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
        </lib-showform>
    </div>
</div>
<!-- <div class="modal-button-wrapper">
    <button mat-button (click)="modalClose()">Cancel</button>
    <button mat-button (click)="uploadReportFile()">Upload</button>
</div> -->
<mat-progress-bar mode="indeterminate" *ngIf="uploadProgressBar"></mat-progress-bar>

<div *ngIf="showFileFlag">
    <mat-progress-bar mode="indeterminate" *ngIf="showLoaderFlag"></mat-progress-bar>

    <!-- <mat-spinner *ngIf="showLoaderFlag"></mat-spinner> -->
    <div class="pdfFileSection" *ngFor="let pdfFile of pdfFileName;let i=index">
        <embed [src]="pdfFile" type="application/pdf" height="100%" width="100%" class="responsive">
    </div>
    <div class="modal-button-wrapper">
        <button mat-button (click)="finalSubmit()">Submit</button>
        <button mat-button (click)="backFileUpload()">Cancel</button>
    </div>

</div>