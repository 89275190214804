import { Component } from '@angular/core';

@Component({
  selector: 'app-emcode',
  templateUrl: './emcode.component.html',
  styleUrls: ['./emcode.component.css']
})
export class EmcodeComponent {

}
