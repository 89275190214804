import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';


@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css']
})
export class PatientDetailsComponent {

  public patientdetailsData:any;

  public folderviewdataData: any;

  @Input() set patientdetails(value: any) {

    if (value) {
      console.log("Input555555", value)

      this.patientdetailsData=value;

      console.log("patientdetailsData", this.patientdetailsData)

      
      let datasetval: any = {
        condition: {},
        searchcondition: {
          _id: this.patientdetailsData.practice_id
        },
        sort: { type: 'desc', field: '_id' },
      }
  
      this.httpService.httpViaPost('users/folderviewdata', datasetval).subscribe((response: any) => {
        if (response.status == "success") {
  
  
          this.folderviewdataData = response.results.res[0];
          
          console.log(this.folderviewdataData, 'folderviewdataData+++++++++++++++')
  
        }
      })
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}
