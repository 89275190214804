<div class="order_sheet_body">


    <div class="order_sheet_wrapper">

        <ng-container *ngIf="addFormLoader">

            <div class="order_progress order_progress-striped">
                <div class="order_progress-bar">
                </div>
            </div>

            <label class="pleasewaittext">Please Wait.</label>

        </ng-container>



        <ng-container *ngIf="order_sheet_valu.length > 0">
        <ng-container *ngIf="selectHideFlag">
            <mat-checkbox (change)="checkUncheckAll()" [(ngModel)]="masterSelected" *ngIf="selecteallFlag">Select All</mat-checkbox>
        </ng-container>
            <div class="orderAllCon" *ngFor="let item of order_sheet_valu;let i=index;">
                <div class="orderAllConh2 hidecon" id="diagnostic_cat_{{item.diagnostic_category_id}}">
                    {{item.diagnostic_categories_name}}
                </div>
                <ng-container *ngIf="!addFormLoader">
                    <div class="orderAllConSubbody" id="diagnostic_{{item.diagnosis_id}}">
                        <div class="orderAllConSubTop">
                            <h2 [ngClass]="isPatientFolderView==true? 'chakeboxremove':'' ">
                                <span *ngIf="!isPatientFolderView">
                                    <mat-checkbox (change)="showOptions($event,item)" [(ngModel)]="item.selected"
                                        [checked]="item.selected"></mat-checkbox>
                                </span>
                                <strong>
                                    {{item.diagnostic_name}}
                                    <a *ngIf="item.diago_reference_link" [href]="item.diago_reference_link"
                                        target="_blank"><i class="fa fa-info-circle"></i></a>
                                </strong>
                            </h2>


                            <div class="orderAllConSubTop_right">
                                <div class="percentagecss">
                                    <div class="percentageloder" [style.width.%]='item.matched_percentage'></div>
                                </div>
                                <label>{{item.matched_percentage}}%</label>
                            </div>

                            <button *ngIf="item.notes_by_doctor" (click)="veiwDoctorNotes(item)" class="ordernote" title="View Notes">
                                <mat-icon>description</mat-icon>
                            </button>
                        </div>
                        <div [ngClass]="fororderreject == true && item.status == 1 ? 'statusreject' : ''">
                            <div class="orderAllConSub"><strong>*</strong>
                                <span>{{item.diagnostic_reason}}</span>
                                <p *ngIf="item.notes_by_doctor"><strong><mat-icon>description</mat-icon></strong><span>{{item.notes_by_doctor}}</span></p>
                            </div>
                            <div class="orderAllConSub_body2">
                                <div class="orderAllConSub2">
                                    <h3>Assay Name:</h3>
                                    <div class="spantext" [innerHtml]="item?.assay_name"></div>
                                </div>
                                <div class="orderAllConSub2">
                                    <h3>Matched Symptoms:</h3>
                                    <div class="spantext" [innerHtml]="item?.matched_symptoms"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <p class="diagnostic_des">{{item.diagnostic_des}}</p> -->
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="fororderreject && !addFormLoader">
                <button mat-raised-button class="submitbtnsection" (click)="opensignDialog()">Sign & Send</button>
            </ng-container>
            <ng-container *ngIf="!fororderreject && order_sheet_sign_value && !addFormLoader">
                <button mat-raised-button class="submitbtnsection" (click)="opensignDialog()">Sign & Send</button>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="order_sheet_valu.length == 0 && !addFormLoader">
            <span class="orderno">Order Sheet Not Generated, Due to Patient's Symptoms</span>
        </ng-container>

    </div>

    <!-- <div class="order_sheet_loderbody"> -->
    <!-- <div>
        <p style="color: red; text-align: center; ">
            <strong style="display: block;">Oops !</strong> NO Record Found
        </p>
    </div> -->

    <!-- <div class="my-progress-bar">
            <span>{{loadingPercent}}%</span>
            <mat-progress-bar mode="determinate" [value]="loadingPercent" (animationEnd)="progressInLoading()" >
                
            </mat-progress-bar>
        </div> -->
    <!-- </div> -->
</div>