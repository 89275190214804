import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
 

export class MedicationComponent {

  public MedicationComponentDataset:any;

 

  @Input() set medicatio(value: any) {

    if (value) {
    
      // console.log(value, 'value+++++++++++++++')
      this.MedicationComponentDataset=value;

      // console.log(this.MedicationComponentDataset, 'MedicationComponentDataset+++++++++++++++')
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}