<block-ui>
    <app-header></app-header>
    <mat-card class="adminBody">
        <mat-card-content class="rapidResponseUserListCard" *ngIf="cookieData.user_type == 'admin' && rapidResponseAccessUserlist.length > 0 && rapidResponseAccessUserlistCount !== 0">
            You have <strong>{{rapidResponseAccessUserlistCount}}</strong> pending requests for Rapid Response Virtual Platform access <span class="divider"></span> <button (click)="viewRapidResponseList()">View Details</button>
        </mat-card-content>

        <mat-card-content *ngIf="cookieData && cookieData.last_login_datetime " class="lastLoginWrapper" [ngClass]="notificationCount != 0 && cookieData.user_type == 'admin'?'adminLastLoginWrapper':''">
            <div *ngIf="laboratoryData && !demoDoctorDashboard">
                <!-- <h1>Laboratory Details</h1> -->
                <div class="labdetails_lastlogin">
                    <div class="labdetails_lastlogininner">
                        <p><span><i class="fa fa-flask" aria-hidden="true"></i></span> {{laboratoryData.laboratory_name}},
                        </p>
                        <p><span><i class="fa fa-envelope-o" aria-hidden="true"></i></span> {{laboratoryData.email}}, </p>
                        <p><span><i class="fa fa-phone" aria-hidden="true"></i></span> {{laboratoryData.phone}} </p>
                    </div>
                </div>

            </div>
            <ng-container *ngIf="notificationCount != 0 && cookieData.user_type == 'admin'">
                <div class="labdetails_lastlogin">
                    <div class="labdetails_lastlogininner">
                        <span> {{notificationCount}} New Test Added | <button (click)="reviewNewTest()">Please
                            Review</button></span>
                    </div>
                </div>
            </ng-container>
            <mat-card class="lastLoginSubWrapper">
                <!-- <h5>Last Login</h5> -->

                <div>
                    <p><strong>Last Login: </strong> {{cookieData.last_login_datetime | date}} {{cookieData.last_login_datetime | date:'shortTime'}} - {{cookieData.login_data?.timezone?cookieData.login_data?.timezone:'' }} {{cookieData.state}}
                    </p>
                </div>
            </mat-card>
        </mat-card-content>




        <mat-card-content class="dashboardWrapper">
            <div *ngIf="cookieData.user_type == 'laboratory' ">
                <div class="title_wrapper">
                    <h3>Practice Sign-Up Link</h3>
                    <button *ngIf="btnflag" (click)="openUniqueNameDialog('','add')">Add Unique Name</button>
                </div>
                <div class="link_title_wrapper">
                    <div class="link_title" *ngFor="let item of UniqueLIstData  ">
                        <h3><a>{{signupLink}}{{item.uniquecode}}</a></h3>
                        <div class="iconwrapper">
                            <a matTooltip="Edit" (click)="openUniqueNameDialog(item,'edit')"><i class="fa fa-pencil-square"
                                aria-hidden="true"></i></a>
                            <a matTooltip="Copy" (click)="copyLink(item.uniquecode)"><i class="fa fa-clipboard"
                                aria-hidden="true"></i></a>
                            <a matTooltip="Open in new page" href="{{signupLink}}{{item.uniquecode}}" target="_blank">
                                <i class="fa fa-chain-broken" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <!-- <p>Sales Person</p> -->
                </div>

            </div>

            <div *ngIf="cookieData.user_type == 'sales_person' ">
                <ng-container *ngIf="!demoDoctorDashboard">
                    <div class="title_wrapper">
                        <h3>Practice Sign-Up Link</h3>
                        <button *ngIf="btnflag" (click)="openUniqueNameDialog('','add')">Add Unique Name</button>
                    </div>
                    <div class="link_title_wrapper">
                        <div class="link_title" *ngFor="let item of UniqueLIstData  ">
                            <h3><a>{{signupLink}}{{item.uniquecode}}</a></h3>

                            <div class="iconwrapper">
                                <a matTooltip="Edit" (click)="openUniqueNameDialog(item,'edit')"><i class="fa fa-pencil"
                                    aria-hidden="true"></i></a>
                                <a matTooltip="Copy" (click)="copyLink(item.uniquecode)"><i class="fa fa-clipboard"
                                    aria-hidden="true"></i></a>
                                <a matTooltip="Open in new page" href="{{signupLink}}{{item.uniquecode}}" target="_blank">
                                    <i class="fa fa-chain-broken" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="demoDoctorDashboard">
                    <div class="title_wrapper">
                        <h3>Patient Sign-Up Link</h3>
                        <button *ngIf="btnflag" (click)="openUniqueNameDialog('','add')">Add Unique Name</button>
                    </div>
                    <div class="link_title_wrapper">
                        <div class="link_title" *ngFor="let item of UniqueLIstData  ">
                            <h3><a>{{signupLinkpac}}{{item.uniquecode}}</a></h3>

                            <div class="iconwrapper">
                                <a matTooltip="Edit" (click)="openUniqueNameDialog(item,'edit')"><i class="fa fa-pencil"
                                    aria-hidden="true"></i></a>
                                <a matTooltip="Copy" (click)="copyLinkpac(item.uniquecode)"><i class="fa fa-clipboard"
                                    aria-hidden="true"></i></a>
                                <a matTooltip="Open in new page" href="{{signupLinkpac}}{{item.uniquecode}}" target="_blank">
                                    <i class="fa fa-chain-broken" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <button class="doctorSwitchButton" title="{{demoDoctorDashboard?'Switch to Regular View' :'Doctor Dashboard Walk Through'}}" (click)="switchDoctor()">
                <i class="fa fa-stethoscope" aria-hidden="true" *ngIf="!demoDoctorDashboard"></i>
                <i class="fa fa-user" aria-hidden="true" *ngIf="demoDoctorDashboard"></i>
            </button>


            </div>





            <div *ngIf="cookieData.user_type == 'practice' || cookieData.user_type == 'doctor' ">
                <div class="title_wrapper">
                    <h3>Patient Sign-Up Link</h3>
                    <button *ngIf="btnflag" (click)="openUniqueNameDialog('','add')">Add Unique Name</button>
                </div>
                <div class="link_title_wrapper">
                    <div class="link_title" *ngFor="let item of UniqueLIstData  ">
                        <h3><a>{{signupLinkpac}}{{item.uniquecode}}</a></h3>

                        <div class="iconwrapper">
                            <a matTooltip="Edit" (click)="openUniqueNameDialog(item,'edit')"><i class="fa fa-pencil"
                                aria-hidden="true"></i></a>
                            <a matTooltip="Copy" (click)="copyLinkpac(item.uniquecode)"><i class="fa fa-clipboard"
                                aria-hidden="true"></i></a>
                            <a matTooltip="Open in new page" href="{{signupLinkpac}}{{item.uniquecode}}" target="_blank">
                                <i class="fa fa-chain-broken" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>




            <mat-card>
                <mat-card-content class="use_summary_col_container">
                    <div class="use_summary_col3">
                        <div class="use_summary_left_subwrapper">
                            <div *ngIf="cookieData.user_type !== 'doctor' && !demoDoctorDashboard">
                                <h2>Users Summary</h2>
                                <ul>
                                    <!-- <li *ngFor="let user of userTypeList;let i=index" (click)="useSummary(user.val)">{{user.value}}</li> -->
                                    <li (click)="useSummary('admin')" *ngIf="cookieData.user_type =='admin'" [ngClass]="usertype_selected == 'Admin'? 'active':'' ">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-user-circle-o"
                                                aria-hidden="true"></i></span>
                                        <span class="menu_text">Admin</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.admin}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="openUserInAnotherTab('admin')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>
                                    <li (click)="useSummary('laboratory')" [ngClass]="usertype_selected == 'Laboratory'? 'active':'' " *ngIf="cookieData.user_type !=='laboratory' && cookieData.user_type !=='practice' && cookieData.user_type !=='sales_person' && cookieData.user_type !=='doctor'">

                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-flask" aria-hidden="true"></i></span>
                                        <span class="menu_text">Laboratory</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.laboratory}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="openUserInAnotherTab('laboratory')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i></span>
                                        </span>
                                    </li>
                                    <li (click)="useSummary('practice')" [ngClass]="usertype_selected == 'Practice'? 'active':'' " *ngIf="cookieData.user_type !=='practice'  && cookieData.user_type !=='doctor'">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-tasks" aria-hidden="true"></i></span>
                                        <span class="menu_text">Practice</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.practice}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="openUserInAnotherTab('practice')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i></span>
                                        </span>
                                    </li>
                                    <li (click)="useSummary('sales_person')" [ngClass]="usertype_selected == 'Salesperson'? 'active':'' " *ngIf="cookieData.user_type !=='sales_person' && cookieData.user_type !=='doctor' && cookieData.user_type !=='practice'">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-user-o" aria-hidden="true"></i></span>
                                        <span class="menu_text">Salesperson</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.sales_person}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="openUserInAnotherTab('sales_person')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i></span>
                                        </span>
                                    </li>
                                    <li (click)="useSummary('doctor')" [ngClass]="usertype_selected == 'Doctor'? 'active':'' " *ngIf="cookieData.user_type !=='doctor' && cookieData.user_type !=='sales_person'">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-user-md" aria-hidden="true"></i></span>
                                        <span class="menu_text">Doctor</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.doctor}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="openUserInAnotherTab('doctor')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i></span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf=" cookieData.user_type !== 'sales_person' || (cookieData.user_type == 'sales_person' && demoDoctorDashboard)">

                                <h2>{{demoDoctorDashboard?"Doctor ":""}}Order Summary
                                    <div class="iconwrapper" (click)="resetPatient()" *ngIf="cookieData.user_type == 'sales_person' && demoDoctorDashboard">
                                        <a matTooltip="Reset Patient List"><i class="fa fa-refresh"
                                            aria-hidden="true"></i></a>
                                    </div>
                                </h2>

                                <ul>
                                    <!-- <li *ngFor="let user of userTypeList;let i=index" (click)="useSummary(user.val)">{{user.value}}</li> -->
                                    <li (click)="reportSummary(1,'leads')" [ngClass]="reportSummarySelected == 1? 'active':'' " *ngIf=" cookieData.user_type != 'laboratory'">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-user-circle-o"
                                                aria-hidden="true"></i></span>
                                        <span class="menu_text">New patient</span>
                                        </span>
                                        <span class="menu_right">
                                        <span class="menu_count">{{usertypecount.leads?usertypecount.leads:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(1,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>

                                    <li (click)="reportSummary(2,'leads')" [ngClass]="reportSummarySelected == 2? 'active':'' " *ngIf=" cookieData.user_type != 'laboratory'">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-list-alt" aria-hidden="true"></i></span>
                                        <span class="menu_text">Order Sheet Generated</span>
                                        </span>
                                        <span class="menu_right">
                                        <span
                                            class="menu_count">{{usertypecount.order_generated?usertypecount.order_generated:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(2,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>
                                    <li (click)="reportSummary(3,'leads')" [ngClass]="reportSummarySelected == 3? 'active':'' ">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-paper-plane"
                                                aria-hidden="true"></i></span>
                                        <span class="menu_text" *ngIf=" cookieData.user_type != 'laboratory'">Order
                                            Signed And Sent</span>
                                        <span class="menu_text" *ngIf=" cookieData.user_type == 'laboratory'">Order
                                            Pending</span>
                                        </span>
                                        <span class="menu_right">
                                        <span
                                            class="menu_count">{{usertypecount.order_sent?usertypecount.order_sent:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(3,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>

                                    <li (click)="reportSummary(4,'leads')" [ngClass]="reportSummarySelected == 4? 'active':'' ">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-check" aria-hidden="true"></i></span>
                                        <span class="menu_text">Order Accepted</span>
                                        </span>
                                        <span class="menu_right">
                                        <span
                                            class="menu_count">{{usertypecount.order_accepted?usertypecount.order_accepted:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(4,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>
                                    <li (click)="reportSummary(5,'leads')" [ngClass]="reportSummarySelected == 5? 'active':'' ">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-times-circle-o"
                                                aria-hidden="true"></i></span>
                                        <span class="menu_text">Order Rejected</span>
                                        </span>
                                        <span class="menu_right">
                                        <span
                                            class="menu_count">{{usertypecount.order_rejected?usertypecount.order_rejected:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(5,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>
                                    <li (click)="reportSummary(6,'leads')" [ngClass]="reportSummarySelected == 6? 'active':'' ">
                                        <span class="menu_left">
                                        <span class="menu_icon"><i class="fa fa-heartbeat"
                                                aria-hidden="true"></i></span>
                                        <span class="menu_text">{{testdone_txt}}</span>
                                        </span>
                                        <span class="menu_right">
                                        <span
                                            class="menu_count">{{usertypecount.completed?usertypecount.completed:0}}</span>
                                        <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(6,'leads')">
                                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                        </span>
                                        </span>
                                    </li>
                                </ul>
                                <ng-container *ngIf="demoDoctorDashboard">
                                    <h2>Laboratory Order Summary</h2>
                                    <ul>
                                        <li (click)="labDemoreportSummary(3,'leads')" [ngClass]="labDemoReportSummarySelected == 3? 'active':'' ">
                                            <span class="menu_left">
                                            <span class="menu_icon"><i class="fa fa-paper-plane"
                                                    aria-hidden="true"></i></span>
                                            <span class="menu_text">Order Pending</span>
                                            </span>
                                            <span class="menu_right">
                                            <span
                                                class="menu_count">{{usertypecount.lab_order_sent?usertypecount.lab_order_sent:0}}</span>
                                            <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(3,'leads')">
                                                <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                            </span>
                                            </span>
                                        </li>
                                        <li (click)="labDemoreportSummary(4,'leads')" [ngClass]="labDemoReportSummarySelected == 4? 'active':'' ">
                                            <span class="menu_left">
                                            <span class="menu_icon"><i class="fa fa-check"
                                                    aria-hidden="true"></i></span>
                                            <span class="menu_text">Order Accepted</span>
                                            </span>
                                            <span class="menu_right">
                                            <span
                                                class="menu_count">{{usertypecount.lab_order_accepted?usertypecount.lab_order_accepted:0}}</span>
                                            <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(4,'leads')">
                                                <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                            </span>
                                            </span>
                                        </li>
                                        <li (click)="labDemoreportSummary(5,'leads')" [ngClass]="labDemoReportSummarySelected == 5? 'active':'' ">
                                            <span class="menu_left">
                                            <span class="menu_icon"><i class="fa fa-times-circle-o"
                                                    aria-hidden="true"></i></span>
                                            <span class="menu_text">Order Rejected</span>
                                            </span>
                                            <span class="menu_right">
                                            <span
                                                class="menu_count">{{usertypecount.lab_order_rejected?usertypecount.lab_order_rejected:0}}</span>
                                            <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(5,'leads')">
                                                <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                            </span>
                                            </span>
                                        </li>
                                        <li (click)="labDemoreportSummary(6,'leads')" [ngClass]="labDemoReportSummarySelected == 6? 'active':'' ">
                                            <span class="menu_left">
                                            <span class="menu_icon"><i class="fa fa-heartbeat"
                                                    aria-hidden="true"></i></span>
                                            <span class="menu_text">Lab Test Results Sent</span>
                                            </span>
                                            <span class="menu_right">
                                            <span
                                                class="menu_count">{{usertypecount.lab_completed?usertypecount.lab_completed:0}}</span>
                                            <span class="menu_righticon" matTooltip="Open in new page" (click)="patientOpenUserInAnotherTab(6,'leads')">
                                                <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                            </span>
                                            </span>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </div>

                        <!-- <div class="use_summary_left_subwrapper"  *ngIf="cookieData.user_type == 'practice' || cookieData.user_type == 'doctor' || cookieData.user_type == 'admin' || cookieData.user_type == 'laboratory' ">
                        <h2>Report Summary</h2>
                        <ul>
                            <li (click)="reportSummary('leads')" 
                                [ngClass]="usertype_selected == 'Leads'? 'active':'' ">
                                <span class="menu_left">
                                    <span class="menu_icon"><i class="fa fa-user-circle-o"
                                            aria-hidden="true"></i></span>
                                    <span class="menu_text">Leads</span>
                                </span>
                                <span class="menu_right">
                                    <span class="menu_count">{{usertypecount.leads}}</span>
                                    <span class="menu_righticon" matTooltip="Open in new page"
                                        (click)="openUserInAnotherTab('admin')">
                                        <i class="fa fa-share-square-o" aria-hidden="true"></i>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div> -->


                    </div>
                    <div class="use_summary_col7">

                        <div class="hidepreviewbtn">
                            <!-- <div class="externalButtons"> -->
                            <button class="addUserButton" (click)="addingUser(usertype_selected)">Add
                            {{usertype_selected}}</button>
                            <button class="addPatientButton" (click)="addingPatient()">Add Patient</button>
                            <button class="downloadCSVButton" (click)="downloadCSV()">Download CSV</button>
                            <!-- </div> -->
                            <h2>
                                <ng-container *ngIf="usertype_selected !== 'Leads'">Users: ({{usertype_selected}})
                                </ng-container>
                                <ng-container *ngIf="usertype_selected == 'Leads'">Patient Summary</ng-container>
                                <span class="legendSpan" *ngIf="selectedOrderSummary == 3">
                                <span>
                                    <span class="normalLegand" title="New Patient"></span> New Patient
                                </span>
                                <span>
                                    <span class="reOrderLegand" title="Re-ordered Patient"></span> Re-ordered Patient
                                </span>
                                </span>
                                <span class="searchandrefreshbtns">
                                <button class="search_btn refreshbtn" (click)="refreshSearchedList()"><i
                                        class="fa fa-refresh" aria-hidden="true"></i></button>
                                <button (click)="openSearchDialog()" class="search_btn"><i class="fa fa-search"
                                        aria-hidden="true"></i></button>
                            </span>
                            </h2>
                            <!-- <div [ngClass]="UsersSummaryLoader == true ? 'show':'hide'  "> -->
                            <div *ngIf="UsersSummaryLoader">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                            <div>
                                <lib-listing class="adminlisttable_v2 " *ngIf="tabledatalist != null && tabledatalist.length > 0" [datasource]="tabledatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner" [deleteendpoint]="deleteendpoint"
                                    [updateendpoint]="updateendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                                    [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                                    [limitcond]="limitcond" [updatetable]="dashboard_updatetable" [customlistenbutton]="customlistenbutton" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)">
                                </lib-listing>
                                <span *ngIf="tabledatalist != null && tabledatalist.length == 0 && !UsersSummaryLoader">
                                <div class="noFoundTextinner">
                                    <span>Oops!<br /> No Records Found</span>
                            </div>
                            </span>
                        </div>
                    </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <!-- <mat-card *ngIf="cookieData.user_type == 'sales_person' || cookieData.user_type == 'doctor'"> -->

            <!-- </mat-card> -->
        </mat-card-content>

        <!-- <a hidden #myDiv id="tutor-price" href="{{reportLink}}"></a> -->


        <span class="footerBlock"><app-footer></app-footer></span>
    </mat-card>
</block-ui>