import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import * as html2pdf from "html2pdf.js";


@Component({
  selector: 'app-intake-pdf-sheet',
  templateUrl: './intake-pdf-sheet.component.html',
  styleUrls: ['./intake-pdf-sheet.component.css']
})
export class IntakePdfSheetComponent {
  public patient_id:any;
  public order_sheet_data:any;
  public patient_details:any;
  public examination_details:any;
  public intakeFlag:boolean = false;
  public ordersheetFlag:boolean = false;
  public physicianExaminationFlag:boolean = false;
  public order_sheet_sign: boolean = false;
  public fororderreject: boolean = false;
  public pdfstatus: boolean = false;
  public pdfFiles: any = [];
  public pdfFlag: boolean = false;
  public  pageVariable = 1;
  public pageCount:any = [];
  public htmlViewFlag: boolean = false;
  public hightcss: any = "width: 100%; height: 700px; display:block !important"
  objectKeys = Object.keys;
  @Input()
  set patientid(value: any) {
    console.log("inputupdatessign", value);
    if (value) {
      this.patient_id = value;
    }
  }
  constructor(
    private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public cookieService: CookieService, private sanitizer: DomSanitizer,public matIconRegistry: MatIconRegistry) {
      if(this.activatedRoute.snapshot.params['_id']){
        this.patient_id = this.activatedRoute.snapshot.params['_id']
      }
      // console.log('Patient>>>',this.activatedRoute.snapshot.url[0].path)
      // if(this.activatedRoute.snapshot.url[0].path == "patient-pdf-view"){
      //   this.htmlViewFlag = true;
      // }
      // this.matIconRegistry.addSvgIcon(
      //   "applause",
      //   this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/images/practice2lab_logo")
      // );
    }
    ngOnInit() {
      setTimeout(() => {
        this.intakeForm();
      }, 500);

      // setTimeout(() => {
      //   this.ordersheetpage();
      // }, 1000);
    }


  afterLoadComplete(pdf: any) {
    console.log('after-load-complete');
  }
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }
  textLayerRendered(e: CustomEvent) {
    console.log('(text-layer-rendered)', e);
  }

    ordersheetpage() {
      let data: any = {
        searchcondition: {
          patient_id: this.patient_id,
          is_trash: { $exists: false }
        }
      }
        data.searchcondition.status = { $in: [1] };

      this.apiservice.httpViaPost('intake/ordersheet', data).subscribe((response: any) => {
        if (response && response.status == 'success') {
          // console.log('response0000', response.results.res)
          this.order_sheet_data = response.results.res;
          this.ordersheetFlag = true;
          setTimeout(() => {
            
            this.dataFetching();
          }, 1000);
        }
      })
    }

    intakeForm() {
      let data: any = {
        searchcondition: {
          _id: this.patient_id
        }
      }

      this.apiservice.httpViaPost('intake/getleaddata', data).subscribe((response: any) => {
        if (response && response.status == 'success') {
          // console.log('response0000', response.results.res)
          this.patient_details = response.results.res[0];
          if(response.results.res.length > 0) {
            this.intakeFlag = true;
            if(this.patient_details.status > 2){
              this.ordersheetpage();
            }
            if(this.patient_details.status > 1){
              this.physicianExamination();
            }
            if(this.patient_details.status == 6){
              this.pdfShow();
            }
          }
        }
      })
    }


    dataFetching() {
      // setTimeout(() => {
  
  
      let currentpractise = null
      let i = 0;
  
      // console.log('order_sheet_valu', this.order_sheet_valu)
      let allcategoryName = document.querySelectorAll(".orderAllConh2");
  
      // console.log('allcategoryName', allcategoryName)
  
      allcategoryName.forEach((spanElement) => {
  
        let phead = spanElement.id.replace("diagnostic_cat_", "");
        // console.log('phead', phead)
        if (currentpractise == null || currentpractise != phead) {
          // console.log('>>>>>>');
          spanElement.classList.remove("hidecon");
        }
        // else{
        //   console.log('>>>>>>',currentpractise);
  
        // }
        // else console.log("no header", phead, currentpractise, i);
        currentpractise = phead;
        i++;
  
  
      })
  
  
      // }, 1500);
    }


    physicianExamination() {
      let data: any = {
        searchcondition: {
          patient_id: this.patient_id
        }
      }

      this.apiservice.httpViaPost('intake/getexamination', data).subscribe((response: any) => {
        if (response && response.status == 'success') {
          // console.log('response0000', response.results.res)
          this.examination_details = response.results.res[0];
          if(response.results.res.length > 0) {
            this.physicianExaminationFlag = true;
          }

          
        }
      })
    }

    // print(){
    //   window.print();
    // }

    // pdfGen(){
    //   var element = document.getElementById('element-to-print');
    //   var opt = {
    //     margin: 1, 
    //     filename: 'ontract.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: {scale:1 },
    //     jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
    //     pagebreak: { mode: 'css', after:'.pagebreak_before'}       
    // };

    // html2pdf().from(element).set(opt).toPdf().get('pdf').save()
    
    // }


    pdfShow(){
      let datasetval =
      { "searchcondition": { "_id": this.patient_id } }
    this.apiservice.httpViaPost('intake/getleaddata', datasetval).subscribe((response: any) => {
      if (response.status == "success") {
        // for (const value of response.results.res[0].clinical_report) {
        //   this.pdfFiles.push("https://s3.amazonaws.com/practice2lab.com/" + value.path + value.fileservername)
        // }

        if(typeof response.results.res[0].merged_pdf_file != 'undefined' && response.results.res[0].merged_pdf_file != null){
          
          this.pdfFiles = response.results.res[0].merged_pdf_file.s3_url;
          // for(var i=1; i<response.results.res[0].merged_pdf_page_count; i++){
          //   this.pageCount.push(i);
          // }
          // this.pageCount = response.results.res[0].merged_pdf_page_count;
          
        } 
        
        this.pdfFlag = true

        console.warn("BBBB>>",this.pdfFlag,this.pdfFiles);
        
      }
    })

    }

}
