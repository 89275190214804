<div class="ModalTabSubConPage">

    <h1>Patient History</h1>
    <h3>PERSONAL INFORMATION</h3>

    <div class="ModalTabSubConPageFrom">


        <div class="PageFromcon">
            <span>Practice Name</span>
            <label>{{patientdetailsData?.practice_name?patientdetailsData.practice_name:'Na'}}</label>

        </div>

        <div class="PageFromcon">
            <span>Address</span>
            <label>{{ patientdetailsData.patient_address ? patientdetailsData.patient_address : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>State</span>
            <label>{{ patientdetailsData.state ? patientdetailsData.state : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>City</span>
            <label>{{ patientdetailsData.city ? patientdetailsData.city : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>ZIP</span>
            <label>{{ patientdetailsData.zip ? patientdetailsData.zip : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Patient Name</span>
            <label>{{ patientdetailsData.patient_name ? patientdetailsData.patient_name : 'NA' }}</label>

        </div>
        <div class="PageFromcon">
            <span>Patient Phone</span>
            <label>{{ patientdetailsData.patient_phone ? patientdetailsData.patient_phone : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Gender</span>
            <label>{{ patientdetailsData.patient_gender ? patientdetailsData.patient_gender : 'NA' }}</label>

        </div>


        <div class="PageFromcon">
            <span>Patient Email</span>
            <label>{{ patientdetailsData.patient_email ? patientdetailsData.patient_email : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Height</span>
            <label>{{ patientdetailsData.height ? patientdetailsData.height : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>weight</span>
            <label>{{ patientdetailsData.weight ? patientdetailsData.weight : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Date Of Birth</span>
            <label>{{ patientdetailsData.patient_dob ? patientdetailsData.patient_dob : 'NA' }}</label>

        </div>


    </div>

    <h3>PATIENT HISTORY</h3>

    <div class="ModalTabSubConPageFrom3 HISTORYblock">

        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Neurological </h4>

            <table>

                <tr>
                    <td> Seizure Disorder</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>
                <tr>
                    <td> Dizziness/Fainting</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>

                <tr>
                    <td> Weakness/Paralysis</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>

                <tr>
                    <td> Swelling Of Lower Extremities</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>

            </table>

        </div>

        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Infectious Disease </h4>

            <table>



                <tr>
                    <td>Tuberculosis</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Hepatitis</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Mumps</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Measles</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Syphilis</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Gonorrhea</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Venereal</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

            </table>

        </div>


        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Drug Use/Abuse </h4>

            <table>



                <tr>
                    <td>Depressant</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Stimulants</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Narcotics</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Alcohol</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>



            </table>

        </div>


        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Congenital or Genetic </h4>

            <table>



                <tr>
                    <td>Malformations/Deformities</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Blood Diseases</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Multiple Births</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>





            </table>

        </div>

        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Congenital or Genetic </h4>

            <table>



                <tr>
                    <td> Neurological</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Pulmonary(Lung) Diseases</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Renal(Kidney) Diseases</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Immune</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Hypertension</td>
                    <td><span>Yes<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>
                        Atherosclerosis</td>
                    <td><span>Yes<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>No<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>





            </table>

        </div>


        <div class="ModalTabSubConPageFrom3SUB">

            <h4>Additional Notes by Physician</h4>

            <textarea placeholder="Notes"></textarea>

           
                </div>

    </div>

    <!-- <button class="modalsubbtn">Submit</button> -->

</div>