<button class="close_button" style="right: 0;top: 0;">
    <mat-icon style="cursor: pointer;" (click)="closeModal()"> cancel</mat-icon>
</button>

<div class="symptoms_bodal_container">
    <mat-progress-bar mode="indeterminate" *ngIf="modalrogressBar"></mat-progress-bar>
    <ng-container>
        <mat-card-content class="new_usermanagement_right_mainpart_searchpart cw1">
            <div class="navlistwrapper">
                <!-- <div class="navlist" style="display: inline;" *ngFor="let item of nameselectedData;let i=index;"> -->
                <mat-chip class="example-box" *ngIf="nameselectedData">{{nameselectedData.val}}
                    <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(nameselectedData,'name')">
                        cancel</mat-icon>
                </mat-chip>
                <!-- </div> -->
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="nameprogressFlag"></mat-progress-bar>
            <mat-form-field class="example-full-width patient_searchmodal_location_label">
                <input type="text" placeholder="Add CPT Code" matInput [(ngModel)]="symptomsName" (ngModelChange)="userNameModalChange.next($event)" [matAutocomplete]="nameAuto">
            </mat-form-field>
            <mat-autocomplete #nameAuto="matAutocomplete">
                <mat-option *ngFor="let nameObj of nameArr;let i = index" [value]="nameObj.name" (click)="namechooseChipItem(nameObj,i)">
                    {{nameObj.val}}
                </mat-option>
            </mat-autocomplete>
        </mat-card-content>
    </ng-container>


    <div class="search_modal_buttonwrapper">
        <button mat-button (click)="onNoClick()">Submit</button>
        <!-- <button mat-button [mat-dialog-close]="modalData" (click)="modalClose()">Cancel</button>
        <button mat-button (click)="resetForm()">Reset</button> -->
    </div>
</div>