<div class="signPageDialogBody symptomReasonModal">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <ng-container>
        <h2>Symptom Description</h2>
        <p>Please give us a short description for your symptom.</p>
        <form (ngSubmit)="addSymptomReasonMessage()" autocomplete="off" name="symptomReasonForm" [formGroup]="symptomReasonForm">
            <ng-container>
                <div class="allReasonsDiv">
                    <mat-form-field>
                        <mat-label>Symptom Description </mat-label>
                        <textarea matInput row="6" name="reason" formControlName="reason" required [ngModelOptions]="{standalone: true}"></textarea>
                    </mat-form-field>
                </div>
            </ng-container>
            <!-- <mat-form-field> -->
            <mat-checkbox (change)="unsureSymptom($event)" formControlName="unsureSymptomSelected">Unsure how to describe the symptom.</mat-checkbox>
            <!-- </mat-form-field> -->
            <div class="signmodalgroup">
                <button type="submit" mat-raised-button color="primary">Submit</button>
                <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="addsignLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

</div>