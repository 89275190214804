import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-email-template-add',
  templateUrl: './email-template-add.component.html',
  styleUrls: ['./email-template-add.component.css']
})
export class EmailTemplateAddComponent {

  public isedit: boolean = false;
  public addFormLoader: boolean = false;
  public emailTemplateformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public cookieData: any
  public editFormData: any
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public ckeConfig: any = {
    toolbar: [
      { name: "editing", items: ["Scayt", "Find", "Replace", "SelectAll"] },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "tools",
        items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"],
      },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          "Table",
          "HorizontalRule",
          "SpecialChar",
          "Iframe",
          "imageExplorer",
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "RemoveFormat",
        ],
      },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "CreateDiv",
          "-",
          "Blockquote",
        ],
      },
      {
        name: "justify",
        items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      },
      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };
  public userTypeArray: any = [
    { val: 'admin', name: "Admin" },
    { val: 'doctor', name: "Doctor" },
    { val: 'laboratory', name: "Laboratory" },
    { val: 'sales_person', name: "Salesperson" },
    { val: 'practice', name: "Practice" },
    { val: 'patient', name: "Patient" },
  ];


  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    if (!window.location.pathname.includes('admin/email-template')) {
      this.router.navigateByUrl('404')
    }
  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data.results.res) {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    }
    this.initailForm();
  }
  initailForm() {
    this.emailTemplateformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [
        {
          label: 'Subject',
          name: 'subject',
          value: this.editFormData && this.editFormData.subject ? this.editFormData.subject : '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Subject Name is Required' },

          ],
        },

        {
          label: 'User Type',
          name: 'user_type',
          type: 'select',
          multiple: true,
          hint: '',
          val: this.userTypeArray,
          value: this.editFormData && this.editFormData.user_type ? this.editFormData.user_type : '',
          validations: [
            { rule: 'required', message: 'User Type is required' },
            {
              rule: 'pattern',

              message: 'Must be a valid User Type'
            }
          ]
        },


        {
          label: "Email Template",
          name: "template",
          type: "editor",
          value: this.editFormData && this.editFormData.template ? this.editFormData.template : '',
          ckeConfig: this.ckeConfig,
          hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Html Desc field Needs to be required" },
          ]

        },




        {

          label: 'Active',
          name: 'status',
          type: 'checkbox',
          hint: '',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : '',

        }

      ]

    }
  }

  listenFormFieldChange(val: any) {
    console.log("dataobj", val)
    if (val.field && val.field == "fromsubmit") {
      let dataobj = {
        data: val.fromval
      }
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }

      this.addFormLoader = true;
      console.log("dataobj------------", dataobj);

      this.apiservice.httpViaPost('email/addtemplate', dataobj).subscribe((response) => {
        console.log("response", response);

        if (response) {
          if (response.status == 'success') {

            this.addFormLoader = false;
            this.matSnackBar.open(this.editFormData ? "Updated Successfully" : response.message, "Ok", {
              duration: 2000
            });
            this.router.navigateByUrl(`${this.cookieData.user_type}/email-template/template-list`);
          }
          else {
            this.matSnackBar.open(response.message, "", {
              duration: 2000
            });
            this.addFormLoader = false;
          }
        }

      })

    }
  }
}
