<div class="approve_modal_mainwrapper practice_upgrade_modal">
    <!-- <button mat-button (click)="modalClose()" class="closealert"> <mat-icon>close</mat-icon></button> -->
    <div class="dialoghead">
        <h1 class="listingsearchtitle">AI Upgradation</h1>
    </div>
    <div class="bodyContent">
        <h3>UPGRADE to Decision Doc AI and we will help you organize, analyze, and use the best data for each patient.
            <button (click)="learnDecisionAI()">Learn More</button>
        </h3>
        <div class="btnDiv">
            <button (click)="interestForUpgrade('yes')">Interested</button>
            <button (click)="interestForUpgrade('no')">Not Interested</button>
        </div>
        <br />
        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
    </div>
</div>