import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-taining-categoty-add-edit',
  templateUrl: './taining-categoty-add-edit.component.html',
  styleUrls: ['./taining-categoty-add-edit.component.css']
})
export class TainingCategotyAddEditComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public trainingCategoryformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public userRollList: any = [{ name: 'Practice', val: 'practice' }, { name: 'Laboratory', val: 'laboratory' }, { name: 'Salesperson', val: 'sales_person' }, { name: 'Doctor', val: 'doctor' }];
  public parentCategoryList: any = [];
  public ckeConfig: any = {
    toolbar: [
      { name: 'editing', items: ['Scayt', 'Find', 'Replace', 'SelectAll'] },
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      {
        name: 'tools',
        items: ['Maximize', 'ShowBlocks', 'Preview', 'Print', 'Templates'],
      },
      { name: 'document', items: ['Source'] },
      {
        name: 'insert',
        items: [
          'Image',
          'Table',
          'HorizontalRule',
          'SpecialChar',
          'Iframe',
          'imageExplorer',
        ],
      },
      '/',
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript',
          '-',
          'RemoveFormat',
        ],
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          'CreateDiv',
          '-',
          'Blockquote',
        ],
      },
      {
        name: 'justify',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
      },
      {
        name: 'styles',
        items: ['Styles', 'Format', 'FontSize', '-', 'TextColor', 'BGColor'],
      },
    ],
  };

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    if (!window.location.pathname.includes('training')) {
      this.router.navigateByUrl('404')
    }
 if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }

  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            if (response.data.results && response.data.results.res && response.data.results.res.length > 0) {
              if (response.data.results.res[0].user_roll && response.data.results.res[0].user_roll.length > 0) {
                response.data.results.res[0].user_roll = response.data.results.res[0].user_roll.map((e: any) => {
                  if (e == "Salesperson") {
                    e = "sales_person"
                  }
                  return e.toLowerCase()
                })
              }
              this.editFormData = response.data.results.res[0];
            }
          }
        }
      });
      // this.editForm();
    }
    this.fetchAllCategories();
    this.initailForm();
  }

  initailForm() {
    console.log('this.editFormData', this.editFormData)
    this.trainingCategoryformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `${this.cookieData.user_type}/training/training-category-list`, // use for redirect after cancel the form
      fields: [

        {
          label: 'Category Name',
          name: 'training_category_name',
          value: this.editFormData && this.editFormData.training_category_name ? this.editFormData.training_category_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Category Name is Required' },

          ],
        },
        {
          label: 'User Roll',
          name: 'user_roll',
          multiple: true,
          val: this.userRollList,
          value: this.editFormData && this.editFormData.user_roll ? this.editFormData.user_roll : '',
          type: 'select',
          validations: [{ message: 'State is Required' }],
        },
        {
          label: 'Parent Category',
          name: 'parent_category_id',
          val: this.parentCategoryList,
          value: this.editFormData && this.editFormData.parent_category_id ? this.editFormData.parent_category_id : '',
          type: 'select',
          validations: [{ message: 'Parent Category is Required' }],
        },
        {
          label: 'Priority',
          name: 'training_category_priority',
          value: this.editFormData && this.editFormData.training_category_priority ? this.editFormData.training_category_priority : '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },

        {
          label: "Description",
          name: "training_category_des",
          type: "editor",
          ckeConfig: this.ckeConfig,
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.training_category_des ? this.editFormData.training_category_des : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description field Needs to be required" },
          ]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
  }

  fetchAllCategories() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/trainingcategorylist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            response.results.res.forEach((e: any) => {
              let obj: any = {};
              if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
                if (this.editFormData && (this.editFormData._id !== e._id)) {
                  obj = { name: e.training_category_name, val: e._id };
                  this.parentCategoryList.push(obj);
                }
              } else {
                obj = { name: e.training_category_name, val: e._id };
                this.parentCategoryList.push(obj);
              }
            })
          };
        }
      }
    })
  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val) {
      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        console.log("val.fromval", val.fromval);
        let dataobj = {
          data: val.fromval
        }
        if (this.editFormData) {
          dataobj.data._id = this.editFormData._id
        }

        this.apiservice.httpViaPost('training/addedittrainingcategory', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-category-list`);
            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })

      }
      if (val.field && val.field == "formreset") {
        this.formfieldrefreshdata = {
          field: "training_category_des",
          value: '',
        };
      }
    }
  }

}
