import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-subjective-final-view',
  templateUrl: './subjective-final-view.component.html',
  styleUrls: ['./subjective-final-view.component.css']
})
export class SubjectiveFinalViewComponent {
  public paramID: any;
  public intakeData: any;
  public orderData: any = [];
  public showHtmlContent: boolean = false;
  public progressBalFlag: boolean = false;
  // @Input()
  // set ordersheet(value: any) {
  //   console.log("ordersheet000>>>>>", value);
  //   if (value) {
  //     this.orderData = value;
  //   }
  // }
  @Input()
  set intakeId(val: any) {
    if (val) {
      this.paramID = val;
    }
    // console.log(val,"paramID>>>>>", this.paramID);

  }

  constructor(public dialog: MatDialog, public activatedRoute: ActivatedRoute, private httpService: HttpServiceService, public cookieService: CookieService) {
    this.progressBalFlag = true;
  }
  ngOnInit() {
    setTimeout(() => {
      console.log(this.orderData, "paramID>>>>>", this.paramID);
      this.findPatiet();
      this.orderSheetFetching();
    }, 2000);
  }
  findPatiet() {

    let dataset: any = {
      "searchcondition": { "_id": this.paramID }
    }
    this.httpService.httpViaPost('intake/getleaddata', dataset).subscribe((response: any) => {
      if (response) {
        this.intakeData = response.results.res[0];
        //   if (Object.keys(this.intakeData).length > 0) {
        //     let objSixMonths: any = {};
        //     let objLastMonth: any = {};
        //     let objToday: any = {};
        //     let objNote: any = {};
        //     for (let key in this.intakeData) {
        //       let name: any = key.split("_");
        //       let newName: any = '';
        //       newName = this.setSymptomName(name);
        //       let nameSixMonths: any = newName + "_six_months";
        //       let nameLastMonth: any = newName + "_last_month";
        //       let nameToday: any = newName + "_today";
        //       let nameNote: any = newName + "_notes";

        //       if (key == nameSixMonths) {
        //         if (this.intakeData[nameSixMonths]) {
        //           let fullName = newName.split("_");
        //           fullName = fullName.splice(0).join(" ");
        //           objSixMonths = { dbname: newName, fullName: fullName, value: this.intakeData[nameSixMonths] };
        //           // console.log("newName nameSixMonths **", nameSixMonths, "** newName **", newName, "** value **", this.intakeData[nameSixMonths])
        //         }
        //       }
        //       if (key == nameLastMonth) {
        //         if (this.intakeData[nameLastMonth]) {
        //           let fullName = newName.split("_");
        //           fullName = fullName.splice(0).join(" ");
        //           objLastMonth = { dbname: newName, fullName: fullName, value: this.intakeData[nameLastMonth] };
        //           // console.log("newName nameLastMonth **", nameLastMonth, "** newName **", newName, "** value **", this.intakeData[nameLastMonth])
        //         }
        //       }
        //       if (key == nameToday) {
        //         if (this.intakeData[nameToday]) {
        //           let fullName = newName.split("_");
        //           fullName = fullName.splice(0).join(" ");
        //           objToday = { dbname: newName, fullName: fullName, value: this.intakeData[nameToday] };
        //           // console.log("newName nameToday **", nameToday, "** newName **", newName, "** value **", this.intakeData[nameToday])
        //         }
        //       }
        //       if (key == nameNote) {
        //         if (this.intakeData[nameNote]) {
        //           let fullName = newName.split("_");
        //           fullName = fullName.splice(0).join(" ");
        //           objNote = { note: this.intakeData[nameNote] };
        //           // console.log("newName nameNote **", nameNote, "** newName **", newName, "** value **", this.intakeData[nameNote])
        //         }
        //       }
        //       // console.log("allData", objSixMonths, objLastMonth, objToday, objNote)
        //     }
        //   }

      }

      console.warn('IIIII', this.intakeData);
      this.showHtmlContent = true;
      this.progressBalFlag = false;
    })
  }
  orderSheetFetching() {
    let data: any = {
      searchcondition: {
        patient_id: this.paramID,
        is_trash: { $exists: false },
        // status: 1
      }
    }
    this.httpService.httpViaPost('intake/ordersheet', data).subscribe((response: any) => {
      console.log("response", response)
      if (response) {
        if (response && response.results && response.results.res && response.results.res.length > 0) {
          response.results.res.forEach((e: any) => {
            if (e.notes_by_doctor) {
              this.orderData.push(e);
            }
          })
        }
      }
    })
  }

  // setSymptomName(name: any) {
  //   let newName: any = '';
  //   if (name.length < 3) {
  //     name.push("_")
  //     name.push("_")
  //     newName = name.slice(0, name.length - 3).join("");
  //     newName = newName;
  //   } else if (name.length < 4) {
  //     if ((name[name.length - 2] == 'last') || (name[name.length - 2] == 'six')) {
  //       name.push("_");
  //       name.push("_");
  //       newName = name.slice(0, name.length - 4).join("_");
  //     } else {
  //       name.push("_");
  //       newName = name.slice(0, name.length - 2).join("_");
  //     }
  //     newName = newName;
  //   } else if (name.length > 4) {
  //     if ((name[name.length - 1] == 'today')) {
  //       newName = name.slice(0, name.length - 1).join("_");
  //     } else {
  //       console.log('name', name)
  //       newName = name.slice(0, name.length - 2).join("_");
  //     }
  //     newName = newName;
  //   } else {
  //     if ((name[name.length - 1] == 'today') && name.length > 3) {
  //       newName = name.slice(0, name.length - 1).join("_");
  //     } else {
  //       newName = name.slice(0, name.length - 2).join("_");
  //     }
  //     newName = newName;
  //   }
  //   return newName;
  // }

}
