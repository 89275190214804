<app-header>
</app-header>
<mat-card class="adminBody">

    <mat-card-content class="addEditPageBody">
        <mat-card class="addEditPageWrapper">
            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>

            <h2>{{isedit?'Edit':'Add'}} Admin</h2>
            <mat-card class="addEditForm">
                <form (ngSubmit)="addEditAdmin()" autocomplete="off" name="addEditAdminForm" [formGroup]="addEditAdminForm" class="addEditAdminForm">
                    <mat-form-field appearance="fill" class="inputBlock2line">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" name="firstname" formControlName="firstname" required>
                        <ng-container *ngIf="!addEditAdminForm.controls['firstname'].valid && addEditAdminForm.controls['firstname'].touched">
                            <span class="error"> First Name is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock2line">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" name="lastname" formControlName="lastname" required>
                        <ng-container *ngIf="!addEditAdminForm.controls['lastname'].valid && addEditAdminForm.controls['lastname'].touched">
                            <span class="error"> Last Name is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="Email Address" name="email" formControlName="email" required [readonly]="isedit?true:false">
                        <ng-container *ngIf="!addEditAdminForm.controls['email'].valid && addEditAdminForm.controls['email'].touched && addEditAdminForm.controls['email'].value==''">
                            <span class="error">Email Address is Required.</span>
                        </ng-container>
                        <ng-container *ngIf="addEditAdminForm.controls['email'].touched && addEditAdminForm.controls['email'].value!=='' && addEditAdminForm.controls['email'].errors">
                            <span class="error"> Email not valid.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Phone No</mat-label>
                        <input matInput placeholder="Phone" name="phone" formControlName="phone" required (input)="phoneFormatting($event)" maxlength="14" required>


                        <ng-container *ngIf="!addEditAdminForm.controls['phone'].valid && addEditAdminForm.controls['phone'].touched && addEditAdminForm.controls['phone'].value==''">
                            <span class="error">Phone No is Required.</span>
                        </ng-container>

                        <ng-container *ngIf="addEditAdminForm.controls['phone'].touched && addEditAdminForm.controls['phone'].value!=='' && addEditAdminForm.controls['phone'].errors">
                            <span class="error"> Phone No not valid.</span>
                        </ng-container>


                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Fax</mat-label>
                        <input matInput placeholder="Fax" name="fax" formControlName="fax" required (input)="faxFormatting($event)" maxlength="14">


                        <ng-container *ngIf="!addEditAdminForm.controls['fax'].valid && addEditAdminForm.controls['fax'].touched && addEditAdminForm.controls['fax'].value==''">
                            <span class="error">Fax is Required.</span>
                        </ng-container>

                        <ng-container *ngIf="addEditAdminForm.controls['fax'].touched && addEditAdminForm.controls['fax'].value!=='' && addEditAdminForm.controls['fax'].errors">
                            <span class="error"> Fax not valid.</span>
                        </ng-container>




                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock1line addressField">
                        <mat-label>Address</mat-label>
                        <textarea matInput placeholder="Address" name="address" formControlName="address" required></textarea>
                        <ng-container *ngIf="!addEditAdminForm.controls['address'].valid && addEditAdminForm.controls['address'].touched">
                            <span class="error"> Address is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>State</mat-label>
                        <mat-select name="state" formControlName="state" required>
                            <mat-option *ngFor="let val of stateList" value="{{val.abbreviation}}">{{val.name}}</mat-option>
                        </mat-select>
                        <ng-container *ngIf="!addEditAdminForm.controls['state'].valid && addEditAdminForm.controls['state'].touched">
                            <span class="error"> State is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" name="city" formControlName="city" required>
                        <ng-container *ngIf="!addEditAdminForm.controls['city'].valid && addEditAdminForm.controls['city'].touched">
                            <span class="error"> City is Required.</span>
                        </ng-container>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Zip</mat-label>
                        <input matInput placeholder="Zip" name="zip" formControlName="zip" required maxlength="5">
                        <ng-container *ngIf="!addEditAdminForm.controls['zip'].valid && addEditAdminForm.controls['zip'].touched">
                            <span class="error">Zip is Required.</span>
                        </ng-container>
                    </mat-form-field>





                    <ng-container *ngIf="!isedit">
                        <mat-form-field appearance="fill" class="inputBlock2line">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" name="password" formControlName="password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                            <ng-container *ngIf="!addEditAdminForm.controls['password'].valid && addEditAdminForm.controls['password'].touched && addEditAdminForm.controls['password'].value==''">
                                <span class="error"> Password is Required.</span>
                            </ng-container>
                            <ng-container *ngIf="addEditAdminForm.controls['password'].touched && addEditAdminForm.controls['password'].value!=='' && addEditAdminForm.controls['password'].errors">
                                <span class="error"> Password not valid.</span>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="inputBlock2line">
                            <mat-label>Confirm Password</mat-label>
                            <input matInput [type]="hidec ? 'password' : 'text'" name="confirmpassword" formControlName="confirmpassword" required>
                            <button mat-icon-button matSuffix (click)="hidec = !hidec;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidec">
                            <mat-icon>{{hidec ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                            <ng-container *ngIf="!addEditAdminForm.controls['confirmpassword'].valid && addEditAdminForm.controls['confirmpassword'].touched && addEditAdminForm.controls['confirmpassword'].value && addEditAdminForm.controls['confirmpassword'].value.length == 0">
                                <ng-container>
                                    <span class="error">Confirm Password required.</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="addEditAdminForm.controls['confirmpassword'].touched && addEditAdminForm.controls['password'].value !== '' && addEditAdminForm.controls['confirmpassword'].value !== '' && (addEditAdminForm.controls['password'].value !== addEditAdminForm.controls['confirmpassword'].value)">
                                <ng-container>
                                    <span class="error"> Password does not match</span>
                                </ng-container>
                            </ng-container>
                        </mat-form-field>
                    </ng-container>







                    <mat-checkbox name="status" formControlName="status" class="inputBlock1line">Active </mat-checkbox>

                    <mat-card class="addEditButton">
                        <button mat-button type="submit">{{isedit?'Update':'Submit'}} </button>
                        <button mat-button type="button" (click)="backPage()">Back </button>
                        <button mat-button type="reset" (click)="resetForm()" *ngIf="!isedit">Reset </button>
                    </mat-card>

                </form>


                <ng-container *ngIf="addFormLoader">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-container>


            </mat-card>



        </mat-card>
    </mat-card-content>


    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>