<div class="ModalTabSubConPage">
        <h1>POTENTIAL DIAGNOSIS TO REVIEW </h1>

        <div class="Additionalmodalwrapper_content_wrapper Potential_Diagnosis">
                <ng-container *ngIf="professionalopinionDataset">
                        <div *ngFor="let item of professionalopinionDataset" class="wrapper">
                                <mat-card-content class="content1st">
                                        <h2>{{item.icd_code}}: </h2>
                                        <h2>{{item.icd_description}} </h2>
                                </mat-card-content>

                                <mat-card-content *ngIf="item.taxonomies" class="taxonomyButtonDiv">
                                        <div *ngFor="let taxonomy of item.taxonomies">
                                                <button (click)="OpenPinion(item.icd_description,taxonomy)">
                                                        <ng-container *ngIf="taxonomy.logo">
                                                                <span>
                                                                        <img [src]="taxonomy.logo" />
                                                                </span>
                                                        </ng-container>
                                                        {{taxonomy.name}}
                                                </button>
                                        </div>
                                </mat-card-content>

                        </div>
                </ng-container>

                <div *ngIf="professionalopinionDataset == null" class="fld_nodata">
                        <h3> No Record Found !</h3>
                </div>
        </div>





</div>