import { Component } from '@angular/core';

@Component({
  selector: 'app-evaluation-management',
  templateUrl: './evaluation-management.component.html',
  styleUrls: ['./evaluation-management.component.css']
})
export class EvaluationManagementComponent {

}
